
"use strict";
import Reflux from "reflux";
import Actions from "./../../actions/Actions.js";
import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import RB from "react-bootstrap";
import ReactDOM from "react-dom";
import moment from "moment";
import Vutils from "./../../utils/Vutil.js";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import UserDataStore from "./../../stores/UserDataStore.js";
import VConst from "./../../utils/VConst.js";
var t = TranslateStore.getTranslate();
import FilterStore from "./../../stores/FilterStore.js";
import BT from "react-bootstrap-table";
require("./ManualLinks.css");




export default class ManualLinkList extends React.Component{
//var ManualLinkList = export default class   extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            list: []

        };

        
  

    };



    componentDidMount () {
        //this.unsubscribeManualDownloadStore = ManualDownloadStore.listen(this.onData);



    };


    componentWillUnmount () {
        //this.unsubscribeManualDownloadStore();
    };

  

    aclick (e) {
        
    };




    render () {

 

     


	const cols = [
		{
			dataField: 'ID',
            text: " ",
			dataAlign:"left"
		},
		{
			dataField: 'Link',
            text:"Link on document",
			dataAlign:"left"
		}
	];

	const rowEvents = {
		onClick: (e, row, rowIndex) => {
	    Actions.manLinkSelected(rowIndex);

	


		}
	};
  



        return (<div id="divLinkListBox"> 

         
            <h3>Documents  </h3>
            
            <BootstrapTable 
            data={this.props.ManualList} 
            striped={true} 
           								mode="radio"
								noDataIndication="Table is Empty"
            keyField="ID" 
            columns=  {cols}
            rowEvents={rowEvents}
           				selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}


            />
            




        </div>)
    }
};

//module.exports = ManualLinkList;