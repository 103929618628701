/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var ReactDOM = require ("react-dom");
var VConst = require("./../../utils/VConst.js");
var BGTools = require("./../../utils/BGtools.js");
import PropTypes from "prop-types";
var cfchart = null;

function createGraph(domNode, cfHistogramList, t, Day, Location) {


    var ofWeight = [];
    var ofCount = [];
    var xCategorie = [];

    var numCols = cfHistogramList.length;



    try {


        var xStep = Math.round(numCols / 16 );


        for (var i = 0; i < numCols ; i++) {
            ofWeight.push(cfHistogramList[i].BinWeightPortion  );
            ofCount.push(cfHistogramList[i].BinPopulationPortion );
            xCategorie.push ( parseFloat(cfHistogramList[i].BinStartCF)+ " - "+ parseFloat(cfHistogramList[i].BinEndCF) );
        }


        const el = document.querySelector('.HiChartsBaseFontSize');

        var fsNum = 14;
        if (el)
            fsNum = parseInt(getComputedStyle(el).fontSize);

        var chartOptions = {
            credits: {
                enabled: false  //hide watermark
            } ,



            exporting: { enabled: true,
               // scale: 2,
               // sourceWidth: 2280,
               // sourceHeight: 1100,
                filename: "CF_Distribution "+Location+"_"+Day,  // +populationName+" "+ subTitle,
                /*
                buttons: {
                    contextButton: {
                        height: 40
                    }


                }

*/

            } , //hide print / context menu
            chart: {
                type: 'column',
                renderTo: domNode
            } ,

            title: {
                text: t("distribution.cfHeader"),
                style: { fontSize: (fsNum * 1.1) + "px" }
            } ,
            subtitle: {
                text: Day,
                style: { fontSize: (fsNum * 0.86) + "px" }
                /*
                y: 35
                */
            } ,
            xAxis: {
                categories: xCategorie,
                labels:{
                    rotation: 90,
                    style: { fontSize: (fsNum * 0.78) + "px" } ,
                    step: parseInt(xStep)
                } ,



                //  crosshair: true
            } ,
            yAxis: {
                min: 0
                ,
                title: {
                    enabled: false,
                    style: { fontSize: (fsNum * 0.86) + "px" } ,
                } ,
                labels: {
                    style: { fontSize: (fsNum * 0.78) + "px" }

                }

            } ,
            tooltip: {
                headerFormat: '<span style="font-size:10px">CF = {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            } ,
            plotOptions: {

                series: {


                    events: {
                        legendItemClick () {

                            this.show();
                            if (this.index == 0)
                                cfchart.series[1].hide();
                            else
                                cfchart.series[0].hide();

                            return false;
                            // <== returning false will cancel the default action
                        }
                    } ,

                    visible: false,
                } ,

                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }

            } ,
            series: [{
                index: 0,
                name: t("distribution.pOfBiomass"),
                data: ofWeight,
                visible:true,
                color: VConst.COLORS.CONDITION_FACTOR


            } , {
                index: 1,
                name: t("distribution.pOfPop"),
                data: ofCount,
                visible:false,
                color: VConst.COLORS.DISTRIBUTION_RED

            }]


        };


        cfchart = new Highcharts.Chart(chartOptions
            /*  ,
             function(chart) {
             Actions.trendGraphDoneLoading();
             }
             */
        );
		
		BGTools.clearMyHiChartsButtons();
        //var found = BGTools.isHCTextButton("XXXXXX");






    }catch(er){
        alert ("Exception: " + er);
    }







}

export default class  ConditionFactorDistribution extends React.Component{
    static propTypes = {
        // Todo: create a better definition
        cfHistogramList: PropTypes.any.isRequired
    };

    componentDidMount ( ) {
        var domNode = ReactDOM.findDOMNode(this);
        createGraph(domNode, this.props.cfHistogramList, this.props.t, this.props.Day, this.props.Location);
    };

    shouldComponentUpdate ( nextProps, nextState) {
        var domNode = ReactDOM.findDOMNode(this);
        createGraph(domNode, nextProps.cfHistogramList, this.props.t, this.props.Day, this.props.Location);
        return false;
    };

    render () {
        var divStyle = {
            width: "400px",
            height: "400px"
        };

        return (
            <div style={divStyle}></div>
        )
    }
};

