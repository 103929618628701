var React = require("react");
 import {Dropdown,DropdownButton , Button, Menu, Accordion, Row, Col, Form, OverlayTrigger, Alert, InputGroup, Tooltip } from"react-bootstrap";
import Actions from "../../../actions/Actions.js";
import SearchDropdown from "./Dropdowns/SearchDropdown.jsx";
import SelectedDropdownOptions from "./Dropdowns/SelectedDropdownOptions.jsx";
import SessionStore from "../../../stores/SessionStore";


 export default class AdminEditFarmForm  extends React.Component{
	constructor(props) {
     super(props);
    	this.state = {
    		id: 0,
    		name: "",
    		email: "",
    		phone: "",
    		vakiSupEmail: "",
    		latitude: 0,
    		longitude: 0,
    		alternativeId: "",
    		nameEmpty: false,
    		orgEmpty: false,
    		loading: false,
    		timezoneSelected: false,
    		countrySelected: false,
    		regionSelected: false,
            orgSelected: false,
            mustCountry: false,
            mustTimezone: false
    	};

	this.handleNameChange = (e) => {
		this.setState({ name: e.target.value });
	};
	this.handleEmailChange = (e) => {
		 this.setState({ email: e.target.value });
	};
	this.handleVakiSupEmailChange = (e) => {
		this.setState({ vakiSupEmail: e.target.value });
	};
	this.handlePhoneChange = (e) => {
		 this.setState({ phone: e.target.value });
	};
	this.handleLatitudeChange = (e) => {
		this.setState({ latitude: e.target.value });
	};
	this.handleLongitudeChange = (e) => {
		this.setState({ longitude: e.target.value });
	};
	this.handleAlternativeIdChange = (e) => {
		this.setState({ alternativeId: e.target.value });
	};
    this.onFormSubmit = () => {




		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}
		if(this.state.name === "") {
			this.setState({ nameEmpty: true });
			return;
		}
		if(!this.state.orgSelected) {
			this.setState({ orgEmpty: true });
			return;
		}
        if (!this.state.countrySelected) {
            this.setState({ mustCountry: true });
            return;
        }

        if (!this.state.timezoneSelected) {
            this.setState({mustTimezone: true });
            return;
        }

		Actions.editFarmFormSubmit(this.state.name, this.state.email,
			this.state.phone, this.state.vakiSupEmail, this.state.latitude, this.state.longitude, this.state.alternativeId);
		this.setState({
			nameEmpty: false,
			orgEmpty: false,
    		loading: true
		});
		var self = this;
		setTimeout(function(){
			self.setState({
				loading: false
			}); 
		} , 2000);
	};


	};
    UNSAFE_componentWillReceiveProps (nextProps) {

 

		if(nextProps.currentEditFarm.id !== this.state.id) {
			this.setState({
				id: nextProps.currentEditFarm.id,
				name: nextProps.currentEditFarm.name,
				email: nextProps.currentEditFarm.email,
				phone: nextProps.currentEditFarm.phone,
				vakiSupEmail: nextProps.currentEditFarm.vakiSupEmail,
				latitude: nextProps.currentEditFarm.latitude,
				longitude: nextProps.currentEditFarm.longitude,
				alternativeId: nextProps.currentEditFarm.alternativeId
			});
		}
		if(nextProps.currentEditFarm.selectedTimezones.length > 0) this.setState({ timezoneSelected: true });
		else if(this.state.timezoneSelected) this.setState({ timezoneSelected: false });
		if(nextProps.currentEditFarm.selectedCountries.length > 0) this.setState({ countrySelected: true });
		else if(this.state.countrySelected) this.setState({ countrySelected: false });
		if(nextProps.currentEditFarm.selectedRegions.length > 0) this.setState({ regionSelected: true });
		else if(this.state.regionSelected) this.setState({ regionSelected: false });
		if(nextProps.currentEditFarm.selectedOrganisations.length > 0) this.setState({ orgSelected: true });
		else if(this.state.orgSelected) this.setState({ orgSelected: false });
	};

    render () {


    
        var mustCountry = this.state.mustCountry;
        var mustTimezone = this.state.mustTimezone;

        this.state.mustTimezone = false;
        this.state.mustCountry = false;




     return (
			<form>
				<Row>
					<Col md={6}>
						<Form.Label >{this.props.t("adminView.name")}</Form.Label >
						<Form.Control value={this.state.name} onChange={this.handleNameChange} name=""
						type="text"  placeholder={this.props.t("adminView.name") + ".."} />
						<Alert  variant="warning" hidden={!this.state.nameEmpty}>
							<strong> {this.props.t("adminView.mustEnter")} {this.props.t("adminView.name")}!</strong>
						</Alert>
					</Col>
					<Col md={6}>
						<Form.Label >{this.props.t("adminView.email")}</Form.Label >
						<Form.Control value={this.state.email} onChange={this.handleEmailChange} name=""
							type="text"  placeholder={this.props.t("adminView.email") + ".."} />
					</Col>
				</Row>
				<Row>
					<Col md={6}>
					<Form.Label >{this.props.t("adminView.vakiSupEmail")}</Form.Label >
						<Form.Control value={this.state.vakiSupEmail} onChange={this.handleVakiSupEmailChange} name=""
						type="text"  placeholder={this.props.t("adminView.vakiSupEmail") + ".."} />
					</Col>
					<Col md={6}>
					<Form.Label >{this.props.t("adminView.phoneNumber")} </Form.Label >
						<Form.Control value={this.state.phone} onChange={this.handlePhoneChange}
							type="text" placeholder={this.props.t("adminView.phoneNumber") + ".."} />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
					<Form.Label >{this.props.t("adminView.latitude")}</Form.Label >
						<Form.Control value={this.state.latitude} onChange={this.handleLatitudeChange} name=""
							type="number"  placeholder={this.props.t("adminView.latitude") + ".."} />
					</Col>
					<Col md={4}>
					<Form.Label >{this.props.t("adminView.longitude")}</Form.Label >
						<Form.Control value={this.state.longitude} onChange={this.handleLongitudeChange}
							type="number"  placeholder={this.props.t("adminView.longitude") + ".."} />
					</Col>
					<Col md={4}>
					<Form.Label >{this.props.t("adminView.alternativeId")}</Form.Label >
						<Form.Control value={this.state.alternativeId} onChange={this.handleAlternativeIdChange}
							type="text"  placeholder={this.props.t("adminView.alternativeId") + ".."} />
					</Col>
				</Row>
			
				<Row>
				<div className="just-width100">
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.country")} options={this.props.currentEditFarm.countries}
														view="editFarm" type="country" translateTrigger={this.props.translateTrigger}
														disableButton={this.state.countrySelected} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedCountries}
                            view="editFarm" type="country" />

                        <Alert  variant="warning" hidden={!mustCountry}>
                            <strong> {this.props.t("adminView.countrymust")}! </strong>
                        </Alert>
					</Col>
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.region")} options={this.props.currentEditFarm.regions}
															view="editFarm" type="region" translateTrigger={this.props.translateTrigger}
															disableButton={this.state.regionSelected} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedRegions}
														view="editFarm" type="region" />
					</Col>
					</div>
				</Row>
				<Row>
				<div className="just-width100">
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.timezone")} options={this.props.currentEditFarm.timezones}
															view="editFarm" type="timezone" translateTrigger={this.props.translateTrigger}
															disableButton={this.state.timezoneSelected} translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedTimezones}
                            view="editFarm" type="timezone" />

                        <Alert  variant="warning" hidden={!mustTimezone}>
                            <strong> {this.props.t("adminView.timezonemust")}! </strong>
                        </Alert>

					</Col>
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.systems")} options={this.props.currentEditFarm.systems}
														view="editFarm" type="system" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedSystems}
														view="editFarm" type="system" />
					</Col>
					</div>
				</Row>
				<Row>
				<div className="just-width100">
					<Col md={6}>
						<div className="text-center"><strong> Users: </strong></div>
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedUsers}
														view="editFarm" type="user" constant/>
					</Col>
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.organisation")} options={this.props.currentEditFarm.organisations}
																view="editFarm" type="organisation" translateTrigger={this.props.translateTrigger}
																disableButton={this.state.orgSelected} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditFarm.selectedOrganisations}
														view="editFarm" type="organisation" />
						<Alert  variant="warning" hidden={!this.state.orgEmpty}>
							<strong> {this.props.t("adminView.aFarmMust")} {this.props.t("adminView.organisation")}! </strong>
						</Alert>
					</Col>
					</div>
				</Row>
			
				<div className="text-center">
					<Button  variant="primary" onClick={this.onFormSubmit} disabled={this.state.loading} variant="outline-dark">
						{this.props.t("adminView.submit")}
					</Button>
					<div className="spinner" hidden={!this.state.loading}></div>
				</div>
			</form>
		);
	}
};

