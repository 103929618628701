/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 */



"use strict";

var Reflux = require("reflux");
var Immutable = require("immutable");
var moment = require("moment");

var VUtil = require("./../utils/Vutil.js");
var VConst = require("./../utils/VConst.js");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");

var TranslateStore = require("./TranslateStore.js");
var t = TranslateStore.getTranslate();

/**
 * Keeps track of state of filters specific to the Trend Graph report as well
 * as the actual trend data used to generate the graph.
 */
var PopCompareStore = Reflux.createStore({
    init: function() {

        // Here we listen to actions and register callbacks
        this.listenTo(Actions.apiNewPopCompareData, this.onApiNewPopCompareData);

        //this.listenTo(Actions.apiNewTrendGraphAllCagesData, this.onApiNewTrendGraphAllCagesData);


        this.listenTo(Actions.goFetchPopCompareData, this.fetchPopCompareGraph);
        this.listenTo(Actions.matchStartWeight, this.onMatchStartWeight);
        this.listenTo(Actions.popCompareDoneLoading, this.onDoneLoading);
        this.listenTo(Actions.apiHistPopListReceived, this.onHistPopListReceived);
        this.listenTo(Actions.popCompFetchTree, this.fetchTree);
        this.listenTo(Actions.treeSelectEvent, this.onTreeSelectEvent);
        this.listenTo(Actions.fitBestLine, this.onFitBestLine);
        this.listenTo(Actions.showTemp, this.onShowTemp);
        this.listenTo(Actions.datesOnX, this.onDatesOnX);

        this.listenTo(Actions.exactDatesOnX, this.onExactDatesOnX);
        this.listenTo(Actions.trendLineOffset, this.onTrendLineOffset);
        this.listenTo(Actions.minNumFishes, this.onMinNumFishes);
        this.listenTo(Actions.checkTree, this.onCheckTree);
        this.listenTo(Actions.popCompRedraw, this.onPopCompRedraw);



        this.listenTo(Actions.pcHideGroupWell, this.onHideGroupWell);
        this.listenTo(Actions.pcShowGroupWell, this.onShowGroupWell);
        this.listenTo(Actions.pcSaveGroupClick, this.onSaveGroupClick);
        this.listenTo(Actions.pcOpenGroupSelect, this.onOpenGroupSelect);

        this.listenTo(Actions.pcEditModalCancelClick, this.onEditModalCancelClick);
        this.listenTo(Actions.pcEditModalOkClick, this.onEditModalOkClick);


        this.listenTo(Actions.pcGroupModalShowClick, this.onGroupModalShowClick);
        this.listenTo(Actions.pcGroupModalCloseClick, this.onGroupModalCloseClick);
        this.listenTo(Actions.pcGroupModalDeleteClick, this.onGroupModalDeleteClick);

        this.listenTo(Actions.pcPopGroupsReceived, this.onPopGroupsReceived);
        this.listenTo(Actions.pcEditPrivateCheck, this.onEditPrivateCheck);
        this.listenTo(Actions.pcPopGroupSelected, this.onPopGroupSelected);



        this.listenTo(Actions.apiError, this.onApiError);


       this.listenTo(Actions.popCompareButton, this.onButtonPress);
      /*
        this.listenTo(Actions.trendStartDateUpdate, this.onStartDateUpdate);
        this.listenTo(Actions.trendEndDateUpdate, this.onEndDateUpdate);
        this.listenTo(Actions.trendShowAllCages, this.onShowAllCages);
        this.listenTo(Actions.trendShowAvgWeight, this.onShowAvgWeight);
        this.listenTo(Actions.trendGraphDoneLoading, this.onLoadingDone);
*/
        // By default, pick a range of # days in the past from now. We need
        // startOf("day") because the React-Bootstrap-Daterangepicker uses
        // dates from midnight by default.




       // var trendGraphData = Immutable.Map(VakiReactPropTypes.defaultTrendGraphData);
        var trendGraphAllCagesData = Immutable.Map(VakiReactPropTypes.defaultAllCagesPopulationTrendData);
        // Because this store serves a dual purpose, keeping track of state for
        // the filter and most recent Trend Graph data I'm trying here to
        // encapsulate it in an umbrella or parent object; data. This is
        // because when we trigger an action we have to either always send an
        // object with the same structure (like the data approach below) or
        // wrap what we send in an object where we specify what we are sending.
        // Both ways have a code smell but I'm very much trying things out,
        // this may not be the best way of doing things.
        this.data = Immutable.Map({

            trendGraphData: null,
            matchStartWeight: false,

            isLoading: false,
            trendLineOffset: 0,
            minNumFishes:0,
            maxNumDays: 0, //Max num of days pr/serie
            maxNumDaysWhenWeightMatched:0, //Max num of days pr/serie when start point (at y-axis) has been dubtracted
            maxDaySpan: 0, //span of x axis when "dateOnX is checked
            maxDaySpanExact: 0
        });

        this._showGroupWell=false;
        this._showGroupModal = false;

        this._showGroupEditModal = false;


        this.tree = null;
        this.popsSelected = [];


        this.popGroups = [];

        //For table modal
        this.selectedVirtualPopId = -1;




        this.savingPopGroup = null;
        this.shownPopGroup = null;


    },


    getShownPopGroup: function(){
       return this.shownPopGroup;
    },
    getSelectedVirtualPopId: function() {
        return this.selectedVirtualPopId;
    },

    onPopGroupSelected: function (row, isSelected){
        if (!isSelected)
            this.selectedVirtualPopId = -1;
        else
            this.selectedVirtualPopId = row.VirtualPopulationId;
    },



    onPopGroupsReceived:function( groupData ){
        this.popGroups = [];
        for (var i = 0; i<groupData.length; i++){
            var vpList = groupData[i].VirtualPopulationList;
            for (var j=0;j<vpList.length; j++){
                var popList = [];
                for (var k=0; k<vpList[j].PopulationList.length; k++)
                    popList.push({
                        PopulationId:  vpList[j].PopulationList[k].PopulationId,
                        SegmentId:  vpList[j].PopulationList[k].SegmentId
                    });

                this.popGroups.push({
                        VirtualPopulationId: vpList[j].VirtualPopulationId,
                        Name: vpList[j].Name.trim(),
                        StartDate: moment.utc(vpList[j].StartDate) ,
                        EndDate:  moment.utc(vpList[j].EndDate) ,
                        NumPops: vpList[j].PopulationList.length ,
                        Description: vpList[j].Description.trim() ,
                        LastChangeDate: moment.utc(vpList[j].LastChangeDate) ,
                        LastChangedBy: vpList[j].LastChangedBy,
                        Private: (vpList[j].IsPrivate > 0?true:false),
                        Local: true,
                        PopulationList : popList
                    }
                );
            }
        }



       this.trigger("GroupTable");
    },


    onOpenGroupSelect: function(){

        VakiAPI.getVirtualPopulation();

        this._showGroupModal=true;
        this.trigger( "GroupTable" );

    },

    onGroupModalShowClick: function(){

        if (this.selectedVirtualPopId < 0)
            return;


        var selId = this.selectedVirtualPopId;
        var idx = this.popGroups.findIndex( function (el) {return (el.VirtualPopulationId == selId)});





        this.trigger( "GroupTable", true );


        this.popsSelected = [];
        this.clearSelectionsFromTree();
        this.setTreeSelectionFromGroup(this.popGroups[idx]);


        this.shownPopGroup = this.getPopGroup( this.popGroups[idx]);

        this.trigger();
    },





    onGroupModalCloseClick: function(){
        this.trigger ("GroupTable", true);
    },
    onGroupModalDeleteClick: function(){
        if (this.selectedVirtualPopId < 0){
            alert (t("popcompare.nothingselected"));
            return;
        }



        var selId = this.selectedVirtualPopId;
        var idx = this.popGroups.findIndex( function (el) {return (el.VirtualPopulationId == selId)});

        if (idx < 0)
            return;

        var changePage = false;
        if (this.shownPopGroup && this.shownPopGroup.VirtualPopulationId == this.popGroups[idx].VirtualPopulationId) {
            this.shownPopGroup = null;
            changePage = true;
        }

        VakiAPI.deleteVirtualPopulation(this.mapGroupForServer(this.popGroups[idx]));
        this.popGroups.splice (idx, 1);
        this.selectedVirtualPopId = -1;

        this.trigger ("GroupTable");

        if (changePage)
            this.trigger ();

    },



    onEditPrivateCheck: function(){
        this.savingPopGroup.Private = !this.savingPopGroup.Private;
        this.trigger("GroupEdit");
    },

onEditModalCancelClick: function(){
        alert (t("popcompare.savecancel"));
        this._showGroupEditModal = false;
        this.trigger("GroupEdit", true);

    },
    onEditModalOkClick: function(){


        if (this.savingPopGroup.Name.trim().length == 0){
            alert (t("popcompare.mustname"))
            return;
        }

        var tPut = false;

        if (this.savingPopGroup.VirtualPopulationId > 0){
            VakiAPI.putVirtualPopulation(this.mapGroupForServer(this.savingPopGroup));
            tPut = true;
        }else {
            VakiAPI.postVirtualPopulation(this.mapGroupForServer(this.savingPopGroup));
        }

        var triggerMain = false;
        if (this.shownPopGroup){
            triggerMain = true;
            if (tPut){
                this.shownPopGroup = this.getPopGroup(this.savingPopGroup);
            }else{
                this.shownPopGroup = null;
            }
        }
        this.savingPopGroup = null;
        this.trigger("GroupEdit", true);

        if (triggerMain)
            this.trigger ();
    },


    getPopGroupList: function(){
      return this.popGroups;
    },

    getPopGroup: function(pg){
        var newPG = {
            VirtualPopulationId: -1,
            Name: "",
            Description: "",

            EndDate: null,
            EndDateIso: "",
            StartDate: null,
            StartDateIso: "",
            LastChangeDate: null,
            LastChangedBy: "",
            PopulationList: [ ],
            Local: true,
            NumPops: 0,
            FarmID:0,
            Private:true
        };

        if (pg){
            newPG.VirtualPopulationId = pg.VirtualPopulationId;
            newPG.Name = pg.Name
            newPG.Description = pg.Description;
            newPG.EndDateIso= pg.EndDateIso;
            newPG.StartDateIso= pg.StartDateIso;
            newPG.LastChangeDate= pg.LastChangeDate;
            newPG.LastChangedBy = pg.LastChangedBy;

            for (var i = 0; i<pg.PopulationList.length; i++)
                newPG.PopulationList.push ({PopulationId:pg.PopulationList[i].PopulationId,
                                            SegmentId:pg.PopulationList[i].SegmentId});


            if (pg.Local) {
                newPG.EndDate = pg.EndDate;
                newPG.StartDate = pg.StartDate;
                newPG.NumPops = pg.NumPops;
                newPG.FarmID = pg.FarmID;
                newPG.Private = pg.Private;
            }else{
                newPG.EndDate = moment.utc(pg.EndDate);
                newPG.StartDate = moment.utc(pg.StartDate);
                newPG.NumPops = pg.PopulationList.length;
                newPG.Private = (pg.IsPrivate >0)
            }
        }
        return newPG;
    },

    mapGroupForServer: function(pg){
        var spg={

            VirtualPopulationId: pg.VirtualPopulationId,
            Name: pg.Name,
            Description: pg.Description,
            CurrentlyActive: true,
            EndDateIso: "",
            StartDateIso: "",
            LastChangedBy: pg.LastChangedBy,
            IsPrivate: (pg.Private?1:0),
            PopulationList: []

        };

        for (var i = 0; i<pg.PopulationList.length; i++)
            spg.PopulationList.push ({PopulationId:pg.PopulationList[i].PopulationId,
                SegmentId:pg.PopulationList[i].SegmentId});


        return spg;
    },




    getSavingPopGroup: function(){
        return this.savingPopGroup;
    },
    onEditDescriptionChange: function(e){
        this.savingPopGroup.Description = e.target.value;
        if (this.savingPopGroup.Description.length > 100)
            this.savingPopGroup.Description = this.savingPopGroup.Description.substring(0,100);
        this.trigger("GroupEdit");
    },

    onEditNameChange: function(e){
        this.savingPopGroup.Name = e.target.value;
        if (this.savingPopGroup.Name.length > 20)
            this.savingPopGroup.Name = this.savingPopGroup.Name.substring(0,20);

        this.trigger("GroupEdit");
    },

    onHideGroupWell: function(){
    this._showGroupWell = false;
    this.trigger();
},
onShowGroupWell: function(){

    this._showGroupWell = true;
    this.trigger();
},

showGroupWell: function(){
    return this._showGroupWell;
},

    showGroupModal: function(){
        return this._showGroupModal;
    },

    showGroupEditModal: function(){
        return this._showGroupEditModal;
    },

onSaveGroupClick: function(){


    var len = this.popsSelected.length;

    var tPut = false;
    if (this.shownPopGroup){
        tPut = confirm("\'"+this.shownPopGroup.Name+"\' "+ t("popcompare.putmsg"));
    }



    if (tPut){


        this.savingPopGroup = this.getPopGroup(this.shownPopGroup);
        this.savingPopGroup.PopulationList = [];
    }else {
        this.savingPopGroup = this.getPopGroup();
    }

    var farmId = 0;

    for (var i = 0; i<len; i++){
        var p = this.popsSelected[i];


        var frm = this.tree.children[p.o];

        if (i > 0) {
            if (frm.children[p.f].Id != farmId)
                farmId = -1;
        }
        if (farmId != -1)
            farmId = frm.children[p.f].Id;

        var pop =frm.children[p.f].children[p.p];
        var arr = pop.id.split('.');

        this.savingPopGroup.PopulationList.push({PopulationId:parseInt(arr[0]),
                                    SegmentId:parseInt(arr[1])})




    }



    this.savingPopGroup.FarmID = farmId;

    this._showGroupEditModal = true;

    this.trigger("GroupEdit");




},



onCheckTree: function() {
        if (this.tree == null && !this.fetchingTree()) {
            this.fetchTree();
            return;
        }



    },

    onPopCompRedraw: function (){

        this.trigger(null, this.data.get("trendGraphData"));
    },

    onTrendLineOffset: function(val){


        if (!this.fitBestLine())
            return;

        this.data = this.data.set("trendLineOffset", val );

        this.trigger(null, this.data.get("trendGraphData"));

    },


    onMinNumFishes: function(val){



        this.data = this.data.set("minNumFishes", val );

        this.trigger(null, this.data.get("trendGraphData"));

    },


    onTreeSelectEvent: function (selected, path) {

        var change = 1;

        if (selected){
            this.popsSelected.push(path);

        }else{
            var len = this.popsSelected.length;
            var idx = -1;
            for (var i = 0; i<len; i++){
                if (this.popsSelected[i] == path){
                    idx = i;
                    break;
                }
            }
            if (idx >= 0){
                this.popsSelected.splice(idx,1);

            }

            change = -1;

        }

        this.trigger();



    },


    onApiError: function (errMsg){

        this.data = this.data.set("fetchingData", false);
        this.data = this.data.set("isLoading", false);
        this.trigger(null, this.data, false, errMsg);

    },



    onApiNewPopCompareData: function(newPopCompareData) {
        this.data = this.data.set("fetchingData", false);

        var maxDays = 0;
        var dtMin = new Date(3333,1,1);
        var dtMax = new Date(1111,1,1);
        var dtMinExact = new Date(3333,1,1);
        var dtMaxExact = new Date(1111,1,1);

        if (newPopCompareData &&  newPopCompareData.TrendGraphDataList){

            var numPops = newPopCompareData.TrendGraphDataList.length;

            for (var i = 0; i<numPops; i++){

                var d1 = new Date (moment.utc(newPopCompareData.TrendGraphDataList[i].StartDate));
                var d2 = new Date (moment.utc(newPopCompareData.TrendGraphDataList[i].EndDate));



                if (d1 < dtMinExact)
                    dtMinExact = new Date(d1);
                if (d2 > dtMaxExact)
                    dtMaxExact = new Date(d2);



                var dY = d2.getFullYear() - d1.getFullYear();

                d1.setFullYear(2000);
                d2.setFullYear(2000+dY);




                if (d1 < dtMin)
                    dtMin = d1;
                if (d2 > dtMax)
                    dtMax = d2;


                var nnn = this.getPopName(newPopCompareData.TrendGraphDataList[i].PopulationID);
                newPopCompareData.TrendGraphDataList[i]["LongName"] = nnn;



                if (newPopCompareData.TrendGraphDataList[i].PopulationTrendDataList != null){
                    if (newPopCompareData.TrendGraphDataList[i].PopulationTrendDataList.length > maxDays) {

                        maxDays = newPopCompareData.TrendGraphDataList[i].PopulationTrendDataList.length;

                    }
                }
            }

        }

        var nd = Math.round((dtMax.getTime()-dtMin.getTime())/(24 * 3600 * 1000));
        var ndExact = Math.round((dtMaxExact.getTime()-dtMinExact.getTime())/(24 * 3600 * 1000));

        this.data = this.data.set("maxDaySpanExact", ndExact);
        this.data = this.data.set("maxDaySpan", nd);
        this.data = this.data.set("trendGraphData", newPopCompareData);
        this.data = this.data.set("maxNumDays", maxDays);
        this.data = this.data.set("isLoading", false);
       // Actions.popCompareDoneLoading()

        this.trigger(null, newPopCompareData,false);

    },

    setTreeSelectionFromGroup:function(pGroup){

        var pLst = this.getPopGroup(pGroup).PopulationList;
        var numPops = pLst.length;
        function isMatch (pId, sId){
            var idx = pLst.findIndex( function (el) {return (el.PopulationId == pId && el.SegmentId == sId)});
            if (idx >= 0){
                pLst.splice(idx,1);
                numPops--;
                return true
            }
            return false;
        }




        var orgs = this.tree.children;
        for (var o=0; o < orgs.length  &&  numPops>0; o++){
            var farms = orgs[o].children;
            if (farms){
                for (var f=0; f<farms.length  &&  numPops>0 ; f++){

                    var pops = farms[f].children;
                    if (pops){
                        for (var p=0; p<pops.length  &&  numPops>0; p++) {
                            var strId = pops[p].id;
                            var arr = strId.split(".");

                            if (isMatch(parseInt(arr[0]), parseInt(arr[1]))) {
                                pops[p].selected = true;
                                farms[f].collapsed = false;
                                farms[f].numsel++;
                                orgs[o].collapsed = false;
                                orgs[o].numsel++;
                                this.tree.numsel++;
                                this.tree.collapsed = false;
                                 this.popsSelected.push(pops[p].path);
                            }
                        }
                    }
                }
            }
        }
    },

    clearSelectionsFromTree: function(){
        if (!this.tree)
            return;



        for (var i = 0; i< this.tree.numsel; i++)
            window.sessionStorage.setItem("PopCompGraph"+i, 0 )



        this.tree.numsel = 0;
        this.tree.collapsed = true;

        var orgs = this.tree.children;
        for (var o=0; o < orgs.length; o++){
            orgs[o].collapsed = true;
            orgs[o].numsel=0;
            var farms = orgs[o].children;
            if (farms){
                for (var f=0; f<farms.length; f++){
                    farms[f].collapsed = true;
                    farms[f].numsel = 0;
                    var pops = farms[f].children;
                    if (pops){
                        for (var p=0; p<pops.length; p++) {

                            pops[p].selected = false;
                        }

                    }
                }
            }
        }
    },


    onHistPopListReceived : function(lists)
    {


        if(this.tree != null){
            console.warn("Shouldn't receive new tree on non-null value") ;

            return;

        }

        var numOrgs = lists.length;

        this.tree =  {};

        var root = "Select Org";
        var vTrue = true;
        var vFalse = false;


        this.data = this.data.set("fetchingTree", false);




/*
        if (numOrgs == 1){

        }else{
*/
            var orgs = [];

            for (var i = 0; i<numOrgs; i++){

                var o = lists[i];
                var flist = o.FarmList;
                var numFarms = flist.length;
                var farms = [];

                for (var j=0; j<numFarms; j++){
                    var frm = flist[j];
                    var plist = frm.PopulationList;
                    var numPops = plist.length;
                    var pops = [];


                    for (var k = 0; k<numPops; k++){
                        var p = plist[k];


                        var nam = p.Name.trim()+"-"+p.SegmentID+", "+t("popcompare.from")+":"+ moment.utc(p.StartDate).format("YYYY-MM-DD")+"/"+p.Duration+" "+t("popcompare.days")  ;
                        //var path = {o:i,f:j,p:k};

                        var newP = {module:nam, dpt:0, leaf: vTrue, selected:vFalse, path: {o:i,f:j,p:k}, id: p.PopulationID.toString()+"."+ p.SegmentID  };
                        pops.push(newP);

                    }

                    var newF = {module:frm.Name, Id:frm.FarmID, dpt:1, numsel:0, collapsed:vTrue, children:pops};
                    farms.push(newF);



                }


                var org = {module:o.Name, dpt:2, numsel:0, collapsed:vTrue, Id:o.OrganisationID, children:farms};
                orgs.push(org);


            }
            this.tree = {module: "Populations", dpt:3, numsel:0, collapsed: vTrue,  children:orgs};

            this.trigger(null, "", false);



      //  }



    },



   onMatchStartWeight: function(){
       var match = this.data.get("matchStartWeight");
        match = !match;

       this.data = this.data.set("matchStartWeight", match );

       if (match){
           this.data = this.data.set("datesOnX", false );
           this.data = this.data.set("exactDatesOnX", false );

       }

       this.trigger(null, this.data.get("trendGraphData"));


   },


    onFitBestLine: function(){
        var fit = this.data.get("fitBestLine");
        this.data = this.data.set("fitBestLine", !fit );

        this.trigger(null, this.data.get("trendGraphData"));


    },
    onShowTemp: function(){
        var s = this.data.get("showTemp");
        this.data = this.data.set("showTemp", !s );

        this.trigger(null, this.data.get("trendGraphData"));


    },


    onDatesOnX: function(){
        var dt = this.data.get("datesOnX");
        dt = !dt;
        this.data = this.data.set("datesOnX", dt );

        if (dt){
            this.data = this.data.set("matchStartWeight", false );
            this.data = this.data.set("exactDatesOnX", false );

        }


        this.trigger(null, this.data.get("trendGraphData"));


    },



    onExactDatesOnX: function(){
        var dt = this.data.get("exactDatesOnX");
        dt = !dt;
        this.data = this.data.set("exactDatesOnX", dt );

        if (dt ){
            this.data = this.data.set("matchStartWeight", false );
            this.data = this.data.set("datesOnX", false );
        }


        this.trigger(null, this.data.get("trendGraphData"));


    },


    onDoneLoading: function() {
/*
        if(this.data.get("isLoading")) {
            this.data = this.data.set("isLoading", false);
            this.trigger("", false);
        }
 */
    },





    getPopName: function (popID){
        var nam = "";

        var len = this.popsSelected.length;





        for (var i= 0; i<len; i++ ){
            var path = this.popsSelected[i];

            var idArr = this.tree.children[path.o].children[path.f].children[path.p].id;
            var id = idArr.split('.')[0];

            if (id==popID){
                if (this.tree.children.length > 1) {
                    nam = this.tree.children[path.o].module+ ", ";
                }
                nam +=  this.tree.children[path.o].children[path.f].module+ ", "
                     +this.tree.children[path.o].children[path.f].children[path.p].module;



            }

        }

        return nam;
    },

    fetchingData: function() {
        return this.data.get("fetchingData");
    },

    fetchingTree: function() {
        return this.data.get("fetchingTree");
    },
    matchStartWeight: function(){
        return this.data.get("matchStartWeight");
    },

    fitBestLine: function(){
        return this.data.get("fitBestLine");
    },

    showTemp: function(){
        return this.data.get("showTemp");
    },

    datesOnX: function(){
        return this.data.get("datesOnX");
    },

    exactDatesOnX: function(){
        return this.data.get("exactDatesOnX");
    },

    getTrendLineOffset: function(){
        return this.data.get("trendLineOffset");
    },

    getMinNumFishes: function(){
        return this.data.get("minNumFishes");
    },

    getMaxDaySpan: function(){
        return this.data.get("maxDaySpan");
    },
    getMaxDaySpanExact: function(){
        return this.data.get("maxDaySpanExact");
    },

    getMaxNumDays: function(){
        return this.data.get("maxNumDays");
    },

    getMaxNumDaysWhenWeightMatched: function(){
        return this.data.get("getMaxNumDaysWhenWeightMatched");
    },

    setMaxNumDaysWhenWeightMatched: function(val){
        this.data = this.data.set("getMaxNumDaysWhenWeightMatched", val);
     },



    isLoading: function() {
        return this.data.get("isLoading");
    },





    getTrendGraphData: function() {
        return this.data.get("trendGraphData");
    },

    isSelSetReady: function() {

            if (!this.tree)
                return false;
            if (this.tree.numsel == 0)
                return false;


        return true;

    },



    getTree: function(){


        return this.tree;
    },



    /**
     * Initiates a request to fetch Trend Graph data from VakiAPI. Note that
     * this function does not receive the results, they are delivered via a
     * Reflux action.
     *
     * I must admit I do feel a bit of code smell in this function. For one I
     * suspect it's not smart to depend on FilterStore like this. But it's the
     * cleanest way I figured when writing this. Please don't consider this
     * holy.
     *
     */

    onButtonPress: function() {
        if (this.tree == null){


        }

        if (this.tree != null) {
            this.tree.collapsed = true;
            for (var i = 0; i< this.tree.numsel; i++)
                window.sessionStorage.setItem("PopCompGraph"+i, 1 )

        }

        this.fetchPopCompareGraph();

        this.trigger(null, "", true);


    },


    fetchTree: function() {


        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingTree", true);
        VakiAPI.fetchHistoricalPopulations();

         this.trigger(null, "", true);

    },

    fetchPopCompareGraph: function() {

        //var selectedPopulationID = FilterStore.getSelectedPopulationID();


        //if (selectedPopulationID && selectedPopulationID !== 0) {


        var strArgs = "";// "3540.1-3536.1-3541.1";
        var len = this.popsSelected.length;
        if (len > 0)
            strArgs = "";
        for (var i = 0; i<len; i++){
            var p = this.popsSelected[i];


            var frm = this.tree.children[p.o];
            var pop =frm.children[p.f].children[p.p];
            if (strArgs.length > 0)
                strArgs += "-";
            strArgs += pop.id;

        }




        if (strArgs == ""){
            Actions.apiNewPopCompareData([]);
            return;
        }

        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingData", true);


            VakiAPI.fetchPopCompareData(
                /*"3702.1-3627.1-3375.2-733.2",*/
              /*  100, 13000*/
               strArgs,
                FilterStore.getFilter().minWeight,
                FilterStore.getFilter().maxWeight
            );

        this.trigger (null, "",true);
        //}
    },


    getOneLine:  function(startData, offset, lineData) {

        var result = {a: 0, b: 0, pts: 0};


        var arr = lineData.PopulationTrendDataList;


        if (!arr || arr.length == 0) {
              return result;
        }

        var len = arr.length;

        var start = startData + offset;
        var sumY = 0;
        var sumX = 0;
        var cnt = 0;

        var minFishes = this.getMinNumFishes();
        //if startData < 0, then we have DatesOnX and firstpoint of line at x>0
        if (startData < 0) {

            start = 0;
            var l = 0;
            var r = startData + offset;

            if (r < 0) {
                if (r < -88) {
                     return result;
                }

                l = 90 + r;
                if (len < l)
                    l = len;
            } else {
                start = r;
                l = len - r;
            }

            if (l>90)
                l=90;
            len = l;

        } else {

            len = len - startData - offset;
            if (len > 90){
                len = 90;
            }

        }


        if (len <= 1) {
           return result;
        }

        len += start;






        for (var i = start; i<len; i++){
            if ( arr[i].AverageWeight != null && arr[i].NumberOfFish >= minFishes){
                cnt++;
                sumY += arr[i].AverageWeight;
                sumX += i;
            }
        }

        if (cnt == 0) {
             return result;
        }

        sumY = sumY/cnt;
        sumX = sumX/cnt;

        var sumXY = 0;
        var sumXX = 0;
        for (var i = start; i<len; i++){
            if (arr[i].AverageWeight != null && arr[i].NumberOfFish >= minFishes){
                var dY = arr[i].AverageWeight-sumY;
                var dX = i - sumX;
                sumXY += dY*dX;
                sumXX += dX*dX;
            }
        }

        var b= sumXY/sumXX;

        var a = sumY - b*sumX;

        result.a=a;
        result.b=b;
        result.pts = cnt;

        return result;

    },


    getTrendLines: function (startDays,  offset){
        var results = [];



        var td = this.getTrendGraphData();
        if (!td)
            return results;

        var tdList = td.TrendGraphDataList;
        if (!tdList || tdList.length == 0)
            return results;
        var numLines = tdList.length;
        for (var i=0; i<numLines; i++){
            var st = 0;
            if (!(!startDays || ! startDays[i]))
                st = startDays[i];


            results.push(this.getOneLine(st, offset, tdList[i]))

        }


        return results;


    },

    getLinesPrSerie: function(){
        var num = 1;
        if(this.showTemp())
            num++;
        if(this.fitBestLine())
            num++;

        return num;
    }


});

module.exports = PopCompareStore;