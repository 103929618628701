/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */
"use strict";









import React from "react";
import ReactDOM from "react-dom";
import  {Row} from "react-bootstrap";
import LoadingIcon from "react-loader";
import moment from "moment";
import UserDataStore from "./../../stores/UserDataStore.js";
import VConst from "./../../utils/VConst.js";
import Curtain from "./../../utils/Curtain.jsx";
import Actions from "./../../actions/Actions.js";
import Vutils from "./../../utils/Vutil.js";
import OrgFarmPopPicker from "./../Filters/OrgFarmPopPicker.jsx";
import DashboardCanvas from "./DashboardCanvas.jsx";
import DashboardListCanvas from "./DashboardListCanvas.jsx";
import DashboardPopContainer from "./DashboardPopContainer.jsx";
import LastFarmConnection from "./LastFarmConnection.jsx";
import DashboardInfoTable from "./DashboardInfoTable.jsx";
import PhotoView from "./PhotoView.jsx";
import OrgOverview from "./OrgOverview.jsx";
import FilterStore from "../../stores/FilterStore";
import SessionStore from "./../../stores/SessionStore.js";
import DashboardStore from "./../../stores/DashboardStore.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import BT from "react-bootstrap-table";
var t = TranslateStore.getTranslate();
import Spinner from "./../../utils/Spinner.jsx";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import {  Link} from 'react-router-dom';

require("./Dashboard.css");
require("./../../utils/Callout.css");


var dbgCnt = 0;
var getUtilDetails = function (farm) {
    var data = farm.UtilData;
    if (!data) {
        return "";
    }
    var numPops = data.popUtils.length;
    var theData = [];
    for (var i = 0; i < numPops; i++) {
        var pop = FilterStore.getPopulationByID(data.popUtils[i].itemID);
        var popNam = "";
        if (pop)
            popNam = pop.Name;
        theData.push({
            PopName: popNam,
            day7 : data.popUtils[i].day7Summary + "%",
            day30 : data.popUtils[i].day30Summary + "%"
        })
    }

    var theTable =
        <BT.BootstrapTable data={theData} thStyle={{ verticalAlign: 'top', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'red' }}  >
            <BT.TableHeaderColumn dataField='PopName' dataAlign="center" isKey >  Population  </BT.TableHeaderColumn>
            <BT.TableHeaderColumn dataField='day7' headerAlign="center"  >7 day </BT.TableHeaderColumn>
            <BT.TableHeaderColumn dataField='day30' dataAlign="center"  > 30 day </BT.TableHeaderColumn>
        </BT.BootstrapTable>;

    return <div>
        <div className="UtilDesc">
            {t("dashboard.UtilPopTx1")}  <b>7</b> / <b>30 </b> {t("dashboard.UtilPopTx2")}
            <br />  {t("dashboard.UtilPopTx3")}
            <br /> {t("dashboard.UtilPopTx4")}
            
            </div>
        {theTable}

         </div>
};


export default class Dashboard  extends React.Component{
    constructor(props) {
     super(props);
        try {

          
        this.state = {
            filter: FilterStore.getFilter(),
            dashboardData: DashboardStore.getDashboardData(),
            allBoardsData: DashboardStore.getAllBoardsData(),
            dashboardListLoaded: false,
            isLoaded: false, // If we should display loading data
            showMap: false,
            redirect: false



        };

    }catch(er){
    alert ("getInitialState Exception:" + er);
}


/**************from ComponentWillMount************************ */
 try{
            if (SessionStore.isLoggedOut()) {
         
            Actions.redirectToLogin();
            
        }
        var farmID = 0;
        // I must admit I'm not 100% sure this is the right way to do it.
        if ( this.state.filter.selectedFarm !=null &&  this.state.filter.selectedFarm !== undefined)
            farmID = this.state.filter.selectedFarm.FarmID;

      //  if(DashboardStore.showAllOrgs()) Actions.dashShowAllOrgs();

        if (farmID && farmID !== 0) {

            DashboardStore.fetchDashboardForFarm(this.state.filter.selectedFarm.FarmID);

        }

        }catch(er){
            alert ("componentWillMount Exc:" + er);
        }


/*********************************** */

    this.onDashboardStoreChange = (newDashboardData) => {
        var dbg = 0;
                try {
            if (DashboardStore.showAllOrgs() && !newDashboardData.get("allboardsData")) {
                this.setState ({});
                return;
                    }
                    dbg = 1;
            var boardListLoaded = true;
            var orgList = null;
            if (!newDashboardData.get("allboardsData")) {
                boardListLoaded = false;
            } else {
                orgList=newDashboardData.get("allboardsData").get("OrganisationList");
                    }
                    dbg = 2;
            this.setState({
                dashboardData: newDashboardData.get("dashboardData"),
                allBoardsData: orgList ,
                isLoaded: true,
                dashboardListLoaded: boardListLoaded,
                showMap: newDashboardData.get("showMap"),
                showCurrOrg: newDashboardData.get("showCurrOrg"),
                showMsg: newDashboardData.get("showMsg")

                    });
                    dbg=3
                } catch (er) {
                    console.log("onDBstorageChange (" + dbg + ")Exc:  " + er + "------->" );
        }
    };


    this.onFilterStoreChange = ( newFilter) => {
        var dbg = 0;

        try {

            if (newFilter == null) {
                //No data available
                return;
            }

            var selectedFarmID = 0;

            if ( newFilter.selectedFarm != null &&  newFilter.selectedFarm !== undefined)
                selectedFarmID = newFilter.selectedFarm.FarmID;
            dbg = 1

            DashboardStore.fetchDashboardForFarm(selectedFarmID);
            dbg = 2;
            this.setState({
                filter: newFilter,
                isLoaded: false
            });
            dbg = 3;
            Actions.dashCheckOrgListAge();
        }catch(er){
            alert ("onFilterStoreChange Exc("+dbg+"):  "+er);
        }
    };
    /* 
    *  Triggering a re render so that the new language becomes active
    */
    this.onTranslateChange = ( ) =>{
        this.setState({ });
    };

};




/*
    componentWillMount ( ) {
       
        try{
            if (SessionStore.isLoggedOut()) {
         
            Actions.redirectToLogin();
        }
        var farmID = 0;
        // I must admit I'm not 100% sure this is the right way to do it.
        if ( this.state.filter.selectedFarm !=null &&  this.state.filter.selectedFarm !== undefined)
            farmID = this.state.filter.selectedFarm.FarmID;

      //  if(DashboardStore.showAllOrgs()) Actions.dashShowAllOrgs();

        if (farmID && farmID !== 0) {

            DashboardStore.fetchDashboardForFarm(this.state.filter.selectedFarm.FarmID);

        }

        }catch(er){
            alert ("componentWillMount Exc:" + er);
        }


      

    };

*/




    componentDidMount ( ) {
        try {
       
            UserDataStore.postPageHit(VConst.PAGEID.DASHBOARD);
            DashboardStore.notifyMount();
        this.unsubscribeFilterStore = FilterStore.listen(this.onFilterStoreChange);
        this.unsubscribeDashboardStore = DashboardStore.listen(this.onDashboardStoreChange);
        this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateChange);

        sessionStorage.setItem("NewDashSeen", true);
            localStorage.setItem("TheLatestDashUsed", 2); 

     

    }catch(er){
    alert ("compDidUnmount Exc:" + er);
}
    };
   
    componentWillUnmount ( ) {
        try {
            this.unsubscribeFilterStore();
            this.unsubscribeDashboardStore();
            this.unsubscribeTranslateStore();
        }catch(er){
            alert ("compWillUnmount Exc:  "+ er);
        }
    };











    shouldComponentUpdate ( nextProps, nextState) {
        try {

            var currentPopulationID = 0;
             if (this.state.filter.selectedPopulation != null && this.state.filter.selectedPopulation !== undefined)
                 currentPopulationID = this.state.filter.selectedPopulation.PopulationID;

            var nextPopulationID = 0;
            if (nextState.filter.selectedPopulation != null && nextState.filter.selectedPopulation !== undefined)
                nextPopulationID = nextState.filter.selectedPopulation.PopulationID;

        }catch(er){
            alert ("shouldComoinentUpdate Exc:  "+er);
        }
        if (nextPopulationID !== currentPopulationID) {
            return false;
        }

        return true;
    };

    /**
     * Convenience function to pluck out the populationStatus object for the
     * selected populationID, so we can shove it into the table component.
     *
     * @param populationStatusList array of objects from VakiAPI, PopulationStatus
     * @param populationID number, ID of the population we want to fetch the
     * status for
     */
    getPopStatusByID ( populationStatusList, populationID) {

        try {

        if (populationStatusList && populationID) {
            var results = populationStatusList.filter(function (popStatus) {
                return popStatus.PopulationID === populationID;
            });

            if (results && results.length > 0) {
                return results[0];
            }
        }
    }catch(er){
            alert ("getPopStatusBiUD:  Exc  "+er);
        }


        return undefined;
    };

    farmClicked (org, farm) {
     
        if (DashboardStore.showAllOrgs()) {
            Actions.dashFarmClickedOnAllOrgs(org,farm); 

        }else
            Actions.farmClick(farm.FarmID);

     //   Actions.dashboardFarmLink(org.OrganisationID, farm.FarmID);
    };








   


render () {


    //alert (window.devicePixelRatio);

    var theOrgs = "";
      
        var currOrg = DashboardStore.getCurrentOrgDash();
        
        var cnter = 0;
        var currOrgDash = "";
               
        var noLocFarms = "";

        var thingToRender;
        var thePhotos = "";
        var checkboxHelp = "Loading..";
        var disableCheckbox = true;
        if (this.state.dashboardListLoaded) checkboxHelp = "";



         if(this.state.showMap) {
            DashboardStore.showMap(document.getElementById('map'),document.getElementById('mapMsg') );

        }
         
         var theLoading = "";


         if (!this.state.dashboardListLoaded)
             theLoading =  <div className="clsSpinner">
        
            <Spinner />
                </div>



    /*
         if(DashboardStore.showAllOrgs()) {
             dbgCnt = 0;
 
            if (!this.state.dashboardListLoaded)
                thingToRender = (<Spinner />);
            else
                thingToRender = this.getAllDashboards();

         }else
     */    
         {



            

            var stringMsg = t("dashboard.noStatus");

            var dataOK = !(!this.state.filter.selectedPopulation);

            if (dataOK) {
                dataOK = !(!this.state.dashboardData);
                if (dataOK) {
                    dataOK = !(!this.state.dashboardData.get("PopulationStatusList"));
                    if (dataOK) {
                        dataOK = (this.state.dashboardData.get("PopulationStatusList").length > 0);
                    }

                }
             
            }else{
                stringMsg = t("dashboard.noPop");
               
            }

        
             if (dataOK) {

                thePhotos = <PhotoView DashData = {this.state.dashboardData} />
             
                 //if (this.state.dashboardListLoaded)
                        currOrgDash = <OrgOverview theOrgs= { DashboardStore.getCurrentOrgDash()}   
                            showDetails={this.state.showCurrOrg && this.state.dashboardListLoaded } 
                                            currentOrg={true}
                                            onclickfunc = {Actions.dashboardShowCurrOrg}
                                            />
                    // this.getOrgDashboard(currOrg);

       




                 var selectedPopID = this.state.filter.selectedPopulation.PopulationID;
                 var populationStatusList = this.state.dashboardData.get("PopulationStatusList");

                 var comments = this.state.dashboardData.get("CommentsList");

                // console.log("TheCommmmemnts: " + JSON.stringify(comments))

                 var lastDataConnectionTime = moment(this.state.dashboardData.get("LastDataConnectionTime"));
                 var connectionActive = this.state.dashboardData.get("ConnectionActive");
                 if (!(connectionActive != null && (connectionActive.toString().toLowerCase() == "true" || connectionActive.toString() == "1")  ))
                     lastDataConnectionTime = null;
                 var selectedPopStatus = this.getPopStatusByID(populationStatusList, selectedPopID);


                 /*
                                         <RB.Row>
                                         <DashboardInfoTable t={t}
                                                      populationStatus={selectedPopStatus}
                                                      organisationName={this.state.filter.selectedOrganisation.Name}
                                                      farmName={this.state.filter.selectedFarm.Name}
                                                      contactPhone={this.state.dashboardData.get("ContactPhone")}
                                                      contactEmail={this.state.dashboardData.get("ContactEmail")}
                                                      commentsList={this.state.dashboardData.get("CommentsList")}
                                                      teamviewerID={this.state.dashboardData.get("TeamViewerID")} />
                                     </RB.Row>
                 */
                 thingToRender = 
                     <div>
                       
                            <Row>
                                <DashboardPopContainer populationStatusList={populationStatusList}
                                    lastDataConnectionTime={lastDataConnectionTime}
                                    commentsList={comments}
                                    t={t}
         
            
                                />
                            </Row>


                         
                      </div>
                
           

             }else{

                thingToRender = [];

                var strContact = "";
                if(!(!this.state.dashboardData)) {
                    if (!(!this.state.dashboardData.get("LastDataConnectionTime"))) {
                        thingToRender.push((<LastFarmConnection key={cnter++} lastDataConnectionTime={ moment(this.state.dashboardData.get("LastDataConnectionTime"))} t={t }/>));
                        
                    }
                    if (!(!this.state.dashboardData.get("ContactEmail"))) {
                        strContact = "email: "+  this.state.dashboardData.get("ContactEmail");
                    }

                    if (!(!this.state.dashboardData.get("ContactPhone"))) {
                        if (strContact.length == 0){
                            strContact = "phone: "+  this.state.dashboardData.get("ContactPhone");
                        }else {
                            strContact += ",  phone: " + this.state.dashboardData.get("ContactPhone");
                        }
                    }

                    if (strContact.length > 0){
                        thingToRender.push((<Alert key={cnter++} variant="info">
                            <strong>{"Contact info.  "}</strong>{strContact} </Alert>));
                    }
                 }

                thingToRender.push (
                    (<Alert key={cnter++} variant="warning"> {stringMsg} </Alert>));
             }
        }


            //window.dispatchEvent(new Event('resize'));

        //disabled={!this.state.dashboardListLoaded} 


    if ( DashboardStore.showAllOrgCheckbox())
        theOrgs = <OrgOverview  theOrgs={this.state.allBoardsData}  
                               showDetails={DashboardStore.showAllOrgs()} 
                               onclickfunc={Actions.dashShowAllOrgs} 
                                                           
                               />


     /*
       <RB.OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<RB.Popover > Switch to original dashboard </RB.Popover >}>
       </RB.OverlayTrigger >
    */



    /*
                    <div className="hideonmobile">      
                    <OverlayTrigger className="clsDashSwitchButton" delay={200} rootClose={true} placement='top' overlay={<Popover > Switch to original dashboard </Popover >}>
                            <img src="img/OldDash-1.png" className="clsDashSwitchButton" onClick={Actions.dashboardToggleDashboard} />
                        </OverlayTrigger >
                        </div>

    */



        return (
            <div>

                <div id="divFilterWrap" className="clsFilterControlsWrap">
                    
                <div className="clsInlineBlock" >
                <OrgFarmPopPicker filter={this.state.filter}  />
               
                 </div>
               
                <Button type="checkbox" variant="outline-dark"
               onClick={Actions.dashboardToggleMap}>
<img src="img/map-32.png" className = "clsMapIcon" />
                        </Button>
                   </div>

                <div id="mapWrap" className={(this.state.showMap ? "" : "hidden")}>
                    <div id="map" className="mapClass"></div>

                    <div id="mapMsg"></div>
                </div>
                {thingToRender}

{thePhotos}

{currOrgDash}

<br/>


{theOrgs  }





{theLoading}



            </div>
        );
    }
};


/*

        return (
            <div>

                <div id="divFilterWrap" className="clsFilterControlsWrap">
                    
                <div className="clsInlineBlock" >
                <OrgFarmPopPicker filter={this.state.filter}  />
                 </div>

                   

                <Button type="checkbox" variant="primary"
               onClick={Actions.dashboardToggleMap}>
<img src="img/map-32.png" className = "clsMapIcon" />
                        </Button>
                    

 
                        

                        
                    </div>

                <div id="mapWrap" className={(this.state.showMap ? "" : "hidden")}>
                    <div id="map" className="mapClass"></div>

                    <div id="mapMsg"></div>
                </div>
                {thingToRender}
{currOrgDash}

<br/>


{theOrgs  }

{theLoading}



            </div>
        );

*/