/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");

var Actions = require("../../actions/Actions.js");
var moment = require("moment");
var UserDataStore = require("./../../stores/UserDataStore.js");
import ReactBootstrap, {Dropdown, Modal} from "react-bootstrap";
import PropTypes from "prop-types";

export default class  PopulationPicker extends React.Component{
//var PopulationPicker = export default class   extends React.Component{

constructor(props) {
     super(props);
    this.handleClick = (e) => {

        if (this.props.popSelectFunc)
            this.props.popSelectFunc(e.target.id);

        Actions.populationClick(e.target.id);
    };

};

    static propTypes = {
        selectedPopulation: PropTypes.shape({
            PopulationID: PropTypes.number.isRequired,
            Name: PropTypes.string.isRequired
        }).isRequired,

        // Technically it would be better to declare this better. We are
        // expecting an array of objects like: [{ID: 1, Name: "Org"}]. I
        // however don't know at this time how to express that.
        populations: PropTypes.array.isRequired,
        popSelectFunc: PropTypes.func
    };


/*
var populationNodes = populations.map(function (pop) {


    return (
        < RB.MenuItem
    onSelect = {self.handleClick.bind(null, pop)}
key = {pop.PopulationID}
>
{
    pop.Name
}
</
RB.MenuItem >
)
;
});
  */

    render () {
        var self = this; // We won't need to do this if we start to use 6to5, hack because JS sucks
        var populations = this.props.populations.concat();

        var cnt = 0;

        var showInactive = UserDataStore.showInactive();
        var selName = this.props.selectedPopulation.Name;



        if (showInactive) {
            var seldt = moment.utc(this.props.selectedPopulation.StartDate);
            selName += " (" + (seldt.month() + 1) + "/" + seldt.year()+")";
        }

        try {
            var populationNodes = populations.map(function (pop) {
                //var name = pop.Name +  (pop.SegmentID == 1? "": "("+ pop.SegmentID+")");

                var name = pop.Name
                if (showInactive) {
                    var dt = moment.utc( pop.StartDate);
                    name += " (" + (dt.month() + 1) + "/" + dt.year()+")";
                }

                cnt++;

                return (
                   <li key = {pop.PopulationID+" "+name}> < Dropdown.Item   onClick = {self.handleClick}  key = {cnt}  id={pop.PopulationID} >
        {      name       } </Dropdown.Item ></li>
        );
    });
}catch (er){ alert ("PopulationPicker render Exc: "+ er);}
        return (
                   <Dropdown>
              <Dropdown.Toggle  id="dropdown-pops"  variant="outline-dark">
    {selName}
            </Dropdown.Toggle>
            <Dropdown.Menu title="PopulationMenu" onClick={Actions.popButtonClick} >
                {populationNodes}
            </Dropdown.Menu>
            </Dropdown>
        )
    }
};


