/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
import {InputGroup, FormControl} from"react-bootstrap";
import validator from "validator";
var Actions = require("./../../actions/Actions.js");

var VConst = require("./../../utils/VConst.js");
import PropTypes from "prop-types";
var timervar = null;
var timerNewValue = null;


export default class MinWeightFilter extends React.Component{

    static propTypes = {
        maxValue: PropTypes.number,
        allowempty: PropTypes.bool,
        initValue : PropTypes.number
   
     };


  	constructor(props) {
          
     



     super(props);




    this.isInputValid  = (inputStrValue) => {
        if (this.props.allowempty && (!inputStrValue || inputStrValue.toString() == ""))
            return true;
        if (!(typeof inputStrValue === 'string' || inputStrValue instanceof String)) 
            inputStrValue = inputStrValue.toString();
        
        if (validator.isInt(inputStrValue)) {
            var minWeight = validator.toInt(inputStrValue);
            if (minWeight >= VConst.ABSOLUTE_MIN_WEIGHT && minWeight <= this.props.maxValue) {
                return true;
            }
        }

        return false;
     };


      let initValue = "";


      if (this.props.initValue)
        initValue = this.props.initValue;


      let initErrorState = "";  

      if (!this.isInputValid(initValue))
        initErrorState = VConst.CLASS.EDIT_ERROR;

        this.state = {
            value: initValue,
            errorState: initErrorState
        };

    this.validationState = () => {

        if (this.isInputValid(this.state.value)) {
            // Render normally. In Bootstrap there is no "normal" style so if
            // we want to get just normal display (no red or yellow borders)
            // the only way I found was to return undefined.
            return "";
        }
        return VConst.CLASS.EDIT_ERROR;
     };




    this.handleChange = () => {


        var newValue = this.refs.input.value;




        if (timervar != null) {
            window.clearTimeout(timervar);
            timervar = null;
        }

        let errState = "";
        if (this.isInputValid(newValue)) {
            timervar = window.setTimeout(this.timerFunc,VConst.FILTER_EDIT_DELAY);
            timerNewValue = newValue;
            /*
              var newMinWeight = validator.toInt(newValue);
            Actions.minWeightUpdate(newMinWeight);
            */
        }else{
            errState = VConst.CLASS.EDIT_ERROR;
        }

        this.setState({
            value: newValue,
            errorState: errState
        });

     };

     };//constructor

    UNSAFE_componentWillReceiveProps (newProps) {
  
        this.setState({ value: newProps.initValue });
     };






timerFunc (){
    var newMinWeight = validator.toInt(timerNewValue);
    Actions.minWeightUpdate(newMinWeight);
    timervar = null;
 };

render () {
        let strVal = "";
        if (this.state.value)
            strVal = this.state.value;
//   variant={this.validationState()}

        return (

  <InputGroup className="XXmb-2">
    <InputGroup.Text id="inputGroup-sizing-lg">{this.props.t("filters.minWeight")}</InputGroup.Text>
    <FormControl 
            className = {this.state.errorState}
                type = "text"
                ref = "input"
                value={strVal}
             
                onChange = {this.handleChange}
             
              
                placeholder="Auto"
               
     />
  </InputGroup>





        );
        
    }
};



/*
            <RB.Input
                type = "text"
                ref = "input"
                addonBefore = {this.props.t("filters.minWeight")}
                value={this.state.value}
                allowempty={this.props.allowempty}
                onChange = {this.handleChange}
                 variant={this.validationState()}
                placeholder="Auto"

            />
*/