/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
import NumericInput from "./NumericInput.jsx";
import PropTypes from "prop-types";
/**
 * React component to specify a gutting factor for the Distribution report. The
 * gutting factor is represented by a percentage number, for example 12%.
 */
export default class  GuttingFactorFilter extends React.Component{
    static propTypes = {
        guttingFactor: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    render () {
        return (
            <NumericInput
                value={this.props.guttingFactor}
                label={this.props.t("filters.guttingFactor")}
                minValue={0}
                maxValue={100}
                actionFunction={this.props.actionFunction}
                allowempty = {true} />
        )
    }
};

