
"use strict";
import Reflux from "reflux";
import Actions from "./../../actions/Actions.js";
import Spinner from "./../../utils/Spinner.jsx";
import React from "react";
import RB from "react-bootstrap";
import ReactDOM from "react-dom";
import moment from "moment";
import Vutils from "./../../utils/Vutil.js";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import UserDataStore from "./../../stores/UserDataStore.js";
import VConst from "./../../utils/VConst.js";
var t = TranslateStore.getTranslate();
import FilterStore from "./../../stores/FilterStore.js";
import ManualLinkList from "./ManualLinkList.jsx";
import ManualLinkEdit from "./ManualLinkEdit.jsx";
import VakiAPI from "./../../webservice/VakiAPI.js";
require("./ManualLinks.css");


var ManualLinksStore = Reflux.createStore({

 
    init () {

        this.listenTo(Actions.manRcvForEdit, this.onListRcv);
        this.listenTo(Actions.manEditGetList, this.getList); 
        this.listenTo(Actions.manPostResults, this.onSaveResult);
        this.listenTo(Actions.manLinkSelected, this.onSelManual);
        this.listenTo(Actions.manDelete, this.onManDeleted);
        this.theList = [];

        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },

    onListRcv: function (resp) {
        this.theList = resp.manList;

        this.trigger(this.theList);
    },

    getList: function () {
        VakiAPI.GetManuals(Actions.manRcvForEdit);

        this.trigger(null);
    },

    onSaveResult: function (result) {

        if (result.substring(0, 4).toUpperCase() !== "SUCC") {
            alert("Error saving: " + result);
            return;
        }
        Actions.manEditGetList();

   
       
    },
    onManDeleted: function (result) {
        if (result.substring(0, 4).toUpperCase() !== "SUCC") {
            alert("Error deleting: " + result);
            return;
        }
        Actions.manEditGetList();
    },



    onSelManual: function (rowIndex) {


        this.trigger(this.theList, rowIndex);

    }




});


export default class ManualLinks extends React.Component{
//var ManualLinks = export default class   extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            manList: [],
            selIdx: -1,
            loading: true


        };

    this.onList =  (newList, sel) => {


        if (sel === null)
            sel = -1;

        this.setState({ manList: newList, selIdx:sel, loading: (newList == null)   });
    };


    };



    componentDidMount () {
        this.unsubscribeManualLinksStore = ManualLinksStore.listen(this.onList);
        Actions.manEditGetList();

        //VakiAPI.GetManuals(Actions.manRcvForEdit);

    };


    componentWillUnmount () {

     
        this.unsubscribeManualLinksStore();
    };

  




    render () {



        if (this.state.loading)
            return <div><Spinner /></div>;

  //     <ManualLinkList />


        var _ID = -1;
        var _Link = "";
        var _ToolTip = "";
        var _LinkText = "";
        var _AdminOnly = false;
        var _Language = "";
        var _NoEditInfo = "";
        var _NewLink = true;



     
        if (this.state.selIdx >= 0 && this.state.selIdx < this.state.manList.length) {
            var man = this.state.manList[this.state.selIdx];
            _ID = man.ID;
            _Link = man.Link;
            _ToolTip = man.ToolTipText;
            _LinkText = man.LinkText;
            _AdminOnly = man.AdminOnly;
            _Language = man.Language;
            _NoEditInfo ="("+man.ID+ ") Created " + moment( man.DateCreated).format("DD-MMM-YYYY HH:mm") + " by " + man.Creator;

            if (man.LastModified)
                _NoEditInfo += "\nLast modified " + moment(man.LastModified).format("DD-MMM-YYYY HH:mm") + " by " + man.ModifiedBy + ":  " + man.Modifications;
                 
            _NewLink = false;

        }


        return (<div> 

         
                 

            <div id="divFlex">
                <ManualLinkList ManualList={this.state.manList} SelIndex={this.state.selIdx} />

                <ManualLinkEdit
                    NewLink={_NewLink}
                    ID={_ID}
                    Link = {_Link}
                    ToolTip = {_ToolTip}
                    LinkText = {_LinkText}
                    AdminOnly = {_AdminOnly}
                    Language = {_Language}
                    NoEditInfo={_NoEditInfo}
                    />
                </div>

        </div>)
    };
};

//module.exports = ManualLinks;