
"use strict";
var Reflux = require("reflux");
var Actions = require("./../../actions/Actions.js");
var React = require("react");
import {Button} from "react-bootstrap";
var ReactDOM = require("react-dom");
var moment = require("moment");
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var UserDataStore = require("./../../stores/UserDataStore.js");
var VConst = require("./../../utils/VConst.js");
var t = TranslateStore.getTranslate();
var VakiAPI = require("../../webservice/VakiAPI");
var FilterStore = require("./../../stores/FilterStore.js");
import SingleDatePicker  from "./../Filters/SingleDatePicker.jsx";
var DateRangePicker = require("react-bootstrap-daterangepicker");
//var DatePicker = require("react-bootstrap-date-picker");
require("./DashStarvDate.css");

var _isMounted = false;

var DashStarvDateStore = Reflux.createStore({
    init () {
        this.listenTo(Actions.dashPopStarvDate, this.onDashPopStarvDate);
        this.listenTo(Actions.dashPopStarvDateUpdate, this.onSetDate);
        this.listenTo(Actions.apiPostStarvingDateResults, this.onPosted);

        this.StarvStuff = {
            pop: null,
            saving: false,
            anyChange: false,
            starvDate: null
        };


    },

    onPosted (msg) {

        if (msg.substring(0, 3) === "Err") {
            alert(msg);

            this.trigger(null);
            return;
        }


        var f = FilterStore.getSelectedFarm();
        VakiAPI.fetchDashboardForFarm(f.FarmID);
        this.trigger(null);
    },

    onSetDate (dt) {

        if (dt > moment.utc()) {
            alert("NOTE! This set starving date is in the future.")
        }
    

        if (dt != this.StarvStuff.pop.PopDayStatus.StarvingDate)
            this.StarvStuff.anyChange = true;

        this.StarvStuff.pop.PopDayStatus.StarvingDate = dt;
        this.StarvStuff.starvDate = moment(dt);

        this.trigger(this.StarvStuff, true);

    },

    onDashPopStarvDate (p) {


        this.StarvStuff.anyChange = false;
        this.StarvStuff.pop = p;
        this.StarvStuff.saving = false;
        if (!p.PopDayStatus || !p.PopDayStatus.StarvingDate)
            this.StarvStuff.starvDate = null;
        else
            this.StarvStuff.starvDate = moment(p.PopDayStatus.StarvingDate)



        this.trigger(this.StarvStuff, true);

  

    },

    doCancel () {

        this.trigger(null);
    },


    doRemove () {
  
        if (this.StarvStuff.starvDate !== null)
            this.StarvStuff.anyChange = true;
        this.StarvStuff.starvDate = null;

        this.trigger(this.StarvStuff, true);

    },


    doSave () {

        var dt = "";
        if (this.StarvStuff.starvDate != null)
            dt = this.StarvStuff.starvDate.format("YYYY-MM-DD");




        var pID = this.StarvStuff.pop.PopulationID;
        var sID = this.StarvStuff.pop.PopDayStatus.SegmentID;
        this.StarvStuff.saving = true;

        var sd = {

            theDateString: dt,
            PopulationID: pID,
            SegmentID: sID


        };
        VakiAPI.PostStarvingDate(sd);

        this.trigger(this.StarvStuff);
    }





});


export default class DashStarvDate  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
          
            show: false,
            
            data: null
        };

    this.onResize = ()  => {

   if (_isMounted)
        this.setState({});
    };

    this.onChange = (theData, show) => {

        if (!show)
            show = false;


        this.setState({
            show: show,
           
            data: theData
        });


     
    };


    };


 


    componentDidMount () {

        _isMounted = true;
        //var domNode = ReactDOM.findDOMNode(this);
        this.unsubscribeDashStarvDate = DashStarvDateStore.listen(this.onChange);
        //   createDashboardCanvas(domNode, this.props.populationStatusList);

 

        window.addEventListener("resize", this.onResize);
       // this.setState({});
    };


    componentWillUnmount () {
        _isMounted = false;
        this.unsubscribeDashStarvDate();
    };


    



    


    render () {



        var saveDisabled = true;
        var removeDisabled = true;

        var el = document.getElementById("overlay");



        var starvDayText = t("dashboard.starvnotset");
        var calendarDate = moment();

        var fs = 14;
      
        if (el) {


            fs = parseInt(window.getComputedStyle(document.body).fontSize);

            var numEms = window.innerWidth / fs;


            var pad = 4;
            if (numEms > 45) {
                pad = ((numEms - 40) / 2) * 100 / numEms;
            }
      

            if (this.state.data && this.state.data.starvDate) {
                calendarDate = this.state.data.starvDate;
               
            }

              
            el.style.display = (this.state.show ? "block" : "none");



            el.style.paddingLeft = pad + "%";
            el.style.paddingRight = pad + "%";
       
          


            var elM = document.getElementById("divStarvDateSet");


            elM.style.width = (100 - 2 * pad) + "%";

            //elM.style.top = 0; //(this.state.coord.y - 80) + "px";
            //elM.style.left = 0; //(this.state.coord.x - 100) + "px";

            if (this.state.data && this.state.data.starvDate != null)
                        starvDayText = "Set starving date: " + this.state.data.starvDate.format("LL");





            if (this.state.data) {
                saveDisabled = !this.state.data.anyChange;
                removeDisabled = this.state.data.starvDate === null;
            }
        }





  







        return (


            <div id="overlay" onClick={DashStarvDateStore.doCancel} >
                <div id="divStarvDateSet" className="clsStarvDateSet" onClick={function (e) { e.stopPropagation() }} >

                    
                    <div id="divStarvPopName" > {(this.state.data && this.state.data.pop) ? this.state.data.pop.PopulationName : "XXX"}  </div>
                    <div id="divStatusInfo">{starvDayText}</div>

  
                    <SingleDatePicker date={calendarDate} actionFunction={Actions.dashPopStarvDateUpdate} t={t} endDate={'+0d'} />


                    <div id="divStarvButtons" className = "clsStarvButtons">
                        <Button id="btnRemove" onClick={DashStarvDateStore.doRemove} disabled={removeDisabled} >{t("dashboard.removesetdate")}</Button>
                        <Button id="btnCancel" onClick={DashStarvDateStore.doCancel}  >Cancel</Button>
                        <Button id="btnSave" onClick={DashStarvDateStore.doSave} disabled={saveDisabled}>Save</Button>
                    </div>

                </div>
            </div>


        )
    }
};



/*
                     <SingleDatePicker date={calendarDate} actionFunction={Actions.dashPopStarvDateUpdate} t={t} endDate={'+0d'} />

 */


 
