/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * Displays the trendgraph that shows the average weight of all cages.
 */

"use strict";

var React = require("react");
var ReactDOM = require("react-dom");
var moment = require("moment");
var ImmutablePropTypes = require("react-immutable-proptypes");
var FilterStore = require("../../stores/FilterStore");
var TrendGraphStore = require("./../../stores/TrendGraphStore.js");

var VConst = require("./../../utils/VConst.js");
var HSUtil = require("./../../utils/HSUtil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var Actions = require("./../../actions/Actions.js");
var ColorPicker = require("./ColorPicker.js");
var BGTools = require("./../../utils/BGtools.js");
var trendGraphArea;
import PropTypes from "prop-types";
/**
 * React component that displays the actual graph in the Trend Graph report.
 */
export default class TrendgraphAvgWeightArea  extends React.Component{
    static propTypes = {

        // I'm testing Immutable PropTypes, see:
        // https://github.com/HurricaneJames/react-immutable-proptypes
        //trendGraphData: ImmutablePropTypes.shape(VakiReactPropTypes.trendGraphData)
    };

    componentDidMount ( ) {
        var domNode = ReactDOM.findDOMNode(this);
        trendGraphArea = this;
        var drawTrends = TrendGraphStore.showAWasTrendlines();
        this.makeGraph(domNode, this.props.trendGraphData, drawTrends,
        	this.props.maxNumberOfFish, this.props.maxAvgWeight,
        	this.props.startDate, this.props.endDate);
    };

    shouldComponentUpdate ( nextProps, nextState) {


        if(TrendGraphStore.fetchingData()) return false;
        var domNode = ReactDOM.findDOMNode(this);
        var drawTrends = TrendGraphStore.showAWasTrendlines();
        this.makeGraph(domNode, nextProps.trendGraphData,drawTrends,
        	nextProps.maxNumberOfFish, nextProps.maxAvgWeight,
        	nextProps.startDate, nextProps.endDate);
        // Always return false because we have no need for React to re-render,
        // we hand over the re-rendering to HighChart by calling makeGraph()
        // and passing in the DOM node.
        return false;
    };
    /**
     * Highchart settings on how we want the graph to be displayed.
     *
     * Note: JSON dates returned by ASP.Net come on the following format:
     *
     *  "/Date(1422489600000+0000)/"
     *
     * This is a bit un-convenient as JavaScript has no native way to parse this
     * to an native Date object. However the third party library moment can parse
     * this, see: http://momentjs.com/docs/#/parsing/asp-net-json-date/
     *
     * @param domNode
     * @param trendData
     */
    makeGraph (domNode, trendData, drawTrend, maxFish, maxWeight, start, end) {



        if (!trendData || trendData.length == 0) {
            console.warn("TrendgraphAvgWeight with no data");
            return;
        }








    	var numberOfPops = trendData.length;


    	var averageWeights = [];
    	var longestDataList = 0;
    	var longestDataListNumber = 0;
    	for(var i = 0; i < numberOfPops; ++i) {
    		averageWeights.push([]);
            if(trendData[i].PopulationTrendDataList === null || trendData[i].PopulationTrendDataList === undefined) continue;
    		if(trendData[i].PopulationTrendDataList.length > longestDataList) {
    			longestDataList = trendData[i].PopulationTrendDataList.length;
    			longestDataListNumber = i;
    		}
    	}
        
        var dates = [];
        var maxAvgWeightYAxis = maxWeight;
        var maxNumberOfFish = maxFish;
        var sgr = trendData[0].SGR;
        var endWeight = Math.round(trendData[trendData.length - 1].TrendLine);
        var startDate = moment(start);
        var endDate = moment(end);

        var subTitle = startDate.format("YYYY-MM-DD") +" to "+ endDate.format("YYYY-MM-DD");
        var startDateUnixTime = startDate.valueOf();
        var oneDayInMS = 24 * 3600 * 1000;
        var graphSeries = [];
        for(var j = 0; j < numberOfPops; ++j) {
        	var currentEntryDate = startDate.clone();


            if(trendData[j].PopulationTrendDataList === null || trendData[j].PopulationTrendDataList === undefined) continue;
            for (var i = 0; i < trendData[j].PopulationTrendDataList.length; i++) {
        		var measurement = trendData[j].PopulationTrendDataList[i]; // To make the code a bit more readable
	            var trendDataDate = moment(measurement.Date);
	            /*
	            *  When showing all cages, charts sometimes start at time period where
	            *  no data is available. To avoid starting data entry at the wrong time,
	            *  we compare the starting date with the true data starting date. If they
	            *  do no match then we skip over the date.
	            */
	            if(trendDataDate.unix() !== currentEntryDate.unix()) {
	                if(trendDataDate.unix() > currentEntryDate.unix()){
	                    --i;
	                    currentEntryDate = currentEntryDate.add(1, "days");
	                    averageWeights[j].push([null]);
	                }
	                continue;
	            }
	            currentEntryDate = currentEntryDate.add(1, "days");

                if (!drawTrend)
	                averageWeights[j].push(measurement.AverageWeight);
                else
                    averageWeights[j].push(measurement.TrendLine);


	            if(j === longestDataListNumber) {
	            	var date = moment(measurement.Date);
	            	dates.push(date.valueOf());
	            }
        	}
            var lineColor = ColorPicker.getColor(j);
        	graphSeries.push({
        		name: FilterStore.getPopulationNameByID(trendData[j].PopulationID) || "",
                yAxis: "avgWeightYAxis",
                type: "spline",
                color: lineColor,
                pointStart: startDateUnixTime,
                pointInterval: oneDayInMS,
                tooltip: {
                    valueSuffix: "g"
                } ,
                data: averageWeights[j]
        	});

        }
        var chartOptions = {
            chart: {
                renderTo: domNode,
                zoomType: "xy"
            } ,

            title: {
                text: this.props.t("trendgraph.avgWeightHeader"),
                x: -60 //center
            } ,

            subtitle: {
                text: subTitle,
                x: -60
            } ,

            credits: false,

            exporting: {
                enabled: true,
                scale: 2,
                sourceWidth: 2280,
                sourceHeight: 1100,
                filename: "BMD_TrendAvgWgt "+FilterStore.getSelectedPopulationName() +" "+ subTitle,
                buttons: {
                    contextButton: {
                        height: 40
                    }


                }

            } ,


            // Defines a list of the x-axes we want
            xAxis: {
                type: "datetime",
                title: {
                    text: this.props.t("trendgraph.time")
                } ,
                min: startDate.utc().valueOf(),
                max: endDate.utc().valueOf()
            } ,

            // Defines a list of the y-axes we want
            yAxis: [
                // Average weight y-axis
                {
                    id: "avgWeightYAxis",
                    // Fix the y-axis to be a minimum of 0, if this is not in place then
                    // the autoscaling could show the y-axis with -100g weight when we
                    // get measurements of 0g (meaning no accurate measurements available).
                    min: 0,
                    max: maxAvgWeightYAxis,
                    title: {
                        text: this.props.t("trendgraph.leftYHeader")
                    } ,
                    labels: {
                        format: "{value}g"
                    } ,
                    opposite: true,
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }]
                }
            ],

            tooltip: {
                shared: true
            } ,

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0
            } ,

            series: graphSeries
        };

        // Draw the graph with our given options
        var chart = new Highcharts.Chart(chartOptions, function(chart) {
            Actions.trendGraphDoneLoading();
        });
        var found = BGTools.isHCTextButton("XXXXXX");
    };
    render () {
        var divStyle = {
            width: "100%",
            height: "550px"
        };

        return (

            <div style={divStyle}>Loading..</div>


        )
    }
};


