/**
Uptime and Measurement count monitor

 *
 */

 "use strict";
/*
var React = require("react");
var RB = require("react-bootstrap");
var BT  = require("react-bootstrap-table");
var moment = require("moment");
var OrgFarmPopPicker = require("./../Filters/OrgFarmPopPicker.jsx");
var FilterStore = require("../../stores/FilterStore");
var SessionStore = require("./../../stores/SessionStore.js");
var UptimeStore = require("./../../stores/UptimeStore.js");
var Spinner = require("./../../utils/Spinner.jsx");
var OrgFarmPopPicker = require("../Filters/OrgFarmPopPicker.jsx");
var MinWeightFilterNew = require("./../Filters/MinWeightFilterNew.jsx");
var MaxWeightFilterNew = require("./../Filters/MaxWeightFilterNew.jsx");
var NumericInput = require("./../Filters/NumericInput.jsx");
var StartEndDatePicker = require("./../Filters/StartEndDatePicker.jsx");
var Actions = require("./../../actions/Actions.js");
var FileSaver = require("file-saver");
var TranslateStore = require("./../../stores/TranslateStore.js");
var SavedFiltersIndicator = require("./../Filters/SavedFiltersIndicator.jsx");
var t = TranslateStore.getTranslate();
*/

import React from "react";
import {Container, Row, Col, Button, Form, Alert }from "react-bootstrap";
var BT = require("react-bootstrap-table");
import OrgFarmPopPicker from "./../Filters/OrgFarmPopPicker.jsx";
import StartEndDatePicker from "./../Filters/StartEndDatePicker.jsx";
import moment from "moment";
import FilterStore from "../../stores/FilterStore.js";
import PopCompareStore from "../../stores/PopCompareStore.js";
import SessionStore from "./../../stores/SessionStore.js";
import UserDataStore from "./../../stores/UserDataStore.js";
import UptimeStore from "./../../stores/UptimeStore.js";
import MeasurementsStore from "./../../stores/MeasurementsStore.js";
import Spinner from "./../../utils/Spinner.jsx";
import SavedFiltersIndicator from "./../Filters/SavedFiltersIndicator.jsx";
import Actions from "./../../actions/Actions.js";
import FileSaver from "file-saver";
import TranslateStore from "./../../stores/TranslateStore.js";
import VakiAPI from "./../../webservice/VakiAPI.js";
import BootstrapTable from 'react-bootstrap-table-next';
var t = TranslateStore.getTranslate();



export default class Uptime  extends React.Component{

	constructor(props) {
     super(props);
        this.state = {
            Filter: FilterStore.getFilter(),

            data: null,
            loading: false
  
        };

        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }




    this.onUptimeStoreChange = ( newData, filter,  loading)=>{
        
        if (!loading)
            loading = false;

        if(filter != null){
            this.setState({
                Filter: filter
            })
        }

        this.setState({
            data: newData,
            loading: loading
        });




        


    };







    this.onAllFarmsChange = () => {
      
        Actions.uptAllFarmsChange();
    };


/*

                    <BT.BootstrapTable data={thisOne.list} thStyle={{ verticalAlign: 'top', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'red' }}  >

                            <BT.TableHeaderColumn dataField='PopulationName' dataAlign="center" isKey > {t("uptim.population")} </BT.TableHeaderColumn>
                            <BT.TableHeaderColumn dataField='NumberOfFish' dataAlign="right" headerAlign="center" tdStyle={{ paddingRight: '2em' }} > {t("uptim.numfish")}</BT.TableHeaderColumn>
                            <BT.TableHeaderColumn dataField='NumberOfDaysWithFish' dataAlign="center"  > {t("uptim.daysfish")}</BT.TableHeaderColumn>
                      
                            <BT.TableHeaderColumn dataField='NumberOfDaysWithConnection' dataAlign="center"  >{t("uptim.daysconn")}</BT.TableHeaderColumn>
                        
                            <BT.TableHeaderColumn dataField='NumberOfDaysWithAlarm' dataAlign="center"  >{t("uptim.daysvis")}</BT.TableHeaderColumn>
                    </BT.BootstrapTable>

*/



    this.doTheTable = () => {


        var theContent = [];
        var theHeader = "";

	const columns = [
		{
			dataField: 'PopulationName',
			text: t("uptim.population"),
            style: {textAlign:"center"}
		},
		{
			dataField: 'NumberOfFish',
			text: t("uptim.numfish"),
            style: {textAlign:"right"}
		},
		{
			dataField: 'NumberOfDaysWithFish',
			text: t("uptim.daysfish"),
            style: {textAlign:"center"}
   
		},
		{
			dataField: 'NumberOfDaysWithConnection',
			text: t("uptim.daysconn"),
            style: {textAlign:"center"}
		},
		{
			dataField: 'NumberOfDaysWithAlarm',
			text: t("uptim.daysvis"),
            style: {textAlign:"center"}
		}
	];



        if (!this.state.data || !this.state.data.data || this.state.data.data.length === 0)
            return <Alert variant="warning">{t("uptim.nodata")}</Alert>

        if (this.state.data && this.state.data.data != null) {


            var len = this.state.data.data.length;

            for (var i = 0; i < len; i++) {
                var thisOne = this.state.data.data[i]; 

                theContent.push(
                    <div className= "AvoidBreakInside">
                        <br/>
                        <h4> { this.state.data.allFarms?thisOne.FarmName:""}  </h4>

							<BootstrapTable
							
                
								data={thisOne.list}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
								keyField="PopulationName"
							

							/>
                 
                    </div>
                    );
            }



        }

        return theContent;
       

    };

    this. doTheHeader = () => {


        return <div className="text-center">

            <h3> {t("uptim.title")} </h3>
            <h2> {this.state.data.OrgName + (this.state.data.allFarms ? "" : " - " + this.state.data.FarmName)} </h2>
            <h4> {this.state.data.dateSpanString + ",  " + t("uptim.total")+" " + this.state.data.numDays +" " +t("uptim.days")}  </h4>


        </div>;

    };



    };


    componentDidMount ( ) {
        UptimeStore.notifyMount();
        this.unsubscribeUptimeStore = UptimeStore.listen(this.onUptimeStoreChange);
    };
    componentWillUnmount ( ) {
        this.unsubscribeUptimeStore();
    };




	render ( ) {


        var allFarms = UptimeStore.allFarms();

        var theContent = "";
        var theHeader = "";
        let NotAllFarmsIncluded = "";
        if (this.state.data == null && this.state.loading) {
            theContent = <Spinner />;
        } else if (this.state.data) {
            

            theContent = this.doTheTable();
            theHeader = this.doTheHeader();
            if (this.state.data.allFarms && this.state.data.data.length < FilterStore.getNumFarms()  )
                  NotAllFarmsIncluded = <Alert variant="warning"><Alert.Heading>NOTE </Alert.Heading> One or more farms are not listed here as they dont have any data for the given date range  </Alert>



        }

        
       



		return (
            <div>
 
                <div className="hidden-print" >
                <Row>
                    <Col className = "mb-2">
                        <OrgFarmPopPicker filter={this.state.Filter} hidePopulationPicker hideFarmPicker={allFarms} />
                </Col>
                    <Col  className = "mb-2">
    <StartEndDatePicker
startDate={moment(this.state.Filter.startDate)}
startDateActionFn={Actions.startDateUpdate}
endDate={moment(this.state.Filter.endDate)}
endDateActionFn={Actions.endDateUpdate} />





</Col>

                    <Col className = "mb-2">
                        <Form.Check  label={t("uptim.allfarms")}
                            onChange={this.onAllFarmsChange} checked={allFarms} />
                    </Col>

<Col className = "mb-2" >
    <Button
type = "button"
onClick = {Actions.uptFetchReport}
block = {"true"}
variant="outline-dark"
>
{t("uptim.fetch")}

</Button>


</Col>


                    <Col className = "mb-2" >
    <SavedFiltersIndicator />
    </Col>

                </Row>
                </div>
               
               
                {theHeader}

                {theContent}
                {NotAllFarmsIncluded}
                 




			</div>
		);
	}
};

