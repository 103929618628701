/** 
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 *  - Atli Guðlaugsson
 */

'use strict';

import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';
import ReactBootstrap, {
	DropdownButton,
	Dropdown,
	Modal,
	Navbar,
	Container,
	NavDropdown,
	Nav,
	NavLink,
	Row
} from 'react-bootstrap';
import { FlagFill, CaretDownFill, PersonFill, InfoCircleFill } from 'react-bootstrap-icons';
import { Switch, Route, Link, Redirect, generatePath } from 'react-router-dom';

import ReactDOM from 'react-dom';

import Vutil from './../utils/Vutil.js';
import VConst from './../utils/VConst.js';
import TranslateStore from './../stores/TranslateStore.js';
import SessionStore from './../stores/SessionStore.js';
import NavBarStore from './../stores/NavBarStore.js';
import Actions from './../actions/Actions.js';

var t = TranslateStore.getTranslate();
import LanguageSelector from './Language/LanguageSelector.jsx';
import SignoutModal from './Views/SignoutModal.jsx';
import Login from './../components/Login/Login.jsx';
import SimpleFile from './SimpleFile.jsx';

var Dashboard = require('./Dashboard/Dashboard.jsx');
//var Dashboard_Org = require("./../components/Dashboard-Orginal/DashboardOrg.jsx");
var Trendgraph = require('./../components/Trendgraph/Trendgraph.jsx');
import Distribution from './../components/Distribution/Distribution.jsx';
var WeightAndTime = require('./../components/WeightAndTime/WeightAndTime.jsx');

require('./MenuNavigation.css');

var RedirectStore = Reflux.createStore({
	init: function() {
		this.listenTo(Actions.trendgraphDistributionLink, this.onDistributionLink);
		this.listenTo(Actions.showSignoutModal, this.onShowSignoutModal);
		this.listenTo(Actions.popDetailsLink, this.onPopDetailsLink);
		this.listenTo(Actions.redirectToApp, this.onRedirectToApp);


	

		/*
        // Here we listen to Flux actions and register callbacks
            
        this.listenTo(Actions.loginSuccessful, this.onRedirectToApp);
        this.listenTo(Actions.dashboardTrendgraphLink, this.onTrendgraphLink);
       
        this.listenTo(Actions.popDetailsLink, this.onPopDetailsLink);
        this.listenTo(Actions.dashboardToggleDashboard, this.onToggleDashboard);
        this.listenTo(Actions.dashboardShow, this.showDashboard);
        this.listenTo(Actions.menu, this.onMenu);
        this.router = Router.create({
            routes: _routes,
            location: null // Router.HistoryLocation
            //location: Router.HistoryLocation
        });
        */
	},

	onShowSignoutModal: function(show) {
		if (show) {
			this.trigger('SignoutShow');
		} else this.trigger('SignoutHide');
	},

	onDistributionLink: function() {
		this.trigger('/Distribution');
	},

	onPopDetailsLink: function(p) {
		this.trigger('/PopDetails', p);
	},

	onRedirectToApp: function() {
		this.trigger('/');
	}
});

/**
 * Defines the header of the page, navigation and stuff like that.
 */
export default class MenuNavigation extends React.Component {
	//var MenuNavigation = export default class   extends React.Component{
	static propTypes = {
		loggedInUserName: PropTypes.string.isRequired, // the username or email address
		isAdmin: PropTypes.bool // if we should display a link to the Admin View
		//        onSelect: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.state = {
			passwordChangeMsg: {
				showMsg: false,
				successful: undefined
			},
			redirect: '',
			redirectParam: null,
			showSignout: false,
			showLogin: false,
			toggleExpanded: false
		};

		this.onChange = () => {
			this.setState({});
		};

		this.onNavBarStoreChange = (message) => {
			this.setState({ passwordChangeMsg: message });
		};

		this.onRedirectStoreChange = (msg, param) => {
			if (msg === 'SignoutShow') {
				this.setState({ showSignout: true });
			} else if (msg === 'SignoutHide') {
				this.setState({ showSignout: false });
			} else if (msg === '/PopDetails') {
				this.setState({ redirect: msg, redirectParam: param });
			} else {
				this.setState({ redirect: msg });
			}
		};

		this.onSessionStoreChange = () => {
			
            if (SessionStore.isLoggedOut ()){
                Actions.redirectToLogin();                

            }
            
		};

		this.setEnglish = () => {
			Actions.setEnglishTranslation();
		};

		this.setGerman = () => {
			Actions.setGermanTranslation();
		};

		this.setNorwegian = () => {
			Actions.setNorwegianTranslation();
		};

		this.setSpanish = () => {
			Actions.setSpanishTranslation();
		};

		this.showSignoutModal = () => {
			Actions.showSignoutModal(true);
		};

		this.onDropDownSelect = () => {
			//Generate click to get dropdown to close...s
			document.dispatchEvent(new MouseEvent('click'));
		};


		this.handleToggle = ( e ) => {
		let ttt = !this.state.toggleExpanded;
		this.setState ({toggleExpanded: ttt});
//alert (document.getElementById("TheToggler"));
//document.getElementById("TheToggler").click();

		}


		this.handleNavbarClick = ( e ) => {

			if (!e.target.id || !this.state.toggleExpanded)
				return;
			if ((typeof e.target.id) == "string"){
				if (e.target.id.length > 4){
					if (e.target.id.substring(0,4)=="Link"){
						document.getElementById("TheToggler").click();  //Close the hamburger dropdown
					}
				}
			}	


		}


	}

	componentDidMount() {
		this.unsubscribeTranslateStore = TranslateStore.listen(this.onChange);
		this.unsubscribeNavBarStore = NavBarStore.listen(this.onNavBarStoreChange);
		this.unsubscribeRedirectStore = RedirectStore.listen(this.onRedirectStoreChange);
		this.unsubscribeSessionStore = SessionStore.listen(this.onSessionStoreChange);
	}

	componentWillUnmount() {
		this.unsubscribeTranslateStore();
		this.unsubscribeNavBarStore();
		this.unsubscribeRedirectStore();
		this.unsubscribeSessionStore();
	}

	removeDropdownList() {
		// Problem fixes itself
	}



	testLink() {
		Actions.dashboardShow();
	}



	render() {
		// By default assume user is not admin, add link if he is

		//                               <Dropdown.Item onSelect={this.removeDropdownList}> <Link to="popcompare">{t("navbar.popcompare")}</Link> </Dropdown.Item>

		// <Dropdown.Item  onSelect={this.removeDropdownList} > <Link to="priceedit" >{"PriceTest"}</Link> </Dropdown.Item>

		const { redirect, showSignout, showLogin, redirectParam } = this.state;

		let TheRedirect = '';

		if (this.state) {
			this.state.redirect = '';
			this.state.redirectParam = null;
		}

		/*
redirectParam: {
 "FrameSerialNumber": 1523162,
 "FrameType": "FR800",
 "HoursSinceLastData": 2,
 "HoursSinceLastFish": 2,
 "LastConnectionStatus": "OK",
 "LastDataLocalTime": "/Date(1636887078000+0000)/",
 "LastFishLocalTime": "/Date(1636886830000+0000)/",
 "LastFrameVisibility": "OK",
 "NumberOfFish24Hr": 1378,
 "NumberOfFishToday": 508,
 "PopDayStatus": {
  "EndDate": "/Date(1636848000000+0000)/",
  "Reason1": 0,
  "Reason2": 10,
  "RunState": 0,
  "SGR": 0.66,
  "SegmentID": 1,
  "StartDate": "/Date(1634342400000+0000)/",
  "StarvingDate": null,
  "TrendValEnd": 4014,
  "TrendValStart": 3316,
  "WarningLevel": 5,
  "Weights": "3311;3331;3339;3363;3379;3397;3405;3444;3451;3549;3591;3651;3701;3716;3737;3768;3797;3821;3850;3841;3847;3830;3856;3871;3895;3929;3931;3922;3925;3933",
  "cv": 0.17,
  "cvAvgWgt": 3932,
  "cvNumFish": 3965,
  "displayWgt": 3973
 },
 "PopulationID": 8562,
 "PopulationName": "FM8"
}
*/
		if (redirect !== '') {
			if (redirect === '/PopDetails') {
				let pth = redirect + '/:popid';
				TheRedirect = <Redirect to={generatePath(pth, { popid: redirectParam.PopulationID })} />;
			} else TheRedirect = <Redirect to={redirect} />;
		}

		let TheSignout = '';
		if (showSignout) {
			TheSignout = (
				<SignoutModal userName={this.props.loggedInUserName} pwChangeMsg={this.state.passwordChangeMsg} />
			);
		}

		//var TheLanguageMenu= "";

		var userName = Vutil.shortenEmailIfNeeded(this.props.loggedInUserName, VConst.MAX_USERNAME_LENGTH);

		var hideDistr = true;

		var MenuStripClass = 'navbar-collapse collapse  ';

		let adminViewLink = '';
		let cameraMgmtLink = '';
		let manualLinksLink ='';

		if (this.props.isAdmin) {
			adminViewLink = (
				<Nav.Item key="AdminView"  >
					{' '}
					<Link to="/AdminView" className="more-link" id="LinkAdminView">
						{t('navbar.adminview')}
					</Link>
				</Nav.Item>
			);

			cameraMgmtLink = (
				<Nav.Item key="CameraManagement">
					{' '}
					<Link to="/CameraManagement" className="more-link" id="LinkCameraManagement">
						{t('navbar.cameramgmt')}
					</Link>
				</Nav.Item>
			);

			manualLinksLink = (
				<Nav.Item key="ManualLinks">
					{' '}
					<Link to="/ManualLinks" className="more-link" id="LinkManualLinks">
						{"Document management"}
					</Link>
				</Nav.Item>
			);

		}




		return (
			<div id="TheMenu">
			
				<Navbar variant="dark" bg="dark" expand="xl"   fixed="top"   collapseOnSelect={true}   onClick={ this.handleNavbarClick }>
					<Container>
						<Navbar.Brand href="/">
							<img
								src="img/vaki_logo_no_text.png"
								width="30"
								height="30"
								className="d-inline-block align-top"
							/>
						</Navbar.Brand>
						<Navbar.Brand href="/">Biomass Daily</Navbar.Brand>
						<Navbar.Toggle  id = "TheToggler" aria-controls="basic-navbar-nav"  onClick = {this.handleToggle} />
						<Navbar.Collapse id="basic-navbar-nav"  >
							<Nav className="me-auto"  >
								<Nav.Item>
									<Link to="/Dashboard"  id="LinkDashboard"> {t('navbar.dashboard')} </Link>
								</Nav.Item>
								<Nav.Item  >
									<Link to="/Trendgraph"   id="LinkTrendgraph"  >{t('navbar.trendgraph')}</Link>
								</Nav.Item>
								<Nav.Item>
									<Link to="/Distribution"  id="LinkDistribution"> {t('navbar.distribution')}</Link>
								</Nav.Item>
								<Nav.Item>
        


									<Link to="/WeightAndTime"  id="LinkWeightAndTime" > {t('navbar.weightandtime')}</Link>
								</Nav.Item>
								<Nav.Item>
									<NavDropdown
										title={t('navbar.more')}
										id="more-dropdown"
										onClick={this.onDropDownSelect}
									>






										<Nav.Item key="RepSubscribe">
											{' '}
											<Link to="/RepSubscribe" className="more-link" id="LinkRepSubscribe" >
												{t('navbar.repsub')}
											</Link>{' '}
										</Nav.Item>

										<Nav.Item key="Measurements">
											{' '}
											<Link to="/Measurements" className="more-link" id="LinkMeasurements">
												{t('navbar.measurements')}
											</Link>
										</Nav.Item>

										<Nav.Item key="HourDistribution">
											{' '}
											<Link to="/HourDistribution" className="more-link" id="LinkHourDistribution">
												{' '}
												24-hour Distribution
											</Link>
										</Nav.Item>

										<Nav.Item key="JoinPops">
											{' '}
											<Link to="/JoinPops" className="more-link" id="LinkJoinPops">
												{t( 'navbar.joinpop')}
											
											</Link>
										</Nav.Item>

										<Nav.Item key="Uptime">
											{' '}
											<Link to="/Uptime" className="more-link" id="LinkUptime">
												{t('navbar.uptime')}
											</Link>
										</Nav.Item>


										{manualLinksLink}
										{cameraMgmtLink}
										{adminViewLink}
									</NavDropdown>
								</Nav.Item>
							</Nav>

							<Nav className="ml-auto">
								<NavDropdown
									title={<FlagFill />}
									id="basic-nav-dropdown"
									onClick={this.onDropDownSelect}
								>
									<Nav.Item onClick={this.setEnglish} className="lang-link" id="LinkEnglish" >
										{' '}
										English{' '}
									</Nav.Item>
									<Nav.Item onClick={this.setNorwegian} className="lang-link" id="LinkNorwegian">
										{' '}
										Norwegian{' '}
									</Nav.Item>
									<Nav.Item onClick={this.setSpanish} className="lang-link" id="LinkSpanish">
										{' '}
										Spanish{' '}
									</Nav.Item>
								</NavDropdown>

								<Nav.Item
									onClick={() => {
										Actions.showSignoutModal(true);
									}}
									className="user-button"
									id="LinkSignout"
								>
									{' '}
									{userName} <PersonFill />
								</Nav.Item>
								<NavDropdown title={<InfoCircleFill />} onClick={this.onDropDownSelect}>
									<Nav.Item key="QA" style={{ color: 'red' }}>
										{' '}
										<Link to="/QA" className="info-link"  id="LinkQA">
											{' '}
											Q&A
										</Link>
									</Nav.Item>
									<Nav.Item key="Manuals">
										{' '}
										<Link to="/Manuals" className="info-link" id="LinkManuals">
											{' '}
											Manuals
										</Link>
									</Nav.Item>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				{TheSignout}
				{TheRedirect}
			
			</div>
		);
	}
}
