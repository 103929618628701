/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";


var Reflux = require("reflux");
var jwt = require("jsonwebtoken");
var moment = require("moment");
var request = require("superagent");

var Actions = require("./../actions/Actions.js");
var VConst = require("./../utils/VConst.js");

/**
 * Tries to get a JSON Web Token from Vaki API for a given username and
 * password.
 *
 * Note 1: Originally I intended for the functionality to be located in
 * VakiAPI.js. I was running into an issue with circular dependencies.
 * Ideally I just wanted to have VakiAPI query the SessionStore for the token
 * so I wouldn't have to pass it in all the time and keep track of it somewhere
 * else. So this code is here to prevent circular dependencies. It's not ideal
 * and I feel a huge code smell of this but since it's only one method I let it
 * slip.
 *
 * Note 2: Up until this point we had been doing synchronous AJAX calls for
 * simplicity sakes while getting off the ground. However that is not the
 * proper thing to do because if the latency is high all user interactions
 * would be blocked until the request finishes. This was not a problem
 * with the server few meters away from us.
 *
 * When we started to tackle proper authentication I (jónsi) decided to do
 * proper asynchronous AJAX calls. This is primarily because after the
 * authentication part was in place we would move the testing environment
 * to Amazon. This means latency would increase and usability would suffer.
 *
 * Doing things asynchronously is pretty new to me (jónsi) so I'm not
 * entirely sure what the most suitable architecture/patter/flow would be.
 * In particular, what should we do when a result or an error comes
 * through? I researched it a bit and found three primary ways to go about
 * it:
 *
 *  * Callbacks, the muddy road
 *  * Promises, the paved tarmac
 *  * RxJS observable, the highway
 *  * Reflux actions, the existing gravel road
 *
 * Callbacks is out of the question, it's very well established in the
 * industry that callbacks, although handy, tend to grow into an
 * unmaintainable mess. Promises are well established in the JS community
 * to deal with single result asynchrony. Popular libraries include Q and
 * Bluebird for example. Promises are also a standard in ES6. There are
 * some really clever people in the JS community that believe that Promises
 * are not the forward looking thing to handle asynchrony. RxJS is a set of
 * libraries to bring more functional tools/paradigms to JavaScript. One
 * of the major things are the Observable (proposed for ES7). An Observable
 * is basically an object that notifies its subscribers when it changes.
 * Formally speaking Promises are a type of Observable. Although I (jónsi)
 * firmly believe that the future of JavaScript will be in the direction
 * RxJS is taking I think it's premature to embark on it now.
 *
 * Instead what I opted for was the dirty gravel road that we already got;
 * Reflux actions. To me using Reflux actions like this is a bit of a code
 * smell. However it does fit pretty decently in the way the page
 * fundamentally operates, reacting to actions (events). I chose to go for
 * this the gravel road because as nice it would be to ride on the paved
 * tarmac or even highway, we don't have the time to build it at the moment.
 *
 * @param username {string}
 * @param password {string}
 */

function _fetchUserLoginTokenRequest(username, password) {
    try {

        request

            .get(VConst.APIURL + "UserLogin")

            .auth(username, password)

            .accept("application/json")

            .end(function (error, result) {

                if (result.ok) {
                   
                    var jsonWebToken = result.body;
                    Actions.loginSuccessful(jsonWebToken);
                    
                } else if (error && error.status === 401) {
                    // If response is HTTP code 401, wrong user/pass
                  
                    Actions.loginWrongUserPass(username);
                  
                } else {
                    // Here is a good opportunity to log exceptions if we get
                    // ever get around to it.
                    console.warn(error.status + "  2:    " + error);
                    console.warn(error.stack);
              
                    Actions.apiError();
                }
            });

    }catch(er){console.error ("_fetchUserLoginTokenRequest  Exc:"+er  )}
}

/**
 * A Reflux store that handles our JSON Web Token (JWT) and indicates if the
 * user is authenticated or not.x
 *
 * Note: SessionStore might not be the most appropriate name. We are in a way
 * storing information about a session but it should not be confused with a
 * traditional cookie session.
 */
var SessionStore = Reflux.createStore({
    init: function() {
        // Here we listen to actions and register callbacks
        this.listenTo(Actions.loginAttempt, this.onLoginAttempt);
        this.listenTo(Actions.loginSuccessful, this.onLoginSuccessful);
        this.listenTo(Actions.loginWrongUserPass, this.onLoginWrongUserPass);
        this.listenTo(Actions.signOut, this.onSignOut);
    },

    onLoginAttempt: function(username, password) {
        _fetchUserLoginTokenRequest(username, password);
    },

    onLoginSuccessful: function (jsonWebToken) {
        this.setToken(jsonWebToken);
        this.trigger();
    },

    onLoginWrongUserPass: function(username) {
        this.trigger(VConst.WRONG_USER_OR_PASS);
    },

    onSignOut: function() {
        this.setToken(undefined);
        this.trigger();
    },

    /**
     * Returns true if the variable "token" in HTML5 localStorage contains a
     * well formatted JSON Web Token and it hasn't expired yet. Extra.
     *
     * @returns {boolean}
     */
    isLoggedIn: function() {
        try {
            if (this.isWellStructuredJWT(this.getToken())) {
                var tokenPayload = jwt.decode(this.getToken());
                var tokenExpiryDateTime = moment.unix(tokenPayload.exp);
                var now = moment();

                if (tokenExpiryDateTime.isAfter(now)) {
                    return true;
                }
            }
        }catch (er){alert ("isLOggedIn Exc: "+er);}
        return false;
    },

    /**
     * Convenience method to check if the user is logged out or not. I mostly
     * created this method to be able to write more readable code. Instead of:
     *
     *  if (!SessionStore.isLoggedIn()) {code to handle log out}
     *
     * we can now write:
     *
     *  if (SessionStore.isLoggedOut()) {code to handle log out}
     *
     * @returns {boolean} true if the user has been logged out, false otherwise
     */
    isLoggedOut: function() {
        return this.isLoggedIn() ? false : true;
    },

    /**
     * Returns the username of the currently logged in user. Usernames are
     * usually email addresses. If there is no logged in user undefined is
     * returned.
     *
     * @returns {string} or {undefined}
     */
    getLoggedInUsername: function() {
        if (this.isLoggedIn()) {
            var tokenPayload = jwt.decode(this.getToken());
            return tokenPayload.unique_name;
        } else {
            return undefined;
        }
    },

    /**
     * Returns true if provided JSON Web Token string is of the expected
     * Header.Payload.SigValue format. Note that JWT strings are base64 url
     * encoded.
     *
     * Note: this method is pretty crude, it could be refined to check if the
     * 3 parts of the token (header, payload, signature) are actually well
     * structured as well. It was however enough for me at the time of writing.
     *
     * @param jsonWebToken {string}
     * @returns {boolean}
     */
    isWellStructuredJWT: function(jsonWebToken) {
        // Regular expression that matches Header.Payload.SigValue
        var regEx = /^([^.]+)\.([^.]+)\.([^.]+)$/;
        var result = jsonWebToken.match(regEx);

        // If regEx matches, return true, false otherwise
        return result ? true : false;
    },

    /**
     * Returns currently set token in localStorage, empty string if it's
     * undefined so subsequent Regular Expressions don't freak out.
     *
     * @returns {string}
     */
    getToken: function() {
        return localStorage.token || "";
    },

    setToken: function(jsonWebToken) {
        localStorage.token = jsonWebToken;
    }
});

module.exports = SessionStore;