"use strict";



var React = require("react");
//var ReactDOM = require ("react-dom");
import {Row, Col, Container, Button} from  "react-bootstrap";


var Actions = require("./../../../actions/Actions.js");
var VConst = require("./../../../utils/VConst.js");
var CountriesFilterStore = require("./../../../stores/CountriesFilterStore.js");
var UserDataStore = require("./../../../stores/UserDataStore.js");
var TranslateStore = require("./../../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
var Spinner = require("./../../../utils/Spinner.jsx");

var SessionStore = require("./../../../stores/SessionStore.js");


//require('react-ui-tree/dist/react-ui-tree.css');


require('./CountriesFilter.css');

//require("./file.less");

/*
require('react-ui-tree/example/app.less');
*/

/**
 * A filter that offers a start and end date. Useful when we have to select
 * some range of dates. It's mostly a thin wrapper on top of the third party
 * React component react-bootstrap-daterangepicker, which is in turn a port of
 * another library.
 *
 * The component takes in as props startDate and endDate. Those are expected
 * to be moment objects, for docs please see: http://momentjs.com/
 *
 * As well as start and end date the component receives Reflux action
 * functions as parameters. This allows us to reuse this component in many
 * different reports.
 */
 export default class CountriesFilter extends React.Component{
//var CountriesFilter = export default class   extends React.Component{


    constructor(props) {
     super(props);
        this.state = {
            _myCountries: CountriesFilterStore.myCountries(),
            _isLoading: CountriesFilterStore.isLoading(),


        };

  /**********componentWillMount() {*******************/
        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }

        CountriesFilterStore.notifyMounting();
/************************************************** */

    this.onCountriesFilterStoreChange = (myCountries, isLoading) => {


        if (!myCountries)
            myCountries = [];

        if (!isLoading)
            isLoading = false;

        this.setState({
                _myCountries: myCountries,
                _isLoading: isLoading
            });

    };


    };




  

    componentDidMount() {

        this.unsubscribeCountriesFilterStore = CountriesFilterStore.listen(this.onCountriesFilterStoreChange);
//        this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
 //       Actions.popCompareDoneLoading();
    };




    componentWillUnmount() {

        this.unsubscribeCountriesFilterStore();
  //      this.unsubscribeTranslateStore();
    };






    shouldComponentUpdate(nextProps, nextState) {




        return true;
    };




    /* to prevent dragging of selected nodes
     e.stopPropagation() from onMouseDown like this

     renderNode(node) {
     return (
     <span
     className={'node' +(node === this.state.active ? ' is-active' : '')}
     onClick={this.onClickNode.bind(null, node)}
     onMouseDown={node.undraggable ? function(e){e.stopPropagation()} : undefined}
     >
     {node.module}
     </span>
     )
     }



     */



onAddClick () {

    var opts = document.getElementById ("selCountries");
    var len = opts.length;
    var sel = [];
    for (var i=0; i<len; i++){
        if (opts[i].selected){
            sel.push ({value: opts[i].value, text: opts[i].text})
        }
    }
    Actions.cntryAdd(sel);


    $("#selCountries").find('option').attr("selected",false) ;

};


        onDelClick (){
            var lst = document.getElementById ("selMyCountries");
            var len = lst.length;
            var sel = [];

            for (var i = 0; i<len; i++){
                if (lst[i].selected) {
                    sel.push({value: lst[i].value, text: lst[i].text});

                }
            }

            Actions.cntryDel(sel);

            $("#selMyCountries").find('option').attr("selected",false) ;
        };






render () {


    if (!UserDataStore.IsEmployee())
        return <div/>;
    var vv = CountriesFilterStore.countries();



    if (this.state._isLoading){

        return <Spinner/>
    }

    var selOptions = vv.map(function(vv, i) {
        return (
            <option value={vv.value} key={i}> {vv.text}  </option>
);

});


var myC = this.state._myCountries.map(function(vv, i) {
    return (
        <option value={vv.value} key={i}> {vv.text}  </option>
);

});






    return (


            <div>

<p>Limit the number of accessible farms by filtering in farms in specific countries only </p>

        <Container >
        <Row>
        <Col  className="cols" >

        <select multiple size={6} className="countrySel" id="selCountries">
        {selOptions}
        </select>




        </Col>





        <Col className="cols" >
    <select multiple size={6} className="countrySel"  id="selMyCountries">
    {myC}
    </select>
        </Col>


        </Row>

    <Row>
    <Col className="cols" >

    <Button
type = "button"
onClick = {this.onAddClick}
block = "true"
>
Add selected
</Button>



    </Col>





    <Col  className="cols" >
    <Button
type = "button"
onClick = {this.onDelClick}
block = "true"
>
Remove selected
</Button>
    </Col>


    </Row>

        </Container>


<p>Use [Shift] or [Ctrl] to select multiple countries <br/>
No countries selected = No country filtering <br/>
This country filter is only accessible for BMD admins</p>




        </div>





)
}
};

//module.exports = CountriesFilter;