/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var Reflux = require("reflux");
var Immutable = require("immutable");
var ParMan = require("./ParMan.js");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var _userData = Immutable.Map({});
var _showInactive = false;
var moment = require("moment");
var userJustLogged = false;

var ServerTimeOffset = 0;



const showInactive_ID = "UDS_ShowInactive";



var UserLoaded = false;


/**
 * UserData stores things like what organisations, farms, populations,
 * preferences, and features the user has access to.
 */
var UserDataStore = Reflux.createStore({
    init: function () {

       
        _showInactive = ParMan.initParameter(showInactive_ID, false, 'bool');

        
        this.listenTo(Actions.loginSuccessful, this.onLoginSuccessful);
        this.listenTo(Actions.apiNewUserData, this.onApiNewUserData);
        this.listenTo(Actions.reloadUserData, this.onReloadUserData);
        this.fetchUserData();
    },



    postPageHit: function (pageID) {
        var param = {
            PageID: pageID,
            UserKind: _userData.get("CountryID")
        };

        VakiAPI.PostPageMount(param);


    },


    isUserLoaded: function () {
        return UserLoaded;
    },

    onReloadUserData: function(JustReloading) {
        
        if (!JustReloading) {
            _showInactive = !_showInactive;
            ParMan.setItem(showInactive_ID, _showInactive);
        }
        this.fetchUserData();
    },


    onLoginSuccessful: function () {
        userJustLogged = true;
   
        this.fetchUserData();
    },

    onApiNewUserData: function (newUserData) {

        var s = newUserData.ServerDateTime;




          

            var diff = moment(s).diff(moment());
            ServerTimeOffset = Math.round(diff / 3600000);

  

            _userData = Immutable.Map(newUserData);
            UserLoaded = true;

        if (!this.showExtraDash()) {
            window.localStorage.setItem("TheLatestDashUsed", 1);
        }
      

        this.trigger(_userData, false);
    },




    showExtraDash: function () {


        return true;
        /*
        var cid = _userData.get("CountryID");
        var ie = _userData.get("IsEmployee");



        // Norway: 169
        // uk:79
        //Canada:39 
        if (ie || cid == 169 || cid == 39){
            return true;
        }
        return false;
        */
    },

    showInactive: function(){
        return _showInactive;
    },
    /**
     * Returns true if the user has the admin roles, false otherwise. Admin
     * roles grants for example access to the Admin View.
     */
    isUserAdmin: function() {

        return _userData.get("IsAdmin");
    },

    IsEmployee: function() {
        return _userData.get("IsEmployee");
    },

    getOrgFromID: function (oid)
    {
        var o = null;

        var orgs = _userData.get("OrganisationList");
        if (orgs) {
            var len = orgs.length;
            for (var i in orgs){
                if (orgs[i].OrganisationID == oid){

                    o = orgs[i];
                    break;
                }
            }
        }
        return o;
    },

    getServerTimeOffsetHours: function () {
        return ServerTimeOffset;
    },

    getUserName: function() {
        return _userData.get("UserName");
    },

    fetchUserData: function () {


this.trigger(null, true); //notify start loading
        
        VakiAPI.fetchUserData(_showInactive);
    },

    userJustLoggedIn: function (doResetOnTrue) {
        var b = userJustLogged;

        if (b && doResetOnTrue)
            userJustLogged = false;
        return b;
    }

});

module.exports = UserDataStore;