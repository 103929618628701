/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 *
 * Note that this file was originally forked from DashboardCanvas.jsx so there
 * is some duplicate code going around. We did create a file to keep code that
 * can be commonly used by both; DashboardDrawUtils.js. It could be refined
 * even further but we decided to have duplicate methods where the integration
 * with the constants in the document was too deep, as we were really strapped
 * for time. Both going to school in few days ヽ(。_°)ノ
 */

"use strict";

var React = require("react");
var ReactDOM = require("react-dom");
var Immutable = require("immutable");

var DashboardDrawUtils = require("./DashboardDrawUtils.js");
var Vutil = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var FilterStore = require("./../../stores/FilterStore.js");
var Actions = require("./../../actions/Actions.js");
var VConst = require("./../../utils/VConst.js");
var _stage = {};

// The radius of a population, static for now, should prolly make fluid
var _radius = 20;
// This specifies the amount of space in pixels that should be between each
// population, center to center. This distance is a somewhat optimal distance
// in a way that the tangent of two adjacent circles meet in way that looks
// nice. For an explanation see: http://imgur.com/YTgfFSh
var _spacer = 2 * _radius * Math.sqrt(2);
// How many pixels do we want to have clear as a margin on the canvas
var _margin = 8;
// How many populations are allowed on even numbered lines in the Dashboard
var _popsPerLine = 10;





function createPopBlock(p, i, cDiv, farmName) {

    var iDiv = document.createElement('div');


    iDiv.id = farmName+"_"+ p.populationName;
    iDiv.className = 'popSmBlock';

   
    if (p.NumberOfFish24Hr === 0) {
        iDiv.style.backgroundColor = VConst.COLORS.POP_RED;
        iDiv.style.borderColor = VConst.COLORS.POP_RED_BORDER;
        // style.backgroundGradient = redGradient;

    } else if (p.NumberOfFish24Hr > 0 && p.NumberOfFish24Hr < 100) {
        iDiv.style.backgroundColor = VConst.COLORS.POP_YELLOW;
        iDiv.style.borderColor = VConst.COLORS.POP_YELLOW_BORDER;
        //style.backgroundGradient = yellowGradient;

    } else {
        iDiv.style.backgroundColor = VConst.COLORS.POP_GREEN;
        iDiv.style.borderColor = VConst.COLORS.POP_GREEN_BORDER;
    }

    var title = document.createElement('div');
    title.className = "clsSmTitle";
    title.innerHTML = p.PopulationName;



    var population = new FilterStore.getPopulationByID(p.PopulationID);

    iDiv.appendChild(title);
    iDiv.addEventListener('click', function (e) {
       
        Actions.populationClick(population);


        // delete the column here
    });
    cDiv.appendChild(iDiv);

}



function createDashboardCanvas(domNode, populationStatusList, thisFarm) {
    if (populationStatusList) {

        var theDiv = document.getElementById(("div" + thisFarm))
        theDiv.innerHTML = "";
        // Draw all the populations to the stage
        for (var i = 0; i < populationStatusList.length; i++) {
            createPopBlock(populationStatusList[i], i, theDiv, "farmName")
        }

        
    }
}






/**
 * React component that displays a minified version of the Dashboard for a farm.
 * This is used to show a dashboard overview for all the farms the user has
 * access to. In this view the populations are made smaller and only contain
 * minimal info to assess the general status of the farm.
 */
export default class DashboardCanvasList  extends React.Component{

    componentDidMount () {

        var domNode = ReactDOM.findDOMNode(this);
        createDashboardCanvas(domNode, this.props.populationStatusList, this.props.thisFarm);
    };

    shouldComponentUpdate ( nextProps, nextState) {
        var currentPropsImm = Immutable.fromJS(this.props.populationStatusList);
        var nextPropsImm = Immutable.fromJS(nextProps.populationStatusList);
        var sameFarm = Immutable.is(currentPropsImm, nextPropsImm);

        if (sameFarm) {
            // Same farm, no need to update
            return false;
        } else {


            return true;
        }
    };

    /**
     * Called after the render function has completed. That means the <canvas>
     * element has been created so we can now draw to it anew.
     */
    componentDidUpdate ( previousProps, previousState) {
        var domNode = ReactDOM.findDOMNode(this);
        createDashboardCanvas(domNode, this.props.populationStatusList, this.props.thisFarm);

    };

    /**
     * Minor convenience method, returns true if we received props, false
     * otherwise. This might be done better by actually checking the contents
     * of the object and see if there is actually anyting in it. But will do
     * for now. Just be careful.
     *
     * @returns {boolean}
     */
    statusListNotEmpty ( ) {
        return this.props.populationStatusList ? true : false;
    };

    render () {

        var theID = "div" + this.props.thisFarm


        return (
            <div  id={theID}  className ="clsSmContainer"></div>
        )
    }
};


