

"use strict";

import  React from "react";
import { Alert } from 'react-bootstrap';

import moment from "moment";
import VakiAPI from "../../webservice/VakiAPI";
import vutil from "../../utils/Vutil";
import FilterStore from "../../stores/FilterStore";
import SessionStore from "./../../stores/SessionStore.js";
import DistributionStore from "./../../stores/DistributionStore.js";
import OrgFarmPopPicker from "../Filters/OrgFarmPopPicker.jsx";
import MinWeightFilter from "./../Filters/MinWeightFilter.jsx";
import MaxWeightFilter from "./../Filters/MaxWeightFilter.jsx";
import MyPanel from "../../utils/MyPanel.jsx"



import Actions from "./../../actions/Actions.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import SavedFiltersIndicator from "./../Filters/SavedFiltersIndicator.jsx";
import BGTools from "./../../utils/BGtools.js";

import Immutable from "immutable";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import CF_Chart from "./CF_Chart.jsx";
import CF_ChartStore from "../../stores/CF_ChartStore";
import Spinner from "./../../utils/Spinner.jsx";

var t = TranslateStore.getTranslate();




require("./Distribution.css")

export default class DistributionSummary  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            filter: FilterStore.getFilter(),
            distributionFilter: DistributionStore.getFilter(),
            distributionsData: DistributionStore.getDistributionsData(),
            loading: false

        };

/****************componentWillMount ( )****************** */
         
        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }
/****************************************************** */

    this.onCommonFilterChange = ( filter) => {

        this.setState({filter: filter});

    };

    this.onTranslateStoreChange = ( ) => {
        this.setState({ });
    };

    this.onDistributionFilterChange = ( newDistData, loadingMsg) => {


        var load = true;
        if (!loadingMsg)
            load = false;



        if (!newDistData){
            this.setState({
                distributionsData: null,
                loading: load
            });
        }else {
            this.setState({
                distributionFilter: newDistData.get("filter"),
                distributionsData: newDistData.get("distributionsData"),
                loading: load
            });
        }

    };

    };//constructor


    componentDidMount () {

        this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
        this.unsubscribeFilterStore = FilterStore.listen(this.onCommonFilterChange);
        this.unsubscribeDist = DistributionStore.listen(this.onDistributionFilterChange);
    };


    componentWillUnmount ( ) {
        this.unsubscribeTranslateStore();
        this.unsubscribeFilterStore();
        this.unsubscribeDist();
    };




    summaryPair (label1, value1, label2, value2) {


        return <div className="clsSummaryPairWrap">

            <div className="clsSummaryLabel">
                {label1}
            </div>
            <div className="clsSummaryValue">
                {value1}
            </div>

            <div className="clsOverUnderSpace">
                <div className="clsSummaryLabel">
                    {label2} 
                </div>
                <div className="clsSummaryValue">
                    {value2}
                </div>
            </div>
        </div>;



                 


    };

    render () {



        var distributionsData = this.state.distributionsData;
        var dataOK = !(!distributionsData);
        if (dataOK)
            dataOK =  ((distributionsData.get("NumFish") > 0) && (distributionsData.get("AverageLiveWeight") > 0));


        var displayDate = this.state.distributionFilter.get("displayDate");
        var guttingFactor = this.state.distributionFilter.get("guttingFactor");
        var populationSize = this.state.distributionFilter.get("populationSize");

        var renderStuff = null;
        var renderStuff3 = null;






        var txtGutted = "";
        var valGutted = "";
        var bGutted = true;
        if (guttingFactor == null || guttingFactor == 0)
            bGutted = false;

        var vArr = [];

        var txtTotalBiomass = "";
        var valTotalBiomass = "";

        if (distributionsData && populationSize && populationSize > 0){
            txtTotalBiomass = t("distribution.totalBiomass");
            var avg = distributionsData.get("AverageLiveWeight");
            var tot = Math.round( avg*populationSize / 1000);
            valTotalBiomass = BGTools.formatThousandSep(tot)+" kg";
        }


        if (this.state.loading) {
            renderStuff = ( < Spinner />)  ;


        } else if (!dataOK) {

           renderStuff = ( < Alert   variant = "warning" > {t("distribution.noData")} < / Alert >) ;

        }else {


            var dateStr = moment.utc(displayDate).format ("YYYY-MM-DD");






            if (bGutted) {
                txtGutted = t("distribution.avgGuttedWgt");
                valGutted = BGTools.formatThousandSep(distributionsData.get("AverageCorrectedWeight")) + "g";
            }

            var len = distributionsData.get("WeightHistogramList").length;
            for (var i = 0; i < len; i++)
                               vArr.push(distributionsData.get("WeightHistogramList")[i])
               

//<div  className = "well well-sm" >

            renderStuff = 
               <div>

                  <MyPanel >
                
                    <strong> {this.state.filter.selectedOrganisation.Name} / {this.state.filter.selectedFarm.Name} / {this.state.filter.selectedPopulation.Name}. </strong> 

                    <div className="clsFiltFlex"  >
                           {this.summaryPair(t("distribution.sampleDate"), displayDate, t("distribution.sampleSize"),
                               BGTools.formatThousandSep(distributionsData.get("NumFish")))}

                           {this.summaryPair(t("distribution.avgLiveWgt"), BGTools.formatThousandSep(distributionsData.get("AverageLiveWeight"))+"g",
                               t("distribution.cv"), distributionsData.get("CV") )}

                           {this.summaryPair(t("distribution.minWeight"), BGTools.formatThousandSep(distributionsData.get("MinWgtFilter")) + "g",
                               t("distribution.maxWeight"), BGTools.formatThousandSep(distributionsData.get("MaxWgtFilter")) + "g")}

                           {this.summaryPair(t("distribution.avgCF"), distributionsData.get("AvgCF"),
                               t("distribution.guttingFactor"), guttingFactor + "%")}



                           {this.summaryPair(t("distribution.popSize"), BGTools.formatThousandSep(populationSize),
                               t("distribution.numExcluded"), BGTools.formatThousandSep(distributionsData.get("ExcludedFish")) )}  

                           {this.summaryPair(txtTotalBiomass,valTotalBiomass, txtGutted,valGutted)}

                                         
                                         

                   </div>
                   </MyPanel >
                   </div>



        }



        return (
               <div>
                 {renderStuff}
    </div>

        )
    }
};

/*
 <LiveWeightTable weightHistogramList={distributionsData.get("WeightHistogramList")} NumFishes = {populationSize}  t={t} />

 */

