/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var Reflux = require("reflux");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var UserDataStore = require("./UserDataStore.js");


var _username = "";
var _userPreferences = { };

var NavBarStore = Reflux.createStore({
  

    init: function() {
        this.listenTo(Actions.changePasswordSubmit, this.changePassword);
        this.listenTo(Actions.apiPasswordChanged, this.onPasswordChangeReply);
        this.listenTo(Actions.saveFilters, this.onSaveFilters);
        this.listenTo(Actions.showAllOrgs, this.onShowAllOrgs);
        this.listenTo(Actions.uniformScaling, this.onUniformScaling);
        this.listenTo(Actions.setDefaultFarm, this.onSetDefaultFarm);
        this.listenTo(UserDataStore, this.onUserDataStoreUpdate);
       
    },

    getUserName: function () {
        return _username;
    },
    onUserDataStoreUpdate: function(userData) {
        if (!userData)
            return;

        _username = userData.get("UserName");

        /* Checking if filters have been saved for this user */
        if(typeof window.localStorage !== undefined) {
            var obj = window.localStorage.getItem(_username);
            _userPreferences = JSON.parse(obj) || {};
            



        }
    },

    /*
    * Sets a new password for this user if the old password is correct 
    */
    changePassword: function(oldPassword, newPassword) {
        var passwordData = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        VakiAPI.changeUserPassword(passwordData);
    },

    /* Triggers to show a message whether the action was successful or not */
    onPasswordChangeReply: function(successful) {
        var msg = { showMsg: true, successful: successful };
        this.trigger(msg);
    },

    onSaveFilters: function() {
        _userPreferences.saveFilters = !_userPreferences.saveFilters;
        if(typeof window.localStorage !== undefined) {
            window.localStorage.setItem(_username, JSON.stringify(_userPreferences));
        }
    },
    onShowAllOrgs: function() {
        _userPreferences.showAllOrgs = !_userPreferences.showAllOrgs;
        if(typeof window.localStorage !== undefined) {
            window.localStorage.setItem(_username, JSON.stringify(_userPreferences));
        }
    },

    onUniformScaling: function() {
        _userPreferences.uniformScaling = !_userPreferences.uniformScaling;

        if(typeof window.localStorage !== undefined) {
            window.localStorage.setItem(_username, JSON.stringify(_userPreferences));

        }
    },

    saveUserFilters: function(userFilters) {
        if(typeof window.localStorage !== undefined) {
            window.localStorage.setItem(_username, JSON.stringify(userFilters));
            _userPreferences = userFilters;
        }
    },

    saveFilter: function(key, value) {
        if(_userPreferences.saveFilters) {
            _userPreferences[key] = value;
            this.saveUserFilters(_userPreferences);
        }
    },

    filtersSaved: function() {
        if(_userPreferences !== null) {
            return _userPreferences.saveFilters;
        }
        return false;
    },

    showAllOrgs: function() {

        if(_userPreferences !== null) {
            return _userPreferences.showAllOrgs;
        }
        return false;
    },


    uniformScaling: function() {


        if(_userPreferences !== null) {
            return _userPreferences.uniformScaling;
        }
        return false;
    },

    getUserFilters: function () {
       
          

        return _userPreferences;
    },

    onSetDefaultFarm: function(FarmID){

        _userPreferences.defaultFarm = FarmID;
        if(typeof window.localStorage !== undefined) {
            window.localStorage.setItem(_username, JSON.stringify(_userPreferences));
        }
    },

    defaultFarm: function() {
        if(_userPreferences !== null) {
            return _userPreferences.defaultFarm;
        }
        return -1;
    },

});

module.exports = NavBarStore;