/**
 * 
 */
"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("./../actions/Actions.js");
var VConst = require("./../utils/VConst.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var NavBarStore = require("./NavBarStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var TranslateStore = require("./TranslateStore.js");
var t = TranslateStore.getTranslate();

var _filter = null;
var _loading = false;

var _allFarms = false;

var _data = {
    allFarms: _allFarms,
    OrgName: "",
    FarmName: "",
    dateSpanString: "",
    numDays: -1, 
    data: null
};

var _currFilter = {
    startDate:null,
    endDate: null,
    popID: 0,
    popName: "",
    farmName: ""
};

var UptimeStore = Reflux.createStore({
    init: function () {



        this.listenTo(FilterStore, this.onFilterStoreChange);
        this.listenTo(Actions.uptAllFarmsChange, this.onAllFarmsChange);

        this.listenTo(Actions.uptFetchReport, this.onFetchReport);

        this.listenTo(Actions.apiUptimeRecived, this.onUptimeRecived);








        var endDate = moment().endOf("day");
        var startDate = endDate.clone().subtract(30, "days").startOf("day");
        _filter = FilterStore.getFilter();

                              



    },


    onAllFarmsChange: function () {
        _allFarms = !_allFarms;
        this.trigger();

    },


    onFetchReport: function () {

        var getAll = UptimeStore.allFarms();
      


        var md1 = moment.utc(_filter.startDate);
        var md2 = moment.utc(_filter.endDate);


        var numDays = md2.diff(md1, "days") + 1;

   
        var d1 = md1.format("YYYY-MM-DD");

        var d2 = md2.format("YYYY-MM-DD");

        _data.allFarms = getAll;

        _data.OrgName = FilterStore.getSelectedOrgName();

        if (getAll)
            _data.FarmName = "";
        else
            _data.FarmName = FilterStore.getSelectedFarmName();

        _data.dateSpanString= d1 + " -> " + d2;
        _data.numDays = numDays;
        _data.data = null;





        var params = {  
            OrganisationId: FilterStore.getSelectedOrgID(),
            FarmID: FilterStore.getSelectedFarmID(),
            GetOrganisation: getAll,
            StartDate: d1,
            EndDate: d2

        };

       this.trigger(null, null, true);
       VakiAPI.GetUptimeReport(params);



    },


    allFarms: function () {
        return _allFarms;
    },

    notifyMount: function () {

        if (FilterStore.getSelectedOrgID() > 0)
            this.onFetchReport();
    },

    onUptimeRecived: function (upt, err) {

        var theStuff = null;
        if (upt == null) {
            alert("Error retrieving report from server: \n" + error);
        } else {

            theStuff = [];
            var len = upt.length;
            var lastFarm = "�u�u�u";
            var oFarm = null;
            for (var i = 0; i < len; i++) {
                var row = upt[i];
                if (lastFarm != row.FarmName) {
                    lastFarm = row.FarmName;
                    if (oFarm != null) {


                        theStuff.push(oFarm);
                    }
                    oFarm = {
                        FarmName: row.FarmName,
                        list: []
                    }

                    row.NumberOfDaysWithConnection = row.NumberOfDaysWithConnection.toFixed(0) + "  (" + ((row.NumberOfDaysWithConnection / _data.numDays) * 100).toFixed(0) + "%)";
               
                    row.NumberOfFish = row.NumberOfFish.toLocaleString();

                    oFarm.list.push(row);
                } else {
                    row.NumberOfDaysWithConnection = row.NumberOfDaysWithConnection.toFixed(0) + "  (" + ((row.NumberOfDaysWithConnection / _data.numDays) * 100).toFixed(0) + "%)";
                    row.NumberOfFish = row.NumberOfFish.toLocaleString();
                  //  row.FarmName = ((row.NumberOfDaysWithConnection / _data.numDays) * 100).toFixed(1) + "%";
                    oFarm.list.push(row);
                }

            }
            if (oFarm && oFarm.list.length > 0)
                theStuff.push(oFarm);
            _data.data = theStuff;
        }


        this.trigger(_data, null, false);

    },

    onFilterStoreChange: function(newFilter) {

        _filter = newFilter;
        this.trigger(null, _filter, false);
    },

    /**
     * Set chart data expects raw data so we'll have to update translations
     * manually here.
     */
    onTranslateStoreChange: function() {


        //this.trigger(_data, false, true);
    },
    






});

module.exports = UptimeStore;

