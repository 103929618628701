

"use strict";

var React = require("react");
var RB = require("react-bootstrap");
var moment = require("moment");
import PropTypes from "prop-types";

var ReactDOM = require("react-dom");
var Immutable = require("immutable");
var VConst = require("./../../utils/VConst.js");
var DashboardDrawUtils = require("./DashboardDrawUtils.js");
var Vutil = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var FilterStore = require("./../../stores/FilterStore.js");
var DashboardStore = require("./../../stores/DashboardStore.js");
var Actions = require("./../../actions/Actions.js");
var Spinner = require("./../../utils/Spinner.jsx");
var LastFarmConnection = require("./LastFarmConnection.jsx");
var SingleDatePicker = require("./../Filters/SingleDatePicker.jsx");
var TranslateStore = require("./../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
var DUtils = require("./DashUtils.js");

var Reflux = require("reflux");
var VakiAPI = require("../../webservice/VakiAPI");

var Router = require("react-router");
var Link = Router.Link;


var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");

require("./Dashboard.css");









var thePopID = 0;

export default class PopulationBlock  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {

            drawStuff: {
                theArr: [],
                avgWgt: 0,
                idxStart: -1,
                idxEnd: 0
            }

        };

   

    };


    static propTypes = {
        popStatus: VakiReactPropTypes.populationStatus,
        comments: PropTypes.array,
        // organisationName: PropTypes.string,
        farmName: PropTypes.string,

        theID: PropTypes.number
    };

    static defaultProps = {
        
            populationStatus: VakiReactPropTypes.defaultPopulationStatus,
            organisationName: "Organisation",
            farmName: "Farm",
            contactPhone: "",
            contactEmail: ""
        
    };



    componentDidMount () {


        this.drawCanvas(this.props.popStatus);
        //   createDashboardCanvas(domNode, this.props.populationStatusList);
    };


    componentDidUpdate (previousProps, previousState) {

        this.drawCanvas(this.props.popStatus);
    };



    logg (str) {
        return;
    

    };

    drawCanvas (p) {

        var canv = document.getElementById("Canvas" + this.props.theID);
        var dayStat = p.PopDayStatus;
        var ctx = null;

        thePopID = p.PopulationID;
        if (dayStat && canv) {




            var lastDay = null;
            if (dayStat.StarvingDate) {
                lastDay = moment(dayStat.StarvingDate).subtract(1, "days");
            } else
                lastDay = moment(moment().subtract(1, "days").format("YYYY-MM-DD"));



            var dayGap = 0;

            if (moment(dayStat.EndDate) < lastDay) {
                dayGap = lastDay.diff(dayStat.EndDate, "days");
            }
            var daysFromStart = lastDay.diff(dayStat.StartDate, "days");


            if (dayGap < 30) {



                var startIdx = dayGap;
                var ctx = canv.getContext("2d");
                ctx.clearRect(0, 0, canv.width, canv.height);

                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.lineCap = 'round';
                ctx.strokeStyle = VConst.COLORS.AVG_WEIGHT;
                ctx.fillStyle = VConst.COLORS.AVG_WEIGHT;
                var len = this.state.drawStuff.theArr.length;

                if (len == 0)
                    len = 1;

                var dx = canv.width / 30;

                var nTL1 = dayStat.TrendValStart;
                var nTL2 = dayStat.TrendValEnd;

                if (!nTL1 || !nTL2) {
                    nTL1 = this.state.drawStuff.avgWgt;
                    nTL2 = this.state.drawStuff.avgWgt;
                }


     
                var ySpan = 1000;// 1400; //g
                var nMin = nTL2 - ySpan * 3 / 4;
                if (nMin < 0)
                    nMin = 0;

                var nMax = nTL2 + ySpan * 1 / 4;




                var maxSpan = ySpan;
         
                var dy = (canv.height * 2 / 3) / maxSpan;

                //Want nTL2 to be at 2/3 height @ right side
                //Zero line will be
                var nZero = nTL2 - 2 * canv.height / 3 / dy;




                //  ctx.moveTo(0, canv.height - dy * (arr[0] - nZero))
                var ix = 1;

                var theXstart = -1;
                var theXend = -1;


                for (var j = startIdx; j < len; j++) {
                    if (this.state.drawStuff.theArr[j] != null) {
                        if (theXstart < 0)
                            theXstart = ix;

                        theXend = ix;

                        ctx.beginPath();
                        ctx.fillRect(ix * dx - 4, canv.height - dy * (this.state.drawStuff.theArr[j] - nZero) - 8, 8, 16);


                        //ctx.arc(j * dx, canv.height - dy * (this.state.drawStuff.theArr[j] - nZero), 7, 0, 2 * Math.PI);
                        ctx.fill();
                        // 
                    }
                    ix++;
                    // ctx.lineTo(j * dx, canv.height - dy * (arr[j] - nZero));
                }



                if (dayStat.TrendValStart != null && dayStat.TrendValEnd != null) {
                    ctx.lineWidth = 10;
                    ctx.beginPath();
                    ctx.lineCap = 'round';
                    ctx.strokeStyle = VConst.COLORS.TREND_LINE;

                    ctx.globalAlpha = 0.5;

                    if (startIdx > this.state.drawStuff.idxStart) {
                        var d = this.state.drawStuff.idxEnd - this.state.drawStuff.idxStart;
                        if (d > 0) {
                            var newNtl1 = nTL1 + ((nTL2 - nTL1) / d) * (startIdx - this.state.drawStuff.idxStart);
                            this.state.drawStuff.idxStart = startIdx;
                            nTL1 = newNtl1;

                        }
                    }

                    ctx.moveTo(theXstart * dx, canv.height - dy * (nTL1 - nZero))
                    ctx.lineTo(theXend * dx, canv.height - dy * (nTL2 - nZero));
                    ctx.stroke();
                }
            }


        }


        if (canv && !dayStat) {
            var ctx = canv.getContext("2d");
            if (ctx)
                ctx.clearRect(0, 0, canv.width, canv.height);
        }


    };






    render () {
        var myID = "block" + this.props.theID;
        var p = this.props.popStatus;

        if (!p)
            return <div> --------------- NO DATA ----------------- </div>;

        var statusStyle = {};

        var borderCol = { borderColor: 'red' };
        if (p.NumberOfFish24Hr === 0) {
            statusStyle = {
                backgroundColor: VConst.COLORS.POP_RED,
                borderColor: VConst.COLORS.POP_RED_BORDER
            };
        } else if (p.NumberOfFish24Hr > 0 && p.NumberOfFish24Hr < 100) {

            statusStyle = {
                backgroundColor: VConst.COLORS.POP_YELLOW,
                borderColor: VConst.COLORS.POP_YELLOW_BORDER
            };
        } else {
            statusStyle = {
                backgroundColor: VConst.COLORS.POP_GREEN,
                borderColor: VConst.COLORS.POP_GREEN_BORDER
            };
        }


        var isCamera = ((p.FrameSerialNumber%10 == 5) || (p.FrameSerialNumber%10 == 6)) ;
      

        var dayStat = p.PopDayStatus;


        if (p.PopulationID == FilterStore.getSelectedPopulationID())
            statusStyle.borderColor = "black";
        borderCol.borderColor = statusStyle.borderColor;

        var dayStat = p.PopDayStatus;




        var lastDayWithData = null;

        var txtMsg = "";
        var txtLevelMsg=""
        this.state.drawStuff.theArr = [];
        this.state.drawStuff.idxStart = -1;
        this.state.drawStuff.idxEnd = 0;

        var someData = 0;

        var borderStyleResaults = { borderColor: borderCol.borderColor, color: '' }
   
        var resaultsWgt = "";

        var arrLevelClass = [];

        for (var i = 0; i < 5; i++) {
            if (i>0)
                arrLevelClass.push("clsLevelBar clsLevelLeftMargin");
            else
                arrLevelClass.push("clsLevelBar");
        }





        if (dayStat) {
            thePopID = p.PopulationID;

            
            var dtEndData = moment.utc(dayStat.EndDate);

       


            if (dayStat.WarningLevel > 0 && dayStat.WarningLevel <= 5) {
              

                

                for (var i = 0; i < dayStat.WarningLevel; i++)
                    arrLevelClass[i] += " " + "clsFilled";

            }






            var cnt = 0;



            if (dayStat.Weights) {
                var tt = dayStat.Weights.split(';');
                for (var ii = tt.length - 1; ii >= 0; ii--) {
                    if (tt[ii] != "")
                        break;
                    cnt++;
                }

              
                if (cnt > 0)
                    dtEndData = dtEndData.subtract(cnt, "days");



                var gVal = 0;
                var cntNoNull = 0;
                var sumNoNull = 0;
                for (var ii = 0; ii < tt.length; ii++) {
                    if (tt[ii] != "") {

                        gVal = parseInt(tt[ii]);
                        cntNoNull++;
                        sumNoNull += gVal;
                        this.state.drawStuff.theArr.push(gVal);
                        someData++;
                        if (this.state.drawStuff.idxStart < 0)
                            this.state.drawStuff.idxStart = ii;
                        this.state.drawStuff.idxEnd = ii;
                    } else {

                        this.state.drawStuff.theArr.push(null);
                    }
          
                }
                this.state.drawStuff.avgWgt = 0;
                if (cntNoNull > 0)
                    this.state.drawStuff.avgWgt = sumNoNull / cntNoNull;

                if (this.state.drawStuff.idxStart == this.state.drawStuff.idxEnd) {
                    dayStat.TrendValEnd = gVal;
                    dayStat.TrendValStart = gVal;
                }

            }


            var chkMark = "";
            if (dayStat.StarvingDate)
                chkMark = <img src="img/if_check-mark_511949.png" className= "clsPopBlockIconDefaultSize" /> ; 
         
            if (dayStat.displayWgt)
                resaultsWgt = <div>{(dayStat.displayWgt / 1000).toFixed(1) + "kg" } {chkMark} </div>;



        }


        var strSGR = "";
        if (dayStat && dayStat.SGR != null)
            if (dayStat && this.state.drawStuff.idxStart != this.state.drawStuff.idxEnd && dayStat.SGR > -10)
                strSGR = "S:" + dayStat.SGR.toFixed(1);

    
        if (!dayStat)
            txtMsg = "No measurements found last 30 days.<br/>Starving date NOT set";
        else {



            txtMsg = "Weight & SGR estimate<br/>at end of " + moment.utc(dtEndData).format("MMM D");




          

            txtLevelMsg = "Confidence in weightestimate: " + dayStat.WarningLevel + "/5";
     /*
            if (dayStat.WarningLevel == 2) {
                if (dayStat.Reason1 == 50) {
                    txtMsg += "<br/>Suspicous SGR!";
                }
                if (dayStat.Reason2 == 20 || dayStat.Reason1 == 20)
                    txtMsg += "<br/>Limited number of measurements!";

            }
            */

        }

     
        
        var imgWarnPath = "";
        var warnMsg = "";

        if (!isCamera && p.LastConnectionStatus.toUpperCase() !== "UNKNOWN"){
        if (p.LastConnectionStatus === "OK") {
            if (p.LastFrameVisibility.toUpperCase() != "OK") {
                warnMsg = p.LastFrameVisibility;
                imgWarnPath = "img/Eye_32.png"
            }
        } else {
            warnMsg = p.LastConnectionStatus;
            imgWarnPath = "img/Antenna_32.png"
        }
        }

        var stylPlaceHolder = {
            width: "1.5em",
            display:"block"
        };

        var imgWarnStyle = {display:'none'};
        if (imgWarnPath != "") {
            imgWarnStyle.display = "block";
            stylPlaceHolder.display = "none";
        }



        var imgHelpPath = "img/help_24.png";
        var strHelpMsg = "Click for help on population blocks";


       
        
        
        var imgFramePath = "img/square.png";
       
        if (isCamera)
            imgFramePath = "img/camera.png"
        var strFrameInfo = t("dashboard.deviceType") + ": " + p.FrameType + "<br/>" + t("dashboard.frameSerialNumber") + ": " + p.FrameSerialNumber;


        let DeviceIcon = <div/>;
        if (p.FrameType && p.FrameType.toUpperCase() != "UNKNOWN")
            DeviceIcon = <img src={imgFramePath} className= "clsSquareImg clsPopBlockIconDefaultSize" 
                onMouseEnter={function (e) { DUtils.theMouseEnter(e,  strFrameInfo) }}  
                onMouseLeave={function (e){ DUtils.theMouseLeave()} }   />;





        var imgStarvPath = "";
        var imgPausePath = "";
        var strStarvInfo = "";
        imgPausePath = "img/pause48.png";
        imgStarvPath = "img/if_arrow-right_511954.png";
        strStarvInfo = t("dashboard.clicksetstarving");
        if (dayStat) {
            if (dayStat.StarvingDate) {
                imgStarvPath = "img/if_check-mark_511949.png";
                strStarvInfo = t("dashboard.starvingdate")+":\n" + moment.utc(dayStat.StarvingDate).format("DD.MM.YYYY");
            } 

            if (dayStat.RunState && dayStat.RunState > 0) {
                imgPausePath = "img/play48.png";
            } 

        }   





        
        var imgCommPath = "";
        var CommMsg = t("dashboard.clicksetstarving");
        var commParams = null;
        var nCommMsgWidth = 20;
        var commLines = null;

        var iconHeight = "24";
        if (this.props.comments.length > 0) {
            imgCommPath = "img/comment.png"
            CommMsg = DUtils.showComments;
            commParams = this.props.comments;
            commLines = this.props.comments.length + 6;
            nCommMsgWidth = -1;
        } else {
            var iconHeight = "20";

            imgCommPath = "img/pencil2_24.png"

        }
        



/*
 {function (e) {
                           

                            
                                    var str = moment().format("YYYY-MM-DD");
                                    var theComment = prompt(t("dashboard.commentto") + p.PopulationName, "");
                                    if (theComment != null && theComment != "") {
                                        var newComment = {
                                            FarmID: FilterStore.getSelectedFarmID(),
                                            PopulationID: p.PopulationID,
                                            PertinentDateTime: str,
                                            CommentText: theComment
                                        };
                                        Actions.addComment(newComment);
                                    }
                                    
    }
}

 */



       
        


        /*
                              <img src={imgPausePath} className="clsStarvDateImg clsPopBlockIconDefaultSize"
                        onClick={function (e) {  Actions.dashPause(p);  e.stopPropagation(); }}
                    />
          
         */


        return (
            <div id= {myID}  className="popBlock" style={statusStyle}>

           
                <div className="clsTop">
                <div id="imgHelpWrap" className = "clsImgHelpWrap"   
                    onClick = {function () { Actions.dashShowHelp() }}
                     onMouseEnter={function (e){ DUtils.theMouseEnter(e, strHelpMsg  )}}  
                     onMouseLeave={function (e){ DUtils.theMouseLeave()} }> 
                    <img src={imgHelpPath} className= "clsPopBlockIconDefaultSize" />
                </div>
                    <div id="title" className="clsTitle" onClick={function () { Actions.populationClick(FilterStore.getPopulationByID(p.PopulationID)) }}> {p.PopulationName} </div>


                    <div id="warnPlaceholder" style={stylPlaceHolder}></div>
                <div id="imgWarnWrap" className = "clsImgWrap" style = {imgWarnStyle}  onMouseEnter={function (e){ DUtils.theMouseEnter(e,  warnMsg  )}}  onMouseLeave={function (e){ DUtils.theMouseLeave()} }> 
                    <img src={imgWarnPath} className="clsPopBlockIconDefaultSize" />
                </div>

                    </div>

                <div id="received" className = "clsReceived"  style={borderCol} > 
                    <div id="hours" className="clsNumFish"> {p.NumberOfFish24Hr + "x"} </div>
                    <div id="last" className="clsLastFish"> {Vutil.timeFormatFromHours(p.HoursSinceLastFish, false)} </div>
                </div>

                <div id="results" className = "clsResults"  style={borderStyleResaults}    onMouseEnter={function (e){ DUtils.theMouseEnter(e, txtMsg  )}}  onMouseLeave={function (e){ DUtils.theMouseLeave()} } > 
                    <div id="wgt" className="clsWgtWrap">

                        <div id="divConfidenceLevel" className="clsConfidenceLevel" onMouseEnter={function (e) { DUtils.theMouseEnter(e, txtLevelMsg) }} onMouseLeave={function (e) { DUtils.theMouseLeave() }}>

                            <div id="divLevel1" className={arrLevelClass[0]} />
                            <div id="divLevel2" className={arrLevelClass[1]} />
                            <div id="divLevel3" className={arrLevelClass[2]} />
                            <div id="divLevel4" className={arrLevelClass[3]} />
                            <div id="divLevel5" className={arrLevelClass[4]} />

                        </div>

                        <div id="wgt" className="clsWgt">
                        {resaultsWgt}
                        </div>



                    </div>
                 
                    <div id="sgr" className="clsSgr"> { strSGR} </div>
                </div>

                
                <div id={"convDiv"+myID} className = "clsCnvDiv" style = {borderCol} 
                    onClick = {function (){Actions.popDetailsLink (p)}}
                    onMouseEnter={function (e){ DUtils.theMouseEnter(e, t("dashboard.clickmoredetails") )}}  
                    onMouseLeave={function (e){ DUtils.theMouseLeave()} }   >
                    <canvas id = {"Canvas"+this.props.theID} className = "clsCanvas" />
                </div>

                  <div  className = "clsBottom">
                      {DeviceIcon}
          
                     
                    <img src={imgStarvPath} className="clsStarvDateImg clsPopBlockIconDefaultSize"
                        onClick={function (e) { Actions.dashPopStarvDate(p); e.stopPropagation(); }}
                       />   
                     


                    <img src={imgCommPath} className="clsCommentImg clsPopBlockIconDefaultSize" 
                          
                        onClick={function (e) { Actions.dashCommentsShow(true, commParams, p.PopulationName, p.PopulationID ); }}
                          />
                          
                 </div>

                   


            </div>
            )


     
    }
};

