/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var RB = require("react-bootstrap");
var moment = require("moment");
require("./Dashboard.css");
import PropTypes from "prop-types";
/**
 * React component that displays appropriate dialog or warning with regard how
 * many hours we last received any data from a farm.
 */
export default class  LastFarmConnection extends React.Component{
    static propTypes = {
        // A moment.js object representing time
        lastDataConnectionTime: PropTypes.object
    };


    /**
     * Receives a moment.js datetime object and returns how many hours the difference
     * is, rounded down.
     *
     * @param lastDataConnectionTime, a moment.js datetime object
     * @returns number, inter number of horus
     */
    calculateHourDifference ( lastDataConnectionTime) {
        var now = moment();
        var differenceInHours = now.diff(lastDataConnectionTime, "hours");

        return differenceInHours;
    };




    getNormalDisplay ( lastDataConnectionTime) {
        return (
            <div className="clsAlert clsAlertNormal" >
                <strong>{this.props.t("dashboard.timeSinceLast")}</strong>: {lastDataConnectionTime.fromNow()}
            </div>
        )
    };

    getWarningDisplay ( lastDataConnectionTime) {

        return (
             <div className="clsAlert clsAlertWarning" >
                <strong>{this.props.t("dashboard.timeSinceLast")}</strong>: {lastDataConnectionTime.fromNow()}.
                The state of this dashboard may not reflect current status,
                this was how it was {lastDataConnectionTime.fromNow()}.
            </div>
        )
    };

    getDangerDisplay ( lastDataConnectionTime) {


        return (
            <div className="clsAlert clsAlertDanger" >
                <strong>{this.props.t("dashboard.timeSinceLast")}</strong>: {lastDataConnectionTime.fromNow()}.
                The state of this dashboard may not reflect current status,
                this was how it was {lastDataConnectionTime.fromNow()}.
            </div>
        )
    };

    getDisconnectedDisplay ( ){


        return (

            <div className="clsAlert clsAlertInfo" >
                <strong>{this.props.t("dashboard.farmDisconnected")}</strong>
                <br/>The state of this dashboard may not reflect current status.
            </div>
        )

    };


    getInDiv ( )
    {
       return (<div className="clsAlert" >asdf sadf safd sadfasdf sadf sadfad asdsadfasdf</div>)
    };

    /**
     * Receives a moment.js datetime object and returns the appropriate JSX to
     * render given our defined hour ranges. This contains our business logic as
     * to how we want to define our warning levels.
     *
     * @param lastDataConnectionTime, a moment.js datetime object
     * @returns {*} JSX
     */
    getDisplay (lastDataConnectionTime) {


   

        if (lastDataConnectionTime == null)
            return this.getDisconnectedDisplay();

        var hoursSinceLastData = this.calculateHourDifference(lastDataConnectionTime);

        if (!this.props.lastDataConnectionTime) {
            // If we didn't receive props, don't display anything
            return <div></div>;
        } else if (hoursSinceLastData < 12) {
            return this.getNormalDisplay(lastDataConnectionTime);
        } else if (hoursSinceLastData >= 12 && hoursSinceLastData < 24) {
            return this.getWarningDisplay(lastDataConnectionTime);
        } else if (hoursSinceLastData >= 24) {
            return this.getDangerDisplay(lastDataConnectionTime);
        } else {
            return this.getNormalDisplay(lastDataConnectionTime);
        }
    };

    render () {
        var display = this.getDisplay(this.props.lastDataConnectionTime);

        return (
            <div>{display}</div>
        )
    }
};

