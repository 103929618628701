/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to edit a farm.
 * Along with basic information, a farm has a list of users that have access to it
 * and the Organisation that it belongs to.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var adminViewFilter = AdminViewStore.getFilter();

var _currentEditFarm = { };

var EditFarmFormStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.editFarmFormSubmit, this.onEditFarmFormSubmit);
        this.listenTo(Actions.farmListRowClick, this.onFarmListRowClick);

        _currentEditFarm = {
            id: 0,
            name: "",
            email: "",
            vakiSupEmail: "",
            phone: "",
            latitude: "",
            longitude: "",
            alternativeId: "",
            countries: [],
            organisations: [],
            regions: [],
            timezones: [],
            systems: [],
            selectedCountries: [],
            selectedUsers: [],
            selectedOrganisations: [],
            selectedRegions: [],
            selectedTimezones: [],
            selectedSystems: []
        };
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {
        if(view === "editFarm") {
            if(type === "organisation") {
                this.onDropdownOrgSelect(data);
            } else if(type === "timezone") {
                this.onDropdownTimezoneSelect(data);
            } else if(type === "country") {
                this.onDropdownCountrySelect(data);
            } else if(type === "region") {
                this.onDropdownRegionSelect(data);
            } else if(type === "system") {
                this.onDropdownSystemSelect(data);
            }
        }
        
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "editFarm") {
            if(type === "organisation") {
                this.onRemoveOrg(data);
            } else if(type === "timezone") {
                this.onRemoveTimezone(data);
            } else if(type === "country") {
                this.onRemoveCountry(data);
            } else if(type === "region") {
                this.onRemoveRegion(data);
            } else if(type === "system") {
                this.onRemoveSystem(data);
            }
        }
    },
    /* When a user selects an item from a dropdown menu, adds that option to selected options */
    onDropdownOrgSelect: function(org) {
        var orgIndex = _currentEditFarm.organisations.indexOf(org);
        if(orgIndex === -1) return;

        // Finding all users at this company and adding them to selected users
        for(var i = 0; i < _currentEditFarm.organisations[orgIndex].userList.length; ++i) {
            var user = _currentEditFarm.organisations[orgIndex].userList[i];
            for(var j = 0; j < _currentEditFarm.users.length; ++j) {
                if(user.id === _currentEditFarm.users[j].id) {
                    _currentEditFarm.users.splice(j, 1);
                    _currentEditFarm.selectedUsers.push(user);
                    break;
                }
            }
        }
        
        _currentEditFarm.organisations.splice(orgIndex, 1);
        _currentEditFarm.selectedOrganisations.push(org);

        this.trigger(_currentEditFarm);
    },
    onDropdownTimezoneSelect: function(timezone) {
        var timezoneIndex = _currentEditFarm.timezones.indexOf(timezone);
        if(timezoneIndex !== -1) {
            _currentEditFarm.timezones.splice(timezoneIndex, 1);
        }
        _currentEditFarm.selectedTimezones.push(timezone);
        _currentEditFarm.timezoneSelected = true;
        this.trigger(_currentEditFarm);
    },
    onDropdownCountrySelect: function(country) {
        var countryIndex = _currentEditFarm.countries.indexOf(country);
        if(countryIndex !== -1) {
            _currentEditFarm.countries.splice(countryIndex, 1);
        }
        _currentEditFarm.selectedCountries.push(country);
        _currentEditFarm.regions = country.regionList;
        _currentEditFarm.timezones = country.timezoneList;
        _currentEditFarm.selectedRegions = [];
        _currentEditFarm.selectedTimezones = [];
        this.trigger(_currentEditFarm);
    },
    onDropdownRegionSelect: function(region) {
        var regionIndex = _currentEditFarm.regions.indexOf(region);
        if(regionIndex !== -1) {
            _currentEditFarm.regions.splice(regionIndex, 1);
        }
        _currentEditFarm.selectedRegions.push(region);
        this.trigger(_currentEditFarm);
    },
    onDropdownSystemSelect: function(system) {
        var systemIndex = _currentEditFarm.systems.indexOf(system);
        if(systemIndex !== -1) {
            _currentEditFarm.systems.splice(systemIndex, 1);
        }
        _currentEditFarm.selectedSystems.push(system);
        this.trigger(_currentEditFarm);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveUser: function(user) {
        var userIndex = _currentEditFarm.selectedUsers.indexOf(user);
        if(userIndex !== -1) {
            _currentEditFarm.selectedUsers.splice(userIndex, 1);
        }
        _currentEditFarm.users.push(user);
        _currentEditFarm.users.sort(this.compare);
        this.trigger(_currentEditFarm);
    },
    onRemoveOrg: function(org) {
        var orgIndex = _currentEditFarm.selectedOrganisations.indexOf(org);
        if(orgIndex === -1) return;
        // Finding all users at this company and removing them from selected users
        for(var i = 0; i < _currentEditFarm.selectedOrganisations[orgIndex].userList.length; ++i) {
            var user = _currentEditFarm.selectedOrganisations[orgIndex].userList[i];
            var userIndex = _currentEditFarm.selectedUsers.indexOf(user);
            _currentEditFarm.selectedUsers.splice(userIndex, 1);
            _currentEditFarm.users.push(user);
        }
        _currentEditFarm.selectedOrganisations.splice(orgIndex, 1);
        _currentEditFarm.organisations.push(org);
        _currentEditFarm.organisations.sort(this.compare);
        _currentEditFarm.users.sort(this.compare);

        this.trigger(_currentEditFarm);
    },
    onRemoveTimezone: function(timezone) {
        var timezoneIndex = _currentEditFarm.selectedTimezones.indexOf(timezone);
        if(timezoneIndex !== -1) {
            _currentEditFarm.selectedTimezones.splice(timezoneIndex, 1);
        }
        _currentEditFarm.timezones.push(timezone);
        _currentEditFarm.timezones.sort(this.compare);
        _currentEditFarm.timezoneSelected = false;
        this.trigger(_currentEditFarm);
    },
    onRemoveCountry: function(country) {
        var countryIndex = _currentEditFarm.selectedCountries.indexOf(country);
        if(countryIndex !== -1) {
            _currentEditFarm.selectedCountries.splice(countryIndex, 1);
        }
        _currentEditFarm.countries.push(country);
        _currentEditFarm.countries.sort(this.compare);
        _currentEditFarm.regions = [];
        _currentEditFarm.timezones = [];
        _currentEditFarm.selectedRegions = [];
        _currentEditFarm.selectedTimezones = [];
        this.trigger(_currentEditFarm);
    },
    onRemoveRegion: function(region) {
        var regionIndex = _currentEditFarm.selectedRegions.indexOf(region);
        if(regionIndex !== -1) {
            _currentEditFarm.selectedRegions.splice(regionIndex, 1);
        }
        _currentEditFarm.regions.push(region);
        _currentEditFarm.regions.sort(this.compare);
        this.trigger(_currentEditFarm);
    },
    onRemoveSystem: function(system) {
        var systemIndex = _currentEditFarm.selectedSystems.indexOf(system);
        if(systemIndex !== -1) {
            _currentEditFarm.selectedSystems.splice(systemIndex, 1);
        }
        _currentEditFarm.systems.push(system);
        this.trigger(_currentEditFarm);
    },
    compare: function(a,b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    },
    /* When a farm is clicked on the table to left, it's information is shown
    *  in the edit form, ready for editing.
    */
    onFarmListRowClick: function(farm) {
        _currentEditFarm.id = farm.id;
        _currentEditFarm.name = farm.name;
        _currentEditFarm.email = farm.email;
        _currentEditFarm.vakiSupEmail = farm.vakiSupEmail;
        _currentEditFarm.phone = farm.phone;
        _currentEditFarm.latitude = farm.latitude;
        _currentEditFarm.longitude = farm.longitude;
        _currentEditFarm.alternativeId = farm.alternativeId;
        _currentEditFarm.countries = AdminViewStore.getAllCountriesCloned();
        _currentEditFarm.users =  AdminViewStore.getAllUsersCloned();
        _currentEditFarm.organisations = AdminViewStore.getAllOrganisationsCloned();
        _currentEditFarm.systems = AdminViewStore.getAllSystemsCloned();
        _currentEditFarm.selectedCountries = [];
        _currentEditFarm.selectedRegions = [];
        _currentEditFarm.selectedUsers = farm.userList;
        _currentEditFarm.selectedTimezones = [];
        _currentEditFarm.selectedOrganisations = [];
        _currentEditFarm.selectedSystems = [];

        if(farm.organisation !== undefined) _currentEditFarm.selectedOrganisations = [farm.organisation];

        /* Finding information about the selected country */
        if(farm.countryId !== undefined || farm.countryId !== 0) {
            for(var i = 0; i < _currentEditFarm.countries.length; ++i) {
                if(_currentEditFarm.countries[i].id === farm.countryId) {
                    _currentEditFarm.selectedCountries.push(_currentEditFarm.countries[i]);
                    _currentEditFarm.regions = _currentEditFarm.countries[i].regionList.slice();
                    _currentEditFarm.timezones = _currentEditFarm.countries[i].timezoneList.slice();
                    /* Finding information about the selected region */
                    for(var j = 0; j < _currentEditFarm.regions.length; ++j) {
                        if(_currentEditFarm.regions[j].id === farm.regionId) {
                            _currentEditFarm.selectedRegions.push(_currentEditFarm.regions[j]);
                            _currentEditFarm.regions.splice(j, 1);
                            break;
                        }
                    }
                    /* Finding information about the selected timezone */
                    for(var k = 0; k < _currentEditFarm.timezones.length; ++k) {
                        if(_currentEditFarm.timezones[k].id === farm.timezoneId) {
                            _currentEditFarm.selectedTimezones.push(_currentEditFarm.timezones[k]);
                            _currentEditFarm.timezones.splice(k, i);
                            break;
                        }
                    }
                    _currentEditFarm.countries.splice(i, 1);
                    break;
                }
            }
        }
        /* Finding information about the selected systems */
        if(farm.visibleSystemList.length > 0) {
            for(var i = 0; i < farm.visibleSystemList.length; ++i) {
                var system = $.grep(_currentEditFarm.systems, function(e){ return e.id === farm.visibleSystemList[i].SystemID });
                _currentEditFarm.selectedSystems.push(system[0]);
            }
        }

        this.filterSelectOptions(_currentEditFarm.selectedCountries, _currentEditFarm.countries);
        this.filterSelectOptions(_currentEditFarm.selectedOrganisations, _currentEditFarm.organisations);
        this.filterSelectOptions(_currentEditFarm.selectedSystems, _currentEditFarm.systems);
        this.trigger(_currentEditFarm);
    },

    /* Triggered when the entire form is submitted. Eventually this will
    *  update the database entry for the farm and then send in updates
    *  to all new farm-org and user-farm entries.
    */
    onEditFarmFormSubmit: function(name, email, phone, vakiSupEmail, latitude, longitude, alternativeId) {
        var org = { };
        var regionId;
        var countryId = 0;
        var timezoneId = 0;
        var systems = [];
        _currentEditFarm.name = name;
        _currentEditFarm.email = email;
        _currentEditFarm.phone = phone;
        _currentEditFarm.vakiSupEmail = vakiSupEmail;
        _currentEditFarm.latitude = latitude;
        _currentEditFarm.longitude = longitude;
        _currentEditFarm.alternativeId = alternativeId;
        if(_currentEditFarm.selectedOrganisations.length === 1) org = _currentEditFarm.selectedOrganisations[0];
        if(_currentEditFarm.selectedRegions.length === 1) regionId = _currentEditFarm.selectedRegions[0].id;
        if(_currentEditFarm.selectedCountries.length === 1) countryId = _currentEditFarm.selectedCountries[0].id;
        if(_currentEditFarm.selectedTimezones.length === 1) timezoneId = _currentEditFarm.selectedTimezones[0].id;
        for(var i = 0; i < _currentEditFarm.selectedSystems.length; ++i) {
            systems.push({ FarmID: _currentEditFarm.id, SystemID: _currentEditFarm.selectedSystems[i].id });
        }
        var newFarm = {
            id: _currentEditFarm.id,
            name: name,
            email: email,
            phone: phone,
            vakiSupEmail: vakiSupEmail,
            latitude: latitude,
            longitude: longitude,
            alternativeId: alternativeId,
            organisation: org,
            regionId: regionId,
            timezoneId: timezoneId,
            countryId: countryId,
            userList: _currentEditFarm.selectedUsers,
            visibleSystemList: systems
        }
        Actions.newFarmCreated(newFarm);
        this.trigger(_currentEditFarm);
    },
    filterSelectOptions: function(selectedOptions, options) {
        for(var i = 0; i < selectedOptions.length; ++i) {
            for(var j = 0; j < options.length; ++j) {
                if(selectedOptions[i].name === options[j].name) {
                    options.splice(j, 1);
                }
            }
        }
    },
    getCurrentEditFarm: function() {
        return _currentEditFarm;
    }
});

module.exports = EditFarmFormStore;