/**
 * Vaki Aquaculture Systems Ltd. Copyright 2018, all rights reserved.
 *

Management of parameters living in both sessionStore and localStore
 */


"use strict";







var ParMan = {  

    _params: [],

    getEmptyPar: function () {
        return {
            _name: "",
            _type: 'string'
        };
          
    },

    isParDefined: function (name) {
        if (this._params.length > 0){
            for (var i = 0; i < this._params.length; i++)
                if (this._params[i]._name == name)
                    return true;


        }
        return false;

    },

    initParameter: function (name, defaultVal, type, storedFunc) {
       
        if (this.isParDefined(name))
     {

            console.log("ParMan Parameter initialized twice! >>>" + name);


        }

        var p = this.getEmptyPar();
        p._name = name;



        if (type)
            p._type = type;

        var v = sessionStorage.getItem(name);


            if (v && typeof v === 'string' && v == "null") {
           //     console.log("ParMan  " + name + "  returning null");
                return null;
            }
 
           
        if (storedFunc && v == null) {
            v = storedFunc()[name];
        }

        if (v != null) {
            if (p._type === 'bool' || p._type === 'boolean')
                v = (v.trim().toUpperCase() == "TRUE");
            else if (p._type === 'number')
                v = Number(v);
        } else
            v = defaultVal;

        this._params.push(p);

    
        //+ "           params:" + JSON.stringify(this._params));



        return v;


    },



    setItem: function (name, val, storeFunction) {

        sessionStorage.setItem(name, val);


        var v = null;

        if (this._params.length > 0) {
            for (var i = 0; i < this._params.length; i++)
                if (this._params[i]._name == name) {
                    v = this._params[i];
                    break;
                }

        }



        if (storeFunction) {
            storeFunction(name, val);
        }
        if (v == null) {
            console.warn("ParMan: Set value to an uninitialized parameter: >>>" + name);
        }
    },


    getItem: function (name) {

        return sessionStorage.getItem(name);

    },



    clear: function () {


       sessionStorage.clear();
       this._params = [];
     
    }
};

module.exports = ParMan;

