/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

var React = require("react");
import {ArrowRepeat, Trash}  from 'react-bootstrap-icons';
var Actions = require("../../../../actions/Actions.js");
import ConfirmDeleteModal from "./Modals/ConfirmDeleteModal.jsx";

export default class  UserListRow extends React.Component{
 constructor(props) {
     super(props);

 	this.userClicked = () => {
		Actions.userListRowClick(this.props.user);
	};
	this.nameIsNullOrEmpty = () => {
		return ((this.props.user.firstName === null) || (this.props.user.firstName === "")) &&
				((this.props.user.lastName === null) || (this.props.user.lastName === ""))
	};
	this.deleteClicked = () => {
		Actions.userListRowDelete(this.props.user);
	};
	this.restoreClicked = () => {
		Actions.userListRowRestore(this.props.user);
	};
	this.getButton = () => {
		if(this.props.user.isActive) {
			return this.getDeleteButton();
		} else {
			return this.getRestoreButton();
		}
	};
	this.getDeleteButton = () => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Delete">
				<button className="btn btn-danger btn-xs" onClick={this.deleteClicked}>
				<Trash />
					
				</button>
			</p>
		);
	};
	this.getRestoreButton = () => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Restore">
				<button className="btn btn-primary btn-xs" onClick={this.restoreClicked}>
					<ArrowRepeat />
				</button>
			</p>
		);
	};    

    };//constructor



	render ( ) {
		var name = "";
		var button = this.getButton();
		if(this.nameIsNullOrEmpty()) name = this.props.t("adminView.noNameRegistered");
		else name = this.props.user.firstName + " " + this.props.user.lastName;

		/* Dynamically shortening the user name so it fits in the table if its too long */
		var username = this.props.user.username;

		if(username.length > 15) username = username.substr(0, 12) + "..";


		return (
			<tr>
				<td className="col-md-3" onClick={this.userClicked.bind(null, this.props.user.name)}> {name} </td>
				<td className="col-md-3" onClick={this.userClicked.bind(null, this.props.user.name)}> {username} </td>
				<td className="col-md-2" onClick={this.userClicked.bind(null, this.props.user.name)}> {this.props.user.organisations.length} </td>
				<td className="col-md-2" onClick={this.userClicked.bind(null, this.props.user.name)}> {this.props.user.farms.length} </td>
				<td className="col-md-2">
					{button}
				</td>
			</tr>
		);
	}
};

