/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
  *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";




var Reflux = require("reflux");
var Immutable = require("immutable");
var FilterStore = require("./FilterStore.js");
var UserDataStore = require("./UserDataStore.js");
var NavBarStore = require("./NavBarStore.js");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var TranslateStore = require("./TranslateStore.js");
var ParMan = require("./ParMan.js");
var Spinner = require("./../utils/Spinner.jsx")
var moment = require("moment");
//BGXXX var createKeyframe = require('create-keyframe');
//BGXXX var insertCSS = require('insert-styles');

var t = TranslateStore.getTranslate();

var _dashboardData = Immutable.Map({});
var obj = Immutable.Map({ OrganisationList: undefined });
var _allboardsData = Immutable.Map(obj);
var _showAllOrgs = false;

const _showAllOrgs_ID = "DBS_showAllOrgs";

var mapp = null;
var mappCenter = null;

var timerPar = null;

var UtilData = [];

var UtilsTempShortCnt = 0;




var cntDbg = 0;


var hidingMsg = false;


var DashboardStore = Reflux.createStore({
    init: function () {
        this.listenTo(Actions.apiNewDashboardData, this.onApiNewDashboardData);
        this.listenTo(Actions.dashShowAllOrgs, this.onShowAllOrgs);
        this.listenTo(Actions.apiNewDashboardList, this.onNewDashboardList);
        this.listenTo(Actions.dashboardToggleMap, this.onToggleMap);

        this.listenTo(Actions.dashboardLoadAllDashboards, this.fetchAllDashboards);

        this.listenTo(Actions.apiGetFarmUtilizations, this.onGotFarmUtilizations);
        this.listenTo(Actions.dashboardShowPopsUtil, this.showPopsUtil);
        this.listenTo(Actions.dashboardShowCurrOrg, this.onShowCurrOrg);
        this.listenTo(Actions.addComment, this.onNewComment);

        this.listenTo(Actions.dashboardHideStartupMsg, this.onHideMsg);

        this.listenTo(Actions.dashCheckOrgListAge, this.checkOrgListAge);

      //  this.listenTo(Actions.farmClick, this.onFarmClick);
        this.listenTo(Actions.dashFarmClickedOnAllOrgs, this.onFarmClickedOnAllOrgs);





        this.data = Immutable.Map({
            dashboardData: _dashboardData,
            allboardsData: null, //_allboardsData,
            allBoardsDataUpdated: new Date('1944-06-17T12:00:00'),
            showMap: false,
            showCurrOrg: false,
            showMsg: true
       

        });

        this.FetchingUtils = [];

        this.map = null;
        this.markers = [];
        // _showAllOrgs = NavBarStore.showAllOrgs();
        
      
        

    },




    checkOrgListAge: function () {

        var v = this.data.get("allboardsData");
        if (!v)
            return;

        var updtTime = this.data.get("allboardsDataUpdated");

        var msUpdt = new Date() - updtTime;
       
        if (msUpdt > (30*60*1000) && (_showAllOrgs || this.data.get("showCurrOrg") )) { //More than half an hour and orgs visible
               VakiAPI.getDashboardList();
        }



    },


    onHideMsg: function () {

        var elND = document.getElementById("TheCallout");

        if (elND && !hidingMsg) {
            hidingMsg = true;
            var endFunc = function () { console.log("End Hiding"); document.getElementById("CO_overlay").style.display = "none"; hidingMsg = false;   };
   

            elND.addEventListener("webkitAnimationEnd", endFunc);
                     
            elND.addEventListener("animationend", endFunc);


            var elND = document.getElementById("ShowNewDash");


                var box = document.getElementById("TheCallout");



/*BGXXX
                var keyframeObj = createKeyframe({

                    100: {
                        left: "-1000px"
              

                    }
                });


                box.style.animationName = keyframeObj.name;

                insertCSS(keyframeObj.css, { id: keyframeObj.name })

*/
        } else {


            this.data = this.data.set("showMsg", false);
        }


         this.trigger(this.data);
    },

    showMsg: function () {
        var show = this.data.get("showMsg");
        return show;

    },

    isOldDash: function () {
        var b = window.localStorage.getItem("TheLatestDashUsed") == "1";
        return b;
    },


    fetchUtilData: function () {

        var a = this.data.get("allboardsData");
        a = a.get ("OrganisationList");

        var numOrgs = a.length;
        if (!a)
            return;
        for (var i = 0; i < numOrgs; i++) {

            for (var j = 0; j < a[i].DashboardList.length; j++) {
                if (a[i].UtilData == null) {
                   
 
                    

                    var pops = a[i].DashboardList[j].PopulationStatusList;
                    var popIDs = [];
                    for (var k = 0; k < pops.length; k++) {
                        popIDs.push(pops[k].PopulationID);
                    }

                    VakiAPI.GetFarmUtilizations({ FarmID: a[i].DashboardList[j].FarmID, pops: popIDs.join() });
                    return;

                }

                
            }
        }



    },



    /*
       public class UtilizationSummary
        {
            public int itemID = 0;
            public int day7Summary = 0;
            public int day30Summary = 0;
        }

        public class FarmUtilizations{
            public UtilizationSummary farmUtil = new UtilizationSummary();
            public List<UtilizationSummary> popUtils = new List<UtilizationSummary>();
        }
    */


    showAllOrgCheckbox: function(){
        var dl = this.data.get("allboardsData");
        if (!dl)
            return false;

        var orgs = dl.get("OrganisationList");
        if (orgs && orgs.length > 1)
            return true;

        return false;

    },


    onFarmClickedOnAllOrgs: function (org, farm) {

      //  console.log("-------------Click   Org:" + JSON.stringify(org));

      //  console.log("-------------Click   farm:" + JSON.stringify(farm));

    },

    /*
    onFarmClick: function (f) {
        console.log("----------------FARM CLICK---------------" + _showAllOrgs)
        if (_showAllOrgs) {
            this.onShowAllOrgs();

        }

    },
    */

    onNewComment: function (newComment) {


        //this.setUpComments(newDashboardData);
        //var immutableData = Immutable.Map(newDashboardData);
        //this.data = this.data.set("dashboardData", immutableData);

        var site = this.data.get("dashboardData");


   //console.log ("dashboardData:  "+JSON.stringify (site))    ; return; 

        var thecomments = site.get("CommentsList");
        /*
        FarmID: FilterStore.getSelectedFarmID(),
            PopulationID: 0,
                PertinentDateTime: str,
                    CommentText: theComment
        **************************************************
     
        {"Created_Datetime":"\/Date(1526972108650+0000)\/",
        "Pertinent_Datetime":"\/Date(1526256000000+0000)\/",
        "PopulationID":5931,
        "Text":"m03 ath ttttt",
        "Username":"bjarni@vaki.is"},
        */
        var c = {
            Created_Datetime: moment(),
            PopulationID: newComment.PopulationID,
            Pertinent_Datetime: moment(newComment.PertinentDateTime),
            Text: newComment.CommentText,
            Username: UserDataStore.getUserName()
        };
        thecomments.push(c);
        site = site.set("CommentsList", thecomments);


        this.data = this.data.set("dashboardData", site);


        this.trigger(this.data);

    },


    getUtilInfo: function (farmID) {
        var i = 0;

        cntDbg++;

       
  
        for (i = 0; i < UtilData.length; i++) {
            if (UtilData[i].farmUtil.itemID == farmID) {
                return UtilData[i];
               
            }
        }
        return null;
    },


    onShowCurrOrg: function () {

        var show = this.data.get("showCurrOrg");
        show = !show;
        this.data = this.data.set("showCurrOrg", show);

        if (!show) {
            this.trigger(this.data);
            return;
        }

      

        var updtTime = this.data.get("allboardsDataUpdated");

        var msUpdt = new Date() - updtTime;
      
        if (msUpdt > 30*60*1000) { //More than half an hour
            VakiAPI.getDashboardList();
        } else {
            this.trigger(this.data);
        }




        this.trigger(this.data);

    },

    showCurrOrg: function () {

        return this.data.get("showCurrOrg");
    },

    onGotFarmUtilizations: function (utilData) {

        var farmID = utilData.farmUtil.itemID;

        var b = this.data.get("allboardsData");
        var a = b.get("OrganisationList");
        var numOrgs = a.length;

        if (!a)
            return;

        var found = false;
        var nextFarm = -1;

        var par = {};
        for (var i = 0; i<numOrgs; i++) {

            for (var j = 0; (j < a[i].DashboardList.length) && (!found || nextFarm<0)   ; j++) {
                if (a[i].DashboardList[j].FarmID == farmID) {
                    a[i].DashboardList[j].UtilData = utilData
                    found = true;
                } else if (nextFarm < 0 && a[i].DashboardList[j].UtilData == null ) {
                    nextFarm = a[i].DashboardList[j].FarmID;
                    var pops = a[i].DashboardList[j].PopulationStatusList;
                    var popIDs = [];
                    for (var k = 0; k < pops.length; k++) {
                        popIDs.push(pops[k].PopulationID);
                    }
                    par = { FarmID: nextFarm, pops: popIDs.join() }

                }
            }

            if (found && nextFarm >= 0)
                break;
        }



        b = b.set("OrganisationList", a);
        this.data  = this.data.set("allboardsData", b);


        if (nextFarm >= 0)
            VakiAPI.GetFarmUtilizations(par);
     

        UtilsTempShortCnt++;



        if (((UtilsTempShortCnt >= 8) && (this.showCurrOrg() || this.showAllOrgs())) || nextFarm < 0) {

            UtilsTempShortCnt = 0;
            this.trigger(this.data)
        }




    },



    getIDLetter: function (name) {
        var first = name.substring(0, 1).toUpperCase();
        const theEnglish = "ABCDEFGHIJKLMOPQRSTUVWZXY";
        if (theEnglish.indexOf(first) > -1)
            return first;

        var ret = "I";
        switch (first) {
            case "Å":
            case "Á":
            case "Æ":
                ret = "A";
                break;
            case "É":

                ret = "E";
                break;
            case "Í":
                ret = "I";
                break;


            case "Ö":
            case "Ó":
            case "Ø":
                ret = "O";
                break;

            case "Ú":
            case "Ü":
                ret = "U";
                break;

            case "Ý":
                ret = "Y";
                break;
            case "Ñ":
                ret = "N";
                break;
            case "Þ":
                ret = "T";
                break;


            default:
                ret = "I";




        }
        return ret;

    },

    notifyMount: function () {

        var show = this.data.get("showMap");


        timerPar = this;
        if (show) {
            setTimeout(function () {
                timerPar.trigger(timerPar.data);

            }, 100);
        }

    },

    showMap: function (theDiv, msgDiv) {



        var currOrg = UserDataStore.getOrgFromID(FilterStore.getSelectedOrgID());


        var noLocFarms = "";
        var mapMsg = "";

        var locList = [];
        var numFarms = currOrg.FarmList.length;



        //clean upp markers
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];


        for (var i = 0; i < numFarms; i++) {


            var farm = currOrg.FarmList[i];
            if (!(farm.FarmLocation.Lon == 0 && farm.FarmLocation.Lat == 0)
                && (Math.abs(farm.FarmLocation.Lon) <= 180)
                && (Math.abs(farm.FarmLocation.Lat) <= 90)
            ) {

                var active = false;
                for (var j = 0; j < farm.PopulationList.length; j++) {
                    if (farm.PopulationList[j].CurrentlyActive) {
                        active = true;
                        break;
                    }
                }


                locList.push({
                    Name: farm.Name,
                    Lon: farm.FarmLocation.Lon,
                    Lat: farm.FarmLocation.Lat,
                    Active: active
                });

            } else {
                noLocFarms += farm.Name + "<br/>";
            }
        }


        var locDataOK = true;

        if (locList.length == 0) {
            mapMsg = t("dashboard.novalidloc");

            theDiv.style["display"] = 'none';

            locDataOK = false;
        }

        if (locDataOK && locList.length < numFarms) {
            mapMsg = t("dashboard.notallwithvalidloc") + "<br/>" + noLocFarms;

        }



        if (locDataOK) {
            var centerLoc = locList[0];
            theDiv.style["display"] = 'block';

            var selFarmLoc = FilterStore.getSelectedFarm().FarmLocation;


            if (!(selFarmLoc.Lon == 0 && selFarmLoc.Lat == 0)
                && (Math.abs(selFarmLoc.Lon) <= 180)
                && (Math.abs(selFarmLoc.Lat) <= 90)) {
                centerLoc = selFarmLoc;
            }



            var mapOptions = {
                zoom: 9,
                center: new google.maps.LatLng(centerLoc.Lat, centerLoc.Lon),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                fullscreenControl: true,
                scaleControl: true
            }

            mappCenter = mapOptions.center;

            //***************
            if (navigator.appVersion.indexOf("Trident") != -1 || navigator.appVersion.indexOf("Edge") != -1 || navigator.appVersion.indexOf("MSIE ") != -1) {
                //Seems to need this for IE browsers
                this.map = null;
            }
            //*****************



            if (this.map == null) {


                theDiv.innerHTML = '<div id="inner_map_div" style="width: 100%; height: 100%"></div>';
                this.map = new google.maps.Map(document.getElementById('inner_map_div'), mapOptions);
                mapp = this.map;
            } else {
                try {
                    this.map.setOptions(mapOptions);

                    theDiv.innerHTML = '';
                    theDiv.appendChild(this.map.getDiv())
                } catch (e) {
                    console.log("Exception in showMap: " + e)

                }

                /*
                            var bounds = this.map.getBounds();
                
                            var NE = bounds.getNorthEast();
                            var SW = bounds.getSouthWest();
                
                
                            if ((NE.lat() == SW.lat()) && (NE.lng() == SW.lng())) {
                            
                                //Happens when map has been hidden, window resized and map shown again
                                setTimeout(function(){
                                    google.maps.event.trigger(mapp,'resize')
                                    mapp.setCenter (mappCenter)
                                }, 50);
                            }
                */

            }

            var infowindow = new google.maps.InfoWindow();
            var marker, i;
            var selIndex = -1;
            for (i = 0; i < locList.length; i++) {
                var color = "82E0AA";
                var zIdx = 0;
                if (locList[i].Name == FilterStore.getSelectedFarm().Name) {
                    color = "EC7063";
                    zIdx = 1;
                } else if (!locList[i].Active) {
                    color = "E5E7E9";
                }

                /*
                       icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + this.getIDLetter(locList[i].Name)+'|'+color+'|000000',
                */

                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(locList[i].Lat, locList[i].Lon),
                    map: this.map,

                    icon: 'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=' + this.getIDLetter(locList[i].Name) + '|' + color + '|000000',

                    zIndex: google.maps.Marker.MAX_ZINDEX + zIdx
                });
                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infowindow.setContent(locList[i].Name);
                        infowindow.open(this.map, marker);
                    }
                })(marker, i));






                this.markers.push(marker);
            }
            if (this.markers.length > 1) {
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < this.markers.length; i++) {
                    bounds.extend(this.markers[i].getPosition());
                }
                this.map.fitBounds(bounds);
            }


        }

        if (mapMsg != "") {
            msgDiv.style["display"] = 'block';
            msgDiv.innerHTML = mapMsg;
        } else {
            msgDiv.style["display"] = 'none';
        }


    },


    onToggleMap: function () {

        var show = this.data.get("showMap");


        show = !show;
        this.data = this.data.set("showMap", show);

        if (show && this.map)
            this.notifyMount(); //Same issue here


        this.trigger(this.data);

    },





    getCurrentOrgDash: function () {
        var d = null;
        var oid = FilterStore.getSelectedOrgID();
        if (this.data) {
            var dl = this.data.get("allboardsData");

            if (dl) {

                var orgs = dl.get("OrganisationList")


                if (orgs) {

                    var len = orgs.length;
                    for (var i in orgs) {
                        if (orgs[i].OrganisationID == oid) {

                            d = orgs[i];
                            break;
                        }
                    }

                }
            }
        }
        return d;
    },

    onApiNewDashboardData: function (newDashboardData) {


        this.setUpComments(newDashboardData);
        var immutableData = Immutable.Map(newDashboardData);
        this.data = this.data.set("dashboardData", immutableData);

        

        if (!this.data.get("allboardsData") || !this.data.get("allboardsData").get("OrganisationList")) {
            this.fetchAllDashboards();

        }

        this.trigger(this.data);
    },







    getFarmUtilization: function (farm, def) {


        var FarmID = farm.FarmID;

        var len = UtilData.length;

   


        var i = 0;
        for (i = len - 1; i >= 0; i--) {
            if (UtilData[i].farmUtil.itemID == FarmID)
                break;
        }



        if (farm.UtilData ==  null) {
            return def;
        } else {
            var ret = farm.UtilData.farmUtil.day7Summary + " / " + farm.UtilData.farmUtil.day30Summary;

                 return ret;

        }
    },


    onNewDashboardList: function(newDashboardList) {



        var numOrgs = newDashboardList.length;

        for (var i= 0; i<numOrgs; i++){
          
           
            for (var j = 0; j < newDashboardList[i].DashboardList.length; j++) {
                newDashboardList[i].DashboardList[j]["UtilData"] = null;




            }
        }

        var obj = { OrganisationList: newDashboardList };


        var immutableData = Immutable.Map(obj);
        this.data = this.data.set("allboardsData", immutableData);
     
        this.data = this.data.set("allboardsDataUpdated", new Date());
       //Start the Utils fetch
        this.fetchUtilData();





        this.trigger(this.data);
    },

    setUpComments: function(newDashboardData) {
        try {
            newDashboardData.CommentsList.sort(this.compareDates);
            for (var i = 0; i < newDashboardData.CommentsList.length; ++i) {
                newDashboardData.CommentsList[i].PopulationName = FilterStore.getPopulationNameByID(newDashboardData.CommentsList[i].PopulationID) || "";
            }
        }catch(er){alert ("setUpComments Exc: "+er)}
    },

    /**
     * Causes a call to VakiAPI to fetch the newest state of a given farm.
     *
     * @param farmID
     */
    fetchDashboardForFarm: function (farmID) {
        if (!ParMan.isParDefined(_showAllOrgs_ID))
            _showAllOrgs = ParMan.initParameter(_showAllOrgs_ID, NavBarStore.showAllOrgs(), 'bool');

        VakiAPI.fetchDashboardForFarm(farmID);
    },


    /** 
    * Causes a call to VakiAPI to fetch a list of all farms
    */
    fetchAllDashboards: function() {
   
        VakiAPI.getDashboardList();
    },

    getDashboardData: function() {
        return this.data.get("dashboardData");
    },

    getAllBoardsData: function () {
        if (!this.data.get("allboardsData"))
            return null;
        return this.data.get("allboardsData").get("OrganisationList");
    },

    showAllOrgs: function() {
        return _showAllOrgs;
    },



    onShowAllOrgs: function() {
        _showAllOrgs = !_showAllOrgs;
        ParMan.setItem(_showAllOrgs_ID, _showAllOrgs);

        if (!_showAllOrgs) {
            this.trigger(this.data);
            return;
        }


        var updtTime = this.data.get("allboardsDataUpdated");

        var msUpdt = new Date() - updtTime;
      
        if (msUpdt > 30 * 60 * 1000) { //More than half an hour
            VakiAPI.getDashboardList();
        } else {
            this.trigger(this.data);
        }
    },

    compareDates: function(a,b) {
        return b.Created_Datetime.localeCompare(a.Created_Datetime);
    },






});

module.exports = DashboardStore;