"use strict";

var React = require("react");
import {InputGroup, FormControl, Tooltip, OverlayTrigger} from"react-bootstrap";
import validator from "validator";
var Actions = require("./../../actions/Actions.js");

var VConst = require("./../../utils/VConst.js");
import PropTypes from "prop-types";
var timervar = null;
var timerNewValue = null;


export default class MinHourCountFilter extends React.Component{
    /*
    static propTypes = {
      
        allowempty: PropTypes.bool,
        initValue : PropTypes.number
     };
*/
  	constructor(props) {
     super(props);




    this.isInputValid  = (inputStrValue) => {



        if (this.props.allowempty && (!inputStrValue || inputStrValue.toString() == ""))
            return true;
        if (!(typeof inputStrValue === 'string' || inputStrValue instanceof String)) 
            inputStrValue = inputStrValue.toString();
        if (validator.isInt(inputStrValue)) {

            var maxWeight = validator.toInt(inputStrValue);
        
            if (maxWeight >= 0) {
                return true;
            }
        }
    
        return false;
     };


      let initValue = "";


      if (this.props.initValue)
        initValue = this.props.initValue;



      let initErrorState = "";  

      if (!this.isInputValid(initValue))
        initErrorState = VConst.CLASS.EDIT_ERROR;

        this.state = {
            value: initValue,
            errorState: initErrorState
        };

    this.validationState = () => {
 
        if (this.isInputValid(this.state.value)) {
            // Render normally. In Bootstrap there is no "normal" style so if
            // we want to get just normal display (no red or yellow borders)
            // the only way I found was to return undefined.
            return "";
        }
        return VConst.CLASS.EDIT_ERROR;
     };




    this.handleChange = () => {


        var newValue = this.refs.input.value;





  

        if (timervar != null) {
            window.clearTimeout(timervar);
            timervar = null;
        }

        let errState = "";
        if (this.isInputValid(newValue)) {
 
            timervar = window.setTimeout(this.timerFunc,VConst.FILTER_EDIT_DELAY);
            timerNewValue = newValue;
            /*
              var newMaxWeight = validator.toInt(newValue);
            Actions.maxWeightUpdate(newMaxWeight);
            */
        }else{
            errState = VConst.CLASS.EDIT_ERROR;
        }

        this.setState({
            value: newValue,
            errorState: errState
        });

     };

     };//constructor

    UNSAFE_componentWillReceiveProps (newProps) {

        this.setState({ value: newProps.initValue });
     };






timerFunc (){
    var newVal = validator.toInt(timerNewValue);
    Actions.minHourCountUpdate(newVal);
    timervar = null;
 };

render () {

        let strVal = "";
        if (this.state.value)
            strVal = this.state.value;


  
if (this.props.hidden)
    return "";
//   variant={this.validationState()}


        return (

  <InputGroup className="XXmb-0">


    <InputGroup.Text id="inputGroup-sizing-lg">{this.props.t("filters.minHourCount")}</InputGroup.Text>

    <FormControl 
            className = {this.state.errorState}
                type = "text"
                ref = "input"
                value={strVal}
             
                onChange = {this.handleChange}
              
              
               
     />
  </InputGroup>





        );
        
    }
};



/*
            <RB.Input
                type = "text"
                ref = "input"
                addonBefore = {this.props.t("filters.maxWeight")}
                value={this.state.value}
                allowempty={this.props.allowempty}
                onChange = {this.handleChange}
                 variant={this.validationState()}
                placeholder="Auto"

            />
*/