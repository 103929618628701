/**

 *
 */

"use strict";

import React from "react";
import {Container, Row, Col, Button }from "react-bootstrap";
var BT = require("react-bootstrap-table");
import moment from "moment";
import FilterStore from "../../stores/FilterStore.js";
import PopCompareStore from "../../stores/PopCompareStore.js";
import SessionStore from "./../../stores/SessionStore.js";
import UserDataStore from "./../../stores/UserDataStore.js";

import MeasurementsStore from "./../../stores/MeasurementsStore.js";
import Spinner from "./../../utils/Spinner.jsx";

import Actions from "./../../actions/Actions.js";
import FileSaver from "file-saver";
import TranslateStore from "./../../stores/TranslateStore.js";
import VakiAPI from "./../../webservice/VakiAPI.js";






var t = TranslateStore.getTranslate();

var pops = [];
var joinedPops = [];

var newJoined = 0;


export default class JoinPops  extends React.Component{
    constructor(props) {
     super(props);
        this.state = {
            loading: false
        };

        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }
    this.onUserDataStoreChange = (userData, isLoading) => {

            this.setState({ loading: false });
        
    };





 



    this.succCallback= ()=> {

//        alert("You need to restart the browser to be able to select this newly created joined population from the dropdown list!");
        Actions.reloadUserData(true); //Should only be posted when some changes made
    
       // this.setState({loading:true});
    };

    this.errorCallback = (err) => {
        alert("Error during save: " + err);

        this.setState({
            loading: false
        });
    };

    this.handleJoinClick = () => {
        var str = "";

        var popGroup = PopCompareStore.getPopGroup();



        var popList = [];

       
        var cnt = 0;
        for (var row in pops) {
           
            if (pops[row].Selected) {
                if (cnt>0)
                    str += "+";
                str += pops[row].Name.trim();
                cnt++;
             
                popGroup.PopulationList.push({
                    PopulationId: pops[row].PopulationID,
                    SegmentId: pops[row].SegmentID
                });

            }
        }

        if (cnt <= 1) {
            alert(t("join.morepops"));
            return;
        }


       


        popGroup.Name = str;
        popGroup.Description = new moment().format("YYYY-MM-DD") + ", by: " + UserDataStore.getUserName();
        popGroup.NumPops = cnt;
        popGroup.FarmID = FilterStore.getSelectedFarmID();
        popGroup.Private=false;

       

        VakiAPI.postVirtualPopulation(PopCompareStore.mapGroupForServer(popGroup), this.succCallback, this.errorCallback);

        
        this.setState({loading: true });
    };


    this.handleDeleteClick = () => {
        var jPop = PopCompareStore.getPopGroup(); 
        var found = false;
        var cnt = 0;
       for (var row in joinedPops) {

           if (joinedPops[row].Selected) {
               found = true;
               jPop.VirtualPopulationId = joinedPops[row].PopulationID;
               jPop.Name = joinedPops[row].Name;
               break;

           }
           cnt++;
       }

       if (!found) {
           alert( t("join.nopops"))
           return;
       }

       var farm = FilterStore.getSelectedFarm();
       cnt = 0;
       for (var i = 0; i < farm.PopulationList.length; i++) {
           if (farm.PopulationList[i].PopulationID == jPop.VirtualPopulationId)
               break;
           cnt++;
       }

       farm.PopulationList.splice(cnt, 1);
       VakiAPI.deleteVirtualPopulation(PopCompareStore.mapGroupForServer(jPop), this.succCallback);
       this.setState({ loading:true });
    };






    };

 

    componentDidMount () {
        this.unsubscribeUserDataStore = UserDataStore.listen(this.onUserDataStoreChange);

    };


    componentWillUnmount () {
        this.unsubscribeUserDataStore();
    };






    render (str) {

                
        function onRowSelect(row, isSelected, e) {
            row.Selected = isSelected;
        }

        function onRowSelectJoined(row, isSelected, e) {
            /*
            for (var i = 0; i < joinedPops.length; i++)
                joinedPops.Selected = false;
            */
            row.Selected = isSelected;
        }


        var farm = FilterStore.getSelectedFarm();
        var fff = FilterStore. getSelected


        var farmName = <h3> {FilterStore.getSelectedOrgName()+ ",  " +farm.Name}  </h3> 

        if (!farm) {
            return (<div> {farmName}    --- {t("join.nosel")}  --- </div>)
        }

        if (!farm.PopulationList || farm.PopulationList.length < 2) {
            return (<div> {farmName} <p> --- {t("join.toofew")} --- </p> </div>)
        }

        pops = [];
        joinedPops = [];

        for (var i = 0; i < farm.PopulationList.length; i++) {
            var name = farm.PopulationList[i].Name;

            if (name[0] == "[") {
                joinedPops.push(farm.PopulationList[i])
                joinedPops[joinedPops.length - 1]["Selected"] = false;
            } else {
                pops.push(farm.PopulationList[i])
                pops[pops.length - 1]["Selected"] = false;
            }

        }

        const selectRowProp = {


            mode: 'checkbox',
            bgColor: 'lightblue', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
            hideSelectColumn: true,  // enable hide selection column.
            clickToSelect: true,
            onSelect: onRowSelect,
            selected: []
        };

        const selectRowPropJoined = {


            mode: 'radio',
            bgColor: 'lightblue', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
            hideSelectColumn: true,  // enable hide selection column.
            clickToSelect: true,
            onSelect: onRowSelectJoined,
            selected: []
        };

        var popsTable = "";
        var joinedTable = "";

  
        if (!this.state.loading) {
            popsTable = (
                <BT.BootstrapTable data={pops} selectRow={selectRowProp}>
                    <BT.TableHeaderColumn dataField='Name' >Population</BT.TableHeaderColumn>
                    <BT.TableHeaderColumn dataField='PopulationID' isKey hidden></BT.TableHeaderColumn>

                </BT.BootstrapTable>
                
            );

            joinedTable = (
                <BT.BootstrapTable data={joinedPops} selectRow={selectRowPropJoined}>
                    <BT.TableHeaderColumn dataField='Name'  >Population</BT.TableHeaderColumn>
                    <BT.TableHeaderColumn dataField='PopulationID' isKey hidden></BT.TableHeaderColumn>

                </BT.BootstrapTable>
            );
        }



    

        var theWholeStuff = <Spinner />;



                
        if (!this.state.loading){
            theWholeStuff = <div >

                <Container>
                <Row  >
                <div className="center-width100 mb-3">
                    {farmName}
                    </div>
                    </Row>
<Row>
                    <Col className=" mb-3" >
                            {t("join.tojoin")}
                            
                            {popsTable}
                            <Button onClick={this.handleJoinClick} block={"true"} variant="outline-dark">  {t("join.join")} </Button>
                        </Col>
 

                    <Col className="mb-3">
                            {t("join.todel")}
                            
                {joinedTable}
                <Button onClick={this.handleDeleteClick} block={"true"} variant="outline-dark">{t("join.del")} </Button>
                        </Col>
                </Row>
                    </Container>
                    <div id="divMessage">
                        <br />
                       
                    </div>
                </div>
            
        }
        

        return theWholeStuff;


        
    }
};




