/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 *  This store handles the logic behind the weight and time chart.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("./../actions/Actions.js");
var VConst = require("./../utils/VConst.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var NavBarStore = require("./NavBarStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var TranslateStore = require("./TranslateStore.js");
var ParMan = require("./ParMan.js");
var t = TranslateStore.getTranslate();


const startDate_ID = "WTS_startDate";
const endDate_ID = "WTS_endDate";


var suggMinWgt = -1;
var suggMaxWgt = -1;

var dtFirst = null;
var dtLast = null;


var _waitingForFirstFetch = false;
var _data = {};
var baseFontSize = 14;
var fetchOnDatechangeTimer = null;

var WeightAndTimeStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.apiNewWeightAndTimeData, this.onNewWeightAndTimeData);
    	this.listenTo(FilterStore, this.onFilterStoreChange);
        this.listenTo(TranslateStore, this.onTranslateStoreChange);
    	this.listenTo(Actions.watStartDateUpdate, this.onWatStartDateUpdate);
        this.listenTo(Actions.watEndDateUpdate, this.onWatEndDateUpdate);

        this.listenTo(Actions.watDateChange, this.onDateChange)


 

    	// By default, pick a range of # days in the past from now. We need
        // startOf("day") because the React-Bootstrap-Daterangepicker uses
        // dates from midnight by default.
        var numberOfDays = VConst.DEFAULT_WEIGHT_TIME_RANGE;
        var endDate = moment().endOf("day");
        var startDate = endDate.clone().subtract(numberOfDays, "days").startOf("day");

        var strStart = ParMan.initParameter(startDate_ID);
        var strEnd = ParMan.initParameter(endDate_ID);


        if (strStart)
            startDate = moment(strStart);

        if (strEnd)
            endDate = moment(strEnd);


        var filter = {
            startDate: startDate,
            endDate: endDate,
            minWeight: FilterStore.getMinWeight(),
            maxWeight: FilterStore.getMaxWeight()
        };

        _data = {
            filter: filter,
            weightAndTimeData: null,
            chartLoading: false,
            showSlowMsg: false,
            autoDates: false
        };
     //   this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);

      
        this.fetchWeightAndTimeGraph(true);
    },

    onFilterStoreChange: function(newFilter) {



        if (!newFilter)
            return;



  

        if (_data == null)
            return; 


        if ( _data.filter.minWeight != newFilter.minWeight || _data.filter.maxWeight != newFilter.maxWeight) {

            _data.filter.minWeight = newFilter.minWeight;
            _data.filter.maxWeight = newFilter.maxWeight;

            if (!_waitingForFirstFetch) {

                this.addMinMaxToChart(newFilter.minWeight, newFilter.maxWeight)
                this.trigger(_data, false);
                return;
            }
        }

        _waitingForFirstFetch = false;

   

        this.fetchWeightAndTimeGraph(true);


        this.trigger("", true);
       
    },

    /**
     * Set chart data expects raw data so we'll have to update translations
     * manually here.
     */
    onTranslateStoreChange: function () {
        /*
        if (_data.weightAndTimeData) {
            _data.weightAndTimeData.title.text = t("wat.chartHeader") + FilterStore.getSelectedPopulationName();
            _data.weightAndTimeData.yAxis[0].title.text = t("wat.leftYAxis");
            _data.weightAndTimeData.yAxis[1].title.text = t("wat.rightYAxis");
            _data.weightAndTimeData.series[0].name =  t("wat.fish");
            _data.weightAndTimeData.series[1].name =  t("wat.voltage");
        }

        this.trigger(_data, false, true);
        */
    },



    addMinMaxToChart: function (minW, maxW)
    {

        if (!_data || !_data.weightAndTimeData || dtFirst == null)
            return;

        if (suggMaxWgt < 0 || suggMinWgt < 0)
            return;  //Something wrong with received data

        if (!minW)
            minW = suggMinWgt;
        if (!maxW)
            maxW = suggMaxWgt;


        if (_data.weightAndTimeData.series.length == 3) {

            _data.weightAndTimeData.series.push({
                name: "Min",
                marker: {
                    enabled: false
                },
                lineWidth:0.5,
                type: "line",
                yAxis: "weight",
                color: "#ff6961",
                data: []
            });
            _data.weightAndTimeData.series.push({
                name: "Max",
                marker: {
                    enabled: false
                },
                type: "line",
                lineWidth: 0.5,
                yAxis: "weight",
                color: "#ff6961",
                data: []
            });

        }

        if (_data.weightAndTimeData.series.length == 5) {

            var absFirst = moment(dtFirst).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();

            var absLast = moment(dtLast).set({ hour: 23, minute: 59, second: 59 }).valueOf();



            _data.weightAndTimeData.series[3].data = [[absFirst, minW], [absLast, minW]];
            _data.weightAndTimeData.series[3].name = "Min Weight (" + minW + ")";
            _data.weightAndTimeData.series[4].data = [[absFirst, maxW], [absLast, maxW]];
            _data.weightAndTimeData.series[4].name = "Max Weight (" + maxW + ")";
        }

    },

    onNewWeightAndTimeData: function (data) {

        if (!data || !data.Weights || !data.Depths || !data.AvgWeights) {
            //something wrong on serverside.  should at least get empty arrays
            console.error("WT ERRor.  NULL list(s) from server.  Weights:" + data.Weights+"    Depths:"+data.Depths+"      AvgW:"+data.AvgWeights);

            _data.weightAndTimeData = null;
            this.trigger(null, false);
            return;
        }
        
        if (data.Weights.length == 0 && data.Depths.length == 0 && data.AvgWeights.length == 0) {
            //No data available
            _data.weightAndTimeData = null;
            this.trigger(null, false);
            return;
        }


    suggMinWgt = data.suggMinWgt;
    suggMaxWgt = data.suggMaxWgt;
    var autoDates = data.autoDates;

    if (autoDates) {
        _data.filter.startDate = moment(data.dtFirst);
        _data.filter.endDate = moment(data.dtLast);
    }



    dtFirst = moment(data.dtFirst).valueOf();
    dtLast = moment(data.dtLast).valueOf();




    this.setChartData(data);
   

    _data.autoDates = data.autoDates;
    this.addMinMaxToChart(FilterStore.getMinWeight(), FilterStore.getMaxWeight());


    	this.trigger(_data, false, true);
    },

    onWatStartDateUpdate: function (newStartDate) {
        if (newStartDate)
            ParMan.setItem(startDate_ID, newStartDate.format())

        _data.filter.startDate = newStartDate;
  

        this.fetchWeightAndTimeGraph(false);
        this.trigger("", true);
    },

    onWatEndDateUpdate: function (newEndDate) {
        if (newEndDate)
            ParMan.setItem(endDate_ID, newEndDate.format())
        
        _data.filter.endDate = newEndDate;


        this.fetchWeightAndTimeGraph(false);
        this.trigger("", true);
    },

    onDateChange: function (startDate, endDate) {
        if (_data.filter.endDate != endDate) {
            ParMan.setItem(endDate_ID, endDate.format());
            _data.filter.endDate = endDate;
        }

        if (_data.filter.startDate != startDate) {
            ParMan.setItem(startDate_ID, startDate.format())
            _data.filter.startDate = startDate;
        }
        this.fetchWeightAndTimeGraph(false);
        this.trigger("", true);



    },



    setChartData: function (data) {
        if (data === null || data === undefined) {
            _data.weightAndTimeData = undefined;
            return;
        }
      //  var startDate = moment(_data.filter.startDate);
        // endDate is the last date found in the data list we get
        var endDate = moment(data.dtLast);
        var startDate = moment(data.dtFirst);

    
        /*
        var minWeight = _data.filter.minWeight;
        if (minWeight <= 100) minWeight = 0;
        */
        var numberOfDays = endDate.diff(startDate, "days");
        var plotLines = [];
        for (var i = 0; i < numberOfDays; ++i) {
            plotLines.push({
                value: startDate.clone().add(i, "days"),
                width: 2,
                color: "#E0E0E0"
            });
            plotLines.push({
                value: startDate.clone().add(i, "days").add(12, "hours"),
                width: 1,
                color: "#EEEEEE"
            });
        }

        var dataPoints = this.getDataPoints(data);

        var numMeasurements = dataPoints.weightPoints.length;

        var subTitle = startDate.format("YYYY-MM-DD") + " to " + endDate.format("YYYY-MM-DD") + ", " + numMeasurements + " weight measurements";

        var span = endDate.diff(startDate);
        var oneDay = 24 * 60 * 60 * 1000;
        span = (span + oneDay / 3) / oneDay;


        var theHours = 6;
        if (span > 6)
            theHours = 24;
        else if (span > 3)
            theHours = 12;

        const el = document.querySelector('.HiChartsBaseFontSize');

   

      var fsNum = 13;
        if (!(!el))
            fsNum = parseInt(getComputedStyle(el).fontSize);
            
        baseFontSize = fsNum;

        _data.weightAndTimeData = {
            chart: {
                height: "500px",
                type: "scatter",
                zoomType: "xy",
                renderTo: ""
                /*
                ,
           
                margin: [70, 70,90,70]
                */
            }  ,

            navigation: {
                buttonOptions: VConst.CONTEXT_BUTTON_SHAPE

            },
            exporting: {

                filename: "BMD_WeightAndTime " + FilterStore.getSelectedPopulationName() + " " + subTitle
            },
            title: {
                text: t("wat.chartHeader") + FilterStore.getSelectedPopulationName(),
                style: { fontSize: (baseFontSize * 1.1) + "px" }
            },

            subtitle: {
                text: subTitle,
                style: { fontSize: (baseFontSize * 0.86) + "px" }
            },
            credits: false,
            // Defines a list of the x-axes we want
            xAxis: {
                type: "datetime",
                title: {
                    text: ""
                },
                labels: {
                    style: {
                        fontSize: (fsNum * 0.78) + "px"
                    }
                },

                gridLineWidth: 1,
               
                min: startDate.utc().valueOf(),
                max: endDate.utc().valueOf(),
        
                units: [
                    ['hour', [theHours]]
                    ]
                //tickInterval
                //plotLines: plotLines
            },
            yAxis: [
                {
                    id: "weight",
                    title: {
                        text: t("wat.leftYAxis"),
                        style: { fontSize: (baseFontSize * 0.78) + "px" }

                    },
                                    labels: {
                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }
                    }
                    //min: _data.filter.minWeight
                },
                {
                    id: "depth",
                    opposite: true,
                    showEmpty: false,
                    title: {
                        text: "Depth (m)"
                    },
                    labels: {
                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }
                    },
                    min: 0

                }

            ],
            tooltip: {
                shared: true,
                formatter: function () {

                    var strDate = "</b><br/>" + Highcharts.dateFormat("%H:%M %e. %b %Y", new Date(this.x)) + "<br/><b>";

                    if (!this.series)
                        return;
         

                    return "<b>" + this.series.name + "    " + strDate + "    " + this.y + "</b>";


                    }
                


            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    fontSize: (fsNum * 0.86) + "px",
                    fontWeight: "normal"
                },
               // floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
                borderWidth: 1
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 3,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: "<b>{series.name}</b></br>",
                        pointFormat: "{point.x}, {point.y} " + t("wat.grams")
                    }
                }
            },
            series: [
                {
                    name: t("wat.fish"),
                    yAxis: "weight",
                    color: "rgba(119, 152, 191, .5)",
                    data: dataPoints.weightPoints
                },
                {
                    name: t("wat.depth"),
                    yAxis: "depth",
                    color: "#77dd77",
                    data: dataPoints.depthPoints,
                    marker: {
                        radius: 6
                    },

                    visible: false
                },
                {
                    name: "Average weight pr/day",
                    yAxis: "weight",
                    color: "#F0FF03",
                    data: dataPoints.avgPoints,
                    marker: {
                        radius: 6,
                        symbol: "diamond", // "triangle-down",
                        lineWidth: 0.4,
                        lineColor:"#000000"
                    },

                    visible: true
                }

            ]

        };

       
    },


    
    notifyMounting: function () {

        var selID = FilterStore.getSelectedPopulationID()
        if (!selID || selID <= 0) {
            //Too early call
            _waitingForFirstFetch = true;
            return;
        }

        if (_data && _data.weightAndTimeData) {
            if (_data.weightAndTimeData.PopulationID == FilterStore.getSelectedPopulationID()) {
                return;
            }
        }
        this.fetchWeightAndTimeGraph(true);
        

    },



    getDataPoints: function (data) {
        var dataPoints = { weightPoints: [], depthPoints: [], avgPoints: [] };
        if (data.Depths)
            for (var i = 0; i < data.Depths.length; i++)
                dataPoints.depthPoints.push([moment(data.Depths[i].Datetime).valueOf(), data.Depths[i].Depth]);
        if (data.Weights)
            for (var i = 0; i < data.Weights.length; i++)
                dataPoints.weightPoints.push([moment(data.Weights[i].Datetime).valueOf(), data.Weights[i].Weight]);
        if (data.AvgWeights)
            for (var i = 0; i < data.AvgWeights.length; i++)
                dataPoints.avgPoints.push([moment(data.AvgWeights[i].Datetime).valueOf(), data.AvgWeights[i].Weight]);


/*

    	for(var i = 0; i < data.length; ++i) {
    		var date = moment(data[i].Datetime);
    		dataPoints.weightPoints.push([
    			date.valueOf(),
    			data[i].Weight
    		]);
            dataPoints.voltagePoints.push([date.valueOf(), data[i].Voltage]);
            dataPoints.depthPoints.push([date.valueOf(), data[i].Depth]);

    	}
        */
    	return dataPoints;
    },

    fetchWeightAndTimeGraph: function (autoDates) {
        _data.chartLoading = true;

        var strStartDate = "";
        var strEndDate = "";
        
        if (!autoDates) {
            strStartDate = _data.filter.startDate.format(VConst.DATE_FORMAT);
            strEndDate = _data.filter.endDate.format(VConst.DATE_FORMAT);
        }

        var selectedPopulationID = FilterStore.getSelectedPopulationID();
        if (selectedPopulationID && selectedPopulationID !== 0) {
            VakiAPI.fetchWeightAndTimeGraphData(
                selectedPopulationID,
                strStartDate,
                strEndDate,
                _data.filter.minWeight,
                _data.filter.maxWeight
            );
        }
    },

    isLoading: function() {
        return _data.chartLoading;
    },

    showSlowMsg: function() {
        return _data.showSlowMsg;
    },

    getFilter: function() {
        return _data.filter;
    },

    getWatData: function() {
        return _data.weightAndTimeData;
    },

    clone: function(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }
    
});

module.exports = WeightAndTimeStore;