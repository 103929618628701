/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to edit a new user
 * A user must have a userTypeID which represents him either being an admin, 
 * Vaki employee or a customer. Admins and Vaki Employees have access to everything.
 * A user must also have at least one role, fx. Basic User, Feed Data user and so forth.
 * Organisations and farms can also be added to a user, giving him access to information on
 * each selected option.
 */

"use strict";

var Reflux = require("reflux");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentEditUser = { };

var EditUserFormStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.userListRowClick, this.onUserListRowClick);
        this.listenTo(Actions.editUserFormSubmit, this.onEditUserFormSubmit);
        this.listenTo(Actions.apiAdminViewDataReady, this.onDataReady);

        _currentEditUser = {
            id: 0,
            firstName: "",
            lastName: "",
            username: "",
            mobile: "",
            email: "",
            jobTitle: "",
            password: "",
            userTypeID: 0,
            roles: [],
            organisations: [],
            farms: [],
            selectedRoles: [],
            selectedOrganisations: [],
            selectedFarms: []
        };
        
    },
    onDataReady: function () {

      
        _currentEditUser.roles = AdminViewStore.getAllRolesCloned();
        _currentEditUser.organisations = AdminViewStore.getAllOrganisationsCloned();
        _currentEditUser.farms = AdminViewStore.getAllFarmsCloned();
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {
    	if(view === "editUser") {
    		if(type === "role") {
	            this.onDropdownRoleSelect(data);
	        } else if(type === "organisation") {
	            this.onDropdownOrgSelect(data);
	        } else if(type === "farmOrg") {
	            this.onDropdownFarmSelect(data);
	        }
    	}
        
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "editUser") {
    		if(type === "role") {
	            this.onRemoveRole(data);
	        } else if(type === "organisation") {
	            this.onRemoveOrg(data);
	        } else if(type === "farmOrg") {
	            this.onRemoveFarm(data);
	        }
    	}
    },
    /*
    * These functions are triggered when a user selected an item from a dropdown menu
    * Removes the item from the options array and adds it to the selected options array
    */
    onDropdownRoleSelect: function(role, user) {
        var roleIndex = _currentEditUser.roles.indexOf(role);
        if(roleIndex !== -1) {
            _currentEditUser.roles.splice(roleIndex, 1);
        }
        _currentEditUser.selectedRoles.push(role);
        this.trigger(_currentEditUser);
        
    },
    onDropdownOrgSelect: function(org) {
        var orgIndex = _currentEditUser.organisations.indexOf(org);
        if(orgIndex !== -1) {
            _currentEditUser.organisations.splice(orgIndex, 1);
        }
        _currentEditUser.selectedOrganisations.push(org);
        /* Adding all farms linked to this organisation */
        for(var i = 0; i < org.farmList.length; ++i) {
            for(var j = 0; j < _currentEditUser.farms.length; ++j) {
                if(org.farmList[i].id === _currentEditUser.farms[j].id) {
                    _currentEditUser.farms.splice(j, 1);
                    _currentEditUser.selectedFarms.push(org.farmList[i]);
                }
            }
        }
        this.trigger(_currentEditUser);
    },
    onDropdownFarmSelect: function(farm) {
        var farmIndex = _currentEditUser.farms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentEditUser.farms.splice(farmIndex, 1);
        }
        _currentEditUser.selectedFarms.push(farm);
        this.trigger(_currentEditUser);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveRole: function(role) {
        var roleIndex = _currentEditUser.selectedRoles.indexOf(role);
        if(roleIndex !== -1) {
            _currentEditUser.selectedRoles.splice(roleIndex, 1); // Removing role from selected roles
        }
        _currentEditUser.roles.push(role); // Adding role to options array
        _currentEditUser.roles.sort(this.compare);
        this.trigger(_currentEditUser);
    },
    onRemoveOrg: function(org) {
        /* Removing all farms linked to this organisation */
        for(var i = 0; i < org.farmList.length; ++i) {
            for(var j = 0; j < _currentEditUser.selectedFarms.length; ++j) {
                if(org.farmList[i].id === _currentEditUser.selectedFarms[j].id) {
                    _currentEditUser.selectedFarms.splice(j, 1);
                    _currentEditUser.farms.push(org.farmList[i]);
                }
            }
        }
        var orgIndex = _currentEditUser.selectedOrganisations.indexOf(org);
        if(orgIndex !== -1) {
            _currentEditUser.selectedOrganisations.splice(orgIndex, 1);
        }
        _currentEditUser.organisations.push(org);
        _currentEditUser.organisations.sort(this.compare);
        this.trigger(_currentEditUser);
    },
    onRemoveFarm: function(farm) {
        var farmIndex = _currentEditUser.selectedFarms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentEditUser.selectedFarms.splice(farmIndex, 1);
        }
        _currentEditUser.farms.push(farm);
        _currentEditUser.farms.sort(this.compare);
        this.trigger(_currentEditUser);
    },
    /* Triggered when the user submits the form, assembles the user and passes it to the parent store */
    onEditUserFormSubmit: function(firstName, lastName, username, mobile, email, jobTitle, password, userTypeID) {
    	_currentEditUser.firstName = firstName;
        _currentEditUser.lastName = lastName;
        _currentEditUser.username = username;
        _currentEditUser.mobile = mobile;
        _currentEditUser.email = email;
        _currentEditUser.jobTitle = jobTitle;
        _currentEditUser.password = password;
        _currentEditUser.userTypeID = userTypeID;
    	Actions.newUserCreated(_currentEditUser);

        this.trigger(_currentEditUser);
    },
    /*
    * Helper function used when sorting
    */
    compare: function(a,b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    },
    /*
    * When a user is selected in the table to the left, his information
    * is displayed in the edit user table, ready for editing.
    */
    onUserListRowClick: function(user) {
    	_currentEditUser.id = user.id;
        _currentEditUser.firstName = user.firstName;
        _currentEditUser.lastName = user.lastName;
        _currentEditUser.username = user.username;
        _currentEditUser.mobile = user.mobile;
        _currentEditUser.email = user.email;
        _currentEditUser.jobTitle = user.jobTitle;
        _currentEditUser.password = user.password;
        _currentEditUser.userTypeID = user.userTypeID;
        _currentEditUser.selectedRoles = user.roles.slice();
        _currentEditUser.selectedOrganisations = user.organisations;
        _currentEditUser.selectedFarms = user.farms;
        _currentEditUser.roles = AdminViewStore.getAllRolesCloned();
        _currentEditUser.organisations = AdminViewStore.getAllOrganisationsCloned();
        _currentEditUser.farms = AdminViewStore.getAllFarmsCloned();
        this.filterSelectOptions(_currentEditUser.selectedRoles, _currentEditUser.roles);
        this.filterSelectOptions(_currentEditUser.selectedOrganisations, _currentEditUser.organisations);
        this.filterSelectOptions(_currentEditUser.selectedFarms, _currentEditUser.farms);
        this.trigger(_currentEditUser);
    },
    getCurrentEditUser: function() {
        return _currentEditUser;
    },
    /*
    * Helper function that removes all selected options from the options array
    */
    filterSelectOptions: function(selectedOptions, options) {
    	for(var i = 0; i < selectedOptions.length; ++i) {
        	for(var j = 0; j < options.length; ++j) {
        		if(selectedOptions[i].name === options[j].name) {
        			options.splice(j, 1);
        		}
        	}
        }
    }
    
});

module.exports = EditUserFormStore;