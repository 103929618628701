/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

//var React = require("react")
/*
var Router = require("react-router");
*/
//var ReactDOM = require ("react-dom")

// or using ES Modules
//import { Router, Route } from 'react-router';



import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './components/App.jsx';
import MenuNavigation from './components/MenuNavigation.jsx';
//import Login  from "./components/Login/Login.jsx";

var QA = require('./components/QA/QA.jsx');

//var RouteStore = require("./stores/RouteStore.jsx");
//import {RouterStore} from "./stores/RouteStore.jsx";

//var router = RouteStore.getRouter();

//ReactDOM.render("GGGGGGGGGGGGGGGGGGGGGGGGGG", document.getElementById("reactContent"));
/*
router.run(function(Handler, state) {
    ReactDOM.render(<Handler />, document.getElementById("reactContent"));
});
*/
//var App = "ASDASDASDASDASDSA";
/*
ReactDOM.render((
  <Router>
    <Route path="/" component={App}/>
  </Router>
), document.getElementById("reactContent"));
*/

//<Route name="qa" path="/" component={QA} />;
//var menuNavigation = <MenuNavigation loggedInUserName={userName} isAdmin={isAdmin}  isEmployee = {isEmployee} />;
//var menuNavigation = <MenuNavigation loggedInUserName={'Bjarni'} />;
//<Route name="qa" path="/" component={QA} />






ReactDOM.render(<App />, document.getElementById('reactContent'));
