'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, FormControl } from 'react-bootstrap';
import Spinner from './../../utils/Spinner.jsx';
import VakiAPI from '../../webservice/VakiAPI.js';
import SearchDropdownCamera from "./SearchDropdownCamera.jsx"
import BootstrapTable from 'react-bootstrap-table-next';
import Actions from '../../actions/Actions.js';
var UserDataStore = require('./../../stores/UserDataStore.js');

require('./CameraManagement.css');



export default function MoveMeasurements(props) {


const [ alertMsg, setAlertMsg ] = useState({ msg: '', kind: '' });
const [periodSelected, setPeriodSelected ] = useState(false);
const [useDay, setUseDay] = useState(true);
const [loading, setLoading] = useState(false);
const [TheData, setTheData] = useState (null);
const [moveFromLast, setMoveFromLast] = useState (true);
const [disableMoveButton, setDisableMoveButton]= useState(true);

const TheTableRef = useRef(null);

useEffect(() =>{

   

    setPeriodSelected(false);

    setUseDay(true);

    setTheData (null);
    setAlertMsg({ msg: '', kind: '' })
  if (!props.Show || props.Device_ID < 0)
    return;

 //VakiAPI.GetMoveMeasurementData( {Device_ID: props.Device_ID}, GetCameraSetupLogOnDeviceCallback);
    



 
  }, [props.Show, props.Device_ID]);





    if (!props.Show)
        return "";



        const getDataCallback = (data) => {
            setLoading(false);

      

            if (data.msg.length > 0){

                if (data.msg.substring(0, 5) == 'Error') {
                    setAlertMsg({
                        msg:  data.msg,
                        kind: 'danger'
                    });

                }else {
                    setAlertMsg({
                        msg:  data.msg,
                        kind: 'info'
                    });    
                }
                return;
           }
           setTheData (data);

            
        };

        const fetchData = (dayres) =>  {

            setLoading (true);
        

            var params = {
                Device_ID: props.Device_ID,
                bDayRes: dayres
      
            }
            VakiAPI.GetMoveMeasData(params, getDataCallback);

        };     



        const checkboxFormatter = (cell, row)=>{
  
            return  <div className="center-width100 "> <Form.Check	checked={cell} className="mb-4" readOnly /> </div>; 
                     
          }
          
          const columns = [
      

                  {
                      dataField: 'CurrCnt',
                      text: 'Current'
                  },
                  {
                    dataField: 'Time',
                    text: '',
                  }, 
                  {
                      dataField: 'PrecCnt',
                      text: 'Preceeding',
                      
                  }
                ];
                  

    

        const findEntry = (o, arr) => {
            let len = arr.length;

            for (let i = 0; i<len; i++){
                if (o.Hour == arr[i].Hour && o.Date == arr[i].Date)
                    return arr[i];
            }
            return null;
        } 

 

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true
          };

        const getTheTable = () =>{

            let currArr = TheData.lstPC[0].lstNums;
            let lastArr = TheData.lstPC[1].lstNums;
            let lenCurr = currArr.length;
            let lenLast = lastArr.length;

            let TheMoveTable = [];
            if ( moveFromLast && useDay){


                for (let i = 0; i<lenLast; i++){
                    let curr = findEntry(lastArr[i], currArr);
                    let currNumMeas = 0;
                    if (curr)
                        currNumMeas = curr.NumMeasurements;
                    
                    TheMoveTable.push ({
         
                        Time: lastArr[i].Date,
                        CurrCnt: currNumMeas,
                        PrecCnt: lastArr[i].NumMeasurements
                    });
                }
            }else if (!moveFromLast && useDay){

                for (let i = lenCurr-1; i>=0; i--){
                    let last = findEntry(currArr[i], lastArr);
                    let lastNumMeas = 0;
                    if (last)
                        lastNumMeas = last.NumMeasurements;
                    
                    TheMoveTable.push ({
         
                        Time: currArr[i].Date,
                        CurrCnt: currArr[i].NumMeasurements,
                        PrecCnt: lastNumMeas
                    });
                }

            } else if (moveFromLast && !useDay){
                let strDT = lastArr[0].Date;
                for (let i = 23; i >= 0; i--){
                    let o = {Date: strDT, Hour: i};
                    let curr = findEntry (o, currArr);
                    let last = findEntry (o, lastArr);
                    let currMeas = 0
                    if (curr)
                        currMeas = curr.NumMeasurements;
                    let lastMeas = 0
                    if (last)
                        lastMeas = last.NumMeasurements;


                    TheMoveTable.push({
                        Time: strDT+" "+(i<10?"0"+i:i),
                        CurrCnt: currMeas,
                        PrecCnt: lastMeas,
                    })

                }    

            }else if (!moveFromLast && !useDay){
                let strDT = currArr[0].Date;
                for (let i = 0; i <= 23; i++){
                    let o = {Date: strDT, Hour: i};
                    let curr = findEntry (o, currArr);
                    let last = findEntry (o, lastArr);
                    let currMeas = 0
                    if (curr)
                        currMeas = curr.NumMeasurements;
                    let lastMeas = 0
                    if (last)
                        lastMeas = last.NumMeasurements;


                    TheMoveTable.push({
                        Time: strDT+" "+(i<10?"0"+i:i),
                        CurrCnt: currMeas,
                        PrecCnt: lastMeas,
                    })

                }    

            }

        
            let theTable= 
            <div>
                <p width="40em"> Click rows to select time period(s). Then click <b>Do Move</b> button below to do the transfer. 
                Modifications won't be reflected in the Trendgraph and Distribution until after hourly background process. 
                Population should be set <i>'Active'</i> to participate in the background process </p>
                
                
            <BootstrapTable
            keyField="Time"
            ref = {TheTableRef}
            data={TheMoveTable}
            columns={columns}
            selectRow={{
                mode: 'checkbox',
                backgroundColor: 'blue',

                clickToSelect: true,


                style: { background: '#def3ff' }
            }}

       
            /*
            selectRow={ selectRow }
            
            mode="radio"
            noDataIndication="Table is Empty"
            size="sm"
            className="small"
            bordered={true}
            
    
        
            rowEvents={rowEvents}
            
            selectRow={{
                mode: 'radio',
                backgroundColor: 'blue',

                clickToSelect: true,


                style: { background: '#def3ff' }
            }}
*/
        />
        </div>

            return theTable;
        }


        const onMoveFromChange = () =>{

            if (TheTableRef && TheTableRef.current && TheTableRef.current.selectionContext && TheTableRef.current.selectionContext.selected ) 
                TheTableRef.current.selectionContext.selected = [];
            let tmp = moveFromLast;
            setMoveFromLast (!tmp);



        }

 

        const putMovesCallback = (msg) => {
            if (msg != "Success"){

                setAlertMsg({
                    msg:  msg,
                    kind: 'danger'
                });
                return;
            }

            setPeriodSelected(false);
            setTheData (null);
            setAlertMsg({
                msg:  "Move of measurements successful",
                kind: 'info'
            });           
        }

        const onMoveClick = () => {
  

            setAlertMsg({
                msg:  "",
                kind: ""
            });

            if (!(TheTableRef && TheTableRef.current && TheTableRef.current.selectionContext && TheTableRef.current.selectionContext.selected && TheTableRef.current.selectionContext.selected.length>0) ){
              
                alert ("Nothing selected for moving!");
                return;
            }
           




            let list =  TheTableRef.current.selectionContext.selected;

            let from = TheData.lstPC[1].lstNums;
            if (!moveFromLast)
                from = TheData.lstPC[0].lstNums;

            let len = list.length;
            let cnt = 0;
         
            for (let i = 0; i<len; i++){
                let strDate = list[i].substring(0,10);
                let hr = 0;
                if (!useDay){
                    let str = list[i].substring (11,13);
                    hr = parseInt(str);
                }
                let rec = findEntry ({Date: strDate, Hour:hr}, from);
                if (rec && rec.NumMeasurements > 0){
   
                    cnt++;
                }
            }   
            
            if (cnt == 0){
                alert ("No measurements selected!");
                return;
            }

            let pFrom = TheData.lstPC[1].PopID;
            let pTo= TheData.lstPC[0].PopID;
            if (!moveFromLast){
                let ppp = pTo;
                pTo = pFrom;
                pFrom = ppp;
            }

            let params = {
                MoveList: list,
                PopTo: pTo,
                PopFrom: pFrom,
                Device_ID: props.Device_ID, 
      
            }

 


            VakiAPI.PutMoveMeas(params, putMovesCallback);

            

        }

        let TheSpinner = "";
        if (loading){
            TheSpinner = <div id="spinnerDiv"> <Spinner/></div>;
        }




        let ThePops = "";
        let TheTable = ""; 
        let TheMoveButton = ""; 
        if (TheData && TheData.msg==""){
            

            let len = TheData.lstPC[0].lstNums.length;
            let strCurr = "No measurements found";
            if (len>0){
                strCurr = "First measuments "+TheData.lstPC[0].lstNums[0].Date;
            }
            len = TheData.lstPC[1].lstNums.length;
            let  strLast = "No measurements found";
            if (len>0){
                strLast = "Last measuments "+TheData.lstPC[1].lstNums[0].Date;
            }


            ThePops = <div id = "ThePops">
                    <div className = "mm_OnePop">   
                       
                        { "Current population: "+TheData.lstPC[0].PopName}  <br/>
                        {"Farm: "+TheData.lstPC[0].FarmName}  <br/>
                         {"Connected: "+ TheData.lstPC[0].strTimeConnected}  <br/>

                      {strCurr}<br/>
<div className="mm_CheckBox">
                      <Form.Check 
                      reverse = {"true"}
                      checked = {!moveFromLast}
                      onChange = {onMoveFromChange}
            type={'checkbox'}
            id={'cbCurr'}
            label={`Move from this population`}
          />
</div>
                    </div>

                    <div className = "mm_OnePop">   
                         
                        { "Preceeding population: "+TheData.lstPC[1].PopName}  <br/>
                        {"Farm: "+TheData.lstPC[1].FarmName}  <br/>
                         {"Connected: "+ TheData.lstPC[1].strTimeConnected}  <br/>
                       {strLast}<br/>
                       <div className="mm_CheckBox"> 
                      <Form.Check 
                      reverse = {"true"}
                      checked={moveFromLast}
                      onChange = {onMoveFromChange}
            type={'checkbox'}
            id={'cbLast'}
            label={`Move from this population`}
          />
          </div>
                    </div>                    

                </div>;

              
                TheTable =  getTheTable();
   
                TheMoveButton = <Button 
                    onClick={onMoveClick} 
                    variant="outline-dark" 
                    disabled = {false && disableMoveButton }>
				                Do Move
			                    </Button>
        }




	return (
		<div  className="cs-MoveMeasWrap">

<h3 className="center-width100">  Move measurements between populations for camera #<i>{ props.Serial_Number}  </i>  </h3>  

<div id="ResoltionText">
<p >You must first select the <b>resolution </b> of the measurement move.  Either select that the moved data is in clusters of day's long periods or an hour  </p>
</div>

				<Form.Group className=" mb-3">
					<Form.Label className="mb-0 ">Resolution: </Form.Label>
                    <Form>
      
          <Form.Check 
            inline
            disabled = {periodSelected}
            type={'radio'}
            id={`day`}
            onChange = {(e) => {setPeriodSelected(true); setUseDay(true); fetchData (true); setAlertMsg({msg:"", kind:""})}}
            label={`A Day`}
            checked= {periodSelected & useDay}
          />

          <Form.Check
            inline
            disabled = {periodSelected}
            onChange = {(e) => {setPeriodSelected(true); setUseDay(false); fetchData(false);}}
            type={'radio'}
            label={`An Hour`}
            id={`hour`}
            checked={periodSelected & !useDay}
          />
       
 
    </Form>
				</Form.Group>

                <Alert variant={alertMsg.kind} hidden={alertMsg.msg == ''}>
					{' '}
					{alertMsg.msg}{' '}
				</Alert>
{TheSpinner}

{ThePops}

<div className = "mm_TableWrap mt-3">
{TheTable}
{TheMoveButton}
</div>
<br/> <br/>
		</div>
	);
}
