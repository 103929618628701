/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to create a new farm.
 * Along with basic information, a farm has a list of users that have access to it
 * and the Organisation that it belongs to.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentFarm = { };

var CreateFarmFormStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.createFarmFormSubmit, this.onCreateFarmFormSubmit);
        this.listenTo(Actions.apiAdminViewDataReady, this.onDataReady);

        _currentFarm = {
            name: "",
            email: "",
            vakiSupEmail: "",
            phone: "",
            latitude: "",
            longitude: "",
            alternativeId: "",
            countries: [],
            users: [],
            organisations: [],
            regions: [],
            timezones: [],
            systems: [],
            selectedCountries: [],
            selectedUsers: [],
            selectedOrganisations: [],
            selectedRegions: [],
            selectedTimezones: [],
            selectedSystems: []
        };

    },
    onDataReady: function() {
        _currentFarm.countries = AdminViewStore.getAllCountriesCloned();
        _currentFarm.users = AdminViewStore.getAllUsersCloned();
        _currentFarm.organisations = AdminViewStore.getAllOrganisationsCloned();
        _currentFarm.systems = AdminViewStore.getAllSystemsCloned();
        this.trigger(_currentFarm);
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {
    	if(view === "newFarm") {
    		if(type === "user") {
	            this.onDropdownUserSelect(data);
	        } else if(type === "organisation") {
	            this.onDropdownOrgSelect(data);
	        } else if(type === "timezone") {
                this.onDropdownTimezoneSelect(data);
            } else if(type === "country") {
                this.onDropdownCountrySelect(data);
            } else if(type === "region") {
                this.onDropdownRegionSelect(data);
            } else if(type === "system") {
                this.onDropdownSystemSelect(data);
            }
    	}  
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "newFarm") {
    		if(type === "user") {
	            this.onRemoveUser(data);
	        } else if(type === "organisation") {
	            this.onRemoveOrg(data);
	        } else if(type === "timezone") {
                this.onRemoveTimezone(data);
            } else if(type === "country") {
                this.onRemoveCountry(data);
            } else if(type === "region") {
                this.onRemoveRegion(data);
            } else if(type === "system") {
                this.onRemoveSystem(data);
            }
    	}
    },
    onDropdownUserSelect: function(user) {
        var userIndex = _currentFarm.users.indexOf(user);
        if(userIndex !== -1) {
            _currentFarm.users.splice(userIndex, 1);
        }
        _currentFarm.selectedUsers.push(user);
        this.trigger(_currentFarm);
    },
    /* When a user selects an item from a dropdown menu, adds that option to selected options */
    onDropdownOrgSelect: function(org) {
        var orgIndex = _currentFarm.organisations.indexOf(org);
        if(orgIndex === -1) return;

        // Finding all users at this company and adding them to selected users
        for(var i = 0; i < _currentFarm.organisations[orgIndex].userList.length; ++i) {
            var user = _currentFarm.organisations[orgIndex].userList[i];
            for(var j = 0; j < _currentFarm.users.length; ++j) {
                if(user.id === _currentFarm.users[j].id) {
                    _currentFarm.users.splice(j, 1);
                    _currentFarm.selectedUsers.push(user);
                }
            }
        }
        
        _currentFarm.organisations.splice(orgIndex, 1);
        _currentFarm.selectedOrganisations.push(org);

        this.trigger(_currentFarm);
    },
    onDropdownTimezoneSelect: function(timezone) {
        var timezoneIndex = _currentFarm.timezones.indexOf(timezone);
        if(timezoneIndex !== -1) {
            _currentFarm.timezones.splice(timezoneIndex, 1);
        }
        _currentFarm.selectedTimezones.push(timezone);
        _currentFarm.timezoneSelected = true;
        this.trigger(_currentFarm);
    },
    onDropdownCountrySelect: function(country) {
        var countryIndex = _currentFarm.countries.indexOf(country);
        if(countryIndex !== -1) {
            _currentFarm.countries.splice(countryIndex, 1);
        }
        _currentFarm.selectedCountries.push(country);
        _currentFarm.regions = country.regionList;
        _currentFarm.timezones = country.timezoneList;
        _currentFarm.selectedRegions = [];
        _currentFarm.selectedTimezones = [];
        this.trigger(_currentFarm);
    },
    onDropdownRegionSelect: function(region) {
        var regionIndex = _currentFarm.regions.indexOf(region);
        if(regionIndex !== -1) {
            _currentFarm.regions.splice(regionIndex, 1);
        }
        _currentFarm.selectedRegions.push(region);
        this.trigger(_currentFarm);
    },
    onDropdownSystemSelect: function(system) {
        var systemIndex = _currentFarm.systems.indexOf(system);
        if(systemIndex !== -1) {
            _currentFarm.systems.splice(systemIndex, 1);
        }
        _currentFarm.selectedSystems.push(system);
        this.trigger(_currentFarm);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveUser: function(user) {
        var userIndex = _currentFarm.selectedUsers.indexOf(user);
        if(userIndex !== -1) {
            _currentFarm.selectedUsers.splice(userIndex, 1);
        }
        _currentFarm.users.push(user);
        _currentFarm.users.sort(this.compare);
        this.trigger(_currentFarm);
    },
    onRemoveOrg: function(org) {
        var orgIndex = _currentFarm.selectedOrganisations.indexOf(org);
        if(orgIndex === -1) {
            console.log("Organisation not found error");
            return;
        }
        // Finding all users at this company and removing them from selected users
        for(var i = 0; i < _currentFarm.selectedOrganisations[orgIndex].userList.length; ++i) {
            var user = _currentFarm.selectedOrganisations[orgIndex].userList[i];
            var userIndex = _currentFarm.selectedUsers.indexOf(user);
            _currentFarm.selectedUsers.splice(userIndex, 1);
            _currentFarm.users.push(user);
        }
        _currentFarm.selectedOrganisations.splice(orgIndex, 1);
        _currentFarm.organisations.push(org);
        _currentFarm.organisations.sort(this.compare);

        this.trigger(_currentFarm);
    },
    onRemoveTimezone: function(timezone) {
        var timezoneIndex = _currentFarm.selectedTimezones.indexOf(timezone);
        if(timezoneIndex !== -1) {
            _currentFarm.selectedTimezones.splice(timezoneIndex, 1);
        }
        _currentFarm.timezones.push(timezone);
        _currentFarm.timezones.sort(this.compare);
        _currentFarm.timezoneSelected = false;
        this.trigger(_currentFarm);
    },
    onRemoveCountry: function(country) {
        var countryIndex = _currentFarm.selectedCountries.indexOf(country);
        if(countryIndex !== -1) {
            _currentFarm.selectedCountries.splice(countryIndex, 1);
        }
        _currentFarm.countries.push(country);
        _currentFarm.countries.sort(this.compare);
        _currentFarm.regions = [];
        _currentFarm.timezones = [];
        _currentFarm.selectedRegions = [];
        _currentFarm.selectedTimezones = [];
        this.trigger(_currentFarm);
    },
    onRemoveRegion: function(region) {
        var regionIndex = _currentFarm.selectedRegions.indexOf(region);
        if(regionIndex !== -1) {
            _currentFarm.selectedRegions.splice(regionIndex, 1);
        }
        _currentFarm.regions.push(region);
        _currentFarm.regions.sort(this.compare);
        this.trigger(_currentFarm);
    },
    onRemoveSystem: function(system) {
        var systemIndex = _currentFarm.selectedSystems.indexOf(system);
        if(systemIndex !== -1) {
            _currentFarm.selectedSystems.splice(systemIndex, 1);
        }
        _currentFarm.systems.push(system);
        _currentFarm.systems.sort(this.compare);
        this.trigger(_currentFarm);
    },
    compare: function(a,b) {
        return a.name.localeCompare(b.name);
    },
    /* Triggered when the entire form is submitted. Eventually this will
    *  create a database entry for the new farm, and then send in updates
    *  to all new farm-org and user-farm entries.
    */
    onCreateFarmFormSubmit: function(name, email, phone, vakiSupEmail, latitude, longitude, alternativeId) {
        var systems = [];
        var org = { };
        var regionId = 0;
        var countryId = 0;
        var timezoneId = 0;
        if(_currentFarm.selectedOrganisations.length === 1) org = _currentFarm.selectedOrganisations[0];
        if(_currentFarm.selectedRegions.length === 1) regionId = _currentFarm.selectedRegions[0].id;
        if(_currentFarm.selectedCountries.length === 1) countryId = _currentFarm.selectedCountries[0].id;
        if(_currentFarm.selectedTimezones.length === 1) timezoneId = _currentFarm.selectedTimezones[0].id;
        for(var i = 0; i < _currentFarm.selectedSystems.length; ++i) {
            systems.push({ FarmID: 0, SystemID: _currentFarm.selectedSystems[i].id });
        }
        var date = moment().toJSON();
        var newFarm = {
            name: name,
            email: email,
            phone: phone,
            vakiSupEmail: vakiSupEmail,
            latitude: latitude,
            longitude: longitude,
            alternativeId: alternativeId,
            organisation: org,
            regionId: regionId,
            timezoneId: timezoneId,
            countryId: countryId,
            date: date,
            userList: _currentFarm.selectedUsers,
            visibleSystemList: systems
        }

        Actions.newFarmCreated(newFarm);

        this.trigger(_currentFarm);
    },
    getCurrentFarm: function() {
        return _currentFarm;
    }
});

module.exports = CreateFarmFormStore;