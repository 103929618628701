
"use strict";
var Reflux = require("reflux");
var Actions = require("./../../actions/Actions.js");
var React = require("react");
var RB = require("react-bootstrap");
var ReactDOM = require("react-dom");
var moment = require("moment");
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var UserDataStore = require("./../../stores/UserDataStore.js");
var VConst = require("./../../utils/VConst.js");
var t = TranslateStore.getTranslate();
var FilterStore = require("./../../stores/FilterStore.js");
var VakiAPI = require("./../../webservice/VakiAPI.js");
require("./ManualDownload.css");




var ManualDownloadStore = Reflux.createStore({
    init () {

        this.listenTo(Actions.manListReceived, this.onRcv);
   

        
        this.data = "";
        
        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },

    onRcv (resp) {
        this.data = resp.manList;
        this.trigger(this.data);
    }






});


export default class ManualDownload  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            fileArray: []

        };

      this.onData = (data) => {
         this.setState({ fileArray: data });
        };

    };



    componentDidMount () {
        this.unsubscribeManualDownloadStore = ManualDownloadStore.listen(this.onData);

        VakiAPI.GetManuals(Actions.manListReceived);

        /*
        $.ajax({
            method: "GET",

            url: "/man"
        }).then(function (response) {
 
            Actions.manListReceived(response);

        });



        $.ajax({
            url: '/man/text.txt',
            success (data) {
                console.log(data);
                //parse your data here
                //you can split into lines using data.split('\n') 
                //an use regex functions to effectively parse it
            }
        });

     */

    };


    componentWillUnmount () {
        this.unsubscribeManualDownloadStore();
    };

  




    render () {



        var len = this.state.fileArray.length; 

        var lnkList = [];
        for (var i = 0; i < len; i++) {
            var lnkText = this.state.fileArray[i].LinkText;

            if (!lnkText || lnkText.length == 0)
                lnkText = this.state.fileArray[i].Link;

            var tooltip = this.state.fileArray[i].ToolTipText;
            if (!tooltip || tooltip.length <= 0)
                tooltip = this.state.fileArray[i].Link;

            var lnk = <li>
                <RB.OverlayTrigger placement='top'   overlay={<RB.Popover> {tooltip} </RB.Popover>}>

                <a target="_blank" href={this.state.fileArray[i].Link}>{lnkText}</a>
                    </RB.OverlayTrigger>
            </li>
            lnkList.push(lnk);


        }

     










        return (<div> 

         
            <h3>Documents  </h3>
            
            <br />
            <ul id="ulLinks">
                {lnkList}

            </ul>
            




        </div>)
    }
};

