/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5

 */
"use strict";

var React = require("react");
var RB = require("react-bootstrap");
var cx = require("classnames");
export default class Spinner extends React.Component{
//var Spinner = export default class   extends React.Component{

    dummy () {return ""};

    render() {

        var _loadStatus = this.dummy
        if (this.props.loadStatus)
            _loadStatus = this.props.loadStatus;
        return (
        	<div className="text-center">
              <div className= 'spinner floatLeft'></div> <div className='loadStat'> {_loadStatus()} </div>
	    	</div>
        )
    };
};


//module.exports = Spinner;

