/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to create a new computer.
 * Along with basic information, a computer has a list of farms that it belongs to.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentComputer = { };

var CreateCompFormStore = Reflux.createStore({
	init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.apiAdminViewDataReady, this.onDataReady);
        this.listenTo(Actions.createCompFormSubmit, this.onCreateComputerFormSubmit);

        _currentComputer = {
        	farms: [],
            selectedFarms: [],
            systems: [],
            selectedSystems: []
        };
    },
    onDataReady: function() {
    	_currentComputer.farms = AdminViewStore.getAllFarmsCloned();
        _currentComputer.systems = AdminViewStore.getAllSystemsCloned();
    	this.trigger(_currentComputer);
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {

    	if(view === "newComputer") {
            if (type === "farm" || type === "farmOrg") {
	            this.onDropdownFarmSelect(data);
	        } else if(type === "system") {
                this.onDropdownSystemSelect(data);
            }
    	}  
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "newComputer") {
            if (type === "farm" || type === "farmOrg") {
	            this.onRemoveFarm(data);
	        } else if(type === "system") {
                this.onRemoveSystem(data);
            }
    	}
    },
    onDropdownFarmSelect: function(farm) {



      
    	var farmIndex = _currentComputer.farms.indexOf(farm);

        
        if(farmIndex !== -1) {
            _currentComputer.farms.splice(farmIndex, 1);
        }

      
        _currentComputer.selectedFarms.push(farm);
        this.trigger(_currentComputer);
    },
    onDropdownSystemSelect: function(system) {
        var systemIndex = _currentComputer.systems.indexOf(system);
        if(systemIndex !== -1) {
            _currentComputer.systems.splice(systemIndex, 1);
        }
        _currentComputer.selectedSystems.push(system);
        this.trigger(_currentComputer);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveFarm: function (farm) {

     
        var farmIndex = _currentComputer.selectedFarms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentComputer.selectedFarms.splice(farmIndex, 1);
        }
        _currentComputer.farms.push(farm);
        _currentComputer.farms.sort(this.compare);
        this.trigger(_currentComputer);
    },
    onRemoveSystem: function(system) {
        var systemIndex = _currentComputer.selectedSystems.indexOf(system);
        if(systemIndex !== -1) {
            _currentComputer.selectedSystems.splice(systemIndex, 1);
        }
        _currentComputer.systems.push(system);
        _currentComputer.systems.sort(this.compare);
        this.trigger(_currentComputer);
    },
    onCreateComputerFormSubmit: function (macAddress, uploadInterval, teamViewerId, teamViewerPassword) {

      
        _currentComputer.bioMassVersion = null;
        _currentComputer.id = 0;
        _currentComputer.databaseVersion = null;
        _currentComputer.isActive = true;
        _currentComputer.macAddress = macAddress;
        _currentComputer.name = "";                               //Automatically generated by the database
        _currentComputer.teamViewerId = teamViewerId;
        _currentComputer.teamViewerPassword = teamViewerPassword;
        _currentComputer.uploadInterval = uploadInterval;

        Actions.newCompCreated(_currentComputer);
    },
    compare: function(a,b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    },
    getCurrentComputer: function() {
        return _currentComputer;
    }
});

module.exports = CreateCompFormStore;