/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to create a new organisation
 * Along with basic information, an organisation has a list of users that have 
 * access to it and a list of all its farms. New users and farms can also be created on 
 * the fly when creating an organisation, once the form is submitted, they are automatically
 * linked to the organisation.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentOrganisation = { };

var CreateUserFormStore = Reflux.createStore({
    init: function() {
        
        this.listenTo(Actions.createOrgFormSubmit, this.onCreateOrgFormSubmit);

        _currentOrganisation = {
            name: "",
            address: "",
            email: "",
            phone: ""
        };
    },
    /* Triggered when the entire form is submitted. Eventually this will
    *  create a database entry for the new organisation, and then send in updates
    *  to all new user-org, farm-org and user-farm entries, whether it's completely 
    *  new users/farms being added or existing users/farms given access to it.
    */
    onCreateOrgFormSubmit: function(name, address, email, phone) {
    	_currentOrganisation.name = name;
    	_currentOrganisation.address = address;
    	_currentOrganisation.email = email;
        _currentOrganisation.phone = phone;

    	Actions.newOrganisationCreated(_currentOrganisation);

        _currentOrganisation = {
            name: "",
            address: "",
            email: "",
            phone: ""
        };

    	this.trigger(_currentOrganisation);
    },
    getCurrentOrganisation: function() {
        return _currentOrganisation;
    }
});

module.exports = CreateUserFormStore;