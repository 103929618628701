var React = require("react");
 import {Dropdown,DropdownButton , Button, Menu, Accordion, Row, Col, Form, OverlayTrigger, Alert, InputGroup, Tooltip } from"react-bootstrap";
import Actions from "../../../actions/Actions.js";
import SearchDropdown from "./Dropdowns/SearchDropdown.jsx";
import SelectedDropdownOptions from "./Dropdowns/SelectedDropdownOptions.jsx";
import SessionStore from "../../../stores/SessionStore";

 export default class  AdminEditCompForm extends React.Component{
	constructor(props) {
     super(props);
    	this.state = {
    		id: 0,
    		macAddress: "",
        	uploadInterval: "",
    		teamViewerId: "",
    		teamViewerPassword: "",
    		teamViewerSecondPassword: "",
    		passwordMismatch: false,
    		passwordTooShort: false,
            farmSelected: false,
            farmDeleted: false, //Used to force submit after one farm deleted before another one selected
        	loading: false
    	};

this.handleMacAddressChange = ( e) => {
		this.setState({ macAddress: e.target.value });
	};

	this.handleTeamViewerIdChange = ( e) => {
		this.setState({ teamViewerId: e.target.value });
	};

	this.handleTeamViewerPasswordChange = ( e) => {
		this.setState({ teamViewerPassword: e.target.value });
	};

	this.handleTeamViewerSecondPasswordChange = ( e) => {
		this.setState({ teamViewerSecondPassword: e.target.value });
	};
	this.handleUploadIntervalChange = ( e) => {
		this.setState({ uploadInterval: e.target.value });
	};
	this.onFormSubmit = ( ) => {

  

		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}

		/* Security checks to see if something is wrong */
		/* The passwords are optional however so I start by checking if it's empty */
		if(this.state.teamViewerPassword.length > 0) {
			if( this.state.teamViewerPassword.length < 8) {
				this.setState({ passwordTooShort: true });
				return;
			}
			if(this.state.teamViewerPassword !== this.state.teamViewerSecondPassword) {
				this.setState({ passwordTooShort: false, passwordMismatch: true });
				return;
			}
		}


		/* Everything seems fine, submitting the form */
		Actions.editCompFormSubmit(this.state.macAddress, this.state.uploadInterval, 
										 this.state.teamViewerId, this.state.teamViewerPassword);
		this.setState({
    		passwordMismatch: false,
            passwordTooShort: false,
        	loading: true
		});
		var self = this;
		setTimeout(function(){
			self.setState({
                loading: false,
                farmDeleted: false
			}); 
		} , 2000);
	};




	};
    UNSAFE_componentWillReceiveProps (nextProps) {

        var lastID = this.state.id; 

		if(nextProps.currentEditComputer.id !== this.state.id) {
			this.setState({
				id: nextProps.currentEditComputer.id,
				macAddress: nextProps.currentEditComputer.macAddress,
				teamViewerId: nextProps.currentEditComputer.teamViewerId,
				uploadInterval: nextProps.currentEditComputer.uploadInterval
			});
        }



        if (lastID !== nextProps.currentEditComputer.id) {
            this.state.farmSelected = false;
            this.state.farmDeleted = false;
        }




 
        if (nextProps.currentEditComputer.selectedFarms.length > 0) {
            this.setState({ farmSelected: true });
   

        } else if (this.state.farmSelected) {
            this.setState({ farmSelected: false, farmDeleted: true });
 

        } else {
            this.setState({ farmSelected: false, farmDeleted: false });


        }

	};


	
    render () {
        

		return (
			<form>
				<Row>
					<div className="text-center">
						<h4> {this.props.currentEditComputer.name} </h4>
						<strong> Created on: {this.props.currentEditComputer.createdDate} </strong>
					</div>
				</Row>
				<Row>	
					<Col md={6}>
						<Form.Label>{this.props.t("adminView.macAddress")} </Form.Label>
<Form.Control value={this.state.macAddress} onChange={this.handleMacAddressChange} name=""
								type="text"  placeholder={this.props.t("adminView.macAddress") + ".."} />
					</Col>
					<Col md={6}>
						<Form.Label> {this.props.t("adminView.uploadInterval") + " (hours)"}</Form.Label>
<Form.Control value={this.state.uploadInterval} onChange={this.handleUploadIntervalChange} name=""
							type="text"  placeholder={this.props.t("adminView.uploadInterval") + ".."} />
					</Col>
				</Row>
				<Row>

					<Col md={6}>
						<Form.Label> {this.props.t("adminView.teamViewerID")}</Form.Label>
<Form.Control value={this.state.teamViewerId} onChange={this.handleTeamViewerIdChange} name=""
									type="text"  placeholder={this.props.t("adminView.teamViewerID") + ".."} />
					</Col>
				</Row>
				<Row>

					<Col md={6}>
						<Form.Label> {this.props.t("adminView.teamViewerPassword")}</Form.Label>
<Form.Control value={this.state.teamViewerPassword} onChange={this.handleTeamViewerPasswordChange} name=""
							type="password"  placeholder={this.props.t("adminView.teamViewerPassword") + ".."} />
					</Col>
					<Alert  variant="warning" hidden={!this.state.passwordTooShort}>
						<strong> {this.props.t("adminView.passwordLength")}! </strong>
                    </Alert>
     

				</Row>
				<Row>

					<Col md={6}>
						<Form.Label> {this.props.t("adminView.confirmPassword")} </Form.Label>
<Form.Control value={this.state.teamViewerSecondPassword} onChange={this.handleTeamViewerSecondPasswordChange} name=""
							type="password" placeholder={this.props.t("adminView.confirmPassword") + ".."} />
					</Col>
					<Alert  variant="warning" hidden={!this.state.passwordMismatch}>
						<strong> {this.props.t("adminView.passwordMismatch")}! </strong>
					</Alert>
				</Row>
				<Row>
					<Col md={6}>
						
							<Form.Label> {this.props.t("adminView.biomassVersion")}</Form.Label>
							<OverlayTrigger placement='top' overlay={<Tooltip>{"Biomass version can not be changed"}</Tooltip>}>
<Form.Control value={(!this.props.currentEditComputer.biomassVersion?"": this.props.currentEditComputer.biomassVersion)} readOnly
								type="text"  disabled/>
						</OverlayTrigger>
					</Col>
					<Col md={6}>
						
							<Form.Label>{this.props.t("adminView.databaseVersion")} </Form.Label>
							<OverlayTrigger placement='top' overlay={<Tooltip>{"Database version can not be changed"}</Tooltip>}>
<Form.Control value={(!this.props.currentEditComputer.databaseVersion?"":this.props.currentEditComputer.databaseVersion)} readOnly
								type="text"  disabled/>
						</OverlayTrigger>
					</Col>
				</Row>


				<Row>
					<div className="just-width100">
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.farm")} options={this.props.currentEditComputer.farms}
                            view="editComputer" type="farmOrg" translateTrigger={this.props.translateTrigger}
                            disableButton={this.state.farmSelected || this.state.farmDeleted} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditComputer.selectedFarms}
														view="editComputer" type="farmOrg" />
					</Col>
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.systems")} options={this.props.currentEditComputer.systems}
														view="editComputer" type="system" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentEditComputer.selectedSystems}
														view="editComputer" type="system" />
					</Col>
					</div>
				</Row>
				<div className="text-center">
					<Button  variant="primary" onClick={this.onFormSubmit} disabled={this.state.loading} variant="outline-dark">
						{this.props.t("adminView.submit")}
					</Button>
					<div className="spinner" hidden={!this.state.loading}></div>
				</div>
			</form>
		);
	}
};

