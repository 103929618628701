/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");


import ReactBootstrap, {Dropdown, Modal} from "react-bootstrap";

var Actions = require("../../actions/Actions.js");
import PropTypes from "prop-types";


export default class FarmPicker extends React.Component{

    static propTypes =  {
        selectedFarm: PropTypes.shape({
            FarmID: PropTypes.number.isRequired,
            Name: PropTypes.string.isRequired
        }).isRequired,

        // Technically it would be better to declare this better. We are
        // expecting an array of objects like: [{ID: 1, Name: "Org"}]. I
        // however don't know at this time how to express that.
        farms: PropTypes.array.isRequired
    };

    handleClick (e) {
        Actions.farmClick(e.target.id);
    };

    render () {
        var self = this; // We won't need to do this if we start to use 6to5, hack because JS sucks
        var farms = this.props.farms.concat();
        var farmNodes = farms.map(function (farm) {
            return (
               <li key={farm.FarmID}> <Dropdown.Item onClick={self.handleClick} id={farm.FarmID}>{farm.Name}</Dropdown.Item></li>
            );
        });

        return (
            <Dropdown>
              <Dropdown.Toggle  id="dropdown-farms"  variant="outline-dark">
    {this.props.selectedFarm.Name}
            </Dropdown.Toggle>
            <Dropdown.Menu title="FarmMenu" onClick={Actions.farmButtonClick}>
                {farmNodes}
            </Dropdown.Menu>
            </Dropdown>
        )
    }
};


