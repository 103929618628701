/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to create a new user
 * A user must have a userTypeID which represents him either being an admin, 
 * Vaki employee or a customer. Admins and Vaki Employees have access to everything.
 * A user must also have at least one role, fx. Basic User, Feed Data user and so forth.
 * Organisations and farms can also be added to a user, giving him access to information on
 * each selected option.
 */

"use strict";

var Reflux = require("reflux");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentUser = { };

var CreateUserFormStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.createUserFormSubmit, this.onCreateUserFormSubmit);
        this.listenTo(Actions.apiAdminViewDataReady, this.onDataReady);

        _currentUser = {
            firstName: "",
            lastName: "",
            username: "",
            mobile: "",
            email: "",
            jobTitle: "",
            password: "",
            userTypeID: 0,
            roles: [],
            organisations: [],
            farms: [],
            selectedRoles: [],
            selectedOrganisations: [],
            selectedFarms: [],
            isAdmin: false
        };
    },
    onDataReady: function() {
        _currentUser.roles = AdminViewStore.getAllRolesCloned();
        _currentUser.organisations = AdminViewStore.getAllOrganisationsCloned();
        _currentUser.farms = AdminViewStore.getAllFarmsCloned();
        this.trigger(_currentUser);
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {
    	if(view === "newUser") {
    		if(type === "role") {
	            this.onDropdownRoleSelect(data);
	        } else if(type === "organisation") {
	            this.onDropdownOrgSelect(data);
	        } else if(type === "farmOrg") {
	            this.onDropdownFarmSelect(data);
	        }
    	}
        
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "newUser") {
    		if(type === "role") {
	            this.onRemoveRole(data);
	        } else if(type === "organisation") {
	            this.onRemoveOrg(data);
	        } else if(type === "farmOrg") {
	            this.onRemoveFarm(data);
	        }
    	}
    },
    /* When a user selects an item from a dropdown menu, adds that option to selected options */
    onDropdownRoleSelect: function(role) {
        var roleIndex = _currentUser.roles.indexOf(role);
        // Removing role from options array
        if(roleIndex !== -1) {
            _currentUser.roles.splice(roleIndex, 1); 
        }
        // Adding role to selected roles
        _currentUser.selectedRoles.push(role);
        // Disables the orgs and farms dropdowns
        if(role.name === "UserAdministrator") _currentUser.isAdmin = true;
        this.trigger(_currentUser);
    },
    onDropdownOrgSelect: function(org) {
        var orgIndex = _currentUser.organisations.indexOf(org);
        // Removing organisation from options array
        if(orgIndex !== -1) {
            _currentUser.organisations.splice(orgIndex, 1);
        }
        // Adding org to selected options
        _currentUser.selectedOrganisations.push(org);
        // Adding all farms linked to this organisation
        for(var i = 0; i < org.farmList.length; ++i) {
            for(var j = 0; j < _currentUser.farms.length; ++j) {
                if(org.farmList[i].id === _currentUser.farms[j].id) {
                    _currentUser.farms.splice(j, 1);
                    _currentUser.selectedFarms.push(org.farmList[i]);
                }
            }
        }
        this.trigger(_currentUser);
    },
    onDropdownFarmSelect: function(farm) {
        var farmIndex = _currentUser.farms.indexOf(farm);
        // Removing farm from options array
        if(farmIndex !== -1) {
            _currentUser.farms.splice(farmIndex, 1);
        }
        // Adding farm to selected farms
        _currentUser.selectedFarms.push(farm);

      

        this.trigger(_currentUser);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveRole: function(role) {
        var roleIndex = _currentUser.selectedRoles.indexOf(role);
        if(roleIndex !== -1) {
            _currentUser.selectedRoles.splice(roleIndex, 1); // Removing role from selected roles
        }
        _currentUser.roles.push(role); // Adding role to options array
        _currentUser.roles.sort(this.compare);
        if(role.name === "UserAdministrator") _currentUser.isAdmin = false;
        this.trigger(_currentUser);
    },
    onRemoveOrg: function(org) {
        // Removing all farms linked to this organisation
        for(var i = 0; i < org.farmList.length; ++i) {
            for(var j = 0; j < _currentUser.selectedFarms.length; ++j) {
                if(org.farmList[i].id === _currentUser.selectedFarms[j].id) {
                    _currentUser.selectedFarms.splice(j, 1);
                    _currentUser.farms.push(org.farmList[i]);
                }
            }
        }
        var orgIndex = _currentUser.selectedOrganisations.indexOf(org);
        if(orgIndex !== -1) {
            _currentUser.selectedOrganisations.splice(orgIndex, 1);
        }
        _currentUser.organisations.push(org);
        _currentUser.organisations.sort(this.compare);
        this.trigger(_currentUser);
    },
    onRemoveFarm: function(farm) {
        var farmIndex = _currentUser.selectedFarms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentUser.selectedFarms.splice(farmIndex, 1);
        }
        _currentUser.farms.push(farm);
        _currentUser.farms.sort(this.compare);
        this.trigger(_currentUser);
    },
    /* Triggered when the user submits the form, assembles the user and passes it to the parent store */
    onCreateUserFormSubmit: function(firstName, lastName, username, mobile, email, jobTitle, password, userTypeID) {
    	_currentUser.firstName = firstName;
        _currentUser.lastName = lastName;
    	_currentUser.username = username;
        _currentUser.mobile = mobile;
        _currentUser.email = email;
        _currentUser.jobTitle = jobTitle;
        _currentUser.password = password;
        _currentUser.userTypeID = userTypeID;
    	Actions.newUserCreated(_currentUser);

    	var prevUser = _currentUser;
        // Resetting the input fields so the new user doesn't stick around in the form
        // The old dropdown options remain however for conveniance sake when creating
        // multiple users of the same type
    	_currentUser = {
            firstName: "",
            lastName: "",
            username: "",
            mobile: "",
            email: "",
            jobTitle: "",
            password: "",
            roles: prevUser.roles,
            organisations: prevUser.organisations,
            farms: prevUser.farms,
            selectedRoles: prevUser.selectedRoles,
            selectedOrganisations: prevUser.selectedOrganisations,
            selectedFarms: prevUser.selectedFarms,
            isAdmin: prevUser.isAdmin
        };

        this.trigger(_currentUser);
    },
    /*
    * Helper function used when sorting
    */
    compare: function(a,b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    },
    getCurrentUser: function() {
        return _currentUser;
    }
    
});

module.exports = CreateUserFormStore;