/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

/**
 * HightChart Utility, specific helper methods when working with the third
 * party library HighChart.
 */
var HSUtil = {
    /**
     * Helper function to hide a Y-axis in Hichcharts. I couldn't find any
     * convenient or built-in way to do this at the time of writing.
     *
     * This function is worked from the following code example:
     * http://jsfiddle.net/39xBU/55/
     *
     * @param chart a Highchart chart object
     * @param axisID string, the ID string of a Highchart Y-axis
     */
    hideHighchartYAxis: function(chart, axisID) {
        for (var i in chart.yAxis) {
            if (chart.yAxis[i].userOptions.id === axisID) {
                chart.yAxis[i].update({
                    labels: { enabled: false },
                    title: { text: null },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickLength: 0
                });
            }
        }
    },

    /**
     * Receives a list of date formatted strings, prunes some out if there are
     * too many. Prevents a cluttered X axis in Highchart graphs.
     *
     * Note: I wrote this method in an attempt to fix cluttered X axis in
     * Highchart graphs. At the time I didn't know there was a specific
     * "datetime" type X-axis. In the FCR report I went for that approach but
     * there seem to be some cons with it. For example I was not able to figure
     * out how to show the start and end dates. Therefore I'm going to leave
     * this method here just in case we want to do things manually, that is
     * by using the "category" X-Axis type and manually pick the categories
     * we want.
     *
     * Please note that this function is not entirely complete. Before using
     * please consider checking the functionality if it makes sense. If you're
     * reading this and it's say 2016 or later and nobody is using this
     * function it can be deleted.
     *
     * @param {Array} dates List of date strings, e.g: ["1st Jun", "2nd Jun", ...]
     * @returns {Array} of date formatted strings with some missing depending on
     * size of input array, e.g: ["1st Jun", "", "3rd Jun", "", "5th Jun", ...]
     */
    trimDates: function(dates) {
        var results = dates.slice();
        var size = results.length; // just for code clarity

        if (results && size >= 2) {
            // For every date, except the first and last
            for (var i = 1; i <= size-2; i++) {
                var cutoff = Math.ceil(size / 40);
                var shouldKeep = i % cutoff === 0;

                if (!shouldKeep && size >= 40) {
                    results[i] = "";
                }
            }
        }

        return results;
    }
};

module.exports = HSUtil;