import React, { useState, useEffect, useRef } from 'react';
import {
	Button,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
	Container,
	Dropdown,
	DropdownButton,
	FormControl
} from 'react-bootstrap';

import VakiAPI from '../../webservice/VakiAPI.js';
import Actions from '../../actions/Actions.js';
import Spinner from './../../utils/Spinner.jsx';
import { XLg } from 'react-bootstrap-icons';

export default function SearchDropdownCamera(props) {
	//export default class SearchDropdownCamera extends React.Component {

	const [ searchQuery, setSearchQuery ] = useState('');
	const [ searchResults, setSearchResults ] = useState([]);
	const [ baseList, setBaseList ] = useState([]);
	const [ searchResultsOptions, setSearchResultsOptions ] = useState([]);

	//const TheDropdown = useRef(null);
	const loadSearchResultOptions = (dataList) => {
		let len = dataList.length;
		let tmp = [];
		for (var i = 0; i < len; i++) {
			tmp.push(
				<Dropdown.Item
					/*onSelect={self.addResult.bind(null, result)}*/ onClick={onClickRow}
					key={i}
					eventKey={dataList[i].id}
					id={dataList[i].id}
		
				>
					{dataList[i].name}
				</Dropdown.Item>
			);
		}

		setSearchResultsOptions(tmp);
	};

	useEffect(
		() => {
			let tmp = [];

			for (var i = 0; i < props.OrphanList.length; i++) {
				let str =
					props.OrphanList[i].Serial_Number + ' --- ' + props.OrphanList[i].DeviceCreated.substring(0, 10);
				if (props.OrphanList[i].Model != null && props.OrphanList[i].Model != '')
					str += ',  M: ' + props.OrphanList[i].Model;
				if (props.OrphanList[i].Firmware_Version != null && props.OrphanList[i].Firmware_Version != '')
					str += ',  FV: ' + props.OrphanList[i].Firmware_Version;

				tmp.push({
					name: str,
					obj: props.OrphanList[i],
					id: props.OrphanList[i].Device_ID
				});
			}

			loadSearchResultOptions(tmp);
			setSearchResults(tmp);
			setBaseList(tmp);

			// setFarm(props.farm);
		},
		[ props.OrphanList ]
	);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);

		var query = e.target.value.toLowerCase();
		if (query === '') {
			loadSearchResultOptions(baseList);
			setSearchResults(baseList);

			return;
		}
		var results = [];

		for (var i = 0; i < baseList.length; ++i) {
			if (baseList[i].name.toLowerCase().match(query)) {
				/*
				if (query.length > 3)
				
					alert(
						i +
							'<<   handleSearchChange:  id ' +
							baseList[i].id +
							'     name: ' +
							baseList[i].name
					);
					*/
				results.push(baseList[i]);
			}
		}

		setSearchResults(results);
		loadSearchResultOptions(results);

		//	alert(JSON.stringify(searchResultsOptions));
	};

	const onDropdownOpen = (e) => {
		if (searchResults.length == 0) {
			loadSearchResultOptions(baseList);
			setSearchResults(baseList);
		} else loadSearchResultOptions(searchResults);
	};

	const onInputFocus = () => {
		//	console.log ("onInputFocus  "+ searchResults.length)
	};
	const onInputBlur = () => {
		//	console.log ("onInputBlur "+ searchResults.length)
	};

	const onKey = (event) => {
		if (event.key === 'Enter') {
			if (searchResults.length > 0) props.select(searchResults[0].obj);
			//this.refs.dropdownButton.setDropdownState(false);
		}
	};

	const onClickRow = (e, b) => {
		if (e.target.innerText.toLowerCase().trim() === '') {
			loadSearchResultOptions(baseList);
			setSearchResults(baseList);
			return;
		}

		let queryID = e.target.id;

		let len = baseList.length;

		for (var i = 0; i < len; i++) {
			if (baseList[i].id == queryID) {
				props.select(baseList[i].obj);
				return;
			}
		}
	};

	//var disableButton = this.props.isAdmin || this.props.disableButton;

	//	var searchResultsXX = getSearchResults();

	return (
		<div className="width100">
			<DropdownButton
				title="Select camera device"
				block="true"
				className="search-dropdown-button display-block"
				onFocus={onDropdownOpen}
				variant="outline-dark"
			>
				<div className="search-dropdown-input">
					<Form.Control
						type="search"
						/*addonAfter={(<XLg/>)}*/

						onFocus={onInputFocus}
						onBlur={onInputBlur}
						onChange={handleSearchChange}
						value={searchQuery}
						focus={(searchResults.length !== 0).toString()}
						onKeyDown={onKey}
					/>
				</div>
				<Dropdown>{searchResultsOptions}</Dropdown>
			</DropdownButton>
		</div>
	);
}

/*
			<DropdownButton
				title={this.props.title}
				block="true"
				className="search-dropdown-button display-block"
				onFocus={this.onDropdownOpen}
				disabled={disableButton}
				ref="dropdownButton"
				variant="outline-dark"
				
				
			>
				<div className="search-dropdown-input">
					<FormControl
						type="search"
					
						onChange={handleSearchChange}
						value={searchQuery}
						ref="optionsSearch"
						focus={(searchResults.length !== 0).toString()}
						onKeyDown={onKey}
					/>
				</div>
				<Dropdown  ref="TheDropdown" >
				{searchResultsXX}
				</Dropdown>
			</DropdownButton>
*/
