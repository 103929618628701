/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";




var Reflux = require("reflux");
var Immutable = require("immutable");
var moment = require("moment");
var BGTools = require("./../utils/BGtools.js");
var VUtil = require("./../utils/Vutil.js");
var VConst = require("./../utils/VConst.js");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var NavBarStore = require("./NavBarStore.js");
var DashboardStore = require("./DashboardStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var ParMan = require("./ParMan.js");
var _uniformScaling = true;
var _fetchCount = 0;

var _fetchArray = [];
var _fetchBigChunk = false;



var WaitingSingleGraph = 0;




const startDate_ID = "TGS_startDate";
const endDate_ID = "TGS_endDate";
const showAllCages_ID = "TGS_showAllCages";
const showAvgWeight_ID = "TGS_showAvgWeight";
const showAWasTrendlines_ID = "TGS_showAWasTrendlines";
const useSpecEndDates_ID = "TGS_useSpecEndDates";
const _uniformScaling_ID = "TGS_uniformScaling";





/**
 * Keeps track of state of filters specific to the Trend Graph report as well
 * as the actual trend data used to generate the graph.
 */
var TrendGraphStore = Reflux.createStore({
    init: function() {
        this.listenTo(FilterStore, this.onFilterStoreUpdate);
        // Here we listen to actions and register callbacks
        this.listenTo(Actions.apiNewTrendGraphData, this.onApiNewTrendGraphData);
        this.listenTo(Actions.apiNewTrendGraphAllCagesData, this.onApiNewTrendGraphAllCagesData);
        this.listenTo(Actions.trendStartDateUpdate, this.onStartDateUpdate);
        this.listenTo(Actions.trendEndDateUpdate, this.onEndDateUpdate);
        this.listenTo(Actions.trendDateChange, this.onDateChange);
        this.listenTo(Actions.trendShowAllCages, this.onShowAllCages);
        this.listenTo(Actions.trendShowAvgWeight, this.onShowAvgWeight);
        this.listenTo(Actions.trendGraphDoneLoading, this.onLoadingDone);
        this.listenTo(Actions.trendUniformScaling, this.onUniformScaling);
        this.listenTo(Actions.addComment, this.onAddComment);
        this.listenTo(Actions.apiCommentSaved, this.onCommentSaved);
        this.listenTo(Actions.trendAWshowAsTrend, this.onTrendAWshowAsTrend);
        this.listenTo(Actions.trendUseSpecEndDates, this.onUseSpecEndDates);
        this.listenTo(Actions.trendNewAux, this.onNewAux);
        this.listenTo(Actions.apiError, this.onApiError);

       




        // By default, pick a range of # days in the past from now. We need
        // startOf("day") because the React-Bootstrap-Daterangepicker uses
        // dates from midnight by default.
        var numberOfDays = VConst.DEFAULT_RANGE;
        var endDate = moment().endOf("day");
        var startDate = endDate.clone().subtract(numberOfDays, "days").startOf("day");

        var strStart = ParMan.initParameter(startDate_ID)
        var strEnd = ParMan.initParameter(endDate_ID)

       
        

        if (strStart)
            startDate = moment(strStart);

        if (strEnd)
            endDate = moment(strEnd);

    


        var filter = Immutable.Map({
            minWeight: VConst.DEFAULT_MIN_WEIGHT,
            maxWeight: VConst.DEFAULT_MAX_WEIGHT,
            startDate: startDate,
            endDate: endDate,
            showAllCages: ParMan.initParameter(showAllCages_ID, true, 'bool'),
            showAvgWeight: ParMan.initParameter(showAvgWeight_ID, false, 'bool'),
            showAWasTrendlines: ParMan.initParameter(showAWasTrendlines_ID, true, 'bool'),
            useSpecEndDates: ParMan.initParameter(useSpecEndDates_ID, false, 'bool') ,
            MinFishPrHour:  FilterStore.getFilter().MinFishPrHour

        });

        _uniformScaling = false;
      

        var trendGraphData = Immutable.Map(VakiReactPropTypes.defaultTrendGraphData);
        var trendGraphAllCagesData = Immutable.Map(VakiReactPropTypes.defaultAllCagesPopulationTrendData);




        // Because this store serves a dual purpose, keeping track of state for
        // the filter and most recent Trend Graph data I'm trying here to
        // encapsulate it in an umbrella or parent object; data. This is
        // because when we trigger an action we have to either always send an
        // object with the same structure (like the data approach below) or
        // wrap what we send in an object where we specify what we are sending.
        // Both ways have a code smell but I'm very much trying things out,
        // this may not be the best way of doing things.
        this.data = Immutable.Map({
            filter: filter,
            trendGraphData: trendGraphData,
            allCagesData: trendGraphAllCagesData,
            avgWeightData: trendGraphAllCagesData,
            isLoading: false,
            theNewLoading: false

        });

        //uploaded by client on client side
        this.auxData = null; // {PopulationID:-1, data:null, firstDay:null };
    },


onApiError: function (e){


  this.trigger(null, false, null, "Error: "+e);

},


onNewAux: function (auxData, src){

    var t = this.data.get("trendGraphData");

    var startDate = moment.utc( FilterStore.getFilter().startDate);
    var endDate = moment.utc( FilterStore.getFilter().endDate);









    var cntDoubles = 0;
    var cntSome = 0;
    var minDt = auxData[0].Date;
    var maxDt = minDt;
    for (var i=0; i <auxData.length; i++){
        if (auxData[i].Date < minDt)
            minDt = moment.utc(auxData[i].Date);
        else if (auxData[i].Date > maxDt)
            maxDt = moment.utc(auxData[i].Date);

    }





    var aDay = 86400000;
    var nDays = (maxDt-minDt)/aDay;

    if (nDays > 366){
        alert ("Timespan of data is too long! (max one year)")
        return;
    }
/*
    if (maxDt<startDate || minDt > endDate)
        alert ("NOTE.  Timaspan of data is outside the selected date range!")
*/
    var arr = [];

    for (var i=0; i<nDays; i++)
        arr.push(null);





    for (var i=0; i <auxData.length; i++){
        var itm = auxData[i];
        var idx = (moment.utc(itm.Date) - minDt)/aDay;

        if (idx>=0 && idx < nDays ){
            cntSome++;
            if (arr[idx] != null)
                cntDoubles++;
            arr[idx] = itm.Value;
        }
    }


    if (cntSome == 0) {
        alert("No valid data found in file!")
        return  ;
    }

    if (cntDoubles > 0)
        alert ("The data included some double date entries")





   



    this.auxData =  {PopulationID: FilterStore.getSelectedPopulationID(), data:arr, firstDate:minDt, src: src };
    
    document.getElementById("auxFileName").innerHTML = src;


    this.trigger(this.data, null, VConst.TG_REDRAW, this.auxData);







},

onTrendAWshowAsTrend:function(){

    var showTrend = this.data.get("filter").get("showAWasTrendlines");
    this.data = this.data.setIn(["filter", "showAWasTrendlines"], !showTrend);
    ParMan.setItem(showAWasTrendlines_ID, !showTrend);
    this.trigger(this.data, null, VConst.TG_REDRAW, this.auxData);


},

    onUseSpecEndDates:function(){

        var useSpec = this.data.get("filter").get("useSpecEndDates");

        useSpec = !useSpec
        this.data = this.data.setIn(["filter", "useSpecEndDates"], useSpec);
        ParMan.setItem(useSpecEndDates_ID, useSpec);
        if (useSpec){
            this.data = this.data.setIn(["filter", "showAvgWeight"], false);
            ParMan.setItem(showAvgWeight_ID, false);
        }


        if(this.data.get("filter").get("showAllCages")) {

            this.fetchTrendGraphAllCages();
        }else {
            if (this.data.get("filter").get("showAvgWeight"))
                this.fetchTrendGraphAllCages_InOne();
            else
                this.fetchTrendGraph();
        }
       //ATHUNI  _uniformScaling =  NavBarStore.uniformScaling();
        this.trigger("", true,0,null);

    },





    resetData: function(){
        this.data = this.data.set("trendGraphData", null);

        var defaultAll = Immutable.Map(VakiReactPropTypes.defaultAllCagesPopulationTrendData);

        this.data = this.data.set("allCagesData", defaultAll);

        this.data = this.data.set("avgWeightData", null);

    },

    setDataExtremes: function (){
        var ac = this.data.get("allCagesData");

        if (!ac)
            return;
        if (ac.get("StartDate") != "")
            return;  //has already been done


        var tg = ac.get("TrendGraphDataList");
        if (!tg || tg.length == 0)
            return;

        var StartDate = tg[0].StartDate;
        var EndDate= tg[0].EndDate;
        var MaxAverageWeight = tg[0].MaxAverageWeight;
        var MinAverageWeight = tg[0].MinAverageWeight;
        var MaxCF = tg[0].MaxCF;
        var MinCF = tg[0].MinCF;
        var MaxNumberOfFish = tg[0].MaxNumberOfFish;
        var MinNumberOfFish = tg[0].MinNumberOfFish;

        var len = tg.length;


        for (var i = 1; i<len; i++){

            if (StartDate > tg[i].StartDate)
                StartDate = tg[i].StartDate;

            if (EndDate < tg[i].EndDate)
                EndDate= tg[i].EndDate;

            if (MaxAverageWeight < tg[i].MaxAverageWeight)
                MaxAverageWeight = tg[i].MaxAverageWeight;

            if (MinAverageWeight > tg[i].MinAverageWeight)
                MinAverageWeight = tg[i].MinAverageWeight;

            if (MaxCF < tg[i].MaxCF)
                MaxCF = tg[i].MaxCF;

            if (MinCF > tg[i].MinCF)
                MinCF = tg[i].MinCF;

            if (MaxNumberOfFish < tg[i].MaxNumberOfFish)
                MaxNumberOfFish = tg[i].MaxNumberOfFish;

            if (MinNumberOfFish > tg[i].MinNumberOfFish)
                MinNumberOfFish = tg[i].MinNumberOfFish;
        }



        ac = ac.set("StartDate", StartDate);
        ac = ac.set("EndDate", EndDate);
        ac = ac.set("MaxAverageWeight", MaxAverageWeight);
        ac = ac.set("MinAverageWeight", MinAverageWeight);
        ac = ac.set("MaxCF", MaxCF);
        ac = ac.set("MinCF", MinCF);
        ac = ac.set("MaxNumberOfFish", MaxNumberOfFish);
        ac = ac.set("MinNumberOfFish", MinNumberOfFish);


        this.data = this.data.set("allCagesData", ac);




    },


    onFilterStoreUpdate: function(newFilter) {
        /* Checking if filters have been saved locally by user
        *  If so, we set the filters to those values
        */

       /*
        if(NavBarStore.filtersSaved()) {


            var userFilters = NavBarStore.getUserFilters();
            this.data = this.data.setIn(["filter", "minWeight"], userFilters.minWeight || VConst.DEFAULT_MIN_WEIGHT);
            this.data = this.data.setIn(["filter", "maxWeight"], userFilters.maxWeight || VConst.DEFAULT_MAX_WEIGHT);
        }
*/

        if (!ParMan.isParDefined(_uniformScaling_ID))
            _uniformScaling = ParMan.initParameter(_uniformScaling_ID, NavBarStore.uniformScaling(), 'bool');

        if (!newFilter)
            return;



        //Check if we need new fetch
         if (this.isAllGraphsLoaded()){

            //THen check if changes in min/max and dates
            var myFilt = this.data.get("filter");



            if ((myFilt.get("minWeight") == newFilter.minWeight) 
                && (myFilt.get("maxWeight") == newFilter.maxWeight) 
                && (myFilt.get("MinFishPrHour") == newFilter.MinFishPrHour)  ){

               this.setDataExtremes();
                this.trigger(this.data, null, VConst.TG_REDRAW, this.auxData);
                return;
            }
        }


        this.data = this.data.setIn(["filter", "minWeight"], newFilter.minWeight );
        this.data = this.data.setIn(["filter", "maxWeight"], newFilter.maxWeight );
        this.data = this.data.setIn(["filter", "MinFishPrHour"], newFilter.MinFishPrHour );

        if (this.data.get("filter").get("showAvgWeight")) {
            this.fetchTrendGraphAllCages_InOne();
        }else {
            if (this.data.get("filter").get("showAllCages")) {
                this.fetchTrendGraphAllCages();
            } else {
                this.fetchTrendGraph();
            }
        }
        //ATHUNI  _uniformScaling =  NavBarStore.uniformScaling();

        this.trigger("", true, 0, null);
    },





    onApiNewTrendGraphData: function(newTrendGraphData) {
        // var immutableTrendGraphData = Immutable.Map(newTrendGraphData);

   var disgardThis = false;
        let isError = false;
        let errString = "";

        if ((typeof newTrendGraphData) == "string"  && newTrendGraphData.length >= 5 && newTrendGraphData.substring( 0, 5) == "Error"    ){

            isError = true;
            disgardThis=true;
            errString = newTrendGraphData;
            newTrendGraphData = null;
            if (_fetchArray.length > 1)
                    _fetchArray.splice(0,1);
           
            
       
        }else if (newTrendGraphData.msg && newTrendGraphData.msg.length > 5){

          
            isError = true;
            disgardThis=true;
            errString = newTrendGraphData.msg;
            newTrendGraphData = null;
            if (_fetchArray.length > 1)
                _fetchArray.splice(0,1);
       
        }



     
        if (WaitingSingleGraph > 1) {
            //Ignore this one

            disgardThis = true;
        }
        WaitingSingleGraph = 0;
        var immutableTrendGraphData = null
        if (!disgardThis) {
            immutableTrendGraphData = newTrendGraphData;


            var renderFlags = 0;
            if (!newTrendGraphData)
                renderFlags = 0;


            if ((_fetchArray.length == _fetchCount) && !TrendGraphStore.useSpecEndDates())
                renderFlags |= VConst.TG_RESET;

            //ATHUNI _uniformScaling = NavBarStore.uniformScaling();





            var ac = this.data.get("allCagesData");



            if (ac) {

                var di = ac.get("TrendGraphDataList");

                if (!di)
                    di = [];

                if (di) {
                    di.push(immutableTrendGraphData);
                    ac = ac.set("TrendGraphDataList", di);
                    this.data = this.data.set("allCagesData", ac);
                }
            }
            this.data = this.data.set("trendGraphData", immutableTrendGraphData);
            this.data = this.data.set("fetchingData", false);
        }
        if (_fetchArray.length > 1){
            renderFlags = VConst.TG_NEWDATA;
            if (!disgardThis)
                _fetchArray.splice(0,1);
            if (_fetchArray.length > 0) {
                this.data = this.data.set("isLoading", true);
                this.data = this.data.set("fetchingData", true);

                var filter = this.data.get("filter" );

                var dtStart = null;
                var dtEnd = null;
                if (TrendGraphStore.useSpecEndDates()) {
                    var dd = this.getSpecDates(_fetchArray[0], filter.get("startDate"), filter.get("endDate"));
                    dtStart = moment.utc(dd.startDate).format(VConst.DATE_FORMAT);
                    dtEnd = moment.utc(dd.endDate).format(VConst.DATE_FORMAT);


                }else{
                    dtStart =  filter.get("startDate").format(VConst.DATE_FORMAT);
                    dtEnd = filter.get("endDate").format(VConst.DATE_FORMAT);
                }
                WaitingSingleGraph++;

			let MinFishPrHour =  filter.get("MinFishPrHour")

			if (!MinFishPrHour )
				MinFishPrHour = 0


                VakiAPI.fetchTrendGraphForPopulation(
                    _fetchArray[0].PopulationID,
                    dtStart,
                    dtEnd,
                    (filter.get("minWeight") == null ? -1 : filter.get("minWeight")),
                    (filter.get("maxWeight") == null ? -1 : filter.get("maxWeight")),
                     _fetchArray[0].SegmentID,
                     null,
                   MinFishPrHour
                );
            }
        }else {
           
            _fetchCount=-1;

                this.setDataExtremes();
                renderFlags = VConst.TG_LOAD_OFF | VConst.TG_REDRAW;
                this.data.set("theNewLoading", false);
  
        }

        if (isError){
       
           
            this.trigger(null, null,  renderFlags, errString);
        }


        if (!disgardThis){
       
            this.trigger(this.data, null,  renderFlags, this.auxData);
        }
    },

    onApiNewTrendGraphAllCagesData: function (newTrendGraphData) {



        var renderFlags = VConst.TG_NEWDATA|VConst.TG_RESET|VConst.TG_LOAD_OFF;
        this.data.set ("theNewLoading", false);

        var immutableTrendGraphData = Immutable.Map(newTrendGraphData);
        if(this.showAvgWeight)  //this.handleAvgWeightData(newTrendGraphData);
            this.data = this.data.set("avgWeightData", immutableTrendGraphData);


        this.data = this.data.set("allCagesData", immutableTrendGraphData);
        this.data = this.data.set("fetchingData", false);
        //ATHUNI _uniformScaling =  NavBarStore.uniformScaling();
        _fetchBigChunk = false;
        this.trigger(this.data, null, renderFlags, this.auxData);
    },

    handleAvgWeightData: function(newTrendGraphData) {
        var immutableTrendGraphData = Immutable.Map(newTrendGraphData);
        this.data = this.data.set("avgWeightData", immutableTrendGraphData);
        this.data = this.data.set("fetchingData", false);
        //ATHUNI  _uniformScaling =  NavBarStore.uniformScaling();

        this.trigger(this.data, null, 0, null);
    },


    /*
    onStartDateUpdate: function (newStartDate) {



        if (newStartDate)
            ParMan.setItem(startDate_ID, newStartDate.format())

         this.data = this.data.setIn(["filter", "startDate"], newStartDate);

        if(this.data.get("filter").get("showAllCages")) this.fetchTrendGraphAllCages();
        else if(this.data.get("filter").get("showAvgWeight")) this.fetchTrendGraphAllCages_InOne();
        else this.fetchTrendGraph();

        this.trigger("", true, 0, null);

    },

    onEndDateUpdate: function (newEndDate) {


        if (newEndDate)
            ParMan.setItem(endDate_ID, newEndDate.format())

          this.data = this.data.setIn(["filter", "endDate"], newEndDate);


        if(this.data.get("filter").get("showAvgWeight")) {
            this.fetchTrendGraphAllCages_InOne();
        }else {
            if (this.data.get("filter").get("showAllCages"))
                this.fetchTrendGraphAllCages();
            else
                this.fetchTrendGraph();
        }
        this.trigger("", true, 0, null);

    },
    */

    onDateChange: function (startDate, endDate) {


        var oldStartDate = this.data.get("filter").get("startDate");
        var oldEndDate = this.data.get("filter").get("endDate");


        if (oldStartDate != startDate) {
            ParMan.setItem(startDate_ID, startDate.format())
            this.data = this.data.setIn(["filter", "startDate"], startDate);
        }

        if (oldEndDate != endDate) {
            ParMan.setItem(endDate_ID, endDate.format())
            this.data = this.data.setIn(["filter", "endDate"], endDate);
        }

        if (this.data.get("filter").get("showAvgWeight")) {

            this.fetchTrendGraphAllCages_InOne();
        } else {

            if (this.data.get("filter").get("showAllCages"))
                this.fetchTrendGraphAllCages();
            else
                this.fetchTrendGraph();
        }

        this.trigger("", true, 0, null);

    },


    onShowAllCages: function() {

        //Check which data we have

        var reUseData = false;

        var allCages = this.data.get("filter").get("showAllCages");

        if(!allCages) {
            this.data = this.data.setIn(["filter", "showAvgWeight"], false);
            ParMan.setItem(showAvgWeight_ID, false);

            if (!this.isAllGraphsLoaded())
                this.fetchTrendGraphAllCages();
            else
                reUseData = true;



        }
        else {
            if (this.data.get("filter").get("showAvgWeight")) {

                this.fetchTrendGraphAllCages_InOne();
            } else {

                var idx = this.getIndexOfPop(FilterStore.getSelectedPopulationID());

                if (idx < 0)
                    this.fetchTrendGraph();
                else
                    reUseData = true;
            }
        }
        ParMan.setItem(showAllCages_ID, !allCages);
        this.data = this.data.setIn(["filter", "showAllCages"], !allCages);

        if (reUseData){

            if (!TrendGraphStore.useSpecEndDates())
                this.setDataExtremes();
            this.trigger(this.data,null, VConst.TG_REDRAW, this.auxData);
        }else
            this.trigger("", true);
    },

    onShowAvgWeight:function(){

        var reUseData = false;
        var avgWeight = this.data.get("filter").get("showAvgWeight");
        var allLoaded = this.isAllGraphsLoaded();

        if (!avgWeight) { //Turning show AvgWgts ON
            this.data = this.data.setIn(["filter", "showAllCages"], true);
            ParMan.setItem(showAllCages_ID, true);
            if (!this.useSpecEndDates() && this.isAllGraphsLoaded()) {

                reUseData = true;
            }else{

                this.fetchTrendGraphAllCages_InOne();
            }
            this.data = this.data.setIn(["filter", "useSpecEndDates"], false);
            ParMan.setItem(useSpecEndDates_ID, false);
        }else{ //Coming from show AVGWgt for all all - means all must be loaded
            reUseData = true;

        }

        this.data = this.data.setIn(["filter", "showAvgWeight"], !avgWeight);
        ParMan.setItem(showAvgWeight_ID, !avgWeight);
        if (reUseData){
            this.setDataExtremes();
            this.trigger(this.data,null, VConst.TG_REDRAW, this.auxData);
        }else
            this.trigger("", true);
    },


    onUniformScaling:function(){

        _uniformScaling = !_uniformScaling;
        ParMan.setItem(_uniformScaling_ID, _uniformScaling);
      //  this.trigger("", true);
        this.setDataExtremes();
        this.trigger(this.data, null, VConst.TG_REDRAW,this.auxData);

    },


    onAddComment: function(newComment ) {

        VakiAPI.sendComment(newComment);
    },

    logMapElements: function (value, key, map) {
    alert("m[" + key + "] = " + value);
},

    onCommentSaved: function(newComment ) {


        var oneGraphData = null;
        var showAll = this.data.get("filter").get("showAllCages");

        if (!showAll) {
            oneGraphData = this.data.get("trendGraphData");
        } else {
            var allGraph = this.data.get("allCagesData").get("TrendGraphDataList");
            for (var k in allGraph) {
                if (newComment.PopulationID == allGraph[k].PopulationID) {
                    oneGraphData = allGraph[k];
                    break;
                }
            }
        }

        if (oneGraphData == null)
            return;

        var lst = oneGraphData.PopulationTrendDataList;

        var found = false;
        for (var p in lst) {
            if (moment.utc(lst[p].Date).format("YYYY-MM-DD") == newComment.PertinentDateTime) {
                if (lst[p].Comment == null)
                    lst[p].Comment = newComment.CommentText;
                else
                    lst[p].Comment = lst[p].Comment + "<br/>" + newComment.CommentText;

                found = true;
                break;
            }
        }

        if (found) {
            this.trigger(this.data, null, VConst.TG_REDRAW, this.auxData);
        }


    },


    fetchingData: function() {
        return this.data.get("fetchingData");
    },

    onLoadingDone: function() {
        if(this.data.get("isLoading")) {
            this.data = this.data.set("isLoading", false);

      }
    },

    getFilter: function() {
        return this.data.get("filter");
    },

    isLoading: function() {
        return this.data.get("isLoading");
    },

    theNewLoading: function() {
        return this.data.get("theNewLoading");
    },

    showAllCages: function() {
        return this.data.get("filter").get("showAllCages");
    },

    showAWasTrendlines: function(){


        return this.data.get("filter").get("showAWasTrendlines");
    },


    useSpecEndDates: function(){


    return (!this.data.get("filter").get("useSpecEndDates")?false:true) ;
    },

    showAvgWeight: function() {
        return (!this.data.get("filter").get("showAvgWeight")?false:true);
    },

    resetShowAvgWeight: function (){
        this.data.setIn(["filter", "showAvgWeight"], false);
        ParMan.setItem(showAvgWeight_ID, false);
    },

    getTrendGraphData: function() {
        return this.data.get("trendGraphData");
    },

    getAllCagesData: function() {
        return this.data.get("allCagesData");
    },

    getAvgWeightData:function(){
        return this.data.get("avgWeightData");
    },

    uniformScaling: function() {

        return (!_uniformScaling?false:true);
    },




    fetchTrendGraph: function() {
        var selectedPopulation = FilterStore.getSelectedPopulation();

        _fetchCount = 1;

        _fetchArray = [];

        _fetchArray.push(selectedPopulation);

        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingData", true);





        if (selectedPopulation) {
            var filter = this.data.get("filter");

            var dtStart = null;
            var dtEnd = null;
            if (TrendGraphStore.useSpecEndDates()) {
                var dd = this.getSpecDates(_fetchArray[0], filter.get("startDate"), filter.get("endDate"));

                dtStart = moment.utc(dd.startDate).format(VConst.DATE_FORMAT);
                dtEnd = moment.utc(dd.endDate).format(VConst.DATE_FORMAT);
            }else{
                dtStart =  filter.get("startDate").format(VConst.DATE_FORMAT);
                dtEnd = filter.get("endDate").format(VConst.DATE_FORMAT);
            }

            let MinFishPrHour = filter.get("MinFishPrHour");
            if (!MinFishPrHour)
                MinFishPrHour = 0;

            WaitingSingleGraph++;
            VakiAPI.fetchTrendGraphForPopulation(
                selectedPopulation.PopulationID,
                dtStart,
                dtEnd,
                (filter.get("minWeight") == null ? -1 : filter.get("minWeight")),
                (filter.get("maxWeight") == null ? -1 : filter.get("maxWeight")),
                selectedPopulation.SegmentID,
                null,
                MinFishPrHour
                
            );
            var rFlags = (VConst.TG_CLEAR | VConst.TG_LOAD_ON);
            this.data.set ("theNewLoading", true);


            this.resetData();

            this.trigger(this.data, null,  rFlags, this.auxData);
        }
    },





    getSpecDates: function (pop ,dStart, dEnd){

        var newDates = {startDate: null, endDate:null  };
        var ms = Math.abs(dEnd-dStart);
        newDates.endDate = BGTools.parseJsonDate(pop.EndDate)
        newDates.startDate = new Date(newDates.endDate - ms);
        return newDates;

    },



    fetchTrendGraphAllCages: function() {

        var pops = FilterStore.getPopulations();
        _fetchCount = pops.length;

        _fetchArray = [];

        if (_fetchCount == 0) {
            alert ("No populations on farm!");
            return;
        }

        for (var i = 0; i<_fetchCount; i++){

            _fetchArray.push (pops[i]);
        }


        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingData", true);



        var filter = this.data.get("filter");

        var dtStart = null;
        var dtEnd = null;

        if (TrendGraphStore.useSpecEndDates()) {
            var dd = this.getSpecDates(_fetchArray[0], filter.get("startDate"), filter.get("endDate"));

           

            dtStart = moment.utc(dd.startDate).format(VConst.DATE_FORMAT);
            dtEnd = moment.utc(dd.endDate).format(VConst.DATE_FORMAT);
         

        }else{
            dtStart =  filter.get("startDate").format(VConst.DATE_FORMAT);
            dtEnd = filter.get("endDate").format(VConst.DATE_FORMAT);


        }

        WaitingSingleGraph++;
            VakiAPI.fetchTrendGraphForPopulation(
                _fetchArray[0].PopulationID,
                dtStart,
                dtEnd,
                (filter.get("minWeight") == null ? -1 : filter.get("minWeight")),
                (filter.get("maxWeight") == null ? -1 : filter.get("maxWeight")),
                _fetchArray[0].SegmentID,
                null,
                filter.get("MinFishPrHour")
            );
        var rFlags = (VConst.TG_CLEAR | VConst.TG_LOAD_ON);
        this.data.set ("theNewLoading", true);

        this.resetData();
        this.trigger(this.data, null,  rFlags, this.auxData);
    },

    fetchTrendGraphAllCages_InOne: function() {



        _fetchArray = [];
        _fetchCount = 0;
        _fetchBigChunk = true;

        var selectedFarmID = FilterStore.getSelectedFarmID();
        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingData", true);
        if(selectedFarmID && selectedFarmID !== 0) {
            var filter = this.data.get("filter");
            
      
            VakiAPI.fetchTrendGraphAllCages(
                selectedFarmID,
                filter.get("startDate").format(VConst.DATE_FORMAT),
                filter.get("endDate").format(VConst.DATE_FORMAT),
                filter.get("minWeight"),
                filter.get("maxWeight"),
                FilterStore.getShowInactive()
            );
            var rFlags = (VConst.TG_CLEAR | VConst.TG_LOAD_ON);
            this.data.set ("theNewLoading", true);

            this.resetData();
            this.trigger(this.data, null,  rFlags, this.auxData);
        }
    },

    fetchTrendGraphAllCages_OLD: function() {

        var selectedFarmID = FilterStore.getSelectedFarmID();
        this.data = this.data.set("isLoading", true);
        this.data = this.data.set("fetchingData", true);
        if(selectedFarmID && selectedFarmID !== 0) {
            var filter = this.data.get("filter");
      
            VakiAPI.fetchTrendGraphAllCages(
                selectedFarmID,
                filter.get("startDate").format(VConst.DATE_FORMAT),
                filter.get("endDate").format(VConst.DATE_FORMAT),
                filter.get("minWeight"),
                filter.get("maxWeight"),
                FilterStore.getShowInactive()
            );
        }
    },


    getFetchStatus: function(){

        if (_fetchBigChunk)
            return "Loading...";

        if (_fetchCount<=0)
            return "";


/*        var p = Math.round( 100 - (100*_fetchArray.length) / _fetchCount);
        return p+"%";
*/
        var str = (_fetchCount-_fetchArray.length)+"/"+_fetchCount+ "    ";
        return str;

    },


    isAllGraphsLoaded: function(){
        if (!this.data)
            return false;
        var ac = this.data.get("allCagesData");

        if (!ac)
            return false;

        var tl = ac.get("TrendGraphDataList");

        if (!tl)
            return false;

        var pops = FilterStore.getPopulations();

        if( pops &&  pops.length == tl.length){

            if (tl.length > 0) {
                return (pops[0].PopulationID == tl[0].PopulationID);
            }
        }


        return false;
    },

    getIndexOfPop: function(popID){

        if (!this.data)
            return -1;
        var ac = this.data.get("allCagesData");

        if (!ac)
            return -1;
        var tl = ac.get("TrendGraphDataList");

        if (!tl)
            return -1;
        var idx = -1;
        var len = tl.length;

        for (var p=0; p<len; p++ ) {


            if (tl[p].PopulationID == popID) {
                idx = p;
                break;
            }

        }

        return idx;
    },
    getTrendForPop: function(popID){

        if (!this.data)
            return null;
        var ac = this.data.get("allCagesData");
        if (!ac)
            return null;
        var tl = ac.get("TrendGraphDataList");

        if (!tl)
            return null;
        var idx = 0
        for (var p=0; p<tl.length; p++ ) {
            if (tl[p].PopulationID == popID) {

                return tl[p];

            }
            idx++;
        }
        return null;
    },


    setAuxData: function (lst){


    }

});

module.exports = TrendGraphStore;