'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, FormControl } from 'react-bootstrap';
import VConst from './../utils/VConst.js';

require('./Footer.css');








	export default function Footer() {
  






     

    



	return (
		<div className = "mt-3">
<hr/>
<a href="https://www.essentialaccessibility.com:443/msd">
    <img className = "accessibility-img " src="https://assets.msd-animal-health.com:443/brands/accessibility/img/accessibility-WhiteOnGray.png" alt="Accessibility button"/>
    </a>

   


    <a href="https://www.msd.com:443/our-commitment-to-accessibility-for-all" className = "accessibility-link ">Accessibility</a>

	
    <a href="https://www.msdprivacy.com/" className = "accessibility-link ">Privacy</a>
	
	<a href="https://www.msd.com/terms-of-use/" className = "accessibility-link ">Terms of use</a>

	<a href="https://vakiiceland.is/sales-and-service-agents/" className = "accessibility-link ">Contact</a>
	




<div className="copyRight" >{"© "}{(new Date().getFullYear())} {VConst.COPYRIGHT} </div>

		</div>
	);
}