/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

'use strict';
var React = require('react');
import { ArrowRepeat, Trash } from 'react-bootstrap-icons';
var Actions = require('../../../../actions/Actions.js');
import ConfirmDeleteModal from './Modals/ConfirmDeleteModal.jsx';

export default class FarmListRow extends React.Component {
	constructor(props) {
		super(props);

		this.isNullOrEmpty = (data) => {
			return data === null || data === '' || data === undefined;
		};
		this.deleteClicked = () => {
			Actions.farmListRowDelete(this.props.farm);
		};
		this.restoreClicked = () => {
			Actions.farmListRowRestore(this.props.farm);
		};
		this.getButton = () => {
			if (this.props.farm.isActive) {
				return this.getDeleteButton();
			} else {
				return this.getRestoreButton();
			}
		};

		this.getDeleteButton = () => {
			return (
				<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Delete">
					<button className="btn btn-danger btn-xs" onClick={this.deleteClicked}>
						<Trash />
					</button>
				</p>
			);
		};
		this.getRestoreButton = () => {
			return (
				<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Restore">
					<button className="btn btn-primary btn-xs" onClick={this.restoreClicked}>
						<ArrowRepeat />
					</button>
				</p>
			);
		};

		this.farmClicked = () => {
			Actions.farmListRowClick(this.props.farm);
		};
	} //constructor

	farmClicked() {
		Actions.farmListRowClick(this.props.farm);
	}
	render() {
		var country, organisation;
		var button = this.getButton();
		if (this.isNullOrEmpty(this.props.farm.country)) country = this.props.t('adminView.noCountryRegistered');
		else country = this.props.farm.country.Name;
		if (this.isNullOrEmpty(this.props.farm.organisation)) organisation = 'No organisation registered';
		else organisation = this.props.farm.organisation.name;
		return (
			<tr>
				<td className="col-md-4" onClick={this.farmClicked.bind(null, this.props.farm.name)}>
					{' '}
					{this.props.farm.name}{' '}
				</td>
				<td className="col-md-4" onClick={this.farmClicked.bind(null, this.props.farm.name)}>
					{' '}
					{organisation}{' '}
				</td>
				<td className="col-md-2" onClick={this.farmClicked.bind(null, this.props.farm.name)}>
					{' '}
					{country}{' '}
				</td>
				<td className="col-md-2">{button}</td>
			</tr>
		);
	}
}
