
"use strict";
var Reflux = require("reflux");
var Actions = require("./../../actions/Actions.js");
var React = require("react");
var RB = require("react-bootstrap");
var ReactDOM = require("react-dom");
var moment = require("moment");
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var UserDataStore = require("./../../stores/UserDataStore.js");
var VConst = require("./../../utils/VConst.js");
var t = TranslateStore.getTranslate();
var FilterStore = require("./../../stores/FilterStore.js");

require("./DashPause.css");

var TheRunStates = [
    "Normal",
    "Maintenance",
    "Delicing",
    "Grading",
    "Other...",
    "Frame Removed (Finish)"

];


var DashPauseStore = Reflux.createStore({
    init () {


        this.listenTo(Actions.dashPause, this.onTogglePause);
        this.listenTo(Actions.dashSelRunstate, this.onRunstateSelect);
      //  this.listenTo(Actions.dashPauseSubmit, this.onSubmitClick);
        
        this.data = {
            runState: -13,
            show: false,
            popID: 0,
            comment: null,
            Name: ""
        };
        
        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },


    onTogglePause (pop) {

        
        //(x, y, comments, farm, pop) {
        this.data.runState = (pop.PopDayStatus.RunState ? pop.PopDayStatus.RunState: 0);
        this.data.popID = pop.PopulationID;
        this.data.Name = pop.Name;
        this.data.show = true;

        this.trigger(this.data);
    },

    onRunstateSelect (e) {

        this.data.runState = e;
        this.trigger(this.data);
       
    },




    onSubmitClick (e) {
 
         
        var inp = document.getElementById("theInput");
        var theText = inp.value.trim();
        var str = moment().format("YYYY-MM-DD");
        var pop = 0;
        

        if (this.Comments.popID !== null)
            pop = this.Comments.popID;

   
        if (theText !== null && theText !== "") {
            var newComment = {
                FarmID: FilterStore.getSelectedFarmID(),
                PopulationID: pop,
                PertinentDateTime: str,
                CommentText: theText
            };

            Actions.addComment(newComment);
        }

      //  e.stopPropagation();
    }

});


export default class DashPause  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            data: null
   
        };

    };





    componentDidMount () {
        //var domNode = ReactDOM.findDOMNode(this);
        this.unsubscribeDashPause = DashPauseStore.listen(this.onChange);
        //   createDashboardCanvas(domNode, this.props.populationStatusList);

        window.addEventListener("resize", this.onResize);
       // this.setState({});
    };


    componentWillUnmount () {
        this.unsubscribeDashPause();
    };

    onResize () {

  
        this.setState({});
    };




    onChange (theData) {

 
        this.setState({
            data: theData 
  
        });

    };
    

    onEdit (tt) {
       
        var str = tt.target.value.trim();
        
        this.state.buttonDisabled = true;
        if (str.length > 0)
            this.state.buttonDisabled = false;
        this.setState({});

    };
    render () {


        var el = document.getElementById("divPauseOverlay");


        /*
        if (!el || !this.state.data || !this.state.data.show)
            return <div></div>;

*/

        var CommentsHeader = "";
        var TheComments = "";

        var isDisabled = true;

        var theSelectedRunState = "-";


        var divNormal = null;

        var divInterrupted = null;

        var descCurrent = "";

        var fs = 14;
        
        if (el) {
            fs = parseInt( window.getComputedStyle(document.body).fontSize) ;

            var numEms = window.innerWidth / fs;
            var pad = 4;
            if (numEms > 45) {
                pad = ((numEms - 40) / 2)*100 / numEms;
            }



            el.style.paddingLeft = pad+"%";
            el.style.paddingRight = pad + "%";
            var elC = document.getElementById("divCommentsContent");
            elC.style.width = (100 - 2 * pad) + "%";

            divNormal = document.getElementById("divStateNormal");

            divInterrupted = document.getElementById("divStateInterrupted");



        }

        if (this.state.data && this.state.data.show) {


            if (el)
                el.style.display = 'block';





            if (this.state.data.runState < 0 || this.state.data.runState >= TheRunStates.length)
                this.state.data.runState = 0;


            theSelectedRunState = TheRunStates[this.state.data.runState];


            if (divNormal) {
                divNormal.style.display = "none";
                divInterrupted.style.display = "none";


                if (this.state.data.runState > 0) {
                    divInterrupted.style.display = "inline"
                } else {
                    divNormal.style.display = "inline";
                }
            }   

            descCurrent = <div> Current running state:  < b > {TheRunStates[this.state.data.runState]} </b> </div>;



        } else {
            if (el) {
                el.style.display = 'none';
                document.getElementById("theInput").value = "";
            }

        }




     


        var RunStateOptions = TheRunStates.map(function (rs, i) {
            var v = i.toString();
            return <RB.MenuItem eventKey={i} key={i}>{rs}</RB.MenuItem>;

        });

        


        return (
            <div id="divPauseOverlay" 
                onClick={function () { Actions.dashCommentsShow(false); }}
                 >
                
                <div id="divCommentsContent" className= "clsCommentsContent">
                

                   
                   
                   
           
        
                    
                    <div>
                        <div id="divDescriptionCurrent" >{descCurrent}</div>



                        <div id="divStateNormal">
                        <br />

                        Select an interruption on a normal running state:
                            <br />
                        <RB.DropdownButton title={theSelectedRunState} onSelect={Actions.dashSelRunstate}>
                            {RunStateOptions}
                        </RB.DropdownButton>
                            <br />
                            <br />
                            


                        <RB.Input
                            
                            id = "theInput"
                            type="text"
                      
                            onClick={function (e) {
                                e.stopPropagation();
                            }}

                       
                            placeholder={"Optional: write description here"}
                                onChange={this.onEdit} />

                        </div>

                        <div id="divStateInterrupted">
                            Click [Confirm] to return to normal running state:
                            </div>

                        <br />

                        <div className="clsSubmit">
                            <RB.Button
                                id="theButton"
                            type="button"
                            width="20%"

                            disabled={this.state.buttonDisabled}
                            onClick={ Actions.dashCommentSubmitClick}
                            
                            >
                            Confirm
                        </RB.Button>
                        </div>


                    </div>

               </div>



            </div>

        )
    }
};
