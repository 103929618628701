/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var request = require("superagent");

var VConst = require("./../utils/VConst.js");
var Actions = require("./../actions/Actions.js");
var SessionStore = require("./../stores/SessionStore.js");



var fetchingPopID = -1;
/**
 * Module that takes care of interfacing with Vaki's web services, fetching
 * data and what not.
 *
 * The project is set up in such a way that this file depends on SessionStore
 * to get the token of the user (JSON Web Token, JWT, for user access). This
 * may not be the most ideal way but it was the most minimal I could think of
 * at the time. The other way was to add the JWT as a parameter to every fetch
 * function but I found that to be too verbose.
 *
 * For http fetching we use the library superagent. For documentation:
 *  * http://visionmedia.github.io/superagent/ - documentation
 *  * https://github.com/visionmedia/superagent - GitHub repo
 */
var VakiAPI = {
    /**
     * Fetches info to display Dashboard all farms
     */



    getDashboardList: function () {
        
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "DashboardList")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")

                .end(function (error, result) {

                    if (result.ok) {
                        // console.log(result.req.url);

                        Actions.apiNewDashboardList(result.body);
                    } else {
                        console.log("Error retrieving the dashboard list");
                        console.log(error);
                        Actions.apiError(error);
                    }
                })

            ;

        }else
            Actions.redirectToLogin();
    },

    /**
     * Fetches UserData from VakiAPI. It contains stuff like what
     * organisations, farms, populations, roles, preferences, and features the
     * user has access to. It was intended to be a API call you'd invoke right
     * after a user had been authenticated.
     */
    fetchUserData: function (ShowInactive) {

        var inactives = ShowInactive ? 1 : 0;


        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "UserData")
                .query({ShowInactive: inactives, UserCountryFilter: 1})
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    var nullData = !result;

                    if (!nullData && result.ok) {

                        // console.log(result.req.url);
                        Actions.apiNewUserData(result.body);
                    } else {

                        console.log("Error retrieving user data");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    /**
     * Fetches data needed to display the Dashboard report for a given farm.
     *
     * @param farmID {number} Database ID of the farm you want the dashboard for
     */
    fetchDashboardForFarm: function (farmID) {
        
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "SiteDashboard")
                .query({FarmID: farmID, DaysOfComments: 30})
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewDashboardData(result.body);
                    } else {
                        console.log("Error retrieving dashboard data");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },



    /**
     * Fetches trend graph data from VakiAPI for a given population and filters.
     *
     * @param populationID {number} Database ID of the population
     * @param startDate {string} Format: YYYY-MM-DD
     * @param endDate {string} Format: YYYY-MM-DD
     * @param minWeight {number} Weight in grams
     * @param maxWeight {number} Weight in grams
     */
    fetchTrendGraphForPopulation: function (populationID, startDate, endDate, minWeight, maxWeight, segmentID, callbackFunction, MinFishPrHour) {


       fetchingPopID = populationID;

        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationID: populationID,
                StartDate: startDate,
                EndDate: endDate,
                MinWeight: minWeight,
                MaxWeight: maxWeight,
                SegmentId: segmentID,
                MinNumPrHour: MinFishPrHour
            };

            request
                .get(VConst.APIURL + "TrendGraph")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {

                    if (result.ok) {

                        if (result.body.PopulationID == fetchingPopID || (result.body.msg && result.body.msg.length > 5)) {

                            if (!callbackFunction)
                                Actions.apiNewTrendGraphData(result.body);
                            else
                                callbackFunction(result.body);
                        }

                    } else {
                        console.log("Error retrieving trend graph data1   " + JSON.stringify(queryParameters));
                        console.log(error);
                        //Actions.apiError(error);
                        Actions.apiNewTrendGraphData("Error: "+error+ JSON.stringify(result));
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchTrendGraphForPopulationForHist: function (populationID, startDate, endDate, minWeight, maxWeight, segmentID, farmID, callbackFunction) {

        fetchingPopID = populationID;

        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationID: populationID,
                StartDate: startDate,
                EndDate: endDate,
                MinWeight: minWeight,
                MaxWeight: maxWeight,
                SegmentId: segmentID,
                FarmID: farmID
            };

            request
                .get(VConst.APIURL + "GetTrendlineForHist")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        //if (result.body.PopulationID == fetchingPopID) {

                            if (!callbackFunction)
                                Actions.apiNewTrendGraphData(result.body);
                            else
                                callbackFunction(result.body);
                       // } 

                    } else {
                        
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        } else
            Actions.redirectToLogin();
    },


    /**
     * Fetches data for all populations on a given farm needed to draw trend
     * graphs.
     *
     * @param farmID {number} Database ID of the farm you want the dashboard for
     * @param startDate {string} Format: YYYY-MM-DD
     * @param endDate {string} Format: YYYY-MM-DD
     * @param minWeight {number} Weight in grams
     * @param maxWeight {number} Weight in grams
     */

    fetchTrendGraphAllCages: function (farmID, startDate, endDate, minWeight, maxWeight, showInactive) {

        console.log("Trend FetchBegin  " + (new Date()).getTime());

        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                FarmID: farmID,
                StartDate: startDate,
                EndDate: endDate,
                MinWeight: minWeight,
                MaxWeight: maxWeight,
                ShowInactive: (showInactive ? 1 : 0)
            };


            request
                .get(VConst.APIURL + "TrendGraphAllCages")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        console.log("Trend FetchSuccess  " + (new Date()).getTime())
                        Actions.apiNewTrendGraphAllCagesData(result.body);
                    } else {
                        console.log("Error retrieving trend graph all cages data  " + JSON.stringify(queryParameters));
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    /**
     * Fetches data required for the Distribution report from VakiAPI.
     *
     * Note that the Distributions endpoint in the API accepts A LOT of
     * parameters. Originally I intended to include them all in the function
     * definition but I fear it will cause major refactoring pains later on
     * when requirements change. So instead we just expect one object where
     * we specify our parameters. At the time of writing they were as follows:
     *
     *  {
     *      populationID, integer
     *      startDate, string on the form of "YYYY-MM-DD"
     *      endDate, string on the form of "YYYY-MM-DD"
     *      minWeight, integer in grams
     *      maxWeight, integer in grams
     *      minCF, float condition factor
     *      maxCF, float condition factor
     *      starvedWeightFactor, float 0.0 - 1.0 ratio
     *      guttingFactor,
     *      CFBinSize, float 0.0 - 1.0
     *      weightBinSize, integer in grams
     *  }
     *
     * This documentation will probably be outdated at some point, so the above
     * is mostly listed as a starting point. It's probably best to refer to:
     *
     * https://docs.google.com/document/d/11hav6GDc83T7ho7CcojyDDmUSV5UKJN3QOLml7pZlto/edit
     *
     * @param queryParameters {object}
     */
    fetchDistributions: function (queryParameters, callbackFunc) {


        if (SessionStore.isLoggedIn()) {
            // API expects gutting factor to be 0.0 - 1.0. Divide by 100
            // because the UI uses whole percent numbers. 12% will then be 0.12
            queryParameters.guttingFactor = queryParameters.guttingFactor / 100;
            request
                .get(VConst.APIURL + "Distributions")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {


                    if (result.ok) {
           
                        if (callbackFunc)
                           callbackFunc(result.body)
                        else
                           Actions.apiNewDistributionsData(result.body);

                    } else {
                        //console.log("Error retrieving trend graph data");

                        console.log(error);
                        Actions.apiError(error);
                    }


                });
        }else
            Actions.redirectToLogin();
    },

    /**
     * Fetches data required to display the EFCR graph.
     *
     * Structure of queryParameters:
     * {
     *      populationID, integer database ID
     *      startDate, string on the form of "YYYY-MM-DD"
     *      endDate, string on the form of "YYYY-MM-DD"
     *      trendLineLength, integer representing days, usually 30, 60, or 90
     *  }
     *
     * @param {object} queryParameters
     */


    fetchFcrGraphData: function (queryParameters) {


        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "EFCRGraphSingleCage")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")

                .end(function (error, result) {
                    if (result.ok) {


                        //console.log(result.req.url);
                        //console.log(result.body);
                        Actions.apiNewFcrData(result.body);
                    } else {
                        console.warn("Error retrieving single cage FCR data: "+ error);
                        Actions.apiErrorFCR();
                        
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchFcrAllGraphData: function (queryParameters) {


        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "EFCRGraphAllCages")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        //console.log(result.req.url);
                        //console.log(result.body);
                        Actions.apiNewFcrData(result.body);
                    } else {
                        console.warn("Error retrieving FCR data: " + error);
                        Actions.apiErrorFCR();
                     
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchWeightAndTimeGraphData: function (populationID, startDate, endDate, minWeight, maxWeight, callbackFunction) {
  
        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationId: populationID,
                StartDate: startDate,
                EndDate: endDate,
                MinWeight: minWeight,
                MaxWeight: maxWeight
            };
            request
                .get(VConst.APIURL + "WeightAndTimeChart")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
    
                        if (!callbackFunction)
                            Actions.apiNewWeightAndTimeData(result.body);
                        else
                            callbackFunction(result.body);

                    } else {
                        console.log("Error retrieving Weight and time data");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getUserDimensions: function () {

        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "UserDimensions")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewUserDimensionData(result.body);
                    } else {
                        console.log("Error retrieving user dimensions");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getCountryDimensions: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "CountryDimensions")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewCountryDimensionData(result.body);
                    } else {
                        console.log("Error retrieving country dimensions");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getUserList: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "UserList")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewUserListData(result.body);
                    } else {
                        console.log("Error retrieving user list");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getOrganisationList: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "OrganisationList")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewOrgListData(result.body);
                    } else {
                        console.log("Error retrieving organisation list");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getFarmList: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "FarmList")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewFarmListData(result.body);
                    } else {
                        console.log("Error retrieving farm list");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getComputerList: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "ComputerList")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiNewCompListData(result.body);
                    } else {
                        console.log("Error retrieving computer list");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    changeUserPassword: function (passwordData) {
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "ChangeUserPassword")
                .query({oldPassword: passwordData.oldPassword})
                .query({newPassword: passwordData.newPassword})
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        if (result.body === "Old password does not match" ||
                            result.body === "Choose a stronger password") {
                            Actions.apiPasswordChanged(false);
                        } else {
                            Actions.apiPasswordChanged(true);
                        }
                    } else {
                        Actions.apiPasswordChanged(false);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    postUser: function (user) {
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "UserInfo")
                .send(user)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiObjectSaved();
                    } else {
                        console.log("Error posting user");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    postOrganisation: function (org) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "OrganisationInfo")
                .send(org)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiObjectSaved();
                    } else {
                        console.log("Error posting organisation");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    postFarm: function (farm) {
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "FarmInfo")
                .send(farm)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        console.log("Saving farm okay");
                        console.log(result);
                        Actions.apiObjectSaved();
                    } else {
                        console.log("Error posting farm");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    postComputer: function (computer) {

       


        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "ComputerInfo")
                .send(computer)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        Actions.apiObjectSaved();
                    } else {
                        console.log("Error posting computer");
                        console.log(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    fetchPopCompareData: function (populationList, minWeight, maxWeight) {


        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationList: populationList,
                MinWeight: minWeight,
                MaxWeight: maxWeight
            };

            request
                .get(VConst.APIURL + "HistoricalTrendGraphs")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        //console.log(result.body);
                        Actions.apiNewPopCompareData(result.body);
                    } else {
                        console.log("Error retrieving trend graph data2");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    sendComment: function (comment) {
    
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostComment")
                .send(comment)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiCommentSaved(comment);
                    } else {
                        alert("Error posting comment");
                        console.log("Error posting comment");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchHistoricalPopulations: function () {

        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                ShowVirtual: 0
            };


            request
                .get(VConst.APIURL + "HistoricalPopulations")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        //console.log (JSON.stringify(result.body));
                        //console.log(result.body);
                        Actions.apiHistPopListReceived(result.body);
                    } else {
                        //alert ("Error fetching");
                        console.log("Error retrieving trend graph data3");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

/*
    fetchCF_Chart: function (popID, dateString) {
        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationId: popID,
                Date: dateString
            };

            request
                .get(VConst.APIURL + "CfAndWeightChart")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {


                        //console.log(result.body);
                        Actions.apiNewCF_Chart(result.body);
                    } else {
                        console.log("Error retrieving cf graph data");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
 Actions.redirectToLogin();

    },
*/
    fetchCF_ChartToFrom: function (popID, dtStrStart, dtStrEnd, callbackFunc, MinHourCount) {
        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationId: popID,
                StartDate: dtStrStart,
                EndDate: dtStrEnd,
                MinHourCount: MinHourCount
            };

            request
                .get(VConst.APIURL + "CfAndWeightChartToFrom")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        if (!callbackFunc)
                            Actions.apiNewCF_Chart(result.body);
                        else
                            callbackFunc(result.body);

                    } else {
                        console.log("Error retrieving CF graph data4 ");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();

    },



    fetchMonthlyReportPdf: function (farmID) {
        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                FarmId: farmID,
                pIssueDate: "2016-01-18",
                pMinWeight: 100,
                pMaxWeight: 13000

            };

            request
                .get(VConst.APIURL + "MonthlyReportPdf")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
 
                        Actions.apiPdfReport(result.text);
                    } else {
                        console.log("Error retrieving trend graph data5");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();


    },


    postSubscription: function (sub) {
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "ReportSubscription")
                .send(sub)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {


                        Actions.apiPostSubscription(result.text);
                    } else {
                        console.log("Error posting sub");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    putSubscription: function (sub) {
        if (SessionStore.isLoggedIn()) {
            request
                .put(VConst.APIURL + "ReportSubscription")
                .send(sub)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (!result.ok) {

                        console.log("Error putting sub:" + error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    deleteSubscription: function (sub) {
        if (SessionStore.isLoggedIn()) {
            request
                .del(VConst.APIURL + "ReportSubscription")
                .send(sub)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (!result.ok) {

                        console.log("Error deleting sub");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getSubscriptions: function () {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "ReportSubscription")

                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.apiGetSubscriptions(result.body);

                    } else {
                        console.log("Error fetching subs");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    requestPdfByMail: function (params) {
        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "MonthlyReportMail")
                .query(params)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (!result.ok) {
                        console.log("Error requesting pdf");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchPopDefineStuff: function (popID) {
        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationId: popID

            };

            request
                .get(VConst.APIURL + "TrendGraphSegments")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        //console.log(result.body);
                        Actions.apiNewPopDefineStuff(result.body);
                    } else {
                        console.log("Error retrieving trend graph data6");
                        console.log(error);
                        Actions.apiErrorPopDefine(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    putPopSegment: function (pop) {
        if (SessionStore.isLoggedIn()) {
            request
                .put(VConst.APIURL + "SetTrendGraphSegment")
                .send(pop)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        
                        console.log(result);

                    } else {
                        console.log("Error putting pop:" + error);
                    }
                });
        }else
            Actions.redirectToLogin();

    },

    postPopSegment: function (pop) {
         if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "SetTrendGraphSegment")
                .send(pop)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
          
                        console.log(result);

                    } else {
                        console.log("Error posting pop");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    deletePopSegments: function (pop) {
        if (SessionStore.isLoggedIn()) {
            request
                .del(VConst.APIURL + "SetTrendGraphSegment")
                .send(sub)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
   
                        console.log(result);
                    } else {
                        console.log("Error deleting pop");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    postVirtualPopulation: function (pg, successCallback, errorCallback) {

 
        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "VirtualPopulation")
                .send(pg)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        if (successCallback) {
                            successCallback();
                        }else {
                            alert("Group Save Successful")
                        }
                        //Actions.apiPostSubscription(result.text);

                    } else {
                        console.log("Error posting group");
                        if (errorCallback)
                            errorCallback(error)

                    }
                });
        }else
            Actions.redirectToLogin();
    },


    putVirtualPopulation: function (pg) {


        if (SessionStore.isLoggedIn()) {
            request
                .put(VConst.APIURL + "VirtualPopulation")
                .send(pg)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        alert("Group Save Successful")
                        //Actions.apiPostSubscription(result.text);
                    } else {
                        console.log("Error updating group");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    deleteVirtualPopulation: function (pg, callback) {

        if (SessionStore.isLoggedIn()) {
            request
                .delete(VConst.APIURL + "VirtualPopulation")
                .send(pg)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                       
                    
                        if (callback)
                            callback();

                        //Actions.apiPostSubscription(result.text);
                    } else {
                        console.log("Error posting group");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getVirtualPopulation: function () {
        if (SessionStore.isLoggedIn()) {
            //FarmId: 187
            var queryParameters = {};

            request
                .get(VConst.APIURL + "VirtualPopulation")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
//console.log (JSON.stringify(result.body))
                        // console.log(result.body);
                        Actions.pcPopGroupsReceived(result.body);
                    } else {
                        console.log("Error retrieving trend graph data 7");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getFarmAuxData: function (farmID, startDate, endDate) {

        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                FarmID: farmID,
                StartDate: startDate,
                EndDate: endDate
            };

            request
                .get(VConst.APIURL + "FarmAuxilaryData")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        Actions.fcrAuxDataLoaded(result.body);
                    } else {
                        console.log("Error in Aux Farm Data received");
                    }
                });
        }else
            Actions.redirectToLogin();


    },

    getCountryList: function () {
        if (SessionStore.isLoggedIn()) {

            request
                .get(VConst.APIURL + "CountryDimensions")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        Actions.apiCountriesLoaded(result.body);
                    } else {
                        console.log("Error in countries received");
                    }
                });
        }else
            Actions.redirectToLogin();

    },


    getMyCountries: function () {
        if (SessionStore.isLoggedIn()) {

            request
                .get(VConst.APIURL + "UserCountryFilter")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        Actions.apiMyCountriesLoaded(result.body);
                    } else {
                        console.log("Error in countries received");
                    }
                });
        }else
            Actions.redirectToLogin();

    },

    postMyCountries: function (myCountries) {
        if (SessionStore.isLoggedIn()) {

            request
                .post(VConst.APIURL + "UserCountryFilter")
                .send(myCountries)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        console.log("My countrieslist posted");
                        Actions.reloadUserData(true); //Should only be posted when some changes made
                        VakiAPI.getDashboardList();

                    } else {
                        console.log("Error in my countries posted");
                    }
                });
        }else
            Actions.redirectToLogin();
    },

    getPricelist: function () {
        if (SessionStore.isLoggedIn()) {

            request
                .get(VConst.APIURL + "GetPriceInfo")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {

                    if (result.ok) {

                        Actions.apiPricelistReceived(result.body);
                    } else {
                        console.log("Error in countries received");
                    }
                });
        }else
            Actions.redirectToLogin();

    },

    fetchMeasurements: function (populationID, startDate, endDate) {


        if (SessionStore.isLoggedIn()) {
            var queryParameters = {
                PopulationId: populationID,
                StartDate: startDate,
                EndDate: endDate

            };

            request
                .get(VConst.APIURL + "FishByFarmDatePopulation")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {



                    if (result.ok) {

                        Actions.apiNewMeasurementsData(result.body);


                    } else {
                        console.log("Error retrieving measurements data");
                        console.log(error);
                        Actions.apiError(error);
                    }
                });
        }else
            Actions.redirectToLogin();
    },




    fetchValueEstimate: function (queryParameters, funcOnSuccess) {
        if (SessionStore.isLoggedIn()) {
            // API expects gutting factor to be 0.0 - 1.0. Divide by 100
            // because the UI uses whole percent numbers. 12% will then be 0.12
            queryParameters.guttingFactor = queryParameters.guttingFactor / 100;
            queryParameters.starvedWeightFactor = queryParameters.starvedWeightFactor / 100;

            request
                .get(VConst.APIURL + "ValueEstimate")
                .query(queryParameters)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {


                    if (result.ok) {

                        if (funcOnSuccess)
                            funcOnSuccess(result.body);
                        else
                            Actions.apiNewValueEstimateData(result.body);

                    } else {
                        //console.log("Error retrieving trend graph data");

                        console.log(error);
                        Actions.apiError(error);
                    }


                });
        }else
            Actions.redirectToLogin();
    },

    fetchExchangeRates: function () {

        if (SessionStore.isLoggedIn()) {
            // API expects gutting factor to be 0.0 - 1.0. Divide by 100
            // because the UI uses whole percent numbers. 12% will then be 0.12

            request
                .get("https://openexchangerates.org/api/latest.json?app_id=c81c178a257d4592852f1925a7db3091")

                .accept("application/json")
                .end(function (error, result) {

                    if (result.ok) {
                        Actions.apiGotExchangeRates(result.body)
                    } else {
                        //console.log("Error retrieving trend graph data");
                        console.log(error);
                        Actions.apiErrorGettingExchangerates(error);

                    }


                });
        }else
            Actions.redirectToLogin();
    },

    saveVerySimplePriselist: function (list) {
        var param = {
            theList: list
        };

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostVerySimplePricelist")
                .send(param)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        alert ("Pricelist saved")
                    } else {
                        alert("Error saving pricelist!  "+error);
                        console.log("Error posting pricelist");
                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchHarvestReport: function (PopulationID, SegmentID) {
        if (SessionStore.isLoggedIn()) {

            request
                .get(VConst.APIURL + "GetHarvestReportHeader")
                .query({PopulationID:PopulationID, SegmentID:SegmentID })
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {


                    if (result.ok) {
                        Actions.apiGotHarvestReportHeader(result.body);
                    } else {
                        //console.log("Error retrieving trend graph data");
                        console.log(error);
                        Actions.apiError(error);
                    }


                });
        }else
            Actions.redirectToLogin();
    },

    saveHarvestReport: function (params) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostHarvestReportHeader")
                .send(params)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        var res = result.body.substring (0, 7).toUpperCase();
                        if (res == "SUCCESS") {
                            alert("Report saved.\n" + result.body);
                            Actions.apiReportHeaderSaved(result.body);
                        }else
                            alert ("Error saving report!: "+ result.body);
                    } else {
                        alert("Error saving report!\n  "+error );

                    }
                });
        }else
            Actions.redirectToLogin();
    },



    saveHarvestReportComment: function (params) {



        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostHarvestReportComment")
                .send(params)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {

                        var res = result.body.substring (0, 7).toUpperCase();
                        if (res == "SUCCESS") {


                            alert("Comment saved.")
                            Actions.apiReportCommentSaved();

                        }else
                            alert ("Error saving Comment!: "+ result.body);
                    } else {
                        alert("Error saving comment!\n  "+error );

                    }
                });
        }else
            Actions.redirectToLogin();
    },


    fetchWeihgtFilterSuggestion: function (params) {
    if (SessionStore.isLoggedIn()) {

        request
            .get(VConst.APIURL + "SuggestedFilters")
            .query(params)
            .set("Authorization", "Bearer " + SessionStore.getToken())
            .accept("application/json")
            .end(function (error, result) {


                if (result.ok) {
                    Actions.apiFilterSuggestion(result.body);
                } else {
                    //console.log("Error retrieving trend graph data");
                    console.log(error);
                    Actions.apiError(error);
                }


            });
    }else
        Actions.redirectToLogin();

},



    fetchRandomHistoricalSegment: function (farm) {
  //if farm is 0  or less, a random pop from random farm will be fetched
        
        if (SessionStore.isLoggedIn()) {
           
            request
                .get(VConst.APIURL + "GetUnverifiedSegment")
                .query({ FarmID: farm})
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {


                    if (result.ok) {
                       
                        Actions.apiUnverifiedSegmentReceived(result.body);

                    } else {
                       
                        console.error("fetchRandomHistoricalSegment: "+ error);
                        
                    }


                });
        } else
            Actions.redirectToLogin();
            
    },


    postSaveHistoricalPop: function (hispopdef, onSuccessFunc, onFailureFunc) {
        
    if (SessionStore.isLoggedIn()) {
        request
            .post(VConst.APIURL + "SaveHistoricalPop")
            .send(hispopdef)
            .set("Authorization", "Bearer " + SessionStore.getToken())
            .accept("application/json")
            .end(function (error, result) {

                var allFine = (result.body.substring(0, 5).toUpperCase() != "ERROR") && result.ok;

                
                if (allFine) {
  
                    console.log(result);
                    if (onSuccessFunc)
                        onSuccessFunc(result.body);
                    
                } else {
                    console.log("Error during SaveHistoricalPop");
                    console.log(error + "   " + result);
                    var str = error;
                    if (result.ok)
                        str = result.body;


                    if (onFailureFunc)
                        onFailureFunc(str);
                }
            });
    }else
        Actions.redirectToLogin();
},



        GetFarmsWithHisPops: function () {
  
        
            if (SessionStore.isLoggedIn()) {
           
                request
                    .get(VConst.APIURL + "GetFarmsWithHisPops")
             
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                       
                            Actions.apiFarmsWithHisPops(result.body);

                        } else {
                       
                            console.error("GetFarmsWithHisPops: "+ error);
                        
                        }


                    });
            } else
                Actions.redirectToLogin();
            
        },

    
   
        GetHisPopsForLocation: function (params) {
  
      
                if (SessionStore.isLoggedIn()) {
           
                    request
                        .get(VConst.APIURL + "GetHisPopsForLocation")
                        .query (params)
                        .set("Authorization", "Bearer " + SessionStore.getToken())
                        .accept("application/json")
                        .end(function (error, result) {


                            if (result.ok) {
                       
                                Actions.apiHisPopsForLocation(result.body);

                            } else {
                       
                                console.error("GetHisPopsForLocation: " + error);
                        
                            }


                        });
                } else
                    Actions.redirectToLogin();
            
        },


        GetUptimeReport: function (params) {

  
            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "UptimeAndFishCountSummary")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {

                            Actions.apiUptimeRecived(result.body);

                        } else {

                            Actions.apiUptimeRecived(null, error);
                            console.error(" GetUptimeReport: " + error);

                        }


                    });
            } else
                Actions.redirectToLogin();

        },

 




        
        GetFarmUtilizations: function (params) {


            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetFarmUtilizations")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                                    Actions.apiGetFarmUtilizations(result.body);

                        } else {

                            //Actions.apiUptimeRecived(null, error);
                            console.error(" GetFarmUtilizations: " + error);

                        }


                    });
            } else
                Actions.redirectToLogin();

        },




        PostStarvingDate: function(sd) {
            if (SessionStore.isLoggedIn()) {
                request
                    .post(VConst.APIURL + "PostStarvingDate")
                    .send(sd)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {
                        if (result.ok) {
                            Actions.apiPostStarvingDateResults(result.text);
                        } else {

                            Actions.apiPostStarvingDateResults("Error: "+result.text);
                            console.log("Error posting StarvingDate");
                        }
                    });
            } else
                Actions.redirectToLogin();

        },


        
        PostPageMount: function (pm) {
            if (SessionStore.isLoggedIn()) {
                request
                    .post(VConst.APIURL + "PostPageMount")
                    .send(pm)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {
                        if (!result.ok) {
                            console.log("Error posting PageMount: "+result);
                        }
                    });
            } else
                Actions.redirectToLogin();

        },




        PostManual: function (manual) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "SaveManualInfo")
                .send(manual)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.manPostResults(result.body)
                    } else {
                        console.log("Error posting organisation");
                    }
                });
        } else
            Actions.redirectToLogin();
    },






    GetManuals: function (func) {

        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "GetManuals")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")

                .end(function (error, result) {

                    if (result.ok) {
                        // console.log(result.req.url);
                        func(result.body);
                    } else {
                        console.log("Error retrieving the Manuals list");
                        console.log(error);
                        Actions.apiError(error);
                    }
                })

                ;

        } else
            Actions.redirectToLogin();
    },


    DeleteManual: function(man) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "DeleteManual")
                .send(man)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")

                .end(function (error, result) {

                    if (result.ok) {
                        // console.log(result.req.url);
                        Actions.manDelete(result.body);
                    } else {
                        console.log("Error Deleting manual");
                        console.log(error);
                        Actions.apiError(error);
                    }
                })

                ;

        } else
            Actions.redirectToLogin();


    },



    GetSpecies: function (func) { //Only Names and IDs


        if (SessionStore.isLoggedIn()) {
            request
                .get(VConst.APIURL + "GetSpecies")
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        // console.log(result.req.url);
                        func(null, result.body);
                    } else {
                        console.log("Error retrieving the species list");
                        console.log(error);
                        func("Error", error);
                    }
                })

                ;

        } else
            Actions.redirectToLogin();
    },

   PostPopulation: function (pop, func) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostPopulation")
                .send(pop)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.camItemPosted();
                        func(result.body)
                    } else {
                        console.log("Error posting population: "+JSON.stringify(result, null, 1));
                        func ("Error posting Population: "+result.statusCode+":  "+result.statusText)
                    }
                });
        } else
            Actions.redirectToLogin();
    },

   PostCage: function (cage, func) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostCage")
                .send(cage)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                        Actions.camItemPosted();
                        func(result.body)
                     
                    } else {
                        console.log("Error posting cage: "+JSON.stringify(result, null, 1));
                        func ("Error posting cage: "+result.statusCode+":  "+result.statusText)
                    }
                });
        } else
            Actions.redirectToLogin();
    },


   PostDevice: function (dev, func) {

        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "PostDevice")
                .send(dev)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                       
                        func(result.text)
                     
                    } else {
                        console.log("Error posting device: "+JSON.stringify(result, null, 1));
                        func ("Error posting device: "+result.statusCode+":  "+result.statusText)
                    }
                });
        } else
            Actions.redirectToLogin();
    },







      GetFarmCameraList: function (params, func) {


            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetFarmCameraList")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                                    func(result.body);

                        } else {
                            func("Error: "+error)
                            //Actions.apiUptimeRecived(null, error);
                            console.error("GetFarmCameraList: " + error);
                             console.error("GetFarmCameraList2: " + JSON.stringify(result, null, 1));

                        }




                    });
            } else
                Actions.redirectToLogin();

        },


GetPopulationAndCageLists: function (params, func) {


            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetPopulationAndCageLists")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                        
                                    func(result.body);

                        } else {
                            func("Error: "+error)
                            //Actions.apiUptimeRecived(null, error);
                            console.error("GetPopulationAndCageLists: " + error);
                             

                        }




                    });
            } else
                Actions.redirectToLogin();

        },

GetAllFarmsForOrg: function (Org_ID, func) {


            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetAllFarmsForOrg")
                    .query({Org_ID: Org_ID})
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                        
                                    func(result.body);

                        } else {
                            func("Error: "+error)
                            //Actions.apiUptimeRecived(null, error);
                            console.error("GetAllFarmsForOrg: " + error);
                             

                        }




                    });
            } else
                Actions.redirectToLogin();

        },


GetAllOrgs: function (func) {


            if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetAllOrgs")
                   
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                        
                            func(result.body);

                        } else {
                           
                            //Actions.apiUptimeRecived(null, error);
                            console.error("GetAllOrgs: " + error);
                             

                        }




                    });
            } else
                Actions.redirectToLogin();

        },

   UpdateCameraConnection: function (data, func) {


        if (SessionStore.isLoggedIn()) {
            request
                .post(VConst.APIURL + "UpdateCameraConnection")
                .send(data)
                .set("Authorization", "Bearer " + SessionStore.getToken())
                .accept("application/json")
                .end(function (error, result) {
                    if (result.ok) {
                       
                        func(result.body)
                     
                    } else {
                        console.log("Error UpdateCameraConnection: "+JSON.stringify(result, null, 1));
                        func ("Error: "+result.statusCode+":  "+result.statusText)
                    }
                });
        } else
            Actions.redirectToLogin();
    },



    GetCameraSetupLogOnDevice( params, func) {
   if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetCameraSetupLogOnDevice")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                   
                                    func(result.body);

                        } else {
                         
                            func("Error: "+error)
                            //Actions.apiUptimeRecived(null, error);
                            console.error("GetPopulationAndCageLists: " + error);
                             

                        }




                    });
            } else
                Actions.redirectToLogin();

    },
			
  GetHourDistribution( params) {


   if (SessionStore.isLoggedIn()) {

                request
                    .get(VConst.APIURL + "GetHourDistribution")
                    .query(params)
                    .set("Authorization", "Bearer " + SessionStore.getToken())
                    .accept("application/json")
                    .end(function (error, result) {


                        if (result.ok) {
                   
                                    Actions.hdOnNewData(result.body);

                        } else {
                         
                            Actions.hdOnNewData("Error: "+error)
                            //Actions.apiUptimeRecived(null, error);
                            console.error(" GetHourDistribution( params, func): " + error);
                             

                        }

                    });
            } else
                Actions.redirectToLogin();

    },		
	

    GetAllCamHistory(func) {


        if (SessionStore.isLoggedIn()) {
     
                     request
                         .get(VConst.APIURL + "GetAllCamHistory")
               
                         .set("Authorization", "Bearer " + SessionStore.getToken())
                         .accept("application/json")
                         .end(function (error, result) {
     
     
                             if (result.ok) {
                        
                                         func(result.body, null);
     
                             } else {
                              
                                 func (null, "Error: "+error)
                                 //Actions.apiUptimeRecived(null, error);
                                 console.error("GetAllCamHistory " + error);
                                  
     
                             }
     
                         });
                 } else
                     Actions.redirectToLogin();
     
         },
         
         
         GetPhotos( params, func) {
            if (SessionStore.isLoggedIn()) {
         
                         request
                             .get(VConst.APIURL + "GetPhotos")
                             .query(params)
                             .set("Authorization", "Bearer " + SessionStore.getToken())
                             .accept("application/json")
                             .end(function (error, result) {
         
         
                                 if (result.ok) {
                                    func(result.body);
                                 } else {
                                     func("Error: "+error)
                                     //Actions.apiUptimeRecived(null, error);
                                     console.error("GetPhotos: " + error);
                                 }
         
         
         
         
                             });
                     } else
                         Actions.redirectToLogin();
         
             } ,        

             GetMoveMeasData( params, func) {



                if (SessionStore.isLoggedIn()) {
             
                             request
                                 .get(VConst.APIURL + "GetMoveMeasData")
                                 .query(params)
                                 .set("Authorization", "Bearer " + SessionStore.getToken())
                                 .accept("application/json")
                                 .end(function (error, result) {
             
             
                                     if (result.ok) {
                                        func(result.body);
                                     } else {
                                         func("Error: "+error)
                         
                                     }
             
             
             
             
                                 });
                         } else
                             Actions.redirectToLogin();
             
                 }, 
                 
                 

                 PutMoveMeas: function (params, func) {
                    if (SessionStore.isLoggedIn()) {
                        request
                            .put(VConst.APIURL + "PutMoveMeas")
                            .send(params)
                            .set("Authorization", "Bearer " + SessionStore.getToken())
                            .accept("application/json")
                            .end(function (error, result) {
                                if (!result.ok) {
                                    func("Error during move measurements: "+error);
                                    
                                }else{
                                    func("Success");
                                }
                            });
                    }else
                        Actions.redirectToLogin();
                },


};

module.exports = VakiAPI;