/**
 * Vaki Aquaculture Systems Ltd. Copyright 2018, all rights reserved.
 *
 * Language: JavaScript, ES6

 */
"use strict";

var React = require("react");



require("./MyPanel.css")
import PropTypes from "prop-types";

export default class MyPanel extends React.Component{


    static propTypes = {
        ID: PropTypes.string,
        User: PropTypes.string,
        Text: PropTypes.string

    };

    constructor(props) {
     super(props);
     

    };



    render  () {



        return (
            <div  className="MyPanelClass" >


                {this.props.children}
  
	    	</div>
        )
    }
};




