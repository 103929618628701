'use strict';

import React, {useState, useEffect, useRef} from 'react';

import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel,
  Card,
  Collapse

} from 'react-bootstrap';

import { ArrowsAngleExpand, ArrowsAngleContract} from 'react-bootstrap-icons';

import {QuestionCircle} from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./Dashboard.css');

export default function OnePopPhotos (props) {

    const [loading, setLoading] = useState (false);
    const [selectedGuid, setSelectedGuid] = useState(props.Photos[0].guid)
    const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});
    const [PhotoOptions, setPhotoOptions] = useState([]);

    const [CurrentViewSmall, setCurrentViewSmall] = useState(true);


 









  useEffect(() =>{


 
        let Options = []


if (props.Dummy){


    Options.push(
        <option key={0} value={"Default.jpg"}>
            {"Dummy"}
        </option>);

	setPhotoOptions(Options);


    setSelectedGuid ("Default.jpg");
    return;

}

let len = props.Photos.length;
 
     for (let i=0; i< len; i++ ){


        Options.push(
            <option key={i} value={props.Photos[i].guid}>
                {props.Photos[i].time+" LT"}
            </option>);
     }

   
	setPhotoOptions(Options);


     setSelectedGuid (props.Photos[0].guid);

  }, [props.PopName, props.Photos ]);



  const onPhotoSelect = (e, r) => {
   
    setSelectedGuid(e.target.value);
};

const onResizeClick = () => {let bb = CurrentViewSmall; setCurrentViewSmall(!bb);}



  
let path = "https://biomassdaily-photos.s3.eu-west-1.amazonaws.com/"+selectedGuid ;









let CardClass = "ImgCardSmall";

let ResizeIcon = <ArrowsAngleExpand/>;


if (!CurrentViewSmall){
    CardClass = "ImgCardLarge"
    ResizeIcon = <ArrowsAngleContract/>;
}


  //let TheStuff = <img src={path}  height="300"/>;

  let TheStuff =  
    <Card   className = {CardClass}>
        <Card.Header  >
        <div className = "OnePopNameDiv">    
        {props.PopName}
        </div>
      <div className = "OnePopResizeDiv" onClick = {onResizeClick}> {ResizeIcon} </div>      

  
        </Card.Header> 

        <Card.Body style={{ padding:'0px'  }}>
    <img src={path}  width="100%"  />
    </Card.Body>
    <Card.Footer >
    <Form.Select
						aria-label="select photo"
						className="mb-0"
						onChange={onPhotoSelect}
						className="width100"
						value={selectedGuid }
				
					>
						{PhotoOptions}
					</Form.Select>
        </Card.Footer>

</Card>
 ;









    
    //<div className = "center-width100"> <Spinner/>   </div>











    return (
    
    <div className="cs-devlog"> 

{TheStuff}

    <Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>

    </div>);






}

