'use strict';

import React, {useState, useEffect, useRef} from 'react';

import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel,
  Card,
  Collapse

} from 'react-bootstrap';

import {QuestionCircle} from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
import OnePopPhotos from './OnePopPhotos.jsx';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./Dashboard.css');

export default function PhotoView (props) {

    const [loading, setLoading] = useState (true);
    const [popList, setPopList] = useState([]);

   
   const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});
 




/*
const GetCameraSetupLogOnDeviceCallback = (result) => {



       if ( result.msg!= '' && result.msg.length > 5 && result.substring(0, 5) == 'Error') {
             setAlertMsg({msg: "Error fetching log: "+result,
                        kind:"danger"   }) ;
            
       
         
        } else {

            setLogData (result.logs)
     
 
        }
         
         setLoading(false);

} 
*/

const GetPhotosCallback = (result) => {

 

    if ( (typeof result.msg != "string") || ( result.msg!= '' && result.msg.length > 5 && result.substring(0, 5) == 'Error')) {
          setAlertMsg({msg: "Error fetching PHOTOS: "+ JSON.stringify(result),
                     kind:"danger"   }) ;
      } else if (result.popsPhotos.length == 0){
        setAlertMsg({msg: "No photos found",
        kind:"info"   }) ;

      }else{


  
        let len = result.popsPhotos.length;
        for (let i = 0; i< len; i++){
            let id = result.popsPhotos[i].Population_ID;

            let pp = props.DashData.get("PopulationStatusList").find ((el) => {return el.PopulationID == id});


            if (pp){
              result.popsPhotos[i]["Name"] = pp.PopulationName;
              result.popsPhotos[i]["key"] = i; 
            }




       

        }

        setPopList (result.popsPhotos);



       

        

      }
      setLoading(false);
} 


  useEffect(() =>{



     
  if (!props.DashData )
    return;

   
    setPopList ([]);




    

    let popIDs = "";
    let numPops = props.DashData.get("PopulationStatusList").length;

    
let dbg = 0;

    for (let i = 0; i < numPops; i++){
        let frNum = props.DashData.get("PopulationStatusList")[i].FrameSerialNumber;
        let lastDigit = frNum % 10;
        if (lastDigit == 5 || lastDigit == 6){
          //if (dbg==0){
         //   props.DashData.get("PopulationStatusList")[i].PopulationID = 9459;
          //  dbg=1;
          //}

            if (popIDs != "")
                popIDs += ","
            popIDs +=   props.DashData.get("PopulationStatusList")[i].PopulationID ;

        }
    }


    if (popIDs == ""){
      setLoading(false);
      return;
    }

    setLoading(true);
   

    


    VakiAPI.GetPhotos( {pops: popIDs}, GetPhotosCallback);
     

  }, [props.DashData ]);

  

  let TheStuff = "";
 

 if (!props.DashData || popList.length == 0)
        return <div/> 


     if (loading){
            TheStuff = <Spinner/>
     }else{

        let len = popList.length;
        TheStuff = [];
        for (let i = 0; i<len; i++){
        
          // Dummy={i>0}
           TheStuff.push (<div className = "OnePopWrap"  key={i}> <OnePopPhotos  PopName = {popList[i].Name}   Photos = {popList[i].photos }   />  </div>);
        }


     }








    
    //<div className = "center-width100"> <Spinner/>   </div>










    return (
    
    <div className="cs-devlog"> 
<div className = "PhotosWrap">
{TheStuff}
</div>
<br/>
    <Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>

    </div>);






}

