/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson
 *
 *
 */

"use strict";

var React = require("react");

import  {Dropdown} from "react-bootstrap";

var Actions = require("./../../actions/Actions.js");

/**
 * Fairly simple component, takes care of the language dropdown in the
 * navigation bar. If a new language is clicked the whole site will be
 * re rendered in the new language.
 */
export default class LanguageSelector extends React.Component{
//var LanguageSelector = CreateReactClass ()({
//var LanguageSelector = export default class   extends React.Component{
  constructor(props) {
     super(props);
    alert ("LangSelect")
	this.setEnglish = () => {
		Actions.setEnglishTranslation();
	}

	this.setGerman =()=> {
		Actions.setGermanTranslation();
	 }

    this.setNorwegian =()=> {
    
        Actions.setNorwegianTranslation();
     }

    this.setSpanish =()=> {
        Actions.setSpanishTranslation();
     }
  };
    render () {

        
        //var label = "Language"; // If we want to revert back to text label
        // Use a flag, see "glyphicon-flag" at http://getbootstrap.com/components/#glyphicons
        // I (jónsi) switched from "English" to a flag icon to reduce space in
        // the header navigation. We had issues with things overlapping too
        // quickly if the translation was Spanish and the email was long
       // var label = <Glyphicon glyph="flag" />;
        var label = <div class="testColor"> <p>Envelope icon: <span class="glyphicon glyphicon-envelope"></span></p></div>




        return (
            <Dropdown.Menu title={label} id="navbar-dropdown">
                <Dropdown.Item onSelect={this.setEnglish} > English </Dropdown.Item>
                <Dropdown.Item onSelect={this.setNorwegian} disabled> Norwegian </Dropdown.Item>
                <Dropdown.Item onSelect={this.setSpanish} disabled> Spanish </Dropdown.Item>
            </Dropdown.Menu>
        )
        
    }
}

