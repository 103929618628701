
"use strict";




import ReactDOM from "react-dom";
import React from "react";
import {Alert, Button} from "react-bootstrap";
import LoadingIcon from "react-loader";
import moment from "moment";
import Actions from "./../../actions/Actions.js";
import Vutils from "./../../utils/Vutil.js";
import OrgFarmPopPicker from "./../Filters/OrgFarmPopPicker.jsx";
import VakiAPI from "./../../webservice/VakiAPI.js";
import TrendgraphArea from "./../Trendgraph/TrendgraphArea.jsx";
import LiveWeightDistribution from "./../Distribution/LiveWeightDistribution.jsx";
import Reflux from "reflux";
import FilterStore from "../../stores/FilterStore";
import SessionStore from "./../../stores/SessionStore.js";
import DashboardStore from "./../../stores/DashboardStore.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import ParMan from "./../../stores/ParMan.js";

import Spinner from "./../../utils/Spinner.jsx";
import VConst from "./../../utils/VConst.js";
import UserDataStore from "./../../stores/UserDataStore.js";

//import Router from "react-router";


var BT = require("react-bootstrap-table");
var t = TranslateStore.getTranslate();



//require("./PopDetailedStatus.css");

var nextDistrInstanceID = 1; 


var TheStore = Reflux.createStore({
    init () {
        this.listenTo(Actions.dashPopStarvDate, this.onDashPopStarvDate);
        this.listenTo(Actions.dashPopStarvDateUpdate, this.onSetDate);
        this.listenTo(Actions.apiPostStarvingDateResults, this.onPosted);

        this.listenTo(Actions.popDetailsColClick, this.onColClick);
        this.listenTo(Actions.popDetailsDelDistChart, this.onDelDistChart);

        this.listenTo(Actions.popDetailsClickGetDistr, this.onClickGetDistr);


        this.data = {
            popName: "",
            loading: false,
            tgData: null,
            distData: [],
            popID: -1,
            starvDate: "",
            pickDate: false,
            usedMinWgt: -1,
            usedMaxWgt: -1 

        };

        this.datepend = null;

    },



    onClickGetDistr () {
        this.data.pickDate = !this.data.pickDate;
        this.trigger(this.data);
    },

    onDelDistChart (idx)
    {


        for (var i = 0; i < this.data.distData.length; i++) {

            if (this.data.distData[i].ID === idx) {
                this.data.distData.splice(i, 1);
                break;
            }
        }

        this.trigger(this.data);

    },




    onColClick (p, dt) {


        if (!this.data.pickDate) {
            var eb = document.getElementById("divDistrButton");
            if (eb && getComputedStyle(eb).display !== 'none')
                return;
        }

        this.data.pickDate = false;

        var dtDist = moment(dt);
        var dtFrom = moment(dtDist).subtract(2, "days");
        var dtTo = moment(dtDist).add(3, "days");


        this.datepend = moment(dtDist);
        var usedMin = -1;
        var usedMax = -1;

        if (this.data.tgData){
            var dtTgEnd = moment(moment(this.data.tgData.EndDate).format ("YYYY-MM-DD")).add (1, "days");
            usedMin = this.data.tgData.UsedMinWeightFilter;
            usedMax = this.data.tgData.UsedMaxWeightFilter;

            if (dtTo > dtTgEnd)
                dtTo = dtTgEnd;
        }



        VakiAPI.fetchDistributions({
            populationID: p.PopulationID,
            startDate: dtFrom.format("YYYY-MM-DD"),
            endDate: dtTo.format("YYYY-MM-DD"),
            minWeight: usedMin,
            maxWeight: usedMax,
            minCF: 0.9,
            maxCF: 2,
            starvedWeightFactor: 0, 
            guttingFactor: 0,
            CFBinSize: VConst.DEFAULT_CF_BIN_SIZE,
            weightBinSize: -1,
            PopulationSize: 0
        }, this.onDistr);






    },

    onDistr (data) {
        data["TheDate"] = this.datepend;

        data["ID"] = nextDistrInstanceID++;

        this.datepend = null;
   
        this.data.distData.push(data);

        this.data.loading = false;

        this.trigger(this.data);
    },

    onGraph (data) {
   

        var popList = data.PopulationTrendDataList;




        var dtDist = null;
        if (popList && popList.length > 3) {
            dtDist = moment(popList[popList.length - 1].Date);



        }

        if (!dtDist)
            this.data.loading = false;
        this.data.tgData = data;


        this.data.distData = [];




        this.trigger(this.data);

        if (dtDist) {
            //fetch distribution
            var dtFrom = moment(dtDist).subtract(3, "days");
            this.datepend = moment(dtDist);
            var usedMin = -1;
            var usedMax = -1;
            
       
            if (this.data.tgData) {
                usedMin = this.data.tgData.UsedMinWeightFilter;
                usedMax = this.data.tgData.UsedMaxWeightFilter;

            }

       
            
            VakiAPI.fetchDistributions({
                populationID: data.PopulationID,
                startDate: dtFrom.format("YYYY-MM-DD"),
                endDate: dtDist.format("YYYY-MM-DD"),
                minWeight: usedMin,
                maxWeight: usedMax,
                minCF:0.9,
                maxCF: 2,
                starvedWeightFactor: 0, // note entirely sure what this does
                guttingFactor: 0,
                CFBinSize: VConst.DEFAULT_CF_BIN_SIZE,
                weightBinSize:  -1,
                PopulationSize: 0
            }, this.onDistr);



        }
    }, 

    notifyMounting (popID) {
        this.data.popID = popID;

        var thePop = FilterStore.getPopulationByID(popID);

        if (!thePop) {
            console.log("PopDetailesStatus: Population definition not found for ID: " + popID+ "  redirected to Dashboard");
            Actions.redirectToApp();
            
            return;
        }

 

        var strStarv = ParMan.getItem("PopDetailsSD" + popID);
        var dtEnd = null;
        if (!strStarv || strStarv == "") {
            dtEnd = moment.utc(thePop.EndDate);
            strStarv = "";
        } else {
            dtEnd = moment.utc(strStarv).subtract(1, "days");
        }

        var dtStart = moment(dtEnd).subtract(30, "days");
      
    
        this.data.popName = thePop.Name;
        this.data.loading = true;
        this.data.tgData = null;
        this.data.distData = [];
        this.data.starvDate = strStarv;
        this.trigger(this.data);
        this.datepend = null;

        VakiAPI.fetchTrendGraphForPopulation(popID, dtStart.format(VConst.DATE_FORMAT), dtEnd.format(VConst.DATE_FORMAT), -1, -1, thePop.SegmentID, this.onGraph);
       
        

    }





});



function createDelButton(idx) {
   
    return (<Button size="xs" onClick={function () { Actions.popDetailsDelDistChart(idx) }} variant="secondary" > {"Remove" } </Button>);

}

function createDists(distData) {

    var theDist = [];
    if (distData &&distData.length > 0) {
        var theclassname = "clsDistrWrap";  //"floatLeft"; //"clsDistrChart"
        var len = distData.length;
        for(var j = 0; j<len; j++){
  
            

            theDist.push (
                <div id={"Distr_" + distData[j].ID} className= {theclassname} key={j}>
                    <LiveWeightDistribution weightHistogramList={distData[j].WeightHistogramList}
                        Day={moment(distData[j].TheDate).format("D.MMM") + ", CV=" + distData[j].CV }
                        t={t}
                        HideLegend={true}
                        HideExport={true}
                        ChartWidth={"100%"}
                        ChartHeight={"100%"}

        />
        <div className="text-right">
                        {createDelButton(distData[j].ID)}
        </div>

        </div>
                );
        }
    }
    return theDist;
}




export default class PopDetailedStatus  extends React.Component{
    constructor(props) {
     super(props);
        

          
        this.state = {
            popName: "",
            loading: true,
            tgData: null,
            distData: [],
            starvDate: ""
     

        };



    /***************componentWillMount ( ) {*************/
       
        try{
            if (SessionStore.isLoggedOut()) {
                Actions.redirectToLogin();
            }
           
          
      
        }catch(er){
            alert("PopDetailedStatus constructing Exc:" + er);
        }
    /************************************************************* */

    
    this.onTheStoreChange = (data) => {
  
        this.setState({
            popName: data.popName,
            loading: data.loading,
            tgData: data.tgData,
            starvDate: data.starvDate,
            distData: data.distData,
            pickDate: data.pickDate

        });


    

    }; 


};






    
    componentDidMount ( ) {
        try {

            let popID = parseInt(this.props.match.params.popid);
            TheStore.notifyMounting(popID);

            UserDataStore.postPageHit(VConst.PAGEID.POPULATIONSTAT);

            this.unsubscribeTheStore = TheStore.listen(this.onTheStoreChange);





    }catch(er){
            alert("PopDetailedStatus compDidUnmount Exc:" + er);
}
    };
   
    componentWillUnmount ( ) {

        try {
            this.unsubscribeTheStore();
        }catch(er){
            alert("PopDetailedStatus compWillUnmount Exc:  "+ er);
        }
    };



    shouldComponentUpdate ( nextProps, nextState) {


        return true;
    };






render () {
  
    var theSpinner = "";
    var theTrendGraph = "";
    var theDist = [];
    var theDistrButton = "";
    var thePopName = <h1 className="clsPopNameTG"> {this.state.popName.trim()} </h1>
    //var thePopName = <div className="clsPopNameTG"  >{this.state.popName} </div>


   
   






    if (this.state.tgData) {
        
        if (!this.state.tgData.PopulationTrendDataList || this.state.tgData.PopulationTrendDataList.length == 0) {
            theTrendGraph = (<Alert  variant = "warning" > {t("trendgraph.popHasNo")} < / Alert >);
        } else {
            var strTitle = "Growth the last 30 days of measurements"
            if (this.state.starvDate != "") {
                strTitle = "Growth 30 days prior to starving day"
            }
            theTrendGraph =
                (<TrendgraphArea title={strTitle} height="550px" trendGraphData={this.state.tgData}
                    ColumnClickFunc={Actions.popDetailsColClick}
                      t={t} />);
}
 
theDist = createDists( this.state.distData);
 
var DistrButtonText =  "Click to get Distribution at date" ;

        if (this.state.pickDate){
                DistrButtonText = <div className = "clsPickDateMsg" > Tab a column on the TrendGraph </div>     
        }

                theDistrButton = <Button onClick={Actions.popDetailsClickGetDistr}  > {DistrButtonText} </Button>

    }
    if (this.state.loading)
        theSpinner = <Spinner />;
        
            var theData =  this.state.loading.toString() 
        



        return (
            <div id="theStuff" className="text-center">
                    {thePopName}
                 

                    {theTrendGraph}

                    <div id="divDistrButton" className="clsShowOnMobileOnly text-center" >  {theDistrButton} <br /> <br /> </div>
                
                    <div id="divDist" className="clsDist">
                    {theDist}
                        </div>
                     

                {theSpinner}
            </div>
        )
    }
};


