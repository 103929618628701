'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, FormControl } from 'react-bootstrap';
import Spinner from './../../utils/Spinner.jsx';
import VakiAPI from '../../webservice/VakiAPI.js';
import SearchDropdownCamera from "./SearchDropdownCamera.jsx"
import FilterStore from '../../stores/FilterStore';
import Actions from '../../actions/Actions.js';
var UserDataStore = require('./../../stores/UserDataStore.js');

require('./CameraManagement.css');




export default function OrgSelect(props) {

    const [theList, setTheList] = useState([]);
    const [loading , setLoading] = useState (true);
    const [selectedOrg_ID, setSelectedOrg_ID] = useState (-1);


    const OrgListCallback = (obj) => {

   

        setTheList(obj.lst);

        setLoading (false);



        setSelectedOrg_ID (props.InitialOrg);
        props.OnOrgSelect(props.InitialOrg);



	};


	useEffect(() => {

            setLoading (true);   
            VakiAPI.GetAllOrgs ( OrgListCallback);



          


    }, []);


      let TheStuff = <div className="center-with100"> No Organisations found</div>
        if (loading)
            TheStuff = <Spinner/>


  
    let OrgOptions = [];



    if (!loading && theList.length > 0){



        OrgOptions.push (		<option key={0} value={-1}>
			None selected...
		</option>)

      
        for (var i = 1; i<=theList.length; i++){
        
  			OrgOptions.push(
					<option key={i } value={theList[i-1].Org_ID}>
				
						{theList[i-1].Name }
					</option>
				);





        TheStuff = (	<Form.Group className=" mb-3">
        <div className="farmselect_label">
					<Form.Label className="mb-0"> Select Organisation:  </Form.Label>
                    </div>
                    <div id="farmselect">
					<Form.Select
                        size="lg"
						aria-label="select poplation"
						className="mb-0"
						onChange={ (e,r) => {setSelectedOrg_ID(e.target.value); props.OnOrgSelect (e.target.value)}}
						className="width100"
						value={selectedOrg_ID}
					>
						{OrgOptions}
					</Form.Select>
                    <div className="farmselect_id">
                    {(selectedOrg_ID <= 0?  "" : "(ID: "+selectedOrg_ID+")" )}
                    </div>
                    </div>
				</Form.Group>)
        }




        
        
        }


        


     

    
/*
<SearchDropdown title={this.props.t("adminView.organisations")} options={this.props.currentUser.organisations} 
					view="newUser" type="organisation" translateTrigger={this.props.translateTrigger} />
*/


	return (
		<div>
            {TheStuff}
		</div>
	);
}
