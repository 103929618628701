/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var ReactPropTypes = require("prop-types");



/* Convenience variables to save some typing if we want */
var PropTypes =   ReactPropTypes;
var number =   ReactPropTypes.number;
var string =   ReactPropTypes.string;
var requiredNumber =   ReactPropTypes.number.isRequired;
var requiredString =   ReactPropTypes.string.isRequired;
/*
var TranslateStore = require("./../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
*/
/**
 * Data relating to technical status from a frame in a population, originally
 * intended for the Dashboard report.
 */
var populationStatus =   ReactPropTypes.shape({
    PopulationID:   ReactPropTypes.number.isRequired,
    PopulationName:   ReactPropTypes.string.isRequired,
    NumberOfFish24Hr:   ReactPropTypes.number,
    NumberOfFishToday:   ReactPropTypes.number,
    HoursSinceLastData:   ReactPropTypes.number,
    HoursSinceLastFish:   ReactPropTypes.number,
    LastDataLocalTime:   ReactPropTypes.string,
    LastFishLocalTime:   ReactPropTypes.string,
    FrameSerialNumber:   ReactPropTypes.number,
    FrameType:   ReactPropTypes.oneOf([
        "FR550",
        "FR800",
        "Frame FR550",
        "Frame FR800",   
        "Biomass Camera",
        "Lice Camera",
        "Unknown"
    ]),
    LastConnectionStatus:   ReactPropTypes.oneOf([
        "OK",
        "Check Frame Connection",
        "Check Connection Box",
        "Unknown"
    ]),
    LastFrameVisibility:   ReactPropTypes.oneOf([
        "OK",
        "Alarm"
    ])
});

var defaultPopulationStatus = {
    PopulationID: 0,
    PopulationName: "Population",
    NumberOfFish24Hr: 0,
    NumberOfFishToday: 0,
    HoursSinceLastData: 0,
    HoursSinceLastFish: 0,
    LastDataLocalTime: "",
    LastFishLocalTime: "",
    FrameSerialNumber: 0,
    FrameType: "Unknown",
    LastConnectionStatus: "OK",
    LastFrameVisibility: "OK"
};

var populationStatusList =   ReactPropTypes.arrayOf(populationStatus);

var defaultPopulationStatusList = [defaultPopulationStatus];

var weightHistogramList =   ReactPropTypes.arrayOf(
      ReactPropTypes.shape({
        BinBiomass:   ReactPropTypes.number.isRequired,
        BinBiomassPortion:   ReactPropTypes.number.isRequired,
        BinEndWeight:   ReactPropTypes.number.isRequired,
        BinId:   ReactPropTypes.number.isRequired,
        BinPopulation:   ReactPropTypes.number.isRequired,
        BinPopulationPortion:   ReactPropTypes.number.isRequired,
        BinStartWeight:   ReactPropTypes.number.isRequired,
        BiomassKG:   ReactPropTypes.number
    })
);

/* One day in a Trend Graph for a single population */
var populationTrendData =   ReactPropTypes.shape({
    AverageFrameTemperature:   ReactPropTypes.number.isRequired,
    AverageWeight:   ReactPropTypes.number.isRequired,
    Comment:   ReactPropTypes.string,
    ConditionFactor:   ReactPropTypes.number.isRequired,
    Date:   ReactPropTypes.string.isRequired,
    FrameType:   ReactPropTypes.oneOf([
        "FR550",
        "FR800",
        "Unknown"
    ]),
    NumberOfFish:   ReactPropTypes.number.isRequired,
    SGR:   ReactPropTypes.number.isRequired,
    TrendLine:   ReactPropTypes.number.isRequired
});

var defaultPopulationTrendData = {
    AverageFrameTemperature: 0,
    AverageWeight: 0,
    Comment: "",
    ConditionFactor: 0,
    Date: "", // Maybe it's better to have a valid ASP.Net date here
    FrameType:  "Unknown",
    NumberOfFish: 0,
    SGR: 0,
    TrendLine: 0
};

/* The JSON object we get a reply from calling TrendGraph */
var trendGraphData =   ReactPropTypes.shape({
    PopulationID:   ReactPropTypes.number.isRequired,
    StartDate:   ReactPropTypes.string.isRequired,
    EndDate:   ReactPropTypes.string.isRequired,
    MaxAverageWeight:   ReactPropTypes.number.isRequired,
    MinAverageWeight:   ReactPropTypes.number.isRequired,
    MaxCF:   ReactPropTypes.number.isRequired,
    MinCF:   ReactPropTypes.number.isRequired,
    MaxNumberOfFish:   ReactPropTypes.number.isRequired,
    MinNumberOfFish:   ReactPropTypes.number.isRequired,
    PopulationTrendDataList:   ReactPropTypes.arrayOf(populationTrendData)
});

var defaultTrendGraphData = {
    PopulationID: 0,
    StartDate: "",
    EndDate: "",
    MaxAverageWeight: 0,
    MinAverageWeight: 0,
    MaxCF: 0,
    MinCF: 0,
    MaxNumberOfFish: 0,
    MinNumberOfFish: 0,
    PopulationTrendDataList: [defaultPopulationTrendData],
    SegmentID: null,
    bFCR: null,
    MultiplePopulationID: null

};


var defaultAllCagesPopulationTrendData = {
    FarmID: 0,
    StartDate: "",
    EndDate: "",
    MaxAverageWeight: 0,
    MinAverageWeight: 0,
    MaxCF: 0,
    MinCF: 0,
    MaxNumberOfFish: 0,
    MinNumberOfFish: 0,
    TrendGraphDataList: null
    };

/* One entry in the weight histogram graph in the Distribution report */
var weightHistogram = {
    BinID: requiredNumber,
    BinPopulation: requiredNumber,
    BinPopulationPortion: requiredNumber,
    BinStartWeight: requiredNumber,
    BinEndWeight: requiredNumber,
    BinBiomass: requiredNumber,
    BinBiomassPortion: requiredNumber, // percentage number, 12 for 12% for example
    BiomassKG: requiredNumber // set if population size is specified in query
};

var defaultWeightHistogram = {
    BinID: 0,
    BinPopulation: 0,
    BinPopulationPortion: 0,
    BinStartWeight: 0,
    BinEndWeight: 0,
    BinBiomass: 0,
    BinBiomassPortion: 0,
    BiomassKG: 0
};

/* One entry in the condition factor histogram graph in the Distribution report */
var cfHistogram = {
    BinID: requiredNumber,
    BinPopulationPortion: requiredNumber,
    BinStartCF: requiredNumber,
    BinEndCF: requiredNumber,
    BinWeightPortion: requiredNumber
};

var defaultCfHistogram = {
    BinID: 0,
    BinPopulationPortion: 0,
    BinStartCF: 0,
    BinEndCF: 0,
    BinWeightPortion: 0
};

/* The shape of the JSON object we get from the Distributions call in VakiAPI */
var distributionsData = {
    PopulationID: requiredNumber,
    AverageCorrectedWeight: requiredNumber,
    AverageLiveWeight: requiredNumber,
    AvgCF: requiredNumber,
    CV: requiredNumber,
    ExcludedFish: requiredNumber,
    GrossNumFish: requiredNumber,
    NumFish: requiredNumber,
    SD: requiredNumber,
    TotalCorrectedMass: requiredNumber,
    TotalBiomass: number,
    WeightHistogramList: PropTypes.arrayOf(weightHistogram),
    CFHistogramList: PropTypes.arrayOf(cfHistogram)
};

var defaultDistributionsData = {
    PopulationID: 0,
    AverageCorrectedWeight: 0,
    AverageLiveWeight: 0,
    AvgCF: 0,
    CV: 0,
    ExcludedFish: 0,
    GrossNumFish: 0,
    NumFish: 0,
    SD: 0,
    TotalCorrectedMass: 0,
    TotalBiomass: 0,
    WeightHistogramList: [defaultWeightHistogram],
    CFHistogramList: [defaultCfHistogram]
};

/* The shape of one data point in a FCR graph */
var fcrDataPoint = PropTypes.shape({
    "AvgDailyFeedPerFishInGr": requiredNumber,
    "Date": requiredString,
    "EFCR": requiredNumber,
    "EndWeight": requiredNumber,
    "NumberOfMeasurementsInTrendLine": requiredNumber,
    "WeightChange": requiredNumber
});

var defaultFcrDataPoint = {
    "AvgDailyFeedPerFishInGr": 0,
    "Date": "",
    "EFCR": 0,
    "EndWeight": 0,
    "NumberOfMeasurementsInTrendLine": 0,
    "WeightChange": 0
};

/* The shape of the JSON we receive from EFCRGraph in VakiAPI */
var populationFcrGraphData = PropTypes.shape({
    "PopulationID": requiredNumber,
    "PopulationEFCRGraphDataList": PropTypes.arrayOf(fcrDataPoint)
});

var defaultPopulationFcrGraphData = {
    "PopulationID": 0,
    "PopulationEFCRGraphDataList": [defaultFcrDataPoint]
};

var watDataPoint = {
    "Voltage": requiredNumber,
    "Weight": requiredNumber,
    "WeightTime": requiredString
};

var defaultWatDataPoint = {
    "Voltage": 0,
    "Weight": 0,
    "WeightTime": ""
};

var populationWatGraphData = PropTypes.shape({
    "PopulationID": requiredNumber,
    "PopulationWeightAndTimeDataList": PropTypes.arrayOf(watDataPoint)
});

var defaultPopulationWatGraphData = {
    "PopulationID": 0,
    "PopulationWeightAndTimeDataList": [defaultWatDataPoint]
};

/**
 * A central location defining how we expect our React objects (that get
 * passed around as properties) to be structured. These are basically a
 * description of Vaki API JSON objects as they are returned from the web
 * service, but adapted to React. The documentation for the Vaki API can be
 * found here:
 *
 * https://docs.google.com/document/d/11hav6GDc83T7ho7CcojyDDmUSV5UKJN3QOLml7pZlto/edit
 *
 * JavaScript is a dynamically typed language which means that it just accepts
 * whatever it gets and fails hard (and sometimes silently) when it wasn't
 * what it was expecting.   ReactPropTypes is a slight remedy, as it brings a
 * bit of static typing when creating React components. Instead of giving us a
 * compile error React does give us a descriptive warning in the browsers
 * console if it received properties that looked different from what it was
 * expecting. Effectively offering the option of semi-statically typed JS.
 * This feature is completely optional but a nobrainer to use in my opinion
 * as it will help us greatly in diagnosing problems down the line.
 *
 * Here is a bit of documentation about React PropTypes:
 * http://facebook.github.io/react/docs/reusable-components.html
 *
 * The idea is, when we create a React component we will use it like so:
 *
 *      var VakiReactPropTypes = require("...");
 *
 *      var PopulationStatusTable = React.createClass({
 *          propTypes: {
 *              populationStatus: VakiReactPropTypes.populationStatus
 *          },
 *
 *          render: function () { return (stuff to render) }
 *      });
 *
 * This will also serve as a bit of a smoke signal if the structure of the data
 * returned by Vaki API changes. Then we should see warnings about it while
 * developing. The drawback is we'll have to maintain this document and update
 * it.
 *
 * Note that the below object is merely the collection definition. Originally I
 * attempted to define everything in this object but when it came to reusing types
 * we had defined I hit a snag with JavaScript. For example I wanted to do something
 * like this:
 *
 *      populationStatusList:   ReactPropTypes.arrayOf(this.populationStatus);
 *
 * I didn't get it to work for reasons that I'm not entirely sure of, but I suspect
 * that we aren't guaranteed order of how object literals are defined. That is
 * why I define everything above this object and merely piece it together here.
 */
var VakiReactPropTypes = {
    populationStatus: populationStatus,
    defaultPopulationStatus: defaultPopulationStatus,
    populationStatusList: populationStatusList,
    defaultPopulationStatusList: defaultPopulationStatusList,
    weightHistogramList: weightHistogramList,
    populationTrendData: populationTrendData,
    defaultPopulationTrendData: defaultPopulationTrendData,
    trendGraphData: trendGraphData,
    defaultTrendGraphData: defaultTrendGraphData,
    defaultAllCagesPopulationTrendData: defaultAllCagesPopulationTrendData,
    weightHistogram: weightHistogram,
    defaultWeightHistogram: defaultWeightHistogram,
    cfHistogram: cfHistogram,
    defaultCfHistogram: defaultCfHistogram,
    distributionsData: distributionsData,
    defaultDistributionsData: defaultDistributionsData,
    fcrDataPoint: fcrDataPoint,
    defaultFcrDataPoint: defaultFcrDataPoint,
    populationFcrGraphData: populationFcrGraphData,
    defaultPopulationFcrGraphData: defaultPopulationFcrGraphData,
    defaultPopulationWatGraphData: defaultPopulationWatGraphData
};

module.exports = VakiReactPropTypes;