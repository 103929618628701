/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

import React  from "react";
import {Button, ButtonGroup} from  "react-bootstrap";
var Actions = require("../../actions/Actions.js");

export default class AdminRoutePicker  extends React.Component{
	
	constructor(props) {
    	 super(props);

	this.handleUsersClick = ( )  => {
	
        Actions.adminViewUsersClick();
    };
    this.handleOrgsClick = ( ) => {
	
    	Actions.adminViewOrgClick();
    };
    this.handleFarmsClick = ( ) => {
    	Actions.adminViewFarmsClick();
    };
    this.handleCompsClick = ( ) =>  {
    	Actions.adminViewCompsClick();
    };

};

	
	
	render () {
		return (
			<ButtonGroup>
		        <Button onClick={this.handleUsersClick.bind(null, "Users")} size="lg"  variant="outline-dark"> {this.props.t("adminView.users")} </Button>
		        <Button onClick={this.handleOrgsClick.bind(null, "Organisations")} size="lg" variant="outline-dark"> {this.props.t("adminView.organisations")} </Button>
		        <Button onClick={this.handleFarmsClick.bind(null, "Farms")} size="lg" variant="outline-dark"> {this.props.t("adminView.farms")} </Button>
		        <Button onClick={this.handleCompsClick.bind(null, "Computers")} size="lg" variant="outline-dark"> {this.props.t("adminView.computers")} </Button>
		    </ButtonGroup>
		);
	}
	
};

//module.exports = AdminRoutePicker;

/*
		return (
			<ButtonGroup>
		        <Button onClick={self.handleUsersClick.bind(null, "Users")} size="lg"> {this.props.t("adminView.users")} </Button>
		        <Button onClick={self.handleOrgsClick.bind(null, "Organisations")} size="lg"> {this.props.t("adminView.organisations")} </Button>
		        <Button onClick={self.handleFarmsClick.bind(null, "Farms")} size="lg"> {this.props.t("adminView.farms")} </Button>
		        <Button onClick={self.handleCompsClick.bind(null, "Computers")} size="lg"> {this.props.t("adminView.computers")} </Button>
		    </ButtonGroup>
		);
		*/