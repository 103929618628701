/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var React = require("react");
var RB = require("react-bootstrap");
/* REmoved common page descriptions from Q/A page - at least until we have descriptions on them all (BG jan.'17)

 <RB.Row>
 <h3> Dashboard </h3>
 <p> The dashboard reflects the status of given populations. You can select your desired organisation, farm and population from the
 dropdowns above. You can also switch between individual populations by clicking the respective population icon.
 </p>
 </RB.Row>

 <RB.Row>
 <h3> Trend graph </h3>
 <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
 ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
 reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
 sunt in culpa qui officia deserunt mollit anim id est laborum.
 </p>
 </RB.Row>

 <RB.Row>
 <h3> Distributions </h3>
 <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
 ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
 reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
 sunt in culpa qui officia deserunt mollit anim id est laborum.
 </p>
 </RB.Row>

 <RB.Row>
 <h3> Weight and time </h3>
 <h4> Zooming in </h4>
 <p> You can zoom in to any specific area by dragging your cursor over it. </p>
 </RB.Row>



 */

 export default class QA extends React.Component{
//var QA = export default class   extends React.Component{
    render () {
        return (
            <div>
            <h2> Q&A </h2>
            <RB.Col md={10}>




            <RB.Row>
            <h3> Common questions and answers </h3>
            </RB.Row>

            <RB.Row>
            <h4> Is there a way to save my filters? </h4>
            <p> Filters can be saved by clicking your account name in the top right corner, from there you check the save filters box.
                As long as your browser has support for saving settings locally, filters will now be saved in your browser under your account
                name. If you want to revert back to default filters you can simply uncheck the box.
            </p>
            </RB.Row>

            <RB.Row>
            <h4> How do I change the language? </h4>
            <p> The language can be changed by clicking the flag icon in the top right corner. There you can select your language.
                If your browser supports local storage, the language will stay the same each time you come back to the site.
            </p>
            </RB.Row>

            <RB.Row>
            <h4> How do I change my password? </h4>
            <p> Your password can be changed by clicking your account name in the top right corner, from there you can click the Change password link. </p>
            </RB.Row>

        <RB.Row>
        <h4> Zooming in on charts </h4>
        <p> Some of the charts offer zoom in feature.  Just click and drag the cursor over areas of specific interest.  </p>
        </RB.Row>


        </RB.Col>
            </div>
        )
    }
};

//module.exports = QA;