/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 *
 */

 "use strict";

import React  from "react";
import {Alert, Row, Col} from "react-bootstrap";
import FilterStore  from "../../stores/FilterStore";
import SessionStore  from "./../../stores/SessionStore.js";
import SingleDatePicker from './../Filters/SingleDatePicker.jsx';

import OrgFarmPopPicker  from "../Filters/OrgFarmPopPicker.jsx";
import MinWeightFilter  from "./../Filters/MinWeightFilter.jsx";
import MaxWeightFilter  from "./../Filters/MaxWeightFilter.jsx";
import NumericInput  from "./../Filters/NumericInput.jsx";
import Actions  from "./../../actions/Actions.js";
import moment  from "moment";
import Spinner  from "./../../utils/Spinner.jsx";
import TranslateStore  from "./../../stores/TranslateStore.js";
import SavedFiltersIndicator  from "./../Filters/SavedFiltersIndicator.jsx";
import BlinkPop  from "./../../utils/BlinkPop/BlinkPop.jsx";
import UserDataStore  from "./../../stores/UserDataStore.js";
import VConst  from "./../../utils/VConst.js";
import Curtain  from "./../../utils/Curtain.jsx";
import HourDistributionChart from "./24HourDistributionChart.jsx"
var t = TranslateStore.getTranslate();


var Reflux = require("reflux");
var VakiAPI = require("./../../webservice/VakiAPI.js");
var NavBarStore = require("./../../stores/NavBarStore.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");


var ParMan = require("./../../stores/ParMan.js");



const startDate_ID = "WTS_startDate";
const endDate_ID = "WTS_endDate";




var dtFirst = null;
var dtLast = null;


var _waitingForFirstFetch = false;

var baseFontSize = 14;
var fetchOnDatechangeTimer = null;

var _data = {};

var HourDistributionStore = Reflux.createStore({
    init: function() {

 

    	this.listenTo(Actions.hdOnNewData, this.onHdNewData);
    	this.listenTo(FilterStore, this.onFilterStoreChange);
     	this.listenTo(Actions.hdDate, this.onHdDate);




        _data = {
            date: moment().format("YYYY-MM-DD"),
            hdData: null,
            chartLoading: false,
  
            filter: FilterStore.getFilter()
      
        };



     //   this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);

      
       // this.fetchHdData();
    },

    onFilterStoreChange: function(newFilter) {



        if (!newFilter)
            return;



        _data.filter =  newFilter;
        _data.hdData= null,
        
   




        this.fetchHdData();


        this.trigger(_data);
        

       
    },







    onHdNewData: function (data) {

       

        _data.chartLoading=false;
        if (!data ||  ( (typeof data) == "string" &&  data.substr(0,5).toUpperCase()  == "ERROR" )) {
            //something wrong on serverside.  should at least get empty arrays



            _data.hdData = null;
            this.trigger(_data);
            return;
        }
        
       


       _data.hdData = data;
 
       // this.setChartData(data);

    	this.trigger(_data);
    },
   




    onHdDate: function (newStartDate) {



        if (newStartDate)
            ParMan.setItem(startDate_ID, newStartDate)

        _data.date = newStartDate;
        _data.hdData = null;
_data.chartLoading= true;
  
        this.fetchHdData();
        this.trigger(_data);
    },


    
    notifyMounting: function () {

        var selID = FilterStore.getSelectedPopulationID()

  
        if (!selID || selID <= 0) {
            //Too early call
            _waitingForFirstFetch = true;
            return;
        }
 
        this.fetchHdData();
      

    },




    fetchHdData: function () {
        _data.chartLoading = true;



        if (!_data)
            return;

        var selectedPopulationID = FilterStore.getSelectedPopulationID();




         VakiAPI.GetHourDistribution( {PopID: _data.filter.selectedPopulation.PopulationID, strDate:_data.date});

       
    },

    isLoading: function() {
        return _data.chartLoading;
    },

  

 

    getHdData: function() {
        return _data.hdData;
    },


    
});














export default class HourDistribution  extends React.Component{
	constructor(props) {
     super(props);
        this.state = {

            date: moment ().format ("yyyy-MM-DD"), 
            filter: FilterStore.getFilter(),
            chartData: null, 
            loading: false
 
        };

    /***************************componentWillMount ( )****************************** */
    
        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }

        HourDistributionStore.notifyMounting();
     /************************************************************************* */


    this.onHourDistributionStoreChange = ( newData) => {
        //if (loadingMsg) return false;



try{

        if (!newData  )
            this.setState({

                loading:  false,
                chartData: null
            })
        else
             this.setState({
                date: newData.date,
                chartData: newData.hdData,
                loading: newData.chartLoading
        
            });

}catch (ex){
    console.error ("Exception HourDistribution: "+ex)
}       
    };

    




    };//Constructor







    componentDidMount () {


        

        this.unsubscribeHourDistributionStore = HourDistributionStore.listen(this.onHourDistributionStoreChange);
        this.setState({loading: true });

 
    };
    componentWillUnmount ( ) {
        this.unsubscribeHourDistributionStore();
    };
 

        
    render () {




         var chartArea = "";
         if (this.state.chartData === undefined || this.state.chartData === null) {
             if (!this.state.loading)
                chartArea = <Alert  variant="warning">{t("trendgraph.popHasNo")}</Alert>;
            
        } else {
            var chMsg = "";

                //chMsg = "Dates adjusted to the last day of measurements";
            chartArea = <div><HourDistributionChart chartData={this.state.chartData}  t={t}/>

                    <div className="clsWTChartMsg" >{chMsg}</div>
                
              
            </div>;
         }



        var theSpinner = <div></div>;
        if (this.state.loading)
            theSpinner = <Spinner />


var calendarDate = moment(this.state.date);




		return (
			<div>
   <Row>
                    <Col className="mb-2">
                        
                            <OrgFarmPopPicker filter={this.state.filter} />
                          <br/>
                </Col>
                    <Col className="mb-2">





                 
<SingleDatePicker date={calendarDate} actionFunction={Actions.hdDate} t={t} />



                </Col>






                </Row>
                  {theSpinner}
                  {chartArea}
     
              
				
			</div>
		);
	}
};

