/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 */



"use strict";
var React = require("react");
var RB = require ("react-bootstrap");
var Reflux = require("reflux");
var Immutable = require("immutable");
var moment = require("moment");
var BGTools = require("./../utils/BGtools.js");

var VUtil = require("./../utils/Vutil.js");
var VConst = require("./../utils/VConst.js");
var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var UserDataStore = require("./UserDataStore.js");
var TranslateStore = require("./TranslateStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var t = TranslateStore.getTranslate();
/**
 * Keeps track of state of filters specific to the Trend Graph report as well
 * as the actual trend data used to generate the graph.
 */



var RepSubscribeStore = Reflux.createStore({
    init: function() {


/*
        this.listenTo(Actions.apiError, this.onApiError);
        this.listenTo(Actions.apiNewPopDefineStuff, this.onNewPopDefineStuff);
        this.listenTo(Actions.pdDatePicked, this.onDatePicked);

        this.listenTo(Actions.pdSegmentSelected, this.onSegmentSelected);
       */
        this.listenTo(Actions.apiPdfReport, this.onPdfReport);
        this.listenTo(Actions.rsubAddEmail, this.onAddEmail);
        this.listenTo(Actions.rsubEmailSelected, this.onEmailSelected);
        this.listenTo(Actions.rsubDeleteEmail, this.onDeleteEmail);
        this.listenTo(Actions.rsubAfterEditEmail, this.onAfterEditEmail);
        this.listenTo(Actions.rsubSelectPeriod, this.onSelectPeriod);
        this.listenTo(Actions.rsubSelectDay, this.onSelectDay);

        this.listenTo(Actions.rsubAddSub, this.onAddSub);
        this.listenTo(Actions.rsubDeleteSub, this.onDeleteSub);
        this.listenTo(Actions.rsubSubSelected, this.onSubSelected);
        this.listenTo(Actions.apiPostSubscription, this.onPostSubscription);
        this.listenTo(Actions.apiGetSubscriptions, this.onGetSubscriptions);

        this.listenTo(Actions.rsubSaveSub, this.onSaveSub);
        this.listenTo(Actions.rsubCancelEdit, this.onCancelEdit);
        this.listenTo(Actions.rsubTestRequest, this.onTestRequest);

        this.subPendingID = null;
        this.currSub = this.getSubscriptionRecord();

        this.subList = [];




        this.selectedEmailIdx = -1;
        this.selectedSubIdx = -1;

        this.weekDay = 1;
        this.monthDay = 1;

        this.resetData();

    },


    onTestRequest: function(){

        var FarmId = this.currSub.FarmId;
        if (FarmId < 0)
            FarmId = FilterStore.getSelectedFarmID();

        if (this.currSub.Emails.length == 0){
            alert (t("repsub.errnoaddress"));
            return;
        }



        var dt = new Date();

        var params= {
            FarmId:FarmId,
            pIssueDate: moment.utc(new Date()).format(VConst.DATE_FORMAT),
            pEmailAddress:this.currSub.Emails.join(";"),
            pMinWeight:100,
            pMaxWeight:13000
        };


        VakiAPI.requestPdfByMail(params);





    },

    reIndexSubList: function(){
        var len = this.subList.length;
        for (var i=0; i<len; i++)
            this.subList[i].Idx = i;
    },

    onGetSubscriptions: function (subData){

        this.subList = [];
        for (var i=0; i<subData.length; i++){
            this.subList.push (this.getSubscriptionRecord());
            this.subList[i].Period  = subData[i].ScheduleId
                this.subList[i].Day  = subData[i].ExecuteDay
                this.subList[i].RepType  = subData[i].TemplateId
                this.subList[i].FarmId  = subData[i].FarmId;
                this.subList[i].ID  = subData[i].SubscriptionId
                this.subList[i].Emails = subData[i].ToAddress.split(";")
                this.subList[i].Idx = i;
        }

        this.currSub = this.getSubscriptionRecord();
        this.selectedSubIdx = -1;
        this.trigger(this.currSub, this.subList);

    },

    onPostSubscription: function (strID){



        var arr = strID.split("");

        if (arr[0] == '"'){
            arr.splice(0,1);
            arr.splice(arr.length-1,1);
            strID = arr.join("");
        }


      var id = parseInt(strID);





        if ((typeof id).toUpperCase() != "NUMBER"){
            console.error(" onPostSubscription received NonNumer: "+strID );
            return;

        }



        if (!this.subPendingID){
            console.error("onPostSubscription NULL pending" );
            return;
        }


        this.subPendingID.ID = id;

       this.subPendingID.Idx = this.subList.length;
        this.subList.push(this.subPendingID);


        this.subPendingID = null;
        this.trigger(this.currSub, this.subList);

    },

    onSaveSub: function(){




        var eLst = [];

        for (var i = 0; i<this.currSub.Emails.length; i++){
            if (this.currSub.Emails[i].trim().length > 0)
                eLst.push(this.currSub.Emails[i].trim());
        }

        if (eLst.length == 0){
            alert (t("repsub.errnoaddress"));
            return;
        }

        this.currSub.Emails = eLst;

        if (this.selectedSubIdx >= 0){
            this.subList.splice(this.selectedSubIdx, 1, this.currSub);
            this.updateSub();
        }else {

            for (var i = 0; i<this.subList.length; i++)
                if (this.subList[i].FarmId == FilterStore.getSelectedFarmID()){
                    alert (t("repsub.warndouble"));
                    break;

                }


            this.currSub.FarmId = FilterStore.getSelectedFarmID();


            this.newSub();
           // this.subList.push(this.currSub);
        }
        this.selectedSubIdx = -1
        this.trigger (this.currSub);


    },

    onCancelEdit: function(){
        this.currSub = this.getSubscriptionRecord();
        this.selectedSubIdx = -1;
        this.trigger(this.currSub);
    },


    onAddSub: function(){

        this.currSub = this.getSubscriptionRecord();
        this.currSub.Idx = -100;
        this.selectedSubIdx = -1;

        this.trigger(this.currSub);



    },

    onDeleteSub: function(){

        var sub = this.subList[this.selectedSubIdx];


        this.deleteSub(sub);
        this.subList.splice(this.getSelectedSubIdx(),1);

        this.reIndexSubList();


        this.currSub = this.getSubscriptionRecord();
        this.selectedSubIdx = -1;
        this.trigger(this.currSub, this.subList);

    },

    onSubSelected: function(row, isSelected){



        var idx = row.Idx;
        if(this.selectedSubIdx<0){
            this.selectedSubIdx = idx;
            this.currSub = this.getSubscriptionRecord(this.subList[idx]);
        }else{
            if (idx == this.selectedSubIdx){
                this.selectedSubIdx = -1;
                this.currSub = this.getSubscriptionRecord();
            }else{
                this.selectedSubIdx = idx;
                this.currSub = this.getSubscriptionRecord(this.subList[idx]);

            }
        }

        this.trigger(this.currSub);

    },

    onSelectPeriod: function (s){



        this.currSub.Period = s;
        this.currSub.Day = 1;

        this.trigger(this.currSub);
    },

    onSelectDay: function(s){
        this.currSub.Day = s;
        this.trigger(this.currSub);
    },


    onAfterEditEmail: function(row, cellName, cellValue)
    {
        this.currSub.Emails[row.i]=cellValue;
        if (!this.validateEmail(cellValue)){
            alert ( t("repsub.warnemailformat")+": "+cellValue  );
        }

        this.trigger(this.currSub);

    },

    validateEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
    },

    onEmailSelected: function (row, isSelected){
        var idx = row.i;
        if(this.selectedEmailIdx<0){
            this.selectedEmailIdx = idx;
        }else{
            if (idx == this.selectedEmailIdx){
                this.selectedEmailIdx = -1;
            }else{
                this.selectedEmailIdx = idx;
            }
        }
        this.trigger();
    },

    onDeleteEmail: function (){

        if (this.selectedEmailIdx < 0)
            return;
        this.currSub.Emails.splice (this.selectedEmailIdx,1);
        this.selectedEmailIdx = -1;
        this.trigger(this.currSub);
    },



    onAddEmail: function(){

        this.currSub.Emails.push("");
        if (this.currSub.Emails.length == 1) {//first address
            var nam = UserDataStore.getUserName();
            if (nam &&  nam.indexOf("@") > 0)
                this.currSub.Emails[0] = nam;
        }
        this.trigger(this.currSub);
    },

    isDeleteEmailEnabled: function(){
        return (this.selectedEmailIdx >= 0);
    },
    isDeleteSubEnabled: function(){
        return (this.selectedSubIdx >= 0);
    },

    getSelectedEmailIdx: function(){
        return this.selectedEmailIdx;
    },
    getSelectedSubIdx: function(){

        return this.selectedSubIdx;
    },
    getPeriodText: function(p){
           return (p==2 ? t("repsub.weekly"): t("repsub.monthly"));
    },

    getDayText: function (period, day ){
        if (period==1)
            return (day+t("repsub.dayofmonth"));

        if (day<1 || day > 7)
            return "---";
        day--;

        var week = t("repsub.weekdays").split(";");

        if (week.length != 7)
            return "***";

        return week[day];


    },



    getSubscriptionRecord: function(sub){


        var s = {
            ID : -1,

            OrgName: "",
            FarmName:"",
            FarmId: -1,
            RepType: 1, //For future when more reports. Start by 1
            Period: 1, //1:monthly, 2:weekly

            Day:1, //1-7 for weekly, 1-28 for monthly
            Emails: [],  //List of strings (email addresses),
            Idx: -1,
            getPeriodText: function(){return  RepSubscribeStore.getPeriodText (this.Period);},
            getDayText: function(){return RepSubscribeStore.getDayText(this.Period, this.Day);}
        };

        if (sub){
            s.ID = sub.ID;
            s.Idx = sub.Idx;
            s.FarmId=sub.FarmId;
            s.RepType = sub.RepType;
            s.Period = sub.Period;
            s.Day = sub.Day;
            s.Emails = [];
            for (var i=0; i<sub.Emails.length; i++)
                s.Emails.push(sub.Emails[i]);
        }

        return s

    },

getSubscriptionObjForServer: function(sub){

    var s = {
        SubscriptionId : sub.ID,
        FarmId: sub.FarmId,
        TemplateId: sub.RepType,
        ScheduleId: sub.Period,
        ExecuteDay: sub.Day,
        ToAddress: sub.Emails.join(";")
    };
    return s
},


    resetEditStuff: function(){
        this.ed = {

            rb_start: false, //radiobuttons check/uncheck
            rb_end: false,
            anyChange :false,



        };

    },


    resetData: function (){

        this.chartData = null;
        this.fetchData = false;
        this.popSegments = [];
        this.noVerified = true;
        this.selectedSegment = -1;
        this.resetEditStuff();


    },


    onPdfReport: function (r){
        var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/rn/g,"n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}};




        try {

    var saveByteArray = (function () {

        var a = document.createElement("a");
        alert ("a  "+a)
        document.body.appendChild(a);
        a.style = "display: none";
        alert (1);
        return function (data, name) {




            //  var blob = new Blob(data, {type: "octet/stream"}),
            var blob = new Blob(  data, {type: "data:application/pdf"}),
                url = window.URL.createObjectURL(blob);
            alert ("blob:  "+blob);
            alert ("url:  "+url);
            a.href = url;
            a.download = name;
            alert ("a.down: "+ a.download);
            a.click();
            alert (2);

           window.URL.revokeObjectURL(url);
        };
    }());

    alert("going to save")
    saveByteArray([r], 'example1.pdf');








}catch (er){alert ("Exc:  "+er);}






       // window.open("data:application/pdf;base64," +r);//villa
       // window.open("data:application/pdf;base64," +Base64.encode(r)); //opnar t�mur
        //window.open("data:application/pdf," +encodeURI(r)); //opnast t�mur
        //window.open(Base64.encode(r)); //opnast t�mur
        //window.open(r); //opnast t�mur
        //window.open("data:application/pdf," +r);//villa
        //window.open("data:application/pdf," +Base64.encode(r)); //T�mur gluugi opnast
         //window.open("data:application/pdf:base64," +Base64.encode(r)); //opnast gluggi fullur af fddfgdf
        //window.open("data:application/pdf:base64," +r); //Villa

    },








    onDownloadClick: function(){

        alert (113);


        this.fetchPdfReport(182)

    },



    fetchPdfReport: function(farmID){

        VakiAPI.fetchMonthlyReportPdf(farmID);

    },

    updateSub: function(){

        var sub = this.getSubscriptionObjForServer(this.currSub);

       VakiAPI.putSubscription( sub);
        this.currSub = this.getSubscriptionRecord();
    },

    newSub: function(){
        this.subPendingID = this.currSub;
        VakiAPI.postSubscription( this.getSubscriptionObjForServer(this.currSub));
        this.currSub = this.getSubscriptionRecord();


    },

    getSubs: function(){
        VakiAPI.getSubscriptions();
    },

    deleteSub: function(sub){
        VakiAPI.deleteSubscription( this.getSubscriptionObjForServer(sub));
    }


});

module.exports = RepSubscribeStore;