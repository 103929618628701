/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

/**
 * Vaki Constants. Object containing bunch of static constants. If/when we
 * start using ES6 it might be worth converting this into the new Symbol
 * primitive. It was designed for exactly this purpose.
 */
var VConst = {
    /**
     * VakiAPI constants
     *
     * At the time of writing (2015.07.20) we do not have a proper production
     * build step (a npm/webpack build script) that uses the appropriate API
     * URL. That is if we should use the testserver or the production server.
     * Until we get around to doing that properly a hacky way is to simply
     * comment in & out the line you want to target. Just be very careful to
     * never commit to the codebase the URL to the production API. Git should
     * only contain the development server. This is obviously not ideal but a
     * temporary hack/workaround.
     */





    /*****  ÚRELT ***********
    APIURL: "http://testserver:8088/api/v1.0/Service1.svc/", // Development server
    APIURL: "http://vakiapi.com:8088/v1.0/Service1.svc/", // Production server
    ************************/








/****************************************************************************** */
//APIURL: "https://VakiAPI2.COM/api/v1.0/Service1.svc/",  //THe TestSErver @ AMAZON  

APIURL: "https://vakiapi.com/VakiAPI/v1.0/Service1.svc/",  //Production

/******************************************************************************* */


//APIURL: "http://34.253.139.38/api/v1.0/Service1.svc/",  //THe TestSErver @ AMAZON  
//APIURL:  "https://vakiapi.com/v1.0/Service1.svc/",   //production server with ssl
//Ný vél :
//APIURL: "http://54.229.151.30/v1.0/Service1.svc/",
//APIURL: "http://54.229.151.30/VakiAPI/v1.0/Service1.svc/",
//APIURL: "http://54.229.151.30:85",
//APIURL: "http://54.229.151.30/VakiAPI/v1.0/Service1.svc/", //Test fyrir Hlyn  


    // Add new colors in here, access by: VConst.COLORS.NAME_OF_COLOR
    COLORS: {
        // Average weight of fish, for example in TrendGraph and Distribution
        AVG_WEIGHT: "#7cb5eb",
        AUX_WGT_DATA: "#F771F9",
		TL30: "#8000FF",
		TL60: "#0000FF",
        // The red color of the trend line in Trend Graph report
        TREND_LINE: "#b34442",
        TREND_LINE_TRANS: "rgba (199, 0, 0, 0.5)",
        CONDITION_FACTOR: "#91eb7c",
        // The alternative red color in distribution
        DISTRIBUTION_RED: "#ba4d51",
        // Transparent grey for fish measured columns
        /*
        FISH_MEASURED: "rgba(192, 192, 192, 0.5)",
        FISH_MEASURED_LARGEFRAME: "rgba(140, 140, 140, 0.5)",

         */
        FISH_MEASURED: "rgba(210, 210, 210, 0.5)",
        FISH_MEASURED_LARGEFRAME: "rgba(100, 100, 100, 0.5)",
        FISH_MEASURED_SMALLFRAME: "rgba(150, 150, 150, 0.5)",
        TEMPERATURE: "#F5A05B",
        DEPTH: "#7fffd4",
        COMMENT: "#F7D642", //   "#b0c4de",
        // Average daily feed per fish, used in FCR report
        AVG_DAILY_FEED: "#695ACC",
        // FCR report
        WEIGHT_CHANGE: "#53CC1F",

       // Original end weight color from old EFCR report
        END_WEIGHT: "#780000",
        EFCR: "#FF8000",
        SEGMENT: "rgba(100, 250, 40, 0.5)",
        OTHER_SEGMENT: "rgba(255, 153, 230, 0.5)",
        BFCR: "#FACA2E",
        BFCR_ACC:"#DF7401",
        AUX_OX: "#14F4D6",
        AUX_TEMP:"#F41450",
        AUX_CNT:"#66F414",
        AUX_BOOKWGT: "#A214F4",
        POP_GREEN: "#89E894",
        POP_GREEN_BORDER:"#64EB74",
        POP_YELLOW: '#fffe72', /*"#FDFD96",*/  /*org #ffee22*/
        POP_YELLOW_BORDER:"#FEFE71",
        POP_RED: "#ff6961",
        POP_RED_BORDER: "#FF5148",
        POP_PURPLE: "#D2B4DE",
        POP_PURPLE_BORDER: "#BB8FCE"
},



    CLASS: {
        EDIT_ERROR: "show-error-edit"
    },

    /** Default values for filters */

    FILTER_EDIT_DELAY: 800, //0.8 sec after edit - before trigger

    DEFAULT_MIN_WEIGHT: 100, // grams
    DEFAULT_MAX_WEIGHT: 13000, // grams
    ABSOLUTE_MIN_WEIGHT: 0,
    ABSOLUTE_MAX_WEIGHT: 99999,
    ABSOLUTE_MIN_BINSIZE: 10,
    DEFAULT_MIN_CF: 0.9,
    DEFAULT_MAX_CF: 2,
    DEFAULT_BIN_SIZE: 1000, // grams
    DEFAULT_GUTTING_FACTOR: 0, // percentage, not sure if it's 0-1 or 0-100
    DEFAULT_POPULATION_SIZE: 0, // number of fish
    DEFAULT_CF_BIN_SIZE: 0.1, // Condition Factor bin size, for the CF distribution histogram

    DEFAULT_GUTTINGFACTOR: 14,
    DEFAULT_STARVINGFACTOR: 3,
    DEFAULT_POPULATIONSIZE: 1000,
    /** Trend Graph defaults */
    // How many days we want do display in the Trend Graph
    DEFAULT_RANGE: 30, // number of days
    DEFAULT_WEIGHT_TIME_RANGE: 5,

    /** Distribution specific */
    MIN_ALLOWED_CF: 0,
    MAX_ALLOWED_CF: 10,

    /** FCR Report */
    DEFAULT_TREND_LINE_LENGTH: 30, // number of days

    /** Session constants */
    WRONG_USER_OR_PASS: "WRONG_USER_OR_PASS",

    /** Other various constants */
    DATE_FORMAT: "YYYY-MM-DD",
    MAX_USERNAME_LENGTH: 20,

    CONTEXT_BUTTON_SHAPE: {
        height: 40,
        width: 48,
        symbolSize: 24,
        symbolX: 23,
        symbolY: 21,
        symbolStrokeWidth: 2
    },


    PAGEID: {
        DASHBOARD: 1,
        DASHBOARD_ORG: 2,
        TRENDGRAPH: 3,
        DISTRIBUTION: 4,
        WEIGHTANDTIME: 5,
        POPULATIONSTAT: 6,
        DASHBOARDHELP: 7
    },

    /**Trendgraph rendering flags**/
    TG_NEWDATA: 0x01,
    TG_RESET: 0x02,
    TG_REDRAW: 0x04,
    TG_CLEAR: 0x08,
    TG_LOAD_ON: 0x10,
    TG_LOAD_OFF: 0x20,
    TG_JUST_MOUNTED: 0x40,




    MNT_VALUES:1,
    MNT_DISTR:2,

    COPYRIGHT:" Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved."

};

module.exports = VConst;