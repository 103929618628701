var React = require("react");
	import {Dropdown,DropdownButton , Button, Menu, Row, Col,Alert,  Form} from"react-bootstrap";
var Actions = require("../../../actions/Actions.js");
var SearchDropdown = require("./Dropdowns/SearchDropdown.jsx");
var SelectedDropdownOptions = require("./Dropdowns/SelectedDropdownOptions.jsx");
var SessionStore = require("../../../stores/SessionStore");

 export default class AdminCreateOrgForm  extends React.Component{
	constructor(props) {
     super(props);
    	this.state = { 
    		name: "",
    		address: "",
    		email: "",
    		phone: "",
    		loading: false,
    		nameEmpty: false,
    		nameTaken: false
    	};
	this.onFormSubmit = ( ) => {

		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}

		if(this.state.name === "") {
			this.setState({ nameEmpty: true });
			return;
		}
		for(var i = 0; i < this.props.organisations.length; ++i) {
			if(this.state.name === this.props.organisations[i].name) {
				this.setState({
					nameTaken: true
				});
				return;
			}
		}
		Actions.createOrgFormSubmit(this.state.name, this.state.address, this.state.email, this.state.phone);
		this.setState({
			name: "",
			address: "",
			email: "",
			phone: "",
    		loading: true,
    		nameEmpty: false,
    		nameTaken: false
		});
		var self = this;
		setTimeout(function(){
			self.setState({
				loading: false
			}); 
		} , 2000);
	};
	this.handleNameChange = ( e) => {
		this.setState({name: e.target.value });
	};
	this.handleAddressChange = ( e) => {
		 this.setState({address: e.target.value });
	};
	this.handleEmailChange = ( e) => {
		 this.setState({email: e.target.value });
	};
	this.handlePhoneChange = ( e) => {
		 this.setState({phone: e.target.value });
	};


	};

	render ( ) {
		return (
			<form>
				<Row>
					<Col md={6}>
					<Form.Label>{this.props.t("adminView.name")}</Form.Label>
						<Form.Control value={this.state.name} onChange={this.handleNameChange} type="text" 
						 placeholder={this.props.t("adminView.name") + ".."} />
						<Alert  variant="warning" hidden={!this.state.nameTaken}>
							<strong> {this.props.t("adminView.nameTaken")}! </strong>
						</Alert>
						<Alert  variant="warning" hidden={!this.state.nameEmpty}>
							<strong> {this.props.t("adminView.mustEnter")} {this.props.t("adminView.name")}! </strong>
						</Alert>
					</Col>
					<Col md={6}>
						<Form.Label>{this.props.t("adminView.email")} </Form.Label>
						<Form.Control value={this.state.email} onChange={this.handleEmailChange} type="text" 
								placeholder={this.props.t("adminView.email") + ".."} />
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Label>{this.props.t("adminView.address")}</Form.Label>
						<Form.Control value={this.state.address} onChange={this.handleAddressChange} type="text" 
							 placeholder={this.props.t("adminView.address") + ".."} />
					</Col>
					<Col md={6}>
					<Form.Label>{this.props.t("adminView.phoneNumber")}</Form.Label>
						<Form.Control value={this.state.phone} onChange={this.handlePhoneChange} type="text" 
								 placeholder={this.props.t("adminView.phoneNumber") + ".."} />	
					</Col>
				</Row>

				<div className="text-center">
					<Button  variant="primary" onClick={this.onFormSubmit} disabled={this.state.loading} variant="outline-dark">
						{this.props.t("adminView.submit")}
					</Button>
					<div className="spinner" hidden={!this.state.loading}></div>
				</div>
			</form>
		);
	}
};

