/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

var React = require("react");
import {Table} from "react-bootstrap";
import FarmListRow from "./Rows/FarmListRow.jsx";

export default class AdminViewFarmList  extends React.Component{
	shouldComponentUpdate (nextProps, nextState) {
	    if(nextProps.tableActive === false) {
	    	return false;
	    }
	    return true;
	};
	render ( ) {
		var self = this;
		var farmTableRows = this.props.farms.map(function(farm, i) {
			return (
				<FarmListRow farm={farm} key={i} t={self.props.t} />
			);
		});
		return (
			<div>
			<div className="table-wrapper">
	    		<Table striped bordered condensed = {"true"} hover className="admin-view-table">
	    			<thead>
	    				<tr>
		    				<th className="col-md-4"> {this.props.t("adminView.name")} </th>
		    				<th className="col-md-4"> {this.props.t("adminView.organisation")} </th>
		    				<th className="col-md-2"> {this.props.t("adminView.country")} </th>
		    				<th className="col-md-2"> Del/Res </th>
	    				</tr>
	    			</thead>
	    		</Table>
	    	</div>
			<div className="table-wrapper">
	    		<Table striped bordered condensed = {"true"} hover className="admin-view-table">
	    			<tbody>
	    				{farmTableRows}
	    			</tbody>
	    		</Table>
	    	</div>
	    	</div>
		);
	}
};

