'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, FormControl } from 'react-bootstrap';
import Spinner from './../../utils/Spinner.jsx';
import VakiAPI from '../../webservice/VakiAPI.js';
import SearchDropdownCamera from "./SearchDropdownCamera.jsx"

import Actions from '../../actions/Actions.js';
var UserDataStore = require('./../../stores/UserDataStore.js');

require('./CameraManagement.css');




export default function OrphanSelect(props) {

    const [theList, setTheList] = useState([]);
    const [selectedOrph, setSelectedOrph] = useState (-1);




	useEffect(() => {
            setTheList (props.OrphanList);
            setSelectedOrph (-1);


    }, [props.OrphanList]);





    let options = []
    let TheStuff = <div className="center-with100"> No orphan cameras available </div>

    if (theList.length > 0){
        options.push (		<option key={0} value={-1}>
			None selected...
		</option>)

        let options2 = [];
        for (var i = 0; i<theList.length; i++){
            
            let str = theList[i].Serial_Number+" --- "+theList[i].DeviceCreated.substring(0,10);
            if (theList[i].Model != null && theList.Model != "")
                str += ",  M: "+theList[i].Model;
             if (theList[i].Firmware_Version != null && theList[i].Firmware_Version != "")  
               str += ",  FV: "+theList[i].Firmware_Version;
            options2.push({name:str,
                            obj: theList[i],
                            id:theList[i].Device_ID });   
               
            options.push (<option key={i+1} value={theList[i].Device_ID}>
			{str}
		</option>)
        }


        TheStuff =   <SearchDropdownCamera title="Select camera device" options={options2}  select={props.Select} />


     

    }
/*
<SearchDropdown title={this.props.t("adminView.organisations")} options={this.props.currentUser.organisations} 
					view="newUser" type="organisation" translateTrigger={this.props.translateTrigger} />
*/


	return (
		<div>
            {TheStuff}
		</div>
	);
}

/*
        TheStuff = <div>
                <Form.Select
						aria-label="select poplation"
						className="mb-0"
						onChange={(e) => setSelectedOrph(e.target.value)}
						className="width100"
						value={selectedOrph}
					>
						{options}
					</Form.Select>
<Button
								onClick={onAddOrphan}
								variant="outline-dark"
								className="mb-3"
                                disabled = {selectedOrph == -1}
							>
								Add selected camera
							</Button>
<Row>

<SearchDropdownCamera title="Select camera device" options={options2}  select={props.Select} />

</Row>
        </div>

*/