'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import { Container, Row, Col, Alert, Buttongroup, Button } from 'react-bootstrap';
import moment from 'moment';
import BlinkPop from './../../utils/BlinkPop/BlinkPop.jsx';
import SingleDatePicker from './../Filters/SingleDatePicker.jsx';
import VakiAPI from '../../webservice/VakiAPI';
import vutil from '../../utils/Vutil';
import FilterStore from '../../stores/FilterStore';
import SessionStore from './../../stores/SessionStore.js';
import DistributionStore from './../../stores/DistributionStore.js';
import OrgFarmPopPicker from '../Filters/OrgFarmPopPicker.jsx';
import MinWeightFilter from './../Filters/MinWeightFilter.jsx';
import MaxWeightFilter from './../Filters/MaxWeightFilter.jsx';

import MinHourCountFilter from './../Filters/MinHourCountFilter.jsx';
import MinCFFilter from './../Filters/MinCFFilter.jsx';
import MaxCFFilter from './../Filters/MaxCFFilter.jsx';
import WeightBinFilter from './../Filters/WeightBinFilter.jsx';
import GuttingFactorFilter from './../Filters/GuttingFactorFilter.jsx';
import PopulationSizeFilter from './../Filters/PopulationSizeFilter.jsx';

import Actions from './../../actions/Actions.js';
import TranslateStore from './../../stores/TranslateStore.js';
import SavedFiltersIndicator from './../Filters/SavedFiltersIndicator.jsx';
import UserDataStore from './../../stores/UserDataStore.js';
import BGTools from './../../utils/BGtools.js';
import Immutable from 'immutable';
import VakiReactPropTypes from './../../utils/VakiReactPropTypes.js';
import Spinner from './../../utils/Spinner.jsx';


var t = TranslateStore.getTranslate();
require('./Distribution.css');

export default class DistributionFilters extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showMsg: true,
			filter: FilterStore.getFilter(),
			distributionFilter: DistributionStore.getFilter(),
			distributionsData: DistributionStore.getDistributionsData(),
			loading: false
		};

		/******** from   componentWillMount ( ) ****************/
		if (SessionStore.isLoggedOut()) {
			Actions.redirectToLogin();
		}
		/**************************************************** */

		this.onCommonFilterChange = (filter) => {
			this.setState({ filter: filter });
		};

		this.onTranslateStoreChange = () => {
			this.setState({});
		};

		this.onDistributionFilterChange = (newDistData, loadingMsg) => {
			var load = true;
			if (!loadingMsg) load = false;

			if (!newDistData) {
				this.setState({
					distributionsData: null,
					loading: load
				});
			} else {
				this.setState({
					distributionFilter: newDistData.get('filter'),
					distributionsData: newDistData.get('distributionsData'),
					loading: load
				});
			}
		};
	}; //Contructor

	componentDidMount() {
		// When we load this report, set the default date range to 1 day from
		// today. Note: this might be an anti-pattern, not sure.
		//var defaultOneDaysRange = vutil.lastXDays(1);
		//Actions.startDateUpdate(defaultOneDaysRange[0]);
		//Actions.endDateUpdate(defaultOneDaysRange[1]);
		this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
		this.unsubscribeFilterStore = FilterStore.listen(this.onCommonFilterChange);
		this.unsubscribeDist = DistributionStore.listen(this.onDistributionFilterChange);
	}

	componentWillUnmount() {
		this.unsubscribeTranslateStore();
		this.unsubscribeFilterStore();
		this.unsubscribeDist();
	}

	render() {
		var distributionsData = this.state.distributionsData;
		var dataOK = !!distributionsData;
		if (dataOK) dataOK = distributionsData.get('NumFish') > 0 && distributionsData.get('AverageLiveWeight') > 0;

		var displayDate = this.state.distributionFilter.get('displayDate');
		var startDate = this.state.distributionFilter.get('startDate');
		var endDate = this.state.distributionFilter.get('endDate');
		var minCF = this.state.distributionFilter.get('minCF');
		var maxCF = this.state.distributionFilter.get('maxCF');
		var binSize = this.state.distributionFilter.get('binSize');
		var guttingFactor = this.state.distributionFilter.get('guttingFactor');
		// API hasn't been updated yet, when Bjarki is done, connect this to the API call
		var populationSize = this.state.distributionFilter.get('populationSize');

		var minWeight = this.state.filter.minWeight;
		var maxWeight = this.state.filter.maxWeight;
		var minHourCount = this.state.filter.MinFishPrHour;
		var selectedOrganisation = this.state.filter.selectedOrganisation.Name;
		var selectedFarm = this.state.filter.selectedFarm.Name;

		var disableBinSize = false;

		if (this.props.fixedBinSize) {
			disableBinSize = true;
			binSize = this.props.fixedBinSize;
		}

		var renderedMain = null;

		var calendarDate = moment(displayDate);

		var vArr = [];

		if (this.state.loading) {
			renderedMain = <Spinner />;
		} else if (!dataOK) {
			renderedMain = <Alert variant="warning"> {t('distribution.noData')} </Alert>;
		} else {
			var dateStr = moment.utc(this.state.distributionFilter.get('displayDate')).format('YYYY-MM-DD');

			var len = distributionsData.get('WeightHistogramList').length;
			for (var i = 0; i < len; i++) vArr.push(distributionsData.get('WeightHistogramList')[i]);
		}

		var autoDisabled = false;

		if (binSize == null && minWeight == null && maxWeight == null) autoDisabled = true;



		
		return (
			<div>
				<Row>
					<Col md={8} sm={8} xs={11}>
						<OrgFarmPopPicker filter={this.state.filter} />
					</Col>

					<Col md={3} sm={3} xs={10}>
						<ButtonGroup vertical = "true" >
							<Button
								type="button"
								disabled={autoDisabled}
								onClick={function() {
									FilterStore.onMinWeightUpdate(null);
									FilterStore.onMaxWeightUpdate(null);
									DistributionStore.onBinSizeUpdate(null);
									//Actions.trendUseAutoFilters()
								}}
								inline = 'true'
                                variant="outline-dark"
							>
								Use auto filters
							</Button>

							<Button
								type="button"
								onClick={function() {
									FilterStore.onMinWeightUpdate(100);
									FilterStore.onMaxWeightUpdate(13000);
									DistributionStore.onBinSizeUpdate(null);
									//Actions.trendUseAutoFilters()
								}}
								inline = 'true'
                                variant="outline-dark"
							>
								Use default filters
							</Button>
						</ButtonGroup>
					</Col>
					<Col md={1} sm={1} xs={1}>
						<span id="dist-saved-filter-indicator">
							<SavedFiltersIndicator />
						</span>
					</Col>
				</Row>
				<br />

				<div id="distribution-filters" className="clsFiltFlex">
					<div className="clsFiltInputWrap">
						<SingleDatePicker date={calendarDate} actionFunction={Actions.distDateUpdate} t={t} />
						<div className="clsOverUnderSpace">
							<BlinkPop
								ID="DistBinSize"
								User={UserDataStore.getUserName()}
								Text=" Set the weight BinSize/ColumnWidth for the weight distribution chart or leave it empty for automatic adjustment"
							>
								<WeightBinFilter
									binSize={binSize}
									actionFunction={Actions.distBinSizeUpdate}
									disabled={this.props.fixedBinSize}
									t={t}
								/>
							</BlinkPop>
						</div>
					</div>

					<div className="clsFiltInputWrap">
						<BlinkPop ID="DistMax0" User={UserDataStore.getUserName()} Text={t('trendgraph.minempty')}>
							<MinWeightFilter allowEmpty maxValue={maxWeight} initValue={minWeight} t={t}  allowempty />
						</BlinkPop>

						<div className="clsOverUnderSpace">
							<MaxWeightFilter allowEmpty minValue={minWeight} initValue={maxWeight} t={t}  allowempty/>


						</div>

						<div className="clsOverUnderSpace">
				<MinHourCountFilter
					initValue={minHourCount}
					minValue={0}
					allowempty
					actionFunction={Actions.minHourCountUpdate}
					t={t}
				/>


					

							
						</div>

					</div>

					<div className="clsFiltInputWrap">
						<MinCFFilter minCF={minCF} maxValue={maxCF} t={t} actionFunction={Actions.distMinCFUpdate} />

						<div className="clsOverUnderSpace">
							<MaxCFFilter
								maxCF={maxCF}
								minValue={minCF}
								t={t}
								actionFunction={Actions.distMaxCFUpdate}
							/>
						</div>
					</div>



					<div className="clsFiltInputWrap">
						<GuttingFactorFilter
							guttingFactor={guttingFactor}
							actionFunction={Actions.distGuttingFactorUpdate}
							t={t}
						/>
						<div className="clsOverUnderSpace">
							<PopulationSizeFilter
								populationSize={populationSize}
								actionFunction={Actions.distPopulationSizeUpdate}
								t={t}
							/>
						</div>
					</div>
				</div>

				<br />
			</div>
		);



	}
}

/*
 <LiveWeightTable weightHistogramList={distributionsData.get("WeightHistogramList")} NumFishes = {populationSize}  t={t} />

 */


/*

		return (
			<div>
				<Row>
					<Col md={8} sm={8} xs={11}>
						<OrgFarmPopPicker filter={this.state.filter} />
					</Col>

					<Col md={3} sm={3} xs={10}>
						<ButtonGroup vertical = "true">
							<Button
								type="button"
								disabled={autoDisabled}
								onClick={function() {
									FilterStore.onMinWeightUpdate(null);
									FilterStore.onMaxWeightUpdate(null);
									DistributionStore.onBinSizeUpdate(null);
									//Actions.trendUseAutoFilters()
								}}
								inline
							>
								Use auto filters
							</Button>

							<Button
								type="button"
								onClick={function() {
									FilterStore.onMinWeightUpdate(100);
									FilterStore.onMaxWeightUpdate(13000);
									DistributionStore.onBinSizeUpdate(null);
									//Actions.trendUseAutoFilters()
								}}
								inline
							>
								Use default filters
							</Button>
						</ButtonGroup>
					</Col>
					<Col md={1} sm={1} xs={1}>
						<span id="dist-saved-filter-indicator">
							<SavedFiltersIndicator />
						</span>
					</Col>
				</Row>
				<br />

				<div id="distribution-filters" className="clsFiltFlex">
					<div className="clsFiltInputWrap">
						<SingleDatePicker date={calendarDate} actionFunction={Actions.distDateUpdate} t={t} />
						<div className="clsOverUnderSpace">
							<BlinkPop
								ID="DistBinSize"
								User={UserDataStore.getUserName()}
								Text=" Set the weight BinSize/ColumnWidth for the weight distribution chart or leave it empty for automatic adjustment"
							>
								<WeightBinFilter
									binSize={binSize}
									actionFunction={Actions.distBinSizeUpdate}
									disabled={this.props.fixedBinSize}
									allowEmpty
									t={t}
								/>
							</BlinkPop>
						</div>
					</div>

					<div className="clsFiltInputWrap">
						<BlinkPop ID="DistMax0" User={UserDataStore.getUserName()} Text={t('trendgraph.minempty')}>
							<MinWeightFilter allowEmpty maxValue={maxWeight} initValue={minWeight} t={t} />
						</BlinkPop>

						<div className="clsOverUnderSpace">
							<MaxWeightFilter allowEmpty minValue={minWeight} initValue={maxWeight} t={t} />
						</div>
					</div>

					<div className="clsFiltInputWrap">
						<MinCFFilter minCF={minCF} maxValue={maxCF} t={t} actionFunction={Actions.distMinCFUpdate} />
						<div className="clsOverUnderSpace">
							<MaxCFFilter
								maxCF={maxCF}
								minValue={minCF}
								t={t}
								actionFunction={Actions.distMaxCFUpdate}
							/>
						</div>
					</div>

					<div className="clsFiltInputWrap">
						<GuttingFactorFilter
							guttingFactor={guttingFactor}
							actionFunction={Actions.distGuttingFactorUpdate}
							t={t}
						/>
						<div className="clsOverUnderSpace">
							<PopulationSizeFilter
								populationSize={populationSize}
								actionFunction={Actions.distPopulationSizeUpdate}
								t={t}
							/>
						</div>
					</div>
				</div>

				<br />
			</div>
		);

*/