/**
 * Vaki Aquaculture Systems Ltd. Copyright 2018, all rights reserved.
 *
 * Language: JavaScript, ES6

 */
"use strict";

var React = require("react");



export default class Curtain extends React.Component{




    constructor(props) {
     super(props);
     

    };



    render  () {



        return (
            <div  className="curtain" >


  
	    	</div>
        )
    }
};




