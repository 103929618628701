var ColorPicker = {
	getColor: function(number) {
		if(number === 0) return "rgba(255, 0, 0, 0.5)";
		else if(number === 1) return "rgba(255, 255, 0, 0.5)";
		else if(number === 2) return "rgba(0, 204, 153, 0.5)";
		else if(number === 3) return "rgba(0, 51, 204, 0.5)";
		else if(number === 4) return "rgba(153, 255, 51, 0.5)";
		else if(number === 5) return "rgba(153, 102, 255, 0.5)";
		else if(number === 6) return "rgba(204, 0, 204, 0.5)";
		else if(number === 7) return "rgba(51, 153, 255, 0.5)";
		else if(number === 8) return "rgba(255, 153, 102, 0.5)";
		else if(number === 9) return "rgba(255, 204, 102, 0.5)";
		else if(number === 10) return "rgba(255, 153, 153, 0.5)";
		else if(number === 11) return "rgba(153, 0, 51, 0.5)";
		else if(number === 12) return "rgba(102, 255, 153, 0.5)";
		else if(number === 13) return "rgba(204, 102, 255, 0.5)";
		else if(number === 14) return "rgba(204, 0, 153, 0.5)";
		else if(number === 15) return "rgba(153, 102, 51, 0.5)";
		else if(number === 16) return "rgba(51, 102, 255, 0.5)";
		else if(number === 17) return "rgba(128, 0, 0, 0.5)";
		else if(number === 18) return "rgba(102, 0, 102, 0.5)";
		else if(number === 19) return "rgba(204, 204, 0, 0.5)";
		else if(number === 20) return "rgba(204, 255, 255, 0.5)";
		else if(number === 21) return "rgba(255, 153, 153, 0.5)";
		else if(number === 22) return "rgba(255, 153, 0, 0.5)";
		else if(number === 23) return "rgba(255, 153, 204, 0.5)";
		else if(number === 24) return "rgba(102, 102, 153, 0.5)";
		else if(number === 25) return "rgba(153, 0, 255, 0.5)";
		else if(number === 26) return "rgba(128, 216, 216, 0.5)";
		else if(number === 27) return "rgba(51, 128, 26, 0.5)";
		else if(number === 28) return "rgba(178, 128, 230, 0.5)";
		else if(number === 29) return "rgba(204, 255, 102, 0.5)";
		else if(number === 30) return "rgba(255, 0, 255, 0.5)";
		else return "rgba(0, 0, 0, 0.5)"
	}
};

module.exports = ColorPicker;