var React = require("react");
import {Dropdown,DropdownButton , Button, Menu, Accordion, Row, Col, FormControl, Form, Alert} from"react-bootstrap";
var Actions = require("../../../actions/Actions.js");
import SearchDropdown from "./Dropdowns/SearchDropdown.jsx";
import SelectedDropdownOptions from "./Dropdowns/SelectedDropdownOptions.jsx";

var SessionStore = require("../../../stores/SessionStore");


export default class AdminCreateUserForm  extends React.Component{
	constructor(props) {
     super(props);
    	this.state = { 
    		firstName: "",
    		lastName: "",
    		username: "",
    		mobile: "",
    		email: "",
    		jobTitle: "",
    		password: "",
    		secondPassword: "",
    		userTypeID: 0,
    		userType: "",
    		loading: false,
    		userIsAdminOrEmployee: false,
    		usernameTaken: false,
			usernameEmpty: false,
			passwordMismatch: false,
			passwordEmpty: false,
			passwordTooShort: false,
			roleMissing: false,
			userTypeMissing: false
    	};


	this.onFormSubmit = ( ) => {

		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}

		/* Security checks to see if something's wrong */
		if(this.state.username === "") {
			this.setState({ usernameEmpty: true });
			return;
		}
		if(this.state.password === "") {
			this.setState({ passwordEmpty: true });
			return;
		}
		if(this.state.password !== this.state.secondPassword) {
			this.setState({ passwordMismatch: true });
			return;
		}
		if(this.state.password.length < 8) {
			this.setState({ passwordTooShort: true });
			return;
		}
		if(this.props.currentUser.selectedRoles.length === 0) {
			this.setState({ roleMissing: true });
			return;
		}
		if(this.state.userTypeID === 0) {
			this.setState({ userTypeMissing: true });
			return;
		}
		for(var i = 0; i < this.props.users.length; ++i) {
			if(this.state.username === this.props.users[i].username) {
				this.setState({
					usernameTaken: true
				});
				return;
			}
		}
		Actions.createUserFormSubmit(this.state.firstName, this.state.lastName, this.state.username,
			this.state.mobile, this.state.email, this.state.jobTitle, this.state.password, this.state.userTypeID);
		this.setState({
			firstName: "",
    		lastName: "",
    		username: "",
    		mobile: "",
    		password: "",
    		secondPassword: "",
    		loading: true,
    		usernameTaken: false,
			usernameEmpty: false,
			passwordMismatch: false,
			passwordEmpty: false,
			passwordTooShort: false,
			roleMissing: false,
			userTypeMissing: false
		});
		var self = this;
		setTimeout(function(){
			self.setState({
				loading: false
			}); 
		} , 2000);
	};


	this.handleFirstNameChange  = ( e)  => {
		this.setState({ firstName: e.target.value });
	};
	this.handleLastNameChange  = ( e)  => {
		this.setState({ lastName: e.target.value });
	};
	this.handleUsernameChange  = ( e)  => {
		 this.setState({ username: e.target.value });
	};
	this.handleMobileChange  = ( e)  => {
		this.setState({ mobile: e.target.value });
	};
	this.handleEmailChange  = ( e)  => {
		this.setState({ email: e.target.value });
	};
	this.handleJobTitleChange  = ( e)  => {
		this.setState({ jobTitle: e.target.value });
	};
	this.handlePasswordChange  = ( e)  => {
		this.setState({ password: e.target.value });
	};
	this.handleSecondPasswordChange  = ( e)  => {
		this.setState({ secondPassword: e.target.value });
	};
	this.handleUserTypeChange = ( id) => {
		this.setState({ userTypeID: id });
		this.getUserType(id);
	};
	this.getUserType = ( id) => {
		if(id == 1) this.setState({ userType: "Administrator", userIsAdminOrEmployee: true });
		else if(id == 2) this.setState({ userType: "Customer", userIsAdminOrEmployee: false });
		else if(id == 3) this.setState({ userType: "Employee", userIsAdminOrEmployee: true });
		else this.setState({ userType: "", userIsAdminOrEmployee: false });
	};


	};//construct





	render ( ){

		return (
			<form>
				<Row>
					<Col md={6}>

 <Form.Group className="mb-3" >
    <Form.Label>{this.props.t("adminView.firstName")}</Form.Label>
    <Form.Control 
	 type = "text"
                value={this.state.firstName}
                onChange={this.handleFirstNameChange}
	  placeholder={this.props.t("adminView.firstName") + ".."} />
  </Form.Group>



					</Col>
						<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.lastName")}</Form.Label>
    <Form.Control value={this.state.lastName} onChange={this.handleLastNameChange} type="text" placeholder={this.props.t("adminView.lastName") + ".."} />
  </Form.Group>


					</Col>
				</Row>
				<Row>
					<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.username")}</Form.Label>
    <Form.Control value={this.state.username} onChange={this.handleUsernameChange} type="text"  placeholder={this.props.t("adminView.username") + ".."}  />
  </Form.Group>

					</Col>
					<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.mobileNumber")}</Form.Label>
    <Form.Control value={this.state.mobile} onChange={this.handleMobileChange} type="mobile"  placeholder={this.props.t("adminView.mobileNumber") + ".."} />
  </Form.Group>					
						
					</Col>
				</Row>

				<div hidden={!this.state.usernameTaken}>
				<Alert  variant="warning" hidden={!this.state.usernameTaken}>
					<strong> {this.props.t("adminView.usernameTaken")}! </strong>
				</Alert>
				</div>
				<div hidden={!this.state.usernameEmpty}>
				<Alert  variant="warning" hidden={!this.state.usernameEmpty}>
					<strong> {this.props.t("adminView.mustEnter")} {this.props.t("adminView.username")}! </strong>
				</Alert>
				</div>



				<Row>



					<Col md={6}>
	<Form.Group >
    <Form.Label > {this.props.t("adminView.email")}</Form.Label>
    <Form.Control value={this.state.email} onChange={this.handleEmailChange}	type="text" placeholder={this.props.t("adminView.email") + ".."} />
  </Form.Group>					
					</Col>
					<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.jobTitle")}</Form.Label>
    <Form.Control value={this.state.jobTitle} onChange={this.handleJobTitleChange} type="text"  placeholder={this.props.t("adminView.jobTitle") + ".."} />
  </Form.Group>					
						
					</Col>
				</Row>
				<Row>
					<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.password")}</Form.Label>
    <Form.Control value={this.state.password} onChange={this.handlePasswordChange} type="password"  placeholder={this.props.t("adminView.password") + ".."} />
  </Form.Group>


					</Col>
					<Col md={6}>
	<Form.Group >
    <Form.Label >{this.props.t("adminView.confirmPassword")}</Form.Label>
    <Form.Control value={this.state.secondPassword} onChange={this.handleSecondPasswordChange} type="password"  placeholder={this.props.t("adminView.confirmPassword") + ".."} />
  </Form.Group>

					</Col>
				</Row>
				<Alert  variant="warning" hidden={!this.state.passwordEmpty}>
					<strong> {this.props.t("adminView.mustEnter")} + {this.props.t("adminView.password")}! </strong>
				</Alert>
				<Alert  variant="warning" hidden={!this.state.passwordTooShort}>
					<strong> {this.props.t("adminView.passwordLength")}! </strong>
				</Alert>
				<Alert  variant="warning" hidden={!this.state.passwordMismatch}>
					<strong> {this.props.t("adminView.passwordMismatch")}! </strong>
				</Alert>
				<Row>
					<div className="center-width100">


					<ButtonGroup>
				        <Button size="md" value="2" onClick={this.handleUserTypeChange.bind(null, 2)} variant="outline-dark" > {this.props.t("adminView.customer")} </Button>
				        <Button size="md" value="3" onClick={this.handleUserTypeChange.bind(null, 3)} variant="outline-dark"> {this.props.t("adminView.vakiEmployee")} </Button>
				    </ButtonGroup>
				    <strong><p> {this.state.userType} </p></strong>
				 
					</div>
				</Row>
				<Alert  variant="warning" hidden={!this.state.userTypeMissing}>
					<strong> {this.props.t("adminView.mustChooseUserType")}! </strong>
				</Alert>
				<Row>
				<div className="just-width100">
					<Col md={4}>
					
						<SearchDropdown title={this.props.t("adminView.role")} options={this.props.currentUser.roles} 
													view="newUser" type="role" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentUser.selectedRoles}
													view="newUser" type="role" />
						<Alert  variant="warning" hidden={!this.state.roleMissing}>
							<strong> {this.props.t("adminView.mustChooseRole")}! </strong>
						</Alert>
					</Col>
					<Col md={4}>
						<SearchDropdown title={this.props.t("adminView.organisations")} options={this.props.currentUser.organisations} 
													view="newUser" type="organisation" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentUser.selectedOrganisations}
													view="newUser" type="organisation" isAdmin={this.state.userIsAdminOrEmployee} />
					</Col><Col md={4}>
						<SearchDropdown title={this.props.t("adminView.farms")} options={this.props.currentUser.farms}
													view="newUser" type="farmOrg" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentUser.selectedFarms}
													view="newUser" type="farmOrg" isAdmin={this.state.userIsAdminOrEmployee} />
					</Col>
					</div>
				</Row>
				<div className="text-center">
					<Button  variant="primary" onClick={this.onFormSubmit} disabled={this.state.loading} variant="outline-dark">
						{this.props.t("adminView.submit")}
					</Button>
					<div className="spinner" hidden={!this.state.loading}></div>
				</div>
			</form>
		);
	}
};

