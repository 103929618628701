/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 */

'use strict';

import React from 'react';
import {
	Dropdown,
	DropdownButton,
	Button,
	Menu,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
	InputGroup,
	Container,
	Card,
	ButtonGroup
} from 'react-bootstrap';
import BootstrapTable, {Type} from 'react-bootstrap-table-next';
import { Trash, PlusLg } from 'react-bootstrap-icons';
var BT = require( 'react-bootstrap-table');

import Actions from './../../actions/Actions.js';
import VakiAPI from '../../webservice/VakiAPI.js';
import FilterStore from '../../stores/FilterStore';
import RepSubscribeStore from '../../stores/RepSubscribeStore';
import SessionStore from './../../stores/SessionStore.js';
import VConst from './../../utils/VConst.js';
import Immutable from 'immutable';
import Spinner from './../../utils/Spinner.jsx';
import OrgFarmPopPicker from '../Filters/OrgFarmPopPicker.jsx';
import moment from 'moment';
import TranslateStore from './../../stores/TranslateStore.js';

import BGTools from './../../utils/BGtools.js';
import Tooltip from 'rc-tooltip';
import MyPanel from './../../utils/MyPanel.jsx';

var t = TranslateStore.getTranslate();
//require("./PopDefine.css");

var CntData = -1;

/**
 * Component that takes care of displaying the Trend Graph and it's filters.
 */
export default class RepSubscribe extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: FilterStore.getFilter(),
			currSub: RepSubscribeStore.getSubscriptionRecord(),
			subList: [],
			selPeriod: '',
			selDay: ''
		};

		/*************componentWillMount ( ) {**********/
		if (SessionStore.isLoggedOut()) {
			Actions.redirectToLogin();
		}
		RepSubscribeStore.getSubs();
		/********************************************* */

		this.onFilterStoreChange = (_filter) => {
			this.setState({
				filter: _filter,
				trendData: null
			});
		};

		this.onRepSubscribeStoreChangeChange = (_currSub, _subList) => {
			if (_currSub || _subList) {
				if (!_subList) this.setState({ currSub: _currSub });
				else {
					this.setState({
						currSub: _currSub,
						subList: _subList
					});
				}
			} else {
				this.setState({});
			}
		};

		this.onTranslateStoreChange = () => {
			this.setState({});
		};



		this.onDayClick = (e) => {

            if (!e.target.id || e.target.id == "0")
                return;
            Actions.rsubSelectDay(e.target.id);
	
		};

        this.onPeriodClick = (e) =>{


            if (!e.target.id || e.target.id == "0")
                return;
            Actions.rsubSelectPeriod( e.target.id);

        }
	}

	componentDidMount() {
		this.unsubscribeFilterStore = FilterStore.listen(this.onFilterStoreChange);
		this.unsubscribeRepSubscribeStore = RepSubscribeStore.listen(this.onRepSubscribeStoreChangeChange);
		this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
	}

	componentWillUnmount() {
		this.unsubscribeFilterStore();
		this.unsubscribeRepSubscribeStore();
		this.unsubscribeTranslateStore();
	}

	render() {
		var selectEmailRowProp = {
			mode: 'radio', // or checkbox
			clickToSelect: true,
			bgColor: VConst.COLORS.SEGMENT,
			onSelect: Actions.rsubEmailSelected,
			selected: [ RepSubscribeStore.getSelectedEmailIdx() ]
		};

		var selectSubRowProp = {
			mode: 'radio', // or checkbox
			clickToSelect: true,
			bgColor: VConst.COLORS.SEGMENT,
			onSelect: Actions.rsubSubSelected,
			selected: [ RepSubscribeStore.getSelectedSubIdx() ]
		};

		var cellEditProp = {
			mode: 'click',
			blurToSave: true,
			afterSaveCell: Actions.rsubAfterEditEmail
		};

		function formatDay(cell, row) {
			return RepSubscribeStore.getDayText(row.Period, cell);
		}

		function formatEmails(cell, row) {
			var str = '';
			for (var i = 0; i < row.Emails.length; i++) {
				if (str.length > 0) str += '; ';
				str += row.Emails[i];
			}
			return str;
		}

		var emails = [];
		var existSubClass = '';
		var newSubClass = 'collapse';
		var strFarm = '';
		var dayOptions = [];
		var editAnySubClass = '';

		if (this.state.currSub.Idx == -1) {
			//No sub selected for editing
			editAnySubClass = 'collapse';
		} else {
			for (var i = 0; i < this.state.currSub.Emails.length; i++)
				emails.push({ email: this.state.currSub.Emails[i], i: i });

			if (this.state.currSub.Period == 1) {
				for (var i = 0; i < 28; i++)
					dayOptions.push(
						<Dropdown.Item id={i + 1} key={i}>
							{' '}
							{RepSubscribeStore.getDayText(this.state.currSub.Period, i + 1)}{' '}
						</Dropdown.Item>
					);
			} else {
				for (var i = 0; i < 7; i++)
					dayOptions.push(
						<Dropdown.Item id={i + 1} key={i}>
							{' '}
							{RepSubscribeStore.getDayText(this.state.currSub.Period, i + 1)}{' '}
						</Dropdown.Item>
					);
			}

			if (this.state.currSub.Idx == -100) {
				existSubClass = 'collapse';
				newSubClass = '';
			} else {
				var n = FilterStore.getOrgAndFarmNamesByFarmId(this.state.currSub.FarmId);
				strFarm = n.org + ' >> ' + n.farm;
			}
		}

		for (var i = 0; i < this.state.subList.length; i++) {
			if (this.state.subList[i].OrgName == '') {
				var names = FilterStore.getOrgAndFarmNamesByFarmId(this.state.subList[i].FarmId);

				this.state.subList[i].OrgName = names.org;
				this.state.subList[i].FarmName = names.farm;
			}
		}

		//onClick={this.onRowClick}


		let selPeriodStr = RepSubscribeStore.getPeriodText(this.state.currSub.Period);


		let selDayStr = RepSubscribeStore.getDayText(this.state.currSub.Period,this.state.currSub.Day );


/*
						< BT.BootstrapTable data={this.state.subList} selectRow={selectSubRowProp}>
							< BT.TableHeaderColumn dataField="Idx" isKey={true} hidden={true}>
								i
							</BT.TableHeaderColumn>
							<BT.TableHeaderColumn dataField="OrgName">{t('repsub.org')}</BT.TableHeaderColumn>
							<BT.TableHeaderColumn dataField="FarmName">{t('repsub.farm')}</BT.TableHeaderColumn>
							<BT.TableHeaderColumn dataField="Period" dataFormat={RepSubscribeStore.getPeriodText}>
								{t('repsub.period')}
							</BT.TableHeaderColumn>
							<BT.TableHeaderColumn dataField="Day" dataFormat={formatDay}>
								{t('repsub.day')}
							</BT.TableHeaderColumn>
							<BT.TableHeaderColumn dataField="Emails" dataFormat={formatEmails}>
								{t('repsub.email')}
							</BT.TableHeaderColumn>
						</BT.BootstrapTable>

*/

const columns = [
		{
			dataField: 'OrgName',
			text: t('repsub.org')
            
		},
		{
			dataField: 'FarmName',
			text: t('repsub.farm')
		},
		{
			dataField: 'Period',
			text: t('repsub.period'),
			formatter: RepSubscribeStore.getPeriodText
      
		},
		{
			dataField: 'Day',
			text: t('repsub.day'),
			formatter : formatDay
		},
		{
			dataField: 'Emails',
			text: t('repsub.email'),
			formatter: formatEmails
		}
	];

	const rowEvents = {
		onClick: (e, row, rowIndex) => {

			Actions.rsubSubSelected (row);
	

		}
	};

		let TheTable = <BootstrapTable
								keyField="Idx"
								data={ this.state.subList}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped

								
								rowEvents={rowEvents}	
								selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}
							/>




			let TheOtherTable =		<BT.BootstrapTable data={emails} selectRow={selectEmailRowProp} cellEdit={cellEditProp}>
									<BT.TableHeaderColumn dataField="email" editable={true}>
										{' '}
										{t('repsub.entermail')}
									</BT.TableHeaderColumn>
									<BT.TableHeaderColumn dataField="i" isKey={true} hidden={true}>
										i
									</BT.TableHeaderColumn>
								</BT.BootstrapTable>

/*
const otherColumns = [
		{
			dataField: 'email',
			text: t('repsub.entermail'),
			editor:{ type: Type.TEXTAREA }
            
		}
	
];


		let TheOtherTable = <BootstrapTable
								keyField="i"
								data={ emails}
								columns={otherColumns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
								cellEdit={ cellEditFactory({ mode: 'click' }) }
								selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}
							/>
*/

		return (
			<div>
				<Container>
					<Card style={{ padding: '1rem', marginBottom: '1rem' }}>
{TheTable}
						<ButtonGroup>
							<Button onClick={Actions.rsubAddSub} variant="outline-dark">
								<PlusLg />
								{t('repsub.newsub')}
							</Button>

							<Button disabled={!RepSubscribeStore.isDeleteSubEnabled()} onClick={Actions.rsubDeleteSub}  variant="outline-dark">
								{' '}
								<Trash /> {t('repsub.delsel')}{' '}
							</Button>
						</ButtonGroup>
					</Card>
					<div className={editAnySubClass}>
						<Card style={{ padding: '1rem' }}>
							<Card.Body>
								<div className="floatleft mr-3">
									<div className={newSubClass}>
										<OrgFarmPopPicker filter={this.state.filter} hidePopulationPicker />
									</div>
									<div className={existSubClass}>
										<h3> {strFarm} </h3>
									</div>
								</div>
								<div>
									<ButtonGroup style={{ margin: '1rem 1rem 1rem 0rem', float: 'left' }}>
										<DropdownButton title={t('repsub.period')} id={"0"} onClick={this.onPeriodClick}  variant="outline-dark">
											<Dropdown.Item id="1">{RepSubscribeStore.getPeriodText(1)}</Dropdown.Item>
											<Dropdown.Item id="2">{RepSubscribeStore.getPeriodText(2)}</Dropdown.Item>
										</DropdownButton>
										<Form.Control
											value={selPeriodStr}
											readOnly={true}
											className = "mr-3"
										/>
										<DropdownButton title={t('repsub.submitDay')} onClick={this.onDayClick} id={"0"} variant="outline-dark">
											{dayOptions}
										</DropdownButton>
										<Form.Control value={selDayStr} readOnly={true} />
									</ButtonGroup>
								</div>
							</Card.Body>
							<div>

								{TheOtherTable}
			
							</div>
							<div>
								<ButtonGroup>
									<Button onClick={Actions.rsubAddEmail} variant="outline-dark">
										<PlusLg /> {t('repsub.add')}
									</Button>
									<Button
										disabled={!RepSubscribeStore.isDeleteEmailEnabled()}
										onClick={Actions.rsubDeleteEmail}  variant="outline-dark"
									>
										{' '}
										<Trash /> {t('repsub.delsel')}{' '}
									</Button>
								</ButtonGroup>

								<ButtonGroup style={{ float: 'right' }}>
									<Button onClick={Actions.rsubSaveSub} variant="outline-dark"> {t('repsub.save')}</Button>
									<Button onClick={Actions.rsubCancelEdit} variant="outline-dark">{t('repsub.cancel')}</Button>

									<Button onClick={Actions.rsubTestRequest} variant="outline-dark">{t('repsub.reprequest')}</Button>
								</ButtonGroup>
							</div>
						</Card>
					</div>

					<Row />
				</Container>
			</div>
		);
	}
}

/*

    <Input type="select"  placeholder="select" value = {this.state.currSub.Period}  ref="selPeriod"
            onChange={this.handleSelPeriod} label= {t("repsub.period")} >
            
            <option value="1">{RepSubscribeStore.getPeriodText(1)}</option>
            <option value="2">{RepSubscribeStore.getPeriodText(2)}</option>
    </Input>

    <Input type="select"  placeholder="select" ref="selDay" onChange={this.handleSelDay}
        value = {this.state.currSub.Day} label= {t("repsub.submitDay")}>
    {dayOptions}
    </Input>

*/
