/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var React = require("react");
import  {OverlayTrigger}  from "react-bootstrap";
var NavBarStore = require("./../../stores/NavBarStore.js");



export default class avedFiltersIndicator  extends React.Component{
    constructor(props) {
     super(props);
        this.state = {
            show: NavBarStore.filtersSaved()
        }
    };
    UNSAFE_componentWillReceiveProps ( ) {
        this.setState({ show: NavBarStore.filtersSaved() });
    };
    render ( ) {
        if(!this.state.show) return ( <div> </div>);
        else return( 
            <OverlayTrigger placement='top' overlay={<RB.Tooltip>Using saved filters</RB.Tooltip>}> 
                <span className="glyphicon glyphicon-save" aria-hidden="true" id="saved-filters-indicator"></span>
            </OverlayTrigger>
        );
    }
};


