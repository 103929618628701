var React = require("react");
import {Dropdown,DropdownButton , Button, Menu, Accordion, Row, Col, OverlayTrigger, Tooltip,FormControl, Form, Alert} from"react-bootstrap";
var Actions = require("../../../actions/Actions.js");
import SearchDropdown from "./Dropdowns/SearchDropdown.jsx";
import SelectedDropdownOptions from "./Dropdowns/SelectedDropdownOptions.jsx";

var SessionStore = require("../../../stores/SessionStore");

export default class AdminCreateCompForm  extends React.Component{
	constructor(props) {
     super(props);
    	this.state =  {
    		macAddress: "",
        	uploadInterval: 1,
            teamViewerId: 0,
    		teamViewerPassword: "",
    		teamViewerSecondPassword: "",
    		passwordMismatch: false,
    		passwordTooShort: false,
        	farmSelected: false,
        	loading: false
    	};


	this.handleUploadIntervalChange=  ( e) => {
		this.setState({ uploadInterval: e.target.value });
	};
	this.handleMacAddressChange=  ( e) => {
		this.setState({ macAddress: e.target.value });
	};

	this.handleTeamViewerIdChange=  ( e) => {
		this.setState({ teamViewerId: e.target.value });
	};

	this.handleTeamViewerPasswordChange=  ( e) => {
		this.setState({ teamViewerPassword: e.target.value });
	};

	this.handleTeamViewerSecondPasswordChange=  ( e) => {
		this.setState({ teamViewerSecondPassword: e.target.value });
	};
	this.onFormSubmit = ( ) => {

		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}
		/* Security checks to see if something is wrong */
		/* The passwords are optional however so I start by checking if it's empty */
		if( this.state.teamViewerPassword.length > 0) {
			if(this.state.teamViewerPassword.length < 8) {
				this.setState({ passwordTooShort: true });
				return;
			}
			if(this.state.teamViewerPassword !== this.state.teamViewerSecondPassword) {
				this.setState({ passwordTooShort: false, passwordMismatch: true });
				return;
			}
        }






		/* Everything seems fine, submitting the form */
		Actions.createCompFormSubmit(this.state.macAddress, this.state.uploadInterval,  
										 this.state.teamViewerId, this.state.teamViewerPassword);
		this.setState({
			macAddress: "",
        	uploadInterval: 0,
 
            teamViewerId: 0,
    		teamViewerPassword: "",
    		teamViewerSecondPassword: "",
    		passwordMismatch: false,
    		passwordTooShort: false,
        	farmSelected: false,
        	loading: true
		});
		var self = this;
		setTimeout(function(){
			self.setState({
				loading: false
			}); 
		} , 2000);
	};


    };//constructor





    UNSAFE_componentWillReceiveProps (nextProps) {

		if(nextProps.currentComputer.selectedFarms.length > 0) this.setState({ farmSelected: true });
		else if(this.state.farmSelected) this.setState({ farmSelected: false });
	};
	
    render () {

     
		return (
			<form>
				<Row>
					<Col md={6}>
					<Form.Label>{this.props.t("adminView.macAddress")}</Form.Label>
						<Form.Control value={this.state.macAddress} onChange={this.handleMacAddressChange} name=""
							type="text"  placeholder={this.props.t("adminView.macAddress") + ".."} />
					</Col>
					<Col md={6}>
					<Form.Label>{this.props.t("adminView.uploadInterval") + " (hours)"} </Form.Label>
						<Form.Control value={this.state.uploadInterval} onChange={this.handleUploadIntervalChange} name=""
							type="number" placeholder={this.props.t("adminView.uploadInterval") + ".."} />
					</Col>
				</Row>
				<Row>

					<Col md={6}>
					<Form.Label>{this.props.t("adminView.teamViewerID")} </Form.Label>
						<Form.Control value={this.state.teamViewerId} onChange={this.handleTeamViewerIdChange} name=""
									type="number" placeholder={this.props.t("adminView.teamViewerID") + ".."} />
					</Col>
				</Row>
				<Row>

					<Col md={6}>

						<Form.Label>{this.props.t("adminView.teamViewerPassword")}</Form.Label>
												<OverlayTrigger placement='top' overlay={<Tooltip> Defaults to standard team viewer password </Tooltip>}>
						<Form.Control value={this.state.teamViewerPassword} onChange={this.handleTeamViewerPasswordChange} name=""
							type="password"  placeholder={this.props.t("adminView.teamViewerPassword") + ".."} />
						</OverlayTrigger>
					</Col>
					<Alert  variant="warning" hidden={!this.state.passwordTooShort}>
						<strong> {this.props.t("adminView.passwordLength")}! </strong>
                    </Alert>




				</Row>
				<Row>

					<Col md={6}>
					<Form.Label>{this.props.t("adminView.confirmPassword")}</Form.Label>
						<Form.Control value={this.state.teamViewerSecondPassword} onChange={this.handleTeamViewerSecondPasswordChange} name=""
							type="password"  placeholder={this.props.t("adminView.confirmPassword") + ".."} />
					</Col>
					<Alert  variant="warning" hidden={!this.state.passwordMismatch}>
						<strong> {this.props.t("adminView.passwordMismatch")}! </strong>
					</Alert>
				</Row>
				<Row>
				<div className="just-width100">
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.farm")} options={this.props.currentComputer.farms}
														view="newComputer" type="farmOrg" translateTrigger={this.props.translateTrigger}
														disableButton={this.state.farmSelected} />
						<SelectedDropdownOptions selectedOptions={this.props.currentComputer.selectedFarms}
														view="newComputer" type="farmOrg" />
					</Col>
					<Col md={6}>
						<SearchDropdown title={this.props.t("adminView.systems")} options={this.props.currentComputer.systems}
														view="newComputer" type="system" translateTrigger={this.props.translateTrigger} />
						<SelectedDropdownOptions selectedOptions={this.props.currentComputer.selectedSystems}
														view="newComputer" type="system" />
					</Col>
					</div>
				</Row>
				<div className="text-center">
					<Button  variant="primary" onClick={this.onFormSubmit} disabled={this.state.loading}  variant="outline-dark">
						{this.props.t("adminView.submit")}
					</Button>
					<div className="spinner" hidden={!this.state.loading}></div>
				</div>
			</form>
		);
	}
};

//module.exports = AdminCreateCompForm;