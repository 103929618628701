/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";






var React = require("react");
import {Table }  from "react-bootstrap";
var BGTools = require("./../../utils/BGtools.js");
import PropTypes from "prop-types";

var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");

require("./Distribution.css");



/**
 * Displays data from the weight histogram in a tabular format.
 */
export default class LiveWeightTable  extends React.Component{
    static propTypes = {
        weightList: VakiReactPropTypes.weightHistogramList,
        NumFishes: PropTypes.number
    };

  	constructor(props) {
        super(props);

    /**
     * Returns a list of JSX elements that can injected into a table. This is
     * the header of the table. Example:
     *
     *      Bin Size | 0-1 kg | 1-2 kg
     *
     * this would produce:
     *
     *      [<th>Bin Size</th>, <th>0-1 kg</th>, <th>1-2 kg</th>]
     *
     * This array would then be injected into a React render method.
     *
     * @param weightList
     * @returns {Array}
     */
    this.getHeaderRow = ( weightHistogramList) => {


        var headerRowStr = [this.props.t("distribution.binSize")];

        weightHistogramList.forEach(function(entry) {
            // We receive the weight in grams from the API. In the old reporting
            // system this table is displayed in kilos, hence I divide by 1000.
            // Not that this may not be the ideal way to do it if the weight
            // bin size is below 1000g.

             var binHeaderStr = entry.BinStartWeight / 1000 +" - "+ entry.BinEndWeight / 1000;
            headerRowStr.push(binHeaderStr);
        });





        var headerRow = headerRowStr.map(function(columnStr) {

            return (
                <th key={columnStr}>{columnStr}</th>
            );

        });




            return headerRow;

    };



    this.getLiveWeightPortionRow = ( weightHistogramList) => {


        var liveWeightRowStr =   [this.props.t("distribution.pOfBiomass")];


        weightHistogramList.forEach(function(entry) {
            liveWeightRowStr.push(entry.BinBiomassPortion);
        });

        var liveWeightRow = liveWeightRowStr.map(function(columnStr, index) {
            return (
                // Boldface the first column, it's a header in a way
                index === 0 ? <td key={index}><strong>{columnStr}</strong></td> : <td key={index}>{columnStr}</td>
            );
        });

        return liveWeightRow;

    };

    this.getPopulationPortionRow = ( weightHistogramList) => {


        var populationRowStr = [this.props.t("distribution.pOfPop")];

        weightHistogramList.forEach(function(entry) {
            populationRowStr.push(entry.BinPopulationPortion);
        });


        var populationRow = populationRowStr.map(function(columnStr, index) {
            return (
                // Boldface the first column, it's a header in a way
                index === 0 ? <td key={index}><strong>{columnStr}</strong></td> : <td key={index}>{columnStr}</td>
            );
        });

        return populationRow;

    };



        this.getLiveWeightKgRow = ( weightHistogramList, popCount) => {


            var liveWeightRowStr = [this.props.t("distribution.kg")];



            weightHistogramList.forEach(function(entry) {

                if (popCount > 0) {
                    if (entry.BiomassKG == null)
                        liveWeightRowStr.push(0);
                    else
                        liveWeightRowStr.push( BGTools.formatThousandSep(entry.BiomassKG));
                }else
                    liveWeightRowStr.push("");
            });

            var liveWeightRow = liveWeightRowStr.map(function(columnStr, index) {
                return (
                        // Boldface the first column, it's a header in a way
                        index === 0 ? <td key={index}><strong>{columnStr}</strong></td> : <td key={index}>{columnStr}</td>
                );
            });

            return liveWeightRow;


        };







        this.getPopulationCountRow =( weightHistogramList, popCount)=>  {


                var populationRowStr = [this.props.t("distribution.count")];


                weightHistogramList.forEach(function (entry) {

                    if (popCount > 0)
                        populationRowStr.push(BGTools.formatThousandSep(Math.round(popCount * (entry.BinPopulationPortion / 100))));
                    else
                        populationRowStr.push("");
                });

                var populationRow = populationRowStr.map(function (columnStr, index) {
                    return (
                        // Boldface the first column, it's a header in a way
                        index === 0 ? <td key={index}><strong>{columnStr}</strong></td> :
                            <td key={index}>{columnStr}</td>
                    );
                });

                return populationRow;

        };



    };







    render () {



        return (
            <div className= "clsLiveWeightTable ">
            <Table id="distribution-histogram-table "   striped bordered hover>



                <thead  >
                    <tr >
                        {this.getHeaderRow(this.props.weightList)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {this.getLiveWeightPortionRow(this.props.weightList)}
                    </tr>
                    <tr>
                                                {this.getPopulationPortionRow(this.props.weightList)}
                    </tr>
<tr>
{this.getLiveWeightKgRow(this.props.weightList, this.props.NumFishes)}
</tr>
<tr>
{this.getPopulationCountRow(this.props.weightList, this.props.NumFishes)}
</tr>
                </tbody>
            </Table>
                </div>
        )



        

    }
};

