'use strict';

import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, FormControl } from 'react-bootstrap';
import Spinner from './../../utils/Spinner.jsx';
import VakiAPI from '../../webservice/VakiAPI.js';
import SearchDropdownCamera from "./SearchDropdownCamera.jsx"

import Actions from '../../actions/Actions.js';
var UserDataStore = require('./../../stores/UserDataStore.js');

require('./CameraManagement.css');




export default function FarmSelect(props) {

    const [theList, setTheList] = useState([]);
    const [selectedOrph, setSelectedOrph] = useState (-1);
    const [loading , setLoading] = useState (true);
    const [selectedFarm_ID, setSelectedFarm_ID] = useState (-1);


    const FarmListCallback = (obj) => {

    

        setTheList(obj.lst);

        setLoading (false);



	};


	useEffect(() => {

            if (props.Org_ID > 0){

            setLoading (true);   
            VakiAPI.GetAllFarmsForOrg (props.Org_ID, FarmListCallback);


            }

    }, [props.Org_ID]);


      let TheStuff = <div className="center-with100"> No farms found</div>
        if (loading)
            TheStuff = <Spinner/>


  
    let FarmOptions = [];



    if (!loading && theList.length > 0){



        FarmOptions.push (		<option key={0} value={-1}>
			None selected...
		</option>)

      
        for (var i = 1; i<=theList.length; i++){
        
  			FarmOptions.push(
					<option key={i } value={theList[i-1].Farm_ID}>
				
						{theList[i-1].Name }
					</option>
				);





        TheStuff = (	<Form.Group className=" mb-3">
        <div className="farmselect_label">
					<Form.Label className="mb-0"> Select Farm:  </Form.Label>
                    </div>
                    <div id="farmselect">
					<Form.Select
                        size="lg"
						aria-label="select poplation"
						className="mb-0"
						onChange={ (e,r) => {setSelectedFarm_ID(e.target.value); props.OnFarmselect (e.target.value)}}
						className="width100"
						value={selectedFarm_ID}
					>
						{FarmOptions}
					</Form.Select>
                    <div className="farmselect_id">
                    {(selectedFarm_ID <= 0?  "" : "(ID: "+selectedFarm_ID+")" )}
                    </div>
                    </div>
				</Form.Group>)
        }




        
        
        }


        


     

    
/*
<SearchDropdown title={this.props.t("adminView.organisations")} options={this.props.currentUser.organisations} 
					view="newUser" type="organisation" translateTrigger={this.props.translateTrigger} />
*/


	return (
		<div>
            {TheStuff}
		</div>
	);
}
