'use strict';

import React, {useState, useEffect, useRef} from 'react';
import {

	Button,
  Container,
	Row,
	Col,
	Alert,
	Form,
  Collapse

} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
import {QuestionCircle} from 'react-bootstrap-icons';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./CameraManagement.css');
export default function CamList (props) {

	const [camList, setCamList ] = useState([]);
	const [loadingCams, setLoadingCams] = useState (false);
	const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});
	const [openInfo, setOpenInfo] = useState (false);
	const clearAlert = () => {
		setAlertMsg({ msg: '', kind: '' });
	};

	const formatInUse = (cell, row) => {
		let idx = row["Key"];
		
		if (idx<camList.length-1){
			let thisSN = camList[idx].SN;
			
			if (thisSN == camList[idx+1].SN)
				return "";
		}

		return <div className="center-width100 "> <Form.Check	checked={cell} className="mb-4" readOnly/> </div>
		//return cell;
	};



	const columns = [
		{
			dataField: 'SN',
			text: 'S/N'
	
		},
		{
			dataField: 'CamType',
			text: 'Camera type'
	
		},	
		{
			dataField: 'Created_Datetime',
			text: 'Connected'
		},
		{
			dataField: 'Organisation',
			text: 'Organisation'
		},
		{
			dataField: 'Farm',
			text: 'Farm'
		},
		{
			dataField: 'Population',
			text: 'Population'
		},
		{
			dataField: 'In_Use',
			text: 'In use',
			formatter: formatInUse
		},

	];


const GetHistoryCallback = (data, error) => {


	clearAlert();
	setLoadingCams(false)

	if (error){
		setAlertMsg({
			msg: error,
			kind: 'danger'
		});
	}



	if (data){
		let len = data.lst.length;
	
		for (let i = 0; i<len; i++){
			data.lst[i]["Key"]= i;
		}	


		setCamList(data.lst);

	}





}


  useEffect(() =>{

  
	setLoadingCams(true);

      VakiAPI.GetAllCamHistory(
		GetHistoryCallback
			);

     

  }, [])











 





let TheTableArea = "";
if (loadingCams){
    let TheTableArea = <div className="center-width100"> <Spinner/> </div>

}else if(camList ){
	const rowStyle = (row, rowIndex) => {
		let idx = row["Key"];
		
		if (idx<camList.length-1){
			let thisSN = camList[idx].SN;
			
			if (thisSN == camList[idx+1].SN)
				return {color:"silver"};
		}

		
		return {backgroundColor: "Gainsboro"  };
	  };

        TheTableArea = 	
		
							<BootstrapTable
								keyField="Key"
          
								data={camList}
								columns={columns}

								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								rowStyle={rowStyle} 
							//	striped
							
			
							/>
	}






    return (
      <Container>
<hr/>
<hr/>
<Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>
<div className="center-width100 ">
	<h1> All Cameras </h1>
	<p> White rows show earlier/expired camera connections.  Unexpired cameras with neither organisation or farm indicated are 'orphans'  </p>
	</div>



 
{TheTableArea}
					



      </Container>
    );
  
}