/**

 *
 */

 "use strict";

import React from "react";
import { DropdownItem, Row, Col, Button, DropdownButton, Dropdown} from "react-bootstrap";


import BootstrapTable from 'react-bootstrap-table-next';
import moment from "moment";

import FilterStore from "../../stores/FilterStore";
import UserDataStore from "../../stores/UserDataStore";
import SessionStore from "./../../stores/SessionStore.js";
import MeasurementsStore from "./../../stores/MeasurementsStore.js";
import Spinner from "./../../utils/Spinner.jsx";
import OrgFarmPopPicker from "../Filters/OrgFarmPopPicker.jsx";
import MinWeightFilterNew from "./../Filters/MinWeightFilterNew.jsx";
import MaxWeightFilterNew from "./../Filters/MaxWeightFilterNew.jsx";
import NumericInput from "./../Filters/NumericInput.jsx";
import StartEndDatePicker from "./../Filters/StartEndDatePicker.jsx";
import Actions from "./../../actions/Actions.js";
import FileSaver from "file-saver";
import TranslateStore from "./../../stores/TranslateStore.js";
import SavedFiltersIndicator from "./../Filters/SavedFiltersIndicator.jsx";
var t = TranslateStore.getTranslate();
var theTable = ""
var theWell = "";
var dataReceived = false;

export default class  Measurements extends React.Component{
	constructor(props) {
     super(props);
        this.state = {
            Filter: FilterStore.getFilter(),

            measData: null,
            loading: false,
            criteria: null
        };

    /***************componentWillMount ( ) {*****/
        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }
    /******************************************** */

    this.onMeasurementsStoreChange =( newData, filter,  loading, criteria) =>{

        if (!loading)
            loading = false;

        if(loading){
            this.setState({
                measData: null
            })
        }

        this.setState({
            Filter: filter,
            loading: loading
        });

        if (newData){
            dataReceived = true;
            this.setState({
                    measData: newData
                })
        }

        if (criteria){
            this.setState({
                criteria: criteria
            })
        }


    };


    this.doWell =( ) =>{

        if (this.state.criteria == null) {
            theWell = "";
            return;
        }


        var numbers = this.state.criteria.numDays+ " days";
        var downlButton = "";
        if (this.state.measData != null) {
            numbers += ", " + this.state.measData.length + " measurements";
            downlButton = (
                <div className = "text-right mb-3">
                <DropdownButton title="Write to file" id="write-in-file-button" onSelect = {this.selectDownload}  variant="outline-dark" >
                <Dropdown.Item eventKey="1">as CSV file</Dropdown.Item>
                <Dropdown.Item eventKey="2">as TXT file</Dropdown.Item>
                </DropdownButton>
                </div>
                );
        }

        theWell = (<div >

                <div className="well well-sm">

            <div className="text-center">
        <h2> {this.state.criteria.farmName+"/"+ this.state.criteria.popName.trim()} </h2>
        <h4> {moment(this.state.criteria.startDate).format ("YYYY-MM-DD")+ " to "+moment(this.state.criteria.endDate).format ("YYYY-MM-DD") }  </h4>
        <h4> {numbers} </h4>
        {downlButton}


    </div>



                </div>
            </div>);


    };

    this.doTable = ( ) => {

        function timeFormatter (cell, row){
           
            return moment.utc(cell).format ("MMM. DD, YYYY  HH:mm:ss");
        }



const columns = [
		{
			dataField: 'MeasureTime',
			text: 'Time',
            formatter: timeFormatter
		},
		{
			dataField: 'Length',
			text: 'Length(mm)'
		},
		{
			dataField: 'Weight',
			text: 'Weight(g)'
      
		},
		{
			dataField: 'CF',
			text: 'CF'
		},
		{
			dataField: 'CageName',
			text: 'Cage'
		}
	];



        theTable =
							<BootstrapTable
								keyField="key"
								data={ this.state.measData}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
								selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}
							/>


/*
        theTable = <div>
        <BT.BootstrapTable data={ this.state.measData }>
        <BT.TableHeaderColumn dataField='MeasureTime' dataFormat = {timeFormatter} dataSort={true} isKey>Time</BT.TableHeaderColumn>
        <BT.TableHeaderColumn dataField='Length' dataSort={true} >Length(mm)</BT.TableHeaderColumn>
        <BT.TableHeaderColumn dataField='Weight' dataSort={true}>Weight(g)</BT.TableHeaderColumn>
        <BT.TableHeaderColumn dataField='CF' dataSort={true}>CF</BT.TableHeaderColumn>
        <BT.TableHeaderColumn dataField='CageName' dataSort={true}>Cage</BT.TableHeaderColumn>
        </BT.BootstrapTable>
        </div>;
*/

    };



    this.selectDownload = (evt) => {


            try {

                var csv = (evt==1);

                var str = "";
                var csvTab = [];
                var strSep = "\t";
                //var filt = FilterStore.getFilter();
                var strExt = ".TXT";

                if (csv){
                    strSep = ",";
                    strExt = ".CSV";
                    csvTab.push("sep="+strSep);
                }

                var ServerOffsetHours = UserDataStore.getServerTimeOffsetHours();

                str = this.state.criteria.popName.trim()+strSep+this.state.criteria.farmName
                csvTab.push(str);

                str = t("filters.date")+strSep+moment( this.state.criteria.startDate).format("MMM. DD YYYY")+strSep+t("filters.to")+strSep+moment(this.state.criteria.endDate).format("MMM. DD YYYY");
                csvTab.push(str);
                csvTab.push("");
                str = "Time"+strSep+"Length (mm)"   +strSep+ "Weight (g)"+strSep+ "CF"+ strSep+"Cage"
                csvTab.push(str);
                var dataLen = this.state.measData.length;
               

                var CorrOffset = ServerOffsetHours;

                try {
                    var nDT = parseInt(this.state.measData[0].MeasureTime.substring(6));
                    var strLocal = Date().toString();
                    var corr = parseInt(strLocal.split("GMT")[1]) / 100;
                    if (corr != -1 * CorrOffset) {
                        console.warn("Measurements - localtime: " + corr + ", " + CorrOffset);
                        CorrOffset = -1 * corr;
                    }
                } catch (e) {
                    console.warn("Measurements - localtime exception: "  + CorrOffset);
                }
        

                for (var j=0; j<dataLen; j++) {
                    str = "";

                          //We want to display the same time here as it is stored at the server.  There it's stored at the farm's local time
                    var dtM = moment(this.state.measData[j].MeasureTime);
                   
                    dtM = dtM.add(CorrOffset, "hours");
 
                        str +=  dtM.format("YYYY-MM-DD HH:mm:ss")
                            + strSep+ this.state.measData[j].Length
                        + strSep+ this.state.measData[j].Weight
                        + strSep+ this.state.measData[j].CF
                        + strSep+ this.state.measData[j].CageName;
                        csvTab.push(str);


                    //    if (i < (numCols - 1))
                    //        str += strSep
                    //}
                    // csvTab.push(str);


                }
                str = csvTab.join("\r\n");

                /*
                 for (var prop in this.title) {
                 alert ("prop:  "+prop)
                 }
                 */


                var vNames = FilterStore.getOrgAndFarmNamesByFarmId(FilterStore.getSelectedFarmID());
                var now = moment.utc(new Date());

                var fileName = this.state.criteria.farmName+" "+this.state.criteria.popName.trim()+ " "+now.format("YYYY-MM-DD")+strExt;
                FileSaver.saveAs(new Blob([str], {type: 'text/plain'}), fileName);


            } catch (er) {
                alert("Saving meamsurent file:  " + er);
            }




    };

    };

    componentDidMount ( ) {
        this.unsubscribeMeasurementsStore = MeasurementsStore.listen(this.onMeasurementsStoreChange);
    };
    componentWillUnmount ( ) {
        this.unsubscribeMeasurementsStore();
    };







	render ( ) {


        this.doWell();

        if (this.state.loading) {
            theTable = <Spinner/>
        }else  if (dataReceived){
            this.doTable();
            dataReceived = false;
        }



		return (
			<div>
                <Row>
                    <Col className="mb-2">
                    
				    <OrgFarmPopPicker filter={this.state.Filter}  />
                    
                </Col>
                    <Col className="mb-2" >
    <StartEndDatePicker
startDate={moment(this.state.Filter.startDate)}
startDateActionFn={Actions.startDateUpdate}
endDate={moment(this.state.Filter.endDate)}
endDateActionFn={Actions.endDateUpdate} />


  

</Col>

                    <Col  className="mb-2">
    <Button
type = "button"
onClick = {Actions.measFetchData}
block = {"true"}
 variant="outline-dark"
>
Fetch measurements
</Button>


</Col>


<Col  className="mb-2" >
    <SavedFiltersIndicator />
    </Col>

                </Row>

                <Row>
                <Col md={3}>

                </Col>
                </Row>

                {theWell}
				{theTable}
			</div>
		);
	}
};

