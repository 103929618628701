'use strict';

import React from 'react';
import Reflux from 'reflux';
import {
	Dropdown,
	DropdownButton,
	Button,
	Menu,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
	InputGroup,
	Container,
	Card,
	ButtonGroup,
    Tabs, 
    Tab
} from 'react-bootstrap';

import { Trash, PlusLg } from 'react-bootstrap-icons';


import Actions from './../../actions/Actions.js';
import VakiAPI from '../../webservice/VakiAPI.js';
import FilterStore from '../../stores/FilterStore';
import SessionStore from './../../stores/SessionStore.js';
import VConst from './../../utils/VConst.js';
import Immutable from 'immutable';
import Spinner from './../../utils/Spinner.jsx';
import OrgFarmPopPicker from '../Filters/OrgFarmPopPicker.jsx';
import moment from 'moment';
import TranslateStore from './../../stores/TranslateStore.js';
var UserDataStore = require("./../../stores/UserDataStore.js");

import Populations from "./Populations.jsx";
import Cages from "./Cages.jsx";
import CamList from "./CamList.jsx";
import CameraSetup from "./CameraSetup.jsx";

import FarmSelect from "./FarmSelect.jsx";
import OrgSelect from "./OrgSelect.jsx";
var t = TranslateStore.getTranslate();

   var _species = [];


var CameraManagementStore = Reflux.createStore({
 
	init: function() {
          this.listenTo(Actions.camItemPosted, this.onCamItemPosted);
          
          this.listenTo(Actions.showList, this.onShowList);
       //   this.listenTo(Actions.allOrgList, this.onAllOrgList);

         // VakiAPI.GetAllOrgs();

	},


	onShowList: function(show) {
	  
		this.trigger(false, show);
	
	},



	onCamItemPosted: function() {
	
  
		this.trigger(true);

	
	},

/*
onAllOrgList: function (theList){

console.log ("onAllOrgs: "+JSON.stringify (theList))
  this.trigger(theList);

},
*/


});




export default class CameraManagement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            filter: FilterStore.getFilter(),
            farm: -1,
            orgName: FilterStore.getSelectedOrgName(),
            selectedTab: "camerasetup",
            org:FilterStore.getSelectedOrgID(),
            refreshCount: 0,
            showList: 0
	
		};

		if (SessionStore.isLoggedOut()) 
			Actions.redirectToLogin();
        if (!UserDataStore.isUserLoaded()) {   
      
            Actions.redirectToApp();
        }

        this.onSelectTab=(tab)=>{
          if (tab != this.state.selectedTab)
            this.setState({selectedTab:tab});
        }

        this.onUserDataStoreChange = () => {
            this.setState({});
        }

        this.onCameraManagementChange = (needRefresh, show)=>{

          if (needRefresh){
            let n = this.state.refreshCount + 1;
            this.setState({refreshCount:n});
          }else {
            this.setState({showList:show})
          }

        }
       
       this.onCommonFilterChange = (_filter) =>{
         if (_filter)
          this.setState ({filter: _filter, 
                        farm: _filter.selectedFarm,
                         orgName: _filter.selectedOrganisation.Name
                        })

       }

      this.onFarmSelect = (farmId) => {
    
        this.setState ({farm: farmId })

      }

      this.onOrgSelect = (orgId) => {
    
        this.setState ({org: orgId })

      }


    }; //Consructor


	componentDidMount() {
	
        this.unsubscribeCameraManagementStore = CameraManagementStore.listen(this.onCameraManagementChange);
        this.unsubscribeFilterStore = FilterStore.listen(this.onCommonFilterChange);

	}

	componentWillUnmount() {
		this.unsubscribeCameraManagementStore();
    this.unsubscribeFilterStore();
    }



    render () {



/*

      <Tab eventKey="newcamera" title="New camera">
        <NewCamera  farm={this.state.farm} />
      </Tab>
*/

      
      
      let TheTabs = "";

      if (this.state.farm > 0){
        TheTabs =   (<Tabs
      id="TheTabs"
      activeKey={this.state.selectedTab}
      onSelect={this.onSelectTab}
      className="mb-3"
    >
      <Tab eventKey="camerasetup" title="Camera setup">
       <CameraSetup  farm={this.state.farm}  refreshCount = {this.state.refreshCount}/>
      </Tab>

      <Tab eventKey="populations" title="Populations" >
         <Populations  farm={this.state.farm} />
      </Tab>
      <Tab eventKey="cages" title="Cages" >
         <Cages  farm={this.state.farm} />
      </Tab>

    </Tabs>)
      }

    let TheList =  <div/> 
      if (this.state.showList)
          TheList = <CamList/>


/*
        <div className= "just-width100 m-3">
      <OrgSelect  InitialOrg = {FilterStore.getSelectedOrgID()}    OnOrgSelect = {this.onOrgSelect} />
        </div>

        <div className= "just-width100 m-3">
      <FarmSelect  Org_ID = {this.state.org}  OnFarmselect = {this.onFarmSelect} />
        </div>

        <Form.Check checked={this.state.showList} onChange={(e)=>Actions.showList (e.target.checked)}  label="Camera Overview"
						   className="mb-3" />
*/



        return (<div  id="TheTabPage">

<Row>

<Col md={2}>
<Form.Check checked={this.state.showList} onChange={(e)=>Actions.showList (e.target.checked)}  label="Show all cameras"
						   className="mb-3" />
</Col>
<Col  md={5}>


      <OrgSelect  InitialOrg = {FilterStore.getSelectedOrgID()}    OnOrgSelect = {this.onOrgSelect} />
       
      </Col>
      <Col md={5}>
        
      <FarmSelect  Org_ID = {this.state.org}  OnFarmselect = {this.onFarmSelect} />
      </Col>
      </Row>
  


          {TheTabs}
        
        {TheList}
        </div>);

    };


}