/**
 * Created by Bjarni on 31.8.2016.
 */
"use strict";

var Reflux = require("reflux");
var Immutable = require("immutable");

var Actions = require("./../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");







var CountriesFilterStore = Reflux.createStore({
    init: function() {


        this.listenTo(Actions.apiCountriesLoaded, this.onApiCountryList);
        this.listenTo(Actions.apiMyCountriesLoaded, this.onApiMyCountries);

        this.listenTo(Actions.cntryAdd, this.onAdd);
        this.listenTo(Actions.cntryDel, this.onDel);





//        this.fetchUserData();


        this._countries = [];
        this._myCountries = [];
        this._isLoading = false;


    },

    onAdd: function(sel){
        var len = sel.length;
        var changesMade = false;
        for (var i=0; i<len; i++){

                var v = this._myCountries.find(function(country){return country.value == sel[i].value; });
                if (!v){
                    this._myCountries.push(sel[i])
                    changesMade = true;
                   // this._myCountries.push ({value: sel[i].value, text: sel[i].text});
                }

        }

        if (changesMade)
            this.sendMyCountries();

        this.trigger(this._myCountries);

    },

    onDel: function(sel){
        var len = sel.length;
        var changesMade = false;
        for (var i=0; i<len; i++){
                var len2 = this._myCountries.length;
                for (var j=0; j<len2; j++){
                    if (this._myCountries[j].value == sel[i].value){
                        //this._myCountries =
                        this._myCountries.splice(j,1);
                        changesMade = true;
                        break;
                    }
                }
        }
        if(changesMade)
            this.sendMyCountries();

        this.trigger(this._myCountries);


    },

    countries: function (){
        return this._countries;
    },

    myCountries: function (){
        return this._myCountries;
    },

    isLoading: function(){
        return this._isLoading;
    },


    notifyMounting: function(){

        if (this._countries.length > 0)
            return;

        this.fetchCountryList();

    },



    onApiCountryList: function(data) {


        for (var i = 0; i<data.length; i++){
            this._countries.push({
                value: data[i].CountryCode,
                text: data[i].Name
            })
        }


        this.fetchMyCountries();

       // this.trigger(_userData, false);
    },

    onApiMyCountries: function(data){


      //  data = ["IS", "NO"]
        var len = data.length;
        this._myCountries = [];


        function sameCode (country){return country.value == currCode; }

        for (var i=0; i<len;i++) {
            var currCode = data[i];

            this._myCountries.push(this._countries.find(function(country){return country.value == data[i]; }))
        }









        this._isLoading = false;
        this.trigger(this._myCountries, false);

    },



    fetchCountryList: function() {

        this._isLoading = true;
        this.trigger(null, true);
        VakiAPI.getCountryList();
    },

    fetchMyCountries: function() {
        VakiAPI.getMyCountries();
    },

    sendMyCountries: function (){

        var codeArray = [];
        for (var i in this._myCountries)
        {
            codeArray.push (this._myCountries[i].value);
        }

        VakiAPI.postMyCountries(codeArray);
    }

});

module.exports = CountriesFilterStore;
