/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");

var VConst = require("./../../utils/VConst.js");
import NumericInput from "./NumericInput.jsx";
import PropTypes from "prop-types";

export default class  MinCFFilter extends React.Component{
    static propTypes = {
        minCF: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    render () {
       

 
        return (
            <NumericInput 
                value={this.props.minCF}
                label={this.props.t("filters.minCF")}
                minValue={VConst.MIN_ALLOWED_CF}
                maxValue={this.props.maxValue}
                actionFunction={this.props.actionFunction} 
                allowempty = {false}  
                />
        )
    }
};

