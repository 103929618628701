/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var VConst = require("./../utils/VConst.js");
var UserDataStore = require("./UserDataStore.js");
var NavBarStore = require("./NavBarStore.js");

var ParMan = require("./ParMan.js");

var _filter = {}; // Contains the data for filters and current settings
var _userData = {}; // JSON object containing the web service response
var _allPopulationsDict = {}; // All the populations the user has access to, ID as key
var TranslateStore = require("./TranslateStore.js");
var t = TranslateStore.getTranslate();
var initialOL = null;


const startDate_ID = "FS_startDate";
const endDate_ID = "FS_endDate";


const selPop_ID = "FS_SelectedPopulationID";
const selFarm_ID = "FS_SelectedFarmID";
const selOrg_ID = "FS_SelectedOrgID";
const selSegm_ID = "FS_SelectedSegmentID";

const minWeight_ID = "FS_MinWeight"
const maxWeight_ID = "FS_MaxWeight"
const minHourCount_ID = "FS_MinHourCount"




/**
 * Stores the filter data and their current settings. The filters are the thing
 * that we control the parameters for the graphs and dashboard. An example of
 * a filter would be the Organisation/Farm/Population picker. Another example
 * would be Min & Max weight.
 *
 * Note this is probably far from ideal setup. Actually I have no idea if it
 * is. This was my first stab at doing my own React + Reflux stuff.
 *
 * Todo: Rename to CommonFilterStore, maybe sometimes.
 */
var FilterStore = Reflux.createStore({
    init: function () {

  

        // Listen to changes in the UserDataStore
        this.listenTo(UserDataStore, this.onUserDataStoreUpdate);
        // Listen to other actions
        this.listenTo(Actions.organisationClick, this.onOrgClick);
        this.listenTo(Actions.farmClick, this.onFarmClick);
     

        this.listenTo(Actions.populationClick, this.onPopulationClick);
        this.listenTo(Actions.listPopulationClick, this.onListPopulationClick);
        this.listenTo(Actions.minWeightUpdate, this.onMinWeightUpdate);
        this.listenTo(Actions.maxWeightUpdate, this.onMaxWeightUpdate);
        this.listenTo(Actions.startDateUpdate, this.onStartDateUpdate);
        this.listenTo(Actions.endDateUpdate, this.onEndDateUpdate);
        this.listenTo(Actions.dashboardFarmLink, this.onDashboardFarmlink);
        this.listenTo(Actions.minHourCountUpdate, this.onMinHourCountUpdate);
        

        var endDate = moment();
        var startDate = endDate.clone().subtract(30, "days");

        var strStart = ParMan.initParameter(startDate_ID)
        var strEnd = ParMan.initParameter(endDate_ID)


        if (strStart)
            startDate = moment(strStart);

        if (strEnd)
            endDate = moment(strEnd);

        // The initial organisation list, until we've received data from API
        initialOL = [{
            OrganisationID: 0,
            Name: "Organisation",
            FarmList: [{
                FarmID: 0,
                Name: "Farm",
                PopulationList: [{
                    PopulationID: 0,
                    Name: "Population"
                }]
            }]
        }];

        

        try {
            _filter = {
                organisations: initialOL,
                selectedOrganisation: initialOL[0],
                farms: initialOL[0].FarmList,
                selectedFarm: initialOL[0].FarmList[0],
                populations: initialOL[0].FarmList[0].PopulationList,
                selectedPopulation: initialOL[0].FarmList[0].PopulationList[0],
                minWeight: VConst.DEFAULT_MIN_WEIGHT, 
                maxWeight:VConst.DEFAULT_MAX_WEIGHT,
                startDate:startDate.format(VConst.DATE_FORMAT),
                endDate: endDate.format(VConst.DATE_FORMAT),
                MinFishPrHour: 0
            };
        }catch(er){alert ("FilterStore Exc: "+er )}
    },


    saveToParMan: function ()
    {


        ParMan.setItem(selOrg_ID, _filter.selectedOrganisation.OrganisationID);
        ParMan.setItem(selFarm_ID, _filter.selectedFarm.FarmID);
        ParMan.setItem(selPop_ID, _filter.selectedPopulation.PopulationID);
        ParMan.setItem(selSegm_ID, _filter.selectedPopulation.SegmentID);
    },



    onUserDataStoreUpdate: function (newUserData) {

        if (UserDataStore.userJustLoggedIn(true)) {
            this.init();
        }

        if (!newUserData)
            return;

        var organisations = newUserData.get("OrganisationList");
        var b = true;
        if (organisations == null)
            b = false;

        if (!ParMan.isParDefined(minWeight_ID)) {
            _filter.minWeight = ParMan.initParameter(minWeight_ID, VConst.DEFAULT_MIN_WEIGHT, 'number', (NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null));
            _filter.maxWeight = ParMan.initParameter(maxWeight_ID, VConst.DEFAULT_MAX_WEIGHT, 'number', (NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null));
            _filter.MinFishPrHour = ParMan.initParameter(minHourCount_ID, 0, 'number', (NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : 0));
        }

        if (_filter.selectedOrganisation.OrganisationID <= 0){
            //first call
            var sOrg = ParMan.initParameter(selOrg_ID, null, 'number');
            var sFarm = ParMan.initParameter(selFarm_ID, NavBarStore.defaultFarm(), 'number');
            if (sFarm < 0)
                sFarm = null;
            var sPop = ParMan.initParameter(selPop_ID, null, 'number');
            var sSegm = ParMan.initParameter(selSegm_ID, null, 'number');

            if (sSegm == null || sPop == 0) {
                sSegm = null;
                sPop = null;
            }
            if (sPop) { //pop will define both farm and org
                sFarm = null;
                sOrg = null;
            } else if (sFarm)
                sOrg = null;
            if (sOrg || sFarm||sPop){
                var found = false;
                var oLen = organisations.length;
                for (var o = 0; o < oLen && !found; o++){
                    if (sOrg) {
                        if (organisations[o].OrganisationID == sOrg) {
                            _filter.selectedOrganisation = organisations[o];
                            found = true;
                        }
                    } else {
                        var fLen = organisations[o].FarmList.length;
                        for (var f = 0; f < fLen && !found; f++) {
                            if (sFarm) {
                                if (organisations[o].FarmList[f].FarmID == sFarm) {
                                    found = true;
                                    _filter.selectedOrganisation = organisations[o];
                                    _filter.selectedFarm = _filter.selectedOrganisation.FarmList[f];
                                }
                            } else {
                                var pLen = organisations[o].FarmList[f].PopulationList.length;
                                if (sPop) {
                                    for (var p = 0; p < pLen && !found; p++) {
                                        if (organisations[o].FarmList[f].PopulationList[p].PopulationID == sPop && organisations[o].FarmList[f].PopulationList[p].SegmentID == sSegm) {
                                            _filter.selectedOrganisation = organisations[o];
                                            _filter.selectedFarm = _filter.selectedOrganisation.FarmList[f];
                                            _filter.selectedPopulation = _filter.selectedFarm.PopulationList[p];
                                            found = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        

        if (organisations != null && organisations.length > 0) {
            try {
                var findExact = _filter.selectedOrganisation.OrganisationID > 0;

                

                if (organisations.length > 0) {
                    _filter.organisations = organisations;


                    if (findExact) {
                        var result = $.grep(organisations, function (e) {
                            return e.OrganisationID == _filter.selectedOrganisation.OrganisationID;
                        });
                        findExact = (result.length == 1);

                       
                        if (findExact) {
                            _filter.selectedOrganisation = result[0];
                        }
                    }

                    if (!findExact) {
                        _filter.selectedOrganisation = organisations[0];
                    }

                    if (_filter.selectedOrganisation.FarmList.length > 0) {
                        _filter.farms = _filter.selectedOrganisation.FarmList;
                        if (findExact) {
                            var result = $.grep(_filter.farms, function (e) {
                                return e.FarmID == _filter.selectedFarm.FarmID;
                            });
                            findExact = (result.length == 1);
                            
                            if (findExact) {
                                _filter.selectedFarm = result[0];
                            }
                        }


                        if (!findExact) {
                            _filter.selectedFarm = _filter.farms[0];

                        }

                        if (_filter.selectedFarm.PopulationList.length > 0) {
                            _filter.populations = _filter.selectedFarm.PopulationList;

                            if (findExact) {
                                var result = $.grep(_filter.populations, function (e) {
                                    return (e.PopulationID == _filter.selectedPopulation.PopulationID && e.SegmentID == _filter.selectedPopulation.SegmentID);
                                });
                                findExact = (result.length == 1);
                               
                                if (findExact) {
                                    _filter.selectedPopulation = result[0];
                                }
                            }
                           
                            if (!findExact)
                                _filter.selectedPopulation = _filter.populations[0]
                        }
                    }
                }
            } catch (er) {
                alert("Exception onUserDataStoreUpdate: " + er);
            }

       /* Not sure it's used...'
            v = sessionStorage.getItem("FS_StartDate");
            if (v) {
                _filter.startDate = v;
            }

*/


            _allPopulationsDict = getAllPopulationsDict(organisations);
        } else {

            alert ("Error: No organisations found for user!!!");
            this.trigger(null);
            return;
        }
  
        this.trigger(_filter);
    },


    getPopFromCurrFarm: function (popID, segmID){
        var ps = null;

        var popList = _filter.selectedFarm.PopulationList;
        var len = popList.length;
        for (var i=0; i<len; i++){

            if (popList[i].PopulationID == popID && popList[i].SegmentID == segmID)
                return popList[i];

        }

        return ps;

    },



    onOrgClick: function(orgId) {
        // Only trigger if we are selecting a different organisation than is
        // already selected, prevents unnecessary re-renders and API calls
  
        try {
            if (orgId !== _filter.selectedOrganisation.OrganisationID) {

                _filter.selectedOrganisation = this.getOrgByID(orgId)

                if (_filter.selectedOrganisation.FarmList.length > 0) {
  
                    _filter.farms = _filter.selectedOrganisation.FarmList;
                    _filter.selectedFarm = _filter.selectedOrganisation.FarmList[0];
                    _filter.populations = _filter.selectedOrganisation.FarmList[0].PopulationList;
                    _filter.selectedPopulation = _filter.selectedOrganisation.FarmList[0].PopulationList[0];

                    ParMan.setItem(selFarm_ID, _filter.selectedFarm.FarmID);
                    ParMan.setItem(selPop_ID, _filter.selectedPopulation.PopulationID);
                    ParMan.setItem(selSegm_ID, _filter.selectedPopulation.SegmentID);
 
                } else {
              //ATH BG

                    _filter.farms =  initialOL.FarmList;
                    _filter.selectedFarm =  initialOL.FarmList[0];
                    _filter.populations =  initialOL.FarmList[0].PopulationList;
                    _filter.selectedPopulation = initialOL.FarmList[0].PopulationList[0];
 
                    sessionStorage.removeItem(selFarm_ID);
                    sessionStorage.removeItem(selPop_ID);
  
                   /*
                    _filter.selectedFarm = null;
                    _filter.populations = null;
                    _filter.selectedPopulation =null;
                    */
                }

                
                ParMan.setItem(selOrg_ID, _filter.selectedOrganisation.OrganisationID);
                
    
                this.trigger(_filter);
  

            }
        }catch(er){alert ("onOrgClick Exception: "+er)}
    },

    onFarmClick: function (farmID) {
var dbg = 0;

       try{

  
        // Only trigger if we are selecting a different farm than is
        // already selected, prevents unnecessary re-renders and API calls
        if (farmID !== _filter.selectedFarm.FarmID) {
            //First check if this farm belongs to selected organization
            dbg=1;
            var o = this.getOrgForFarm(farmID);
            if (o != _filter.selectedOrganisation) {
                this.onOrgClick(o.OrganisationID);
            }
                
dbg=2;
            _filter.selectedFarm =this.getFarmByID(farmID);
            dbg=21;
            _filter.populations = _filter.selectedFarm.PopulationList;
            dbg=22;
            _filter.selectedPopulation = _filter.selectedFarm.PopulationList[0];
            dbg=23;
            ParMan.setItem(selFarm_ID, farmID);
            dbg=24;
            ParMan.setItem(selPop_ID, _filter.selectedPopulation.PopulationID);
            ParMan.setItem(selSegm_ID, _filter.selectedPopulation.SegmentID);

      dbg = 3;     


            this.trigger(_filter);
       dbg = 4;
        }
         }catch (ex){
             console.error ("onFarmClick exception("+dbg+") : "+ex)
         }
    },

    onDashboardFarmlink: function(organisationID, farmID) {
        for(var i = 0; i < _filter.organisations.length; ++i) {
            if(organisationID === _filter.organisations[i].OrganisationID) {
                var org = _filter.organisations[i];
                for(var j = 0; j < org.FarmList.length; ++j) {
                    if(farmID === org.FarmList[j].FarmID) {
                        var farm = org.FarmList[j];
                        _filter.selectedOrganisation = org;
                        _filter.farms = org.FarmList;
                        _filter.selectedFarm = farm;
                        _filter.populations = farm.PopulationList;
                        _filter.selectedPopulation = farm.PopulationList[0];
                        break;
                    }
                }
              //  Actions.dashboardTrendgraphLink();
                break;
            }
        }

        ParMan.setItem(selFarm_ID, farm.FarmID);
        ParMan.setItem(selPop_ID, _filter.selectedPopulation.PopulationID);
        ParMan.setItem(selSegm_ID, _filter.selectedPopulation.SegmentID);
        this.trigger(_filter);
    },

    onPopulationClick: function(popID) {
        // Only trigger if we are selecting a different population than is
        // already selected, prevents unnecessary re-renders and API calls
        try {
            let thePop = null;

            if (popID.Name ){
                //THen we have the object
                thePop = popID;
            }else{
                thePop = this.getPopulationByID(popID);
            }



            if (thePop.PopulationID !== _filter.selectedPopulation.PopulationID) {

       


                _filter.selectedPopulation = thePop;


                ParMan.setItem(selPop_ID, thePop.PopulationID);
                ParMan.setItem(selSegm_ID, thePop.SegmentID);

                this.trigger(_filter);
            }
        }catch (er){alert ("onPopulationClick Exc: "+er)}
    },

    onListPopulationClick: function(population) {
        try {

            for (var i = 0; i < _filter.organisations.length; ++i) {
                var org = _filter.organisations[i];
                for (var j = 0; j < org.FarmList.length; ++j) {
                    var farm = org.FarmList[j];
                    for (var k = 0; k < farm.PopulationList.length; ++k) {
                        if (population.PopulationID === farm.PopulationList[k].PopulationID) {
                            _filter.selectedOrganisation = org;
                            _filter.farms = org.FarmList;
                            _filter.selectedFarm = farm;
                            _filter.populations = farm.PopulationList;
                            _filter.selectedPopulation = farm.PopulationList[k];

                            this.saveToParMan();

                           // Actions.dashShowAllOrgs();

               
                            this.trigger(_filter);
                            return;
                        }
                    }
                }
            }
        }catch(er){alert ("onLIstPopulationClick Exc:"+er)}
        return;
    },

    onMinWeightUpdate: function(newMinWeight) {
        if (isNaN(newMinWeight))
            newMinWeight = null;
        ParMan.setItem(minWeight_ID, newMinWeight, NavBarStore.saveFilter)
        _filter.minWeight = newMinWeight;

     

        this.trigger(_filter);
    },

    onMaxWeightUpdate: function(newMaxWeight) {
        if (isNaN(newMaxWeight))
            newMaxWeight = null;
        ParMan.setItem(maxWeight_ID, newMaxWeight, NavBarStore.saveFilter);
        _filter.maxWeight = newMaxWeight;
        this.trigger(_filter);
    },


    onMinHourCountUpdate: function(newMinCount) {



        if ( isNaN(newMinCount))
            newMinCount = null;
        ParMan.setItem(minHourCount_ID, newMinCount, NavBarStore.saveFilter);
        _filter.MinFishPrHour = newMinCount;


        this.trigger(_filter);
    },

    onStartDateUpdate: function (newStartDate) {
        if (newStartDate)
            ParMan.setItem(startDate_ID, newStartDate.format(VConst.DATE_FORMAT));

        _filter.startDate = newStartDate;
        this.trigger(_filter);
    },

    onEndDateUpdate: function (newEndDate) {
        if (newEndDate)
            ParMan.setItem(endDate_ID, newEndDate.format(VConst.DATE_FORMAT));
        _filter.endDate = newEndDate;
        this.trigger(_filter);
    },

    getPopulationByID: function (populationID) {
        return _allPopulationsDict[populationID];
    },

    getOrgForFarm: function (farmID) {
        try {
            for (var o = 0; o < _filter.organisations.length; o++) {
                for (var f = 0; f < _filter.organisations[o].FarmList.length; f++) {

                    if (_filter.organisations[o].FarmList[f].FarmID == farmID) {
                        return _filter.organisations[o];
                    }
                }
            }


        } catch (er) {
      
            console.error("Exception in getOrgForFarm(): " + er);
        }
        return _filter.selectedOrganisation;

    },

getOrgByID: function (orgID) {
        try {
            for (var o = 0; o < _filter.organisations.length; o++) {
                if (_filter.organisations[o].OrganisationID == orgID) 
                        return _filter.organisations[o];
          
            }


        } catch (er) {
      
            console.error("Exception in getOrgByIDm(): " + er);
        }
        return _filter.selectedOrganisation;

    },


    getOrgAndFarmNamesByFarmId: function (farmId){
        var names = {
            org:  t("filters.notfound"),
            farm:  t("filters.notfound")
        };


        try {


            for (var o=0; o< _filter.organisations.length;o++){
                for (var f=0; f<  _filter.organisations[o].FarmList.length; f++){

                    if (_filter.organisations[o].FarmList[f].FarmID == farmId){

                        names.org = _filter.organisations[o].Name;
                        names.farm = _filter.organisations[o].FarmList[f].Name;
                        return names;
                    }
                }

            }


        }catch (er){alert ("Exception in getOrgAndFarmNamesByFarmId(): "+er);
                console.error("Exception in getOrgAndFarmNamesByFarmId(): "+er);
        }

        return names;
    },

    getSelectedFarmID: function() {
        var f = 0;
        if (_filter.selectedFarm != null && _filter.selectedFarm !== undefined)
            f = _filter.selectedFarm.FarmID;
        return f;
    },

    getSelectedFarm: function () {
      
        return _filter.selectedFarm;
    },
    getMinWeight: function () {
        return _filter.minWeight;
    },

    getMaxWeight: function () {
        return _filter.maxWeight;
    },

    getSelectedFarmName: function() {
        var f = "";
        if (_filter.selectedFarm)
            f = _filter.selectedFarm.Name.trim();
        return f;
    },

    getSelectedOrgID: function(){
        var f = 0;
        if (_filter.selectedOrganisation != null && _filter.selectedOrganisation !== undefined)
            f = _filter.selectedOrganisation.OrganisationID;
        return f;
    },

    getSelectedOrgName: function(){
        var f = "";
        if (_filter.selectedOrganisation != null && _filter.selectedOrganisation !== undefined)
            f = _filter.selectedOrganisation.Name.trim();
        return f;
    },



    getSelectedPopulation: function(){
        return _filter.selectedPopulation;
    },

    getSelectedPopulationID: function() {

        var p = 0;
        if (_filter.selectedPopulation)
            p = _filter.selectedPopulation.PopulationID;
        return p;

    },

    getSelectedPopulationName: function() {
        var n = "";
        if (_filter.selectedPopulation != null && _filter.selectedPopulation !== undefined)
            n = _filter.selectedPopulation.Name;
        return n;
      },






    getPopulationNameByID: function(id) {
        try {



            for (var i = 0; i < _filter.selectedFarm.PopulationList.length; ++i) {
                if (_filter.selectedFarm.PopulationList[i].PopulationID === id) {
                    return _filter.selectedFarm.PopulationList[i].Name;
                }
            }
        }catch(er){alert ("getPopulationNameByID  Exc: "+er)}
    },


    getFarmByID: function (id) {

            try{
                for (var i = 0; i < _filter.organisations.length; ++i) {
                    for (var j = 0; j < _filter.organisations[i].FarmList.length; ++j) {
                        if (_filter.organisations[i].FarmList[j].FarmID == id) {
                            return _filter.organisations[i].FarmList[j];
                        }
                    }
                }
                } catch (er) { console.log("getFarmByID  Exc: " + er) }

            return null;
    },

    getNumFarms: function(){
        return _filter.selectedOrganisation.FarmList.length;
    },
    
    getFilter: function() {
        return _filter;
    },
    getPopulations: function() {
       return _filter.populations;
    },

    getShowInactive: function(){
        return UserDataStore.showInactive();
    },

    getPopNameByIdx: function(idx){
        if (idx < 0 || _filter.populations.length <= idx)
            return "";
        return _filter.populations[idx].Name;
    },


});






/**
 * Convenience function that receives a organisation list object from VakiAPI and
 * returns a dict of all the populations in it across all organisations and
 * farms so it can be efficiently looked up.
 */
function getAllPopulationsDict(organisationList) {
    var allPopulations = {};

    try {
        organisationList.forEach(function (organisation) {
            organisation.FarmList.forEach(function (farm) {
                farm.PopulationList.forEach(function (population) {
                    allPopulations[population.PopulationID] = population;
                });
            });
        });
    } catch (er) { alert(" getAllPopulationsDict  Exc: " + er) }

    return allPopulations;
}

module.exports = FilterStore;