/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

import React from 'react';
import Reflux from 'reflux';
import {
	Card,
	OverlayTrigger,
	Button,
	ButtonToolbar,
	Tooltip,
	InputGroup,
	FormControl,
	Form,
	Alert,
	Collapse,
	Fade
} from 'react-bootstrap';



var Actions = require('./../../actions/Actions.js');
var ParMan = require('./../../stores/ParMan.js');
var SessionStore = require('./../../stores/SessionStore.js');
var VConst = require('./../../utils/VConst.js');


var BGTools = require('./../../utils/BGtools.js');
require('./Login.css');


var LoginStore = Reflux.createStore({
	init() {
		this.listenTo(Actions.redirectToLogin, this.onShow);
		this.listenTo(Actions.loginSuccessful, this.onHide);
	},

	onShow() {

		this.trigger(true);
	},

	onHide() {
	
		this.trigger(false);
	}
});

/**
 * The view the users sees if not logged in. Displays a simple log in interface.
 * It's planned to add functionality to help a user recovering a password
 * should it have been forgot. When we do that this component will have to be
 * refactored.
 *
 * Potential refinements: display a warning in the same place where we display
 * the wrong user/pass alert when we are waiting for a response from the server.
 * This may be good or even needed for our customers in Chile where response
 * times are higher.
 */
export default class Login extends React.Component {
	//var Login = export default class   extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			wrongUserPass: false,
			show: false
		};

document.getElementsByTagName("body")[0].style["paddingTop"] = "0px"


	/***************componentWillMount() {*******/
		ParMan.clear();
	
	/******************************************* */

		this.keyDown = (event) => {
			if (event.key === 'Enter') this.handleSubmit();
		};

		this.handleSubmit = () => {
			try {
				var username = this.refs.email.value;

				var password = this.refs.pass.value;

				Actions.loginAttempt(username, password);
			} catch (er) {
				alert('handlesubmit1 exc:  ' + er);
			}
			// This may be redundant. I left it in for the scenario where latency
			// is high. If we get the user/pass wrong the first time an alert will
			// be shown. By setting the state here below we remove the alert, this
			// may be visible when the server takes a long while to respond.
			try {
				this.setState({
					wrongUserPass: false
				});
			} catch (er) {
				alert('login handlesubmit1 exc:  ' + er);
			}
		};

		this.handleClose = () => {
			this.setState({ show: false });
		};

		this.onLoginStoreChange = (show) => {
	
			this.setState({ show: show });
		};

		this.onSessionStoreChange = (message) => {
			if (message && message === VConst.WRONG_USER_OR_PASS) {
				this.setState({
					wrongUserPass: true
				});
			}
		};

	
	}

	componentDidMount() {
		
		this.unsubscribeSessionStore = SessionStore.listen(this.onSessionStoreChange);
		this.unsubscribeLoginStore = LoginStore.listen(this.onLoginStoreChange);

		this.setState ({show: true})
	}
	componentWillUnmount() {
	
		this.unsubscribeLoginStore();
		this.unsubscribeSessionStore();

		document.getElementsByTagName("body")[0].style["paddingTop"] = "65px"
	}

	onHideMessageCheck() {
	
		Actions.dashShowAllOrgs();
	}

	/*
    keyDown  (event)  {


        console.log ("In keyDown <<<<<<<<<<<<<<<<<<<< "+this)


        if(event.key === "Enter") this.handleSubmit();
    };
*/

	/*
    handleEmailNorway (){

        window.location.href = "mailto:bard@vaki.no?Subject=Applying%20for%20BMD%20access";

    };
    handleEmailScotland (){

        window.location.href = "mailto:david@vaki.is?Subject=Applying%20for%20BMD%20access";

    };

    handleEmailChile (){

        window.location.href = "mailto:clopez@vaki.cl?Subject=Applying%20for%20BMD%20access";

    };


    handleEmailOther (){

        window.location.href = "mailto:hlynur@vaki.is?Subject=Applying%20for%20BMD%20access";

    };
*/
	handleHide() {
		
		BGTools.createCookie('LGNMSG_1', '1');
	}
	/**
     * Originally this was rendered with react-bootstrap Modal component. I
     * wanted to have the Modal be displayed in the center of the page. I was
     * going to solve it by using CSS3 Flexbox, instead of some ugly and hard
     * to maintain CSS2 hacks. However react-bootstrap sets "style: block;" in
     * the modal so it always overwrites any other CSS selectors. Therefore I
     * switched back to good-old HTML5 for the Modal functionality. It's not
     * really required to do a modal, I just picked it because it looked decent
     * enough.
     */
	render() {
		// Assume we we'll get the user & password right; don't render the alert

		//  var ret =  confirm ("AAAAAAAAAAAAAAA");
		//   alert (ret);



		var authenticationAlert = <div />;
		var loginMessage = <div />;

		if (this.state.wrongUserPass) {
		
			authenticationAlert = <Alert variant="danger">Authentication failed. Access denied!</Alert>;
		}
		loginMessage = '';
		let TheLogin = <div   className = "dummyLogin">+++++++++The Login +++++++++++++</div>;

		if (!(this.state.show || this.props.forceshow))
			return TheLogin;
		
/*
		if (this.state.show || this.props.forceshow){

			TheLogin = (
				
				<Card className="logg" >
					<Card.Header className="center-width100">
						<Card.Title>
							<h1>Log In</h1>
							<h3>Biomass Daily</h3>
						</Card.Title>
					</Card.Header>

					<Card.Body>
						{loginMessage}

						<InputGroup className="mb-3" onKeyDown={this.keyDown}>
							<InputGroup.Text id="basic-addon1">Username</InputGroup.Text>
							<FormControl
								ref="email"
								type="email"
								placeholder="Username"
								aria-label="Username"
								aria-describedby="basic-addon1"
							/>
						</InputGroup>

						<InputGroup className="mb-3" onKeyDown={this.keyDown}>
							<InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
							<FormControl
								ref="pass"
								type="password"
								placeholder="Password"
								aria-label="Password"
								aria-describedby="basic-addon1"
							/>
						</InputGroup>
					</Card.Body>
					<Card.Footer className="center-width100">
						<Button variant="primary" className="btnClass" onClick={this.handleSubmit}>
							Sign in
						</Button>
					</Card.Footer>
				</Card>
		
				
			);
        }
       */


		return  <div className="logg-wrap" > 
		
			<Card className="logg" >
					<Card.Header className="center-width100">
						<Card.Title>
							<h1>Log In</h1>
							<h3>Biomass Daily</h3>
						</Card.Title>
					</Card.Header>

					<Card.Body>
						{loginMessage}

						<InputGroup className="mb-3" onKeyDown={this.keyDown}>
							<InputGroup.Text id="basic-addon1">Username</InputGroup.Text>
							<FormControl
								ref="email"
								type="email"
								placeholder="Username"
								aria-label="Username"
								aria-describedby="basic-addon1"
							/>
						</InputGroup>

						<InputGroup className="mb-3" onKeyDown={this.keyDown}>
							<InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
							<FormControl
								ref="pass"
								type="password"
								placeholder="Password"
								aria-label="Password"
								aria-describedby="basic-addon1"
							/>
						</InputGroup>

						{authenticationAlert}

					</Card.Body>
					<Card.Footer className="center-width100 pt-2">
						<Button variant="primary" className="btnClass" onClick={this.handleSubmit}>
							Sign in
						</Button>
						<div className = "copyRightOnLogin">{"© "}{(new Date().getFullYear())} {VConst.COPYRIGHT}  </div>
					</Card.Footer>
				</Card>
		
				<div className = "acessibility-stuff">
<a href="https://www.essentialaccessibility.com:443/msd">
    <img class="accessibility-img"  src="https://assets.msd-animal-health.com:443/brands/accessibility/img/accessibility-WhiteOnGray.png" alt="Accessibility button"/>
    </a>





    <a href="https://www.msd.com:443/our-commitment-to-accessibility-for-all" class = "accessibility-link">Accessibility</a>

    <a href="https://www.msdprivacy.com/" className = "accessibility-link ">Privacy</a>
	
	<a href="https://www.msd.com/terms-of-use/" className = "accessibility-link ">Terms of use</a>

	<a href="https://vakiiceland.is/sales-and-service-agents/" className = "accessibility-link ">Contact</a>    


</div>


		  </div>;
	}
}
