/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";


var frameConnectionIcon = require("./Antenna_48.png");
var frameVisibilityIcon = require("./Eye_64.png");//require("./Antenna_24x24.png");


/**
 * Convenience function that returns a dictionary/map that maps the X and Y
 * co-ordinates of the where the center of each population should be drawn on
 * the canvas.
 *
 * Hopefully we'll be able to get to the point where users would drag
 * populations and save their location to their own liking. However if no
 * coordinates exists this function serves as a default pattern.
 *
 * The patterns is roughly as follows:
 *
 *      o   o   o        0   1   2
 *        o   o     or     3   4
 *      o   o   o        5   6   7
 *
 * That is, odd numbered rows have one fewer population and is shifted more to
 * the right to create this grid.
 *
 * Example of the structure of the returned map:
 *  {
 *      0: {x: 50, y: 50},
 *      1: {x: 100, y: 50},
 *      2: {x: 150, y: 50}
 *  }
 *
 * So if we'd want to know the coordinates of the first population:
 *
 *      var map = getXandYcoordinatesMap(...);
 *      var xCoord = map[0].x;
 *      var yCoord = map[0].y;
 *
 * @param itemsPerRow number, how many populations we want to draw on even
 * numbered rows.
 * @param totalItems number, how many populations in total we intend to draw
 * @param radius number, the radius of the population to be drawn in pixels
 * @param spacer number, the number of pixels that should be between each
 * population, from the center of one to the center of the next
 * @param margin number, the amount of pixels we want to clear away from the
 * top and left side of the canvas we want to draw on
 * @returns object
 */
function getXandYcoordinatesMap(itemsPerRow, totalItems, radius, spacer, margin) {
    var currLoc = 0;
    var coordMap = {};
    var numberOfRows = getNumberOfRows(itemsPerRow, totalItems);

    // For every row
    for (var row = 0; row < numberOfRows; row++) {
        var oddNumberedRow = row % 2 === 1;
        var numberOfColumns = itemsPerRow;

        if (oddNumberedRow) {
            numberOfColumns -= 1;
        }

        // For every column
        for (var col = 0; col < numberOfColumns; col++) {
            var xPos = margin + radius + (col * spacer);
            var yPos = margin + radius + (row * spacer*0.75);

            if (oddNumberedRow) {
                xPos += spacer / 2;
            }

            coordMap[currLoc] = {
                x: xPos,
                y: yPos
            };

            currLoc += 1;
        }
    }

    return coordMap;
}

/**
 * Returns how many rows we need for the formation grid. For more detailed documentation
 * on the formation see the function getXandYcoordinatesMap().
 *
 * Example with itemsPerRow = 3 and totalItems = 6, the layout will be:
 *
 *      o   o   o
 *        o   o
 *      o
 *
 * but the function will return 3 as we need 3 lines. Note that it is desired
 * behaviour to have itemsPerRow many on even rows, and itemsPerRow-1 on odd rows.
 *
 * This code was originally in that method but I moved it out as I needed to use
 * it in other places as well.
 *
 * @param itemsPerRow how many populations do we want to display on even numbered
 * rows
 * @param totalItems how many populations are we going to display
 * @returns {number} how many rows we need to display the populations
 */
function getNumberOfRows(itemsPerRow, totalItems) {
    return Math.floor((totalItems/itemsPerRow) + 1);
}

/**
 * Receives a PopulationStatus object and returns true if the frame visibility
 * is of acceptable standard, for example algae growth is not blocking fish
 * measurements. Returns false if the the visibility is not okay.
 *
 * @param population {object} PopulationStatus object from VakiAPI
 * @returns {boolean} true if frame visibility is "OK", false otherwise
 */
function isFrameVisibilityGood(population) {
    return population.LastFrameVisibility === "OK";
}

/**
 * Receives a PopulationStatus object and returns true if the wireless radio
 * connection the the frame is working as expected. False otherwise.
 *
 * @param population {object} PopulationStatus object from Vaki API
 * @returns {boolean} true if last connection to the frame's transmission box was
 * reported to be okay. False otherwise.
 */
function isConnectionGood(population) {
    return population.LastConnectionStatus === "OK";
}


/**
 * Returns a CreateJS Bitmap object that represents an icon that indicates
 * frame connectivity. That is, if there are no problems with the wireless
 * connection to the frame (sending box) a bitmap with a working antenna will
 * be returned. Otherwise a bitmap indicating a broken antenna.
 *
 * CreateJS documentation about the Bitmap object:
 *    http://createjs.com/Docs/EaselJS/classes/Bitmap.html
 *
 * @param population {object} PopulationStatus object from Vaki API
 * @param iconSize {number} 0 - 1.0, 0.5 would be 50% of original size
 * @param iconAlpha {number} 0 - 1.0, how transparent should the icon be
 * @returns {createjs.Bitmap}
 */
function getConnectionIcon(population, iconSize, iconAlpha) {

    if (isConnectionGood(population)){return null;}
    var iconBitmap = new createjs.Bitmap(frameConnectionIcon);

    iconBitmap.setTransform(-24, 3, iconSize, iconSize);
    iconBitmap.shadow = new createjs.Shadow("#8a8a8a", 1, 1, 4);
    iconBitmap.alpha = iconAlpha;

    return iconBitmap;

}





/**
 * Returns a CreateJS Bitmap object that represents an icon that indicates
 * tha status of a biomass frame visibility.
 *
 * CreateJS documentation about the Bitmap object:
 *    http://createjs.com/Docs/EaselJS/classes/Bitmap.html
 *
 * @param population {object} PopulationStatus object from Vaki API
 * @param iconSize {number} 0 - 1.0, 0.5 would be 50% of original size
 * @param iconAlpha {number} 0 - 1.0, how transparent should the icon be
 * @returns {createjs.Bitmap}
 */
function getFrameVisibilityIcon(population, iconSize, iconAlpha) {

    if (!isConnectionGood(population)){return null;} // Don't show visibilty icon if bad connection
    if (isFrameVisibilityGood(population)){return null;}


    var iconBitmap = new createjs.Bitmap(frameVisibilityIcon);
   // iconBitmap.setTransform(-23, 14, iconSize, iconSize);

    iconBitmap.setTransform(-24, 0, iconSize, iconSize);
    iconBitmap.shadow = new createjs.Shadow("#8a8a8a", 1, 1, 4);
    iconBitmap.alpha = iconAlpha;

    return iconBitmap;
}

/**
 * Receives a population status object (pretty much straight from Vaki API)
 * and returns an object that represents some style attributes that we then
 * use to determine how to draw each population in the Dashboard.
 *
 * For example this method determines the background, line thickness and other
 * things. Ideally these constants/magic numbers should belong in VConst but
 * they are so specific to the Dashboard report I'm not entirely convince we
 * gain much by relocating them there.
 *
 * @param pop {object} For shape see PopulationStatus in Vaki API documentation
 * @returns {object} See default style below for shape
 */
function getPopulationStyle(pop) {
    /* Gradients for the population background. Two colors, first is the one
     * that starts at the center of the circle. When this was written we'd set
     * it to be ever so slightly darker. Second color is the color at the outer
     * rim of the circle */
    // [innerGradient, outerGradient]
    var greenGradient = ["#82e371", "#a3f794"];
    var yellowGradient = ["#FFE32A", "#ffee22"];
    var redGradient = ["#F57878", "#FF8A8A"];
    var whiteGradient = ["#e6e6e6", "#ffffff"]; // Default, no value

    /* Line style. Numbers are in pixels. See segment parameter: http://bit.ly/1K5xCQZ */
    // CreateJS docs says if you don't want to dash do null or empty array
    var solidStroke = [];       // --------
    var dashedStroke = [10, 5]; // -- -- --
    var dottedStroke = [5, 10]; // -  -  -  -

    /* The color of the stroke (or line) around the population circle */
    var normalGray = "#9e9e9e";
    var black = "#000000";

    // Default style, just in case
    var style = {
        backgroundGradient: whiteGradient,
        strokeStyle: solidStroke,
        strokeColor: normalGray,
        strokeThickness: 2 // in pixels
    };

    if (pop.NumberOfFish24Hr === 0) {
        style.backgroundGradient = redGradient;
        style.strokeStyle = dottedStroke;
        style.strokeColor = black;
        style.strokeThickness = 4;
    }

    if (pop.NumberOfFish24Hr > 0 && pop.NumberOfFish24Hr < 100) {
        style.backgroundGradient = yellowGradient;
        style.strokeStyle = dashedStroke;
        style.strokeColor = normalGray;
        style.strokeThickness = 2;
    }

    if (pop.NumberOfFish24Hr >= 100) {
        style.backgroundGradient = greenGradient;
        style.strokeStyle = solidStroke;
        style.strokeColor = normalGray;
        style.strokeThickness = 2;
    }

    return style;
}

/**
 * Common code and helper methods for drawing and laying out the Dashboard
 * graphics. Most of the code here originates / was refactored from
 * DashboardCanvas.jsx. When we needed to draw multiple dashboards there was
 * more need for a common place like this. Note that if we do venture into
 * doing a lot more of CreateJS/EaselJS drawing it may make sense to abstract
 * and split this file even further but for now it's quite good enough.
 */
var DashboardDrawUtils = {
    getXandYcoordinatesMap: getXandYcoordinatesMap,
    getNumberOfRows: getNumberOfRows,
    isFrameVisibilityGood: isFrameVisibilityGood,
    isConnectionGood: isConnectionGood,
    getConnectionIcon: getConnectionIcon,
    getFrameVisibilityIcon: getFrameVisibilityIcon,
    getPopulationStyle: getPopulationStyle
};

module.exports = DashboardDrawUtils;