/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var VConst = require("./../../utils/VConst.js");
import NumericInput from "./NumericInput.jsx";
import PropTypes from "prop-types";
/**
 * React component for selecting what weight category we want in the
 * Distribution report.
 */
export default class WeightBinFilter  extends React.Component{
    static propTypes = {
        binSize: PropTypes.number,
        actionFunction: PropTypes.func,
        allowempty: PropTypes.bool
    };

    render () {
        return (
            <NumericInput
                value={this.props.binSize}
                label={this.props.t("filters.binSize")}
                minValue={VConst.ABSOLUTE_MIN_BINSIZE}
                maxValue={10000}
                allowempty={true}
                actionFunction={this.props.actionFunction}
                disabled = {this.props.disabled}
                placeholder = 'Auto'

                />
        )
    }
};

