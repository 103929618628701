/*
*  A modal window that pops up when the admin wishes to delete a user
*  Asks for confirmation and then performs the delete action.
*/

var React = require("react");
import {Button, Modal} from "react-bootstrap";
var Actions = require("../../../../../actions/Actions.js");

 export default class ConfirmDeleteModal  extends React.Component{

   	constructor(props) {
		super(props);
  this.deleteClicked = ( ) => {
    if(this.props.type === "User") {
      Actions.userListRowDelete(this.props.data);
      this.props.onRequestHide();
    } else if(this.props.type === "Org") {
      Actions.orgListRowDelete(this.props.data);
      this.props.onRequestHide();
    } else if(this.props.type === "Farm") {
      Actions.farmListRowDelete(this.props.data);
      this.props.onRequestHide();
    }
  };

     };

  render ( ) {
    return(
      <Modal {...this.props} size="sm" title="Delete" animation={false}>
        <div className="modal-body">
          <p> Are you sure you wish to delete {this.props.data.username}{this.props.data.name}? </p>
        </div>
        <div className="modal-footer">
          <Button  variant="primary" onClick={this.deleteClicked.bind(null, this.props.data.name)}>Delete</Button>
          <Button onClick={this.props.onRequestHide}>Close</Button>
        </div>
      </Modal>
    );
  }
};

