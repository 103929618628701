/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";
var FileSaver = require("file-saver");
var React = require("react");

var ReactDOM = require("react-dom");
var moment = require("moment");
var RB = require ("react-bootstrap");

var ImmutablePropTypes = require("react-immutable-proptypes");
var FilterStore = require("../../stores/FilterStore");
var TrendGraphStore = require("./../../stores/TrendGraphStore.js");

var VConst = require("./../../utils/VConst.js");

var BGTools = require("./../../utils/BGtools.js");
var HSUtil = require("./../../utils/HSUtil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var Actions = require("./../../actions/Actions.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
import PropTypes from "prop-types";
var t = TranslateStore.getTranslate();
var trendGraphArea;


var cntButt = 0;


var colClickFunction = null;

var hideExport = false;

var baseFontSize = 14;

/**
 * React component that displays the actual graph in the Trend Graph report.
 */
var  downLoadAsText = function (chart, csv) {
    try {





        var str = "";
        var csvTab = [];
        var strSep = "\t";

        var filt = FilterStore.getFilter();
        if (csv) {
            strSep = ",";
            csvTab.push("sep=" + strSep);
        }

        str = t("filters.date")+strSep+filt.startDate+strSep+t("filters.to")+strSep+filt.endDate;
        csvTab.push(str);



        csvTab.push(t("filters.minWeight")+strSep+filt.minWeight);
        csvTab.push(t("filters.maxWeight")+strSep+filt.maxWeight);


        csvTab.push("");
        if (chart.options.SGR > 0)
            csvTab.push("SGR: "+strSep+chart.options.SGR);

        csvTab.push(t("trendgraph.endWeight")+": "+strSep+chart.options.EndWeight);


        csvTab.push("");
        var numCols = chart.series.length;


        var strIdx = -1;

        var startDate = new Date( chart.xAxis[0].min); //Ath dataMin


        str = chart.xAxis[0].axisTitle.textStr + strSep;
        for (var i=0; i< numCols; i++){
            //check for comments

            if (chart.series[i].name == t("trendgraph.comments"))
                strIdx = i;
            str += chart.series[i].name;
            if (i<(numCols-1))
                str+=strSep
        }
        csvTab.push(str);



        /*
         for (var i = 0; i < numCols; i++) {
         alert ("Lengdir "+this.series[i].name+ ":   "+ this.series[i].data.length)
         }
         */

        var dataLen = chart.series[0].data.length;

        for (var j=0; j<dataLen; j++) {
            str = "";

            str = moment(startDate).format ("YYYY-MM-DD") + strSep;
  
            for (var i = 0; i < numCols; i++) {




                if (chart.series[i].data[j].y) {
                    if (i==strIdx)
                        str += "\""+chart.series[i].data[j].y+ "\"";
                    else
                        str += chart.series[i].data[j].y;
                }

                if (i < (numCols - 1))
                    str += strSep
            }
            csvTab.push(str);

            startDate = new Date (startDate.setDate(startDate.getDate() + 1));
        }
        str = csvTab.join("\r\n");

        /*
         for (var prop in this.title) {
         alert ("prop:  "+prop)
         }
         */


        var strExt = ".TXT";
        if (csv)
            strExt = ".CSV";
        var vNames = FilterStore.getOrgAndFarmNamesByFarmId(FilterStore.getSelectedFarmID());
        var now = moment.utc(new Date());
        var fileName = vNames.farm+" "+(chart.title.textStr.trim())+ " "+now.format("YYYY-MM-DD")+strExt;
        FileSaver.saveAs(new Blob([str], {type: 'text/plain'}), fileName);


    } catch (er) {
        alert("buttonexp:  " + er);
    }
};



export default class  TrendgraphArea  extends React.Component{
    static propTypes = {
        // I'm testing Immutable PropTypes, see:
        // https://github.com/HurricaneJames/react-immutable-proptypes
        //trendGraphData: ImmutablePropTypes.shape(VakiReactPropTypes.trendGraphData)
    };

    constructor(props) {
     super(props);

     this.state= {
            ochange: false,
            fs:14
        }


/**
     * Highchart settings on how we want the graph to be displayed.
     *
     * Note: JSON dates returned by ASP.Net come on the following format:
     *
     *  "/Date(1422489600000+0000)/"
     *
     * This is a bit un-convenient as JavaScript has no native way to parse this
     * to an native Date object. However the third party library moment can parse
     * this, see: http://momentjs.com/docs/#/parsing/asp-net-json-date/
     *
     * @param domNode
     * @param trendData
     */
        this.makeGraph = (domNode, trendData, 
        UsedMinWeightFilter, UsedMaxWeightFilter, populationID,
        maxFish, maxWeight, start, end, aux, tl30, tl60) => {
        //    var averageWeight = [];
        var trendLine = [];
        var fishMeasured = [];
        var conditionFactors = [];
        var temperatures = [];
        var dates = [];
        var comments = [];
        var commentChecks = [];
        var frameTypes = [];
        var depths = [];

        var auxData = null;
        var bookWgts = null;

        colClickFunction = this.props.ColumnClickFunc;
        hideExport = this.props.HideExport;

 
        if (!hideExport)
            hideExport = false;

        if (!trendData || trendData.length == 0) {
            

            console.warn("TrendgraphArea with no data");
            return;
        }



        
        var strFrame =   this.props.t("trendgraph.device");

        var maxAvgWeightYAxis = maxWeight;
        var maxNumberOfFish = maxFish;
        var sgr = trendData[0].SGR;
        var endWeight = Math.round(trendData[trendData.length - 1].TrendLine);
        var startDate = moment.utc(trendData[0].Date);


        // endDate is the last date found in the data list we get
        var endDate = moment.utc(trendData[trendData.length - 1].Date);

        var populationName = FilterStore.getPopulationNameByID(populationID) || "";

        if(this.props.allCages) {

            startDate = moment.utc(start);
            endDate = moment.utc(end);
        }else{
            if (start){
                startDate = start;
            }
            if (end){
                endDate = end;
            }

        }


        if (aux && aux.PopulationID == populationID) {

            if (endDate > aux.firstDate){
                aux["lastDate"] = moment(aux.firstDate).add(aux.data.length, "days");

                if (aux.lastDate > startDate)
                    auxData = aux;
            }
            auxData = aux;
        }




        var subTitle = startDate.format("YYYY-MM-DD") + " to " + endDate.format("YYYY-MM-DD");

        if (UsedMinWeightFilter)
            subTitle += "<br/>Weight filter: " + UsedMinWeightFilter + "g to " + UsedMaxWeightFilter+"g";

        if (this.props.subTitle) {
            subTitle = this.props.subTitle;
        }
        var startDateUnixTime = startDate.valueOf();
        var oneDayInMS = 24 * 3600 * 1000;
        var currentEntryDate = startDate.clone();

        var avgWgtStr = [];

        var period = (endDate-startDate)/oneDayInMS;




        for (var i = 0; i < trendData.length; i++) {
            var measurement = trendData[i]; // To make the code a bit more readable



            var trendDataDate = moment.utc(measurement.Date);


            /*
             *  When showing all cages, charts sometimes start at time period where
             *  no data is available. To avoid starting data entry at the wrong time,
             *  we compare the starting date with the true data starting date. If they
             *  do no match then we skip over the date.
             */


/* Ath betur varðandi moment leiðréttingu á TimeZone*/
            if(trendDataDate.unix() !== currentEntryDate.unix()) {


                if(trendDataDate.unix() > currentEntryDate.unix()){
                    --i;
                    currentEntryDate = currentEntryDate.add(1, "days");
                    //  averageWeight.push([null]);
                    trendLine.push([null]);
                    fishMeasured.push([null]);
                    conditionFactors.push([null]);
                    temperatures.push([null]);
                    comments.push("");
                    commentChecks.push(null);

                    avgWgtStr.push(null);
                    frameTypes.push("");
                    depths.push(null);

                }

                continue;
            }
/**/
            currentEntryDate = currentEntryDate.add(1, "days");


            if (measurement.NumberOfFish>200)
                avgWgtStr.push( {y:measurement.AverageWeight});
            else {
                var o = { y:measurement.AverageWeight, color:'silver' };
                avgWgtStr.push(o);
            }

           if (!measurement.FrameType)
                measurement.FrameType = VakiReactPropTypes.defaultPopulationStatus.FrameType;

            //  averageWeight.push(measurement.AverageWeight);

            frameTypes.push(measurement.FrameType);
            trendLine.push(measurement.TrendLine);

	
			
           depths.push(measurement.FrameDepth);




            if (measurement.FrameType.toUpperCase() == "FR800" ) {
                    var o = {y: measurement.NumberOfFish, color: VConst.COLORS.FISH_MEASURED_LARGEFRAME};
                    fishMeasured.push(o);
                }else if (measurement.FrameType.toUpperCase() == "FR550" ){
                    var o = {y: measurement.NumberOfFish, color: VConst.COLORS.FISH_MEASURED_SMALLFRAME};
                    fishMeasured.push(o);
                }else
                   fishMeasured.push(measurement.NumberOfFish);



            conditionFactors.push(measurement.ConditionFactor);
            temperatures.push(measurement.AverageFrameTemperature);
            if(measurement.Comment === null || measurement.Comment === "") {
                comments.push("");
                commentChecks.push(null);
            } else {

                var strComm;
                if (measurement.Comment.length > 100) {
                    var arr = measurement.Comment.split("\r\n");
                    if (arr.length > 2) {
                        strComm = "...<br>" + arr[arr.length - 2] + "<br/>" + arr[arr.length - 1];

                    } else if (arr.length == 2) {
                        strComm = arr[arr.length - 2] + "<br/>" + arr[arr.length - 1];
                    } else
                        strComm = measurement.Comment.substring(0, 96)+"...";
                    comments.push(strComm);

                }else
                     comments.push(measurement.Comment);
                commentChecks.push(1000);
            }


            var date = moment.utc(measurement.Date);


            dates.push(date.valueOf());
        }


        var strTitle =  this.props.t("trendgraph.graphHeader") + populationName;
        if (this.props.title && this.props.title.length > 0)
            strTitle = this.props.title;





     

   

     

     

        const el = document.querySelector('.HiChartsBaseFontSize');

 

        var fsNum = 14;
        if(el){
             fsNum = parseInt(getComputedStyle(el).fontSize);

            baseFontSize = fsNum;
        }

   

        var objExport = { enabled:false};
        
        if (!hideExport)
            objExport = {
                enabled: true,
                scale: 2,
                sourceWidth: 2280,
                sourceHeight: 1100,
                filename: "BMD_Trend " + populationName + " " + subTitle,
                /*
                chartOptions: {
                    labels: {
                        items: [{
                            html: this.getFormattedLabelText(sgr, endWeight, period, FilterStore.getFilter().minWeight),
                            style: {
                                left: '0px',

                                top: '-70px',

                                fontSize: fsNum * 0.86 + "px"

                            }
                        }]
                    } ,
                }*/
                buttons: {
                    contextButton: {
                        height: 40
                    }
                }
            };




        var chartOptions = {
            chart: {
                renderTo: domNode,
                zoomType: "xy"
            } ,



            title: {
                text: strTitle,
                style: {  fontSize: (fsNum * 1.1) + "px" }
               // x: -60 //center
            } ,

            subtitle: {
                text: subTitle,
                style: {
                    
                    fontSize: fsNum*0.86+"px"
                    
                }
                //x: -60
            } ,

            credits: false,

            navigation: {
                buttonOptions: VConst.CONTEXT_BUTTON_SHAPE

            } ,

            exporting: objExport,




 
            labels: {
                items : [{
                    html : this.getFormattedLabelText(sgr, endWeight),
                    style : {
                        left: '0px',
                        top: '-50px',
                        
                        fontSize: (fsNum * 0.78) + "px",
                        fontWeight: "bold"

                    }
                }]
            } ,



            // Defines a list of the x-axes we want
            xAxis: {
                type: "datetime",
                title: {
                    text: this.props.t("trendgraph.time"),
                    style: {
                        fontSize: (fsNum*0.86)+"px"
                    }
                } ,

                labels: {
                     style: {
                        fontSize: (fsNum * 0.78) + "px"
                    }
                } ,

                min: startDate.utc().valueOf(),
                max: endDate.utc().valueOf()
            } ,

            // Defines a list of the y-axes we want
            yAxis: [
                // Average weight y-axis
                {
                    id: "avgWeightYAxis",
                    // Fix the y-axis to be a minimum of 0, if this is not in place then
                    // the autoscaling could show the y-axis with -100g weight when we
                    // get measurements of 0g (meaning no accurate measurements available).
                    min: 0,
                    max: maxAvgWeightYAxis,
                    title: {
                        text: this.props.t("trendgraph.leftYHeader")
  
                    } ,
                    labels: {
                        //  format: "{value}g"

                        formatter ( ) {
                            return BGTools.formatThousandSep(this.value)+"g";
                        } ,

                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }
                    } ,
                    opposite: true,
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }]
                } ,
                // Number of fish that swam through the frame y-axis
                {
                    id: "fishMeasuredYAxis",
                    title: {
                        text: this.props.t("trendgraph.rightYHeader"),
                        style: {
                            fontSize: (fsNum * 0.86) + "px"
                        }

                    } ,
                    min: 0,
                    max: maxNumberOfFish,

                    labels: {

                        formatter ( ) {
                            return BGTools.formatThousandSep(this.value);
                        } ,
                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }

                    } ,

                   // opposite: true, // put on right side
                    format: "{value} {this.props.t('trendgraph.fish')}"


                } ,
                // Condition factor y-axis
                {
                    id: "conditionFactorYAxis",
                    min: 1,
                    max: 2,
                    opposite: true,
                    title: {
                        text: this.props.t("trendgraph.conditionFactor")
                    }
                } ,
                // Temperature y-axis
                {
                    id: "temperatureYAxis",
                    opposite: true,
                    format: "{value} °C",
                    min: 0,
                    max: 30,
                    title: {
                        text: this.props.t("trendgraph.temperature")
                    }
                } ,
                {
                    id: "comments",
                    opposite: true,
                    min: 1000,
                    title: {
                        text: "Comments"
                    }
                } ,
                {
                    id: "depthYAxis",
                    opposite: true,
                    min: 0,
                    max: 40,
                    title: {
                        text: "Depth"
                    }
                }
            ],




            tooltip: {
                shared: true,

 

                //Be careful about the position. if too close to point it prevents the click events
                positioner (w, h, point) {


                    var tooltipX, tooltipY;
                    tooltipX = point.plotX + chart.plotLeft -w - 30;
                    if (point.plotX < w*0.67)
                        tooltipX = point.plotX + chart.plotLeft  + 30;
                    tooltipY = (chart.plotHeight/2)+chart.plotTop ;
                    return{  
                        x: tooltipX,
                        y: tooltipY
                    };
                } ,

        







                formatter ( ) {
                    if(this.points === undefined) return;
                   
                    var comment = "", color = "";
                    // alert (this.points.length+ "  "+this.points[0].point.index+"    "+ frameTypes[this.points[0].point.index]+"   " +fishMeasured[this.points[0].point.index]);
                    var date = moment.utc(this.x);

                    // var format = '<span style="font-size: 10px">' + date.format("dddd MMMM Do, YYYY") + '</span><br/>';
                    var format = '<span style="font-size: ' + baseFontSize*0.75 + 'px">' + date.format("ddd MMM Do, YYYY") + '</span><br/>';
                    comment = "<b>"+strFrame + ": </b>" + frameTypes[this.points[0].point.index] +"<br/>";
                    if(comments[this.points[0].point.index] !== "") {

                        comment+= "<b> Comment: </b><br />" + comments[this.points[0].point.index];

                    }
                    for(var i = 0; i < this.points.length; ++i) {
                        var extra = "";
                        if(this.points[i].series.name === t("trendgraph.fishMeasured")) {
                            color = '<span style="color:rgba(192, 192, 192, 0.5)"' + ">● </span>"
                        } else if (this.points[i].series.name === t("trendgraph.temperature")) {
                            color = '<span style="color:#F5A05B"' + ">● </span>";
                            extra = " °C";
                        } else if(this.points[i].series.name === t("trendgraph.conditionFactor")) {
                            color = '<span style="color:#91eb7c"' + ">● </span>";
                        } else if(this.points[i].series.name === t("trendgraph.averageWeight") ) {
                            color = '<span style="color:#7cb5eb"' + ">● </span>";
                            extra = "g";
                        } else if(this.points[i].series.name === t("trendgraph.trendline")) {
                            color = '<span style="color:#b34442"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.depthonframe")) {
                            color = '<span style="color:#7fffd4"' + ">● </span>";
                            extra = "m";
                        }else if(this.points[i].series.name === t("Aux.Weight Data")) {
                            color = '<span style="color:#F771F9"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.tl30")) {
                            color = '<span style="color:#8000FF"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.tl60")) {
                            color = '<span style="color:#0000FF"' + ">● </span>";
                            extra = "g";
                        }













                        format +=  color + '<span style="font-size: ' + baseFontSize*0.7 + 'px">' +this.points[i].point.series.name + ": <b>"
                            + this.points[i].point.y + extra + "</b></span><br/>";




                    }
                    return '<div>' + format + comment + '</div>';
                }

            } ,

            plotOptions: {
                series: {
                    cursor: "pointer",

                    events: {

                        legendItemClick () {

                            if(typeof(Storage) !== "undefined") {
                                window.sessionStorage.setItem("TrGraph"+this.index,!this.visible );
                            }


                            //return false;
                            // <== returning false will cancel the default action
                        }
                    } ,

                    point: {
                        events: {
                            click ( e) {

                                var pop = FilterStore.getPopulationByID(populationID);
                              
                                var date = moment.utc(this.x);

                                if (e.ctrlKey || e.shiftKey){


                                    var str = date.format("YYYY-MM-DD");
                                    var theComment = prompt("Comment on "+str, "");
                                    if (theComment != null && theComment !=""){
                                        var newComment = {
                                            FarmID: FilterStore.getSelectedFarmID(),
                                            PopulationID:  populationID,
                                            PertinentDateTime:str,
                                            CommentText: theComment

                                        };

                                        Actions.addComment(newComment);


                                    }

                                    return false;
                                }

                                if (colClickFunction) {
                                                                 

                                    colClickFunction(pop, date.format());
                                } else {

                                    Actions.populationClick(pop);
                                    Actions.distDateUpdate(date);
                                    Actions.trendgraphDistributionLink();
                                }
                            }
                        }
                    }

                } ,

                scatter: {
                    marker: {
                        radius: 8,
                        symbol: "diamond"
                    }
                }
            } ,

            legend: {

                itemStyle: {
                    fontSize: (fsNum * 0.86) + "px",
                    fontWeight: "normal"
                } ,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0
            } ,

            series: [


                {
                    index: 0,

                    visible: BGTools.readBoolStorage("TrGraph0", true),
                    name: this.props.t("trendgraph.fishMeasured"),
                    
           
                    fontSize: "5px",
                    data: fishMeasured,
                    type: "column",
                    color: VConst.COLORS.FISH_MEASURED,
                    yAxis: "fishMeasuredYAxis",
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    }
                } ,
                {
                    index: 1,
                    visible: BGTools.readBoolStorage("TrGraph1", true),
                    name: this.props.t("trendgraph.temperature"),
                    data: temperatures,
                    yAxis: "temperatureYAxis",
                    type: "spline",
                    color: VConst.COLORS.TEMPERATURE,
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    } ,
                    tooltip: {
                        valueSuffix: " °C"
                    }

                } ,


                {
                    index: 2,
                    visible: BGTools.readBoolStorage("TrGraph2", true),
                    name: this.props.t("trendgraph.conditionFactor"),
                    yAxis: "conditionFactorYAxis",
                    type: "spline",
                    color: VConst.COLORS.CONDITION_FACTOR,
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    } ,
                    data: conditionFactors
                } ,
                {
                    index: 3,

                    visible: BGTools.readBoolStorage("TrGraph3", true),
                    name: this.props.t("trendgraph.averageWeight"),
                    yAxis: "avgWeightYAxis",
                    type: "spline",
                    color: VConst.COLORS.AVG_WEIGHT,
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    tooltip: {
                        valueSuffix: "g"
                    } ,

                    data: avgWgtStr
                } ,
                {
                    index: 4,
                    visible: BGTools.readBoolStorage("TrGraph4", true),
                    name: this.props.t("trendgraph.trendline"),
                    data: trendLine,
                    yAxis: "avgWeightYAxis",
                    color: VConst.COLORS.TREND_LINE,
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    } ,

                    tooltip: {
                        valueSuffix: "g"
                    }
                } ,



                {
                    index: 5,
                    visible: BGTools.readBoolStorage("TrGraph6", true),
                    name: t("trendgraph.depthonframe"),
                    data: depths,
                    color: VConst.COLORS.DEPTH,
                    yAxis: "depthYAxis",
                    type: "line",
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    } ,
                } ,
                {
                    index: 6,
                    visible: BGTools.readBoolStorage("TrGraph5", true),
                    name: "Comments",
                    data: commentChecks,
                    commentStrings: comments,
                    color: VConst.COLORS.COMMENT,
                    yAxis: "comments",
                    type: "scatter",
                    pointStart: startDateUnixTime,
                    pointInterval: oneDayInMS

                    /*
                     * The right way of displaying comments, to be added
                     * once the new version of high charts has been figured out.
                     ,
                     tooltip: {
                     pointFormatter ( ) {
                     return "<b>" + comments[this.index] + "</b>";
                     }
                     } */
                }
            ]
        };


        if(auxData != null){
            var idx = chartOptions.series.length;

            chartOptions.series.push(
                {
                    index: idx,

                    visible: BGTools.readBoolStorage("TrGraph"+idx.toString(), true),
                    name: "Aux.Weight Data",
                    yAxis: "avgWeightYAxis",
                    type: "spline",
                    color: VConst.COLORS.AUX_WGT_DATA,
                    pointStart: auxData.firstDate.valueOf(),
                    pointInterval: oneDayInMS,
                    tooltip: {
                        valueSuffix: "g"
                    } ,
                    data: auxData.data,
                    marker: {
                        enabled: false
                    } ,

                }

            )
        }

		if (tl30){
			var idx = chartOptions.series.length;

            chartOptions.series.push(
                {
                    index: idx,

                    visible: BGTools.readBoolStorage("TrGraph"+idx.toString(), true),
                    name: tl30.name,
                    yAxis: "avgWeightYAxis",
                    
                    color: VConst.COLORS.TL30,
					
                    pointStart: moment.utc(tl30.tl.FirstDayWithData).valueOf(),
                    pointInterval: oneDayInMS,
                    tooltip: {
                        valueSuffix: "g"
                    } ,
                    data: tl30.data,
                    marker: {
                        enabled: false
                    } ,
                }

            )
			
		}

		if (tl60){
			var idx = chartOptions.series.length;

            chartOptions.series.push(
                {
                    index: idx,

                    visible: BGTools.readBoolStorage("TrGraph"+idx.toString(), true),
                    name: tl60.name,
                    yAxis: "avgWeightYAxis",
                    
                    color: VConst.COLORS.TL60,
					
                    pointStart: moment.utc(tl60.tl.FirstDayWithData).valueOf(),
                    pointInterval: oneDayInMS,
                    tooltip: {
                        valueSuffix: "g"
                    } ,
                    data: tl60.data,
                    marker: {
                        enabled: false
                    } ,
                }

            )
			
		}
		

        chartOptions["SGR"] = sgr;
        chartOptions["EndWeight"] = endWeight;
try {

    // Draw the graph with our given options
    var chart = new Highcharts.Chart(chartOptions, function (chart) {
        Actions.trendGraphDoneLoading();
    });







/*
    var found = BGTools.isHCTextButton("trendTXT");
	
	found = BGTools.isHCTextButton("trendCSV");
	*/



        BGTools.clearMyHiChartsButtons();

        var found = false;

        if (!found) {
            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-trendTXT",
                text: t("misc.textfilebuttontext"),
                // style: {color: "green"} ,
                onclick () {
                    return downLoadAsText(this, false);

                }
            });
            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-trendCSV",
                text: t("misc.textfilebuttoncsv"),
                // style: {color: "green"} ,
                onclick () {
                    return downLoadAsText(this, true);
                }
            });

        }
    






}catch (er){
    console.error("drawing trend graph: "+er)
}

        HSUtil.hideHighchartYAxis(chart, "conditionFactorYAxis");
        HSUtil.hideHighchartYAxis(chart, "temperatureYAxis");
        HSUtil.hideHighchartYAxis(chart, "comments");
        HSUtil.hideHighchartYAxis(chart, "depthYAxis");
    };//makeGraph



    };//Construct

    componentDidMount ( ) {

        var domNode = ReactDOM.findDOMNode(this)
        trendGraphArea = this;

//        this.makeGraph(domNode, this.props.trendGraphData.get("PopulationTrendDataList"),
//        this.props.trendGraphData.get("PopulationID"), this.props.maxNumberOfFish,


        this.makeGraph(domNode, this.props.trendGraphData.PopulationTrendDataList, this.props.trendGraphData.UsedMinWeightFilter, this.props.trendGraphData.UsedMaxWeightFilter,
            this.props.trendGraphData.PopulationID, this.props.maxNumberOfFish,
            this.props.maxAvgWeight, this.props.startDate, this.props.endDate, this.props.aux, this.props.tl30, this.props.tl60);
    };

    shouldComponentUpdate ( nextProps, nextState) {



        if(TrendGraphStore.fetchingData()){
             return false;}

          
        var domNode = ReactDOM.findDOMNode(this);

        this.makeGraph(domNode, nextProps.trendGraphData.PopulationTrendDataList, this.props.trendGraphData.UsedMinWeightFilter, this.props.trendGraphData.UsedMaxWeightFilter,
            nextProps.trendGraphData.PopulationID, nextProps.maxNumberOfFish,
            nextProps.maxAvgWeight, nextProps.startDate, nextProps.endDate, nextProps.aux, nextProps.tl30, nextProps.tl60);
        // Always return false because we have no need for React to re-render,
        // we hand over the re-rendering to HighChart by calling makeGraph()
        // and passing in the DOM node.

        return false;
    };



    /**
     * Convenience function that formats a label text for a HighChart graph.
     *
     * Code in the HighChart configuration object was growing pretty big so I
     * decided to pull this out. This function returns the text that is intended
     * to be placed at the top of the graph displaying some summary information
     * for the given graph like so:
     *
     *      SGR: 0.55
     *      End weight: 2356g
     *
     * At a meeting Hermann requested that if the SGR is below 0 we would not show
     * it at all since the data would in that case most likely be unreliable.
     *
     * @param sgr number - float for specific growth rate
     * @param endWeight number - integer, in grams
     * @returns {string} The formatted string ready to be placed into a HighChart
     * graph as a HTML label.
     */
    getFormattedLabelText (sgr, endWeight, period, minWgt) {

        if (sgr == "")
            return "";

        var resultStr = this.props.t("trendgraph.endWeight") + ": "+ endWeight +"g";

        if (sgr > 0) {
            resultStr = "SGR: "+ sgr.toFixed(2) +"<br>" + resultStr;
        }

        if (period)
            resultStr += "<br>Period: "+period+" days";
        if (minWgt)
            resultStr += "<br>Weights > "+minWgt+"g";

        return resultStr;
    };
    



    render () {



        var divStyle = {
             width: "100%"
           /*  height: "550px"*/
            
        };
      
        if (this.props.height) {
            divStyle["height"] = this.props.height;
        }


        






        var theBody = document.getElementsByTagName("BODY")[0];

               
        var fs =
            (
                theBody.currentStyle ||
                (window.getComputedStyle && getComputedStyle(theBody, null)) ||
                theBody.style
            ).fontSize;



        var el1 = document.querySelector('.clsFFF');

    

       /*
            <div id="ChartWrap"    style={divStyle}>Loading..
               

                </div >
*/

        return (

            <div id="ChartWrap"    style={divStyle}>Loading..
               

                </div >



        )
    };
};



