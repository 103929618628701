
"use strict";

var React = require("react");
import {Container, Row, Col} from "react-bootstrap";
var moment = require("moment");
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var UserDataStore = require("./../../stores/UserDataStore.js");
var VConst = require("./../../utils/VConst.js");
var t = TranslateStore.getTranslate();
require("./Dashboard.css")

export default class  DasHelp extends React.Component{
  



    componentDidMount () {

        
        UserDataStore.postPageHit(VConst.PAGEID.DASHBOARDHELP);

        this.setState({});
        //   createDashboardCanvas(domNode, this.props.populationStatusList);
    };

    onClick ( ) {
        var el = document.getElementById("divHelpOverlay");
        if (el)
            el.style.display='none';

        this.setState ({});
    };

    render () {
  

        var strGeneral1 = "The blocks intended to give good overview about each population's current status and provite access to some basic data operation."
        var strGeneral2 = "The bacground colors of each block indicate the rate of fish measurements and/or status of data transfer from frame to base computer"
        var strHelp = "Click on the question mark to bring up this help page";


        return (
            <div   id="divHelpOverlay"
                 onClick={this.onClick}
                 >

                <div id="divHelpContent" className= "clsHelpContent">
                <Container>
                    <Row>
                            <h3>{t("dashboard.hlpGenHeader")}</h3>
                        {t("dashboard.hlpGen1")} <br />{t("dashboard.hlpGen2")}
                    </Row>
                    <div id="tttTT" className="clsHelpBody">
                    <Row>

                        <Col lg={4} md={4} sd={12}>
                                    <h4>{t("dashboard.colorCoding")}:</h4>
                                    <div>{t("dashboard.colorDesc")}</div>
                               <div className="text-left">
                            <ul>
                                <li><strong>{t("dashboard.green")}</strong>: {t("dashboard.greenFish")}.</li>
                                <li><strong>{t("dashboard.yellow")}</strong>: {t("dashboard.yellowFish")}.</li>
                                <li><strong>{t("dashboard.red")}</strong>: {t("dashboard.redFish")}.</li>
                            </ul>
                               </div>

                    <img src="img/PopBlock.png" />
                        </Col>

                      <Col md={8} lg={8} sd={12}>
                          <h4>{t("dashboard.hlpFldHeader")}</h4>
                            <div className="text-left">
                                  <ul>
                                <li><strong>1)</strong>: {t("dashboard.hlpFld1")}</li>
                                <li><strong>2)</strong>: {t("dashboard.hlpFld2")}</li>

                                <li>
                                <strong>3)</strong>: {t("dashboard.hlpFld3")}
                                      <ul>
<li> <img src="img/Antenna_32.png" />: {t("dashboard.disconnectSign")}.</li>
<li><img src="img/Eye_32.png" />: {t("dashboard.badVisibilitySign")}.</li>

                                      </ul>


                                </li>
                                <li><strong>4)</strong>: {t("dashboard.hlpFld4")} <br /> {t("dashboard.hlpFld4_1")} </li>
                                            <li><strong>5)</strong>: {t("dashboard.hlpFld5")} <br /> {t("dashboard.hlpFld5_1")} <br /> {t("dashboard.hlpFld5_2")} <br /> {t("dashboard.hlpFld5_2a")}: <img src="img/AvgWgtStarv.png" /> <br /> {t("dashboard.hlpFld5_3")}
                                            </li>
                                  </ul>
                            </div>
                      </Col>

                    </Row>
                            <Row>
                               
                          <div className="text-left marginleft">
                                    <ul>




                                        
                                        <li>
                                            
                                            <img className = "clsIntent" src="img/ConfidenceLevelIcon.png" />: {t("dashboard.hlpFld5_4")}
                                            <br /> {t("dashboard.hlpFld5_5")}

                                        </li>

                                <li><strong>6)</strong>: {t("dashboard.hlpFld6")} <br /> {t("dashboard.hlpFld6_1")} <br /> {t("dashboard.hlpFld6_2")} <br /> {t("dashboard.hlpFld6_3")} </li>
                                <li>
                                <strong>7)</strong>: {t("dashboard.hlpFld7")}
                                      <img src="img/comment.png" />{t("dashboard.hlpFld7_1")}

                                </li>
                                <li><strong>8)</strong>: {t("dashboard.hlpFld8")}   <img src="img/if_check-mark_511949.png" /> </li>
                                <li><strong>9)</strong>: {t("dashboard.hlpFld9")}</li>
                              </ul>
                          </div>


                    </Row>
                    </div>

<Row>

                     <div className="text-center">
     <br />{t("dashboard.hlpHide")}

                     </div>

    </Row>
</Container>


</div>



            </div>

        )
    }
};

