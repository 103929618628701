"use strict";

import  React from "react";
import  ReactDOM from "react-dom";
import  moment from "moment";
import  ImmutablePropTypes from "react-immutable-proptypes";
import  Spinner from "./../../utils/Spinner.jsx";
import  VConst from "./../../utils/VConst.js";
import  VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import  CF_ChartStore from "./../../stores/CF_ChartStore.js";
import  Actions from "./../../actions/Actions.js";
import  BGTools from "./../../utils/BGtools.js";
import PropTypes from "prop-types";

export default class CF_Chart  extends React.Component{

    static propTypes = {

        CfChartData: PropTypes.any

    };


    constructor(props) {


     super(props);
        this.state = {
            loading: false
        };


    this.onCF_ChartStoreChange = ( newChartData, loadingMsg, apiErrorMsg) => {

        var err = null;

        if (!(typeof apiErrorMsg === "undefined"))
            err = new String (apiErrorMsg);



        if (!loadingMsg) {
            this.setState({
                loading: false,
                chartData: newChartData.CfData,
                apiError: err

            });
        }else {
            this.setState({
                loading: true,
                chartData: null,
                apiError: err
            });
        }




    };



    this.makeGraph = ( chartData)=>{


        var domNode = ReactDOM.findDOMNode(this.refs.chartArea);

         if(!chartData) {
             domNode = (<div> </div>) ;
             return;
         }
         chartData.chart.renderTo = domNode;

        
        var chart = new Highcharts.Chart(chartData);
		BGTools.clearMyHiChartsButtons();
        
        /*
        var chart = Highcharts.Chart('Heat', {

             chart: {
                 type: 'heatmap',
                 marginTop: 40,
                 marginBottom: 80,
                 plotBorderWidth: 1
             };


             title: {
                 text: 'Sales per employee per weekday'
             };

             xAxis: {
                 categories: ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas', 'Maria', 'Leon', 'Anna', 'Tim', 'Laura']
             };

             yAxis: {
                 categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                 title: null
             };

             colorAxis: {
                 min: 0,
                 minColor: '#FFFFFF',
                 maxColor: Highcharts.getOptions().colors[0]
             };

             legend: {
                 align: 'right',
                 layout: 'vertical',
                 margin: 0,
                 verticalAlign: 'top',
                 y: 25,
                 symbolHeight: 280
             };

             tooltip: {
                 formatter () {
                     return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> sold <br><b>' +
                         this.point.value + '</b> items on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
                 }
             };

             series: [{
                 name: 'Sales per employee',
                 borderWidth: 1,
                 data: [[0, 0, 10], [0, 1, 19], [0, 2, 8], [0, 3, 24], [0, 4, 67], [1, 0, 92], [1, 1, 58], [1, 2, 78], [1, 3, 117], [1, 4, 48], [2, 0, 35], [2, 1, 15], [2, 2, 123], [2, 3, 64], [2, 4, 52], [3, 0, 72], [3, 1, 132], [3, 2, 114], [3, 3, 19], [3, 4, 16], [4, 0, 38], [4, 1, 5], [4, 2, 8], [4, 3, 117], [4, 4, 115], [5, 0, 88], [5, 1, 32], [5, 2, 12], [5, 3, 6], [5, 4, 120], [6, 0, 13], [6, 1, 44], [6, 2, 88], [6, 3, 98], [6, 4, 96], [7, 0, 31], [7, 1, 1], [7, 2, 82], [7, 3, 32], [7, 4, 30], [8, 0, 85], [8, 1, 97], [8, 2, 123], [8, 3, 64], [8, 4, 84], [9, 0, 47], [9, 1, 114], [9, 2, 31], [9, 3, 48], [9, 4, 91]],
                 dataLabels: {
                     enabled: true,
                     color: '#000000'
                 }
             }]

         });
        */

        //var found = BGTools.isHCTextButton("XXXXXX");
    };



    };//Constructor



	componentDidMount ( ) {
  
        this.unsubscribeCF_ChartStore = CF_ChartStore.listen(this.onCF_ChartStoreChange);

        if (this.props.CfChartData)
            this.makeGraph(this.props.CfChartData);
        else
            this.makeGraph(this.state.chartData);



    };
    componentDidUpdate (prevProps, prevState) {

        this.makeGraph(this.state.chartData);
    };

    componentWillUnmount ( ) {
 
        this.unsubscribeCF_ChartStore();
    };







    render () {


      /*
        return (

        )
        */
        return (
            <div  className="center-width100">
            <div className="data-chart" ref="chartArea">
                <Spinner />


            </div>

                <div id="Heat"> </div>
                </div>
        )

    }
};

