/**
 * Vaki Aquaculture Systems Ltd. Copyright 2018, all rights reserved.

 */

"use strict";

import React from "react";
import {Row, Col} from "react-bootstrap";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import VConst from "./../../utils/VConst.js";
import DashboardDrawUtils from "./DashboardDrawUtils.js";
import Vutil from "./../../utils/Vutil.js";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import FilterStore from "./../../stores/FilterStore.js";
import DashboardStore from "./../../stores/DashboardStore.js";
import Actions from "./../../actions/Actions.js";
import Spinner from "./../../utils/Spinner.jsx";
import LastFarmConnection from "./LastFarmConnection.jsx";
import SingleDatePicker from "./../Filters/SingleDatePicker.jsx";
import TranslateStore from "./../../stores/TranslateStore.js";
var t = TranslateStore.getTranslate();
import moment from "moment";
import Reflux from "reflux";
import VakiAPI from "../../webservice/VakiAPI";
import PopulationBlock from "./PopulationBlock.jsx";
import DUtils from "./DashUtils.js";
import DashHelp from "./DashHelp.jsx";
import DashComments from "./DashComments.jsx";
import DashStarvDate from "./DashStarvDate.jsx";
import DashPause from "./DashPause.jsx";
import PropTypes from "prop-types";
require("./Dashboard.css");

var _isMounted = false;

var DashboardPopContainerStore = Reflux.createStore({
    init () {
        //this.listenTo(Actions.dashPopStarvDate, this.onDashPopStarvDate);

      //  this.listenTo(Actions.dashPopStarvDateUpdate, this.onSetDate);
       // this.listenTo(Actions.apiPostStarvingDateResults, this.onPosted);


        this.listenTo(Actions.dashShowHelp, this.onShowHelp);
   
    



        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },

    /*
    onShowComments (e, str, pop) {
        //(x, y, comments, farm, pop) {

        //alert("e: " + e + "    str:" + str); 
        
        var el = document.getElementById("divCommentsOverlay");
        if (el)
            el.style.display = 'block';

        this.trigger(null);
        
    };
    */
onShowHelp ( ){
    var el = document.getElementById("divHelpOverlay");

     if (el)
        el.style.display = 'block';

       this.trigger (null);
}










});


/*
var _stage = {};

// The radius of a population, static for now, should prolly make fluid
var _radius = 50;
// This specifies the amount of space in pixels that should be between each
// population, center to center. This distance is a somewhat optimal distance
// in a way that the tangent of two adjacent circles meet in way that looks
// nice. For an explanation see: http://imgur.com/YTgfFSh
var _spacer = 2 * _radius * Math.sqrt(2);
// How many pixels do we want to have clear as a margin on the canvas
var _margin = 10;
// How many populations are allowed on even numbered lines in the Dashboard
var _popsPerLine = 6;
// Specifies how big we want the frame visibility and connection icons to be.
// 0.5 = half the size, 0.7 = 70% of the size of the original.
var _iconSize = 1;
// How transparent should the frame visibility and connection icons be?
var _iconAlpha = 0.8;
*/

var doUpdate = false;












export default class  DashboardPopContainer extends React.Component{
    static propTypes = {
        populationStatusList: VakiReactPropTypes.populationStatusList,
        lastDataConnectionTime: PropTypes.object

    };

    constructor(props) {
     super(props);

        this.state = {
            showOverlay: false,
            selectedPop: -1,
            numComments: 0 
        };
    
        this.onDashPopChange = (starvStuff) => {

        doUpdate = true;
        if (starvStuff === null  && _isMounted) {
            this.setState({
                showOverlay: false
            });

            return;
        }




        this.setState({
            showOverlay: true,
            coord: starvStuff.coord,
            pop: starvStuff.pop,
            anyChange: starvStuff.anyChange,
            theStarvingDate: starvStuff.theDate,
            saving: starvStuff.saving

        });



    };







    };

    componentDidMount () {
         _isMounted = true;
        //var domNode = ReactDOM.findDOMNode(this);
        this.unsubscribeDashPop = DashboardPopContainerStore.listen(this.onDashPopChange);
        //   createDashboardCanvas(domNode, this.props.populationStatusList);
    };


    componentWillUnmount () {
         _isMounted = false;
        this.unsubscribeDashPop();
    };

     shouldComponentUpdate (nextProps, nextState) {
        var currentPropsImm = Immutable.fromJS(this.props.populationStatusList);
        var nextPropsImm = Immutable.fromJS(nextProps.populationStatusList);
        var sameFarm = Immutable.is(currentPropsImm, nextPropsImm);

      
            var d = sameFarm;
            if (sameFarm && (this.state.selectedPop != FilterStore.getSelectedPopulationID()))
                sameFarm = false;

            var ddd = sameFarm;

            if (this.props.commentsList && this.props.commentsList.length != this.state.numComments)
                sameFarm = false;
        
        //console.log(d + "      " + ddd + "  should update----" + sameFarm + "     " + doUpdate + "    " + this.props.commentsList.length + "      >"  + this.state.numComments + "<>" + this.props.commentsList );

        if (sameFarm && !doUpdate) {
            // Same farm, no need to update
            return false;
        } else {

            doUpdate = false;
            return true;
        }
    };


  



    /**
     * Called after the render function has completed. That means the <>
     * element has been created so we can now draw to it anew.
     */
    componentDidUpdate (previousProps, previousState) {
        //var domNode = ReactDOM.findDOMNode(this);


    };

    /**
     * Minor convenience method, returns true if we received props, false
     * otherwise. This might be done better by actually checking the contents
     * of the object and see if there is actually anyting in it. But will do
     * for now. Just be careful.
     *
     * @returns {boolean}
     
    statusListNotEmpty ( ) {
        return this.props.populationStatusList ? true : false;
    };
    */

    handleExpand () {


        var exp = document.getElementById("divExpandFarm");
        exp.style.display = "none";



        var d = document.getElementById("divTotalFarm");
        d.style.display = "block";
    };

    handleCollapse () {


        var exp = document.getElementById("divExpandFarm");
        exp.style.display = "inline-block";



        var d = document.getElementById("divTotalFarm");
        d.style.display = "none";
    };



    openTV () {
        alert("adsfadsf");
    };




    onModalClick (e) {
        e.stopPropagation();

    };



    render () {
        // If we don't receive any props, default to given height. Pixel count.
        /*
        var canvasHeight = 0;


     


        if (this.statusListNotEmpty()) {
            var rowsNeeded = DashboardDrawUtils.getNumberOfRows(_popsPerLine, this.props.populationStatusList.length);
            // I'm totally guessing that 0.25 number, it seems to work okay for
            // 1 - 5 rows. Might need re-adjusting if we go above 6 rows. Pressure
            // is on to get this thing done, don't have time to do properly.
            canvasHeight = (rowsNeeded * 2 *_radius) + (2 * _margin) + (_spacer * 0.25);
        }
        */





        this.state.selectedPop = FilterStore.getSelectedPopulationID();

        var farmName = FilterStore.getSelectedFarmName();


        var frm = FilterStore.getSelectedFarm();

/*

        var farmComments = [];

        if (this.props.commentsList && this.props.commentsList.length > 0) {
            for (var j = 0; j < this.props.commentsList.length; j++)
                if (!this.props.commentsList[j].PopulationID || this.props.commentsList[j].PopulationID <= 0)
                    farmComments.push(this.props.commentsList[j]);

        }


        try {
            var elCommIcon = document.getElementsByClassName("clsHasComment")[0];
            if (farmComments.length > 0)
                elCommIcon.style.display = "block";
            else
                elCommIcon.style.display = "none";

        } catch (e) { }


  */    




        var dbData = DashboardStore.getDashboardData();


        var contactPhone = dbData.get("ContactPhone");
        var contactEmail = dbData.get("ContactEmail");
        var teamviewerID = dbData.get("TeamViewerID");


      

        if (!contactPhone  || contactPhone.trim() == "")
            contactPhone = "Not set";

        if (!contactEmail || contactEmail.trim() == "")
            contactEmail = "Not set";

        var strTvID = "";
        if (teamviewerID == null)
            strTvID = "Not set";
        else {
            strTvID = teamviewerID.toString().trim();
            if (strTvID.length == 9) {
                //Format with spaces if normat TV number
                strTvID = strTvID.substr(0, 3) + " " + strTvID.substr(3, 3) + " " + strTvID.substr(6, 3);

                //tvLink = (<div>  <strong> Link:  </strong>  <a href="https://start.teamviewer.com//581123956" target="_blank" onclick="this.openTV"> TeamViewer</a> </div>);
            }
        }


        var farmTotal = <div>
            <Row>
                <Col md={4}> <strong>{this.props.t("dashboard.contactPhone")}</strong>: {contactPhone}  </Col>
                <Col md={4}> <strong>{this.props.t("dashboard.contactInfo")}</strong>: {contactEmail} </Col>
                <Col md={4}> <strong>{this.props.t("dashboard.tv_id")} </strong>: {strTvID} </Col>
            </Row>
       
        </div>;





        //<strong>TeamViewer ID </strong>: {strTvID}  {tvLink} < br />


        var el = document.getElementById("overlay");
        
        var starvDayText = this.props.t("dashboard.starvnotset"); 
        var calendarDate = moment();

        if (this.state.theStarvingDate) {
            calendarDate = this.state.theStarvingDate;
        }

        if (el) {
            el.style.display = (this.state.showOverlay ? "block" : "none");
            if (this.state.coord) {

                var elM = document.getElementById("divStarvDateSet");

                elM.style.top = (this.state.coord.y-80)+"px";
                elM.style.left = (this.state.coord.x - 100) + "px";

                if (this.state.theStarvingDate != null)
                    starvDayText = "Set starving date: " + this.state.theStarvingDate.format("DD.MM.YYYY");

                var elb = document.getElementById("divStarvButtons");

                if (this.state.saving) {
                    if (elb)
                        elb.style.display = "none";
                    starvDayText = this.props.t("dashboard.saving");
                } else {
                    if (elb)
                        elb.style.display = "block";
                }



            }

        }



            //StarvClickedPop 

        

    


        var saveDisabled = !this.state.anyChange;
        var removeDisabled = this.state.theStarvingDate == null;




        var thePops = [];

        this.state.numComments = this.props.commentsList.length;
        
        var psL = this.props.populationStatusList;
        for (var i=0; i<psL.length; i++){
             // Get comments for this pop
            var thisPopComments = [];

            for (var j = 0; j<this.props.commentsList.length; j++ )
                 if (this.props.commentsList[j].PopulationID == psL[i].PopulationID)  
                      thisPopComments.push(this.props.commentsList[j]);                                             
            
            

            thePops.push ( <PopulationBlock popStatus = {psL[i]}  comments={thisPopComments} theID = {i+1} key={i} /> );
        }


        


        var farmComments = [];

        for (var j = 0; j<this.props.commentsList.length; j++ )
            if (!this.props.commentsList[j].PopulationID || this.props.commentsList[j].PopulationID<=0)  
                farmComments.push(this.props.commentsList[j]); 

       var imgCommPath = "";
  
        

        var CommMsg = this.props.t("dashboard.clicktoadd");
       var commParams = null;
       var commLines = null;
       var nCommMsgWidth = 200; 
       var iconHeight = "24";
       if (farmComments.length > 0){
          imgCommPath = "img/comment.png"
          CommMsg = DUtils.showComments;

          commParams = farmComments;
          commLines = farmComments.length + 5;
          nCommMsgWidth = -1; //Will be adjusted
       }else{
           iconHeight = "20em"
          imgCommPath = "img/pencil2_24.png"
       }


        /*
             onClick= { function (e) {
                                    var str = moment().format("YYYY-MM-DD");
                                    var theComment = prompt("Add a comment to this farm", "");
                                    if (theComment != null && theComment != "") {
                                        var newComment = {
                                            FarmID: FilterStore.getSelectedFarmID(),
                                            PopulationID: 0,
                                            PertinentDateTime: str,
                                            CommentText: theComment
                                        };
                                        Actions.addComment(newComment);
                                    }
                                    e.stopPropagation();
                                    }}
                                onMouseEnter={function (e) { DUtils.theMouseEnter(e,  CommMsg,nCommMsgWidth,commLines, commParams) }}
                                onMouseLeave={function (e) { DUtils.theMouseLeave() }}
        */ 

        /*
           <DashPause />
         */ 



  

        return (
                <div className="clsFarmWrap">
                <div className="clsFarmBlock">
                    <div id="divFarmName"><strong>{farmName}</strong></div>
                    <div id="divFarmStatus">
                       <LastFarmConnection lastDataConnectionTime={this.props.lastDataConnectionTime} t={this.props.t} />
                    </div>

                    <div id="divExpandFarm" className="clsExpand" onClick={this.handleExpand} onKeyDown = {(e)=>{if(e.keyCode === 13) this.handleExpand()}}>
                        <div className="clsHalf" tabIndex="0"  >

             


                            <img src={imgCommPath} className="clsHasComment" height={iconHeight} tabIndex="0" onKeyDown = {(e)=>{if(e.keyCode === 13){Actions.dashCommentsShow(true, commParams); e.stopPropagation()}}}
                                onClick={function (e) { Actions.dashCommentsShow(true, commParams); e.stopPropagation() }}
                            />
                                 
                              
                        
                                
                            <img src="img/expand50.png" className="clsExpandImgHeight" />
                        </div>
                     </div>

                    <div id="divTotalFarm">


                        <div className="clsHalf" >
                        <img src={imgCommPath} className="clsHasComment" height={iconHeight}
                            onClick={function (e) { Actions.dashCommentsShow(true, commParams); e.stopPropagation() }}
                            />
</div>
                        {farmTotal}




                     <div id="divCollapseFarm" className="clsExpand" onClick={this.handleCollapse}>


                           <img src="img/collapse50.png" className="clsExpandImgHeight" />
                                                      </div>
                                </div>



                </div>

                <DashHelp />
              


              
                <DashComments />
                <DashStarvDate />




                <div className="clsPopContainerWrap">
                <div id="PopContainer" className="clsPopContainer">
                    
                    <div id="divPop" className= "clsPop" onClick={DUtils.thePopClick} ></div>
                    
                    {thePops}
                </div>
                </div>
       
        </div>

        )
    }
};

