/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 *
 */
"use strict";
var FileSaver = require("file-saver");
var React = require("react");
var ReactDOM = require("react-dom");
var moment = require("moment");
var ImmutablePropTypes = require("react-immutable-proptypes");

var VConst = require("./../../utils/VConst.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var WeightAndTimeStore = require("./../../stores/WeightAndTimeStore.js");
var Actions = require("./../../actions/Actions.js");
var BGTools = require("./../../utils/BGtools.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
var FilterStore = require("../../stores/FilterStore");


var downLoadText = function (chart, csv) {
    try {



        var str = "";
        var csvTab = [];
        var strSep = "\t";
        var filt = FilterStore.getFilter();
        var strExt = ".TXT";

        if (csv){
            strSep = ",";
            strExt = ".CSV";
            csvTab.push("sep="+strSep);
        }

        str = t("filters.date")+strSep+filt.startDate+strSep+t("filters.to")+strSep+filt.endDate;
        csvTab.push(str);



        csvTab.push(t("filters.minWeight")+strSep+filt.minWeight);
        csvTab.push(t("filters.maxWeight")+strSep+filt.maxWeight);


        csvTab.push("");

        var numCols = chart.series.length;







        str = "Time"+strSep+ "Weight (g)"

        csvTab.push(str);



        /*
         for (var i = 0; i < numCols; i++) {
         alert ("Lengdir "+this.series[i].name+ ":   "+ this.series[i].data.length)
         }
         */
        var dataLen = chart.series[0].data.length;

        for (var j=0; j<dataLen; j++) {
            str = "";

            // for (var i = 0; i < numCols; i++) {
            //skip the voltage measurement

            if (chart.series[0].data[j].y) {
                str +=  moment(chart.series[0].data[j].x).format("YYYY-MM-DD HH:mm:ss") + strSep+ chart.series[0].data[j].y;
                csvTab.push(str);
            }

            //    if (i < (numCols - 1))
            //        str += strSep
            //}
            // csvTab.push(str);


        }
        str = csvTab.join("\r\n");

        /*
         for (var prop in this.title) {
         alert ("prop:  "+prop)
         }
         */


        var vNames = FilterStore.getOrgAndFarmNamesByFarmId(FilterStore.getSelectedFarmID());
        var now = moment.utc(new Date());

        var fileName = vNames.farm+" "+(chart.title.textStr.trim())+ " "+now.format("YYYY-MM-DD")+strExt;
        FileSaver.saveAs(new Blob([str], {type: 'text/plain'}), fileName);


    } catch (er) {
        alert("buttonexp:  " + er);
    }
};


/**
 * React component that displays the actual graph in the Weight and time report.
 * Note that the details for drawing the highchart graph is not here because I moved
 * it to the store.
 */
 export default class  WeightAndTimeChart extends React.Component{
//var WeightAndTimeChart = export default class   extends React.Component{
 

constructor(props) {

    super(props);
    this.state = {
           loading: false 
    };

     this.makeGraph = (chartData) => {
        if (chartData === undefined) return;

        var domNode = ReactDOM.findDOMNode(this.refs.chartArea);


        chartData.chart.renderTo = domNode;
        var chart = new Highcharts.Chart(chartData);
        //var found = BGTools.isHCTextButton("wgttime");
		BGTools.clearMyHiChartsButtons();
		var found = false;	
        if (chart.series[0].data.length > 1 && !found) {
            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-wgttime",
                text: t("misc.textfilebuttontext"),
                // style: {color: "green" },
                onclick  (){
                    return downLoadText (this, false);
                }
            });

            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-wgttimeCSV",
                text: t("misc.textfilebuttoncsv"),
                // style: {color: "green"},
                onclick  (){
                    return downLoadText (this, true);
                }
            });
        }




    };


   
  };//Constructor

    componentDidMount  () {

        this.makeGraph(this.props.chartData);
     };
    componentDidUpdate  (prevProps, prevState) {
        this.makeGraph(this.props.chartData);
     };

    render  () {
        return (
            <div className="data-chart" ref="chartArea">
                <div className="text-center">
                    <div className="spinner" hidden={!this.state.loading}></div>
                </div>
            </div>
        )
    }
};

