/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * This component makes up the form that allows the admin to create a new computer.
 * Along with basic information, a computer has a list of farms that it belongs to.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");
var EditFarmFormStore = require("./EditFarmFormStore.js");
var _currentEditComputer = { };

var EditCompFormStore = Reflux.createStore({
	init: function() {
    	this.listenTo(Actions.dropdownSelect, this.onDropdownSelect);
        this.listenTo(Actions.removeSelectedOption, this.onRemoveSelectedOption);
        this.listenTo(Actions.compListRowClick, this.onCompListRowClick);
        this.listenTo(Actions.editCompFormSubmit, this.onEditComputerFormSubmit);

        _currentEditComputer = {
            id: 0,
        	name: "",
        	macAddress: "",
        	dropboxUsername: "",
        	dropboxPassword: "",
        	teamViewerId: 0,
        	teamViewerPassword: "",
        	createdDate: "",
        	biomassVersion: "",
        	uploadInterval: "",
        	databaseVersion: "",
        	farms: [],
            selectedFarms: [],
            systems: [],
            selectedSystems: []
        };
    },
    /* Triggered on an action shared by all sub-stores */
    onDropdownSelect: function(data, view, type) {
    	if(view === "editComputer") {
            if (type === "farm" || type === "farmOrg" ) {
	            this.onDropdownFarmSelect(data);
	        } else if(type === "system") {
                this.onDropdownSystemSelect(data);
            }
    	}  
    },
    onRemoveSelectedOption: function(data, view, type) {
        if(view === "editComputer") {
            if (type === "farm" || type === "farmOrg") {
	            this.onRemoveFarm(data);
	        } else if(type === "system") {
                this.onRemoveSystem(data);
            }
    	}
    },
    onDropdownFarmSelect: function(farm) {
    	var farmIndex = _currentEditComputer.farms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentEditComputer.farms.splice(farmIndex, 1);
        }

  

    AdminViewStore.isFarmConnectedToComputer (farm.id);
       _currentEditComputer.selectedFarms.push(farm);

      

        this.trigger(_currentEditComputer);
    },
    onDropdownSystemSelect: function(system) {
        var systemIndex = _currentEditComputer.systems.indexOf(system);
        if(systemIndex !== -1) {
            _currentEditComputer.systems.splice(systemIndex, 1);
        }
        _currentEditComputer.selectedSystems.push(system);
        this.trigger(_currentEditComputer);
    },
    /*  These functions are triggered when the user presses to x next to a selected option 
    *   Removes the option from the selected options and returns it to the dropdown menu
    */
    onRemoveFarm: function (farm) {
        var farmIndex = _currentEditComputer.selectedFarms.indexOf(farm);
        if(farmIndex !== -1) {
            _currentEditComputer.selectedFarms.splice(farmIndex, 1);
        }
        _currentEditComputer.farms.push(farm);
        _currentEditComputer.farms.sort(this.compare);
        this.trigger(_currentEditComputer);
    },
    onRemoveSystem: function(system) {
        var systemIndex = _currentEditComputer.selectedSystems.indexOf(system);
        if(systemIndex !== -1) {
            _currentEditComputer.selectedSystems.splice(systemIndex, 1);
        }
        _currentEditComputer.systems.push(system);
        _currentEditComputer.systems.sort(this.compare);
        this.trigger(_currentEditComputer);
    },
    onCompListRowClick: function (computer) {

    

        _currentEditComputer.id = computer.id;
        _currentEditComputer.name = computer.name;
        _currentEditComputer.macAddress = computer.macAddress;
        _currentEditComputer.dropboxUsername = computer.dropboxUsername;
        _currentEditComputer.teamViewerId = computer.teamViewerId;
        _currentEditComputer.createdDate = computer.createdDate;
        _currentEditComputer.biomassVersion = computer.biomassVersion;
        _currentEditComputer.uploadInterval = computer.uploadInterval;
        _currentEditComputer.databaseVersion = computer.databaseVersion;
        _currentEditComputer.dropboxPassword = computer.dropboxPassword;
        _currentEditComputer.teamViewerPassword = computer.teamViewerPassword;
        _currentEditComputer.selectedSystems = computer.activeSystems;
        _currentEditComputer.farms = AdminViewStore.getAllFarmsCloned();
        _currentEditComputer.systems = AdminViewStore.getAllSystemsCloned();
        if(computer.farm !== undefined) _currentEditComputer.selectedFarms = [computer.farm];
        else _currentEditComputer.selectedFarms = [];
        this.filterSelectOptions(_currentEditComputer.selectedSystems, _currentEditComputer.systems);
        this.filterSelectOptions(_currentEditComputer.selectedFarms, _currentEditComputer.farms);
        this.trigger(_currentEditComputer);
    },
    onEditComputerFormSubmit: function (macAddress, uploadInterval,  teamViewerId, teamViewerPassword) {
        _currentEditComputer.macAddress = macAddress;
        _currentEditComputer.teamViewerId = teamViewerId;
        _currentEditComputer.isActive = true;
        _currentEditComputer.teamViewerPassword = teamViewerPassword;
        _currentEditComputer.uploadInterval = uploadInterval;

        Actions.newCompCreated(_currentEditComputer);
    },
    compare: function(a,b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    },
    filterSelectOptions: function (selectedOptions, options) {
       

        for(var i = 0; i < selectedOptions.length; ++i) {
            for(var j = 0; j < options.length; ++j) {
                if(selectedOptions[i].name === options[j].name) {
                    options.splice(j, 1);
                }
            }
        }
    },
    getCurrentEditComputer: function() {
        return _currentEditComputer;
    }
});


module.exports = EditCompFormStore;