/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */



"use strict";

var React = require("react");

var VConst = require("./../../utils/VConst.js");
var NumericInput = require("./NumericInput.jsx");
var SavedFiltersIndicator = require("./../Filters/SavedFiltersIndicator.jsx");
import PropTypes from "prop-types";

export default class MaxWeightFilterNew  extends React.Component{
    static propTypes = {
        maxWeight: PropTypes.number.isRequired,
        minValue: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    render () {
        return (
            <NumericInput
                value={this.props.maxWeight}
                label={ this.props.t("filters.maxWeight")}
                minValue={this.props.minValue}
                maxValue={VConst.ABSOLUTE_MAX_WEIGHT}
                actionFunction={this.props.actionFunction} />
        )
    }
};

