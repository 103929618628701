/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var RB = require("react-bootstrap");
var FilterStore = require("../../stores/FilterStore");
var TrendGraphStore = require("./../../stores/TrendGraphStore.js");
/**
 * Component that takes care of displaying the Trend Graph and it's filters.
 */
export default class Loader  extends React.Component{
	constructor(props) {
     super(props);
	    this.state = {
	        loading: false  
	    };
	};


	componentDidMount ( ) {
        this.unsubscribeTrendGraphStore = TrendGraphStore.listen(this.onStoreChange);
    };
    componentWillUnmount ( ) {
        this.unsubscribeTrendGraphStore();
    };
    onStoreChange ( data, loadingMsg) {


    	if(loadingMsg) {
    		this.setState({ loading: TrendGraphStore.isLoading() });
    	}
    };
    render () {
        return (
        	<div className="text-center">
	            <br hidden={this.state.loading} />
	            <div className="spinner" hidden={!this.state.loading}></div>
	    	</div>  
        )
    }
};

