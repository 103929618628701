/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, OverlayTrigger, Button, ButtonToolbar, Tooltip, InputGroup, Form, Accordion } from 'react-bootstrap';
import ChangePasswordForm from './ChangePasswordForm.jsx';
import Actions from './../../actions/Actions.js';
import NavBarStore from './../../stores/NavBarStore.js';
import FilterStore from '../../stores/FilterStore';
import CountriesFilter from './../Filters/CountriesFilter/CountriesFilter.jsx';
import Reflux from 'reflux';

require('./SignoutModal.css');

/**
 * A react-bootstrap Modal that pops ups when you click on "Signed in as X"
 * in the upper right hand corner of the site. It displays a popup modal that
 * states you are logged in as X and asks if you really want to log out or not.
 */
/*
var SignutModalStore = Reflux.createStore({
	init() {
		this.listenTo(Actions.showSignoutModal, this.onShow);
	},

	onShow(show) {
		this.trigger(show);
	}
});
*/
function handleClose() {
	Actions.showSignoutModal(false);
}

export default class SignoutModal extends React.Component {
	//var SignoutModal = export default class   extends React.Component{

	static propTypes = {
		userName: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			saveFilters: NavBarStore.filtersSaved(),
			showAllOrgs: NavBarStore.showAllOrgs(),
			uniformScaling: NavBarStore.uniformScaling(),
			show: true
		};

		this.onSaveFilters = () => {
			Actions.saveFilters();
			this.setState({ saveFilters: !this.state.saveFilters });
		};

		this.onShowAllOrgs = () => {
			Actions.showAllOrgs();
			this.setState({ showAllOrgs: !this.state.showAllOrgs });
		};

		this.onUniformScaling = () => {
			Actions.uniformScaling();
			this.setState({ uniformScaling: !this.state.uniformScaling });
		};

		this.handleSignout = () => {
			this.setState({ show: 'false' });
			Actions.signOut();



		};

		this.onShow = (show) => {


			this.setState({ show: show });
		};
	} //constructor

/*
	componentDidMount() {
		//var domNode = ReactDOM.findDOMNode(this);
		this.unsubscribeSignutModalStore = SignutModalStore.listen(this.onShow);
		//   createDashboardCanvas(domNode, this.props.populationStatusList);
	}

	componentWillUnmount() {
		this.unsubscribeSignutModalStore();
	}
*/
	onSetDefaultFarm() {
		Actions.setDefaultFarm(FilterStore.getSelectedFarmID());
		alert(FilterStore.getSelectedFarmName() + ' set as default farm');
	}

	render() {

		/*

 <div className="modal-header">
 <h2 className="modal-title text-center">Sign Out</h2>
 </div>
*/

		var txtDefaultFarm = 'Set (' + FilterStore.getSelectedFarmName() + ') as default farm';

		return (
			<Modal show={this.state.show} animation={true} className="my-modal" >
				<div className="modal-body text-center width100 ">
					You are currently signed in as <strong>{this.props.userName}.</strong>
					<div className="buttonpadding">
						<Button size="lg" variant="warning" onClick={this.handleSignout}>
							Sign Out
						</Button>
					</div>
					<Accordion defaultActiveKey="1">
						<Accordion.Item eventKey="0">
							<Accordion.Header>Change password</Accordion.Header>
							<Accordion.Body>
								<div className="wrap">
									<ChangePasswordForm pwChangeMsg={this.props.pwChangeMsg} />
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>User preferences</Accordion.Header>
							<Accordion.Body>
								<div className="wrap">
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>
												{' '}
												When a filter is selected it will be saved locally on this computer{' '}
											</Tooltip>
										}
									>
										<div>
											<Form.Check
												onChange={this.onSaveFilters}
												checked={this.state.saveFilters}
												label={'Save filters'}
											/>
										</div>
									</OverlayTrigger>
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>
												{' '}
												Show cages for all organisations and farms in deshboard when entering
												BiomassDaily. Setting will be saved locally on this computer{' '}
											</Tooltip>
										}
									>
										<div>
											<Form.Check
												onChange={this.onShowAllOrgs}
												checked={this.state.showAllOrgs}
												label={'Show all organizations on dashboard by default'}
											/>
										</div>
									</OverlayTrigger>

									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>
												{' '}
												Check the box if you want uniform scaling to be default ON each time you
												load new trendgraph{' '}
											</Tooltip>
										}
									>
										<div>
											<Form.Check
												onChange={this.onUniformScaling}
												checked={this.state.uniformScaling}
												label={'Uniform scaling default ON in trendgraph'}
											/>
										</div>
									</OverlayTrigger>

									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip>Select current farm to become the default one at startup </Tooltip>
										}
									>
										<div className="buttonpadding">
											<Button size="xs" variant="outline-dark" onClick={this.onSetDefaultFarm}>
												{txtDefaultFarm}
											</Button>
										</div>
									</OverlayTrigger>
								</div>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="2">
							<Accordion.Header>Countries filter</Accordion.Header>
							<Accordion.Body>
								<div className="wrap">
									<CountriesFilter />
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<br />
					<div className="buttonpadding">
						<Button onClick={handleClose} variant={'outline-dark'}>
							{' '}
							Close{' '}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}

/*

        return (
            <Modal {...this.props}>

                <div className="modal-body text-center">
                    You are currently signed in as <strong>{this.props.userName}.</strong>
                    <br /><br/>
                    <ButtonToolbar>
                        <Button size="lg"  variant="warning" onClick={this.handleSignout}>Sign Out</Button>
                    </ButtonToolbar>
                    <ChangePasswordForm pwChangeMsg={this.props.pwChangeMsg} />
<div className = "well well-sm" >
    <p><strong> User preferences </strong></p>

                    <OverlayTrigger placement='top' overlay={<Tooltip> When a filter is selected it will be saved locally on this computer </Tooltip>}>
                        <div>
                        <InputGroup>
						    <InputGroup.Checkbox
							    onChange={this.onSaveFilters}
							    checked={this.state.saveFilters}
						    />
							<InputGroup.Text>{"Save filters"}</InputGroup.Text>
						</InputGroup>
                        </div>
                    </OverlayTrigger>
<OverlayTrigger placement='top' overlay={<Tooltip> Show cages for all organisations and farms in deshboard when entering BiomassDaily. Setting will be saved locally on this computer </Tooltip>}>
<div>
                        <InputGroup>
						    <InputGroup.Checkbox
							    onChange={this.onShowAllOrgs}
							    checked={this.state.showAllOrgs}
						    />
							<InputGroup.Text>{"Show all organizations on dashboard by default"}</InputGroup.Text>
						</InputGroup>
</div>
</OverlayTrigger>

<OverlayTrigger placement='top' overlay={<Tooltip> Check the box if you want uniform scaling to be default ON each time you load new trendgraph </Tooltip>}>
<div>

                        <InputGroup>
						    <InputGroup.Checkbox
							    onChange={this.onUniformScaling}
							    checked={this.state.uniformScaling}
						    />
							<InputGroup.Text>{"Uniform scaling default ON in trendgraph"}</InputGroup.Text>
						</InputGroup>

</div>
</OverlayTrigger>



<OverlayTrigger placement='top' overlay={<Tooltip>Select current farm to become the default one at startup </Tooltip>}>
<div>


<Button  size="xs" variant="outline-dark" onClick = {this.onSetDefaultFarm}>{txtDefaultFarm}</Button>
</div>
</OverlayTrigger>


<CountriesFilter />



    </div >
                </div>
            </Modal>
        )

*/
