/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 * Small component that allows the user to set a new password
 * if he can provide a new one.
 */

"use strict";


var React = require("react");
import {Row, Col, Alert, FormControl, Button, Dropdown} from "react-bootstrap";
var Actions = require("./../../actions/Actions.js");
var SessionStore = require("./../../stores/SessionStore.js");


export default class ChangePasswordForm extends React.Component{
//var ChangePasswordForm = export default class   extends React.Component{

		constructor(props) {
     super(props);
    
    
        this.state = {
            open: false,
            oldPassword: "",
            oldPasswordSecond: "",
            newPassword: "",
            newPasswordSecond: "",
            passwordMismatch: false,
            loading: false,
            failure: false
        };

    this.panelChange = () =>{
        this.setState({ open: !this.state.open });
      }
    this.handleOldPasswordChange =  (e)=> {
		this.setState({ oldPassword: e.target.value });
	  }
	this.handleOldPasswordSecondChange = (e) =>{
		this.setState({ oldPasswordSecond: e.target.value });
	  }
	this.handleNewPasswordChange = (e) => {
		this.setState({ newPassword: e.target.value });
	  }
	this.handleNewPasswordSecondChange  = (e) =>{
		this.setState({ newPasswordSecond: e.target.value });
	  }
	this.onFormSubmit  = ()=> {
		if (SessionStore.isLoggedOut()) {
			alert (this.props.t("adminView.expiredaccess"));
			Actions.redirectToLogin();
		}

		if(this.state.newPassword !== this.state.newPasswordSecond ||
			this.state.oldPassword !== this.state.oldPasswordSecond) {
			this.setState({ passwordMismatch: true });
			return;
		}
		Actions.changePasswordSubmit(this.state.oldPassword, this.state.newPassword);
		this.setState({
			oldPassword: "",
			oldPasswordSecond: "",
            newPassword: "",
            newPasswordSecond: "",
            passwordMismatch: false,
            loading: true
		});
		var self = this;
		setTimeout(function(){
			self.setState({
				loading: false
			}); 
		} , 2000);
	}

		};//Constructor

    render   () {
        return (
        	


               
	
                <div>
                  <form>
                  	<Row>
                  		<Col md={6}>
 		



							<FormControl value={this.state.oldPassword} onChange={this.handleOldPasswordChange}
							type="password" label="Old password" placeholder="Old password.." />
                  		</Col>
                  		<Col md={6}>
							<FormControl value={this.state.oldPasswordSecond} onChange={this.handleOldPasswordSecondChange}
							type="password" label="Confirm old password" placeholder="Confirm old password.." />
                  		</Col>
                  	</Row>
                  	<Row>
						<Col md={6}>
							<FormControl value={this.state.newPassword} onChange={this.handleNewPasswordChange}
							type="password" label="New password" placeholder="New password.." />
						</Col>
						<Col md={6}>
							<FormControl value={this.state.newPasswordSecond} onChange={this.handleNewPasswordSecondChange}
							type="password" label="Confirm new password" placeholder="Confirm new password.." />
						</Col>
						<Alert  variant="warning" hidden={!this.state.passwordMismatch}>
							<strong> The passwords do not match! </strong>
						</Alert>
						<Alert  variant="warning" hidden={!this.state.passwordMismatch}>
							<strong> Something went wrong, password was not changed. </strong>
						</Alert>
					</Row>
					<div className="text-center" id="change-password-submit">
					<br/>
						<Button  variant="outline-dark" onClick={this.onFormSubmit}>
							Submit
						</Button>
						<div className="spinner" hidden={!this.state.loading}></div>
					</div>
					<Alert  variant="success" hidden={!this.props.pwChangeMsg.showMsg || !this.props.pwChangeMsg.successful}>
						<strong> Password changed successfully! </strong>
					</Alert>
					<Alert  variant="warning" hidden={!this.props.pwChangeMsg.showMsg || this.props.pwChangeMsg.successful}>
						<strong> Something went wrong, password was not saved! </strong>
					</Alert>
                  </form>
                </div>

        )
    }
};

//module.exports = ChangePasswordForm;