/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

var React = require("react");
import {Table} from "react-bootstrap";
import UserListRow from "./Rows/UserListRow.jsx";

 export default class  AdminViewUserList extends React.Component{
	shouldComponentUpdate (nextProps, nextState) {
	    if(nextProps.tableActive === false) {
	    	return false;
	    }
	    return true;
	};
	render ( ) {
		var self = this;
		var userTableRows = this.props.users.map(function(user, i) {
			return (
				<UserListRow user={user} key={i} t={self.props.t} />
			);
		});
		return (
			<div>
			<div className="table-wrapper">
			<Table striped bordered condensed  = {"true"} hover className="admin-view-table">
	    			<thead>
	    				<tr>
		    				<th className="col-md-3"> {this.props.t("adminView.name")} </th>
		    				<th className="col-md-3"> {this.props.t("adminView.username")} </th>
		    				<th className="col-md-2"> Orgs </th>
		    				<th className="cold-md-2"> Farms </th>
		    				<th className="col-md-2"> Del/Res </th>
	    				</tr>
	    			</thead>
    		</Table>
	    	</div>
			<div className="table-wrapper">
	    		<Table striped bordered condensed = {"true"} hover className="admin-view-table">
	    			<tbody className="user-table-body">
	    				{userTableRows}
	    			</tbody>
	    		</Table>
	    	</div>
	    	</div>
		);
	}
};

