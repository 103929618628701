/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";


var React = require("react");
import {ArrowRepeat, Trash}  from 'react-bootstrap-icons';
var Actions = require("../../../../actions/Actions.js");
import ConfirmDeleteModal from "./Modals/ConfirmDeleteModal.jsx";





export default class CompListRow  extends React.Component{
	 constructor(props) {
     super(props);

	this.compClicked = ( ) => {
		Actions.compListRowClick(this.props.computer);
	};
	this.isNullOrEmpty = ( data) => {
		return (data === null) || (data === "") || (data === undefined)
	};
	this.deleteClicked = ( ) => {
		Actions.compListRowDelete(this.props.computer);
	};
	this.restoreClicked = ( ) => {
		Actions.compListRowRestore(this.props.computer);
	};
	this.getButton = ( ) => {
		if(this.props.computer.isActive) {
			return this.getDeleteButton();
		} else {
			return this.getRestoreButton();
		}
	};
	this.getDeleteButton = ( ) => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Delete">
				<button className="btn btn-danger btn-xs" onClick={this.deleteClicked}>
					<Trash/>
				</button>
			</p>
		);
	};
	this.getRestoreButton = ( ) => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Restore">
				<button className="btn btn-primary btn-xs" onClick={this.restoreClicked}>
					<ArrowRepeat/>
				</button>
			</p>
		);
	};

	 };//constructor
	render = ( ) => {
		var farm;
		var button = this.getButton();
		if(this.isNullOrEmpty(this.props.computer.farm)) farm = this.props.t("adminView.noFarmRegistered");
		else farm = this.props.computer.farm.name;
		return (
			<tr>
				<td className="col-md-3" onClick={this.compClicked.bind(null, this.props.computer.id)}> {this.props.computer.id} </td>
				<td className="col-md-3" onClick={this.compClicked.bind(null, this.props.computer.id)}> {this.props.computer.teamViewerId} </td>
				<td className="col-md-4" onClick={this.compClicked.bind(null, this.props.computer.id)}> {farm} </td>
				<td className="col-md-2">
					{button}
				</td>
			</tr>
		);
	};
};

