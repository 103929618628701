/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

var Reflux = require('reflux');
var moment = require('moment');
var Immutable = require('immutable');

var Actions = require('../actions/Actions.js');
var VConst = require('./../utils/VConst.js');
var NavBarStore = require('./NavBarStore.js');
var FilterStore = require('./FilterStore.js');
var ParMan = require('./ParMan.js');
var CF_ChartStore = require('./CF_ChartStore.js');
var VakiAPI = require('./../webservice/VakiAPI.js');
var VakiReactPropTypes = require('./../utils/VakiReactPropTypes.js');
var UserDataStore = require('./UserDataStore.js');

var _data = Immutable.Map({});

var _mounted = 0;

const startDate_ID = 'DS_startDate';
const endDate_ID = 'DS_endDate';
const displayDate_ID = 'DS_displayDate';
const minCF_ID = 'DS_minCF';
const maxCF_ID = 'DS_maxCF';
const binSize_ID = 'DS_binSize';
const guttingFactor_ID = 'DS_guttingFactor';
const populationSize_ID = 'DS_populationSize';

/**
 * Store for the Distribution report. To start out with it contains filters
 * that are specific to that report but in the future we might want to store
 * some other kind of state in this store.
 *
 * To note is that I decided to take a bit different and more correct way to
 * write this store. It's very similar to TrendGraphStore with the exception
 * that the data attribute is outside the store. I'm pretty much just trying
 * the difference. I feel all the prefixed underscores makes for ugly code
 * (a JS convention to denote something is private) so we may at a later date
 * refactor this to be more in line with how TrendGraphStore is written, with
 * the data attribute attached to the object.
 */
var DistributionStore = Reflux.createStore({
	init: function() {
		this.listenTo(FilterStore, this.onFilterStoreUpdate);
		this.listenTo(Actions.apiNewDistributionsData, this.onApiNewDistributionsData);
		this.listenTo(Actions.distDateUpdate, this.onDateUpdate);
		this.listenTo(Actions.distMinCFUpdate, this.onMinCFUpdate);
		this.listenTo(Actions.distMaxCFUpdate, this.onMaxCFUpdate);
		this.listenTo(Actions.distBinSizeUpdate, this.onBinSizeUpdate);
		this.listenTo(Actions.distGuttingFactorUpdate, this.onGuttingFactorUpdate);
		this.listenTo(Actions.distPopulationSizeUpdate, this.onPopulationSizeUpdate);

		// Pick the default date range from yesterday because current day isn't
		// over yet, meaning we are missing fish measurements until midnight

		/* For one day average
        var startDate = moment().subtract(2, "days");
        var endDate =    startDate.clone().add(1, "days");
        */

		//5 day avarage
		var startDate = moment().subtract(3, 'days');
		var endDate = startDate.clone().add(5, 'days');
		var displayDate = startDate.clone().add(2, 'days');

		var filter = Immutable.Map({
			/*
            startDate: startDate.format(VConst.DATE_FORMAT),
            endDate: endDate.format(VConst.DATE_FORMAT),
            displayDate: displayDate.format(VConst.DATE_FORMAT),
            */

			startDate: ParMan.initParameter(startDate_ID, startDate.format(VConst.DATE_FORMAT)),
			endDate: ParMan.initParameter(endDate_ID, endDate.format(VConst.DATE_FORMAT)),
			displayDate: ParMan.initParameter(displayDate_ID, displayDate.format(VConst.DATE_FORMAT)),
			minCF: VConst.DEFAULT_MIN_CF,
			maxCF: VConst.DEFAULT_MAX_CF,
			binSize: VConst.DEFAULT_BIN_SIZE,
			guttingFactor: VConst.DEFAULT_GUTTING_FACTOR,
			populationSize: VConst.DEFAULT_POPULATION_SIZE
		});

		var distributionsData = Immutable.Map(VakiReactPropTypes.defaultDistributionsData);

		_data = Immutable.Map({
			filter: filter,
			distributionsData: distributionsData,
			valueData: null
		});
	},

	setMounted: function(m) {
		if (
			(_mounted == VConst.MNT_VALUES && m != VConst.MNT_VALUES) ||
			(_mounted != VConst.MNT_VALUES && m == VConst.MNT_VALUES)
		) {
			_mounted = m;
			_fetchDistributionsData();
		}

		_mounted = m;
	},

	mounted: function() {
		return _mounted;
	},

	onFilterStoreUpdate: function(newFilter) {
		/* Checking if filters have been saved locally by user
        *  If so, we set the filters to those values
        */



		if (!ParMan.isParDefined(minCF_ID)) {
			_data = _data.setIn(
				[ 'filter', 'minCF' ],
				ParMan.initParameter(
					minCF_ID,
					VConst.DEFAULT_MIN_CF,
					'number',
					NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null
				)
			);
			_data = _data.setIn(
				[ 'filter', 'maxCF' ],
				ParMan.initParameter(
					maxCF_ID,
					VConst.DEFAULT_MAX_CF,
					'number',
					NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null
				)
			);
			_data = _data.setIn(
				[ 'filter', 'binSize' ],
				ParMan.initParameter(
					binSize_ID,
					VConst.DEFAULT_BIN_SIZE,
					'number',
					NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null
				)
			);
			_data = _data.setIn(
				[ 'filter', 'guttingFactor' ],
				ParMan.initParameter(
					guttingFactor_ID,
					VConst.DEFAULT_GUTTING_FACTOR,
					'number',
					NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null
				)
			);
			_data = _data.setIn(
				[ 'filter', 'populationSize' ],
				ParMan.initParameter(
					populationSize_ID,
					VConst.DEFAULT_POPULATION_SIZE,
					'number',
					NavBarStore.filtersSaved() ? NavBarStore.getUserFilters : null
				)
			);
		}

		_fetchDistributionsData();
		this.trigger('', true);
	},

	onApiNewDistributionsData: function(newDistributionsData) {
		if (_mounted == VConst.MNT_VALUES) {
			var lst = newDistributionsData.WeightHistogramList;

			var endWgt = 1000;
			var lstKG = [];
			var lstLen = lst.length;

			var bioKgSum = 0;
			while (lstLen > 0 && lst[0].BinEndWeight > endWgt) {
				endWgt += 1000;
			}

			for (var i = 0; i < lstLen; i++) {
				if (lst[i].BiomassKG) bioKgSum += lst[i].BinBiomass;
				if (endWgt == lst[i].BinEndWeight) {
					var v = {
						BinBiomass: 0,
						BinBiomassPortion: 0,
						BinEndWeight: endWgt,
						BinId: (endWgt - 1000) / 1000,
						BinPopulation: 0,
						BinPopulationPortion: 0,
						BinStartWeight: endWgt - 1000,
						BiomassKG: bioKgSum
					};

					lstKG.push(v);
					endWgt += 1000;
					bioKgSum = 0;
				}
			}

			if (bioKgSum > 0) {
				var v = {
					BinBiomass: 0,
					BinBiomassPortion: 0,
					BinEndWeight: endWgt,
					BinId: (endWgt - 1000) / 1000,
					BinPopulation: 0,
					BinPopulationPortion: 0,
					BinStartWeight: endWgt - 1000,
					BiomassKG: bioKgSum
				};
				lstKG.push(v);
			}

			var dd = {
				WeightHistogramList: lstKG
			};
			_data = _data.set('valueData', dd);
		} else {
			_data = _data.set('valueData', null);

			let MinFishPrHour = FilterStore.getFilter().MinFishPrHour

			if (!MinFishPrHour )
				MinFishPrHour = 0
			//According suggestion from Bard.  Only for Vaki staff
		//	if (UserDataStore.IsEmployee()) {
				var dt = this.getFilter().get('displayDate');
				var dt1 = this.getFilter().get('startDate');
				var dt2 = this.getFilter().get('endDate');

				CF_ChartStore.fetchCF_ChartToFrom(FilterStore.getSelectedPopulationID(), dt, dt1, dt2, MinFishPrHour);
		//	}
		}

		var immutableDistributionsData = Immutable.Map(newDistributionsData);
		_data = _data.set('distributionsData', immutableDistributionsData);

		this.trigger(_data, false);
	},

	onDateUpdate: function(newDate) {
		var tmpDate = moment(newDate);

		/* one day avarage
        var newStartDate =  tmpDate.format(VConst.DATE_FORMAT);
        var newEndDate =   tmpDate.add(1, "days").format(VConst.DATE_FORMAT);
        */

		//5 days
		var newStartDate = moment(newDate).add(-2, 'days').format(VConst.DATE_FORMAT);
		var newDisplayDate = tmpDate.format(VConst.DATE_FORMAT);
		var newEndDate = tmpDate.add(3, 'days').format(VConst.DATE_FORMAT);

		ParMan.setItem(startDate_ID, newStartDate);
		ParMan.setItem(endDate_ID, newEndDate);
		ParMan.setItem(displayDate_ID, newDisplayDate);

		_data = _data.setIn([ 'filter', 'endDate' ], newEndDate);
		_data = _data.setIn([ 'filter', 'startDate' ], newStartDate);
		_data = _data.setIn([ 'filter', 'displayDate' ], newDisplayDate);
		_fetchAndTrigger();
	},

	onMinCFUpdate: function(newMinCF) {
		ParMan.setItem(minCF_ID, newMinCF, NavBarStore.saveFilter);
		_data = _data.setIn([ 'filter', 'minCF' ], newMinCF);

		_fetchAndTrigger();
	},

	onMaxCFUpdate: function(newMaxCF) {
		ParMan.setItem(maxCF_ID, newMaxCF, NavBarStore.saveFilter);
		_data = _data.setIn([ 'filter', 'maxCF' ], newMaxCF);
		_fetchAndTrigger();
	},

	onBinSizeUpdate: function(newBinSize) {
		if (isNaN(newBinSize)) newBinSize = null;
		ParMan.setItem(binSize_ID, newBinSize, NavBarStore.saveFilter);

		_data = _data.setIn([ 'filter', 'binSize' ], newBinSize);

		_fetchAndTrigger();
	},

	onGuttingFactorUpdate: function(newGuttingFactor) {
		ParMan.setItem(guttingFactor_ID, newGuttingFactor, NavBarStore.saveFilter);
		_data = _data.setIn([ 'filter', 'guttingFactor' ], newGuttingFactor);
		_fetchAndTrigger();
	},

	onPopulationSizeUpdate: function(newPopulationSize) {
		//NavBarStore.saveFilter("populationSize", newPopulationSize);

		ParMan.setItem(populationSize_ID, newPopulationSize, NavBarStore.saveFilter);
		_data = _data.setIn([ 'filter', 'populationSize' ], newPopulationSize);
		_fetchAndTrigger();
	},

	getFilter: function() {
		return _data.get('filter');
	},

	getDistributionsData: function() {
		return _data.get('distributionsData');
	},

	getValueData: function() {
		return _data.get('valueData');
	},

	getData: function() {
		return _data;
	}
});

/**
 * Convenience function to basically save me writing those 2 lines in all the
 * onFunctions. Yeah I'm lazy.
 */
function _fetchAndTrigger() {
	_fetchDistributionsData();
	// DistributionStore.trigger(_data);
	_data.set('distributionsData', null), DistributionStore.trigger(_data, true);
}

function _fetchDistributionsData() {
	var selectedPopulationID = FilterStore.getSelectedPopulationID();

	if (selectedPopulationID && selectedPopulationID !== 0) {
		Actions.distStartFetching();
		var filter = _data.get('filter');

		var binSiz = 500; //dirty stuff because we are sharing DistributionStore
		if (_mounted != VConst.MNT_VALUES) binSiz = filter.get('binSize');

		var mw = FilterStore.getFilter().minWeight;

		var nl = 0;
		var nll = null;



		VakiAPI.fetchDistributions({
			populationID: selectedPopulationID,
			startDate: filter.get('startDate'),
			endDate: filter.get('endDate'),
			/* Not sure it's a good idea to get min/max weight like this from
             * the FilterStore, but the best thing I could think of. */
			minWeight: FilterStore.getFilter().minWeight == null ? -1 : FilterStore.getFilter().minWeight,
			maxWeight: FilterStore.getFilter().maxWeight == null ? -1 : FilterStore.getFilter().maxWeight,
			minCF: filter.get('minCF'),
			maxCF: filter.get('maxCF'),
			starvedWeightFactor: 0, // note entirely sure what this does
			guttingFactor: filter.get('guttingFactor'),
			CFBinSize: VConst.DEFAULT_CF_BIN_SIZE,
			weightBinSize: !binSiz ? -1 : binSiz,
			PopulationSize: filter.get('populationSize'),
			MinHourCount: FilterStore.getFilter().MinFishPrHour == null ? 0 : FilterStore.getFilter().MinFishPrHour
		});
	}
}

module.exports = DistributionStore;
