

"use strict";

import React from "react";

import moment from "moment";

import Vutils from "./../../utils/Vutil.js";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import FilterStore from "../../stores/FilterStore";
import SessionStore from "./../../stores/SessionStore.js";
import DashboardStore from "./../../stores/DashboardStore.js";
import Spinner from "./../../utils/Spinner.jsx";
import DashboardListCanvas from "./DashboardListCanvas.jsx";
import Actions from "./../../actions/Actions.js";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import TranslateStore from "./../../stores/TranslateStore.js";
var t = TranslateStore.getTranslate();
/*
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Alert from 'react-bootstrap/Alert';
*/

import {OverlayTrigger, Popover, Alert, Button, Row, Col, Well} from 'react-bootstrap';



var getUtilDetails = function (farm) {

    var data = farm.UtilData;

    if (!data) {
        return "";
    }

    var numPops = data.popUtils.length;
    var theData = [];

    for (var i = 0; i < numPops; i++) {
        var pop = FilterStore.getPopulationByID(data.popUtils[i].itemID);
        var popNam = "";
        if (pop)
            popNam = pop.Name;
        theData.push({
            PopName: popNam,
            day7: data.popUtils[i].day7Summary + "%",
            day30: data.popUtils[i].day30Summary + "%"

        })

    }


    var theTable =
        (<BootstrapTable data={theData} thStyle={{ verticalAlign: 'top', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'red' }}  >

            <TableHeaderColumn dataField='PopName' dataAlign="center" isKey >{t("dashboard.population")}    </TableHeaderColumn>
            <TableHeaderColumn dataField='day7' headerAlign="center"  > {t("dashboard.7day")}  </TableHeaderColumn>
            <TableHeaderColumn dataField='day30' dataAlign="center"  > {t("dashboard.30day")} </TableHeaderColumn>
            

        </BootstrapTable>);



    return <div>
        <div className="UtilDesc">
            {t("dashboard.UtilPopTx1")}  <b>7</b> / <b>30 </b> {t("dashboard.UtilPopTx2")}
            <br />  {t("dashboard.UtilPopTx3")}
            <br /> {t("dashboard.UtilPopTx4")}

        </div>
        {theTable}

    </div>
};







export default class  OrgOverview extends React.Component{
    static propTypes = {
    

    };

  constructor(props) {
     super(props);
       this.state = {
            theOrgs: null
          
        };

    this.getOrgDashboard = ( ) => {
        //var populationStatusList = this.state.dashboardData.get("PopulationStatusList");
        var self = this;
        var expandClass = "clsExpand";
        var imgPath = "img/expand50.png";



        if (!this.props.theOrgs){
            expandClass = "clsHide";
        }else if (this.props.showDetails){
            imgPath = "img/collapse50.png"
        }
        
        var allFarms = <div></div>;



        if (this.props.theOrgs && this.props.showDetails){
            allFarms = this.props.theOrgs.DashboardList.map(function (farm, i) {
                var cls = "clsOrgFarmBottomRowBorder"
                if (i==0);
                cls += " clsOrgFarmRowTopBorder"
                if ((i%2)==0)
                    cls +=  " clsFarmRowEven";
                return (
                    <Row key={i} className= {cls} >

                            <Col md={2} sm={3} xs={4} className="dashboard-list-farm">
                            <a href="#" onClick={self.farmClicked.bind(null, farm)}><strong key={farm.FarmID}> {farm.FarmName} </strong></a>
                            </Col>
                            <Col md={2} sm={2} xs={3} className="UtilVal">
                                <div>
                                <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover title={<center><b>{farm.FarmName}</b></center>}  > {getUtilDetails(farm)} </Popover >}>
                                <div>
                                    <br/>
                                        <strong key={i}>{DashboardStore.getFarmUtilization(farm, <Spinner />)} </strong>
                                    </div>
                                </OverlayTrigger >
                            </div>
                        </Col>

                        <Col md={8} sm={7} xs={12} >
                            <DashboardListCanvas populationStatusList={farm.PopulationStatusList} thisFarm={farm.FarmName} />
                        </Col>
                        </Row>
                );
});
}

var classNam = "clsAllOrgsHdrDiv";
var imgPath = "img/expand50.png"
var imgPathFooter = "img/collapse50.png"

var styleFooter = {display:'none'}





if (this.props.showDetails){
    classNam += " clsAllOrgsHdrDivDetails"
    imgPath = "img/collapse50.png"
    var styleFooter = {display:'block'}
}

return (
    <div >

         

     <div className={classNam} onClick={this.props.onclickfunc}>
     <strong>{FilterStore.getSelectedOrgName()}</strong>
            <p><img src={imgPath} className="clsExpandImgHeight" /></p>
     </div>


 <div id= "divOrgFarms"  className="clsOrgBlock" >

     {allFarms}
 </div>

           <div className="clsAllOrgsHdrDivFooter" style={styleFooter} onClick={this.props.onclickfunc}>
            <p><img src={imgPathFooter} className="clsExpandImgHeight" /></p>
           </div>


</div>
);


};


this.getAllDashboards = ( ) => {
    
    var self = this;


    var n = 0;
      
    var allDashboards = <div></div>;

    if (this.props.showDetails) {

        allDashboards = this.props.theOrgs.map (function(org, i) {
            if (org.DashboardList.length === 0) return;
            n = 0;
            var allFarms = org.DashboardList.map(function (farm, i) {
 
                var cls = "clsOrgFarmBottomRowBorder"
                if (n == 0);
                cls += " clsOrgFarmRowTopBorder"
                if ((n % 2) == 0)
                    cls += " clsFarmRowEven";
                    n++;
                n++;


                return (
                    <Row key={i} className={cls} >

                        <Col md={2} sm={3} xs={4} className="dashboard-list-farm">
                            <a href="#" onClick={self.farmClicked.bind(null,farm)}><strong key={i}> {farm.FarmName} </strong></a>
                        </Col>
                        <Col md={2} sm={2} xs={3} className="UtilVal">
                            <div>
                                <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover title={<center><b>{farm.FarmName}</b></center>}  > {getUtilDetails(farm)} </Popover >}>
                                    <div>
                                        <br />
                                        <strong key={i}>{DashboardStore.getFarmUtilization(farm, <Spinner />)} </strong>
                                    </div>
                                </OverlayTrigger >
                            </div>
                        </Col>

                        <Col md={8} sm={7} xs={12} >
                            <DashboardListCanvas populationStatusList={farm.PopulationStatusList} thisFarm={farm.FarmName} />
                        </Col>
                    </Row>
                );
            });


            return (
                <div  className = "well well-sm"  key={i}>
                <div className= "clsOrgHeader">
                <h4> {org.Name} </h4>
                </div>
                {allFarms}
                </div>
                );
        });
    }



var classNam = "clsAllOrgsHdrDiv";
    var imgPath = "img/expand50.png"

var imgPathFooter = "img/collapse50.png"

var styleFooter = {display:'none'}




if (this.props.showDetails){
    classNam += " clsAllOrgsHdrDivDetails"
    imgPath = "img/collapse50.png"
    var styleFooter = {display:'block'}
}
    return(
    <div>   
            <div className={classNam} onClick={this.props.onclickfunc}  tabstop="0" >     <strong> {t("dashboard.allOrgs")}</strong> 
                <p><img src={imgPath} className="clsExpandImgHeight"/></p>
        </div>


 <div  className="clsOrgBlock">
     {allDashboards}
     </div>

     <div className="clsAllOrgsHdrDivFooter" style={styleFooter} onClick={this.props.onclickfunc}  tabstop="0">
                <p><img src={imgPathFooter} className="clsExpandImgHeight" /></p>
     </div>
 </div>
)
};



  }; 




    farmClicked (farm) {
        Actions.farmClick(farm.FarmID);
    };
















render () {

             
        var theStuff = "";
   if(this.props.currentOrg){
         
       theStuff = this.getOrgDashboard();
    }else{
        theStuff = this.getAllDashboards();
    }
       

        return (

                        <div>


     {theStuff}

 </div>

        )
    }
};








