'use strict';

import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col, Form, OverlayTrigger, Alert, Container, Card, useAccordionButton, Collapse } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';
import VakiAPI from '../../webservice/VakiAPI.js';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from './../../utils/Spinner.jsx';
import {QuestionCircle} from 'react-bootstrap-icons';
import SearchDropdownCamera from './SearchDropdownCamera.jsx';
import OrphanSelect from './OrphanSelect.jsx';
import DeviceLog from './DeviceLog.jsx';
import MoveMeasurements from './MoveMeasurements.jsx';

require('./CameraManagement.css');

export default function CameraSetup(props) {
	const [ farm, setFarm ] = useState({});
	const [ alertMsg, setAlertMsg ] = useState({ msg: '', kind: '' });
	const [ cameraList, setCameraList ] = useState([]);
	const [ editCamera, setEditCamera ] = useState(null);
	const [ editModel, setEditModel ] = useState('');
	const [ editFirmware, setEditFirmware ] = useState('');
	const [ editSN, setEditSN ] = useState('');
	const [ editInUse, setEditInUse ] = useState(false);
	const [ editType, setEditType ] = useState(0); //BiomassCamera by default
	const [ popList, setPopList ] = useState([]); //For populating dropdown
	const [ cageList, setCageList ] = useState([]); //ditto

	const [ selectedPopID, setSelectedPopID ] = useState(-1);
	const [ selectedCageID, setSelectedCageID ] = useState(-1);

	const [ loadingPopsCages, setLoadingPopsCages ] = useState(false);
	const [ UpdtCount, setUpdtCount ] = useState(0);
	const [ editDetach, setEditDetach ] = useState(false);
	const [ showAddOrphanOption, setShowAddOrphanOption ] = useState(false);
	const [ loadingOrphanOption, setLoadingOrphanOption ] = useState(false);
	const [ orphanList, setOrphanList ] = useState([]);

	const [ showDeviceLog, setShowDeviceLog ] = useState(false);
	const [ showMoveMeas, setShowMoveMeas ] = useState(false);
  const [openInfo, setOpenInfo] = useState (false);
	const TheTable = useRef(null);

	//   const [editOpen, setEditOpen] = useState(false);

	const GetOrphanCamerasCallback = (data) => {
		if (data.msg != '' && data.msg.length > 5 && data.msg.substring(0, 5) == 'Error') {
			setLoadingOrphanOption(false);
			setShowAddOrphanOption(false);
			setAlertMsg({
				msg: 'Error fetching orphan cameras: ' + data.msg,
				kind: 'danger'
			});
			return;
		}

		setOrphanList(data.dacList);
		setLoadingOrphanOption(false);
	};
	const onAddOrphanOptionClick = () => {
		setShowAddOrphanOption(true);
		setLoadingOrphanOption(true);

		//Fetch orphans:
		VakiAPI.GetFarmCameraList({ FarmID: -1 }, GetOrphanCamerasCallback);
	};

	const GetCamerasCallback = (data) => {


		if (data.msg != '' && data.msg.length > 5 && data.msg.substring(0, 5) == 'Error') {
			setAlertMsg({
				msg: data.msg,
				kind: 'danger'
			});
			return;
		}

		setCameraList(data.dacList);

		let dt = new Date();
		dt.setDate(dt.getDate() - 730); //2 years back
		let dtString = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
		setLoadingPopsCages(true);
		//VakiAPI.GetAllFarmsForOrg({OrgID: props. } )

		VakiAPI.GetPopulationAndCageLists(
			{ FarmID: props.farm, PopFromDate: dtString, partOnly: null },
			GetPopsCagesCallback
		);
	};

	useEffect(
		() => {

			// setFarm(props.farm);
			VakiAPI.GetFarmCameraList({ FarmID: props.farm }, GetCamerasCallback);
		},
		[ UpdtCount, props.farm, props.refreshCount ]
	);

	const clearAlert = () => {
		setAlertMsg({ msg: '', kind: '' });
	};

	const onSaveCallback = (msg) => {
		setLoadingPopsCages(false);
		if (msg != '' && msg.length > 5 && msg.substring(0, 5) == 'Error') {
			setAlertMsg({
				msg: msg,
				kind: 'danger'
			});
			return;
		}
		setAlertMsg({
			msg: 'Camera setup saved succesfully',
			kind: 'info'
		});

		clearSelection();
		setSelectedCageID(-1);
		setSelectedPopID(-1);
		setEditCamera(null);
		setEditFirmware('');
		setEditInUse(false);
		setEditModel('');
		setEditSN(0);
		setEditType(0);
		setShowAddOrphanOption(false);
		setUpdtCount(UpdtCount + 1);
	};

	const clearSelection = () => {
		if (!TheTable || !TheTable.current || !TheTable.current.selectionContext) return;
		TheTable.current.selectionContext.selected = [];
		setEditCamera(null);
		setSelectedCageID(-1);
		setSelectedPopID(-1);
		setEditModel('');
		setEditFirmware('');
		setEditSN(0);
		setEditInUse(false);
		setEditDetach(false);
		clearAlert();
	};

	const onSave = () => {
		clearAlert();
		let dev = null;
		setLoadingPopsCages(true);
		if (editCamera == null) {
			let theType = editSN % 10;
			if (!(theType == 5 || theType == 6)){
				setAlertMsg({
					msg: 'Illegal format on serial number',
					kind: 'danger'
				});
				return;

			}
			dev = {
				Farm_ID: props.farm,
				Serial_Number: editSN,
				Population_ID: selectedPopID,
				Cage_ID: selectedCageID,
				Model: editModel,
				Firmware_Version: editFirmware,
				Device_ID: -1,
				Connection_ID: -1,
				In_Use: editInUse,
				Device_Type_Id: theType,
				PopName: '',
				CageName: '',

				ConnCreated: '',
				DeviceCreated: ''
			};
		} else {
			editCamera.Population_ID = selectedPopID;
			editCamera.Cage_ID = selectedCageID;
			editCamera.Model = editModel;
			editCamera.Firmware_Version = editFirmware;
			editCamera.In_Use = editInUse;
			dev = { ...editCamera };

			if (editDetach) {
				dev.Population_ID = null;
				dev.Cage_ID = null;
				dev.Farm_ID = null;
			}
		}
		VakiAPI.UpdateCameraConnection(dev, onSaveCallback);
	};

	const onTableClick = (e) => {};

	const onPopSelect = (e, r) => {
		clearAlert();
		setSelectedPopID(e.target.value);
	};

	const onCageSelect = (e, r) => {
		clearAlert();
		setSelectedCageID(e.target.value);
	};

	const GetPopsCagesCallback = (data) => {
		setLoadingPopsCages(false);

		if (data.msg != '' && data.msg.length > 5 && data.msg.substring(0, 5) == 'Error') {
			
			setAlertMsg({
				msg: data.msg,
				kind: 'danger'
			});
			return;
		}

		setPopList(data.popList);


		setCageList(data.cageList);
	};

	const onEditClick = () => {
		if (loadingPopsCages) return;

		if (popList.length == 0 && cageList.length == 0) {
			let dt = new Date();
			dt.setDate(dt.getDate() - 730); //2 years back
			let dtString = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
			setLoadingPopsCages(true);
			VakiAPI.GetPopulationAndCageLists(
				{ FarmID: props.farm, PopFromDate: dtString, partOnly: null },
				GetPopsCagesCallback
			);
		}
	};

	function CustomToggle({ eventKey }) {
		const decoratedOnClick = useAccordionButton(eventKey, onEditClick);

		return (
			<div className="cs-edit-btn" onClick={decoratedOnClick}>
				Edit
			</div>
		);
	}




	
	let TestData = [
		{ Serial_Number: 1234565, Inserted_Datetime: '2021-11-10', Farm: 'Farm1' },
		{ Serial_Number: 1231235, Inserted_Datetime: '2021-1-8', Farm: 'Farm Leeeeeengra nafn' },
		{ Serial_Number: 2133345, Inserted_Datetime: '2020-12-12', Farm: 'Farmurinn eini' }
	];

	const formatDate = (cell, row) => {
		return cell.substring(0, 10);
	};

	const formatIn_Use = (cell, row) => {
		return (
			<div className="center-width100 ">
				{' '}
				<Form.Check checked={cell} className="mb-4" readOnly />{' '}
			</div>
		);
	};

	const columns = [
		{
			dataField: 'Serial_Number',
			text: 'SN'
		},
		{
			dataField: 'DeviceCreated',
			text: 'Created',
			formatter: formatDate
		},
		{
			dataField: 'In_Use',
			text: 'In Use',
			formatter: formatIn_Use
		},
		{
			dataField: 'PopName',
			text: 'Pop.'
		},
		{
			dataField: 'CageName',
			text: 'Cage'
		}
	];

	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			clearAlert();

			setEditCamera({ ...row });
			setSelectedPopID(row.Population_ID);
			setSelectedCageID(row.Cage_ID);
			setEditModel(row.Model);
			setEditFirmware(row.Firmware_Version);
			setEditSN(row.Serial_Number);
			setEditInUse(row.In_Use);
			setEditType(row.Device_Type_Id);
		}
	};

	const OrphanSelectCallback = (obj) => {
		obj.Farm_ID = props.farm;
		VakiAPI.UpdateCameraConnection(obj, (msg) => {
			onSaveCallback(msg);
			if (msg.substring(0, 5) != 'Error') setShowAddOrphanOption(false);
		});
	};

	/*
"dacList": [
    {
      "CageName": "",
      "Cage_ID": null,
      "ConnCreated": null,
      "Connection_ID": 33850,
      "DeviceCreated": "/Date(1637924193617+0000)/",
      "Device_ID": 1741,
      "Farm_ID": 435,
      "PopName": "",
      "Population_ID": null,
      "Serial_Number": 7777772
    },
*/

	let PopOptions = [];
	PopOptions.push(
		<option key={0} value={-1}>
			None selected...
		</option>
	);

	let CageOptions = [];
	CageOptions.push(
		<option key={0} value={-1}>
			None selected...
		</option>
	);

	let DeviceLogTable = <div />;

	let DetachMessage = '';

	if (editDetach && editCamera != null)
		DetachMessage = (
			<h6 className="mt-3">
				Selected camera will be detached from farm upon <b> Save!</b>{' '}
			</h6>
		);

	let TheCardBody = <Spinner />;
	if (!loadingPopsCages) {
		if (popList.length > 0) {
			let len = popList.length;
			for (let i = 0; i < len; i++)
				PopOptions.push(
					<option key={i + 1} value={popList[i].PopID}>
						{' '}
						{popList[i].Name +
							' --- ' +
							popList[i].Species +
							' --- ' +
							popList[i].CreatedDate.slice(0, 10)}{' '}
					</option>
				);
		}

		if (cageList.length > 0) {
			let len = cageList.length;

			for (let i = 0; i < len; i++)
				CageOptions.push(
					<option key={i + 1} value={cageList[i].CageID}>
						{' '}
						{cageList[i].Name + ' --- ' + cageList[i].CreatedDate.slice(0, 10)}{' '}
					</option>
				);
		}

	
		let hideActivePopAlert = true;
		let popActiveMsg = "";
		if (selectedPopID != -1){

			let poplen = popList.length;
			for (let i = 0; i<poplen; i++)

				if (popList[i].PopID == selectedPopID){
				
					if (editInUse){
					   hideActivePopAlert = popList[i].Active;
					   popActiveMsg = "Selected population is NOT set active and might not be displayed";
					}else{
						hideActivePopAlert = !popList[i].Active;
						popActiveMsg = "The population is set ACTIVE albeit camera not in use";
					}
					break;
				}

		}

		TheCardBody = (
			<div>
				<Row>
					<Col>
						<Form.Label className="mb-0">Model </Form.Label>
						<Form.Control
							value={editModel == null ? '' : editModel}
							onChange={(e) => setEditModel(e.target.value)}
							type="text"
							className="mb-3"
						/>
					</Col>
					<Col>
						<Form.Label className="mb-0">Device type </Form.Label>
						<Form.Select
							aria-label="Device select"
							size="lg"
							onChange={(e) => setEditType(e.target.value)}
							value={editType}
							disabled = {true} //{editCamera != null}
						>
							<option value="0"> </option>
							<option value="5">Biomass Camera</option>
							<option value="6">Lice Camera</option>
						</Form.Select>
					</Col>

					<Col>
						<Form.Label className="mb-0">Firmware </Form.Label>
						<Form.Control
							readOnly
							value={editFirmware == null ? '' : editFirmware}
							/*onChange={(e) => setEditFirmware(e.target.value)}*/
							type="text"
							className="mb-3"
						/>
					</Col>
				</Row>

				<Form.Group className=" mb-3">
					<Form.Label className="mb-0">Population </Form.Label>
					<Form.Select
						aria-label="select poplation"
						className="mb-0"
						onChange={onPopSelect}
						className="width100"
						value={selectedPopID}
						disabled = {editInUse}
					>
						{PopOptions}
					</Form.Select>
				</Form.Group>
				<Alert variant={"warning"} hidden={hideActivePopAlert}>
					{' '}
					{ popActiveMsg}{' '}
				</Alert>
				<Form.Group className=" mb-3">
					<Form.Label className="mb-0">Cage </Form.Label>
					<Form.Select
						aria-label="select cage"
						className="mb-0"
						onChange={onCageSelect}
						className="width100"
						value={selectedCageID}
					>
						{CageOptions}
					</Form.Select>
				</Form.Group>

				<Form.Check

					checked={editInUse}
					onChange={(e) => setEditInUse(e.target.checked)}
					label="Camera in use"
					className="mb-4"
					disabled =   {!editInUse && selectedPopID == -1}
				/>

				<Form.Check
					checked={editDetach}
					onChange={(e) => setEditDetach(e.target.checked)}
					label="Detach camera from farm"
					disabled={!editCamera}
				/>

				{DetachMessage}
			</div>
		);
	}

	let AnyChange = false;
	if (editCamera) {
		AnyChange =
			editDetach ||
			editCamera.Population_ID != selectedPopID ||
			editCamera.Cage_ID != selectedCageID ||
			editCamera.Model != editModel ||
			editCamera.Firmware_Version != editFirmware ||
			editCamera.In_Use != editInUse;
	} else {
		let n = editSN % 10;
		AnyChange = editSN > 10 && (n==5 || n==6) ;
	}

	var thetabcurrent = TheTable.current;
	let ert = 113113;
	let disableClearSelect = false;

	if (
		!TheTable ||
		!TheTable.current ||
		!TheTable.current.selectionContext ||
		!TheTable.current.selectionContext.selected ||
		TheTable.current.selectionContext.selected.length == 0
	)
		disableClearSelect = true;

	let AddOrphanOption = '';


	if (!showAddOrphanOption) {
		AddOrphanOption = (
			<Button onClick={onAddOrphanOptionClick} variant="outline-dark" className="mb-3">
				Add orphan camera
			</Button>
		);
	} else {
		if (loadingOrphanOption) {
			AddOrphanOption = (
				<div className="center-width100">
					{' '}
					<Spinner />{' '}
				</div>
			);
		} else {
			AddOrphanOption = <SearchDropdownCamera OrphanList={orphanList} select={OrphanSelectCallback} />;
			//AddOrphanOption = <OrphanSelect OrphanList = {orphanList}  Select = {OrphanSelectCallback} />
		}
	}





	return (
		<div>
			<Container>
				<Alert variant={alertMsg.kind} hidden={alertMsg.msg == ''}>
					{' '}
					{alertMsg.msg}{' '}
				</Alert>

				<Row>
					<Col md="6">
						<div className="cs-leftpane">
							<Button
								onClick={clearSelection}
								variant="outline-dark"
								disabled={disableClearSelect}
								className="mb-3"
							>
								Clear selection
							</Button>

<div className="cs-button-right" onClick = {() => setOpenInfo (!openInfo)}>
< QuestionCircle  width="1.5rem" height="1.5rem" />
</div>
 <Collapse in={openInfo}  timeout={1000} >
        <div className="cs-info-text">
       <p> Click a row in a table to view and edit details of that particular setup. </p>
        <p> Click <b><i>Clear selection</i></b> to clear up the edit board and make it ready for the creation of a new camera setup </p>
		<p>  The population dropdown is only editable while the  <b><i>In use</i></b> box is un-checked </p>
		<p>  When a camera is detached from farm, the <b><i>In use</i></b> is automatically unchecked and population and cage are disconnected before it is added to the pool of orphan cameras - and can from there be hooked to the same or any other farm when needed  </p>
        </div>
      </Collapse>




							<BootstrapTable
								ref={TheTable}
								keyField="Serial_Number"
								data={cameraList}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped={false}
								keyField="Serial_Number"
								onSelect={onTableClick}
								rowEvents={rowEvents}
								selectRow={{
									mode: 'radio',

									clickToSelect: true,

									style: { background: '#def3ff' }
								}}
							/>
						</div>
						{AddOrphanOption}
					</Col>
					<Col>
						<div className="cs-rightpane">
							<Card>
								<Card.Header className="center-width100">
									<div className="cs-font-large ">
										{editCamera == null ? 'Create new camera setup' : 'Edit camera setup'}
									</div>
								</Card.Header>

								<div>
									<Card.Body>
										<Row>
											<Col md="4">
												<Form.Label className="mb-0">SN</Form.Label>
												<Form.Control
													value={editSN == 0 ? '' : editSN}
													readOnly={editCamera != null}
													type="number"
													className="mb-3"
													onChange={(e) => setEditSN(e.target.value)}
												/>
											</Col>
											<Col>
												<Form.Label className="mb-0">Device</Form.Label>
												<Form.Control
													value={editCamera == null ? '' : editCamera.DeviceCreated}
													readOnly
													type="text"
													className="mb-3"
												/>
											</Col>
											<Col>
												<Form.Label className="mb-0">Connection</Form.Label>
												<Form.Control
													value={editCamera == null ? '' : editCamera.ConnCreated}
													readOnly
													type="text"
													className="mb-3"
												/>
											</Col>
										</Row>

										{TheCardBody}
									</Card.Body>

									<Card.Footer>
										<Button onClick={onSave} variant="outline-dark" disabled={!AnyChange}>
											Save
										</Button>
									</Card.Footer>
								</div>
							</Card>
							<div  className = "center-width100">
							<Button
								className = "mt-3 mb-3 float-left"
								onClick={() => {
									let tmp = showMoveMeas;
									setShowMoveMeas(!tmp);
								}}
								variant="outline-dark"
								disabled={!editCamera || !editCamera.Population_ID || editCamera.Population_ID <=0 }
							>
								Move measurements
							</Button>

							<Button
								className = "mt-3 mb-3 float-right"
								onClick={() => {
									let tmp = showDeviceLog;
									setShowDeviceLog(!tmp);
								}}
								variant="outline-dark"
								disabled={!editCamera}
							>
								Changelog for device
							</Button>
							</div>
						</div>
					</Col>
				</Row>


				<MoveMeasurements
					Device_ID={!editCamera ? -1 : editCamera.Device_ID}
					Show={showMoveMeas}
					Serial_Number={(!editCamera ? "" :editCamera.Serial_Number.toString())}
					PopID = {!editCamera ? -1 : editCamera.Population_ID }
		
				/>

				<DeviceLog
					Device_ID={!editCamera ? -1 : editCamera.Device_ID}
					Show={showDeviceLog}
					Serial_Number={!editCamera ? '' : editCamera.Serial_Number.toString()}
				/>
			</Container>
		</div>
	);
}

/*

													<Button
														onClick={()=>{ let tmp = showDeviceLog; setShowDeviceLog(!tmp) }}
														variant="outline-dark"
														disabled={false}
													>
														Log for device
													</Button>

													<DeviceLog  Device_ID={!editCamera?-1:editCamera.Device_ID} Show={ShowDeviceLog} />

*/

/*
								<Accordion defaultActiveKey="0">
									<Card>
										<Card.Header>
											<CustomToggle eventKey="1" />
										</Card.Header>
										<Accordion.Collapse eventKey="1">
											<div>
												<Card.Body>{TheCardBody}</Card.Body>

												<Card.Footer>
													<Button
														onClick={onSave}
														variant="outline-dark"
														disabled={!AnyChange}
													>
														Save
													</Button>
												</Card.Footer>
											</div>
										</Accordion.Collapse>
									</Card>
								</Accordion>

*/
