/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
import {Button, Form} from "react-bootstrap";
//import {DatePicker}  from "react-bootstrap-date-picker";

var VConst = require("./../../utils/VConst.js");
import PropTypes from "prop-types";







/**
 * A filter for picking a single calendar date, for example 2015.08.17.
 *
 * The component takes in as props a moment date object, for docs please see:
 * http://momentjs.com/
 *
 * Second parameter is the Reflux action function that is invoked once the
 * date has changed.
 */
export default class SingleDatePicker   extends React.Component{
    static propTypes = {
        date: PropTypes.object.isRequired, // moment object
        actionFunction: PropTypes.func.isRequired
    };

constructor(props) {
		super(props);

    this.handleEvent = ( event, datePicker) => {
        this.props.actionFunction(event.target.value);
         
     
    };
};


    render () {
        var dateAsStr = this.props.date.format(VConst.DATE_FORMAT);
       

        return (
         <Form.Control type="date" name='the date'   value={dateAsStr}   onChange={(e)=>this.props.actionFunction(e.target.value)} />
       
        )
    }
};
