/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

import React from "react";
import {Table } from "react-bootstrap";
import OrgListRow from"./Rows/OrgListRow.jsx";

 export default class  AdminViewOrgList extends React.Component{
	shouldComponentUpdate (nextProps, nextState) {
	    if(nextProps.tableActive === false) {
	    	return false;
	    }
	    return true;
	};
	render ( ) {
		var self = this;
		var orgTableRows = this.props.orgs.map(function(org, i) {
			return (
				<OrgListRow org={org} key={i} t={self.props.t} />
			);
		});
		return (
			<div>
			<div className="table-wrapper">
	    		<Table striped bordered condensed = {"true"} hover className="admin-view-table">
	    			<thead>
	    				<tr>
		    				<th className="col-md-4"> {this.props.t("adminView.name")} </th>
		    				<th className="col-md-3"> {this.props.t("adminView.address")} </th>
		    				<th className="col-md-2"> Users </th>
		    				<th className="col-md-2"> Farms </th>
		    				<th className="col-md-2"> Del </th>
	    				</tr>
	    			</thead>
	    		</Table>
	    	</div>
			<div className="table-wrapper">
	    		<Table striped bordered condensed = {"true"} hover className="admin-view-table">
	    			<tbody>
	    				{orgTableRows}
	    			</tbody>
	    		</Table>
	    	</div>
	    	</div>
		);
	}
};

