"use strict";
var moment = require("moment");




var popTimer = null;
var bigOne = false;
var showing = false;

const thePopClass = "clsPop";
var DUtils = {

    thePopClick: function (e){
        var thePop = document.getElementsByClassName(thePopClass)[0];
        if (popTimer)
            clearTimeout(popTimer);
        popTimer = null;

        if (thePop) {
            thePop.style.display = "none";
            showing = false;
        }
    },



    doComments: function () {

    }, 



    theMouseEnter: function (e, text,popWidth, lines, params) {



        var body = document.body;

        var oneEm = 18;
        var fs =
            (
                body.currentStyle ||
                (window.getComputedStyle && getComputedStyle(body, null)) ||
                body.style
            ).fontSize;
        var em = 0;
        if (fs && fs !== "") {
            em = parseInt(fs);
            if (em > 0)
                oneEm = em;
        }

        if (popWidth < 0) {
            popWidth = 60;
            var emWidth = window.innerWidth / oneEm;

            
            if (emWidth + 4 <= 60)
                popWidth = emWidth - 4;




        }


    //var thePop = document.getElementById (thePopID)
        var v = document.getElementsByClassName(thePopClass);
    if (v.length != 1) {
        console.error("Exactly one element allowed with classname '" + thePopClass+"'");
        return;
    }
    var thePop = v[0];
    if (popTimer){
        clearTimeout(popTimer);
        popTimer = null;
    }


    if (!popWidth || popWidth <= 0)
        popWidth = 18;


    var theX = e.clientX;
    var theY = e.clientY;

        bigOne = false;
        if (lines && (lines * (oneEm+5)) > (theY - 40)) {
           bigOne = true;
    }




    popTimer = setTimeout(function () {

 
        var theBody = document.body.getBoundingClientRect();
        var minPixRight = (popWidth + 1.7) * oneEm;

        if (!text)
            text = "####";
        
        if ((typeof text).toUpperCase() === "FUNCTION") {
            
            text(thePop, params, bigOne);
        } else {

            if (text && text.length > 0)
                thePop.innerHTML = text;
        }
        thePop.style.width = popWidth + "em"
        thePop.style.top = null;
        thePop.style.botttom = null;
        thePop.style.height = "auto";
        thePop.style.overflowY = "hidden";  

        if (bigOne) {
            thePop.style.top = ((oneEm*1.34) - theBody.y) + "px";
            thePop.style.overflowY = "scroll";
            var h = (lines * (oneEm*1.34));

            if (h > window.innerHeight * 0.80) {
                h = window.innerHeight * 0.80;
               
            }
            
           
            thePop.style.height = h + "px";
            
            var l = (window.innerWidth/oneEm - popWidth) / 2;
            if (l < 0)
                l = 0;


         

            thePop.style.left = l + "em";


        } else {
            thePop.style.bottom = (window.innerHeight - theY + 20 + theBody.y) + "px";
            if (minPixRight && (window.innerWidth - theX) < minPixRight) {
                thePop.style.left = (window.innerWidth - minPixRight) + "px";
            } else {
                thePop.style.left = (theX) + "px";
            }
        }
        thePop.style.display = "block";
        showing = true;
        if (document.getElementById("theCommentInput"))
            document.getElementById("theCommentInput").select();
        popTimer = null;
    }, 600);
},


    theMouseLeave: function () {

  
        if (bigOne) {
            if (popTimer)
                clearTimeout(popTimer);
            popTimer = null;
            return;
        }


        var thePop = document.getElementsByClassName(thePopClass)[0];
        if (popTimer)
            clearTimeout(popTimer);
        popTimer = null;

        if (thePop) {
            thePop.style.display = "none";
            showing = false;
        }
    },



    showComments: function (d, comments, sticky) {
        d.innerHTML = "";
        var divHeader = document.createElement('h4');
        divHeader.className = "text-center";
        divHeader.innerHTML = "<b>Comments from last 30 days</b>"
        d.appendChild(divHeader);
        var divBody = document.createElement('div');
        divBody.className = "clsPopBody";
        var str = "";
        for (var cc = 0; cc < comments.length; cc++) {
            if (cc > 0)
                str += "<br/>";
            str += "<b>" + comments[cc].Username + "</b> (" + moment(comments[cc].Pertinent_Datetime).format("DD/MM") + "): " + comments[cc].Text;
        }
        divBody.innerHTML = str;
        d.appendChild(divBody);

        var divFooter = document.createElement('div');
        divFooter.className = "clsPopFooter";
        if (sticky)
            divFooter.innerHTML = "Click it to close it"
        else
            divFooter.innerHTML = "Click icon to add a comment"

        var divMsg = document.createElement('div');
        divMsg.innerHTML = "Write comment:";
        divMsg.style.paddingLeft = "3em";
        divMsg.style.color = "green";
        divFooter.appendChild(divMsg);

        var inp = document.createElement("INPUT");
        inp.setAttribute("id", "theCommentInput");
        inp.addEventListener("click", function (e) {
            alert("Klikk!");
            e.stopPropagation();

        }, false);


        divFooter.appendChild(inp);

        var btn = document.createElement("BUTTON");        // Create a <button> element
        var t = document.createTextNode("CLICK -------ME");       // Create a text node
        btn.appendChild(t);                                // Append the text to <button>
        divFooter.appendChild(btn);
     



        d.appendChild(divFooter);

      
    }


};

module.exports = DUtils;