

"use strict";

import React from "react";
var RouteHandler = require("react-router").RouteHandler;
var CreateReactClass = require("create-react-class");
var MenuNavigation = require("./MenuNavigation.jsx");
import SessionStore  from "./../stores/SessionStore.js";
import UserDataStore from "./../stores/UserDataStore.js";
import Login  from "./../components/Login/Login.jsx";




import LoadingIcon from "react-loader";
import moment from "moment";

import VConst from "./../utils/VConst.js";
import Actions from "./../actions/Actions.js";
import Vutils from "./../utils/Vutil.js";
import OrgFarmPopPicker from "./Filters/OrgFarmPopPicker.jsx";

import DashboardCanvas from "./Dashboard/DashboardCanvas.jsx";
import DashboardListCanvas from "./Dashboard/DashboardListCanvas.jsx";
import DashboardPopContainer from "./Dashboard/DashboardPopContainer.jsx";
import LastFarmConnection from "./Dashboard/LastFarmConnection.jsx";
import DashboardInfoTable from "./Dashboard/DashboardInfoTable.jsx";
import OrgOverview from "./Dashboard/OrgOverview.jsx";

import FilterStore from "../stores/FilterStore";

import DashboardStore from "./../stores/DashboardStore.js";
import TranslateStore from "./../stores/TranslateStore.js";
import BT from "react-bootstrap-table";
var t = TranslateStore.getTranslate();
import Spinner from "./../utils/Spinner.jsx";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'


//require("./Dashboard.css");
require("./../utils/Callout.css");









 // function SimpleFile (){
export default class SimpleFile  extends React.Component{
//    var App = CreateReactClass({

    render (){

        return (
            <div>
            <h1>Simple File Header 1</h1>
            <p> aðeins nánar um það </p>
             <p> aðeins nánar um það </p>
              <p> aðeins nánar um það </p>
               <p> aðeins nánar um það </p>
                <p> aðeins nánar um það </p>
                 <p> aðeins nánar um það </p>

            </div>
        );
    };
 

    
};

//export default  SimpleFile;
////module.exports = App;