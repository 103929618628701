var React = require('react');
import { Dropdown, DropdownButton, FormControl, Form } from 'react-bootstrap';
var Actions = require('../../../../actions/Actions.js');
import {XLg} from 'react-bootstrap-icons';
export default class SearchDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchQuery: '',
			searchResults: [],
			tooltipActive: false,
			inputActive: false,
			optionTrigger: false
		};

		this.handleSearchChange = (e) => {

			this.setState({ searchQuery: e.target.value });
			var query = e.target.value.toLowerCase();
			if (query === '') {
				this.setState({ searchResults: this.props.options });
				return;
			}
			var results = [];

			for (var i = 0; i < this.props.options.length; ++i) {


				if (this.props.options[i].name.toLowerCase().match(query)) {

					results.push(this.props.options[i]);
				}
			}

			this.setState({ searchQuery: e.target.value, searchResults: results });
		};
		this.addResult = (result) => {
	


			Actions.dropdownSelect(result, this.props.view, this.props.type);
			this.setState({ searchQuery: '', optionTrigger: true });
		};




		this.getSearchResults = () => {
			var self = this;

			var results = this.state.searchResults.map(function(result, i) {

				var name = result.name;
	

				if (self.props.type === 'user') name = result.username;
				if (self.props.type === 'farmOrg') name = result.nameWithOrg;
				return (
					<Dropdown.Item onSelect={self.addResult.bind(null, result)} onClick={self.onClickRow} key={i}>
						{name}
					</Dropdown.Item>
				);
			});

			return results;
		};

		this.onDropdownOpen = (e) => {
			if (this.state.searchResults.length == 0)
				this.setState({ searchResults: this.props.options });
			this.forceUpdate();
		};
		this.onInputFocus = () => {
			this.setState({ inputActive: true });
		};
		this.onInputBlur = () => {
			this.setState({ inputActive: false });
		};
		this.onKey = (event) => {
			if (event.key === 'Enter') {
				if (this.state.searchResults.length > 0) this.addResult(this.state.searchResults[0]);
				//this.refs.dropdownButton.setDropdownState(false);
			}
		};

		this.onClickRow = (e) => {

	
			var query = e.target.innerText.toLowerCase().trim();

	
			if (query === '') {
				
				this.setState({ searchResults: this.props.options });
				return;
			}

			//var results = [];
			for (var i = 0; i < this.props.options.length; ++i) {
				//console.log ("onClickRow  opt "+i+": "+ JSON.stringify(this.props.options[i], 1, 1));
				if (this.props.type === 'farmOrg'){

			
					if (this.props.options[i].nameWithOrg.toLowerCase() == query) {
	
						this.addResult(this.props.options[i]);
						break;
					}
				}else {

					if (this.props.options[i].name.toLowerCase().trim() == query) {
	
						this.addResult(this.props.options[i]);
						break;
					}
				}
	
			}
			//this.setState({ searchQuery: '', searchResults: results });
			this.setState({ searchQuery: '', searchResults: [] });

		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			this.state.inputActive ||
			nextProps.disableButton !== this.props.disableButton ||
			nextProps.isAdmin !== this.props.isAdmin ||
			nextProps.translateTrigger !== this.props.translateTrigger
		) {
			return true;
		} else if (this.state.optionTrigger) {
			this.setState({ optionTrigger: false });
			return true;
		}
		return false;
	}

	render() {
		var disableButton = this.props.isAdmin || this.props.disableButton;

		var searchResults = this.getSearchResults();

		return (
			<DropdownButton
				title={this.props.title}
				block="true"
				className="search-dropdown-button"
				onFocus={this.onDropdownOpen}
				disabled={disableButton}
				ref="dropdownButton"
				variant="outline-dark"
			>
				<div className="search-dropdown-input">
					<FormControl
						type="search"
						addonafter={(<XLg/>)}
						onFocus={this.onInputFocus}
						onBlur={this.onInputBlur}
						onChange={this.handleSearchChange}
						value={this.state.searchQuery}
						ref="optionsSearch"
						focus={(this.state.searchResults.length !== 0).toString()}
						onKeyDown={this.onKey}
					/>
				</div>
				{searchResults}
			</DropdownButton>
		);
	}
}

