/**
 * Vaki Aquaculture Systems Ltd. Copyright 2018, all rights reserved.
 *
 * Language: JavaScript, ES5

 */
"use strict";

var React = require("react");
import {OverlayTrigger, Popover} from "react-bootstrap";

var moment = require("moment");
require("./BlinkPop.css")
import PropTypes from "prop-types";

export default class BlinkPop extends React.Component{


    static propTypes = {
        ID: PropTypes.string.isRequired,
        User: PropTypes.string,
        Text: PropTypes.string

    };

    constructor(props) {
     super(props);
     
    this.handleMouseOver = () => {
        var u = (!this.props.User ? "" : this.props.User);
     
        if (window.localStorage.getItem(u+this.props.ID))
            return;

        var v = document.getElementById(this.props.ID);

      
        if (v) {
  
            v.classList.remove("clsBlinker");
            window.localStorage.setItem(u + this.props.ID, moment().format("YYYY-MM-DD"))
        }
      
    };

    };



    render  () {





        var blinkCallBack = this.handleMouseOver;
        var blinkCallBack = null;
        var blinkClass = ""
        var u = (!this.props.User ? "" : this.props.User);

        if (!window.localStorage.getItem(u + this.props.ID)) {
      
            blinkCallBack = this.handleMouseOver;
            blinkClass = "clsBlinker";
        }

        var txt = (!this.props.Text ? "" : this.props.Text);
        var toRender = this.props.children;


        if (txt.length > 0)
            toRender = (<OverlayTrigger trigger={["hover", "click"]} delayShow={300} delayHide={5} rootClose={true} placement='top'
                overlay={<Popover  className = "clsBlinkBase"> {txt}</Popover >}>
                <div>
                    {this.props.children}
                </div>
            </OverlayTrigger>);



        return (
            <div id={this.props.ID} className={blinkClass} onMouseOver={blinkCallBack}>


                {toRender}
  
	    	</div>
        )
    }
};




