/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";




var React = require("react");

var Actions = require("../../actions/Actions.js");
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ReactBootstrap, {Dropdown, Modal} from "react-bootstrap";
export default class OrganisationPicker extends React.Component{
//var OrganisationPicker = export default class   extends React.Component{
    static propTypes = {
        selectedOrganisation: PropTypes.shape({
            OrganisationID: PropTypes.number.isRequired,
            Name: PropTypes.string.isRequired
        }).isRequired,

        // Technically it would be better to declare this better. We are
        // expecting an array of objects like: [{ID: 1, Name: "Org"}]. I
        // however don't know at this time how to express that.
        organisations: PropTypes.array.isRequired
    };

constructor(props) {
  
    super(props);


    this.handleClick = (e) => {

        Actions.organisationClick(e.target.id);
    };
};
    render () {
        var self = this; // We won't need to do this if we start to use 6to5, hack because JS sucks
        var organisations = this.props.organisations.concat();


        var orgNodes = organisations.map(function (org) {
            return (
               <li  key={org.OrganisationID}> <Dropdown.Item onClick={self.handleClick} id={org.OrganisationID}  >{org.Name}</Dropdown.Item> </li>
            );
        });

/*
  <Dropdown.Toggle  id="dropdown-orgs">
    Dropdown Button
  </Dropdown.Toggle>


            <RB.DropdownButton title={this.props.selectedOrganisation.Name}    onClick={ Actions.orgButtonClick } >
                {orgNodes}
            </RB.DropdownButton>
*/
        return (
<Dropdown>



  <Dropdown.Toggle  id="dropdown-orgs"  variant="outline-dark">
    {this.props.selectedOrganisation.Name}
  </Dropdown.Toggle>
  <Dropdown.Menu>
        {orgNodes}
  </Dropdown.Menu>
</Dropdown>



        )
    }
};


