/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

import { Container, Row, Col, Alert } from 'react-bootstrap';
import Immutable from 'immutable';
import moment from 'moment';
import React from 'react';

import Actions from './../../actions/Actions.js';
import BGTools from './../../utils/BGtools.js';
import CF_Chart from './CF_Chart.jsx';
import CF_ChartStore from '../../stores/CF_ChartStore';
import ConditionFactorDistribution from './ConditionFactorDistribution.jsx';
import DistributionFilters from './DistributionFilters.jsx';
import DistributionStore from './../../stores/DistributionStore.js';
import DistributionSummary from './DistributionSummary.jsx';
import FilterStore from '../../stores/FilterStore';
import LiveWeightDistribution from './LiveWeightDistribution.jsx';
import LiveWeightTable from './LiveWeightTable.jsx';
import SavedFiltersIndicator from './../Filters/SavedFiltersIndicator.jsx';
import SessionStore from './../../stores/SessionStore.js';
import Spinner from './../../utils/Spinner.jsx';
import TranslateStore from './../../stores/TranslateStore.js';
import UserDataStore from './../../stores/UserDataStore.js';
import VakiAPI from '../../webservice/VakiAPI';
import VakiReactPropTypes from './../../utils/VakiReactPropTypes.js';
import VConst from './../../utils/VConst.js';
import vutil from '../../utils/Vutil';

var t = TranslateStore.getTranslate();
require('./Distribution.css');

export default class Distribution extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filter: FilterStore.getFilter(),
			distributionFilter: DistributionStore.getFilter(),
			distributionsData: DistributionStore.getDistributionsData(),
			loading: false
		};

		/*******************from componentWillMount ( ) **************************** */
		DistributionStore.setMounted(VConst.MNT_DISTR);
		if (SessionStore.isLoggedOut()) {
			Actions.redirectToLogin();
		}
		CF_ChartStore.notifyMount();
		/*********************************************** */


		let dateStr = moment.utc(this.state.distributionFilter.get('displayDate')).format('YYYY-MM-DD');
 		//CF_ChartStore.fetchCF_Chart(FilterStore.getSelectedPopulationID(), dateStr);
			
           

               


		this.onCommonFilterChange = (filter) => {
			this.setState({ filter: filter });
		};

		this.onTranslateStoreChange = () => {
			this.setState({});
		};

		this.onDistributionFilterChange = (newDistData, loadingMsg) => {
			var load = true;
			if (!loadingMsg) load = false;

			if (!newDistData) {
				this.setState({
					distributionsData: null,
					loading: load
				});
			} else {
				this.setState({
					distributionFilter: newDistData.get('filter'),
					distributionsData: newDistData.get('distributionsData'),
					loading: load
				});
			}
		};
	}

	componentDidMount() {
		// When we load this report, set the default date range to 1 day from
		// today. Note: this might be an anti-pattern, not sure.
		//var defaultOneDaysRange = vutil.lastXDays(1);
		//Actions.startDateUpdate(defaultOneDaysRange[0]);
		//Actions.endDateUpdate(defaultOneDaysRange[1]);

		UserDataStore.postPageHit(VConst.PAGEID.DISTRIBUTION);

		this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
		this.unsubscribeFilterStore = FilterStore.listen(this.onCommonFilterChange);
		this.unsubscribeDist = DistributionStore.listen(this.onDistributionFilterChange);
	}

	componentWillUnmount() {
		this.unsubscribeTranslateStore();
		this.unsubscribeFilterStore();
		this.unsubscribeDist();
	}

	render() {
		var distributionsData = this.state.distributionsData;
		var dataOK = !!distributionsData;
		if (dataOK) dataOK = distributionsData.get('NumFish') > 0 && distributionsData.get('AverageLiveWeight') > 0;

		var displayDate = this.state.distributionFilter.get('displayDate');

		var endDate = this.state.distributionFilter.get('endDate');

		var guttingFactor = this.state.distributionFilter.get('guttingFactor');
		var populationSize = this.state.distributionFilter.get('populationSize');
		var selectedFarm = this.state.filter.selectedFarm.Name;
		var selectedPopulationName = this.state.filter.selectedPopulation.Name;

		var renderedMain = null;

		var bGutted = true;
		if (!guttingFactor || guttingFactor == 0) bGutted = false;

		var vArr = [];

		if (this.state.loading) {
			renderedMain = <Spinner />;
		} else if (!dataOK) {
			renderedMain = <Alert variant="warning"> {t('distribution.noData')} </Alert>;
			//renderedCF = "";
		} else {
			var dateStr = moment.utc(this.state.distributionFilter.get('displayDate')).format('YYYY-MM-DD');

	/*
            if (!CF_ChartStore.isCurrentData(dateStr, FilterStore.getSelectedPopulationID())){
              //if (!true){

                CF_ChartStore.fetchCF_Chart(FilterStore.getSelectedPopulationID(), dateStr);
                renderedCF = (<div className="clsRelative"> <CF_Chart  CfChartData={CF_ChartStore.getCfData()} />  </div>);
            }
*/

			/*
           if (this.state.cfVisible && CF_ChartStore.isCurrentData(dateStr, FilterStore.getSelectedPopulationID())){
               renderedCF = (<div className="clsRelative"> <CF_Chart />  </div>);

           }else {
               renderedCF = ( < Button type = "button" onClick = {this.handleButton}
            block > {t("distribution.loadCF")}  < / Button >);
                this.state.cfVisible = false;





            }
*/

			var len = distributionsData.get('WeightHistogramList').length;
			for (var i = 0; i < len; i++) vArr.push(distributionsData.get('WeightHistogramList')[i]);

			renderedMain = (
				<div>
					<Row id="distribution-graph-row"   className="justify-content-md-center mt-3">
						<Col   id="weight">
							
								<div id="divHistoChart">
									<LiveWeightDistribution
										weightHistogramList={distributionsData.get('WeightHistogramList')}
										theBinSize={this.state.distributionFilter.get('binSize')}
										bGutted={bGutted}
										Day={this.state.distributionFilter.get('displayDate')}
										Location={selectedFarm + '/' + selectedPopulationName.trim()}
										t={t}
									/>
								</div>
								</Col>
								<Col>
								<div id="divCFChart">
									<ConditionFactorDistribution
										cfHistogramList={distributionsData.get('CFHistogramList')}
										Day={this.state.distributionFilter.get('displayDate')}
										Location={selectedFarm + '/' + selectedPopulationName.trim()}
										t={t}
									/>
								</div>
							
						</Col>
					</Row>

					<br />

				</div>
			);
		}


		return (
			<div id="DistributionContainer">
			<Container >
				<DistributionFilters />
				<Container id="distribution-grid">
					<Row>
						<DistributionSummary />
						<br />
						{renderedMain}
					</Row>
					<Row >
						
							<LiveWeightTable  weightList={vArr} NumFishes={populationSize} t={t} />
						
					</Row>
					<Row>
						<br />
						<CF_Chart CfChartData={CF_ChartStore.getCfData()} />
					</Row>
				</Container>
			</Container>
			</div>
		);
	}
}

/*

*/


/*
 <LiveWeightTable weightHistogramList={distributionsData.get("WeightHistogramList")} NumFishes = {populationSize}  t={t} />

 */
