/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
import {InputGroup, FormControl} from"react-bootstrap";
import validator from "validator";
var Actions = require("./../../actions/Actions.js");

var VConst = require("./../../utils/VConst.js");
import PropTypes from "prop-types";
var timervar = null;
var timerNewValue = null;


export default class NumericInput extends React.Component{

    static propTypes = {
        value: PropTypes.number,
        label: PropTypes.string,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        allowempty: PropTypes.bool,
        actionFunction: PropTypes.func,
        placeholder: PropTypes.string
    };


  	constructor(props) {
  



     super(props);




    this.isInputValid  = (inputStrValue) => {

  



        if (this.props.allowempty && (!inputStrValue || inputStrValue.toString() == "")){
            return true;
        }


        if (!(typeof inputStrValue === 'string' || inputStrValue instanceof String)) 
            inputStrValue = inputStrValue.toString();


     
        if (validator.isFloat(inputStrValue)) {
            var theValue = validator.toFloat(inputStrValue);

            if (theValue >= this.props.minValue && theValue <= this.props.maxValue) {
  
                return true;

            }
           
        }

        return false;
     };


      let initValue = "";


      if (this.props.value)
        initValue = this.props.value;


      let initErrorState = "";  

      if (!this.isInputValid(initValue))
        initErrorState = VConst.CLASS.EDIT_ERROR;



        this.state = {
            value: initValue,
            errorState: initErrorState
        };

    this.validationState = () => {

        if (this.isInputValid(this.state.value)) {
            // Render normally. In Bootstrap there is no "normal" style so if
            // we want to get just normal display (no red or yellow borders)
            // the only way I found was to return undefined.
            return "";
        }
        return VConst.CLASS.EDIT_ERROR;
     };

   this.callActionIfPresent = ( newValue) => {
        if (typeof this.props.actionFunction === "function") {
              this.props.actionFunction(newValue);
        }
    };


    this.handleChange = () => {


        var newValue = this.refs.input.value;




        if (timervar != null) {
            window.clearTimeout(timervar);
            timervar = null;
        }

        let errState = "";
        if (this.isInputValid(newValue)) {
            timervar = window.setTimeout(this.timerFunc,VConst.FILTER_EDIT_DELAY);
            timerNewValue = newValue;
            /*
              var newMinWeight = validator.toInt(newValue);
            Actions.minWeightUpdate(newMinWeight);
            */
        }else{
            errState = VConst.CLASS.EDIT_ERROR;
        }

        this.setState({
            value: newValue,
            errorState: errState
        });

     };

this.timerFunc = ()=>{
    var newMinWeight = validator.toInt(timerNewValue);

    this.callActionIfPresent(newMinWeight);
   // Actions.minWeightUpdate(newMinWeight);
    timervar = null;
 };



     };//constructor








render () {




let placeholder = "Enter value"
if (this.props.placeholder && this.props.placeholder != "")
    placeholder = this.props.placeholder;
//   variant={this.validationState()}



        return (

  <InputGroup>




    <InputGroup.Text id="inputGroup-sizing-lg">{this.props.label}</InputGroup.Text>

    <FormControl 
            className = {this.state.errorState}
                type = "text"
                ref = "input"
                value={this.state.value}
            
                onChange = {this.handleChange}
              
                placeholder={placeholder}

               
     />
  </InputGroup>





        );
        
    }
};
