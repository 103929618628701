/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var ReactDOM = require("react-dom");
var Immutable = require("immutable");
import PropTypes from "prop-types";
var DashboardDrawUtils = require("./DashboardDrawUtils.js");
var Vutil = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var FilterStore = require("./../../stores/FilterStore.js");
var Actions = require("./../../actions/Actions.js");

var _stage = {};

// The radius of a population, static for now, should prolly make fluid
var _radius = 50;
// This specifies the amount of space in pixels that should be between each
// population, center to center. This distance is a somewhat optimal distance
// in a way that the tangent of two adjacent circles meet in way that looks
// nice. For an explanation see: http://imgur.com/YTgfFSh
var _spacer = 2 * _radius * Math.sqrt(2);
// How many pixels do we want to have clear as a margin on the canvas
var _margin = 10;
// How many populations are allowed on even numbered lines in the Dashboard
var _popsPerLine = 6;
// Specifies how big we want the frame visibility and connection icons to be.
// 0.5 = half the size, 0.7 = 70% of the size of the original.
var _iconSize = 1;
// How transparent should the frame visibility and connection icons be?
var _iconAlpha = 0.8;

/**
 * Draws one population on a EaselJS canvas.
 *
 * Each population is represented by a circle with some gradient in the background.
 *
 * Note: The Y-positioning on the various text elements and font sizes could use
 * some scaling love.
 *
 * Note: Ideally I'd have liked to have this function to be without any
 * business logic, i.e. I'd have liked just passing in dumb parameters instead
 * of the population object. However the amount of parameters was growing
 * considerably to the point where it was starting to make sense to just pass
 * in the population object.
 *
 * @param stage object, a EaselJS stage object that we wish to draw to
 * @param popStatus object, PopulationStatus object from the database
 * @param xPos number, the X-coordinate to draw the population from the center
 * @param yPos number, the Y-coordinate to draw the population from the center
 */
function drawPopulation(stage, popStatus, xPos, yPos) {
    
    try {
        var style = DashboardDrawUtils.getPopulationStyle(popStatus);

        var circle = new createjs.Shape();
        circle.graphics
            .setStrokeStyle(style.strokeThickness)
            .setStrokeDash(style.strokeStyle, 0)
            .beginStroke(style.strokeColor)
            .beginRadialGradientFill(style.backgroundGradient, [0, 1], 0, 0, 0, 0, 0, _radius)
            .drawCircle(0, 0, _radius);
        circle.shadow = new createjs.Shadow("#C2C2C2", 3, 3, 10);

        var populationName = new createjs.Text(popStatus.PopulationName, "bold 20px Arial", "black");
        populationName.textAlign = "center";
        populationName.y = -30;
        populationName.shadow = new createjs.Shadow("#8a8a8a", 2, 2, 7);

        var timeSinceLastFish = Vutil.timeFormatFromHours(popStatus.HoursSinceLastFish, false);


        var secondLine = popStatus.NumberOfFish24Hr + "x  " + timeSinceLastFish;
        var secondTextLine = new createjs.Text(secondLine, "bold 12px Arial", "black");
        secondTextLine.textAlign = "center";
        secondTextLine.y = -5;
        secondTextLine.shadow = new createjs.Shadow("#8a8a8a", 1, 1, 4);

        var frameConnectionIcon = DashboardDrawUtils.getConnectionIcon(popStatus, _iconSize, _iconAlpha);
        var frameVisibilityIcon = DashboardDrawUtils.getFrameVisibilityIcon(popStatus, _iconSize, _iconAlpha);

        var populationContainer = new createjs.Container();
        populationContainer.x = xPos;
        populationContainer.y = yPos;
        populationContainer.addChild(circle, populationName, secondTextLine,
            frameVisibilityIcon, frameConnectionIcon);
        stage.addChild(populationContainer);

        var population = FilterStore.getPopulationByID(popStatus.PopulationID);

        // When we hover on a population
        populationContainer.on("mouseover", function (event) {



            // This is too slow as is, re-enable when performance is acceptable
            //Actions.populationClick(population);
        });

        // When we click on a population
        populationContainer.on("click", function (event) {
            Actions.populationClick(population);
        });

        // When we click on a population and drag it around. Temporarily disabled
        // because we haven't implemented the necessary backend code to save
        // locations, so I'm leaving it here until we get back to that.
        /*
         populationContainer.on("pressmove", function(event) {
         var newXPos = event.stageX;
         var newYPos = event.stageY;

         var minX = _radius + _margin;
         var minY = _radius + _margin;
         var maxX = stage.canvas.width - _radius - _margin;
         var maxY = stage.canvas.height - _radius - _margin;

         // Boundary check X coordinate
         newXPos = Math.max(minX, newXPos);
         newXPos = Math.min(maxX, newXPos);

         // Boundary check Y coordinate
         newYPos = Math.min(maxY, newYPos);
         newYPos = Math.max(minY, newYPos);

         event.currentTarget.x = newXPos;
         event.currentTarget.y = newYPos;

         stage.update();
         });
         */
    }catch(er){alert ("drawPopulation Exc: ")+er}
}

function createDashboardCanvas(domNode, populationStatusList) {
    if (populationStatusList) {
        _stage = new createjs.Stage(domNode);
        _stage.enableMouseOver();

        var pos = DashboardDrawUtils.getXandYcoordinatesMap(_popsPerLine, populationStatusList.length,
            _radius, _spacer, _margin);

        // Draw all the populations to the stage
        for (var i = 0; i < populationStatusList.length; i++) {
            drawPopulation(_stage,  populationStatusList[i], pos[i].x, pos[i].y);
        }

        _stage.update();
    }
}


 export default class DashboardCanvas  extends React.Component{
    static propTypes = {
        populationStatusList: VakiReactPropTypes.populationStatusList
    };

    componentDidMount () {

        var domNode = ReactDOM.findDOMNode(this);
        createDashboardCanvas(domNode, this.props.populationStatusList);
    };

    shouldComponentUpdate ( nextProps, nextState) {
        var currentPropsImm = Immutable.fromJS(this.props.populationStatusList);
        var nextPropsImm = Immutable.fromJS(nextProps.populationStatusList);
        var sameFarm = Immutable.is(currentPropsImm, nextPropsImm);

        if (sameFarm) {
            // Same farm, no need to update
            return false;
        } else {


            return true;
        }
    };

    /**
     * Called after the render function has completed. That means the <canvas>
     * element has been created so we can now draw to it anew.
     */
    componentDidUpdate ( previousProps, previousState) {
        var domNode =   ReactDOM.findDOMNode(this);
        createDashboardCanvas(domNode, this.props.populationStatusList);

    };

    /**
     * Minor convenience method, returns true if we received props, false
     * otherwise. This might be done better by actually checking the contents
     * of the object and see if there is actually anyting in it. But will do
     * for now. Just be careful.
     *
     * @returns {boolean}
     */
    statusListNotEmpty ( ) {
        return this.props.populationStatusList ? true : false;
    };

    render () {
        // If we don't receive any props, default to given height. Pixel count.
        var canvasHeight = 0;

        if (this.statusListNotEmpty()) {
            var rowsNeeded = DashboardDrawUtils.getNumberOfRows(_popsPerLine, this.props.populationStatusList.length);
            // I'm totally guessing that 0.25 number, it seems to work okay for
            // 1 - 5 rows. Might need re-adjusting if we go above 6 rows. Pressure
            // is on to get this thing done, don't have time to do properly.
            canvasHeight = (rowsNeeded * 2 *_radius) + (2 * _margin) + (_spacer * 0.25);
        }

        return (
            <canvas width="940" height={canvasHeight}></canvas>
        )
    }
};
