/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */



"use strict";

var Reflux = require("reflux");


/**
 * Pretty much just a static list of strings (not really though, Reflux does
 * some magic as well) where we document in one place what we want our actions
 * to be called. In most examples I've found projects only contain one Actions
 * file but I suppose if it gets big we can split it up to more files.
 */
var Actions = Reflux.createActions([
    // Note: ideally these actions should be broken up. This is what I started
    // out with when I didn't have much clue what I was doing. Some of these
    // actions are common with many reports, some are old.
    "organisationClick", // When we select an organisation from the dropdown
    "orgButtonClick", //Org menu dropped down
    "farmButtonClick", //farm menu dropped down
    "popButtonClick",
    "farmClick",
    "populationClick",
    "listPopulationClick",
    "populationStatusClick", // When we click on a population in the dashboard
    "minWeightUpdate",
    "maxWeightUpdate",
    "startDateUpdate",
    "endDateUpdate",
    "minHourCountUpdate",

    // Dashboard actions
    "dashShowAllOrgs",
    "dashboardFarmLink",
    "dashboardTrendgraphLink",
    "dashboardToggleMap",
    "dashboardLoadAllDashboards",
    "dashboardShowPopsUtil",
    "dashboardShowCurrOrg",
    "dashPopStarvDate",
    "dashPopStarvDateUpdate",
    "dashShowHelp",
    "dashFarmClickedOnAllOrgs",
    "dashboardToggleDashboard",
    "dashboardShow",
    "dashboardHideStartupMsg",
    "dashPause",
    "dashPauseSubmit",
    "dashSelRunstate",
    "dashCheckOrgListAge",

    //DashComments
    "dashCommentsShow",
    "dashCommentSubmitClick",

   

    // for later

    //Language actions
    "setEnglishTranslation",
    "setGermanTranslation",
    "setNorwegianTranslation",
    "setSpanishTranslation",

    // Trend Graph actions
    "trendStartDateUpdate",
    "trendEndDateUpdate",
    "trendDateChange",
    "trendShowAllCages",
    "trendShowAvgWeight",
    "trendGraphDoneLoading",
    "trendgraphDistributionLink",
    "trendUniformScaling",
    "addComment",
    "apiCommentSaved",
    "trendAWshowAsTrend",
    "trendUseSpecEndDates",
    "trendNewAux",


    // Distribution actions
    "distDateUpdate",
    "distMinCFUpdate",
    "distMaxCFUpdate",
    "distBinSizeUpdate",
    "distGuttingFactorUpdate",
    "distPopulationSizeUpdate",
    "distStartFetching",


    // FCR Report
    "fcrTrendLineLengthUpdate",
    "fcrStartDateUpdate",
    "fcrEndDateUpdate",
    "fcrShowAllCages",
    "fcrStartFetching",
    "fcrLoadAuxData",
    "fcrAuxDataLoaded",

    //Weight and Time Report
    "watTrendLineLengthUpdate",
    "watStartDateUpdate",
    "watEndDateUpdate",
    "watDateChange",
    "watMinWeightUpdate",
    "watMaxWeightUpdate",



    //PopCompare
    "goFetchPopCompareData",
    "apiNewPopCompareData",
    "reloadGraph",
    "matchStartWeight",
    "popCompareDoneLoading",
    "popCompareButton",
    "apiHistPopListReceived",
    "popCompFetchTree",
    "treeSelectEvent",
    "fitBestLine",
    "trendLineOffset",
    "datesOnX",
    "showTemp",
    "checkTree",
    "popCompRedraw",
    "minNumFishes",
    "pcHideGroupWell",
    "pcShowGroupWell",
    "pcSaveGroupClick",
    "pcOpenGroupSelect",
    "pcEditModalCancelClick",
    "pcEditModalOkClick",
    "pcGroupModalShowClick",
    "pcGroupModalCloseClick",
    "pcGroupModalDeleteClick",
    "pcPopGroupsReceived",
    "pcEditPrivateCheck",
    "pcPopGroupSelected",
    "exactDatesOnX",


    // Admin view actions
    "adminViewUsersClick",
    "adminViewOrgClick",
    "adminViewFarmsClick",
    "adminViewCompsClick",
    "userListRowClick",
    "userListRowDelete",
    "userListRowRestore",
    "orgListRowClick",
    "orgListRowDelete",
    "orgListRowRestore",
    "farmListRowClick",
    "farmListRowDelete",
    "farmListRowRestore",
    "compListRowClick",
    "compListRowDelete",
    "compListRowRestore",
    "dropdownSelect",
    "removeSelectedOption",
    "createUserFormSubmit",
    "createOrgFormSubmit",
    "createFarmFormSubmit",
    "createCompFormSubmit",
    "editUserFormSubmit",
    "editOrgFormSubmit",
    "editFarmFormSubmit",
    "editCompFormSubmit",
    "newUserCreated",
    "newOrganisationCreated",
    "newFarmCreated",
    "newCompCreated",
    "userEdited",
    "organisationEdited",
    "farmEdited",
    "compEdited",
    "createUserOrgFormSubmit",
    "createUserFarmFormSubmit",
    "createUserEditOrgFormSubmit",
    "createUserEditFarmFormSubmit",
    "createFarmOrgFormSubmit",
    "createFarmEditOrgFormSubmit",
    "showDeletedUsers",
    "showDeletedOrganisations",
    "showDeletedFarms",
    "showDeletedComputers",
    "showInactiveFarms",
    "showInactiveComputers",
    "showOnlyAdminUsers",

    // Router actions
    "redirectToLogin", // if we do not have a valid token
    "redirectToApp", // if we are logged in, redirect to

    // Session actions
    "loginAttempt", // when a user submits the log-in form with user/pass
    "loginSuccessful",
    "loginWrongUserPass",
    "signOut", // when a user manually signs out from the site
    "changePasswordSubmit",
    "saveFilters",
    "reloadUserData", //reloads and toggle show inactive cages
    "showAllOrgs",
    "uniformScaling",
    "setDefaultFarm",

    //CF_Chart actions
    "",

    //RepSubscribe
    "rsubAddEmail",
    "rsubEmailSelected",
    "rsubDeleteEmail",
    "rsubAfterEditEmail",
    "rsubSelectPeriod",
    "rsubSelectDay",
    "rsubAddSub",
    "rsubDeleteSub",
    "rsubSubSelected",
    "rsubSaveSub",
    "rsubCancelEdit",
    "rsubTestRequest",


    //PopDefine
    "pdDatePicked",
    "pdSegmentSelected",    
    "pdChangeAlive",

    // Web Service actions
    "apiUptimeRecived",
    "apiNewDashboardData",
    "apiNewTrendGraphData",
    "apiNewTrendGraphAllCagesData",
    "apiNewDistributionsData",
    "apiNewFcrData",
    "apiNewWeightAndTimeData",
    "apiNewUserData",
    "apiError", // when VakiAPI returns a 5xx code, server error
    "apiErrorFCR",
    "apiErrorPopDefine",
    "apiNewUserDimensionData",
    "apiNewCountryDimensionData",
    "apiNewUserListData",
    "apiNewOrgListData",
    "apiNewFarmListData",
    "apiNewCompListData",
    "apiAdminViewDataReady",
    "apiObjectSaved",
    "apiNewDashboardList",
    "apiPasswordChanged",
    "apiNewPopCompareData",
    "apiNewCF_Chart",
    "apiNewPopDefineStuff",
    "apiPdfReport",
    "apiPostSubscription",
    "apiGetSubscriptions",
    "apiCountriesLoaded",
    "apiMyCountriesLoaded",
    "apiPricelistReceived",
    "apiNewMeasurementsData",
    "apiNewValueEstimateData",
    "apiGotExchangeRates",
    "apiErrorGettingExchangerates",
    "apiGotHarvestReportHeader",
    "apiFilterSuggestion",
    "apiReportCommentSaved",
    "apiReportHeaderSaved",
    "apiUnverifiedSegmentReceived",
    "apiFarmsWithHisPops",
    "apiHisPopsForLocation",
    "apiGetFarmUtilizations",
    "apiPostStarvingDateResults",

    //CountriesFilter
    "cntryAdd",
    "cntryDel",

    //Pricelist
    "plUpdatePrice",
    "plSavePriceList",
    "plDoNasdaq",
    "plSelectCurrency",
    "plClickCurrency",
    "plLoadPrices",





    //ValueEstimate
    "valueFetchData",

    //measurements
    "measStartDateUpdate",
    "measEndDateUpdate",
    "measFetchData",
  
    // HarvestReportactions
    "hrepDateUpdate",
    "hrepMinCFUpdate",
    "hrepMaxCFUpdate",
    "hrepBinSizeUpdate",
    "hrepGuttingFactorUpdate",
    "hrepStarvingFactorUpdate",
    "hrepPopulationSizeUpdate",
    "hrepStartFetching",

    "hrepFetchHeader",
    "hrepSaveHeader",
    "hrepGetSuggest",
    "hrepAskSave",
    "hrepTextInputEdit",
    "hrepSaveTextInput",
    "hrepCloseWarning",
    "hrepCheckNext",
    "hrepSetDefaults",
    "hrepFetchData",
    "hrepGotValueEstimate",
    "hrepHarvestedRowUpdated",
    "hrepFiltersRowUpdated",
    "hrepReceivedTrendGraph",
    "hrepNewWeightAndTimeData",
    "hrepToggleMenu",
    "hrepMenuTransitEnd",
    "hrepReceivedCF_Chart",
    "hrepShowHelp",

    //Historical Verify
    "hisFetchUnverified",
    "hisRedraw",
    "hisDiscard",
    "hisSave",


    //Historical trendlines
    "histTrend",
    "histDrawLine",
    "histApiNewTrendGraphData",
    "histTest",
    "histFectchHistory",
    "histGetWhich",

    //Uptime
    "uptAllFarmsChange",
    "uptFetchReport",

    //PopDetails
    "popDetailsLink",
    "popDetailsColClick",
    "popDetailsDelDistChart",
    "popDetailsClickGetDistr",



    "menu",


    "manListReceived",
    "manPostResults",
    "manRcvForEdit",
    "manEditGetList",
    "manLinkSelected",
    "manDelete",
 


    //SignoutModal
    "showSignoutModal",

    //CameraManagement
    "selectOrphanEvent",
    "allOrgList",
    "camItemPosted",
    "showList",

    //24HourDistribution
    "hdDate",
    "hdOnNewData"
  
    


]);

module.exports = Actions;
