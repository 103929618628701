/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

'use strict';

import React from 'react';
import Reflux from 'reflux';
//import RouteHandler from "react-router/RouteHandler";
import MenuNavigation from './MenuNavigation.jsx';
import SessionStore from './../stores/SessionStore.js';
import UserDataStore from './../stores/UserDataStore.js';
import Login from './../components/Login/Login.jsx';
import Dashboard from './Dashboard/Dashboard.jsx';
import Trendgraph from './Trendgraph/Trendgraph.jsx';
import Distribution from './Distribution/Distribution.jsx';
import WeightAndTime from './WeightAndTime/WeightAndTime.jsx';
import AdminView from './AdminView/AdminView.jsx';
import Actions from './../actions/Actions.js';
import { HashRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Curtain from './../utils/Curtain.jsx';
import SignoutModal from './Views/SignoutModal.jsx';
import QA from './../components/QA/QA.jsx';
import Manuals from './../components/ManualDownload/ManualDownload.jsx';
import RepSubscribe from './../components/RepSubscribe/RepSubscribe.jsx';
import Measurements from './../components/Measurements/Measurements.jsx';
import JoinPops from './../components/JoinPops/JoinPops.jsx';
import HourDistribution from './../components/24HourDistribution/24HourDistribution.jsx';
import Uptime from './../components/Uptime/Uptime.jsx';
import LanguageSelector from './../components/Language/LanguageSelector.jsx';
import PopDetails from "./../components/PopDetailedStatus/PopDetailedStatus.jsx";
import CameraManagement from './../components/CameraManagement/CameraManagement.jsx';
import ManualLinks from "./../components/ManualLinks/ManualLinks.jsx";
import Footer from "./Footer.jsx";


var AppLoginStore = Reflux.createStore({
	init: function() {
		this.listenTo(Actions.redirectToLogin,  this.onRedirectToLogin);  //Will bring up login if logged out
	},
	onRedirectToLogin: function() {
		this.trigger();
	}
});



/**
 * By tradition in React, the top level component is called App. A perhaps more
 * descriptive word would be: RootComponent.
 */

//function App(){
export default class App extends React.Component {
	//var App = export default class   extends React.Component{
	//    var App = CreateReactClass({

	constructor(props) {
	
		super(props);
		this.state = {
			doLogin: false
		};

		this.onAppLoginStoreChange = () => {
			this.setState ({doLogin:true});
		};

		this.onUserDataStoreChange = (ud) => {
			if (!ud) return;
			// Maybe this is the best way but by intuition it feels wrong
			this.forceUpdate();
		};

		this.onSessionStoreChange = () => {
			// I feel bad about doing it like this, feels dirty
			this.forceUpdate();
		};
	}

	componentDidMount() {
		this.unsubscribeAppLoginStore= AppLoginStore.listen(this.onAppLoginStoreChange);
		this.unsubscribeSessionStore = SessionStore.listen(this.onSessionStoreChange);
		this.unsubscribeUserDataStore = UserDataStore.listen(this.onUserDataStoreChange);
	}

	componentWillUnmount() {
		this.unsubscribeAppLoginStore();
		this.unsubscribeSessionStore();
		this.unsubscribeUserDataStore();
	}

	//
	render() {
	
	//	var containerClass = 'container-fluid login-background vertical-center';
		var containerClass = "login-background2";
		var menuNavigation = <div> </div>; // empty if we're not logged in, can't think of anything better
		var isAdmin = UserDataStore.isUserAdmin();
		var isEmployee = UserDataStore.IsEmployee();
		var isLoggedIn = SessionStore.isLoggedIn();


		
		if (this.state.doLogin){
				
			if (isLoggedIn){
				isLoggedIn = false;
				//Then log out
				Actions.signOut();
			}
		}
		this.state.doLogin = false;


		let FirstSwitch = <Route exact path="/XXXXXXXXX">	{<div/>} </Route>
let DefaultSwitch = <Route> {<Dashboard/>} </Route>;

		if (isLoggedIn) {
			
			var userName = SessionStore.getLoggedInUsername();
			containerClass = 'container';
			menuNavigation = <MenuNavigation loggedInUserName={userName} isAdmin={isAdmin} isEmployee={isEmployee} />;
		} else {

			DefaultSwitch = <Route>	{<div/>} </Route>
		//	FirstSwitch = <Route>	<Curtain/> </Route>;
			menuNavigation = <Login forceshow={true} />;
		}


		return (
			<div className={containerClass}>
				<Router>
					{menuNavigation}

					<Switch>
						{FirstSwitch}

						<Route exact path="/Trendgraph">
							
							{<Trendgraph />}
						</Route>

						<Route exact path="/Distribution">
							
							{<Distribution />}
						</Route>
						<Route exact path="/WeightAndTime">
							
							{<WeightAndTime />}
						</Route>
						<Route exact path="/AdminView">
							
							{<AdminView />}
						</Route>
						<Route exact path="/LanguageSelector">
							
							{<LanguageSelector />}
						</Route>
						<Route exact path="/QA">
							
							{<QA />}
						</Route>
						<Route exact path="/Manuals">
							
							{<Manuals />}
						</Route>
						<Route exact path="/Login">
							
							{<Login />}
						</Route>

						<Route exact path="/RepSubscribe">
							
							{<RepSubscribe />}
						</Route>
						
						<Route exact path="/Measurements">
							
							{<Measurements />}
						</Route>
						
						<Route exact path="/HourDistribution">
							{<HourDistribution />}
						</Route>

						<Route exact path="/JoinPops">
							
							{<JoinPops />}
						</Route>

						<Route exact path="/Uptime">
							
							{<Uptime />}
						</Route>



						<Route exact path="/CameraManagement">
							{<CameraManagement />}
						</Route>


					<Route exact path="/ManualLinks">
							{<ManualLinks />}
						</Route>


						<Route exact path="/PopDetails/:popid" component={PopDetails}/>
							
							{DefaultSwitch}
						



					</Switch>
				</Router>

				<Footer/>
			</div>
		);
	}
}


/*
<Route exact path="/Measurements">
							
							{isLogg && <Measurements />}
						</Route>
						<Route exact path="/JoinPops">
							
							{isLogg && <JoinPops />}
						</Route>
						<Route exact path="/Uptime">
							
							{isLogg && <Uptime />}
						</Route>
*/



/*
                        <Modal modal={signoutModal}>
                                     <a><strong className="hidden-sm hidden-md">{userName} </strong>
                                        <PersonFill/></a>
                                </Modal>
                                */
