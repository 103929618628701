/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");

var VConst = require("./../../utils/VConst.js");
import NumericInput from "./NumericInput.jsx";
import PropTypes from "prop-types";
 export default class MaxCFFilter  extends React.Component{
    static propTypes = {
        maxCF: PropTypes.number.isRequired,
        minValue: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    render () {
        return (
            <NumericInput
                value={this.props.maxCF}
                label={this.props.t("filters.maxCF")}
                minValue={this.props.minValue}
                maxValue={VConst.MAX_ALLOWED_CF}
                actionFunction={this.props.actionFunction} />
        )
    }
};

