/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var RB = require("react-bootstrap");
var moment = require("moment");
import PropTypes from "prop-types";
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");

export default class DashboardInfoTable  extends React.Component{
    static propTypes = {
        populationStatus: VakiReactPropTypes.populationStatus,
        organisationName: PropTypes.string,
        farmName: PropTypes.string,
        contactPhone: PropTypes.string,
        contactEmail: PropTypes.string,
        teamviewerID: PropTypes.number
    };



    constructor(props) {
     super(props);

            this.props=populationStatus= VakiReactPropTypes.defaultPopulationStatus;
            this.props=organisationName= "Organisation";
            this.props=farmName= "Farm";
            this.props=contactPhone= "";
            this.props=contactEmail= "";     

    };




    getCommentsList ( ) {
        var comments = this.props.commentsList.map(function(comment, i) {
            var date = moment(comment.Created_Datetime).format("MMMM Do, YYYY");
            var user = comment.Username;
            var popName = ", " + comment.PopulationName + ", ";
            if(comment.PopulationName === "") popName = "";
            var text = comment.Text;
            return (
                <div className="media" key={i}>
                <div className="media-body">
                    <div className="media-heading">
                    <strong> {user}</strong>{popName}<small> {date} </small>
                    </div>
                    {text}
                </div>
                </div>
            )
        });
        return comments;
    };

    render () {
        // Ideally it should be like the two comment out lines but there is an
        // error in VakiAPI or the database in that the datetime objects
        // returned do not contain a timezone. Once that is fixed we can switch
        // to these uncommented lines
        //var lastFishPopulation = moment(selectedPopStatus.LastFishLocalTime).fromNow();
        //var lastDataPopulation = moment(selectedPopStatus.LastDataLocalTime).fromNow();
        // In the meantime we use this
        var lastFishPopulation = Vutils.timeFormatFromHours(this.props.populationStatus.HoursSinceLastFish, true);

        var lastDataPopulation = Vutils.timeFormatFromHours(this.props.populationStatus.HoursSinceLastData, true);

        var commentsList = this.getCommentsList();

        var strVisibility = this.props.populationStatus.LastFrameVisibility;
        if (this.props.populationStatus.LastConnectionStatus.toUpperCase() != "OK")
            strVisibility = "Unknown";
/*
 <strong>{this.props.t("dashboard.organisation")}</strong>: {this.props.organisationName} <br />
 <strong>{this.props.t("dashboard.farm")}</strong>: {this.props.farmName} <br />
  */

        var strTvID = "";
        if (this.props.teamviewerID == null)
            strTvID = "Not set";
        else {
            strTvID = this.props.teamviewerID.toString().trim();
            if (strTvID.length == 9) {
                //Format with spaces if normat TV number
                strTvID = strTvID.substr(0, 3) + " " + strTvID.substr(3, 3) + " " + strTvID.substr(6, 3);

            } else if (strTvID.length == 10) {
                strTvID = strTvID.substr(0, 1)+" "+ strTvID.substr(1, 3) + " " + strTvID.substr(4, 3) + " " + strTvID.substr(7, 3);

            }
        }



        return (
            <RB.Well size="lg">
                <RB.Grid>
                    <RB.Row>
                        <RB.Col md={6}>
                            <h4>{this.props.t("dashboard.population")}<i>: {this.props.populationStatus.PopulationName}</i></h4>
                            <strong>{this.props.t("dashboard.fishMeasured")}</strong>: {this.props.populationStatus.NumberOfFish24Hr}x <br />
                            <strong>{this.props.t("dashboard.timeSinceFishMeasure")}</strong>: {lastFishPopulation} <br />
<strong>{this.props.t("dashboard.frameAndConnectionStatus")}</strong> ( {lastDataPopulation}):  <br/>

<ul>
<li>{this.props.t("dashboard.visibility")}: {strVisibility}.</li>
<li>{this.props.t("dashboard.connection")}: {this.props.populationStatus.LastConnectionStatus}.</li>

</ul>


                        </RB.Col>
                        <RB.Col md={6}>
                            <br /><br />
                            <strong>{this.props.t("dashboard.frameType")}</strong>: {this.props.populationStatus.FrameType} <br />
                            <strong>{this.props.t("dashboard.frameSerialNumber")}</strong>: {this.props.populationStatus.FrameSerialNumber} <br /><br/>

<strong>{this.props.t("dashboard.contactPhone")}</strong>: {this.props.contactPhone} <br />
<strong>{this.props.t("dashboard.contactInfo")}</strong>: {this.props.contactEmail} <br />
<strong>TeamViewer ID </strong>: {strTvID} <br />

                        </RB.Col>
                    </RB.Row>
                    <br />

                    <RB.Row>
                        <RB.Col md={6}>
                            <h4>{this.props.t("dashboard.colorCoding")}:</h4>
                            <ul>
                                <li><strong>{this.props.t("dashboard.green")}</strong>: {this.props.t("dashboard.greenFish")}.</li>
                                <li><strong>{this.props.t("dashboard.yellow")}</strong>: {this.props.t("dashboard.yellowFish")}.</li>
                                <li><strong>{this.props.t("dashboard.red")}</strong>: {this.props.t("dashboard.redFish")}.</li>
                            </ul>
<h4>{this.props.t("dashboard.warningSigns")}:</h4>


<ul>
<li> <img src="img/Antenna_32.png"  />: {this.props.t("dashboard.disconnectSign")}.</li>
<li><img src="img/Eye_32.png"  />: {this.props.t("dashboard.badVisibilitySign")}.</li>

</ul>
                        </RB.Col>





                        <RB.Col md={6}>
                            <strong>{this.props.t("dashboard.commentsLast30Days")}: </strong> <br />
                            <div id="comment-list-wrapper">
                                {commentsList}
                            </div>

                        </RB.Col>
                    </RB.Row>
                </RB.Grid>
            </RB.Well>
        )
    }
};

