
"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("./../actions/Actions.js");
var VConst = require("./../utils/VConst.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var NavBarStore = require("./NavBarStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var TranslateStore = require("./TranslateStore.js");
var t = TranslateStore.getTranslate();
var _data = { };




var CF_ChartStore = Reflux.createStore({
    init: function() {
    	this.listenTo(Actions.apiNewCF_Chart, this.onNewCF_Chart);
    	//this.listenTo(FilterStore, this.onFilterStoreChange);
        this.listenTo(TranslateStore, this.onTranslateStoreChange);



    	// By default, pick a range of # days in the past from now. We need
        // startOf("day") because the React-Bootstrap-Daterangepicker uses
        // dates from midnight by default.
        var numberOfDays = VConst.DEFAULT_WEIGHT_TIME_RANGE;
        var endDate = moment().endOf("day");
        var startDate = endDate.clone().subtract(numberOfDays, "days").startOf("day");
        var filter = {
            startDate: startDate,
            endDate: endDate,
            minWeight: VConst.DEFAULT_MIN_WEIGHT,
            maxWeight: VConst.DEFAULT_MAX_WEIGHT
        };

        _data = {
            dateStr: "",//the display date
			dtStart: "",
			dtEnd:"",
			popID: 0,
            CfData: null,
            chartLoading: false,
            useDateRange: false
        };


       // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
       // this.fetchWeightAndTimeGraph();
    },

	isCurrentData: function (dateStr, popID){ //returns true if data for those parameters are stored here
		return (this.getDateStr() == dateStr  && this.getPopID()==popID );
	},




	getDateStr: function(){
		return _data.dateStr;
	},

	getStartDateStr: function(){
		return _data.dtStart;
	},

	getEndDateStr: function(){
		return _data.dtEnd;
	},


	getPopID: function(){
		return _data.popID;
	},

	getCfData: function(){
		return _data.CfData;
	},


    onFilterStoreChange: function(newFilter) {

       /*
        if(NavBarStore.filtersSaved()) {
            var userFilters = NavBarStore.getUserFilters();
            _data.filter.minWeight = userFilters.minWeight || VConst.DEFAULT_MIN_WEIGHT;
            _data.filter.maxWeight = userFilters.maxWeight || VConst.DEFAULT_MAX_WEIGHT;
        }
*/
/*
        _data.filter.minWeight =  newFilter.minWeight ;
        _data.filter.maxWeight =  newFilter.maxWeight ;


    	this.fetchWeightAndTimeGraph();
        this.trigger("", true);
        */
    },

    /**
     * Set chart data expects raw data so we'll have to update translations
     * manually here.
     */
    onTranslateStoreChange: function() {
		/*
        if (_data.weightAndTimeData) {
            _data.weightAndTimeData.title.text = t("wat.chartHeader") + FilterStore.getSelectedPopulationName();
            _data.weightAndTimeData.yAxis[0].title.text = t("wat.leftYAxis");
            _data.weightAndTimeData.yAxis[1].title.text = t("wat.rightYAxis");
            _data.weightAndTimeData.series[0].name =  t("wat.fish");
            _data.weightAndTimeData.series[1].name =  t("wat.voltage");
        }
        */
        this.trigger(_data, false);
    },

    onNewCF_Chart: function(data) {



 

        var a = data.DataList;
         


        var cfArr = [];
        var minCF = 0.5;
        var maxCF = 2;

        var minWgt = 1500;
        var maxWgt = 8000;
        var nWgt = (maxWgt - minWgt) / 100;
        var nCF = 15;
        var theArr = [];

        var yCat = [];
        var xCat = [];
        for (var i = 1; i <= nCF; i++)
            yCat.push((minCF + i * 0.1).toString());

        for (var i = 1; i <= nWgt; i++)
            xCat.push(i);//((minWgt + i * 100));





        var cntOutliers = 0;
        for (var i = 0; i < nWgt; i++) {
            for (var j = 0; j < 15; j++) {
                theArr.push([xCat[i], j, 0]); 
            }
        }

      
        
        
        
        var numData = a.length;
        


        for (var i = 0; i < numData; i++) {
            var w = a[i].Weight;
            var cf = a[i].CF;
            
            if (w < minWgt || w >= maxWgt || cf < minCF || cf >= maxCF) {
                cntOutliers++;
    
            }else {
                var wIdx = Math.floor((w - minWgt) / 100);
                var cIdx = Math.floor((cf - minCF) / 0.1);
                var idx = wIdx * nCF + cIdx;
              
                  //  console.log("+��---- " + JSON.stringify(theArr[idx]));
                    theArr[idx][2] = theArr[idx][2] + 1;
                   // console.log("+�� " + JSON.stringify(theArr[idx]));
                   // console.log("++++ " + theArr[idx][2]);
               
            }
        }
        
        var theData = [];

        for (var i = 0; i < theArr.length; i++) {
            if (theArr[i][2] > 0)
                theData.push (theArr[i])
        }
   

	/*
        if(data.PopulationWeightAndTimeDataList === null) {
            data.showSlowMsg = false;
        } else {
            _data.showSlowMsg = data.PopulationWeightAndTimeDataList.length > 20000;
        }
       */




        //this.set3DMapData(theData, nWgt, nCF);
        //this.setHeatMapData(theData)
    	this.setChartData(data);

    	this.trigger(_data, false);

    },


    set3DMapData: function(theData, nWgt, nCF) {

     



        var d3dat = [];
    
        for (var i = 0; i < nCF; i++) {
            var o = [];
            d3dat.push({ stack: i, data: o});
        }

        var numData = theData.length;
        for (var i = 0; i < numData; i++) {


            d3dat[theData[i][1]].data.push({ x: theData[i][0], y: theData[i][2]  })
        }





        _data.CfData=      {
            chart: {
                type: 'column',
                    options3d: {
                        enabled: true,
                        fitToPlot:true,
                        alpha: 20,
                            beta: 30,
                                depth: 200,
                                viewDistance: 5
                                /*
                                        frame: {
                        bottom: {
                            size: 1,
                                color: 'rgba(0,0,0,0.05)'
                        }
                    }*/
                }
            },
    title: {
        text: 'a 3D Scatter Chart'
    },
    subtitle: {
        text: 'with categories on the Z-Axis'
    },
    yAxis: {
        min: 0
        
    },
    xAxis: {
        min: 0,
       // max: nWgt,
        gridLineWidth: 1
    },
    zAxis: {
        /*
        min: 0,
        max: nCF
        */
        //categories:[1,2,3,4,5,6,7,8], 
       // categories: ['Apples', 'Pears', 'Bananas', 'Kiwis', 'eeeee'],
       /* 
        labels: {
            y: 5,
            rotation: 18
        }
        */
    },
    plotOptions: {
        series: {
            groupZPadding: 10,
            depth: 60,
            groupPadding: 0,
            grouping: false,
        }
    },
    series: d3dat
    /*
    [
        {
            stack: 0,
            data: [{ x:1  ,y:8}, { x:2  ,y:9}, { x:4  ,y:10}  ]
        },
        {
            stack: 1,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }   ]
        },
        {
            stack: 2,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }   ]
        },

        {
            stack: 3,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }   ]
        },
        {
            stack: 4,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }   ]
        },

        {
            stack: 5,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }]
        },
        {
            stack: 6,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }]
        },
        {
            stack: 7,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }]
        },
        {
            stack: 8,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }]
        },
        {
            stack: 9,
            data: [{ x: 1, y: 8 }, { x: 2, y: 9 }, { x: 4, y: 10 }]
        },

    ]

    */

        };


    },


    setHeatMapData: function (theData, xCat, yCat) {




        _data.CfData = {

            chart: {
                type: 'heatmap'
                /*
                ,
                marginTop: 40,
                marginBottom: 80,
                plotBorderWidth: 1
                */
            },


            title: {
                text: 'CF/Weight distribution'
            },

            xAxis: {
                categories: xCat // ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas', 'Maria', 'Leon', 'Anna', 'Tim', 'Laura']

                
            },

            yAxis: {
             //   categories: yCat,// ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
             //   min: 5,
             //   max: 16,
               /* 
                labels: {
                    format: '{value} km'
                },
               */

             
                labels: {
                    formatter: function () {
                        var v = this.value / 10;
                        return v;
                        /*
                        return '<a href="' + this.TheCats[this.value] + '">' +
                            this.value + '</a>';
                        */
                    }
                },

                title: null
            },

            colorAxis: {
                min: 1,
                minColor: '#ffffff',// '#F0F7FF',
                maxColor:  "#0483FB"
            },

            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },

            tooltip: {
                formatter: function () {
                    return  this.point.value + ' items' ;
                    /*
                    return '<b>' + this.series.xAxis.categories[this.point.x] + '</b> sold <br><b>' +
                        this.point.value + '</b> items on <br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
                    */
                }
            },
            
            series: [{
                name: 'Sales per employee',
                borderWidth: 0,
               data: theData

              // data:  [[0, 0, 10], [0, 1, 19], [0, 2, 8], [0, 3, 24], [0, 4, 67], [1, 0, 92], [1, 1, 58], [1, 2, 78], [1, 3, 117], [1, 4, 48], [2, 0, 35], [2, 1, 15], [2, 2, 123], [2, 3, 64], [2, 4, 52], [3, 0, 72], [3, 1, 132], [3, 2, 114], [3, 3, 19], [3, 4, 16], [4, 0, 38], [4, 1, 5], [4, 2, 8], [4, 3, 117], [4, 4, 115], [5, 0, 88], [5, 1, 32], [5, 2, 12], [5, 3, 6], [5, 4, 120], [6, 0, 13], [6, 1, 44], [6, 2, 88], [6, 3, 98], [6, 4, 96], [7, 0, 31], [7, 1, 1], [7, 2, 82], [7, 3, 32], [7, 4, 30], [8, 0, 85], [8, 1, 97], [8, 2, 123], [8, 3, 64], [8, 4, 84], [9, 0, 47], [9, 1, 114], [9, 2, 31], [9, 3, 48], [9, 4, 91]]
               /*
                dataLabels: {
                    enabled: true,
                    color: '#000000'
                }
               */
            }]

        };

    },

    setChartData: function (data, useDataRangeInSubtitle) {
        if (!useDataRangeInSubtitle)
            useDataRangeInSubtitle = false;

    	if(!data ) {
			//_data.weightAndTimeData = undefined
    		_data.CfData = null;
    		return;
    	}

		var theDay = new Date (_data.dateStr);



		var formattedDate = moment(theDay).format ("MMM. DD, YYYY");

		var numMeasurements = data.DataList.length;

        var dataList = [];
        for(var i = 0; i <numMeasurements; ++i) {
			dataList.push ([data.DataList[i].Weight, data.DataList[i].CF]);
			/*
            plotLines.push({
                value: startDate.clone().add(i, "days"),
                width: 2,
                color: "#E0E0E0"
            });
            plotLines.push({
                value: startDate.clone().add(i, "days").add(12, "hours"),
                width: 1,
                color: "#EEEEEE"
            });
            */
        }




        var theTitle = t("cf.chartHeader") + FilterStore.getSelectedPopulationName();

        var theSubTitle = "";

        if (useDataRangeInSubtitle)
            theSubTitle = data.StartDate + " to " + data.EndDate;
        else
            theTitle += " @ " + formattedDate

    	_data.CfData = {
    		chart: {
    			type: "scatter",
    			zoomType: "xy",
    			renderTo: ""



    		},

            navigation: {
                buttonOptions: VConst.CONTEXT_BUTTON_SHAPE

            },
            exporting: {

              filename: "BMD_CF_Weight "+FilterStore.getSelectedPopulationName()+" "+ theSubTitle
            },
    		title: {
    			text: theTitle
    		},
    		subtitle: {
    			text: theSubTitle
    		},
    		credits: false,
    		// Defines a list of the x-axes we want
	        xAxis: {
				id: "Weight",
	            type: "linear",
	            title: {
	                text:  t("cf.xAxis")
	            },
				/*
                min: startDate.utc().valueOf(),
                max: endDate.utc().valueOf(),
                plotLines: plotLines
                */
	        },
	        yAxis:
	        	{
	        		id: "CF",
	        		title: {
	        			text:  t("cf.yAxis")
	        		},

	        	},

	        tooltip: {
/*
           		shared: true,
           		formatter: function() {
           			if(this.series.color === "rgba(119, 152, 191, .5)") {
           				return  "<b>" + this.series.name +"</b><br/>" +
	                    Highcharts.dateFormat("%H:%M %e. %b %Y",
	                                          new Date(this.x))
	                	+ ", " + this.y + " " + t("wat.grams");
           			} else {
           				return "<b>" + this.y + " V </b><br/>";
           			}
           			
           		}
           	*/
        	},
	        legend: {
	            layout: 'vertical',
	            align: 'left',
	            verticalAlign: 'top',
	            x: 100,
	            y: 70,
	            floating: true,
	            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
	            borderWidth: 1
	        },


			/*
	        plotOptions: {
	            scatter: {
	                marker: {
	                    radius: 3,
	                    states: {
	                        hover: {
	                            enabled: true,
	                            lineColor: 'rgb(100,100,100)'
	                        }
	                    }
	                },
	                states: {
	                    hover: {
	                        marker: {
	                            enabled: false
	                        }
	                    }
	                },
	                tooltip: {
	                    headerFormat: "<b>{series.name}</b></br>",
	                    pointFormat: "{point.x}, {point.y} " + t("wat.grams")
	                }
	            }
	        },
	        */
/*
			plotOptions: {
				scatter: {
					marker: {
						radius: 2
					},
				}
			},
*/
	        series: [
	        	{
	        		name: t("cf.fish"),
	        		yAxis: "CF",
	        		color: "rgba(119, 152, 191, .5)",
	        		data:  dataList
	        	}

	        ]

    	};
    },
/*
    getDataPoints: function(data) {
    	var dataPoints = { weightPoints: [], voltagePoints: [] };
    	for(var i = 0; i < data.length; ++i) {
    		var date = moment(data[i].WeightTime);
    		dataPoints.weightPoints.push([
    			date.valueOf(),
    			data[i].Weight
    		]);
    		dataPoints.voltagePoints.push([ date.valueOf(), data[i].Voltage ]);
    	}
    	return dataPoints;
    },
*/
	notifyMount: function(){






		if (_data && _data!={}){

			this.trigger(_data, false, true);

		}
	},


	fetchCF_ChartToFrom: function(popID, displayDate, dtStr1, dtStr2, MinHourCount) {




		_data.chartLoading = true;
		_data.dateStr = displayDate;
		_data.dtStart = dtStr1;
		_data.dtEnd = dtStr2;
		_data.popID = popID;
		_data.CfData = null;

       
		VakiAPI.fetchCF_ChartToFrom(
			popID,
			dtStr1,
			dtStr2,
            null,
            MinHourCount
		);
		this.trigger ("", true);
	},



    fetchCF_Chart: function(popID, dateString) {


        _data.chartLoading = true;
		_data.dateStr = dateString;
		_data.popID = popID;
		_data.CfData = null;
            VakiAPI.fetchCF_Chart(
                popID,
                dateString
            );
		this.trigger ("", true);
    },

    isLoading: function() {
        return _data.chartLoading;
    },



    getFilter: function() {
        return _data.filter;
    },



    clone: function(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }
    
});

module.exports = CF_ChartStore;