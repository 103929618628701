/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");

var VConst = require("./../../utils/VConst.js");
var NumericInput = require("./NumericInput.jsx");
import PropTypes from "prop-types";


export default class MinWeightFilterNew  extends React.Component{
    static propTypes = {
        minWeight: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    render () {
        return (
            <NumericInput
                value={this.props.minWeight}
                label={this.props.t("filters.minWeight")}
                minValue={VConst.ABSOLUTE_MIN_WEIGHT}
                maxValue={this.props.maxValue}
                actionFunction={this.props.actionFunction} />
        )
    }
};

