'use strict';

import React, {useState, useEffect, useRef} from 'react';

import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel,
  Card,
  Collapse

} from 'react-bootstrap';

import {QuestionCircle} from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./CameraManagement.css');

export default function DeviceLog (props) {

    const [loading, setLoading] = useState (true);

    const [logData, setLogData] = useState([]);
   const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});
 





const GetCameraSetupLogOnDeviceCallback = (result) => {



       if ( result.msg!= '' && result.msg.length > 5 && result.substring(0, 5) == 'Error') {
             setAlertMsg({msg: "Error fetching log: "+result,
                        kind:"danger"   }) ;
            
       
         
        } else {

            setLogData (result.logs)
     
 
        }
         
         setLoading(false);

} 




  useEffect(() =>{



     
  if (!props.Show || props.Device_ID < 0)
    return;
 VakiAPI.GetCameraSetupLogOnDevice( {Device_ID: props.Device_ID}, GetCameraSetupLogOnDeviceCallback);
     

  }, [props.Show, props.Device_ID]);


   

 if (!props.Show || props.Device_ID <= 0)
        return <div/> 






/*
            public string UserName;
            public string Serial_String;
            public string Type;
            public string LogTime;
            public bool In_Use;  //From Computer
            public string Model;
            public string Firmware_Version;
         
            public string Population;
            public string Cage;
            public string Action;
            public string Farm;
            public string Organisation;
*/


    let TheTable = <Spinner/>
    
    //<div className = "center-width100"> <Spinner/>   </div>



const checkboxFormatter = (cell, row)=>{
  
  return  <div className="center-width100 "> <Form.Check	checked={cell} className="mb-4" readOnly /> </div>; 
           
}

const columns = [
		{
			dataField: 'UserName',
			text: 'Name'
		},
		{
			dataField: 'LogTime',
			text: 'Time logged'
		},
		{
			dataField: 'In_Use',
			text: 'In_Use',
            formatter: checkboxFormatter
		},
		{
			dataField: 'Model',
			text: 'Model/Txt'
		}
,
		{
			dataField: 'Firmware_Version',
			text: 'Version'
		}
,
		{
			dataField: 'Population',
			text: 'Population'
		}
,
		{
			dataField: 'Cage',
			text: 'Cage'
		}

,
		{
			dataField: 'Farm',
			text: 'Farm'
		}
,
		{
			dataField: 'Organisation',
			text: 'Organisation'
		}
,
		{
			dataField: 'Action',
			text: 'Action'
		}


	];


    let TheHeader = "";


    if (!loading){
            TheTable =	<BootstrapTable
								keyField="LogTime"
              
								data={logData}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
				
							/>;

            TheHeader = <h3 className="center-width100">  Changelog for device { " "+props.Serial_Number}    </h3>             
    }


    return (
    
    <div className="cs-devlog"> 

    {TheHeader}
{TheTable}

    <Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>

    </div>);






}

