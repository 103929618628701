import React from 'react';
import { Dropdown, DropdownButton, Button, Menu, Row, Col, Alert, Form } from 'react-bootstrap';
import Actions from '../../../actions/Actions.js';
import SearchDropdown from './Dropdowns/SearchDropdown.jsx';
import SelectedDropdownOptions from './Dropdowns/SelectedDropdownOptions.jsx';
import SessionStore from '../../../stores/SessionStore';

export default class AdminEditOrgForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			name: '',
			address: '',
			email: '',
			phone: '',
			loading: false,
			nameEmpty: false
		};

		this.onFormSubmit = () => {
			if (SessionStore.isLoggedOut()) {
				alert(this.props.t('adminView.expiredaccess'));
				Actions.redirectToLogin();
			}
			if (this.state.name === '') {
				this.setState({ nameEmpty: true });
				return;
			}

			Actions.editOrgFormSubmit(this.state.name, this.state.address, this.state.email, this.state.phone);
			this.setState({
				loading: true,
				nameEmpty: false
			});
			var self = this;
			setTimeout(function() {
				self.setState({
					loading: false
				});
			}, 2000);
		};
		this.handleNameChange = (e) => {
			this.setState({ name: e.target.value });
		};
		this.handleAddressChange = (e) => {
			this.setState({ address: e.target.value });
		};
		this.handleEmailChange = (e) => {
			this.setState({ email: e.target.value });
		};
		this.handlePhoneChange = (e) => {
			this.setState({ phone: e.target.value });
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.currentEditOrganisation.id !== this.state.id) {
			this.setState({
				id: nextProps.currentEditOrganisation.id,
				name: nextProps.currentEditOrganisation.name,
				address: nextProps.currentEditOrganisation.address,
				email: nextProps.currentEditOrganisation.email,
				phone: nextProps.currentEditOrganisation.phone,
				nameEmpty: false
			});
		}
	}

	render() {
		return (
			<form>
				<strong>Created on: {this.props.currentEditOrganisation.dateCreated}</strong>
				<Row>
					<Col md={6}>
						<Form.Label>{this.props.t('adminView.name')}</Form.Label>
						<Form.Control
							value={!this.state.name ? '' : this.state.name}
							onChange={this.handleNameChange}
							type="text"
							placeholder={this.props.t('adminView.name') + '..'}
						/>
						<Alert variant="warning" hidden={!this.state.nameEmpty}>
							<strong>
								{' '}
								{this.props.t('adminView.mustEnter')} {this.props.t('adminView.name')}!{' '}
							</strong>
						</Alert>
					</Col>
					<Col md={6}>
						<Form.Label>label={this.props.t('adminView.email')}</Form.Label>
						<Form.Control
							value={!this.state.email ? '' : this.state.email}
							onChange={this.handleEmailChange}
							type="text"
							placeholder={this.props.t('adminView.email') + '..'}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Label>{this.props.t('adminView.address')}</Form.Label>
						<Form.Control
							value={!this.state.address ? '' : this.state.address}
							onChange={this.handleAddressChange}
							type="text"
							placeholder={this.props.t('adminView.address') + '..'}
						/>
					</Col>
					<Col md={6}>
						<Form.Label>{this.props.t('adminView.phoneNumber')}</Form.Label>
						<Form.Control
							value={!this.state.phone ? '' : this.state.phone}
							onChange={this.handlePhoneChange}
							type="text"
							placeholder={this.props.t('adminView.phoneNumber') + '..'}
						/>
					</Col>
				</Row>
				<Row>
					<div className="just-width100">
						<Col md={6}>
							<div className="text-center">
								<strong> Users: </strong>
							</div>
							<SelectedDropdownOptions
								selectedOptions={this.props.currentEditOrganisation.selectedUsers}
								view="editOrg"
								type="user"
								constant
							/>
						</Col>
						<Col md={6}>
							<div className="text-center">
								<strong> Farms: </strong>
							</div>
							<SelectedDropdownOptions
								selectedOptions={this.props.currentEditOrganisation.selectedFarms}
								view="editOrg"
								type="farm"
								constant
							/>
						</Col>
					</div>
				</Row>

				<div className="text-center">
					<Button
						variant="primary"
						onClick={this.onFormSubmit}
						disabled={this.state.loading}
						variant="outline-dark"
					>
						{this.props.t('adminView.submit')}
					</Button>
					<div className="spinner" hidden={!this.state.loading} />
				</div>
			</form>
		);
	}
}
