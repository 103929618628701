
"use strict";
var FileSaver = require("file-saver");
var React = require("react");
var ReactDOM = require("react-dom");
var moment = require("moment");
var ImmutablePropTypes = require("react-immutable-proptypes");

var VConst = require("./../../utils/VConst.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var WeightAndTimeStore = require("./../../stores/WeightAndTimeStore.js");
var Actions = require("./../../actions/Actions.js");
var BGTools = require("./../../utils/BGtools.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
var FilterStore = require("../../stores/FilterStore");




/**
 * React component that displays the actual graph in the Weight and time report.
 * Note that the details for drawing the highchart graph is not here because I moved
 * it to the store.
 */
 export default class  HourDistributionChart extends React.Component{
//var WeightAndTimeChart = export default class   extends React.Component{
 

constructor(props) {

    super(props);
    this.state = {
           loading: false 
    };

     this.makeGraph = (chartData) => {
        if (chartData === undefined) return;

//{"AvgWgt":1538,"Hour":0,"NumMeasurements":3},{"AvgWgt":2390,"Hour":1,"NumMeasurements":1},{"AvgWgt":2384,"Hour":3,"NumMeasurements":3},{"AvgWgt":3964,"Hour":5,"NumMeasurements":114},{"AvgWgt":4566,"Hour":6,"NumMeasurements":781},{"AvgWgt":4373,"Hour":7,"NumMeasurements":521},

        let maxWgt = 0;
        let maxNum = 0;


        
        var fishMeasured = [];
        var avgWgtStr = [];
        var len = chartData.lst.length;




        for (var i = 0; i<len; i++){
            let w = chartData.lst[i].AvgWgt;
            let h = chartData.lst[i].Hour;
            let num = chartData.lst[i].NumMeasurements;
            if (w> maxWgt)
                maxWgt = w; 
            if (num > maxNum)
                maxNum = num;

            fishMeasured.push ({x:h, y:num});
            avgWgtStr.push ({x:h, y:w});    

        }


        var maxAvgWeightYAxis = 13000;
        var maxNumberOfFish = 5000;

        if (maxWgt < 100)
            maxAvgWeightYAxis = 100;
        else if (maxWgt < 1000){
            maxAvgWeightYAxis = (maxWgt/100 +1) * 100;
        } else 
            maxAvgWeightYAxis = (maxWgt/1000 +1) * 1000;

        
         if (maxNum < 10)
            maxNumberOfFish = 10;
        else if (maxNum < 100)
            maxNumberOfFish = (maxNum/10 +1) * 10;
        else if (maxNum < 1000){
            maxNumberOfFish = (maxNum/100 +1) * 100;
        } else 
            maxNumberOfFish = (maxNum/1000 +1) * 1000;


        var domNode = ReactDOM.findDOMNode(this.refs.chartArea);


        var baseFontSize = 14;


        var fsNum = 14;

var oneDayInMS = 24 * 3600 * 1000;
        var startDate = moment (chartData.TheDate);

        var endDate = startDate.add(1, "days");

        var startTime = startDate.valueOf();


///////////////////////////////////


        var chartOptions = {
            chart: {
                renderTo: domNode,
                zoomType: "xy"
            } ,



            title: {
                text: "24-hour Distribution",
                style: {  fontSize: (fsNum * 1.1) + "px" }
               // x: -60 //center
            } ,

            subtitle: {
                text: "Inspect if density of fish around device affects the average weight",
                style: {
                    
                    fontSize: fsNum*0.86+"px"
                    
                }
                //x: -60
            } ,

            credits: false,

            navigation: {
                buttonOptions: VConst.CONTEXT_BUTTON_SHAPE

            } ,

          /*  exporting: objExport,*/




 /*
            labels: {
                items : [{
                    html : this.getFormattedLabelText(sgr, endWeight),
                    style : {
                        left: '0px',
                        top: '-50px',
                        
                        fontSize: (fsNum * 0.78) + "px",
                        fontWeight: "bold"

                    }
                }]
            } ,
*/


            // Defines a list of the x-axes we want
            xAxis: {
                type: "linear",
                title: {
                    text: this.props.t("trendgraph.time"),
                    style: {
                        fontSize: (fsNum*0.86)+"px"
                    }
                } ,

                labels: {
                     style: {
                        fontSize: (fsNum * 0.78) + "px"
                    }
                } ,

                min: 0,
                max: 23
            } ,

            // Defines a list of the y-axes we want
            yAxis: [
                // Average weight y-axis
                {
                    id: "avgWeightYAxis",
                    // Fix the y-axis to be a minimum of 0, if this is not in place then
                    // the autoscaling could show the y-axis with -100g weight when we
                    // get measurements of 0g (meaning no accurate measurements available).
                    min: 0,
                    max: maxAvgWeightYAxis,
                    title: {
                        text: this.props.t("trendgraph.leftYHeader")
  
                    } ,
                    labels: {
                        //  format: "{value}g"

                        formatter ( ) {
                            return BGTools.formatThousandSep(this.value)+"g";
                        } ,

                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }
                    } ,
                    opposite: true,
                    plotLines: [{
                        value: 0,
                        width: 1,
                        color: '#808080'
                    }]
                } ,
                // Number of fish that swam through the frame y-axis
                {
                    id: "fishMeasuredYAxis",
                    title: {
                        text: this.props.t("trendgraph.rightYHeader"),
                        style: {
                            fontSize: (fsNum * 0.86) + "px"
                        }

                    } ,
                    min: 0,
                    max: maxNumberOfFish,

                    labels: {

                        formatter ( ) {
                            return BGTools.formatThousandSep(this.value);
                        } ,
                        style: {
                            fontSize: (fsNum * 0.78) + "px"
                        }

                    } ,

                   // opposite: true, // put on right side
                    format: "{value} {this.props.t('trendgraph.fish')}"


                } 
              
            ],




            tooltip: {
                shared: true,

 

                //Be careful about the position. if too close to point it prevents the click events
                positioner (w, h, point) {


                    var tooltipX, tooltipY;
                    tooltipX = point.plotX + chart.plotLeft -w - 30;
                    if (point.plotX < w*0.67)
                        tooltipX = point.plotX + chart.plotLeft  + 30;
                    tooltipY = (chart.plotHeight/2)+chart.plotTop ;
                    return{  
                        x: tooltipX,
                        y: tooltipY
                    };
                } 

        





/*

                formatter ( ) {
                    if(this.points === undefined) return;
                   
                    var comment = "", color = "";
                    // alert (this.points.length+ "  "+this.points[0].point.index+"    "+ frameTypes[this.points[0].point.index]+"   " +fishMeasured[this.points[0].point.index]);
                    var date = moment.utc(this.x);

                    // var format = '<span style="font-size: 10px">' + date.format("dddd MMMM Do, YYYY") + '</span><br/>';
                    var format = '<span style="font-size: ' + baseFontSize*0.75 + 'px">' + date.format("ddd MMM Do, YYYY") + '</span><br/>';
                    comment = "<b>"+strFrame + ": </b>" + frameTypes[this.points[0].point.index] +"<br/>";
                    if(comments[this.points[0].point.index] !== "") {

                        comment+= "<b> Comment: </b><br />" + comments[this.points[0].point.index];

                    }
                    for(var i = 0; i < this.points.length; ++i) {
                        var extra = "";
                        if(this.points[i].series.name === t("trendgraph.fishMeasured")) {
                            color = '<span style="color:rgba(192, 192, 192, 0.5)"' + ">● </span>"
                        } else if (this.points[i].series.name === t("trendgraph.temperature")) {
                            color = '<span style="color:#F5A05B"' + ">● </span>";
                            extra = " °C";
                        } else if(this.points[i].series.name === t("trendgraph.conditionFactor")) {
                            color = '<span style="color:#91eb7c"' + ">● </span>";
                        } else if(this.points[i].series.name === t("trendgraph.averageWeight") ) {
                            color = '<span style="color:#7cb5eb"' + ">● </span>";
                            extra = "g";
                        } else if(this.points[i].series.name === t("trendgraph.trendline")) {
                            color = '<span style="color:#b34442"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.depthonframe")) {
                            color = '<span style="color:#7fffd4"' + ">● </span>";
                            extra = "m";
                        }else if(this.points[i].series.name === t("Aux.Weight Data")) {
                            color = '<span style="color:#F771F9"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.tl30")) {
                            color = '<span style="color:#8000FF"' + ">● </span>";
                            extra = "g";
                        }else if(this.points[i].series.name === t("trendgraph.tl60")) {
                            color = '<span style="color:#0000FF"' + ">● </span>";
                            extra = "g";
                        }



                        format +=  color + '<span style="font-size: ' + baseFontSize*0.7 + 'px">' +this.points[i].point.series.name + ": <b>"
                            + this.points[i].point.y + extra + "</b></span><br/>";




                    }
                    return '<div>' + format + comment + '</div>';
                }
*/
            }, 

            plotOptions: {
 column: {
                pointWidth: 10,
            
            }


        }   ,

            legend: {

                itemStyle: {
                    fontSize: (fsNum * 0.86) + "px",
                    fontWeight: "normal"
                } ,
                //layout: 'horizontal',
                //align: 'right',
                //verticalAlign: 'middle',
                borderWidth: 0
            } ,

            series: [


                {
                    index: 0,

                    visible: BGTools.readBoolStorage("TrGraph0", true),
                    name: this.props.t("trendgraph.fishMeasured"),
                    
           
                    fontSize: "5px",
                    data: fishMeasured,
                    type: "column",
                    color: VConst.COLORS.FISH_MEASURED,
                    yAxis: "fishMeasuredYAxis",
                    pointStart: startTime,
                    pointInterval: oneDayInMS,
                    marker: {
                        enabled: false
                    }
                } ,



                {
                    index: 1,

                    visible: BGTools.readBoolStorage("TrGraph3", true),
                    name: this.props.t("trendgraph.averageWeight"),
                    yAxis: "avgWeightYAxis",
                    type: "line",
                    color: VConst.COLORS.AVG_WEIGHT,
                    pointStart: startTime,
                    pointInterval: oneDayInMS,
                    tooltip: {
                        valueSuffix: "g"
                    } ,

                    data: avgWgtStr
                } ,






  
            ]
        };




///////////////////////////////////////////////////
      
        var chart = new Highcharts.Chart(chartOptions);
        //var found = BGTools.isHCTextButton("wgttime");
		BGTools.clearMyHiChartsButtons();
		var found = false;	
        if (chart.series[0].data.length > 1 && !found) {
            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-wgttime",
                text: t("misc.textfilebuttontext"),
                // style: {color: "green" },
                onclick  (){
                    return downLoadText (this, false);
                }
            });

            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push({
                id: "textFile-wgttimeCSV",
                text: t("misc.textfilebuttoncsv"),
                // style: {color: "green"},
                onclick  (){
                    return downLoadText (this, true);
                }
            });
        }




    };


   
  };//Constructor

    componentDidMount  () {

        this.makeGraph(this.props.chartData);
     };
    componentDidUpdate  (prevProps, prevState) {
        this.makeGraph(this.props.chartData);
     };

    render  () {
        return (
            <div className="data-chart" ref="chartArea">
                <div className="text-center">
                    <div className="spinner" hidden={!this.state.loading}></div>
                </div>
            </div>
        )
    }
};

