/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 *
 * This Component serves as a parent to the admin view, unifying all other components
 * and re arranging the view according to which route is active. (i.e. Users, Organisations or Farms)
 * It listens to changes in all sub-component stores and passes down information based on what has
 * been updated.
 */

'use strict';

import React from 'react';
import { Dropdown, DropdownButton, Button, Menu, Row, Col, FormControl, Form } from 'react-bootstrap';
import {Check}  from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion';
import MyPanel from '../../utils/MyPanel.jsx';

import AdminViewUserList from './Tables/AdminViewUserList.jsx';
import AdminViewOrgList from './Tables/AdminViewOrgList.jsx';
import AdminViewFarmList from './Tables/AdminViewFarmList.jsx';
import AdminViewCompList from './Tables/AdminViewCompList.jsx';
import AdminCreateUserForm from './Forms/AdminCreateUserForm.jsx';
import AdminCreateOrgForm from './Forms/AdminCreateOrgForm.jsx';
import AdminCreateFarmForm from './Forms/AdminCreateFarmForm.jsx';
import AdminCreateCompForm from './Forms/AdminCreateCompForm.jsx';
import AdminEditUserForm from './Forms/AdminEditUserForm.jsx';
import AdminEditOrgForm from './Forms/AdminEditOrgForm.jsx';
import AdminEditFarmForm from './Forms/AdminEditFarmForm.jsx';
import AdminEditCompForm from './Forms/AdminEditCompForm.jsx';
import AdminRoutePicker from './AdminRoutePicker.jsx';
import AdminViewStore from '../../stores/AdminViewStore';
import CreateUserFormStore from '../../stores/AdminViewStores/CreateUserFormStore';
import EditUserFormStore from '../../stores/AdminViewStores/EditUserFormStore';
import CreateOrgFormStore from '../../stores/AdminViewStores/CreateOrgFormStore';
import EditOrgFormStore from '../../stores/AdminViewStores/EditOrgFormStore';
import CreateFarmFormStore from '../../stores/AdminViewStores/CreateFarmFormStore';
import EditFarmFormStore from '../../stores/AdminViewStores/EditFarmFormStore';
import CreateCompFormStore from '../../stores/AdminViewStores/CreateCompFormStore';
import EditCompFormStore from '../../stores/AdminViewStores/EditCompFormStore';
import Actions from './../../actions/Actions.js';
import SessionStore from './../../stores/SessionStore.js';
import TranslateStore from './../../stores/TranslateStore.js';
var t = TranslateStore.getTranslate();
var _isMounted;
export default class AdminView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: AdminViewStore.getFilter(),
			tableActive: true,
			activeKey: '1',
			showDelUsers: false,
			showDelFarms: false,
			showDelComputers: false,
			showDelOrganisations: false,
			showInactiveFarms: false,
			showInactiveComputers: false,
			showOnlyAdminUsers: false,
			translateTrigger: false
		};

		_isMounted = false;
		/********************componentWillMount ()************************ */
		if (SessionStore.isLoggedOut()) {
			Actions.redirectToLogin();
		}
		/*
        *  Put this here as an effort to deal with a bug resulting on 
        *  no data showing up when the user has just logged in
        */
		if (this.state.filter.users.length === 0) {
			AdminViewStore.triggerDataFetch();
		}
		/******************************************** */

		this.handlePanelChange = (activeKey) => {
			this.setState({ activeKey: activeKey });
		};
		this.onShowDeletedUsersClick = () => {
			Actions.showDeletedUsers();
			var checked = this.state.showDelUsers;
			this.setState({ showDelUsers: !checked });
		};
		this.onShowDeletedOrganisationsClick = () => {
			Actions.showDeletedOrganisations();
			var checked = this.state.showDelOrganisations;
			this.setState({ showDelOrganisations: !checked });
		};
		this.onShowDeletedFarmsClick = () => {
			
			Actions.showDeletedFarms();
			var checked = this.state.showDelFarms;


			this.setState({ showDelFarms: !checked });
		};
		this.onShowDeletedComputersClick = () => {
			Actions.showDeletedComputers();
			var checked = this.state.showDelComputers;
			this.setState({ showDelComputers: !checked });
		};
		this.onShowInactiveFarmsClick = () => {
			Actions.showInactiveFarms();
			var checked = this.state.showInactiveFarms;
			this.setState({ showInactiveFarms: !checked });
		};
		this.onShowInactiveComputersClick = () => {
			Actions.showInactiveComputers();
			var checked = this.state.showInactiveComputers;
			this.setState({ showInactiveComputers: !checked });
		};
		this.onShowOnlyAdminUsersClick = () => {
			Actions.showOnlyAdminUsers();
			var checked = this.state.showOnlyAdminUsers;
			this.setState({ showOnlyAdminUsers: !checked });
		};

		/*
    * Sets the appropriate view according to which route button is active.
    */
		this.getView = () => {
			var view = {};

			if (this.state.filter.route === 'Users') {
				view = this.getUserView(view);
			} else if (this.state.filter.route === 'Organisations') {
				view = this.getOrgsView(view);
			} else if (this.state.filter.route === 'Farms') {
				view = this.getFarmsView(view);
			} else if (this.state.filter.route === 'Computers') {
				view = this.getCompsView(view);
			} else {
				view = this.getUserView(view);
			}

			return view;
		};
		/*
	* These functions make the render functions more compact.
	*/
		this.getUserView = (view) => {
			view.title = (
				<Row>
					<Col md={3}>
						<h2> {t('adminView.users')} </h2>
					</Col>
					<Col md={3}>
						<div className="show-deleted-checkbox">
							<DropdownButton title="Filters"  variant="outline-dark">
								<Dropdown.Item onClick={this.onShowOnlyAdminUsersClick} >
									Show only administrators
									<span hidden={!this.state.showOnlyAdminUsers}>
										<Check/>
									</span>
								</Dropdown.Item>
								<Dropdown.Item onClick={this.onShowDeletedUsersClick}>
									Show deleted users
									<span hidden={!this.state.showDelUsers}>
										<Check/>
									</span>
								</Dropdown.Item>
							</DropdownButton>
						</div>
					</Col>
					<Col md={6} />
				</Row>
			);
			view.table = (
				<AdminViewUserList users={this.state.filter.users} tableActive={this.state.tableActive} t={t} />
			);
			view.form = (
				<Accordion activeKey={this.state.activeKey} onSelect={this.handlePanelChange}>
					<Accordion.Item eventKey="1">
						<Accordion.Header>{t('adminView.createUser')} </Accordion.Header>
						<Accordion.Body>
							<AdminCreateUserForm
								users={this.state.filter.users}
								translateTrigger={this.state.translateTrigger}
								currentUser={CreateUserFormStore.getCurrentUser()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>{t('adminView.editUser')}</Accordion.Header>
						<Accordion.Body>
							<AdminEditUserForm
								users={this.state.filter.users}
								translateTrigger={this.state.translateTrigger}
								currentEditUser={EditUserFormStore.getCurrentEditUser()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);

			return view;
		};



		this.getOrgsView = (view) => {
			view.title = (
				<Row>
					<Col md={3}>
						<h2> {t('adminView.organisations')} </h2>
					</Col>
					<Col md={3}>
						<div className="show-deleted-checkbox">
							<Form.Check
								label="Show deleted organisations"
								onChange={this.onShowDeletedOrganisationsClick}
								checked={this.state.showDelOrganisations}
							/>
						</div>
					</Col>
					<Col md={6} />
				</Row>
			);
			view.table = (
				<AdminViewOrgList orgs={this.state.filter.organisations} tableActive={this.state.tableActive} t={t} />
			);
			view.form = (
				<Accordion activeKey={this.state.activeKey} onSelect={this.handlePanelChange}>
					<Accordion.Item eventKey="1">
						<Accordion.Header>{t('adminView.createOrg')} </Accordion.Header>
						<Accordion.Body>
							<AdminCreateOrgForm
								users={this.state.filter.users}
								translateTrigger={this.state.translateTrigger}
								organisations={this.state.filter.organisations}
								currentOrganisation={CreateOrgFormStore.getCurrentOrganisation()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>{t('adminView.editOrg')}</Accordion.Header>
						<Accordion.Body>
							<AdminEditOrgForm
								users={this.state.filter.users}
								translateTrigger={this.state.translateTrigger}
								organisations={this.state.filter.organisations}
								currentEditOrganisation={EditOrgFormStore.getCurrentEditOrganisation()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);

			return view;
		};

		this.getFarmsView = (view) => {
			view.title = (
				<Row>
					<Col md={3}>
						<h2> {t('adminView.farms')} </h2>
					</Col>
					<Col md={3}>
						<div className="show-deleted-checkbox">
							<DropdownButton title="Filters" variant="outline-dark">
								<Dropdown.Item onClick={this.onShowDeletedFarmsClick} >
									Show deleted farms
									<span hidden={!this.state.showDelFarms}>
										<Check/>
									</span>
								</Dropdown.Item>
								<Dropdown.Item onClick={this.onShowInactiveFarmsClick}  >
									Show inactive farms
									<span hidden={!this.state.showInactiveFarms}>
										<Check/>
									</span>
								</Dropdown.Item>
							</DropdownButton>
						</div>
					</Col>
					<Col md={6} />
				</Row>
			);
			view.table = (
				<AdminViewFarmList farms={this.state.filter.farms} tableActive={this.state.tableActive} t={t} />
			);
			view.form = (
				<Accordion activeKey={this.state.activeKey} onSelect={this.handlePanelChange}>
					<Accordion.Item eventKey="1">
						<Accordion.Header>{t('adminView.createFarm')} </Accordion.Header>
						<Accordion.Body>
							<AdminCreateFarmForm
								farms={this.state.filter.farms}
								translateTrigger={this.state.translateTrigger}
								currentFarm={CreateFarmFormStore.getCurrentFarm()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>{t('adminView.editFarm')}</Accordion.Header>
						<Accordion.Body>
							<AdminEditFarmForm
								farms={this.state.filter.farms}
								translateTrigger={this.state.translateTrigger}
								currentEditFarm={EditFarmFormStore.getCurrentEditFarm()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);
			return view;
		};

		this.getCompsView = (view) => {
			view.title = (
				<Row>
					<Col md={3}>
						<h2> {t('adminView.computers')} </h2>
					</Col>
					<Col md={3}>
						<div className="show-deleted-checkbox">
							<DropdownButton title="Filters" variant="outline-dark">
								<Dropdown.Item onClick={this.onShowDeletedComputersClick}>
									Show deleted computers
									<span hidden={!this.state.showDelComputers}>
										<Check/>
									</span>
								</Dropdown.Item>
								<Dropdown.Item onClick={this.onShowInactiveComputersClick} >
									Show inactive computers
									<span hidden={!this.state.showInactiveComputers}>
										<Check/>
									</span>
								</Dropdown.Item>
							</DropdownButton>
						</div>
					</Col>
					<Col md={6} />
				</Row>
			);
			view.table = (
				<AdminViewCompList comps={this.state.filter.computers} tableActive={this.state.tableActive} t={t} />
			);
			view.form = (
				<Accordion activeKey={this.state.activeKey} onSelect={this.handlePanelChange}>
					<Accordion.Item eventKey="1">
						<Accordion.Header>{t('adminView.createComp')} </Accordion.Header>
						<Accordion.Body>
							<AdminCreateCompForm
								computers={this.state.filter.computers}
								translateTrigger={this.state.translateTrigger}
								currentComputer={CreateCompFormStore.getCurrentComputer()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>{t('adminView.editComp')} </Accordion.Header>
						<Accordion.Body>
							<AdminEditCompForm
								computers={this.state.filter.computers}
								translateTrigger={this.state.translateTrigger}
								currentEditComputer={EditCompFormStore.getCurrentEditComputer()}
								t={t}
							/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);
			return view;
		};
		/*
	* Split this up in an effort to re-render only what needs to be at any time.
	*/
		this.onChange = (newFilter) => {
			if (_isMounted) this.setState({ filter: newFilter, tableActive: true });
		};
		this.onTranslateStoreChange = () => {
			var tt = this.state.translateTrigger;
			this.setState({ translateTrigger: !tt, tableActive: true });
		};
		this.onCreateChange = () => {
			if (_isMounted) this.setState({ tableActive: false });
		};
		this.onEditChange = () => {
			if (_isMounted) this.setState({ tableActive: false, activeKey: '2' });
		};
	} //constructor

	componentDidMount() {
		_isMounted = true;
		this.unsubscribeAdminViewStore = AdminViewStore.listen(this.onChange);
		this.unsubscribeUserStore = CreateUserFormStore.listen(this.onCreateChange);
		this.unsubscribeEditUserStore = EditUserFormStore.listen(this.onEditChange);
		this.unsubscribeOrgStore = CreateOrgFormStore.listen(this.onCreateChange);
		this.unsubscribeEditOrgStore = EditOrgFormStore.listen(this.onEditChange);
		this.unsubscribeFarmStore = CreateFarmFormStore.listen(this.onCreateChange);
		this.unsubscribeEditFarmStore = EditFarmFormStore.listen(this.onEditChange);
		this.unsubscribeCompStore = CreateCompFormStore.listen(this.onCreateChange);
		this.unsubscribeEditCompStore = EditCompFormStore.listen(this.onEditChange);
		this.unsubscribeTranslateStore = TranslateStore.listen(this.onTranslateStoreChange);
	}
	componentWillUnmount() {
		_isMounted = false;
		this.unsubscribeAdminViewStore();
		this.unsubscribeUserStore();
		this.unsubscribeEditUserStore();
		this.unsubscribeOrgStore();
		this.unsubscribeEditOrgStore();
		this.unsubscribeFarmStore();
		this.unsubscribeEditFarmStore();
		this.unsubscribeCompStore();
		this.unsubscribeEditCompStore();
		this.unsubscribeTranslateStore();
	}

	render() {
		var view = this.getView();
		return (
			<div>
				<AdminRoutePicker route={this.state.filter.route} t={t} />
				{view.title}
				<Row>
					<Col md={6}>{view.table}</Col>
					<Col md={6}>{view.form}</Col>
				</Row>
			</div>
		);
	}
}

/*
return (
			<div>
		    <AdminRoutePicker route={this.state.filter.route} t={t} />
		    {view.title}
		    <Row>
		    	<Col md={6}>
		    		{view.table}
		    	</Col>
		    	<Col md={6}>
		    		{view.form}
		    	</Col>
		    </Row>
		    </div>
		);
*/
