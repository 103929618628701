var React = require('react');
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {XLg} from 'react-bootstrap-icons';
var Actions = require('../../../../actions/Actions.js');

export default class SelectedDropdownOptions extends React.Component {
	constructor(props) {
		super(props);

		this.getSelectedOptions = () => {
			var self = this;

			var options = this.props.selectedOptions.map(function(option, i) {
				var tooltip = '';
				var theExtra = '';

				var name = option.name;
				if (self.props.type === 'role' || self.props.type === 'userRole') tooltip = option.description;
				else if (self.props.type === 'organisation') {
					if (option.farmList !== undefined) tooltip = option.farmList.length + ' farms';
				} else if (self.props.type === 'user') {
					tooltip = option.username;
					name = option.username;
				} else if (self.props.type === 'farmOrg') {
					tooltip = option.name;
					name = option.name;
					if (option.org) theExtra = ' - ' + option.org;
					else if (option.organisation) {
						theExtra = ' - ' + option.organisation.name + ' (' + option.organisation.id + ')';
					}

					
				} else tooltip = option.name;
		
				return (
					<li key={i}  className={"margin0"}>
						<OverlayTrigger placement="top" overlay={<Tooltip    className="margin0">{tooltip}</Tooltip>}    >
							<span>
								{' '}
								{name} ({option.id}) {theExtra}{' '}
							</span>
						</OverlayTrigger>
						<span hidden={self.props.constant}>
							<Button size="sm" onClick={self.removeOption.bind(null, option) }  variant="light">
								{<XLg/>}
							</Button>
						</span>
					</li>
				);
			});

			return options;
		};

		this.removeOption = (option) => {
			Actions.removeSelectedOption(option, this.props.view, this.props.type);
		};
	} //constructor
	
	render() {
		var selectedOptions = this.getSelectedOptions();

		var displayTimezoneMsg = this.props.type === 'timezone' && this.props.selectedOptions.length === 1;
		return (
			<ul className="admin-view-form-lists">
				<div className="text-center">
					<li hidden={!this.props.isAdmin}>
						{' '}
						Admins and employees have access to all farms and organisations{' '}
					</li>
					<li hidden={!displayTimezoneMsg}> Timezone selected: </li>
					{selectedOptions}
				</div>
			</ul>
		);
	}
}
