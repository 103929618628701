/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");
var ReactDOM = require ("react-dom");
var VConst = require("./../../utils/VConst.js");
var BGTools = require("./../../utils/BGtools.js");
import PropTypes from "prop-types";
var chart = null;


function createGraph(domNode, weightHistogramList, t, binSize, bGutted,  Day, Location, hideLegends, hideExport) {
    var ofWeight = [];
    var ofCount = [];
    var xCategorie = [];


    var myTitle = "";

    if (!hideExport)
        hideExport = false;

    if (bGutted){
            myTitle = t("distribution.guttedWeightHeader");
        } else{
            myTitle = t("distribution.liveWeightHeader");
        }
    var numCols = weightHistogramList.length;


try {


    var xStep = Math.round(numCols / 16 );


    for (var i = 0; i < numCols ; i++) {
        ofWeight.push(weightHistogramList[i].BinBiomassPortion  );
        ofCount.push(weightHistogramList[i].BinPopulationPortion );
        xCategorie.push ( parseFloat(weightHistogramList[i].BinStartWeight)/1000+ " - "+ parseFloat(weightHistogramList[i].BinEndWeight)/1000 );
    }



    const el = document.querySelector('.HiChartsBaseFontSize');

    var fsNum = 14;

    if (el)
        fsnum = parseInt(getComputedStyle(el).fontSize);

        

    var chartOptions = {
        credits: {
            enabled: false  //hide watermark
        } ,
        legend: {
            enabled: !hideLegends
        } ,
        exporting: {
            enabled: !hideExport,
            filename: "Weight_Distribution "+Location+"_"+Day,  // +populationName+" "+ subTitle,
        } , //hide print / context menu
        chart: {
            type: 'column',
            renderTo: domNode
        } ,

            title: {
                text: myTitle, // t("distribution.liveWeightHeader"),
               style: { fontSize: (fsNum * 1.1) + "px" }

            } ,

        subtitle: {
            style: { fontSize: (fsNum * 0.86) + "px" } ,

            text: Day
            //,
            //y: 35
        } ,

        xAxis: {
                categories: xCategorie,
            labels: {
                style: { fontSize: (fsNum * 0.78) + "px" } ,

                    rotation: 90,
                    step: parseInt(xStep)
                } ,



              //  crosshair: true
            } ,
            yAxis: {
                min: 0
                    ,
                title: {
                    style: { fontSize: (fsNum * 0.86) + "px" } ,
                    enabled: false
                } ,

               labels: {
                    style: { fontSize: (fsNum * 0.78) + "px" }

                } ,


            } ,
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key} kg</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            } ,
            plotOptions: {

                series: {


                    events: {
                        legendItemClick () {

                         this.show();
                         if (this.index == 0)
                            chart.series[1].hide();
                         else
                            chart.series[0].hide();

                            return false;
                            // <== returning false will cancel the default action
                        }
                    } ,

                    visible: false,
                } ,

                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }

            } ,
            series: [{
                index: 0,
                name: t("distribution.pOfBiomass"),
                data: ofWeight,
                visible:true,
                color: VConst.COLORS.AVG_WEIGHT


            } , {
                index: 1,
                name: t("distribution.pOfPop"),
                data: ofCount,
                visible:false,
                color: VConst.COLORS.DISTRIBUTION_RED

            }]


        };


    chart = new Highcharts.Chart(chartOptions

	
      /*  ,
        function(chart) {
        Actions.trendGraphDoneLoading();
    }
    */
    );
	
	BGTools.clearMyHiChartsButtons();
   // var found = BGTools.isHCTextButton("XXXXXX");




}catch(er){
    alert ("Exception in LiveWeight: " + er);
}

}

export default class LiveWeightDistribution  extends React.Component{
    static propTypes = {
        // Todo: create a better definition
        weightHistogramList: PropTypes.any.isRequired,
        theBinSize: PropTypes.number,
        bGutted: PropTypes.bool,
        HideLegend: PropTypes.bool 
    };

    componentDidMount ( ) {

        var domNode = ReactDOM.findDOMNode(this);

        createGraph(domNode, this.props.weightHistogramList, this.props.t, this.props.theBinSize, this.props.bGutted, this.props.Day,
            this.props.Location, this.props.HideLegend, this.props.HideExport);
    };

    shouldComponentUpdate ( nextProps, nextState) {
        var domNode = ReactDOM.findDOMNode(this);

        createGraph(domNode, nextProps.weightHistogramList, this.props.t, nextProps.theBinSize, nextProps.bGutted, nextProps.Day, nextProps.Location,
            nextProps.HideLegend, nextProps.HideExport);
        return false;
    };

    render () {

        var theWidth = "400px";
        var theHeight = "400px";
      
        if (!this.props.ChartWidth)
            ;
        else
            theWidth = this.props.ChartWidth;

        if (!this.props.ChartHeight)
            ;
        else
            theHeight = this.props.ChartHeight;


      var divStyle = {
            width: theWidth,
            height: theHeight
        };

        return (
            <div style={divStyle}></div>
        )
    }
};

