
"use strict";
import Reflux from "reflux";
import Actions from "./../../actions/Actions.js";
import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel

} from 'react-bootstrap';
import ReactDOM from "react-dom";
import moment from "moment";
import Vutils from "./../../utils/Vutil.js";
import VakiReactPropTypes from "./../../utils/VakiReactPropTypes.js";
import TranslateStore from "./../../stores/TranslateStore.js";
import UserDataStore from "./../../stores/UserDataStore.js";
import VConst from "./../../utils/VConst.js";
var t = TranslateStore.getTranslate();
import FilterStore from "./../../stores/FilterStore.js";
import VakiAPI from "./../../webservice/VakiAPI.js";
import ReactPropTypes from "prop-types";
require("./ManualLinks.css");




var ManualLinkEditStore = Reflux.createStore({
    init () {

        this.listenTo(Actions.manListReceived, this.onRcv);

        
        this.data = "";
        
        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },

    onRcv (resp) {
        this.data = resp;
        this.trigger(this.data);
    }






});




export default class ManualLinkEdit extends React.Component{

//var ManualLinkEdit = export default class   extends React.Component{



    static propTypes = {
        ID:   ReactPropTypes.number, 
        Link:   ReactPropTypes.string,
        ToolTip:   ReactPropTypes.string,
        LinkText:   ReactPropTypes.string,
        AdminOnly:   ReactPropTypes.bool,
        Language:   ReactPropTypes.string,
        NoEditInfo:   ReactPropTypes.string,
        NewLink:   ReactPropTypes.bool.isRequired 
    };


    constructor(props) {
     super(props);
 
        this.state = {
            id:-1,
            strLink: "",
            strToolTip: "",
            strLinkText: "",
            bAdminOnly: false,
            strNoEditInfo: "",
            anyChange: false,
            loaded: false


        };

           this.onData =(data)=> {
        this.setState({ fileArray: data });
    };

    this.onLinkEdit = (e) => {
        this.state.strLink = e.target.value;
        this.state.anyChange = true;
        this.setState({});

    };


    this.onLinkTextEdit  = (e) => {
        this.state.strLinkText = e.target.value;
        this.state.anyChange = true;

        this.setState({});
    };


    this.onToolTipEdit  = (e) => {
        this.state.strToolTip = e.target.value;
        this.state.anyChange = true;

        this.setState({});
    };

    this.onAdminOnly  = (e) => {
        this.state.bAdminOnly = !this.state.bAdminOnly;
        this.state.anyChange = true;

        this.setState({});
    };

    this.onSaveClick = () => {
        if (!this.state.id)
            this.state.id = 0;
     

        var manObj = {

            ID: this.state.id,
            Link: this.state.strLink,
            Language: "",
            LinkText: this.state.strLinkText,
            ToolTipText: this.state.strToolTip,
            AdminOnly: this.state.bAdminOnly
        };

        VakiAPI.PostManual(manObj );

        


    };




    this.onDeleteClick = () => {
        if (!this.state.id)
            this.state.id = 0;

        if (!confirm("Confirm deleting of this manual link!"))
            return;
        var manObj = {

            ID: this.state.id
    
        };

        VakiAPI.DeleteManual(manObj);




    };




    };

 

    componentDidMount () {
       // this.unsubscribeManualDownloadStore = ManualDownloadStore.listen(this.onData);
    };


    componentWillMount () {

        //this.unsubscribeManualDownloadStore();
    };

    componentWillUnmount () {
        //this.unsubscribeManualDownloadStore();
    };

  


    render () {

        if (!this.state.loaded || (this.state.id != this.props.ID)  ) {

            this.state.id = this.props.ID;
            this.state.strLink = this.props.Link;
            this.state.strToolTip = this.props.ToolTip;
            this.state.strLinkText = this.props.LinkText;
            this.state.bAdminOnly = this.props.AdminOnly;
            this.state.strNoEditInfo = this.props.NoEditInfo;
            this.state.anyChange = false;
            this.state.loaded = true;
        }








        var testStyle = {
            display: ((this.state.strLink && this.state.strLink.length > 2) ? "block" : "none" )
        }

        return (<div id="divEditBox"> 

         
            <h3>Edit document link</h3>
            <pre id = "preNoEdit"> 
                {this.state.strNoEditInfo}
            </pre>
            <Form.Label className="mb-0"> Path to document: </Form.Label>
            <Form.Control

                id="theLink"
                type="text"
                label={"Path to document:"}
                onChange={function (e) {
                    e.stopPropagation();
                }}

                value={this.state.strLink}
                placeholder={"Copy document link in here"}
                onChange={this.onLinkEdit} />

            
            <div id="divTestLink" style={testStyle}>
                <a target="_blank" href={this.state.strLink}  >{"Test: (" + this.state.strLink + ")"}</a>
                </div>

 <Form.Label className="mb-0"> Link text (instead of the link itself - optional </Form.Label>
            <Form.Control

                id="theLinkText"
                type="text"
       
                onClick={function (e) {
                    e.stopPropagation();
                }}

                value={this.state.strLinkText}
                placeholder={"Link text here"}
                onChange={this.onLinkTextEdit} />


             <Form.Label className="mb-0"> Tooltip text (optional) </Form.Label>
            <Form.Control

                id="theLink"
                type="text"
           
                onClick={function (e) {
                    e.stopPropagation();
                }}
                value={this.state.strToolTip}
                placeholder={"Tooltip text here"}
                onChange={this.onToolTipEdit}  
                 />

            <div id="divAdminOnly">

            <Form.Check type="checkbox" label={"Seen by admins only"} checked={this.state.bAdminOnly}
                onChange={this.onAdminOnly}  />
                </div>

            <div id="divButtons">
                <Button onClick={this.onSaveClick} variant="outline-dark" disabled={!this.state.anyChange || this.state.strLink.length<3 }>Save</Button>

                <Button onClick={Actions.manEditGetList} variant="outline-dark" >Cancel</Button>
                <Button onClick={this.onDeleteClick} disabled={!this.state.id || this.state.id<=0 } variant="outline-dark" >Delete</Button>

                </div>








        </div>)
    };
};

//module.exports = ManualLinkEdit;