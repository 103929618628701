/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

 "use strict";

var React = require("react");
import {ArrowRepeat, Trash}  from 'react-bootstrap-icons';
var RB = require("react-bootstrap");
var Actions = require("../../../../actions/Actions.js");
var ConfirmDeleteModal = require("./Modals/ConfirmDeleteModal.jsx");

export default class OrgListRow  extends React.Component{

	constructor(props) {
     super(props);

	this.orgClicked = ( ) => {
		Actions.orgListRowClick(this.props.org);
	};
	this.addressIsNullOrEmpty = ( ) => {
		return (this.props.org.address === null) || (this.props.org.address === "") || (this.props.org.address === " ")
	};
	this.deleteClicked = ( ) => {
		Actions.orgListRowDelete(this.props.org);
	};
	this.restoreClicked = ( ) => {
		Actions.orgListRowRestore(this.props.org);
	};
	this.getButton = ( ) => {
		if(this.props.org.isActive) {
			return this.getDeleteButton();
		} else {
			return this.getRestoreButton();
		}
	};
	this.getDeleteButton = ( ) => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Delete">
				<button className="btn btn-danger btn-xs" onClick={this.deleteClicked}>
					<Trash/>
				</button>
			</p>
		);
	};
	this.getRestoreButton = ( ) => {
		return (
			<p className="user-table-icon" data-placement="top" data-toggle="tooltip" title="Restore">
				<button className="btn btn-primary btn-xs" onClick={this.restoreClicked}>
					<ArrowRepeat/>
				</button>
			</p>
		);
	};

	};

	render ( ) {
		var address = "";
		var button = this.getButton();
		if(this.addressIsNullOrEmpty()) address = this.props.t("adminView.noAddressRegistered");
		else address = this.props.org.address;
		return (
			<tr>
				<td className="col-md-4" onClick={this.orgClicked.bind(null, this.props.org.name)}> {this.props.org.name} </td>
				<td className="col-md-3" onClick={this.orgClicked.bind(null, this.props.org.name)}> {address} </td>
				<td className="col-md-2" onClick={this.orgClicked.bind(null, this.props.org.name)}> {this.props.org.userList.length} </td>
				<td className="col-md-2" onClick={this.orgClicked.bind(null, this.props.org.name)}> {this.props.org.farmList.length} </td>
				<td className="col-md-2">
					{button}
				</td>
			</tr>
		);
	}
};

