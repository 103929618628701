/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("./../actions/Actions.js");
var i18n = require("i18next-client");
var _t = i18n.translate.bind(i18n);

var TranslateStore = Reflux.createStore({
    init: function() {
        this.listenTo(Actions.setEnglishTranslation, this.onSetEnglish);
        this.listenTo(Actions.setGermanTranslation, this.onSetGerman);
        this.listenTo(Actions.setNorwegianTranslation, this.onSetNorwegian);
        this.listenTo(Actions.setSpanishTranslation, this.onSetSpanish);

        var self = this;
        var language = "en";
        if(typeof window.localStorage !== undefined) language = window.localStorage.biomassDailyLang || "en";
        i18n.init({ lng: language }, function() {
            self.doneLoading();
        });

        i18n.init({ fallbackLng: 'en' });
        
    },
    onSetEnglish: function() {
        var self = this;
        i18n.setLng("en", function() {
            self.setLocalStorageLanguage("en");
            self.doneLoading();
        });
    },
    onSetGerman: function() {
        var self = this;
        i18n.setLng("de", function() {
            self.setLocalStorageLanguage("de");
            self.doneLoading();
        });
    },
    onSetNorwegian: function() {
        var self = this;
        i18n.setLng("no", function() {
            self.setLocalStorageLanguage("no");
            self.doneLoading();
        });
    },
    onSetSpanish: function(){
        var self = this;
        i18n.setLng("es", function() {
            self.setLocalStorageLanguage("es");
            self.doneLoading();
        });
    },
    doneLoading: function() {
        this.trigger();
    },
    setLocalStorageLanguage: function(language) {
        if(typeof window.localStorage !== undefined) {
            window.localStorage.biomassDailyLang = language;
        }
    },
    getTranslate: function() {
        return _t;
    }
});

module.exports = TranslateStore;