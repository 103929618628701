/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var is = require("is_js");
var moment = require("moment");

/**
 * Vaki Utils, intended to be a storage of convenience functions.
 */
var Vutils = {
    /**
     * Receives a float and approximately rounds it to given number of decimal
     * places. If something is wrong we return null.
     *
     * We return null because this function was originally intended to be used
     * as a helper function when constructing a Highchart graph. When values
     * are null it won't show creating a gap, which is our intended behaviour.
     *
     * Note that this is just a convenience function using JavaScript's floats
     * which are based on floating point arithmetic and all the bad stuff
     * that comes with it. Meaning this function doesn't do correct rounding
     * in some cases. For example:
     *
     *      round(1.005, 2) = 1
     *
     * This should of course return 1.01 instead. This is because JavaScript's
     * number format is double-precision floating point.
     *
     * Initially I was going to use math.js, http://mathjs.org/, but at the
     * time of writing (2015.03.26) it was giving off some dependency warnings
     * in Webpack. I searched for some other libraries but they were all either
     * too bulky or not doing what we wanted. However if we find something
     * suitable later on or math.js will be fixed then this method will be
     * obsolete.
     *
     * @param number - the float we want to round, example 1.0051
     * @param decimals - number (>= 0) of decimal places we want to round to,
     * example 2
     * @returns {number} the approximate rounded number, null if validation of
     * input parameter fails or the result of the rounding is NaN
     */
    round: function(number, decimals) {
        var result = null;
        var numberIsValid = is.number(number);
        var decimalsValid = is.integer(decimals) && is.positive(decimals);

        if (numberIsValid && decimalsValid) {
            var roundedResult = parseFloat(number.toFixed(decimals));
            // There is a chance that the rounded result will be NaN, in that
            // case we want to return null instead of NaN, hence this extra if
            if (is.number(roundedResult)) {
                result = roundedResult;
            }
        }

        return result;
    },

    /**
     * Receives a number, returns null if it's 0, returns the same number back
     * otherwise.
     *
     * Convenience function for when we are working with graphs in Highcharts.
     * For example if we'd have a weight of 0 (a fish can't be 0g) it means we
     * don't have any data. Highchart still displays this rightfully a valid
     * number. However for some series we want to create a gap in the series
     * when we have zeros.
     *
     * @param number any type of number
     * @returns null if the number is 0 (zero), otherwise it returns the number
     */
    getNullIfZero: function(number) {
        return number === 0 ? null : number;
    },

    /**
     * Returns an array of two date formatted strings separated by specified
     * number of days in between, starting from the day today. Format is
     * "YYYY-MM-DD".
     *
     * For example if we wanted a date range from today and yesterday we could
     * call this function with daysInThePast as 1 and we'd get back:
     *
     *      ["2015-03-30", "2015-03-31]
     *
     * assuming the day the function was called was 2015-03-31. This function
     * exists because in creating the various graphs I had to create default
     * dates over and over again.
     *
     * @param daysInThePast number - numbers of days to go back into the past
     * @returns array - Example: ["2015-01-04", "2015-02-03"]
     */
    lastXDays: function(daysInThePast) {
        var today = moment().format("YYYY-MM-DD");
        var xDaysAgo = moment().subtract(daysInThePast, "days").format("YYYY-MM-DD");
        return [xDaysAgo, today];
    },

    /**
     * Receives number of hours (integer) and returns a human readable text from
     * that number.
     *
     * Examples:
     *      hours = 25, addAgo = false  -> "1 day"
     *      hours = 48, addAgo = true   -> "2 days ago"
     *      hours = 24, addAgo = true   -> "1 day ago"
     *
     * Note: This method is thought of more as a temporary convenience function.
     * When this was written the only reliable measure of time given by VakiAPI
     * was whole hours (for Dashboard information for example). That will hopefully
     * be fixed at a later date (so we can just work with a datetime object instead).
     * Ideally I'd not like to write code like this myself, IMO it should belong
     * in a well tested 3rd party library instead. But it'll do for now.
     *
     * Another issue with this is it's hard coded for English. Maybe it can be
     * adjusted to fit other languages, by for example accepting an additional
     * parameter.
     *
     * @param hours integer number, how many hours we are working with
     * @param addAgo boolean, indicates if we wish our string to end with " ago"
     * or not. This is used if we want to indicate that the time happened in the
     * past.
     * @returns string, human readable string
     */
    timeFormatFromHours: function(hours, addAgo) {
        var agoPostfix = addAgo ? " ago" : "";

        if (hours == null)
            return "Undefined";
        // This is technically not supposed to happen but for now we'll just
        // pass the value on. This would be an opportunity to log an error
        // once (if) we get around to doing such things. There is one case where
        // this can happen and we may legitimately want to show this, that is when
        // computers on farms have an incorrect clock. In such cases the times
        // may be "from the future". We'll want to know about that and correct.
        if (hours < 0) {
            return hours +" hours"+ agoPostfix;
        }

        // Counted in hours
        if (hours >= 0 && hours <= 23) {
            if (hours === 0 && addAgo) {
                return "less than 1 hour ago";
            }

            // A special case for display inside a population in the Dashboard
            // "less than 1 hour ago" is too long to fit.
            if (hours === 0 && !addAgo) {
                return "<1 hour";
            }

            if (hours === 1) {
                return "1 hour"+ agoPostfix;
            }

            if (hours > 1) {
                return hours +" hours"+ agoPostfix;
            }
        }

        // Counted in days
        if (hours > 23 && hours <= 168) {
            var days = Math.floor(hours/24);

            if (days === 1) {
                return "1 day"+ agoPostfix;
            }

            return days +" days"+ agoPostfix;
        }

        // Counted in weeks
        if (hours > 168 && hours <= 672) {
            var weeks = Math.floor(hours/168);

            if (weeks === 1) {
                return "1 week"+ agoPostfix;
            }

            return weeks +" weeks"+ agoPostfix;
        }

        // Counted in months
        if (hours > 672) {
            var months = Math.floor(hours/672);

            if (months === 1) {
                return "1 month"+ agoPostfix;
            }

            return months +" months"+ agoPostfix;
        }
    },

    /**
     * Truncates an email address to a given max length. If the email is
     * shorter than the specified max length nothing is done and the same
     * email is returned.
     *
     * Example:
     *  maxLength = 10
     *  emailStr = "a_very_very_long_email_address@vaki.is"
     *  result = "a_very_ver.."
     *
     * Example:
     *  maxLength = 50
     *  emailStr = "a_very_very_long_email_address@vaki.is"
     *  result = "a_very_very_long_email_address@vaki.is"
     *
     * @param emailStr {string} For example "a_very_very_long_email_address@vaki.is"
     * @param maxLength {number} For example 10
     */
    shortenEmailIfNeeded: function(emailStr, maxLength) {
        if (emailStr.length > maxLength) {
            return emailStr.substring(0, maxLength) + "..";
        }

        return emailStr;
    },

    /**
     * Receives a raw frame type string and a translation object and returns
     * the correct translation.
     *
     * Note: Currently there are 3 frame type values in the database, "Small",
     * "Large", and "Unknown". On older frames we sometimes can't tell the
     * frame type because serial numbers came later. "Small" really means
     * standard size. Why it's called small is beyond me. This complicates
     * things a bit since at the time of writing there is a new type of frame
     * in development, and you guessed it, a small one! It's targeted at
     * hatcheries. It wasn't totally clear what the naming scheme should be
     * but Björg said that "Small", "Standard", "Large" would be cool for now.
     *
     * @param frameType {string} The raw string value from the API/Database.
     * When this was written there were three possible values: "Small" (normal
     * size), "Large", and "Unknown".
     * @param t i18next translation object
     */
     /* name defined on server side from now 30/9 2015
    getTranslatedFrameType: function(frameType, t) {
        // This is the weird exception detailed in the comments above. Note
        // that if the database or API will be updated to better reflect the
        // sizes, this has to be updated as well
        if (frameType === "Small") {
            return t("dashboard.frameTypeStandard");
        }

        if (frameType === "Large") {
            return t("dashboard.frameTypeLarge");
        }

        if (frameType === "Unknown") {
            return t("dashboard.frameTypeUnknown");
        }
    }
   */
};

module.exports = Vutils;