/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

import React from "react";
import  {Row, Col} from "react-bootstrap";
import Actions from "./../../actions/Actions.js";
import OrganisationPicker from "./OrganisationPicker.jsx";
import FarmPicker from "./FarmPicker.jsx";
import PopulationPicker from "./PopulationPicker.jsx";
import UserDataStore from "./../../stores/UserDataStore.js";
import Spinner from "./../../utils/Spinner.jsx";
import Tooltip from 'rc-tooltip';
var t  = require("./../../stores/TranslateStore.js").getTranslate();
import FilterStore from "./../../stores/FilterStore";
import PropTypes from "prop-types";
require ('rc-tooltip/assets/bootstrap.css');
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
require ('./OrgFarmPopPicker.css');

export default class  OrgFarmPopPicker extends React.Component{
//var OrgFarmPopPicker = export default class   extends React.Component{
    static propTypes = {
        filter: PropTypes.object.isRequired,
        popSelectFunc: PropTypes.func,
        hidePopulationPicker: PropTypes.bool,
        hideFarmPicker: PropTypes.bool
    };


constructor(props) {

   
    super(props);
   this.state = {
       //loading: true

       loading: !UserDataStore.isUserLoaded()
    };

    this.onUserDataStoreChange = (userData, isLoading) => {
    
        if (isLoading){
            this.setState ({loading: true});
        }
    };
   



  };






componentDidMount () {
    this.unsubscribeUserDataStore = UserDataStore.listen(this.onUserDataStoreChange);

};

componentWillUnmount() {
    this.unsubscribeUserDataStore();
};



    
    render () {

    
        var buttonFace = t("orgfp.hdrAll");




        if (this.state.loading){
            buttonFace =<div className="SpinnerButton">
        <Spinner />
                </div>;
            this.state.loading = false;

        }else if (!UserDataStore.showInactive())
            buttonFace = t("orgfp.hdrActive");


        var theTip = t("orgfp.tip");





        var farmPicker = "";
        var popPicker = "";


        if (!this.props.hideFarmPicker)
            farmPicker = <FarmPicker
                selectedFarm={this.props.filter.selectedFarm}
                farms={this.props.filter.farms} />;

        if (!this.props.hidePopulationPicker && !this.props.hideFarmPicker)
            popPicker = <PopulationPicker
                            selectedPopulation={this.props.filter.selectedPopulation}
                            populations={this.props.filter.populations}
                            popSelectFunc= {this.props.popSelectFunc}
                            />;



        return (
            <div >
        

<div  className="ofpp-wrap" >
<Row>
<Col>
<div className="ofpp-row1">
    <Tooltip placement="bottom" trigger={['hover']} multiline={true} mouseEnterDelay={0.6} overlayStyle={{opacity: 0.9, padding: 10}}
overlay={<div style={{padding: 4}}>{theTip}</div>}
>
    <Button size="xs" block = "true" variant="outline-dark" className="ofpp-toggle"
                                        onClick={function () { Actions.reloadUserData (false) }}   >
{buttonFace}
</Button>
    </Tooltip>

</div>
</Col>
</Row>
<Row>
<Col>


    <div className="ofpp-row2">
<ButtonGroup className="org-farm-pick"   >
                <OrganisationPicker
                    selectedOrganisation={this.props.filter.selectedOrganisation}
                    organisations={this.props.filter.organisations} />
                {farmPicker}
                {popPicker}
                </ButtonGroup>
   </div>
   </Col>
   </Row>
               </div> 

</div>


        );



    }
};




/*

  return (
            <div >
                <table >
                <tbody>
                <tr >
                <td className="ofpPickHdr">


    <Tooltip placement="bottom" trigger={['hover']} multiline={true} mouseEnterDelay={0.6} overlayStyle={{opacity: 0.9, padding: 10}}
overlay={<div style={{padding: 4}}>{theTip}</div>}
>
    <Button size="xs" block = "true" variant="outline-dark" 
                                        onClick={function () { Actions.reloadUserData (false) }}   >
{buttonFace}
</Button>
    </Tooltip>





    </td>
    </tr>
    <tr>
    <td>
<ButtonGroup className="org-farm-pick"  >
                <OrganisationPicker
                    selectedOrganisation={this.props.filter.selectedOrganisation}
                    organisations={this.props.filter.organisations} />
                {farmPicker}
                {popPicker}
                </ButtonGroup>
    </td>
    </tr>
    </tbody>
    </table>
</div>


        );

*/
