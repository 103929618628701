
"use strict";
var Reflux = require("reflux");
var Actions = require("./../../actions/Actions.js");
var React = require("react");
//import InputGroup from "react-bootstrap/InputGroup";
//import FormControl from "react-bootstrap/FormControl";
import {InputGroup, FormControl, Button, Form} from "react-bootstrap";
var ReactDOM = require("react-dom");
var moment = require("moment");
var Vutils = require("./../../utils/Vutil.js");
var VakiReactPropTypes = require("./../../utils/VakiReactPropTypes.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var UserDataStore = require("./../../stores/UserDataStore.js");
var VConst = require("./../../utils/VConst.js");
var t = TranslateStore.getTranslate();
var FilterStore = require("./../../stores/FilterStore.js");

require("./DashComments.css");

var _isMounted;
var DashCommentsStore = Reflux.createStore({
    init () {
 
        this.listenTo(Actions.dashCommentsShow, this.onShowComments);
        this.listenTo(Actions.dashCommentSubmitClick, this.onSubmitClick);

        this.Comments = {
            list: null,
            pop: "",
            show: false,
            popID:0,
            theText:""

        };
        // this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        // this.fetchWeightAndTimeGraph();
    },


    onShowComments (show, str, pop, popID) {
      
        //(x, y, comments, farm, pop) {
        this.Comments.list = str;
        this.Comments.pop = pop;
        this.Comments.show = show;
        this.Comments.popID = popID;
        this.trigger(this.Comments);

    },

    onSubmitClick (txt) {


/*
        var inp = document.getElementById("theInput");


        var theText = inp.value.trim();
  */
        var theText = txt;      



        var str = moment().format("YYYY-MM-DD");
        var pop = 0;
        

        if (this.Comments.popID !== null)
            pop = this.Comments.popID;

     
        if (theText !== null && theText !== "") {
            var newComment = {
                FarmID: FilterStore.getSelectedFarmID(),
                PopulationID: pop,
                PertinentDateTime: str,
                CommentText: theText
            };

            Actions.addComment(newComment);
        }

      //  e.stopPropagation();
    }

});


export default class DashComments  extends React.Component{

    constructor(props) {
     super(props);

        this.state = {
            buttonDisabled: true,
            show: false,
            comments: "",
            pop: "",
            theText:""
         
   
        };

        _isMounted = false;
    this.onResize = () => {

    if (_isMounted)
        this.setState({});
    };

    this. onChange = (theData) => {

        if (_isMounted)
            this.setState({
                show: theData.show,
                comments: theData.list,
                pop: theData.pop
            });

    };
    

    this.onEdit = (tt)  => {

        var str = tt.target.value.trim();
      
       

    //    this.state.buttonDisabled = true;
        if (str.length > 0)
            this.state.buttonDisabled = false;

         this.setState({theText: str})
       // this.setState({theText: str});

    };



this.onButtonClick = ()  => {
Actions.dashCommentSubmitClick (this.state.theText)
 
         this.state.buttonDisabled = true;
   

    };

    };





    componentDidMount () {
        _isMounted = true;
        //var domNode = ReactDOM.findDOMNode(this);
        this.unsubscribeDashComments = DashCommentsStore.listen(this.onChange);
        //   createDashboardCanvas(domNode, this.props.populationStatusList);

        window.addEventListener("resize", this.onResize);
       // this.setState({});
    };


    componentWillUnmount () {
        _isMounted = false;
        this.unsubscribeDashComments();
    };


    render () {


        var CommentsHeader = "";
        var TheComments = "";

        var isDisabled = true;

        var fs = 14;
        var el = document.getElementById("divCommentsOverlay");
        if (el) {
            fs = parseInt( window.getComputedStyle(document.body).fontSize) ;

            var numEms = window.innerWidth / fs;
            var pad = 4;
            if (numEms > 45) {
                pad = ((numEms - 40) / 2)*100 / numEms;
            }



            el.style.paddingLeft = pad+"%";
            el.style.paddingRight = pad+"%";
            var elC = document.getElementById("divCommentsContent");
            elC.style.width = (100 - 2 * pad) + "%";

            



        }

        if (this.state.show) {
            if (!this.state.comments)
                this.state.comments = [];

            if (!this.state.pop)
                this.state.pop = "";

            if (this.state.comments.length > 0) {
          

                var hdr = document.getElementById("divListHeader");
                if (this.state.pop !== "")
                    hdr.innerHTML =  "Comments written for population <b>" + this.state.pop + "</b>:<hr/>"; 
                else 
                hdr.innerHTML = "Comments written on the farm:<hr/>"; 

                hdr.style.fontSize = fs * 1.2 + "px";

                document.getElementById("divListFooter").innerHTML= "<b><hr/></b>"

                for (var i = 0; i < this.state.comments.length; i++) {

                    if (i > 0)
                        TheComments += "<br/>";
                    TheComments += "<b>" + this.state.comments[i].Username + "</b> (" + moment(this.state.comments[i].Pertinent_Datetime).format("DD/MM") + "): " + this.state.comments[i].Text;
                }

                
            }
            if (el)
                el.style.display = 'block';


            var elDC = document.getElementById("divComments");
            elDC.style.maxHeight = window.innerHeight/3 + "px";
            elDC.style.overflowY = "scroll";

            elDC.innerHTML = TheComments;


        } else {
            if (el) {
                el.style.display = 'none';
                document.getElementById("theInput").value = "";
            }

        }
   

/*

                        <RB.Input
                            
                            id = "theInput"
                            type="text"
                            label={"Add Comment:"}
                            onClick={function (e) {
                                e.stopPropagation();
                            }}

                       
                            placeholder={"Write new comment here..."}
                            onChange={this.onEdit}  />

*/

/*
         value = {this.state.theText}
                                     onChange={this.onEdit}
*/

        return (
            <div id="divCommentsOverlay" 
                onClick={function () { Actions.dashCommentsShow(false); }}
                 >
                
                <div id="divCommentsContent" className= "clsCommentsContent">
                    <div id="divListHeader"></div>
                  

                   
                   
                   
                    <div id="divComments"></div>
                    <div id="divListFooter"></div>
                    
                    <div >
                       <br/>


 <InputGroup className="mb-3"   onClick={function (e) { e.stopPropagation();}}
                                   >
                                <InputGroup.Text>Comment</InputGroup.Text>
                                <Form.Control
                                    ref="Comment"
                                    type="text"
                                    placeholder="Write new comment here..."
                                    aria-label="Comment"
                                     id="theInput"
                             onChange={this.onEdit}
                             type="text"
                                     


                                />
                            </InputGroup>


    




                        <div className="clsSubmit">
                            <Button
                                id="theButton"
                            type="button"
                            width="20%"

                            disabled={this.state.buttonDisabled}
                            onClick={ this.onButtonClick }

                            variant="outline-dark"
   
                                    

                            
                        >
                            Submit
                        </Button>
                        </div>
                    </div>

               </div>



            </div>

        )
    }
};

