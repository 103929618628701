/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is
 */

"use strict";

var React = require("react");

import NumericInput from "./NumericInput.jsx";
import PropTypes from "prop-types";

export default class PopulationSizeFilter  extends React.Component{
    static propTypes = {
        populationSize: PropTypes.number.isRequired,
        actionFunction: PropTypes.func
    };

    /**
     * I set the maxValue to be pretty high, in fact unrealistic high. There
     * is for example law in Norway that says a population in a sea cage can
     * not exceed 200.000 fishes (if I recall correctly). In practice this
     * number typically does not exceed 150.000. I left the max high just in
     * case.
     */
    render () {
        return (
            <NumericInput
                value={this.props.populationSize}
                label={this.props.t("filters.popSize")}
                minValue={0}
                maxValue={100000000}
                actionFunction={this.props.actionFunction} 
                allowempty = {true}/>
        )
    }
};

