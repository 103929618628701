/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 *
 *
 */

 "use strict";

import React  from "react";
import {Alert, Row, Col} from "react-bootstrap";
import FilterStore  from "../../stores/FilterStore";
import SessionStore  from "./../../stores/SessionStore.js";
import WeightAndTimeStore  from "./../../stores/WeightAndTimeStore.js";
import WeightAndTimeChart  from "./WeightAndTimeChart.jsx";
import OrgFarmPopPicker  from "../Filters/OrgFarmPopPicker.jsx";
import MinWeightFilter  from "./../Filters/MinWeightFilter.jsx";
import MaxWeightFilter  from "./../Filters/MaxWeightFilter.jsx";
import NumericInput  from "./../Filters/NumericInput.jsx";
import StartEndDatePicker  from "./../Filters/StartEndDatePicker.jsx";
import Actions  from "./../../actions/Actions.js";
import moment  from "moment";
import Spinner  from "./../../utils/Spinner.jsx";
import TranslateStore  from "./../../stores/TranslateStore.js";
import SavedFiltersIndicator  from "./../Filters/SavedFiltersIndicator.jsx";
import BlinkPop  from "./../../utils/BlinkPop/BlinkPop.jsx";
import UserDataStore  from "./../../stores/UserDataStore.js";
import VConst  from "./../../utils/VConst.js";
import Curtain  from "./../../utils/Curtain.jsx";

var t = TranslateStore.getTranslate();




export default class WeightAndTime  extends React.Component{
	constructor(props) {
     super(props);
        this.state = {
            filter: FilterStore.getFilter(),
            chartFilter: WeightAndTimeStore.getFilter(),
            chartData: null, //WeightAndTimeStore.getWatData(),
            loading: false,
            autoDates: false
        };

    /***************************componentWillMount ( )****************************** */
    
        if (SessionStore.isLoggedOut()) {
            Actions.redirectToLogin();
        }
        WeightAndTimeStore.notifyMounting();
     /************************************************************************* */


    this.onWeightAndTimeStoreChange = ( newData, loadingMsg) => {
        //if (loadingMsg) return false;
        if (!newData || newData == "")
            this.setState({
                loading: loadingMsg,
                chartData: null
            })
        else
             this.setState({
                chartFilter: newData.filter,
                chartData: newData.weightAndTimeData,
                loading: loadingMsg,
                autoDates: newData.autoDates
            });
    };



    };//Constructor





    componentDidMount () {

        UserDataStore.postPageHit(VConst.PAGEID.WEIGHTANDTIME);

        this.unsubscribeWeightAndTimeStore = WeightAndTimeStore.listen(this.onWeightAndTimeStoreChange);
        this.setState({loading: true });

 
    };
    componentWillUnmount ( ) {
        this.unsubscribeWeightAndTimeStore();
    };
 

        
    render () {




         var chartArea = "";
         if (this.state.chartData === undefined || this.state.chartData === null) {
             if (!this.state.loading)
                chartArea = <Alert  variant="warning">{t("trendgraph.popHasNo")}</Alert>;
            
        } else {
            var chMsg = "";
            if (this.state.autoDates)
                chMsg = <Alert  variant="info">Dates adjusted to the last day of measurements</Alert>;
                //chMsg = "Dates adjusted to the last day of measurements";
            chartArea = <div><WeightAndTimeChart chartData={this.state.chartData} />

                    <div className="clsWTChartMsg" >{chMsg}</div>
                
              
            </div>;
         }



        var theSpinner = <div></div>;
        if (this.state.loading)
            theSpinner = <Spinner />






		return (
			<div>
   <Row>
                    <Col className="mb-2">
                        
                            <OrgFarmPopPicker filter={this.state.filter} />
                          <br/>
                </Col>
                    <Col className="mb-2">




                    <MinWeightFilter
                        initValue={this.state.chartFilter.minWeight}
                        maxValue={this.state.chartFilter.maxWeight}
                        allowempty 
                        actionFunction={Actions.minWeightUpdate} t={t} />


               <BlinkPop ID="WTMaxWgt2" User={UserDataStore.getUserName()} Text="The Min/Max weight values are here only to compare it to the W&T chart - not filtering of data.  If left empty, server will provide values to show ">

                    <MaxWeightFilter
                                initValue={this.state.chartFilter.maxWeight}
                        minValue={this.state.chartFilter.minWeight}
                        allowempty 
                        actionFunction={Actions.maxWeightUpdate} t={t} />

                        </BlinkPop>




                </Col>

                    <Col className="mb-2"  >
                    <StartEndDatePicker
                            startDate={this.state.chartFilter.startDate}
                            startDateActionFn={Actions.watStartDateUpdate}
                            endDate={this.state.chartFilter.endDate}
                            endDateActionFn={Actions.watEndDateUpdate}
                            dateChangeActionFn={Actions.watDateChange}
                        />
                    <div className = "text-right">
                            <SavedFiltersIndicator />
                            </div>
                </Col>




                </Row>
                  {theSpinner}
                  {chartArea}
     
              
				
			</div>
		);
	}
};

/*
                <Row>
                    <Col md={5} sm={12}>
                        
                            <OrgFarmPopPicker filter={this.state.filter} />
                          <br/>
                </Col>
                    <Col md={6} sm={5} xs={12}>




                    <MinWeightFilter
                        initValue={this.state.chartFilter.minWeight}
                        maxValue={this.state.chartFilter.maxWeight}
                        allowEmpty = {true}
                        actionFunction={Actions.minWeightUpdate} t={t} />


               <BlinkPop ID="WTMaxWgt2" User={UserDataStore.getUserName()} Text="The Min/Max weight values are here only to compare it to the W&T chart - not filtering of data.  If left empty, server will provide values to show ">

                    <MaxWeightFilter
                                initValue={this.state.chartFilter.maxWeight}
                        minValue={this.state.chartFilter.minWeight}
                        allowEmpty = {true}
                        actionFunction={Actions.maxWeightUpdate} t={t} />

                        </BlinkPop>




                </Col>

                    <Col sm={7} md={6} xs={12}  >
                    <StartEndDatePicker
                            startDate={this.state.chartFilter.startDate}
                            startDateActionFn={Actions.watStartDateUpdate}
                            endDate={this.state.chartFilter.endDate}
                            endDateActionFn={Actions.watEndDateUpdate}
                            dateChangeActionFn={Actions.watDateChange}
                        />
                    <div className = "text-right">
                            <SavedFiltersIndicator />
                            </div>
                </Col>




                </Row>
                  {theSpinner}
                  {chartArea}
*/