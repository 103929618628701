/**
 * Created by Bjarni on 9.9.2015.
 */


var textFile = null;

var BGTools = {


    readCookie:   function (name) {


        var vv  = window.localStorage.getItem(name);
         return vv;

    /*
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
   */
},

    createCookie: function (name, value) {
        window.localStorage.setItem(name, value );

   /*
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
    */
},



    readBoolStorage: function (cname, defaultVal)
{
    var ret = defaultVal;



    var vv = window.sessionStorage.getItem(cname);

    if (vv==null)
        return ret;


            switch (vv.toString().toLowerCase().trim()) {
                case "true":
                case "yes":
                case "1":
                    return true;
                case "false":
                case "no":
                case "0":
                case null:
                    return false;
                default:
                    return ret;
            }



},

    formatThousandSep: function (num)
{
    if (num==0)
        return "0";

    if (num == null || num=="" || num === undefined)
        return "";


    seperator = ' '; //We'll decide later if take the seperator from browsers locale, preferences or something else
    var n = num.toString(), p = n.indexOf('.');
    return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, function($0, i){
        return p<0 || i<p ? ($0+seperator) : $0;
    });
},

    parseJsonDate:  function (jsonDateString){
    if (typeof jsonDateString === "string")
        return new Date(parseInt(jsonDateString.replace('/Date(', '')));

    return   jsonDateString;
},




isHCTextButton: function (str){

    var found = false;
    var toDelIdx = -1;
    var len = Highcharts.getOptions().exporting.buttons.contextButton.menuItems.length;


    for (var i = 0; i<len; i++){
        var bb = Highcharts.getOptions().exporting.buttons.contextButton.menuItems[i];
	
        if (bb.id && bb.id.indexOf("textFile") >= 0){

            if (bb.id.indexOf(str) >= 0)
                found = true;
            else
                toDelIdx=i;
            break;
        }
    }

	
    if (toDelIdx>=0){
        Highcharts.getOptions().exporting.buttons.contextButton.menuItems.splice(toDelIdx,1);

	}


    return found;


},




clearMyHiChartsButtons: function (){
	
	
	var len = Highcharts.getOptions().exporting.buttons.contextButton.menuItems.length;
	
	if (len > 6)
		Highcharts.getOptions().exporting.buttons.contextButton.menuItems.splice(6,len-6);
	
	
	
}



}

module.exports = BGTools;