/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("../actions/Actions.js");
var VakiAPI = require("./../webservice/VakiAPI.js");

var _adminViewFilter = { };
var _userDimensions = [];
var _countryDimensions = [];
var _userList = [];
var _orgList = [];
var _farmList = [];
var _compList = [];
var showDeletedUsers = false;
var showDeletedOrganisations = false;
var showDeletedFarms = false;
var showDeletedComputers = false;
var showInactiveFarms = false;
var showInactiveComputers = false;
var showOnlyAdminUsers = false;

var AdminViewStore = Reflux.createStore({
    init: function() {
        this.listenTo(Actions.adminViewUsersClick, this.onAdminViewUsersClick);
        this.listenTo(Actions.adminViewOrgClick, this.onAdminViewOrgClick);
        this.listenTo(Actions.adminViewFarmsClick, this.onAdminViewFarmsClick);
        this.listenTo(Actions.adminViewCompsClick, this.onAdminViewCompsClick);
        this.listenTo(Actions.userListRowDelete, this.onUserListRowDelete);
        this.listenTo(Actions.orgListRowDelete, this.onOrgListRowDelete);
        this.listenTo(Actions.farmListRowDelete, this.onFarmListRowDelete);
        this.listenTo(Actions.compListRowDelete, this.onComputerListRowDelete);
        this.listenTo(Actions.userListRowRestore, this.onUserListRowRestore);
        this.listenTo(Actions.orgListRowRestore, this.onOrgListRowRestore);
        this.listenTo(Actions.farmListRowRestore, this.onFarmListRowRestore);
        this.listenTo(Actions.compListRowRestore, this.onComputerListRowRestore);
        this.listenTo(Actions.newUserCreated, this.onUserPost);
        this.listenTo(Actions.newOrganisationCreated, this.onOrganisationPost);
        this.listenTo(Actions.newFarmCreated, this.onFarmPost);
        this.listenTo(Actions.newCompCreated, this.onComputerPost);
        this.listenTo(Actions.apiNewUserDimensionData, this.onNewUserDimensionData);
        this.listenTo(Actions.apiNewCountryDimensionData, this.onNewCountryDimensionData);
        this.listenTo(Actions.apiNewUserListData, this.onNewUserListData);
        this.listenTo(Actions.apiNewOrgListData, this.onNewOrgListData);
        this.listenTo(Actions.apiNewFarmListData, this.onNewFarmListData);
        this.listenTo(Actions.apiNewCompListData, this.onNewCompListData);
        this.listenTo(Actions.apiObjectSaved, this.onObjectSaved);
        this.listenTo(Actions.showDeletedUsers, this.onShowDeletedUsers);
        this.listenTo(Actions.showDeletedOrganisations, this.onShowDeletedOrganisations);
        this.listenTo(Actions.showDeletedFarms, this.onShowDeletedFarms);
        this.listenTo(Actions.showDeletedComputers, this.onShowDeletedComputers);
        this.listenTo(Actions.showInactiveFarms, this.onShowInactiveFarms);
        this.listenTo(Actions.showInactiveComputers, this.onShowInactiveComputers);
        this.listenTo(Actions.showOnlyAdminUsers, this.onShowOnlyAdminUsers);

        _adminViewFilter = {
            users: [],
            organisations: [],
            farms: [],
            computers: [],
            roles: [],
            countries: [],
            systems: [],
            route: "Users"
        };
    },

    


    /* Arranges the lists returned from the API into a new list that is useable by the view
    *  This is necessary because the API only returns primitive connections between data types and 
    *  we need more info such as names to display in the UI.
     */
    getAllUsers: function() {
        var clientUserList = [];
        /* Fetching data from dimensions list for each user */
        for(var i = 0; i < _userList.length; ++i) {
            /* If show only admin users is checked and the user is not an admin we do not show him */
            if(showOnlyAdminUsers && _userList[i].RoleList.indexOf(4) === -1) continue;
            /* If show deleted is unchecked and the user in not active we do not show him */
            if(!showDeletedUsers && !_userList[i].IsActive) continue;
            
            var user = {
                id: _userList[i].UserID,
                firstName: _userList[i].FirstName,
                lastName: _userList[i].LastName,
                username: _userList[i].UserName,
                mobile: _userList[i].MobileNumber,
                email: _userList[i].EmailAddress,
                jobTitle: _userList[i].JobTitle,
                password: _userList[i].Password,
                userTypeID: _userList[i].UserTypeID,
                isActive: _userList[i].IsActive,
                roles: [],
                organisations: [],
                farms: []
            };
            /* Fetching roles for this user */
            for(var j = 0; j < _userList[i].RoleList.length; ++j) {
                var role = $.grep(_userDimensions.RoleList, function(e){ return e.RoleID === _userList[i].RoleList[j] });
                if(role[0] === undefined) continue;
                user.roles.push({
                    id: role[0].RoleID,
                    name: role[0].Name,
                    description: role[0].Description
                });
            }
            /* Fetching organisations for this user */
            for(var j = 0; j < _userList[i].OrganisationList.length; ++j) {
                var org = $.grep(_userDimensions.OrganisationList, function(e){ return e.OrganisationID === _userList[i].OrganisationList[j]; });
                if(org[0] === undefined) continue;
                var userListOrg = {
                    id: org[0].OrganisationID,
                    name: org[0].Name,
                    farmList: []
                };
                user.organisations.push(userListOrg);

                /* Fetching farms for this organisation */
                for(var k = 0; k < org[0].FarmList.length; ++k) {
                    var farm = {
                        id: org[0].FarmList[k].FarmID,
                        name: org[0].FarmList[k].Name
                    }
                    user.farms.push(farm);
                    userListOrg.farmList.push(farm);
                }
            }

            for(var j = 0; j < _userList[i].FarmList.length; ++j) {
                for(var k = 0; k < _userDimensions.OrganisationList.length; ++k) {
                    var farm = $.grep(_userDimensions.OrganisationList[k].FarmList, function(e){ return e.FarmID === _userList[i].FarmList[j]; });
                    if(farm.length !== 0) {
                        var userListFarm = {
                            id: farm[0].FarmID,
                            name: farm[0].Name
                        }
                        user.farms.push(userListFarm);
                    }
                    
                }
            }
            clientUserList.push(user);
        }
        return clientUserList;
    },
    getAllOrganisations: function() {
        var organisations = [];
        for(var i = 0; i < _orgList.length; ++i) {
            if(!showDeletedOrganisations && !_orgList[i].IsActive) continue;
            var date = new moment(_orgList[i].CreatedDate);
            /* If the year is set for 01, we change the variable to a message stating that date created is unknown */
            if(date.year() === 1) date = "Date created is unknown";
            else date = date.format("MMM Do YYYY");
            
            var org = {
                id: _orgList[i].OrganisationId,
                name: _orgList[i].Name,
                email: _orgList[i].EmailAddress,
                phone: _orgList[i].PhoneNumber,
                address: _orgList[i].StreetAddress,
                dateCreated: date,
                isActive: _orgList[i].IsActive,
                userList: [],
                farmList: []
            }
            /* Adding all farms that belong to this organisation */
            for(var j = 0; j < _orgList[i].FarmList.length; ++j) {
                var farm = {
                    id: _orgList[i].FarmList[j].FarmID,
                    name: _orgList[i].FarmList[j].Name
                }
                org.farmList.push(farm);
            }

            /* Adding all users that have access to this organisation */
            for(var k = 0; k < _orgList[i].UserList.length; ++k) {
                var user = {
                    id: _orgList[i].UserList[k].UserId,
                    name: _orgList[i].UserList[k].Name,
                    username: _orgList[i].UserList[k].UserName
                }
                org.userList.push(user);
            }
            organisations.push(org);
        }

        organisations.sort(this.compare);
        return organisations;
    },
    getAllFarms: function() {
        var farms = [];



        for (var i = 0; i < _farmList.length; ++i) {

         
            /* If show deleted is unchecked and the farm is not active we do not show it */
            if (!showDeletedFarms && !_farmList[i].IsActive) continue;
            if(!showInactiveFarms && _farmList[i].VisibleSystemList === null && _farmList[i].IsActive) continue;

            var date = new moment().format("MMM Do YYYY");
            var country = $.grep(_countryDimensions, function(e){ return e.CountryId === _farmList[i].CountryId; });
            if(country === undefined) country = [];
            var org = $.grep(_orgList, function(e){ return e.OrganisationId === _farmList[i].OrganisationId });
            if(org === undefined) org = [];
            var farmList = [];
            var userList = [];
            if(org[0].FarmList !== undefined) {
                for(var j = 0; j < org[0].FarmList.length; ++j) {
                    farmList.push({
                        id: org[0].FarmList[j].FarmID,
                        name: org[0].FarmList[j].Name
                      
                    });
                }
            }

            if(org[0].UserList !== undefined) {
                for(var k = 0; k < org[0].UserList.length; ++k) {
                    userList.push({
                        id: org[0].UserList[k].UserId,
                        name: org[0].UserList[k].Name,
                        username: org[0].UserList[k].UserName
                    });
                }
            }

            var organisation = {
                id: org[0].OrganisationId,
                name: org[0].Name,
                farmList: farmList,
                userList: userList
            }

            var farm = {
                id: _farmList[i].FarmId,
                name: _farmList[i].Name,
                nameWithOrg: (_farmList[i].Name + " - " + organisation.name).trim(),
                countryId: _farmList[i].CountryId,
                email: _farmList[i].EmailAddress,
                latitude: _farmList[i].Lat,
                longitude: _farmList[i].Lon,
                alternativeId: _farmList[i].AlternativeId,
                organisation: organisation,
                phone: _farmList[i].PhoneNumber,
                regionId: _farmList[i].RegionId,
                timezoneId: _farmList[i].TimeZoneId,
                vakiSupEmail: _farmList[i].VakiSupervisorEmail,
                dateCreated: date,
                country: country[0],
                isActive: _farmList[i].IsActive,
                userList: [],
                visibleSystemList: _farmList[i].VisibleSystemList || []
            }

            /* Adding all users that have access to this farm */
            for(var j = 0; j < _farmList[i].UserList.length; ++j) {
                var user = {
                    id: _farmList[i].UserList[j].UserId,
                    name: _farmList[i].UserList[j].Name,
                    username: _farmList[i].UserList[j].UserName
                }
                farm.userList.push(user);
            }

            farms.push(farm);
        }



        farms.sort(this.compare);
        return farms;
    },
    getAllComputers: function() {
        var computers = [];

        for(var i = 0; i < _compList.length; ++i) {
            if(!showDeletedComputers && !_compList[i].IsActive) continue;
            if(!showInactiveComputers && _compList[i].ActiveSystemsList === null && _compList[i].IsActive) continue;
            var date = new moment(_compList[i].CreatedDate).format("MMM Do YYYY");
            var farm = $.grep(_farmList, function(e){ return e.FarmId === _compList[i].FarmId; });
            var farmObject = undefined;
            if (farm[0] !== undefined) {



                farmObject = { id: farm[0].FarmId, name: farm[0].Name,nameWithOrg: (farm[0].Name + " - " + farm[0].OrganisationName).trim() , org: farm[0].OrganisationName + " (" + farm[0].OrganisationId+")" };
            }

            /* Fetching active systems linked to this computer */
            var activeSystems = [];
            if(_compList[i].ActiveSystemsList !== null) {
                for(var j = 0; j < _compList[i].ActiveSystemsList.length; ++j) {
                    var system = $.grep(_userDimensions.ActiveSystemTypeList, function(e) { return e.ActiveSystemId === _compList[i].ActiveSystemsList[j].SystemID });
                    if(system.length > 0) {
                        activeSystems.push({
                            id: system[0].ActiveSystemId,
                            name: system[0].Name
                        });
                    }
                }
            }
            computers.push({
                id: _compList[i].ComputerId,
                farm: farmObject,
                name: _compList[i].Name || "",
                macAddress: _compList[i].MacAddress,
                dropboxUsername: _compList[i].DropboxUsername,
                dropboxPassword: "",
                teamViewerId: _compList[i].TeamViewerId,
                teamViewerPassword: _compList[i].TeamViewerPassword,
                createdDate: date,
                activeSystems: activeSystems,
                biomassVersion: _compList[i].BiomassVersion,
                uploadInterval: _compList[i].UploadInterval,
                databaseVersion: _compList[i].DatabaseVersion,
                isActive: _compList[i].IsActive
            });
        }



        return computers;
    },
    getAllRoles: function() {
        var roles = [];
        for(var i = 0; i < _userDimensions.RoleList.length; ++i) {
            roles.push({
                id: _userDimensions.RoleList[i].RoleID,
                name: _userDimensions.RoleList[i].Name,
                description: _userDimensions.RoleList[i].Description
            });
        }
        return roles;
    },
    getAllCountries: function() {
        var countries = [];
        for(var i = 0; i < _countryDimensions.length; ++i) {
            var regionList = [], timezoneList = [];
            for(var j = 0; j < _countryDimensions[i].RegionList.length; ++j) {
                regionList.push({
                    id: _countryDimensions[i].RegionList[j].RegionId,
                    name: _countryDimensions[i].RegionList[j].Name
                });
            }
            for(var k = 0; k < _countryDimensions[i].TimeZoneList.length; ++k) {
                timezoneList.push({
                    id: _countryDimensions[i].TimeZoneList[k].TimeZoneId,
                    name: _countryDimensions[i].TimeZoneList[k].Name
                });
            }
            countries.push({
                id: _countryDimensions[i].CountryId,
                code: _countryDimensions[i].CountryCode,
                name: _countryDimensions[i].Name,
                regionList: regionList,
                timezoneList: timezoneList
            });

        }
        countries.sort(this.compare);
        return countries;
    },
    getAllSystems: function() {
        var systems = [];
        for(var i = 0; i < _userDimensions.ActiveSystemTypeList.length; ++i) {
            systems.push({
                id: _userDimensions.ActiveSystemTypeList[i].ActiveSystemId,
                name: _userDimensions.ActiveSystemTypeList[i].Name
            });
        }
        return systems;
    },
    getFilter: function() {
        return _adminViewFilter;
    },
    onAdminViewUsersClick: function() {

   
        _adminViewFilter.route = "Users";
        this.trigger(_adminViewFilter);
    },
    onAdminViewOrgClick: function() {
        _adminViewFilter.route = "Organisations";
        this.trigger(_adminViewFilter);
    },
    onAdminViewFarmsClick: function() {
        _adminViewFilter.route = "Farms";
        this.trigger(_adminViewFilter);
    },
    onAdminViewCompsClick: function() {
        _adminViewFilter.route = "Computers";
        this.trigger(_adminViewFilter);
    },
    /* In all rights, these functions should belong to a store that handles the view tables */
    onUserListRowDelete: function(user) {
        var index = _adminViewFilter.users.indexOf(user);
        if(index === -1) return;
        user.selectedFarms = user.farms;
        user.selectedOrganisations = user.organisations;
        user.selectedRoles = user.roles;
        this.onUserPost(_adminViewFilter.users[index], true);
    },
    onOrgListRowDelete: function(org) {
        var index = _adminViewFilter.organisations.indexOf(org);
        if(index === -1) return;
        org.selectedUsers = org.userList;
        org.selectedFarms = org.farmList;
        this.onOrganisationPost(_adminViewFilter.organisations[index], true);
    },
    onFarmListRowDelete: function(farm) {
        var index = _adminViewFilter.farms.indexOf(farm);
        if(index === -1) return;
        this.onFarmPost(_adminViewFilter.farms[index], true);
    },
    onComputerListRowDelete: function(computer) {
        var index = _adminViewFilter.computers.indexOf(computer);
        if(index === -1) return;
        computer.selectedFarms = [computer.farm];
        computer.selectedSystems = computer.activeSystems;
        this.onComputerPost(_adminViewFilter.computers[index], true);
    },
    onUserListRowRestore: function(user) {
        var index = _adminViewFilter.users.indexOf(user);
        if(index === -1) return;
        user.selectedFarms = user.farms;
        user.selectedOrganisations = user.organisations;
        user.selectedRoles = user.roles;
        this.onUserPost(_adminViewFilter.users[index], false);
    },
    onOrgListRowRestore: function(org) {
        var index = _adminViewFilter.organisations.indexOf(org);
        if(index === -1) return;
        org.selectedUsers = org.userList;
        org.selectedFarms = org.farmList;
        this.onOrganisationPost(_adminViewFilter.organisations[index], false);
    },
    onFarmListRowRestore: function(farm) {
        var index = _adminViewFilter.farms.indexOf(farm);
        if(index === -1) return;
        this.onFarmPost(_adminViewFilter.farms[index], false);
    },
    onComputerListRowRestore: function(computer) {
        var index = _adminViewFilter.computers.indexOf(computer);
        if(index === -1) return;
        computer.selectedFarms = [computer.farm];
        computer.selectedSystems = computer.activeSystems;
        computer.isActive = true;
        this.onComputerPost(_adminViewFilter.computers[index], false);
    },
    compare: function(a,b) {
        return a.name.localeCompare(b.name);
    },
    /* Functions that are triggered when an entity is created, edited, deleted and restored
    *  Re arrange the object back into the view model accepted by the API and trigger the API function
     */
    onUserPost: function(user, isDelete) {
        var userId = user.id || 0;
        var farmList = [], orgList = [], roleList = [], isActive = true;
        var userFarmList = this.clone(user.selectedFarms);
        if(isDelete) isActive = false;
        for(var i = 0; i < user.selectedOrganisations.length; ++i) {
            orgList.push(user.selectedOrganisations[i].id);
            for(var j = 0; j < user.selectedOrganisations[i].farmList.length; ++j) {
                for(var k = 0; k < userFarmList.length; ++k) {
                    if(user.selectedOrganisations[i].farmList[j].id === userFarmList[k].id) {
                        userFarmList.splice(k, 1);
                        k--;
                        continue;
                    }
                }
            }
        }
        for(var i = 0; i < userFarmList.length; ++i) {
            farmList.push(userFarmList[i].id);
        }
        for(var i = 0; i < user.selectedRoles.length; ++i) {
            roleList.push(user.selectedRoles[i].id);
        }

        var newUser = {
            EmailAddress: user.email,
            FarmList: farmList,
            FirstName: user.firstName,
            IsActive: isActive,
            JobTitle: user.jobTitle,
            LastName: user.lastName,
            MobileNumber: user.mobile,
            OrganisationList: orgList,
            Password: user.password,
            RoleList: roleList,
            UserID: userId,
            UserName: user.username,
            UserTypeID: user.userTypeID
        };
        VakiAPI.postUser(newUser);
    },
    onOrganisationPost: function(org, isDelete) {
        var orgID = org.id || 0;
        var isActive = true;
        if(isDelete) isActive = false;

        var newOrg = {
            EmailAddress: org.email,
            Name: org.name,
            OrganisationId: orgID,
            PhoneNumber: org.phone,
            StreetAddress: org.address,
            IsActive: isActive
        };
        VakiAPI.postOrganisation(newOrg);
    },
    onFarmPost: function(farm, isDelete) {
        var isActive = true;
        if(isDelete) isActive = false;
        var newFarm = {
            AlternativeId: farm.alternativeId,
            CountryId: farm.countryId,
            EmailAddress: farm.email,
            FarmId: farm.id || 0,
            IsActive: isActive,
            Lat: farm.latitude,
            Lon: farm.longitude,
            Name: farm.name,
            OrganisationId: farm.organisation.id || 0,
            OrganisationName: farm.organisation.name || "",
            PhoneNumber: farm.phone,
            RegionId: farm.regionId,
            TimeZoneId: farm.timezoneId,
            VisibleSystemList: farm.visibleSystemList,
            VakiSupervisorEmail: farm.vakiSupEmail
        };
        VakiAPI.postFarm(newFarm);
    },


    onComputerPost: function(computer, isDelete) {



        if (!isDelete && computer.teamViewerId != null && computer.teamViewerId != 0 && computer.teamViewerId != "") {
            for (var i = 0; i < _compList.length; i++) {
                if (_compList[i].IsActive != 0 && _compList[i].TeamViewerId == computer.teamViewerId && _compList[i].ComputerId != computer.id) {
                    alert("ERROR!\n\nAnother computer already registerd with the same teamviewer id (" + computer.teamViewerId + "):  >>" + _compList[i].ComputerId + ": " + _compList[i].Name + "<<");
                    return;
                }
            }
        }


     





        if(isDelete) computer.isActive = false;

        var activeSystemsList = [];
        var farmID = 0;

        /* Setting the farm id */
        if(computer.selectedFarms[0] !== undefined) {
            farmID = computer.selectedFarms[0].id;
        }

        /* Filling the list of systems for this computer */
        for(var i = 0; i < computer.selectedSystems.length; ++i) {
            activeSystemsList.push({
                ComputerID: computer.id,
                FarmID: farmID,
                SystemID: computer.selectedSystems[i].id,
                Version: null
            });
        }

        var newComputer = {
            ActiveSystemsList: activeSystemsList,
            BioMassVersion: computer.biomassVersion,
            ComputerId: computer.id,
            DatabaseVersion: computer.databaseVersion,
            DropboxPassword: "", //computer.dropboxPassword,
            DropboxUsername: "", //computer.dropboxUsername,
            FarmId: farmID,
            IsActive: computer.isActive,
            MacAddress: computer.macAddress,
            Name: computer.name,
            TeamViewerId: computer.teamViewerId,
            TeamViewerPassword: computer.teamViewerPassword,
            UploadInterval: computer.uploadInterval
        }
    



        VakiAPI.postComputer(newComputer);
    },
    /* Necessary helper functions to keep list manipulation from affecting unrelated objects */
    getAllUsersCloned: function() {
        var users = this.clone(_adminViewFilter.users);
        return users;
    },
    getAllOrganisationsCloned: function() {
        var organisations = this.clone(_adminViewFilter.organisations);
        return organisations;
    },
    getAllFarmsCloned: function() {
        var farms = this.clone(_adminViewFilter.farms);
        return farms;
    },
    getAllComputersCloned: function() {
        var computers = this.clone(_adminViewFilter.computers);
        return computers;
    },
    getAllRolesCloned: function() {
        var roles = this.clone(_adminViewFilter.roles);
        return roles;
    },
    getAllCountriesCloned: function() {
        var countries = this.clone(_adminViewFilter.countries);
        return countries;
    },
    getAllSystemsCloned: function() {
        var systems = this.clone(_adminViewFilter.systems);
        return systems;
    },
    clone: function(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    onNewUserDimensionData: function(userDimensions) {
        _userDimensions = userDimensions;
        _adminViewFilter.roles = this.getAllRoles();
        _adminViewFilter.systems = this.getAllSystems();
        VakiAPI.getCountryDimensions();
    },
    onNewCountryDimensionData: function(countryDimensions) {
        _countryDimensions = countryDimensions;
        _adminViewFilter.countries = this.getAllCountries();
        VakiAPI.getUserList();
    },
    onNewUserListData: function(userList) {
        _userList = userList;
        _adminViewFilter.users = this.getAllUsers();
        VakiAPI.getOrganisationList();
    },
    onNewOrgListData: function(orgList) {
        _orgList = orgList;
        _adminViewFilter.organisations = this.getAllOrganisations();
        VakiAPI.getFarmList();
    },
    onNewFarmListData: function(farmList) {
        _farmList = farmList;



        _adminViewFilter.farms = this.getAllFarms();
        VakiAPI.getComputerList();
    },
    onNewCompListData: function(compList) {
        _compList = compList;
        _adminViewFilter.computers = this.getAllComputers();
        Actions.apiAdminViewDataReady();
        this.trigger(_adminViewFilter);
    },
    onObjectSaved: function() {
        VakiAPI.getUserDimensions();
    },
    onShowDeletedUsers: function() {
        showDeletedUsers = !showDeletedUsers;
        _adminViewFilter.users = this.getAllUsers();
        this.trigger(_adminViewFilter);
    },
    onShowDeletedOrganisations: function() {
        showDeletedOrganisations = !showDeletedOrganisations;
        _adminViewFilter.organisations = this.getAllOrganisations();
        this.trigger(_adminViewFilter);
    },
    onShowDeletedFarms: function() {
        showDeletedFarms = !showDeletedFarms;
        _adminViewFilter.farms = this.getAllFarms();
        this.trigger(_adminViewFilter);
    },
    onShowDeletedComputers: function() {
        showDeletedComputers = !showDeletedComputers;
        _adminViewFilter.computers = this.getAllComputers();
        this.trigger(_adminViewFilter);
    },
    onShowInactiveFarms: function() {
        showInactiveFarms = !showInactiveFarms;
        _adminViewFilter.farms = this.getAllFarms();
        this.trigger(_adminViewFilter);
    },
    onShowInactiveComputers: function() {
        showInactiveComputers = !showInactiveComputers;
        _adminViewFilter.computers = this.getAllComputers();
        this.trigger(_adminViewFilter);
    },
    onShowOnlyAdminUsers: function() {
        showOnlyAdminUsers = !showOnlyAdminUsers;
        _adminViewFilter.users = this.getAllUsers();
        this.trigger(_adminViewFilter);
    },
    triggerDataFetch: function() {
        VakiAPI.getUserDimensions();
    },

    isFarmConnectedToComputer: function (FarmID){
        var comps = this.getAllComputers();
        let len = comps.length;

        for (let i = 0; i<len; i++){
           
            if (comps[i].farm){
                comps[i].farm.id
                if (comps[i].farm.id == FarmID){
                    alert("WARNING!!!\n\nSelected farm is already assigned to another computer ("+comps[i].name+")\n\n");
                    return;
                
                }
            }
        }

    }


});

module.exports = AdminViewStore;