/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Jón Rúnar Helgason, jonrh@jonrh.is

 */

"use strict";


var React = require("react");
import {Button} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {Calendar, StartEnd, CaretDown} from 'react-bootstrap-icons';

var moment = require("moment");
var Actions = require("./../../actions/Actions.js");
var VConst = require("./../../utils/VConst.js");
var TranslateStore = require("./../../stores/TranslateStore.js");
var t = TranslateStore.getTranslate();
import PropTypes from "prop-types";
import 'bootstrap-daterangepicker/daterangepicker.css';

//require('react-bootstrap-daterangepicker/css/daterangepicker.css');
/**
 * A filter that offers a start and end date. Useful when we have to select
 * some range of dates. It's mostly a thin wrapper on top of the third party
 * React component react-bootstrap-daterangepicker, which is in turn a port of
 * another library.
 *
 * The component takes in as props startDate and endDate. Those are expected
 * to be moment objects, for docs please see: http://momentjs.com/
 *
 * As well as start and end date the component receives Reflux action
 * functions as parameters. This allows us to reuse this component in many
 * different reports.
 */

export default class StartEndDatePicker  extends React.Component{
    static propTypes = {

        startDate: PropTypes.object.isRequired, // moment object
        startDateActionFn: PropTypes.func,
        endDate: PropTypes.object.isRequired, // moment object
        endDateActionFn: PropTypes.func,
        onAnyChangeActionFn: PropTypes.func

    };




    constructor(props) {
       
     super(props);
        this.state = {
            ranges: {
                "Today": [moment(), moment()],
                "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "Last 60 Days": [moment().subtract(59, "days"), moment()],
                "Last 90 Days": [moment().subtract(89, "days"), moment()]
            }
        };
    this.handleEvent = ( event, datePicker) => {


        // We only want to check if the dates changed after hitting the Apply button

        var someChange = false;
         if (event.type === "apply") {
             if (!this.props.startDate.isSame(datePicker.startDate)) {
                 someChange = true;
                 if (!this.props.dateChangeActionFn )
                      this.props.startDateActionFn(datePicker.startDate);
            }

             if (!this.props.endDate.isSame(datePicker.endDate)) {
                 someChange = true;
                 if (!this.props.dateChangeActionFn)
                    this.props.endDateActionFn(datePicker.endDate);
             }

             if (someChange && this.props.dateChangeActionFn)
                 this.props.dateChangeActionFn(datePicker.startDate, datePicker.endDate);


        }
    };


    };

/*

            <DateRangePicker startDate={this.props.startDate} endDate={this.props.endDate} onEvent={this.handleEvent} format={this.props.format} ranges={this.state.ranges}>
                <Button className="selected-date-range-btn" style={{width: "100%"}}>
                    <span>
                        
                        {textLabel+ "ToDo GLYPH"}
                        <span className="caret" />
                    </span>
                </Button>
            </DateRangePicker>
*/


    render () {

        var startDateStr = this.props.startDate.format(VConst.DATE_FORMAT);
        var endDateStr = this.props.endDate.format(VConst.DATE_FORMAT);

   

        // " Date 2015-08-17 to 2015-08-18"
        var textLabel = " "+ t("filters.date") +" "+ startDateStr +" "+ t("filters.to") +" "+ endDateStr +" ";

//<RB.Glyphicon glyph="calendar" />
        return (

            <DateRangePicker 

                initialSettings={{
                    startDate: this.props.startDate, 
                    endDate: this.props.endDate ,
                    ranges: this.state.ranges
                }}


 
                onEvent={this.handleEvent} 
                format={this.props.format} 

                >
                <Button className="selected-date-range-btn" style={{width: "100%"}} variant="outline-dark">
                    <span>
                        
                       <Calendar/> {textLabel} <CaretDown />
                    </span>
                </Button>
            </DateRangePicker>



        )
    }
};

