'use strict';

import React, {useState, useEffect, useRef} from 'react';
import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel,
  Card,
  Collapse

} from 'react-bootstrap';
import {QuestionCircle} from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./CameraManagement.css');
export default function Populations (props) {


    const [editPopName, setEditPopName] = useState("");  
    const [species, setSpecies] = useState([]);  
      const [selectedSpeciesID, setSelectedSpeciesID ] = useState(0);
    const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});
    const [editActive, setEditActive] = useState(false);
	  const [ UpdtCount, setUpdtCount ] = useState(0);
   
    const [popList, setPopList ] = useState([]); 
    const [editPop, setEditPop] = useState (null);
    const [loadingPops, setLoadingPops] = useState (false);

    const [openInfo, setOpenInfo] = useState (false);

const TheTable = useRef(null);

	const clearSelection = () => {
		if (!TheTable || !TheTable.current || !TheTable.current.selectionContext) return;

  
		TheTable.current.selectionContext.selected = [];
		setEditPop(null);
		setSelectedSpeciesID(0);
setEditPopName("");
setEditActive(false);
		clearAlert();
	};


    const handleGetSpecies = (err, data) =>{
      clearAlert();
      let _s=[];
      if (!err)
          _s = data.list;
      else{
        setSpecies ([])
        setAlertMsg ({msg: "Error: "+err, kind:"danger" })  
        return;
      } 
  

    
          setSpecies (_s)
       // this.setSerialNumber( e.target.value);

    };

/*
        public class Population
        {
            public int PopID;
            public string Name;
            public string CreatedDate;
            public string Species;
            public bool Active;
            public int Farm_ID;
            public int Species_ID;
        }
        public class PopulationAndCageLists
        {
            public string msg;
            public List<Population> popList;
            public List<Cage> cageList;
        }


*/


const GetPopsCallback = (data) => {
    setLoadingPops (false);


		if (data.msg != '' && data.msg.length > 5 && data.msg.substring(0, 5) == 'Error') {
			setAlertMsg({
				msg: data.msg,
				kind: 'danger'
			});
			return;
		}

		setPopList(data.popList);
    clearSelection();



}


  useEffect(() =>{
      let dt = new Date();
			dt.setDate(dt.getDate() - 730); //2 years back
			let dtString = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
      setLoadingPops(true);
      VakiAPI.GetSpecies(handleGetSpecies);


      VakiAPI.GetPopulationAndCageLists(
				{ FarmID: props.farm, PopFromDate: dtString, partOnly: "pops" },
				GetPopsCallback
			);

     

  }, [UpdtCount, props.farm])



	const clearAlert = () => {
		setAlertMsg({ msg: '', kind: '' });
	};
    const handleNameChange = (e) =>{

        setEditPopName( e.target.value);

    };

    const postpopCallback = (result) =>{

     
       if ( result!= '' && result.length > 5 && result.substring(0, 5) == 'Error') {
             setAlertMsg({msg: "Error posting population: "+result,
                        kind:"danger"   }) ;
              setLoadingPops (false);
       
         
        } else {
            setAlertMsg({msg: "Population \'"+editPopName+"\' saved successfully",
                        kind:"info"   });
            setUpdtCount(UpdtCount+1);
            clearSelection();
 
        }
    };


    const onSave=()=>{
            clearAlert();
            if (editPopName.trim() == ""){
               setAlertMsg ({msg: "Must enter name for population!",
                              kind: "danger"});
                return;              

            }


            let pop = {
              Farm_ID: props.farm,
              Name: editPopName,
              Species_ID: selectedSpeciesID,
              PopID: editPop==null? -1 :editPop.PopID,
              Active: editActive ,

              //just dummies when posting:
              CreatedDate: "",
              Species: ""
            }

            setLoadingPops(true)
            VakiAPI.PostPopulation (pop, postpopCallback);


    };

    const handleSelectSpecies = (e)=>{
        setSelectedSpeciesID(e.target.value)
     
    }
 





    let TheSpecies = [];
    if (!UserDataStore.isUserLoaded ()){

        return <Spinner/>;
    }


    if (species && species.length > 0){
        for (let i = 0; i<species.length; i++ ){
           TheSpecies.push (   
              <option  key={i} value={species[i].Species_ID}> {species[i].Name}</option>
           );
        }

    }

/*
            public int PopID;
            public string Name;
            public string CreatedDate;
            public string Species;
            public bool Active;
            public int Farm_ID;
            public int Species_ID;
*/

	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			clearAlert();

			setEditPop({...row});

     setEditPopName (row.Name);
     setEditActive(row.Active);
   
     setSelectedSpeciesID (row.Species_ID);

	


		}
	};

const checkboxFormatter = (cell, row)=>{
  
  return  <div className="center-width100 "> <Form.Check	checked={cell} className="mb-4" readOnly /> </div>; 
           
}

	const columns = [
		{
			dataField: 'Name',
			text: 'Name'
		},
		{
			dataField: 'Species',
			text: 'Species'
		},
		{
			dataField: 'Active',
			text: 'Active',
      formatter: checkboxFormatter
		},
		{
			dataField: 'CreatedDate',
			text: 'Created'
		}
	];

let TestData = [
 {
   PopID: 121,
   Name: 'pop1',
   Species: 'Lax',
   Active: true,
   CreatedDate: '2021-12-01'
 },
 {
   PopID: 127,
   Name: 'pop2',
   Species: 'Lax',
   Active: false,
   CreatedDate: '2021-11-01'
 }
]


    let TheTableArea = <div className="center-width100"> <Spinner/> </div>

    if (!loadingPops)
        TheTableArea =
							<BootstrapTable
								keyField="PopID"
                ref={TheTable}
								data={popList}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
								keyField="PopID"
							
								rowEvents={rowEvents}
								selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}
							/>

	let disableClearSelect = false;

	if (
		!TheTable ||
		!TheTable.current ||
		!TheTable.current.selectionContext ||
		!TheTable.current.selectionContext.selected ||
		TheTable.current.selectionContext.selected.length == 0
	)
		disableClearSelect = true;


	let AnyChange = false;
	if (editPop) {
		AnyChange =
			editPop.Name != editPopName ||
			editPop.Species_ID != selectedSpeciesID ||
      editPop.Active != editActive;

	} else {
		AnyChange = editPopName.trim().length > 0;
	}


const onClickQuestion = () =>{
  alert ("clikk");
}

    return (
      <Container>


      <Row>
					<Col md="6">
						<div className="cs-leftpane">
   <Row>  
   <div className = "cs-left-pane-header">       
	<Button
								onClick={clearSelection}
								variant="outline-dark"
								disabled={disableClearSelect}
								className="mb-3"
							>
								Clear selection
							</Button>


<div className="cs-button-right" onClick = {() => setOpenInfo (!openInfo)}>
< QuestionCircle  width="1.5rem" height="1.5rem" />
</div>
 <Collapse in={openInfo}  timeout={1000} >
        <div className="cs-info-text">
       <p> Populations listed here are only the ones created on the BiomasDaily web page and time from createion less than 2 years.</p>
        <p> These are not visible in the on-site application, Bio3000 </p>
        </div>
      </Collapse>

</div>
</Row>

{TheTableArea}
						</div>


          </Col>

					<Col md="6">
          

      <div  className="cs-rightpane"   >
<Card>
<Card.Header className="center-width100">

<div className="cs-font-large" >
{editPop == null?"Create new population" :"Edit population"}
</div>
</Card.Header>
<Card.Body>
      <Form.Control
										value={editPop == null ?"": "ID: "+editPop.PopID}
										readOnly
										type="text"
										className="mb-3"
										label=""
                    style={{width:"6rem", float:"right"}}

                    />
                
   
   <br/>
   <Form.Group className="mb-3" controlId="formName">
    <Form.Label   className="mb-0">Name</Form.Label>
		<Form.Control value={editPopName} onChange={handleNameChange} 
							type="text"   className="mb-3"    placeholder="Population name"  />
           
</Form.Group>
     
		<Form.Check checked={editActive} onChange={(e)=>setEditActive (e.target.checked)}  label="Population active"
						   className="mb-3" />




<div >
<Form.Group className=" mb-3">
<Form.Label className="mb-0">Select species </Form.Label> 
<Form.Select  aria-label="select specie "  className="mb-0 width100 pt-2 pb-2"   onChange={handleSelectSpecies}  value={selectedSpeciesID}>
  {TheSpecies}
</Form.Select>
</Form.Group>
</div>

<Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>

</Card.Body>

<Card.Footer>
        <Button onClick={onSave} variant="outline-dark"  disabled={!AnyChange}        >
          Save
        </Button>
        </Card.Footer>
      </Card>
      </div>

      </Col>
      </Row>
      </Container>
    );
  
}