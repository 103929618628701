/**
 * Vaki Aquaculture Systems Ltd. Copyright 2015, all rights reserved.
 *
 * Language: JavaScript, ES5
 *
 * Authors:
 *  - Atli Guðlaugsson, atli@vaki.is
  *
 * This component makes up the form that allows the admin to edit a new organisation
 * Along with basic information, an organisation has a list of users that have 
 * access to it and a list of all its farms. New users and farms can also be created on 
 * the fly when editing an organisation, the will automatically be linked to the organisation
 * being edited.
 */

"use strict";

var Reflux = require("reflux");
var moment = require("moment");

var Actions = require("./../../actions/Actions.js");
var AdminViewStore = require("./../AdminViewStore.js");

var _currentEditOrganisation = { };

var EditOrgFormStore = Reflux.createStore({
    init: function() {
        this.listenTo(Actions.editOrgFormSubmit, this.onEditOrgFormSubmit);
        this.listenTo(Actions.orgListRowClick, this.onOrgListRowClick);

        _currentEditOrganisation = {
            id: 0,
            name: "",
            address: "",
            email: "",
            phone: "",
            dateCreated: "",
            selectedUsers: [],
            selectedFarms: []
        };
        
    },
    /* Triggered when the entire form is submitted. Eventually this will
    *  update the database entry for the organisation, and then send in updates
    *  to all new user-org, farm-org and user-farm entries.
    */
    onEditOrgFormSubmit: function(name, address, email, phone) {
        _currentEditOrganisation.name = name;
        _currentEditOrganisation.address = address;
        _currentEditOrganisation.email = email;
        _currentEditOrganisation.phone = phone;

        Actions.newOrganisationCreated(_currentEditOrganisation);

        this.trigger(_currentEditOrganisation);
    },
    /* When an organisation is clicked on the table to left, it's information is shown
    *  in the edit form, ready for editing.
    */
    onOrgListRowClick: function(org) {
        _currentEditOrganisation.id = org.id;
        _currentEditOrganisation.name = org.name;
        _currentEditOrganisation.address = org.address;
        _currentEditOrganisation.email = org.email;
        _currentEditOrganisation.phone = org.phone;
        _currentEditOrganisation.dateCreated = org.dateCreated;
        _currentEditOrganisation.selectedUsers = org.userList.slice();
        _currentEditOrganisation.selectedFarms = org.farmList.slice();
        this.trigger(_currentEditOrganisation);
    },
    getCurrentEditOrganisation: function() {
        return _currentEditOrganisation;
    }
});

module.exports = EditOrgFormStore;