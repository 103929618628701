'use strict';

import React, {useState, useEffect, useRef} from 'react';
import {

	Button,
  Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Alert,
  FloatingLabel,
  Card

} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import VakiAPI from '../../webservice/VakiAPI.js';
import Spinner from './../../utils/Spinner.jsx';
var UserDataStore = require("./../../stores/UserDataStore.js");
require('./CameraManagement.css');
export default function Cages (props) {
	const [ editName, setEditName ] = useState('');
	const [ editShape, setEditShape ] = useState('');
	const [ editArea, setEditArea ] = useState(0);
	const [ editDepth, setEditDepth ] = useState(0);
	const [ editCage, setEditCage ] = useState(null);
	const [cageList, setCageList ] = useState([]);	

    const [alertMsg, setAlertMsg] = useState ({msg:"", kind:""});

	const [ UpdtCount, setUpdtCount ] = useState(0);

    const [loadingCages, setLoadingCages] = useState (false);

const TheTable = useRef(null);

	const clearSelection = () => {
		if (!TheTable || !TheTable.current || !TheTable.current.selectionContext) return;

  
		TheTable.current.selectionContext.selected = [];
		setEditCage(null);
		setEditName("");
setEditShape('');
setEditArea(0);
setEditDepth (0);
		clearAlert();
	};






const GetCagesCallback = (data) => {
    setLoadingCages (false);


		if (data.msg != '' && data.msg.length > 5 && data.msg.substring(0, 5) == 'Error') {
			setAlertMsg({
				msg: data.msg,
				kind: 'danger'
			});
			return;
		}

		setCageList(data.cageList);
    clearSelection();



}


  useEffect(() =>{
      let dt = new Date();

      setLoadingCages(true);
  
	

      VakiAPI.GetPopulationAndCageLists(
				{ FarmID: props.farm, PopFromDate: "", partOnly: "cages" },
				GetCagesCallback
			);

     

  }, [UpdtCount, props.farm])



	const clearAlert = () => {
		setAlertMsg({ msg: '', kind: '' });
	};
    const handleNameChange = (e) =>{

        setEditName( e.target.value);

    };

    const postcageCallback = (result) =>{

     
       if ( result!= '' && result.length > 5 && result.substring(0, 5) == 'Error') {
             setAlertMsg({msg: "Error posting cage: "+result,
                        kind:"danger"   }) ;
              setLoadingCages (false);
       
         
        } else {
            setAlertMsg({msg: "Cage \'"+editName+"\' saved successfully",
                        kind:"info"   });
            setUpdtCount(UpdtCount+1);
            clearSelection();
 
        }
    };


    const onSave=()=>{
            clearAlert();
			
            if (editName.trim() == ""){
               setAlertMsg ({msg: "Must enter name for cage!",
                              kind: "danger"});
                return;              
            }
			/*
			if (editName.trim().length >= 50   ||  editShape.trim().length >= 50  ){
				 setAlertMsg ({msg: "A text field exceeds max length (50)",
                              kind: "danger"});
                return; 
			}
			*/

		let cage = {

			
			CageID: (editCage == null ? -1:editCage.CageID),
			Farm_ID: props.farm,
			Name: editName,
			Shape: editShape,
			Surface_Area: editArea,
			Depth: editDepth,
			CreatedDate: ""
		};

            setLoadingCages(true)
            VakiAPI.PostCage (cage, postcageCallback);


    };


 





  
    if (!UserDataStore.isUserLoaded ()){

        return <Spinner/>;
    }





	const rowEvents = {
		onClick: (e, row, rowIndex) => {
			clearAlert();
			setEditCage({...row});
     setEditName (row.Name);
   	 setEditShape(row.Shape);
		setEditArea (row.Surface_Area);
		setEditDepth (row.Depth);
  

	


		}
	};

const checkboxFormatter = (cell, row)=>{
  
  return  <div className="center-width100 "> <Form.Check	checked={cell} className="mb-4" /> </div>; 
           
}

	const columns = [
		{
			dataField: 'Name',
			text: 'Name'
		},
		{
			dataField: 'CreatedDate',
			text: 'Created'
		}
	];

let TestData = [
 {
   PopID: 121,
   Name: 'pop1',
   Species: 'Lax',
   Active: true,
   CreatedDate: '2021-12-01'
 },
 {
   PopID: 127,
   Name: 'pop2',
   Species: 'Lax',
   Active: false,
   CreatedDate: '2021-11-01'
 }
]


    let TheTableArea = <div className="center-width100"> <Spinner/> </div>

    if (!loadingCages)
        TheTableArea =
							<BootstrapTable
								keyField="CageID"
                ref={TheTable}
								data={cageList}
								columns={columns}
								mode="radio"
								noDataIndication="Table is Empty"
								size="sm"
								className="small"
								bordered={true}
								striped
							
								rowEvents={rowEvents}
								selectRow={{
									mode: 'radio',
									backgroundColor: 'blue',

									clickToSelect: true,
				

									style: { background: '#def3ff' }
								}}
							/>

	let disableClearSelect = false;

	if (
		!TheTable ||
		!TheTable.current ||
		!TheTable.current.selectionContext ||
		!TheTable.current.selectionContext.selected ||
		TheTable.current.selectionContext.selected.length == 0
	)
		disableClearSelect = true;


	let AnyChange = false;
	if (editCage) {
		AnyChange =
			editCage.Name != editName ||
			editCage.Surface_Area != editArea ||
      		editCage.Depth != editDepth ||
			 editCage.Shape != editShape ;

	} else {
		AnyChange = editName.trim().length > 0;
	}


    return (
      <Container>
      <Row>
					<Col md="6">
						<div className="cs-leftpane">
	<Button
								onClick={clearSelection}
								variant="outline-dark"
								disabled={disableClearSelect}
								className="mb-3"
							>
								Clear selection
							</Button>

{TheTableArea}
						</div>


          </Col>

					<Col md="6">
          

      <div  className="cs-rightpane"   >
<Card>
<Card.Header className="center-width100">
<div className="cs-font-large" >
{editCage == null?"Create new cage" :"Edit cage"}
</div>
</Card.Header >
<Card.Body>
      <Form.Control
										value={editCage == null ?"": "ID: "+editCage.CageID}
										readOnly
										type="text"
										className="mb-3"
										label=""
                    style={{width:"6rem", float:"right"}}

                    />
                
   
   <br/>

   <Form.Group className="mb-3" controlId="formGroupEmail">
    <Form.Label   className="mb-0">Name</Form.Label>
		<Form.Control value={editName} onChange={handleNameChange} 
							type="text"   className="mb-3"    placeholder="Cage name"  />
           
</Form.Group>
     



			<Form.Label className="mb-0">Description/shape/location (optional)</Form.Label>
			<Form.Control value={editShape} onChange={(e)=>setEditShape (e.target.value)} type="text" className="mb-3" />

			<Form.Label className="mb-0">Area (optional)</Form.Label>
			<Form.Control value={editArea} onChange={(e)=>setEditArea (e.target.value)} type="number" className="mb-3" />

			<Form.Label className="mb-0">Depth (optional)</Form.Label>
			<Form.Control value={editDepth} onChange={(e)=>setEditDepth (e.target.value)} type="number" className="mb-3" />





<Alert  variant={alertMsg.kind}  hidden = {alertMsg.msg == ""} > {alertMsg.msg} </Alert>
</Card.Body>
<Card.Footer>
        <Button onClick={onSave} variant="outline-dark"  disabled={!AnyChange}        >
          Save
        </Button>
		</Card.Footer>
		</Card>
      </div>
      </Col>
      </Row>
      </Container>
    );
  
}