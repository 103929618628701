/**
 * Created by Bjarni on 12.12.2016.
 */
"use strict";

var Reflux = require("reflux");
var moment = require("moment");
var Actions = require("./../actions/Actions.js");
var VConst = require("./../utils/VConst.js");
var VakiAPI = require("./../webservice/VakiAPI.js");
var FilterStore = require("./FilterStore.js");
var NavBarStore = require("./NavBarStore.js");
var VakiReactPropTypes = require("./../utils/VakiReactPropTypes.js");
var TranslateStore = require("./TranslateStore.js");
var ParMan = require("./ParMan.js");
var t = TranslateStore.getTranslate();



const startDate_ID = "MS_startDate";
const endDate_ID = "MS_endDate";


var _data = { };
var _filter = null;
var _loading = false;



var MeasurementsStore = Reflux.createStore({
    init: function() {

        this.listenTo(FilterStore, this.onFilterStoreChange);
        this.listenTo(Actions.apiNewMeasurementsData, this.onNewMeasurementsData);
        this.listenTo(Actions.measFetchData, this.fetchMeasurements);


        // By default, pick a range of # days in the past from now. We need
        // startOf("day") because the React-Bootstrap-Daterangepicker uses
        // dates from midnight by default.
/*
        var numberOfDays = VConst.DEFAULT_WEIGHT_TIME_RANGE;
        var endDate = moment().endOf("day");
        var startDate = endDate.clone().subtract(numberOfDays, "days").startOf("day");

        var strStart = ParMan.initParameter(startDate_ID)
        var strEnd = ParMan.initParameter(endDate_ID)


        if (strStart)
            startDate = moment(strStart);

        if (strEnd)
            endDate = moment(strEnd);

*/

        _filter = FilterStore.getFilter();
        _data = null;



        /*
        this.setChartData(VakiReactPropTypes.defaultPopulationWatGraphData.PopulationWeightAndTimeDataList);
        this.fetchWeightAndTimeGraph();
        */
    },

    onFilterStoreChange: function(newFilter) {

        _filter = newFilter;
        this.trigger(null, _filter, false);
    },

    /**
     * Set chart data expects raw data so we'll have to update translations
     * manually here.
     */
    onTranslateStoreChange: function() {


        //this.trigger(_data, false, true);
    },

    onNewMeasurementsData: function(data) {

        _data = data;
        this.trigger(_data, _filter, false);
        
    },






    fetchMeasurements: function() {
        _loading = true;
        var selectedPopulationID = FilterStore.getSelectedPopulationID();
     //Add one day to the enddate to keep it included
 
        if (selectedPopulationID && selectedPopulationID !== 0) {
            VakiAPI.fetchMeasurements(
                selectedPopulationID,
                moment(_filter.startDate).format(VConst.DATE_FORMAT),
                moment(_filter.endDate).add(1,'d').format(VConst.DATE_FORMAT)

            );
        }

        var duration = moment.duration( moment(_filter.endDate).diff(moment(_filter.startDate)));
        var days = Math.round( duration.asDays());


        var Criteria = {
            startDate: _filter.startDate,
            endDate: _filter.endDate,
            farmName: FilterStore.getSelectedFarmName(),
            popName: FilterStore.getSelectedPopulationName(),
            numDays: days
         }
        this.trigger (null, _filter, true, Criteria);
    }



});

module.exports = MeasurementsStore;

